import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './i18n/en';
import hu from './i18n/hu';
import OptimizerLogTable from './table/OptimizerLogTable';
import OptimizerLogEditForm from './editform/OptimizerLogEditForm';

i18next.addResourceBundle('en', 'optimizerLog', en);
i18next.addResourceBundle('hu', 'optimizerLog', hu);

export default [
	{
		path: '/optimizer-log/edit/:id/:tabKey?',
		component: OptimizerLogEditForm,
		auth: authRoles.optimizerLog
	},
	{
		path: '/optimizer-log/:sessionNameParam?',
		component: OptimizerLogTable,
		auth: authRoles.optimizerLog
	}
];
