import { Tooltip, IconButton, TableCell } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import { Link } from 'react-router-dom';

function TableSubPageButton(props) {
	return (
		<TableCell className="w-auto text-center" padding="none">
			<Tooltip title={props.config.subPage.tooltip}>
				<IconButton component={Link} to={props.config.subPage.state + props.item.id} aria-label="subPage" disabled={props.config.subPage.disabledIf ? props.config.subPage.disabledIf(props.item) : false}>
					<ListIcon fontSize="small" />
				</IconButton>
			</Tooltip>
		</TableCell>
	);
}

export default TableSubPageButton;
