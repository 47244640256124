import _ from '@lodash';

import { gridConverter } from '../../WorkflowUtil';
import WorkflowItem from '../WorkflowItem';

export const defaultDefinition = {
	name: null,
	input_fields: [{ name: 'Components', type: 'Components', enabled: true, value: null }],
	output_fields: [{ name: 'Components', type: 'Components' }],
	rows: [],
	columns: [],
	conversion: [],
	feature_column: null,
	component_title: null
};

export const converter = function (data) {
	return {
		...data,
		conversion: gridConverter(data, 'conversion')
	};
};

export default function BlenderTransformation(props) {
	return <WorkflowItem {...props} type="BLENDER" />;
}
