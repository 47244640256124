import nBossConnection from 'modules/base/service/nBossConnection';
import useImage from 'use-image';
import { Group, Rect, Image } from 'react-konva';

const iconUrl = `${nBossConnection.host}/assets/icons/settings.svg`;

function PropertiesSvgBtn(props) {
	const { enablePropertiesBtn, handleClick } = props;
	const [icon] = useImage(iconUrl);

	return (
		<Group width={24} height={24} x={0} y={0} opacity={enablePropertiesBtn ? 1 : 0} onPointerClick={e => handleClick()}>
			<Rect fill="white" stroke="black" strokeWidth={1} width={24} height={24} cornerRadius={3} />
			<Image x={4} y={4} width={16} height={16} image={icon} />
		</Group>
	);
}

export default PropertiesSvgBtn;
