import _ from '@lodash';
import { IconButton, Icon, Tooltip, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useReformer } from './ReformerProvider';

export default function ReformerConversion(props) {
	const { grid, addRow, removeRow } = useReformer();
	const { t } = useTranslation('transformer');

	return (
		<>
			{grid.conversion ? grid.conversion.map((c, i) => <ReformerConversionItem key={i} index={i} onDelete={() => removeRow(i)} field={c} />) : null}
			<div className="flex justify-end">
				<Tooltip title={t('addNewRow')}>
					<IconButton onClick={() => addRow()}>
						<Icon className="text-32">add</Icon>
					</IconButton>
				</Tooltip>
			</div>
		</>
	);
}

function ReformerConversionItem(props) {
	const { index } = props;
	const { t } = useTranslation('transformer');
	const { grid, changeRowName, changeConversionValue } = useReformer();
	const { key } = grid.rows[index];
	const { value } = grid.conversion[index];

	return (
		<div className={`flex flex-row w-full ${(index + 1) % 2 === 0 ? 'bg-gray-100' : ''}`}>
			<div className="mr-16 flex-1">
				<TextField value={grid.rows[index].name || ''} onChange={e => (e.target.value.length > 0 ? changeRowName(key, e.target.value) : changeRowName(key, null))} variant="outlined" fullWidth />
			</div>
			<div className="mr-16 flex-1">
				<TextField
					value={!_.isUndefined(value) && value !== null ? value : ''}
					onChange={e => (e.target.value.length > 0 ? changeConversionValue(key, Number.parseFloat(e.target.value)) : changeConversionValue(key, null))}
					type="number"
					variant="outlined"
					fullWidth
				/>
			</div>
			<Tooltip title={t('deleteRow')}>
				<IconButton onClick={() => props.onDelete()}>
					<Icon className="text-32">delete</Icon>
				</IconButton>
			</Tooltip>
		</div>
	);
}
