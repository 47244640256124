import _ from '@lodash';
import { useEffect, useRef } from 'react';
import { Stage, Layer, Rect } from 'react-konva';
import Konva from 'konva';
import useSize from 'app/admin/whatIf/component/UseSize';
import { generateId } from 'app/admin/whatIf/component/WhatIfUtil';
import { WorkflowContext, useWorkflow } from './WorkflowProvider';
import WorkflowValue from './items/WorkflowValue';
import ExpressionTransformation from './items/expression/ExpressionTransformation';
import FilterZeroTransformation from './items/filterzero/FilterZeroTransformation';
import GroupByPropertyTransformation from './items/groupbyproperty/GroupByPropertyTransformation';
import MergeComponentsTransformation from './items/mergecomponents/MergeComponentsTransformation';
import { roundPos } from './WorkflowUtil';
import WorkflowLine from './items/WorkflowLine';
import MassFracToMassFlowTransformation from './items/massmole/MassFracToMassFlowTransformation';
import MassFlowToMoleFlowTransformation from './items/massmole/MassFlowToMoleFlowTransformation';
import ReformerTransformation from './items/reformer/ReformerTransformation';
import MoleFlowToMassFlowTransformation from './items/massmole/MoleFlowToMassFlowTransformation';
import BlenderTransformation from './items/blender/BlenderTransformation';
import FractionatingTransformation from './items/fractionating/FractionatingTransformation';
import JoinStreamsTransformation from './items/joinstreams/JoinStreamsTransformation';
import DodoTransformation from './items/dodo/DodoTransformation';
import SumUpTransformation from './items/sumup/SumUpTransformation';
import DutyTransformation from './items/duty/DutyTransformation';
import TotalTransformation from './items/total/TotalTransformation';
import { paperSize } from './TransformerEditPage';
import ScalarsToTableTransformation from './items/scalarsToTable/ScalarsToTableTransformation';
import TableToScalarsTransformation from './items/tableToScalars/TableToScalarsTransformation';
import AspectTransformation from './items/aspect/AspectTransformation';
import TransformType from '../dto/TransformType';
import MassFlowToMassFracTransformation from './items/massmole/MassFlowToMassFracTransformation';
import SwitchTransformation from './items/switch/SwitchTransformation';

const scaleBy = 0.75;

function WorkflowView(props) {
	const containerRef = useRef(null);
	const stageRef = useRef(null);
	const layerRef = useRef(null);
	const backgroundRef = useRef(null);
	const containerSize = useSize(containerRef);
	const selectionRectRef = useRef();
	const selection = useRef({
		visible: false,
		x1: 0,
		y1: 0,
		x2: 0,
		y2: 0
	});
	const { setStage, data, setData, editMode, setShowPropertiesDialog, underEditing, setUnderEditing, drawLine, setSelected } = useWorkflow();

	useEffect(() => {
		setStage(stageRef.current);
	}, [stageRef.current]);

	const handleStageClick = e => {
		if (e.target === backgroundRef.current) {
			const clickPos = roundPos(e.target.getRelativePointerPosition());
			if (editMode === 'VALUE') {
				const key = generateId();
				setData({
					...data,
					value: [
						...data.value,
						{
							key,
							valueKey: null,
							new: true,
							class: 'VALUE',
							x: clickPos.x,
							y: clickPos.y
						}
					]
				});
				setShowPropertiesDialog({ class: 'VALUE', key });
			} else if (editMode === 'TRANSFORM') {
				const key = generateId();
				setData({
					...data,
					transform: [
						...data.transform,
						{
							key,
							class: 'TRANSFORM',
							type: null,
							name: null,
							new: true,
							x: clickPos.x,
							y: clickPos.y,
							input_fields: [],
							output_fields: []
						}
					]
				});
				setShowPropertiesDialog({ class: 'TRANSFORM', key, type: null });
			} else if (editMode === 'LINE' && underEditing !== null) {
				setData({
					...data,
					line: data.line.filter(d => d.key !== underEditing.key)
				});
				setUnderEditing(null);
			} else if (editMode === 'MOVE') {
				setSelected([]);
			}
		}
	};

	const handleStageMouseMove = e => {
		if (editMode === 'LINE' && underEditing !== null) {
			const point = backgroundRef.current.getRelativePointerPosition();
			drawLine(point.x, point.y);
		} else if (editMode === 'SELECT') {
			if (!selection.current.visible) {
				return;
			}
			const pos = e.target.getStage().getRelativePointerPosition();
			selection.current.x2 = pos.x;
			selection.current.y2 = pos.y;
			updateSelectionRect();
		}
	};

	const updateStagePos = e => {
		if (e.target.getType() === 'Stage') {
			let x = e.target.x();
			let y = e.target.y();
			const realWidth = paperSize.width * data.stage.scale;
			const realHeight = paperSize.height * data.stage.scale;
			if (realWidth > containerSize.width) {
				if (x > 0) x = 0;
				else if (x < (realWidth - containerSize.width) * -1) x = (realWidth - containerSize.width) * -1;
			}
			if (realHeight > containerSize.height) {
				if (y > 0) y = 0;
				else if (y < (realHeight - containerSize.height) * -1) y = (realHeight - containerSize.height) * -1;
			}

			setData({
				...data,
				stage: {
					x,
					y,
					scale: data.stage.scale
				}
			});
			e.target.x(x);
			e.target.y(y);
		}
	};

	const handleWheel = e => {
		const oldScale = stageRef.current.scaleX();
		const pointer = stageRef.current.getPointerPosition();

		const mousePointTo = {
			x: (pointer.x - stageRef.current.x()) / oldScale,
			y: (pointer.y - stageRef.current.y()) / oldScale
		};

		// how to scale? Zoom in? Or zoom out?
		let direction = e.evt.deltaY > 0 ? 1 : -1;

		// when we zoom on trackpad, e.evt.ctrlKey is true
		// in that case lets revert direction
		if (e.evt.ctrlKey) {
			direction = -direction;
		}

		const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

		stageRef.current.scale({ x: newScale, y: newScale });

		const newPos = {
			x: pointer.x - mousePointTo.x * newScale,
			y: pointer.y - mousePointTo.y * newScale
		};
		stageRef.current.position(newPos);

		setData({
			...data,
			stage: {
				x: newPos.x,
				y: newPos.y,
				scale: newScale
			}
		});
	};

	const handlePointerDown = e => {
		if (editMode === 'SELECT') {
			const pos = e.target.getRelativePointerPosition();
			//const pos = e.target.getStage().getPointerPosition();
			selection.current.visible = true;
			selection.current.x1 = pos.x;
			selection.current.y1 = pos.y;
			selection.current.x2 = pos.x;
			selection.current.y2 = pos.y;
			updateSelectionRect();
		}
	};

	const handlePointerUp = e => {
		if (editMode === 'SELECT') {
			if (!selection.current.visible) {
				return;
			}
			const selBox = selectionRectRef.current.getClientRect();
			const selectedIdList = [];
			layerRef.current.find('.value,.item').forEach(elementNode => {
				const elBox = elementNode.getClientRect();
				if (Konva.Util.haveIntersection(selBox, elBox)) {
					selectedIdList.push(elementNode.attrs.id);
				}
			});
			setSelected(selectedIdList);
			selection.current.visible = false;
			// disable click event
			//Konva.listenClickTap = false;
			updateSelectionRect();
		}
	};

	const updateSelectionRect = () => {
		const node = selectionRectRef.current;
		node.setAttrs({
			visible: selection.current.visible,
			x: Math.min(selection.current.x1, selection.current.x2),
			y: Math.min(selection.current.y1, selection.current.y2),
			width: Math.abs(selection.current.x1 - selection.current.x2),
			height: Math.abs(selection.current.y1 - selection.current.y2),
			fill: 'rgba(0, 100, 0, 0.3)'
		});
		node.getLayer().batchDraw();
	};

	return (
		<div className="w-full h-full" ref={containerRef} id="transformation-view">
			{containerSize && (
				<WorkflowContext.Consumer>
					{value => (
						<Stage
							ref={stageRef}
							className="bg-black"
							x={data.stage.x}
							y={data.stage.y}
							scale={{ x: data.stage.scale, y: data.stage.scale }}
							width={containerSize.width}
							height={containerSize.height}
							draggable={editMode !== 'SELECT'}
							onPointerMove={e => handleStageMouseMove(e)}
							onDragEnd={e => updateStagePos(e)}
							onPointerClick={e => handleStageClick(e)}
							onWheel={e => handleWheel(e)}
							onPointerDown={e => handlePointerDown(e)}
							onPointerUp={e => handlePointerUp(e)}
						>
							<WorkflowContext.Provider value={value}>
								<Layer ref={layerRef}>
									<Rect x={0} y={0} width={paperSize.width} height={paperSize.height} fill="white" ref={backgroundRef} />
									{data.line.map((line, i) => (
										<WorkflowLine key={line.key} item={line} />
									))}
									{data.value
										.filter(v => !v.new)
										.map((val, i) => (
											<WorkflowValue key={val.key} item={val} />
										))}
									{data.transform
										.filter(t => !t.new)
										.map((trans, i) => (
											<WorkflowItem key={trans.key} item={trans} />
										))}
									<Rect fill="rgba(0,100,0,0.5)" ref={selectionRectRef} />
								</Layer>
							</WorkflowContext.Provider>
						</Stage>
					)}
				</WorkflowContext.Consumer>
			)}
		</div>
	);
}

function WorkflowItem(props) {
	const { item } = props;
	if (item.type === TransformType.ASPECT) return <AspectTransformation {...props} />;
	if (item.type === TransformType.FILTER_ZERO) return <FilterZeroTransformation {...props} />;
	if (item.type === TransformType.GROUP_BY_PROPERTY) return <GroupByPropertyTransformation {...props} />;
	if (item.type === TransformType.MERGE_COMPONENTS) return <MergeComponentsTransformation {...props} />;
	if (item.type === TransformType.MASSFRAC_TO_MASSFLOW) return <MassFracToMassFlowTransformation {...props} />;
	if (item.type === TransformType.MASSFLOW_TO_MASSFRAC) return <MassFlowToMassFracTransformation {...props} />;
	if (item.type === TransformType.MASSFLOW_TO_MOLEFLOW) return <MassFlowToMoleFlowTransformation {...props} />;
	if (item.type === TransformType.REFORMER) return <ReformerTransformation {...props} />;
	if (item.type === TransformType.MOLEFLOW_TO_MASSFLOW) return <MoleFlowToMassFlowTransformation {...props} />;
	if (item.type === TransformType.BLENDER) return <BlenderTransformation {...props} />;
	if (item.type === TransformType.FRACTIONATING) return <FractionatingTransformation {...props} />;
	if (item.type === TransformType.JOIN_STREAMS) return <JoinStreamsTransformation {...props} />;
	if (item.type === TransformType.DODO) return <DodoTransformation {...props} />;
	if (item.type === TransformType.SUM_UP) return <SumUpTransformation {...props} />;
	if (item.type === TransformType.DUTY) return <DutyTransformation {...props} />;
	if (item.type === TransformType.TOTAL) return <TotalTransformation {...props} />;
	if (item.type === TransformType.SCALARS_TO_TABLE) return <ScalarsToTableTransformation {...props} />;
	if (item.type === TransformType.TABLE_TO_SCALARS) return <TableToScalarsTransformation {...props} />;
	if (item.type === TransformType.EXPRESSION) return <ExpressionTransformation {...props} />;
	if (item.type === TransformType.SWITCH) return <SwitchTransformation {...props} />;
	return <></>;
}

export default WorkflowView;
