import _ from '@lodash';
import EnumDto from 'modules/base/dto/enum/EnumDto';
import nBossConnection from 'modules/base/service/nBossConnection';

class AspectService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'aspectControl';
		this.getInputAttributesListSelectionPromise = {};
		this.getOutputKeysSelectionPromise = {};
	}

	/**
	 * @return {Promise<NamedDto[]>}
	 */
	getListSelection() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getListSelection`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<EnumDto[]>}
	 */
	getListSelectionEnum() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getListSelection`)
				.then(response => resolve(_.isArray(response.data) ? response.data.map(d => new EnumDto().setup({ label: d.name, value: d.name })) : []))
				.catch(reject);
		});
	}

	/**
	 * @param {number} aspectId
	 * @param {Object} values
	 * @return {Promise<any>}
	 */
	calculateById(aspectId, values) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/calculateById`, {
					aspectId,
					values
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} name
	 * @param {Object} values
	 * @return {Promise<any>}
	 */
	calculate(name, values) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/calculate`, {
					name,
					values
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<any>}
	 */
	getInputAttributesListSelection(id) {
		if (typeof this.getInputAttributesListSelectionPromise[id] === 'undefined') {
			this.getInputAttributesListSelectionPromise[id] = new Promise((resolve, reject) => {
				this.connection
					.post(`/${this.CONTROL}/getInputAttributesListSelection`, {
						id
					})
					.then(response => {
						resolve(response.data);
						delete this.getInputAttributesListSelectionPromise[id];
					})
					.catch(response => {
						reject(response);
						delete this.getInputAttributesListSelectionPromise[id];
					});
			});
			return this.getInputAttributesListSelectionPromise[id];
		}
		return new Promise((resolve, reject) => {
			this.getInputAttributesListSelectionPromise[id].then(result => resolve(result)).catch(error => reject(error));
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<any>}
	 */
	getOutputKeysSelection(id) {
		if (typeof this.getOutputKeysSelectionPromise[id] === 'undefined') {
			this.getOutputKeysSelectionPromise[id] = new Promise((resolve, reject) => {
				this.connection
					.post(`/${this.CONTROL}/getOutputKeysSelection`, {
						id
					})
					.then(response => {
						resolve(response.data);
						delete this.getOutputKeysSelectionPromise[id];
					})
					.catch(response => {
						reject(response);
						delete this.getOutputKeysSelectionPromise[id];
					});
			});
			return this.getOutputKeysSelectionPromise[id];
		}
		return new Promise((resolve, reject) => {
			this.getOutputKeysSelectionPromise[id].then(result => resolve(result)).catch(error => reject(error));
		});
	}

	/**
	 * @param {string} datasetName
	 * @param {number} aspectId
	 * @return {Promise<Object>}
	 */
	createDatasetByAspect(datasetName, aspectId) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/createDatasetByAspect`, {
					datasetName,
					aspectId
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new AspectService();
