import NamedDto from 'modules/base/dto/NamedDto';
import OptimizationCaseLimitValueEditDto from './OptimizationCaseLimitValueEditDto';

export class OptimizationCaseLimitEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {boolean} */
		this.enabled = null;
		/** @type {OptimizationCaseLimitValueEditDto} */
		this.hard = null;
		/** @type {OptimizationCaseLimitValueEditDto} */
		this.soft = null;
	}

	/**
	 * @typedef {Object} _OptimizationCaseLimitEditDtoProps
	 * @property {boolean} enabled
	 * @property {OptimizationCaseLimitValueEditDto} hard
	 * @property {OptimizationCaseLimitValueEditDto} soft
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _OptimizationCaseLimitEditDtoProps} OptimizationCaseLimitEditDtoProps
	 */
	/**
	 *
	 * @param {OptimizationCaseLimitEditDtoProps} props
	 * @returns {OptimizationCaseLimitEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.enabled = props.enabled;
		this.hard = props.hard !== null ? new OptimizationCaseLimitValueEditDto().setup(props.hard) : new OptimizationCaseLimitValueEditDto().setup({});
		this.soft = props.soft !== null ? new OptimizationCaseLimitValueEditDto().setup(props.soft) : new OptimizationCaseLimitValueEditDto().setup({});
		return this;
	}
}

export default OptimizationCaseLimitEditDto;
