import _ from '@lodash';
import * as yup from 'yup';
import { useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFormGridComponent from 'modules/ui/editform/subForm/EditFormGridComponent';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import { TransComponentTitleField, TransFeatureColumnField, TransInputFieldsPropertiesArray, TransNameField, TransValidationName } from '../TransformationProperties';
import TransformationPropertiesDialog from '../TransformationPropertiesDialog';
import { useWorkflow } from '../../WorkflowProvider';
import { converter } from './ExpressionTransformation';

function ExpressionProperties() {
	const { data, showPropertiesDialog, transformationNameAvailable } = useWorkflow();
	const { t } = useTranslation(['transformer', 'validation']);
	const item = data.transform.find(d => d.key === showPropertiesDialog.key);
	const [tabValue, setTabValue] = useState(0);

	const schema = yup.object().shape({
		name: TransValidationName(transformationNameAvailable, item.key)
	});

	const handleTabChange = (event, value) => {
		setTabValue(value);
	};

	return (
		<TransformationPropertiesDialog
			maxWidth="lg"
			schema={schema}
			titleKey="expressionProperties"
			titleContent={
				<Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto" classes={{ root: 'h-64 bg-white' }}>
					<Tab className="h-64" label={t('basicInfo')} />
					<Tab className="h-64" label={t('expressions')} />
					<Tab className="h-64" label={t('input_fields')} />
				</Tabs>
			}
			converter={d => converter(d, false)}
		>
			<div className={tabValue !== 0 ? 'hidden' : ''}>
				<TransNameField />
				<TransFeatureColumnField />
				<TransComponentTitleField />
			</div>
			<div className={tabValue !== 1 ? 'hidden' : ''}>
				{tabValue === 1 && (
					<EditFormGridComponent
						config={{ topic: 'transformer' }}
						fieldConfig={{
							key: 'expressionList',
							component: EditFormGridComponent,
							defaultValue: {
								key: null,
								value: null
							},
							fields: [
								{
									key: 'key',
									component: EditFormTextField,
									required: true
								},
								{
									key: 'value',
									component: EditFormTextField,
									required: true
								}
							]
						}}
					/>
				)}
			</div>
			<div className={tabValue !== 2 ? 'hidden' : 'mt-16'}>
				<TransInputFieldsPropertiesArray disableWatch />
			</div>
		</TransformationPropertiesDialog>
	);
}

export default ExpressionProperties;
