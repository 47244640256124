import _ from '@lodash';
import ModelService from './ModelService';

class StorageModelService {
	constructor() {
		this.inputAttributeResult = [];
		this.outputAttributeResult = [];
	}

	getInputAttributesListSelection(id) {
		if (_.isUndefined(this.inputAttributeResult[id]))
			return new Promise((resolve, reject) => {
				ModelService.getInputAttributesListSelection(id)
					.then(resp => {
						this.inputAttributeResult[id] = resp;
						resolve(resp);
					})
					.catch(e => reject(e));
			});
		return new Promise(resolve => {
			resolve(this.inputAttributeResult[id]);
		});
	}

	getOutputAttributesListSelection(id) {
		if (_.isUndefined(this.outputAttributeResult[id]))
			return new Promise((resolve, reject) => {
				ModelService.getOutputAttributesListSelection(id)
					.then(resp => {
						this.outputAttributeResult[id] = resp;
						resolve(resp);
					})
					.catch(e => reject(e));
			});
		return new Promise(resolve => {
			resolve(this.outputAttributeResult[id]);
		});
	}
}
export default StorageModelService;
