import _ from '@lodash';
import NumberValue from 'app/admin/common/component/NumberValue';
import { useState, useEffect } from 'react';
import { useWorkflow } from '../WorkflowProvider';

const maxItem = 4;

export default function WorkflowOutputValueFloat(props) {
	const { containerRef } = props;
	const [coords, setCoords] = useState({ x: 0, y: 0 });
	const { floatCalculate } = useWorkflow();

	useEffect(() => {
		const handleWindowMouseMove = event => {
			setCoords({
				x: event.clientX,
				y: event.clientY
			});
		};
		window.addEventListener('mousemove', handleWindowMouseMove);

		return () => {
			window.removeEventListener('mousemove', handleWindowMouseMove);
		};
	}, []);
	if (!containerRef.current) return <></>;
	const containerCoords = containerRef.current.getBoundingClientRect();

	const renderObject = () => {
		const rows = Object.keys(floatCalculate.value);
		if (_.isObject(floatCalculate.value[rows[0]])) {
			const columns = Object.keys(floatCalculate.value[rows[0]]);

			return (
				<table>
					<thead>
						<tr>
							<th align="left" className="pr-8">
								Name
							</th>
							{columns.map(column => (
								<th key={column} align="right">
									{column}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{rows.slice(0, maxItem).map(row => (
							<tr key={row}>
								<th align="left" className="font-bold pr-8">
									{row}
								</th>
								{columns.map(column => (
									<td className="font-mono" align="right">
										<NumberValue value={floatCalculate.value[row][column]} />
									</td>
								))}
							</tr>
						))}
						{rows.length > maxItem ? <p>and more...</p> : null}
					</tbody>
				</table>
			);
		}

		return (
			<table>
				<thead>
					<tr>
						<th align="left" className="pr-8">
							Name
						</th>
						<th align="right">Value</th>
					</tr>
				</thead>
				<tbody>
					{rows.slice(0, maxItem).map(row => (
						<tr key={row}>
							<th align="left" className="font-bold pr-8">
								{row}
							</th>
							<td className="font-mono" align="right">
								<NumberValue value={floatCalculate.value[row]} />
							</td>
						</tr>
					))}
					{rows.length > maxItem ? <p>and more...</p> : null}
				</tbody>
			</table>
		);
	};

	return (
		<div className="absolute border-green-500 border-2 bg-white p-8 rounded-xl" style={{ top: `${coords.y - containerCoords.top + 5}px`, left: `${coords.x - containerCoords.left + 5}px` }}>
			{_.isObject(floatCalculate.value) ? (
				renderObject()
			) : (
				<div className="font-mono">
					<NumberValue value={floatCalculate.value} />
				</div>
			)}
		</div>
	);
}
