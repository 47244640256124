import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './i18n/en';
import hu from './i18n/hu';
import MeasurementEditForm from './editform/MeasurementEditForm';
import MeasurementTable from './table/MeasurementTable';

i18next.addResourceBundle('en', 'measurement', en);
i18next.addResourceBundle('hu', 'measurement', hu);

export default [
	{
		path: '/measurement/edit/:id/:tabKey?',
		component: MeasurementEditForm,
		auth: authRoles.measurement
	},
	{
		path: '/measurement/:sessionNameParam?',
		component: MeasurementTable,
		auth: authRoles.measurement
	}
];
