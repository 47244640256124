import _ from '@lodash';
import { useSelector, useDispatch } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import { ThemeProvider } from '@material-ui/core/styles';
import { useState } from 'react';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useFormContext } from 'react-hook-form';
import { Button, Icon, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import ModelService from 'app/admin/model/service/ModelService';
import AspectService from 'app/admin/aspect/service/AspectService';
import ConfirmDialog from 'modules/ui/component/ConfirmDialog';
import TaskManagerService from 'app/admin/taskManager/service/TaskManagerService';

export default function (props) {
	const mainTheme = useSelector(selectMainTheme);

	const dispatch = useDispatch();
	const { id, type } = props;
	const { getValues } = useFormContext();
	const [loading, setLoading] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [message, setMessage] = useState(null);

	const handleClick = () => {
		setShowConfirm(false);
		const dto = getValues();
		if (dto.name === null || dto.name.length === 0) {
			dispatch(showMessage({ message: 'Model name is required.' }));
		} else if (dto.input_attributes === null || dto.input_attributes.length === 0 || dto.output_attributes === null || dto.output_attributes.length === 0) {
			dispatch(showMessage({ message: 'Input/output attributes is required.' }));
		} else {
			const values = {};
			dto.input_attributes.forEach(element => {
				values[element.name] = element.value;
			});
			setLoading(true);
			setShowDialog(true);
			if (type === 'aspect')
				TaskManagerService.addCalculate(dto.id, dto.name, null, values)
					.then(response => handleResponse(response))
					.catch(err => handleCatch(err));
			else {
				console.log(`unknown calculate background type: ${type}`);
			}
		}
	};

	const handleResponse = response => {
		console.log(response);
		if (response !== null) {
			setMessage(response);
		} else {
			setShowDialog(false);
			dispatch(showMessage({ message: 'Calculate request submitted.' }));
		}
		setLoading(false);
	};

	const handleCatch = err => {
		console.log(err);
		setLoading(false);
		setMessage(`Calculate connection problem: ${err !== null ? err : 'Unkown'}`);
	};

	return (
		<>
			<Button className="whitespace-nowrap mx-4" variant="contained" color="secondary" disabled={id === 'new'} onClick={() => setShowConfirm(true)} startIcon={<Icon className="hidden sm:flex">graphic_eq</Icon>}>
				Calculate Background
			</Button>
			<ConfirmDialog show={showConfirm} title="Calculate Background" description="Do you want to start this calculation task in the background?" onYes={() => handleClick()} onClose={() => setShowConfirm(false)} />
			<ThemeProvider theme={mainTheme}>
				<Dialog open={showDialog} fullWidth>
					<DialogTitle disableTypography className="border-b">
						Calculate Background
					</DialogTitle>
					<DialogContent className="p-20">
						{loading && <FuseLoading />}
						{!loading && <div className="flex justify-center">{message || 'No response.'}</div>}
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button
							onClick={() => {
								setShowDialog(false);
							}}
						>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</>
	);
}
