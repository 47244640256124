import _ from '@lodash';
import NumberValue from 'app/admin/common/component/NumberValue';
import { useState, useEffect } from 'react';
import { useWorkflow } from '../WorkflowProvider';

export default function ValidationFloatMessages(props) {
	const { containerRef } = props;
	const [coords, setCoords] = useState({ x: 0, y: 0 });
	const { floatValidation } = useWorkflow();

	useEffect(() => {
		const handleWindowMouseMove = event => {
			setCoords({
				x: event.clientX,
				y: event.clientY
			});
		};
		window.addEventListener('mousemove', handleWindowMouseMove);

		return () => {
			window.removeEventListener('mousemove', handleWindowMouseMove);
		};
	}, []);
	if (!containerRef.current) return <></>;
	const containerCoords = containerRef.current.getBoundingClientRect();

	return (
		<div className="absolute border-green-500 border-2 bg-white p-8 rounded-xl" style={{ top: `${coords.y - containerCoords.top + 5}px`, left: `${coords.x - containerCoords.left + 5}px` }}>
			{floatValidation.value.entries.map((entry, i) => (
				<p key={i} className={entry.severity === 'Error' ? 'text-red' : 'text-yellow-900'}>{`${entry.severity}: ${entry.message}`}</p>
			))}
		</div>
	);
}
