import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormGridComponent from 'modules/ui/editform/subForm/EditFormGridComponent';
import { useParams, withRouter } from 'react-router';
import DatasetEditService from '../service/DatasetEditService';
import FieldType from '../dto/FieldType';

const config = data => {
	return {
		key: 'datasetEditForm',
		topic: 'dataset',
		service: DatasetEditService,
		tabList: initData => {
			return [{ key: 'basic' }, { key: 'key_fields' }, { key: 'value_fields' }];
		},
		fields: initData => {
			const fields = [
				{
					key: 'name',
					component: EditFormTextField,
					required: true,
					readOnly: data.id !== 'new'
				},
				{
					key: 'table_name',
					component: EditFormTextField,
					required: true
				},
				{
					key: 'description',
					component: EditFormTextField,
					multiline: true
				},
				{
					key: 'key_fields',
					tab: 'key_fields',
					component: EditFormGridComponent,
					defaultValue: {
						id: null,
						name: null,
						type: null
					},
					fields: [
						{
							key: 'name',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'type',
							component: EditFormSelectField,
							required: true,
							type: 'enum',
							items: Object.keys(FieldType).map(key => {
								return { label: FieldType[key], value: key };
							})
						}
					]
				},
				{
					key: 'value_fields',
					tab: 'value_fields',
					component: EditFormGridComponent,
					defaultValue: {
						id: null,
						name: null,
						type: null
					},
					fields: [
						{
							key: 'name',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'type',
							component: EditFormSelectField,
							required: true,
							type: 'enum',
							items: Object.keys(FieldType).map(key => {
								return { label: FieldType[key], value: key };
							})
						}
					]
				}
			];

			return fields;
		}
	};
};

function DatasetEditForm() { 
	const { id } = useParams();
	const dispatch = useDispatch();
	const myConfig = config({ id });
	return (
		<EditFormPage
			config={myConfig}
			onSave={result => {
				if (!result.engineSuccessful) dispatch(showMessage({ message: `Engine: ${result.engineMessage}` }));
			}}
		/>
	);
}

export default withRouter(DatasetEditForm);
