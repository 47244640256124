import axios from 'axios';

class NBossConnection {
	constructor() {
		console.log('nBossConnection initialized');
		if (process.env.REACT_APP_API_HOST)
			this.host = `http${process.env.REACT_APP_API_SECURE === 'true' ? `s` : ``}://${process.env.REACT_APP_API_HOST + (process.env.REACT_APP_API_PORT.length > 0 ? `:${process.env.REACT_APP_API_PORT}` : ``)}`;
		else this.host = `${window.location.protocol}//${window.location.host}`;

		this.basePath = `/${process.env.REACT_APP_API_NAME}/methods/`;
		this.baseUrl = this.host + this.basePath;

		this.connection = axios.create({
			baseURL: this.baseUrl
		});

		this.errorHandler = e => console.log(e);

		this.connection.interceptors.response.use(
			response => {
				return response;
			},
			error => {
				error.isnBossConnectionError = true;
				return Promise.reject(error);
			}
		);

		window.addEventListener('unhandledrejection', e => {
			if (typeof e.reason !== 'undefined' && typeof e.reason.isnBossConnectionError !== 'undefined' && e.reason.isnBossConnectionError) this.errorHandler(e.reason);
		});
	}

	setErrorHandler(errorHandler) {
		this.errorHandler = errorHandler;
	}
}

export default new NBossConnection();
