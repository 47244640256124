import OcuEntityValidationResult from 'app/admin/common/dto/OcuEntityValidationResult';
import nBossConnection from 'modules/base/service/nBossConnection';

class ModelService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'modelControl';
		this.getInputAttributesListSelectionPromise = {};
		this.getOutputAttributesListSelectionPromise = {};
	}

	/**
	 * @param {string} name
	 * @param {string} simulationId
	 * @return {Promise<string>}
	 */
	addLummusBySimulationId(name, simulationId) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/addLummusBySimulationId`, {
					name,
					simulationId
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} name
	 * @param {string} simulationId
	 * @param {string} subscriptionKey
	 * @return {Promise<string>}
	 */
	addLummus2BySimulationId(name, simulationId, subscriptionKey) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/addLummus2BySimulationId`, {
					name,
					simulationId,
					subscriptionKey
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} name
	 * @param {Object} values
	 * @return {Promise<any>}
	 */
	calculate(name, values) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/calculate`, {
					name,
					values
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<NamedDto[]>}
	 */
	getListSelection() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getListSelection`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<Object>}
	 */
	getData(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getData`, {
					id
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<any>}
	 */
	getInputAttributesListSelection(id) {
		if (typeof this.getInputAttributesListSelectionPromise[id] === 'undefined') {
			this.getInputAttributesListSelectionPromise[id] = new Promise((resolve, reject) => {
				this.connection
					.post(`/${this.CONTROL}/getInputAttributesListSelection`, {
						id
					})
					.then(response => {
						resolve(response.data);
						delete this.getInputAttributesListSelectionPromise[id];
					})
					.catch(response => {
						reject(response);
						delete this.getInputAttributesListSelectionPromise[id];
					});
			});
			return this.getInputAttributesListSelectionPromise[id];
		}
		return new Promise((resolve, reject) => {
			this.getInputAttributesListSelectionPromise[id].then(result => resolve(result)).catch(error => reject(error));
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<any>}
	 */
	getOutputAttributesListSelection(id) {
		if (typeof this.getOutputAttributesListSelectionPromise[id] === 'undefined') {
			this.getOutputAttributesListSelectionPromise[id] = new Promise((resolve, reject) => {
				this.connection
					.post(`/${this.CONTROL}/getOutputAttributesListSelection`, {
						id
					})
					.then(response => {
						resolve(response.data);
						delete this.getOutputAttributesListSelectionPromise[id];
					})
					.catch(response => {
						reject(response);
						delete this.getOutputAttributesListSelectionPromise[id];
					});
			});
			return this.getOutputAttributesListSelectionPromise[id];
		}
		return new Promise((resolve, reject) => {
			this.getOutputAttributesListSelectionPromise[id].then(result => resolve(result)).catch(error => reject(error));
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<any>}
	 */
	getUomList(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getUomList`, {
					id
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @param {string} name
	 * @return {Promise<OcuEntityValidationResult>}
	 */
	copy(id, name) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/copy`, {
					id,
					name
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new ModelService();
