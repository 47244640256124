import { Group, Text, Circle, Line } from 'react-konva';
import { calcOutputPos, width } from './WorkflowItem';
import WorkflowItemOutputValue from '../calculate/WorkflowItemOutputValue';

function OutputPin(props) {
	const { index, name, max, onOutputClick, transformer, itemHeight } = props;
	const pos = calcOutputPos(itemHeight, index, max);

	return (
		<Group>
			<Text text={name} fontSize={9} fill="black" listening={false} x={pos.x - 15 - width} y={pos.y - 4} width={width} align="right" />
			<Line stroke="darkGreen" strokeWidth={5} points={[pos.x, pos.y, pos.x - 10, pos.y]} hitStrokeWidth={5} onPointerClick={() => onOutputClick(name, pos)} />
			<Circle x={pos.x} y={pos.y} fill="darkGreen" radius={5} hitStrokeWidth={5} onPointerClick={() => onOutputClick(name, pos)} />
			<WorkflowItemOutputValue transformer={transformer} name={name} x={pos.x - 12 - 100} y={pos.y - 4} width={100} height={10} />
		</Group>
	);
}
export default OutputPin;
