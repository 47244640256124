import { useTranslation } from 'react-i18next';
import { ButtonGroup, Button, IconButton, Tooltip } from '@material-ui/core';
import { BsArrowsMove, BsArrowUpRight, BsFillRocketFill, BsFillTrashFill, BsCircle, BsSquare, Bs123, Bs6Circle, BsTable, BsBookFill, BsBoxSeamFill } from 'react-icons/bs';
import { FaImage, FaComments } from 'react-icons/fa';
import { FiRotateCw } from 'react-icons/fi';
import { GiResize } from 'react-icons/gi';
import { GoMirror } from 'react-icons/go';
import { useWhatIf } from './WhatIfProvider';

function WhatIfActions(props) {
	const { editMode, setEditMode } = useWhatIf();
	const { t } = useTranslation('whatIf');
	return (
		<div className="flex flex-col items-center">
			<ButtonGroup aria-label="outlined button group">
				<Tooltip title={t('move')}>
					<IconButton onClick={() => setEditMode('MOVE')} color={editMode === 'MOVE' ? 'secondary' : 'default'}>
						<BsArrowsMove />
					</IconButton>
				</Tooltip>
				<Tooltip title={t('resize')}>
					<IconButton onClick={() => setEditMode('RESIZE')} color={editMode === 'RESIZE' ? 'secondary' : 'default'}>
						<GiResize />
					</IconButton>
				</Tooltip>
				<Tooltip title={t('rotate')}>
					<IconButton onClick={() => setEditMode('ROTATE')} color={editMode === 'ROTATE' ? 'secondary' : 'default'}>
						<FiRotateCw />
					</IconButton>
				</Tooltip>
				<Tooltip title={t('mirror')}>
					<IconButton onClick={() => setEditMode('MIRROR')} color={editMode === 'MIRROR' ? 'secondary' : 'default'}>
						<GoMirror />
					</IconButton>
				</Tooltip>
				<Tooltip title={t('image')}>
					<IconButton onClick={() => setEditMode('IMAGE')} color={editMode === 'IMAGE' ? 'secondary' : 'default'}>
						<FaImage />
					</IconButton>
				</Tooltip>
				<Tooltip title={t('devices')}>
					<IconButton onClick={() => setEditMode('DEVICE')} color={editMode === 'DEVICE' ? 'secondary' : 'default'}>
						<BsFillRocketFill />
					</IconButton>
				</Tooltip>
				<Tooltip title={t('line')}>
					<IconButton onClick={() => setEditMode('LINE')} color={editMode === 'LINE' ? 'secondary' : 'default'}>
						<BsArrowUpRight />
					</IconButton>
				</Tooltip>
			</ButtonGroup>
			<ButtonGroup aria-label="outlined button group">
				<Tooltip title={t('Transformer label')}>
					<IconButton onClick={() => setEditMode('LABEL Transformer')} color={editMode === 'LABEL Transformer' ? 'secondary' : 'default'}>
						<Bs123 />
					</IconButton>
				</Tooltip>
				<Tooltip title={t('input label')}>
					<IconButton onClick={() => setEditMode('LABEL Input')} color={editMode === 'LABEL Input' ? 'secondary' : 'default'}>
						<Bs123 />
					</IconButton>
				</Tooltip>
				<Tooltip title={t('output label')}>
					<IconButton onClick={() => setEditMode('LABEL Output')} color={editMode === 'LABEL Output' ? 'secondary' : 'default'}>
						<Bs123 />
					</IconButton>
				</Tooltip>
				<Tooltip title={t('circle')}>
					<IconButton onClick={() => setEditMode('CIRCLE')} color={editMode === 'CIRCLE' ? 'secondary' : 'default'}>
						<BsCircle />
					</IconButton>
				</Tooltip>
				<Tooltip title={t('rect')}>
					<IconButton onClick={() => setEditMode('RECT')} color={editMode === 'RECT' ? 'secondary' : 'default'}>
						<BsSquare />
					</IconButton>
				</Tooltip>
				<Tooltip title={t('sumTable')}>
					<IconButton onClick={() => setEditMode('SUMTABLE')} color={editMode === 'SUMTABLE' ? 'secondary' : 'default'}>
						<BsTable />
					</IconButton>
				</Tooltip>
				<Tooltip title={t('document')}>
					<IconButton onClick={() => setEditMode('DOCUMENT')} color={editMode === 'DOCUMENT' ? 'secondary' : 'default'}>
						<BsBookFill />
					</IconButton>
				</Tooltip>
				<Tooltip title={t('note')}>
					<IconButton onClick={() => setEditMode('NOTE')} color={editMode === 'NOTE' ? 'secondary' : 'default'}>
						<FaComments />
					</IconButton>
				</Tooltip>
				<Tooltip title={t('container')}>
					<IconButton onClick={() => setEditMode('CONTAINER')} color={editMode === 'CONTAINER' ? 'secondary' : 'default'}>
						<BsBoxSeamFill />
					</IconButton>
				</Tooltip>
				<Tooltip title={t('delete')}>
					<IconButton onClick={() => setEditMode('DELETE')} color={editMode === 'DELETE' ? 'secondary' : 'default'}>
						<BsFillTrashFill />
					</IconButton>
				</Tooltip>
			</ButtonGroup>
		</div>
	);
}

export default WhatIfActions;
