import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import LoginConfig from 'app/admin/login/LoginConfig';
import LogoutConfig from 'app/admin/logout/LogoutConfig';
import PasswordConfig from 'app/admin/password/PasswordConfig';
import AdminConfig from 'app/admin/AdminConfig';

const routeConfigs = [LoginConfig, LogoutConfig, PasswordConfig, AdminConfig];

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: '/',
		component: () => <Redirect to="/profile" />
	}
];

export default routes;
