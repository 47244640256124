import UserTableService from 'app/admin/user/service/UserTableService';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';

const config = {
	key: 'userTable',
	topic: 'user',
	service: UserTableService,
	icon: 'person',
	editFormState: item => `/user/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['filter', 'search', 'refresh', 'new'],
	filterFields: [
		{
			propertyName: 'email',
			propertyType: 'String',
			type: 'LIKE'
		},
		{
			propertyName: 'created',
			propertyType: 'Date',
			type: 'INTERVAL'
		},
		{
			propertyName: 'lastLoginTimestamp',
			propertyType: 'Date',
			type: 'INTERVAL',
			withTime: true
		}
	],
	rowAction: ['modify', 'delete'],
	columns: [
		{
			key: 'name'
		},
		{
			key: 'roleList',
			sortable: false
		},
		{
			key: 'email'
		},
		{
			key: 'created',
			type: 'date'
		},
		{
			key: 'lastLoginTimestamp',
			type: 'dateTime'
		},
		{
			key: 'enabled',
			text: 'Engedélyezve',
			type: 'boolean'
		}
	]
};

function UserTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('userTable', reducer('userTable'))(UserTable);
