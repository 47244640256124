import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import _ from '@lodash';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFormTableBrowserField from 'modules/ui/editform/fields/EditFormTableBrowserField';
import { getSessionString } from 'modules/base/utils/GenericUtils';
import { useWhatIf } from '../component/WhatIfProvider';
import DocumentListTableConfig from './DocumentListTableConfig';

function DocumentItemOperator(props) {
	const { data, figure, showPropertiesDialog, setShowPropertiesDialog } = useWhatIf();
	const { t } = useTranslation(['whatIf', 'validation']);
	const item = figure.document.find(d => d.key === showPropertiesDialog.key);

	return (
		<Dialog open maxWidth="lg" fullWidth>
			<DialogTitle disableTypography className="border-b">
				<div className="flex flex-row w-full">
					<div className="flex mr-24 items-center">{document.name}</div>
				</div>
			</DialogTitle>
			<DialogContent className="p-20">
				<EditFormTableBrowserField fieldConfig={{ key: getSessionString(), config: v => DocumentListTableConfig(item, true) }} />
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button onClick={() => setShowPropertiesDialog(null)}>{t('close')}</Button>
			</DialogActions>
		</Dialog>
	);
}

export default withReducer('documentListTable', reducer('documentListTable'))(DocumentItemOperator);
