/**
 * Authorization Roles
 */
const authRoles = {
	whatif: ['WHATIF'],
	whatifExpert: ['WHATIFEXPERT'],
	whatifGroup: ['WHATIFGROUP'],
	transformer: ['TRANSFORMER'],
	driver: ['DRIVER'],
	driverLog: ['DRIVERLOG'],
	model: ['MODEL'],
	aspect: ['ASPECT'],
	dataset: ['DATASET'],
	optimizer: ['OPTIMIZER'],
	optimizerLog: ['OPTIMIZERLOG'],
	measurementUnit: ['MEASUREMENTUNIT'],
	measurement: ['MEASUREMENT'],
	userProfile: ['USERPROFILE'],
	userAccount: ['USERACCOUNT'],
	userRole: ['USERROLE'],
	optimizationCase: ['OPTIMIZATIONCASE'],
	fittingCase: ['FITTINGCASE'],
	taskManager: ['TASKMANAGER'],
	fitterLog: ['FITTERLOG'],
	pictogram: ['PICTOGRAM'],
	piMeasurementReader: ['PI_MEASUREMENT_READER'],
	scheduler: ['SCHEDULER'],
	onlyGuest: []
};

export default authRoles;
