import TaskAspectCalculateDoneNotification from 'app/admin/notification/TaskAspectCalculateDoneNotification';
import TaskOptimizeDoneNotification from 'app/admin/notification/TaskOptimizeDoneNotification';
import TaskWhatIfCalculateDoneNotification from 'app/admin/notification/TaskWhatIfCalculateDoneNotification';
import UserNotification from 'app/admin/notification/UserNotification';

const NotificationRepo = {
	User: UserNotification,
	TaskAspectCalculateDone: TaskAspectCalculateDoneNotification,
	TaskWhatIfCalculateDone: TaskWhatIfCalculateDoneNotification,
	TaskOptimizeDone: TaskOptimizeDoneNotification
};
export default NotificationRepo;
