import _ from '@lodash';
import { useForm, FormProvider, useFormContext, useWatch } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, InputAdornment } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFormGridComponent from 'modules/ui/editform/subForm/EditFormGridComponent';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import { useWhatIf } from '../component/WhatIfProvider';
import { formatLabelValue } from '../component/WhatIfUtil';

export default function (props) {
	const { t } = useTranslation(['common']);
	const { valueModify, setValueModify, getExperimentAttribute, setExperimentAttributeValue } = useWhatIf();
	const attribute = getExperimentAttribute(valueModify.valueKey);
	const displayAsPercentage = _.get(valueModify, 'displayAsPercentage', false);
	console.log(valueModify);

	const handleValue = () => {
		if (attribute.value) {
			try {
				const v = attribute.value;
				const items = Object.keys(v).map(key => {
					return { name: key, value: displayAsPercentage && v[key] != null && v[key] !== 0 ? v[key] * 100 : v[key] };
				});
				if (items.length > 0) {
					return { items };
				}
			} catch (err) {
				console.log(err);
			}
		}
		return {
			items: [
				{
					name: '',
					value: null
				}
			]
		};
	};

	const methods = useForm({
		defaultValues: handleValue(),
		mode: 'onChange'
	});

	const handleSave = value => {
		const result = {};
		value.items.forEach(element => {
			result[element.name] = displayAsPercentage && element.value != null && element.value !== 0 ? element.value / 100 : element.value;
		});
		setExperimentAttributeValue(valueModify.valueKey, result);
		setValueModify(null);
	};

	return (
		<FormProvider {...methods}>
			<Dialog open fullWidth>
				<DialogTitle disableTypography className="border-b">
					{`ComponentMassFrac: ${valueModify.valueKey}`}
				</DialogTitle>
				<DialogContent className="p-20">
					<EditFormGridComponent
						config={{ topic: 'whatIf' }}
						fieldConfig={{
							key: 'items',
							component: EditFormGridComponent,
							defaultValue: {
								name: null,
								value: null
							},
							fields: [
								{
									key: 'name',
									component: EditFormTextField,
									required: true
								},
								{
									key: 'value',
									component: EditFormTextField,
									type: 'number',
									startAdornment: displayAsPercentage ? <InputAdornment position="start">%</InputAdornment> : undefined,
									required: true
								},
								{
									key: 'minMax',
									component: MinMaxValue,
									attribute,
									item: valueModify,
									isControlledInput: false
								}
							]
						}}
					/>
				</DialogContent>
				<DialogActions className="justify-between border-t p-20">
					<Button onClick={() => setValueModify(null)}>{t('CANCEL')}</Button>
					<SumValue />
					<Button onClick={methods.handleSubmit(handleSave)} color="primary" autoFocus>
						{t('SAVE')}
					</Button>
				</DialogActions>
			</Dialog>
		</FormProvider>
	);
}

function MinMaxValue(props) {
	const { getValues } = useFormContext();
	const name = getValues(`${props.fieldPrefix}.name`);
	const { attribute, item } = props.fieldConfig;
	const minValue = attribute.min_value != null && attribute.min_value[name] != null ? attribute.min_value[name] : null;
	const maxValue = attribute.max_value != null && attribute.max_value[name] != null ? attribute.max_value[name] : null;

	if (minValue == null && maxValue == null) return <></>;

	return (
		<div className="flex flex-col text-red-500 text-xs py-8">
			{minValue != null && <div className="whitespace-nowrap">Min: {formatLabelValue(minValue, item, attribute.uom)}</div>}
			{maxValue != null && <div className="whitespace-nowrap">Max: {formatLabelValue(maxValue, item, attribute.uom)}</div>}
		</div>
	);
}

const useCalcSum = () => {
	const { getValues } = useFormContext();
	useWatch();
	const items = getValues('items');
	let sum = 0;
	items.forEach(item => {
		if (item.value != null) sum += item.value;
	});
	return sum;
};

function SumValue() {
	const sum = useCalcSum();
	if (sum !== 0) return <div>Sum: {sum}</div>;
	return null;
}
