import EnumSelectionDto from '../enum/EnumSelectionDto';
import NamedDto from '../NamedDto';

export class FilterSelectionDto {
	constructor() {
		/** @type {Object.<string,Array<NamedDto>>} */
		this.entityMap = null;
		/** @type {Object.<string,EnumSelectionDto>} */
		this.enumMap = null;
	}

	/**
	 *
	 * @typedef {Object} _FilterSelectionDtoProps
	 * @property {Object.<string,Array<NamedDto>>} entityMap
	 * @property {Object.<string,EnumSelectionDto>} enumMap
	 * @typedef {_FilterSelectionDtoProps} FilterSelectionDtoProps
	 */
	/**
	 *
	 * @param {FilterSelectionDtoProps} props
	 * @returns {FilterSelectionDto}
	 */
	setup(props) {
		this.entityMap = props.entityMap;
		this.enumMap = props.enumMap;
		return this;
	}
}

export default FilterSelectionDto;
