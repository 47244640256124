import EntityValidationResult from 'modules/base/dto/validation/EntityValidationResult';
import GenericTableDto from 'modules/base/dto/table/GenericTableDto';
import GenericTableService from 'modules/base/service/GenericTableService';
import SchedulerTableDto from '../dto/SchedulerTableDto';

class SchedulerTableService extends GenericTableService {
	constructor() {
		super();
		this.CONTROL = 'schedulerTableControl';
	}

	/**
	 * @param {string} session
	 * @param {number} firstResult
	 * @param {number} maxResults
	 * @return {Promise<GenericTableDto<SchedulerTableDto>>}
	 */
	getList(session, firstResult, maxResults) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getList`, {
					session,
					firstResult,
					maxResults
				})
				.then(response => resolve(new GenericTableDto().setup(response.data, SchedulerTableDto)))
				.catch(reject);
		});
	}

	/**
	 * @param {string} session
	 * @param {string} name
	 * @return {Promise<EntityValidationResult>}
	 */
	deleteByName(session, name) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/deleteByName`, {
					session,
					name
				})
				.then(response => resolve(response.data !== null ? new EntityValidationResult().setup(response.data) : null))
				.catch(reject);
		});
	}

	/**
	 * @param {string} name
	 * @param {boolean} enabled
	 * @return {Promise<Object>}
	 */
	updateEnabled(name, enabled) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/updateEnabled`, {
					name,
					enabled
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new SchedulerTableService();
