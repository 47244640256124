import _ from '@lodash';
import { useTranslation } from 'react-i18next';
import { FormControl, Checkbox, FormHelperText, FormControlLabel } from '@material-ui/core';

function EditFormRegistrationStatusField(props) {
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const { value, onChange } = props.field;
	const { error } = props.fieldState;

	const handleChange = v => {
		if (v) {
			onChange('ACTIVE');
		} else {
			onChange('DISABLED');
		}
	};

	return (
		<FormControl error={error}>
			<FormControlLabel
				control={<Checkbox {...props.field} checked={value === 'ACTIVE'} onChange={e => handleChange(e.target.checked)} inputProps={{ 'aria-label': 'primary checkbox' }} id={props.field.name} />}
				label={!_.isUndefined(props.fieldConfig.text) ? props.fieldConfig.text : t(`${props.config.topic}:${props.fieldConfig.key}`)}
			/>
			{error ? <FormHelperText>{error.message}</FormHelperText> : null}
		</FormControl>
	);
}

export default EditFormRegistrationStatusField;
