import _ from '@lodash';
import React, { useEffect, useState } from 'react';
import Select from '@material-ui/core/Select';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputLabel, MenuItem } from '@material-ui/core';

function TableFilterStringEqField(props) {
	const { t } = useTranslation(props.config.topic ? ['common', props.config.topic] : 'common');
	const filterList = useSelector(rootState => rootState[props.config.key].tableBrowserFilter.filterList);
	const filterSelection = useSelector(rootState => rootState[props.config.key].tableBrowserFilter.filterSelection);
	const [value, setValue] = useState([]);
	const [items, setItems] = useState([]);

	useEffect(() => {
		if (props.filterConfig.items) {
			setItems(props.filterConfig.items);
		} else if (typeof filterSelection.enumMap[props.filterConfig.propertyName] !== 'undefined') {
			setItems(filterSelection.enumMap[props.filterConfig.propertyName].items);
		}
	}, [filterSelection, props.filterConfig]);

	useEffect(() => {
		const index = filterList.findIndex(el => el.propertyName === props.filterConfig.propertyName);
		if (index > -1) {
			setValue(filterList[index].value);
		} else {
			setValue([]);
		}
	}, [filterList]);

	useEffect(() => {
		if (value) {
			const filterValue = {
				propertyName: props.filterConfig.propertyName,
				type: props.filterConfig.type,
				value
			};
			props.onFilterChanged(filterValue);
		} else {
			props.onFilterRemoved(props.filterConfig.propertyName);
		}
	}, [value]);

	return (
		<div>
			<InputLabel className="mt-16 w-full">{!_.isUndefined(props.filterConfig.text) ? props.filterConfig.text : t(`${props.config.topic}:${props.filterConfig.propertyName}`)}</InputLabel>
			<Select value={value} onChange={e => setValue(e.target.value)} className="mt-16 w-full">
				<MenuItem value={null}>&nbsp;</MenuItem>
				{items.map(i => {
					return (
						<MenuItem key={i.value} value={i.value}>
							{i.label}
						</MenuItem>
					);
				})}
			</Select>
		</div>
	);
}

export default TableFilterStringEqField;
