import NamedDto from 'modules/base/dto/NamedDto';

export class ExpressionModelInputAttributeEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {number} */
		this.value = null;
		/** @type {number} */
		this.min_value = null;
		/** @type {number} */
		this.max_value = null;
		/** @type {string} */
		this.uom = null;
		/** @type {number} */
		this.orderIndex = null;
	}

	/**
	 * @typedef {Object} _ExpressionModelInputAttributeEditDtoProps
	 * @property {number} value
	 * @property {number} min_value
	 * @property {number} max_value
	 * @property {string} uom
	 * @property {number} orderIndex
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _ExpressionModelInputAttributeEditDtoProps} ExpressionModelInputAttributeEditDtoProps
	 */
	/**
	 *
	 * @param {ExpressionModelInputAttributeEditDtoProps} props
	 * @returns {ExpressionModelInputAttributeEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.value = props.value;
		this.min_value = props.min_value;
		this.max_value = props.max_value;
		this.uom = props.uom;
		this.orderIndex = props.orderIndex;
		return this;
	}
}

export default ExpressionModelInputAttributeEditDto;
