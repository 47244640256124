import { useState, useEffect, useRef } from 'react';
import { Checkbox, TableCell } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

function TableHeaderAllSelectionCheckbox(props) {
	const dispatch = useDispatch();
	const [allChecked, setAllChecked] = useState(false);
	const [indeterminate, setIndeterminate] = useState(false);
	const sessionName = useSelector(rootState => rootState[props.config.key].tableBrowserSession.sessionName);
	const allSelected = useSelector(rootState => rootState[props.config.key].tableBrowserData.allSelected);
	const selected = useSelector(rootState => rootState[props.config.key].tableBrowserData.selected);
	const allCheckedTimeout = useRef(null);
	const allCheckedRef = useRef(allChecked);
	allCheckedRef.current = allChecked;

	useEffect(() => {
		return () => {
			if (allCheckedTimeout.current !== null) clearTimeout(allCheckedTimeout.current);
		};
	}, []);

	const handleSelectAllClick = () => {
		setAllChecked(!allChecked);
		if (allCheckedTimeout.current !== null) clearTimeout(allCheckedTimeout.current);
		allCheckedTimeout.current = setTimeout(() => {
			props.config.service.selectAll(sessionName, allCheckedRef.current).then(() => {
				dispatch({ type: `${props.config.key}/data/setAllSelected`, payload: allCheckedRef.current });
			});
		}, 500);
	};

	useEffect(() => {
		setIndeterminate(!allSelected && selected.length > 0);
		setAllChecked(allSelected);
	}, [allSelected, selected]);

	return (
		<TableCell padding="checkbox">
			<Checkbox indeterminate={indeterminate} checked={allChecked} onChange={() => handleSelectAllClick()} />
		</TableCell>
	);
}

export default TableHeaderAllSelectionCheckbox;
