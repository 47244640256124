import _ from '@lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormControlLabel, FormHelperText, RadioGroup, Radio, FormLabel } from '@material-ui/core';

function EditFormRadioGroupField(props) {
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const { error } = props.fieldState;
	const { value, onChange } = props.field;
	const [items, setItems] = useState(null);

	const updateItems = () => {
		if (_.isFunction(props.fieldConfig.items)) {
			props.fieldConfig.items().then(result => {
				setItems(result);
			});
		} else if (Array.isArray(props.fieldConfig.items)) {
			setItems(props.fieldConfig.items);
		} else {
			setItems([]);
		}
	};

	useEffect(() => {
		updateItems();
	}, [props.fieldConfig]);

	return (
		<FormControl component="fieldset" className="w-full mt-8 mb-16" variant="outlined" error={error}>
			<FormLabel id={`${props.field.name}-label`} component="legend">
				{!_.isUndefined(props.fieldConfig.text) ? props.fieldConfig.text : t(`${props.config.topic}:${props.fieldConfig.key}`)}
			</FormLabel>
			{items != null ? (
				<RadioGroup id={props.field.name} defaultValue={null} value={value} onChange={e => onChange(e.target.value)}>
					{!_.isUndefined(props.fieldConfig.type) && props.fieldConfig.type === 'enum'
						? items.map((item, i) => <FormControlLabel key={item.value} value={item.value} control={<Radio />} label={item.label} />)
						: items.map((item, i) => <FormControlLabel key={item.id} value={item.id} control={<Radio />} label={item.name} />)}
				</RadioGroup>
			) : null}
			{error ? <FormHelperText>{error.message}</FormHelperText> : null}
		</FormControl>
	);
}

export default EditFormRadioGroupField;
