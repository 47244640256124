const transformerEn = {
	transformerTable: 'Transformer',
	transformerEditForm: 'Transformer',
	name: 'Name',
	created: 'Created',
	updated: 'Updated',
	back: 'Back',
	save: 'Save',
	cancel: 'Cancel',
	properties: 'Properties',
	workflowName: 'Name of Workflow',
	transformerName: 'Name of Transformer',
	move: 'Move',
	key: 'Key',
	value: 'Value',
	transform: 'Transform',
	line: 'Line',
	delete: 'Delete',
	select: 'Select',
	itemProperties: 'Properties',
	input_key: 'Input Key',
	output_key: 'Output Key',
	feature_column: 'Feature Column',
	threshold: 'Threshold',
	grouping: 'Grouping',
	group_title: 'Group Title',
	basicInfo: 'Basic Info',
	conversion: 'Conversion',
	selectivity: 'Selectivity',
	stoichiometry: 'Stoichiometry',
	key_title: 'Key Title',
	deleteRow: 'Delete Row',
	addNewRow: 'Add New Row',
	deleteColumn: 'Delete Column',
	addNewColumn: 'Add New Column',
	deleteRowConfirm: 'Do you want to delete this row?',
	deleteColumnConfirm: 'Do you want to delete this column?',
	notUnique: 'This key has already been used.',
	aspectProperties: 'Aspect properties',
	blenderProperties: 'Blender Properties',
	ReformerProperties: 'Reformer Properties',
	dodoProperties: 'Dodo Properties',
	dutyProperties: 'Duty Properties',
	expressionProperties: 'Expression Properties',
	filterZeroProperties: 'Filter Zero Properties',
	fractionatingProperties: 'Fractionating Properties',
	groupByProperty: 'Group by property',
	joinStreamsProperties: 'Join Streams Properties',
	massFlowToMoleFlowProperties: 'Mass Flow to Mole Flow Properties',
	massFracToMassFlowProperties: 'Mass Frac to Mass Flow Properties',
	massFlowToMassFracProperties: 'Mass Flow to Mass Frac Properties',
	mergeComponentsProperties: 'Merge Components Properties',
	moleFlowToMassFlowProperties: 'Mole Flow to Mass Flow Properties',
	reformerProperties: 'Reformer Properties',
	sumUpProperties: 'Sum Up Properties',
	totalProperties: 'Total Properties',
	scalarsToTableProperties: 'Scalars 2 Table Properties',
	tableToScalarsProperties: 'Table 2 Scalars Properties',
	prices: 'Prices',
	efficiency: 'Efficiency',
	output_keys: 'Output keys',
	input_keys: 'Input keys',
	mole_flow_title: 'Mole Flow Title',
	mass_frac_title: 'Mass Frac Title',
	mass_flow_title: 'Mass Flow Title',
	mass_flow_key: 'Mass Flow Key',
	output_component: 'Output Component',
	input_components: 'Input Components',
	component_title: 'Component Title',
	add_keys: 'Add Keys',
	subtract_keys: 'Subtract Keys',
	lineAlreadyConnected: 'Line Already Connected',
	ASPECT: 'Aspect',
	BLENDER: 'Blender',
	DODO: 'Dodo',
	DUTY: 'Duty',
	FILTER_ZERO: 'FilterZero',
	FRACTIONATING: 'Fractionating',
	GROUP_BY_PROPERTY: 'Group By',
	JOIN_STREAMS: 'Join',
	MASSFLOW_TO_MOLEFLOW: 'Mass2Mole',
	MASSFRAC_TO_MASSFLOW: 'Frac2Mass',
	MASSFLOW_TO_MASSFRAC: 'Mass2Frac',
	MERGE_COMPONENTS: 'Merge',
	MOLEFLOW_TO_MASSFLOW: 'Mole2Mass',
	REFORMER: 'Reformer',
	SUM_UP: 'Sum Up',
	TOTAL: 'Total',
	SCALARS_TO_TABLE: 'Scals2Tbl',
	TABLE_TO_SCALARS: 'Tbl2Scals',
	EXPRESSION: 'Expression',
	SWITCH: 'Switch',
	valueKey: 'Value Key',
	valueProperties: 'Value Properties',
	item_title: 'Item Title',
	saveSuccessful: 'Save Successful',
	saveFailed: 'Save failed, all fields is required or name is already in use',
	io: 'I/O',
	valueType: 'Type',
	type: 'Type',
	warningConfirmText: 'Ignoring the warnings, are you sure you want to save?',
	uom: 'UOM',
	expressions: 'Expressions',
	enabled: 'Enabled',
	input_fields: 'Input fields',
	aspect_name: 'Aspect name',
	switchProperties: 'Switch Properties'
};

export default transformerEn;
