import _ from '@lodash';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFormGridComponent from 'modules/ui/editform/subForm/EditFormGridComponent';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';

export function ComponentMassSomethingDialog(props) {
	const { loadValueFrom, loadValueMultiplier, fieldPrefix } = props;
	const { t } = useTranslation(['common']);
	const { getValues } = useFormContext();

	const handleValue = () => {
		if (props.value) {
			try {
				const v = props.value;
				const items = Object.keys(v).map(key => {
					return { name: key, value: v[key] };
				});
				if (items.length > 0) {
					return { items };
				}
			} catch (err) {
				console.log(err);
			}
		}
		return {
			items: [
				{
					name: '',
					value: null
				}
			]
		};
	};

	const methods = useForm({
		defaultValues: handleValue(),
		mode: 'onChange'
	});

	const handleSave = value => {
		const result = {};
		value.items.forEach(element => {
			result[element.name] = element.value;
		});
		props.onSave(result);
	};

	const handleCancel = () => {
		props.onCancel();
	};

	const handleLoadFromValue = () => {
		const v = getValues(fieldPrefix + loadValueFrom);
		if (_.isObject(v)) {
			methods.setValue(
				'items',
				Object.keys(v).map(key => {
					return { name: key, value: _.isNumber(v[key]) ? v[key] * loadValueMultiplier : v[key] };
				})
			);
		}
	};

	return (
		<Dialog open fullWidth>
			<DialogTitle disableTypography className="border-b">
				ComponentMass
			</DialogTitle>
			<DialogContent className="p-20">
				<FormProvider {...methods}>
					<EditFormGridComponent
						config={{ topic: 'model' }}
						fieldConfig={{
							key: 'items',
							component: EditFormGridComponent,
							defaultValue: {
								name: null,
								value: null
							},
							fields: [
								props.nameItems !== null
									? {
											key: 'name',
											component: EditFormSelectField,
											required: true,
											type: 'enum',
											items: props.nameItems
									  }
									: { key: 'name', component: EditFormTextField, required: true },
								{
									key: 'value',
									component: EditFormTextField,
									type: 'number',
									required: true
								}
							]
						}}
					/>
				</FormProvider>
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button
					onClick={() => {
						handleCancel();
					}}
				>
					{t('CANCEL')}
				</Button>
				{loadValueFrom && (
					<Button
						onClick={() => {
							handleLoadFromValue(0.9);
						}}
					>
						{t('load_from_value')}
					</Button>
				)}
				<Button onClick={methods.handleSubmit(handleSave)} color="primary" autoFocus>
					{t('SAVE')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
export default ComponentMassSomethingDialog;
