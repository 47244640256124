import { authRoles } from 'app/auth';
import OpcModelEditForm from './editform/OpcModelEditForm';

export default [
	{
		path: '/model-opc/edit/:id/:driverName?',
		component: OpcModelEditForm,
		auth: authRoles.model
	}
];
