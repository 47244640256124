import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import AspectTableService from '../service/AspectTableService';

const config = {
	key: 'aspectTable',
	topic: 'aspect',
	service: AspectTableService,
	editFormState: item => `/aspect/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['search', 'refresh', 'new'],
	rowAction: ['modify', 'delete'],
	columns: [
		{
			key: 'name'
		}
	]
};

function AspectTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('aspectTable', reducer('aspectTable'))(AspectTable);
