import _ from '@lodash';
import { format } from 'date-fns';
import TableFilterListDto from 'modules/base/dto/table/TableFilterListDto';
import { getSessionString } from 'modules/base/utils/GenericUtils';
import { useEffect, useRef, useState } from 'react';
import WhatIfNoteTableService from '../service/WhatIfNoteTableService';

function NoteItemBox(props) {
	const { data } = props;

	return (
		<div className="flex flex-col gap-2 p-8 pl-0">
			<div className="flex flex-row pl-12">
				<div className="text-stone-400 text-[10px] px-2 font-bold">{data.creator.name}</div>
				<div className="text-stone-400 text-[10px] px-2 py-1">{format(data.created, 'yyyy.MM.dd. HH:mm')}</div>
			</div>

			<div className="max-w-[75%] px-20 py-16 shadow-md rounded-2xl bg-gradient-to-r from-primary to-secondary">
				<div className="text-sm" dangerouslySetInnerHTML={{ __html: data.note.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '<br/>') }} />
			</div>
		</div>
	);
}

function NoteItemList(props) {
	const { noteItemKey, whatIf, updateListRef } = props;
	const [sessionString, setSessionString] = useState(getSessionString());
	const [dataList, setDataList] = useState([]);
	const itemsListRef = useRef(null);

	useEffect(() => {
		WhatIfNoteTableService.getInstance(
			sessionString,
			true,
			new TableFilterListDto().setup({
				list: [
					{
						propertyName: 'whatIf',
						propertyType: 'Long',
						type: 'EQ',
						value: whatIf
					},
					{
						propertyName: 'noteItemKey',
						propertyType: 'String',
						type: 'EQ',
						value: noteItemKey
					}
				],
				activeCount: 2
			})
		).then(resp => {
			updateList();
		});
	}, []);

	const updateList = () => {
		WhatIfNoteTableService.getList(sessionString, 0, 0).then(resp => {
			setDataList(resp.data);
			scrollToBottom();
		});
	};

	updateListRef.current = updateList;

	function scrollToBottom() {
		itemsListRef.current.scrollTop = itemsListRef.current.scrollHeight;
	}

	return (
		<div className="w-full overflow-y-scroll overflow-x-hidden mb-20 flex-1" ref={itemsListRef}>
			<div className="w-full h-full p-8 pl-0 flex flex-col items-start gap-2">{_.isArray(dataList) && dataList.map(data => <NoteItemBox data={data} />)}</div>
		</div>
	);
}

export default NoteItemList;
