import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useWhatIf } from './WhatIfProvider';
import WhatIfService from '../service/WhatIfService';

function LinkToWhatIfDialog() {
	const history = useHistory();
	const { linkToWhatIf, setLinkToWhatIf, mode } = useWhatIf();
	const { t } = useTranslation(['whatIf', 'common']);
	const [loading, setLoading] = useState(null);
	const [whatIf, setWhatIf] = useState(null);

	useEffect(() => {
		setLoading(true);
		WhatIfService.getName(linkToWhatIf).then(resp => {
			setWhatIf(resp);
			setLoading(false);
		});
	}, [linkToWhatIf]);

	if (loading) return <></>;
	return (
		<Dialog open fullWidth>
			<DialogTitle disableTypography className="border-b">
				{t('linkTo')}
			</DialogTitle>
			<DialogContent>
				{whatIf != null && (
					<div className="p-8">
						{t('linkToDialog1')} {whatIf != null && <span className="p-8 font-bold text-center">{whatIf.name}</span>}
					</div>
				)}
				{whatIf != null && <div className="p-8">{t('linkToDialog2')}</div>}
				{whatIf == null && <div className="p-8">{t('linkToError')}</div>}
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				{whatIf != null && (
					<Button
						onClick={() => {
							setLinkToWhatIf(null);
							history.push(mode === 'EXPERT' ? `/what-if-expert/edit/${linkToWhatIf}` : `/what-if/edit/${linkToWhatIf}`);
						}}
					>
						{t('common:YES')}
					</Button>
				)}
				{whatIf != null && (
					<Button
						onClick={() => {
							setLinkToWhatIf(null);
						}}
					>
						{t('common:NO')}
					</Button>
				)}
				{whatIf == null && (
					<Button
						onClick={() => {
							setLinkToWhatIf(null);
						}}
					>
						{t('common:CLOSE')}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}

export default LinkToWhatIfDialog;
