import _ from '@lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import { ThemeProvider } from '@material-ui/core/styles';
import { useState } from 'react';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Button, Icon, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import AspectService from 'app/admin/aspect/service/AspectService';
import TransformerService from 'app/admin/workflow/service/TransformerService';
import OptimizationCaseService from '../service/OptimizationCaseService';

function CreateOptimCaseFromAspect(props) {
	const mainTheme = useSelector(selectMainTheme);
	const { t } = useTranslation('optimizationCase');
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [message, setMessage] = useState(null);

	const schema = yup.object().shape({
		name: yup.string().nullable().required(t('validation:FIELD_REQUIRED')),
		aspectName: yup.string().nullable().required(t('validation:FIELD_REQUIRED')),
		transformerName: yup.string().nullable()
	});
	const methods = useForm({
		defaultValues: { name: null, aspectName: null, transformerName: null },
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const handleLoad = dto => {
		setLoading(true);
		OptimizationCaseService.createFromAspect(dto.name, dto.aspectName, dto.transformerName).then(result => {
			setLoading(false);
			console.log(result);
			if (result === null) {
				dispatch({ type: 'optimizationCaseTable/event/setNeedDataReload' });
				dispatch(showMessage({ message: `Successfully created` }));
				setShowDialog(false);
			} else {
				setMessage(result);
			}
		});
	};

	return (
		<>
			<Button className="whitespace-nowrap mx-8" variant="contained" color="secondary" onClick={() => setShowDialog(true)} startIcon={<Icon className="hidden sm:flex">add</Icon>}>
				Create from aspect
			</Button>
			<ThemeProvider theme={mainTheme}>
				<Dialog open={showDialog} fullWidth>
					<DialogTitle disableTypography className="border-b">
						Create from aspect
					</DialogTitle>
					<DialogContent className="p-20">
						<FormProvider {...methods}>
							{loading && <FuseLoading />}
							{!loading && (
								<>
									<Controller
										control={methods.control}
										name="name"
										render={({ field, fieldState }) => (
											<EditFormTextField
												field={field}
												fieldState={fieldState}
												config={{ topic: 'optimizationCase' }}
												fieldConfig={{
													key: 'name',
													text: t('name'),
													required: true
												}}
											/>
										)}
									/>
									<Controller
										control={methods.control}
										name="aspectName"
										render={({ field, fieldState }) => (
											<EditFormSelectField
												field={field}
												fieldState={fieldState}
												config={{ topic: 'optimizationCase' }}
												fieldConfig={{
													key: 'aspectName',
													text: t('aspectName'),
													required: true,
													type: 'enum',
													items: () => AspectService.getListSelectionEnum()
												}}
											/>
										)}
									/>
									<Controller
										control={methods.control}
										name="transformerName"
										render={({ field, fieldState }) => (
											<EditFormSelectField
												field={field}
												fieldState={fieldState}
												config={{ topic: 'optimizationCase' }}
												fieldConfig={{
													key: 'transformerName',
													text: t('transformerName'),
													type: 'enum',
													items: () => TransformerService.getListSelectionEnum()
												}}
											/>
										)}
									/>
								</>
							)}
							{!loading && message !== null && <p>{message}</p>}
						</FormProvider>
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button
							onClick={() => {
								setShowDialog(false);
							}}
						>
							Close
						</Button>
						<Button onClick={methods.handleSubmit(handleLoad)} disabled={loading}>
							Create
						</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</>
	);
}

export default CreateOptimCaseFromAspect;
