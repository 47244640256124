import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'app/auth/store/userSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import nBossConnection from 'modules/base/service/nBossConnection';

function NbossErrorHandler(props) {
	const dispatch = useDispatch();

	useEffect(() => {
		console.log('nBossConnection.setErrorHandler');
		nBossConnection.setErrorHandler(error => {
			if (typeof error.response.status !== 'undefined') {
				if (error.response.status === 500) dispatch(showMessage({ message: 'Szerverhiba! Kérem próbálja meg később.' }));
				else if (error.response.status === 403) dispatch(logoutUser());
			} else {
				dispatch(showMessage({ message: error }));
			}
		});
	}, []);

	return <>{props.children}</>;
}

export default NbossErrorHandler;
