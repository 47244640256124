import TableCell from '@material-ui/core/TableCell';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

function BooleanTableCell(props) {
	const { t } = useTranslation('common');
	const [label, setLabel] = useState(null);

	useEffect(() => {
		if (props.item[props.config.key] != null) {
			setLabel(props.item[props.config.key] ? 'YES' : 'NO');
		} else {
			setLabel('');
		}
	}, [props.item, props.config]);

	return (
		<TableCell className="p-4 md:p-16" scope="row">
			{t(label)}
		</TableCell>
	);
}

export default BooleanTableCell;
