import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import MeasurementUnitTableService from '../service/MeasurementUnitTableService';

const config = {
	key: 'measurementUnitTable',
	topic: 'measurementUnit',
	service: MeasurementUnitTableService,
	editFormState: item => `/measurement-unit/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['search', 'refresh', 'new'],

	rowAction: ['modify', 'delete'],
	columns: [
		{
			key: 'name'
		},
		{
			key: 'created',
			type: 'dateTime'
		},
		{
			key: 'updated',
			type: 'dateTime'
		}
	]
};

function MeasurementUnitTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('measurementUnitTable', reducer('measurementUnitTable'))(MeasurementUnitTable);
