import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './i18n/en';
import hu from './i18n/hu';
import PiMeasurementReaderTable from './table/PiMeasurementReaderTable';
import PiMeasurementReaderEditForm from './editform/PiMeasurementReaderEditForm';

i18next.addResourceBundle('en', 'piMeasurementReader', en);
i18next.addResourceBundle('hu', 'piMeasurementReader', hu);

export default [
	{
		path: '/pi-measurement-reader/edit/:id/:tabKey?',
		component: PiMeasurementReaderEditForm,
		auth: authRoles.piMeasurementReader
	},
	{
		path: '/pi-measurement-reader/:sessionNameParam?',
		component: PiMeasurementReaderTable,
		auth: authRoles.piMeasurementReader
	}
];
