import { motion } from 'framer-motion';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import DeviceButton from './DeviceButton';
import { useWhatIf } from './WhatIfProvider';

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.background.default
	}
}));

function WhatIfDeviceButtons(props) {
	const classes = useStyles(props);
	const { editMode } = useWhatIf();

	return (
		<motion.div
			initial={editMode === 'DEVICE' ? 'visible' : 'hidden'}
			animate={editMode === 'DEVICE' ? 'visible' : 'hidden'}
			variants={{ visible: { translateX: 0 }, hidden: { translateX: '-100%' } }}
			transition={{ ease: 'easeInOut' }}
			className={clsx(classes.root, 'absolute left-0 top-0 h-full p-20 shadow-inner border-r')}
		>
			<div className="flex flex-col gap-20">
				<DeviceButton title="Szivattyú" imageUrl="/assets/whatIf/device/pump.svg" deviceName="PUMP" />
				<DeviceButton title="Kompresszor" imageUrl="/assets/whatIf/device/compressor.svg" deviceName="COMPRESSOR" />
				<DeviceButton title="Léghűtő" imageUrl="/assets/whatIf/device/air_cooler.svg" deviceName="AIR_COOLER" />
				<DeviceButton title="Csőkemence" imageUrl="/assets/whatIf/device/furnace.svg" deviceName="FURNACE" />
				<DeviceButton title="Hőcserélő" imageUrl="/assets/whatIf/device/heat_exchanger.svg" deviceName="HEAT_EXCHANGER" />
				<DeviceButton title="Desztillációs oszlop" imageUrl="/assets/whatIf/device/distillation_column.svg" deviceName="DISTILLATION_COLUMN" />
			</div>
		</motion.div>
	);
}

export default WhatIfDeviceButtons;
