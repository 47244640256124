import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import ModelService from '../service/ModelService';

export default function () {
	const dispatch = useDispatch();
	const mainTheme = useSelector(selectMainTheme);
	const [showDialog, setShowDialog] = useState(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation(['model', 'common']);
	const schema = yup.object().shape({
		name: yup.string().nullable().required(t('validation:FIELD_REQUIRED')),
		subscriptionKey: yup.string().nullable().required(t('validation:FIELD_REQUIRED')),
		simulationId: yup.string().nullable().required(t('validation:FIELD_REQUIRED'))
	});
	const methods = useForm({
		defaultValues: { name: null, simulationId: '4fade864-be13-ee11-8f6e-000d3a5a70e0', subscriptionKey: 'b61a27e9f4734c02817d5a917379ad50' },
		mode: 'onChange',
		resolver: yupResolver(schema)
	});
	const { handleSubmit, reset } = methods;

	const handleSave = dto => {
		setError(null);
		setLoading(true);
		ModelService.addLummus2BySimulationId(dto.name, dto.simulationId, dto.subscriptionKey).then(result => {
			setLoading(false);
			if (result !== null) {
				setError(result);
			} else {
				setShowDialog(false);
				dispatch({ type: `modelTable/event/setNeedDataReload` });
			}
		});
	};

	return (
		<>
			<Button
				onClick={() => {
					reset();
					setError(null);
					setShowDialog(true);
				}}
				className="whitespace-nowrap mx-8"
				variant="contained"
				color="secondary"
			>
				<span className="flex">{t('lummus2BySimulation')}</span>
			</Button>
			<ThemeProvider theme={mainTheme}>
				<Dialog open={showDialog} fullWidth>
					<DialogTitle disableTypography className="border-b">
						Create a Lummus2 model by Simulation id
					</DialogTitle>
					<DialogContent className="p-20">
						<FormProvider {...methods}>
							{loading && <FuseLoading />}
							{!loading && (
								<>
									<Controller
										control={methods.control}
										name="name"
										render={({ field, fieldState }) => (
											<EditFormTextField
												field={field}
												fieldState={fieldState}
												config={{ topic: 'model' }}
												fieldConfig={{
													key: 'name',
													text: t('name'),
													required: true
												}}
											/>
										)}
									/>
									<Controller
										control={methods.control}
										name="subscriptionKey"
										render={({ field, fieldState }) => (
											<EditFormTextField
												field={field}
												fieldState={fieldState}
												config={{ topic: 'model' }}
												fieldConfig={{
													key: 'subscriptionKey',
													text: t('subscriptionKey'),
													required: true
												}}
											/>
										)}
									/>
									<Controller
										control={methods.control}
										name="simulationId"
										render={({ field, fieldState }) => (
											<EditFormTextField
												field={field}
												fieldState={fieldState}
												config={{ topic: 'model' }}
												fieldConfig={{
													key: 'simulationId',
													text: t('simulationId'),
													required: true
												}}
											/>
										)}
									/>
									{error !== null && <div className="py-8 text-red-600">{error}</div>}
								</>
							)}
						</FormProvider>
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button
							onClick={() => {
								setShowDialog(false);
							}}
							disabled={loading}
						>
							{t('common:CANCEL')}
						</Button>
						<Button onClick={handleSubmit(handleSave)} disabled={loading}>
							{t('common:SAVE')}
						</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</>
	);
}
