import NamedDto from 'modules/base/dto/NamedDto';

export class WhatIfCompareViewGroupEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {number} */
		this.orderIndex = null;
		/** @type {string[]} */
		this.attributeList = null;
		/** @type {string} */
		this.headerColor = null;
	}

	/**
	 * @typedef {Object} _WhatIfCompareViewGroupEditDtoProps
	 * @property {number} orderIndex
	 * @property {string[]} attributeList
	 * @property {string} headerColor
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _WhatIfCompareViewGroupEditDtoProps} WhatIfCompareViewGroupEditDtoProps
	 */
	/**
	 *
	 * @param {WhatIfCompareViewGroupEditDtoProps} props
	 * @returns {WhatIfCompareViewGroupEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.orderIndex = props.orderIndex;
		this.attributeList = props.attributeList != null ? props.attributeList : [];
		this.headerColor = props.headerColor;
		return this;
	}
}

export default WhatIfCompareViewGroupEditDto;
