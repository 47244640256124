import GenericTableDto from 'modules/base/dto/table/GenericTableDto';
import GenericTableService from 'modules/base/service/GenericTableService';
import DriverLogTableDto from '../dto/DriverLogTableDto';

class DriverLogTableService extends GenericTableService {
	constructor() {
		super();
		this.CONTROL = 'driverLogTableControl';
	}

	/**
	 * @param {string} session
	 * @param {number} firstResult
	 * @param {number} maxResults
	 * @return {Promise<GenericTableDto<DriverLogTableDto>>}
	 */
	getList(session, firstResult, maxResults) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getList`, {
					session,
					firstResult,
					maxResults
				})
				.then(response => resolve(new GenericTableDto().setup(response.data, DriverLogTableDto)))
				.catch(reject);
		});
	}

	/**
	 * @param {string} name
	 * @return {Promise<string>}
	 */
	autoConnect(name) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/autoConnect`, {
					name
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} name
	 * @return {Promise<string>}
	 */
	connect(name) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/connect`, {
					name
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} name
	 * @return {Promise<string>}
	 */
	disconnect(name) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/disconnect`, {
					name
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new DriverLogTableService();
