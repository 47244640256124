import _ from '@lodash';
import { useEffect, useState, useRef } from 'react';
import { Stage, Layer, Rect } from 'react-konva';
import PumpDevice from '../device/PumpDevice';
import CompressorDevice from '../device/CompressorDevice';
import AirCoolerDevice from '../device/AirCoolerDevice';
import HeatExchangerDevice from '../device/HeatExchangerDevice';
import DistillationColumnDevice from '../device/DistillationColumnDevice';
import LineItem from '../line/LineItem';
import CircleItem from '../circle/CircleItem';
import RectItem from '../rect/RectItem';
import LabelItem from '../label/LabelItem';
import { WhatIfContext, useWhatIf } from './WhatIfProvider';
import useSize from './UseSize';
import FurnaceDevice from '../device/FurnaceDevice';
import { generateId } from './WhatIfUtil';
import ImageItem from '../image/ImageItem';
import { paperSize } from '../page/WhatIfEditPage';
import SumTableItem from '../sumtable/SumTableItem';
import DocumentItem from '../document/DocumentItem';
import NoteItem from '../note/NoteItem';
import ContainerItem from '../container/ContainerItem';

const scaleBy = 0.75;

function WhatIfView(props) {
	const containerRef = useRef(null);
	const stageRef = useRef(null);
	const backgroundRef = useRef(null);
	const containerSize = useSize(containerRef);
	const { setStage, editMode, setEditMode, deviceMode, figure, setFigure, underEditing, initLine, storeLine, drawLine, endLine, viewMode, valueModify, setValueModify, setShowPropertiesDialog, data } = useWhatIf();
	useEffect(() => {
		setStage(stageRef.current);
	}, [stageRef.current]);
	const handleStageClick = e => {
		//if (e.target === backgroundRef.current) {
		//const clickPos = backgroundRef.current.getRelativePointerPosition();
		const clickPos = stageRef.current.getRelativePointerPosition();
		if (editMode === 'DEVICE') {
			setFigure({
				...figure,
				device: [
					...figure.device,
					{
						key: generateId(),
						name: '',
						layerType: 'DEVICE',
						type: deviceMode,
						x: clickPos.x,
						y: clickPos.y,
						scaleX: 1,
						scaleY: 1,
						rotate: 0
					}
				]
			});
		} else if (editMode === 'LINE') {
			if (underEditing !== null) storeLine(clickPos.x, clickPos.y);
			else initLine(clickPos.x, clickPos.y);
		} else if (editMode === 'CIRCLE') {
			setFigure({
				...figure,
				circle: [
					...figure.circle,
					{
						key: generateId(),
						name: '',
						layerType: 'CIRCLE',
						x: clickPos.x,
						y: clickPos.y,
						radius: 60,
						fill: true,
						fillColor: 'darkGreen',
						borderColor: 'darkGreen',
						scaleX: 1,
						scaleY: 1
					}
				]
			});
		} else if (editMode === 'RECT') {
			setFigure({
				...figure,
				rect: [
					...figure.rect,
					{
						key: generateId(),
						name: '',
						layerType: 'RECT',
						x: clickPos.x,
						y: clickPos.y,
						width: 60,
						height: 60,
						fill: true,
						fillColor: 'darkGreen',
						borderColor: 'darkGreen'
					}
				]
			});
		} else if (editMode.split(' ')[0] === 'LABEL') {
			setFigure({
				...figure,
				label: [
					...figure.label,
					{
						key: generateId(),
						name: editMode + figure.label.length + 1,
						value: null,
						layerType: editMode,
						x: clickPos.x,
						y: clickPos.y,
						fillColor: 'black',
						borderColor: 'white',
						fontSize: 12,
						rotate: 0,
						thousandsSeparator: true,
						decimalPlaces: 4,
						displayAsPercentage: false,
						showUnit: true,
						visible: true,
						icon: null,
						iconSize: 64,
						showLabel: true
					}
				]
			});
			setEditMode('MOVE');
		} else if (editMode === 'IMAGE') {
			const itemKey = generateId();
			setFigure({
				...figure,
				image: [
					...(figure.image || []),
					{
						key: itemKey,
						name: '',
						layerType: 'IMAGE',
						x: clickPos.x,
						y: clickPos.y,
						imageKey: null,
						imageWidth: null,
						imageHeight: null,
						scaleX: 1,
						scaleY: 1,
						rotate: 0
					}
				]
			});
			setShowPropertiesDialog({ type: 'IMAGE', key: itemKey });
		} else if (editMode === 'SUMTABLE') {
			const itemKey = generateId();
			setFigure({
				...figure,
				sumTable: [
					...(figure.sumTable || []),
					{
						key: itemKey,
						name: '',
						valueKeyList: [],
						x: clickPos.x,
						y: clickPos.y,
						fillColor: 'black',
						borderColor: 'white',
						fontSize: 12,
						isNew: true,
						icon: null,
						iconSize: 64,
						showLabel: true,
						tableInDialog: false
					}
				]
			});
			setShowPropertiesDialog({ type: 'SUMTABLE', key: itemKey });
		} else if (editMode === 'DOCUMENT') {
			const itemKey = generateId();
			setFigure({
				...figure,
				document: [
					...(figure.document || []),
					{
						key: itemKey,
						name: 'New documents',
						layerType: 'DOCUMENT',
						x: clickPos.x,
						y: clickPos.y,
						fillColor: 'black',
						borderColor: 'white',
						fontSize: 12,
						isNew: true,
						icon: null,
						iconSize: 64,
						showLabel: true
					}
				]
			});
			setShowPropertiesDialog({ type: 'DOCUMENT', key: itemKey });
		} else if (editMode === 'NOTE') {
			const itemKey = generateId();
			setFigure({
				...figure,
				note: [
					...(figure.note || []),
					{
						key: itemKey,
						name: 'New notes',
						layerType: 'NOTE',
						x: clickPos.x,
						y: clickPos.y,
						fillColor: 'black',
						borderColor: 'white',
						fontSize: 12,
						isNew: true
					}
				]
			});
			setShowPropertiesDialog({ type: 'NOTE', key: itemKey });
		} else if (editMode === 'CONTAINER') {
			const itemKey = generateId();
			setFigure({
				...figure,
				container: [
					...(figure.container || []),
					{
						key: itemKey,
						name: '',
						layerType: 'CONTAINER',
						x: clickPos.x,
						y: clickPos.y,
						fillColor: 'black',
						borderColor: 'white',
						fontSize: 12,
						rotate: 0,
						icon: null,
						iconSize: 64,
						showLabel: true,
						visible: true,
						isNew: true,
						linkTo: null
					}
				]
			});
			setShowPropertiesDialog({ type: 'CONTAINER', key: itemKey });
		} else if (viewMode !== 'EDIT' && valueModify !== null) {
			setValueModify(null);
		}
		//		}
	};

	const handleStageDblClick = e => {
		if (e.target === backgroundRef.current) {
			const clickPos = e.target.getRelativePointerPosition();
			if (editMode === 'LINE' && underEditing !== null) endLine(clickPos.x, clickPos.y);
		}
	};

	const handleStageMouseMove = e => {
		if (editMode === 'LINE' && underEditing !== null) {
			const point = backgroundRef.current.getRelativePointerPosition();
			drawLine(point.x, point.y);
		}
	};

	const updateStagePos = e => {
		if (e.target.getType() === 'Stage') {
			let x = e.target.x();
			let y = e.target.y();
			const realWidth = paperSize.width * figure.stage.scale;
			const realHeight = paperSize.height * figure.stage.scale;
			if (realWidth > containerSize.width) {
				if (x > 0) x = 0;
				else if (x < (realWidth - containerSize.width) * -1) x = (realWidth - containerSize.width) * -1;
			}
			if (realHeight > containerSize.height) {
				if (y > 0) y = 0;
				else if (y < (realHeight - containerSize.height) * -1) y = (realHeight - containerSize.height) * -1;
			}

			setFigure({
				...figure,
				stage: {
					x,
					y,
					scale: figure.stage.scale
				}
			});
			e.target.x(x);
			e.target.y(y);
		}
	};

	const handleWheel = e => {
		const oldScale = stageRef.current.scaleX();
		const pointer = stageRef.current.getPointerPosition();

		const mousePointTo = {
			x: (pointer.x - stageRef.current.x()) / oldScale,
			y: (pointer.y - stageRef.current.y()) / oldScale
		};

		// how to scale? Zoom in? Or zoom out?
		let direction = e.evt.deltaY > 0 ? 1 : -1;

		// when we zoom on trackpad, e.evt.ctrlKey is true
		// in that case lets revert direction
		if (e.evt.ctrlKey) {
			direction = -direction;
		}

		const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

		stageRef.current.scale({ x: newScale, y: newScale });

		const newPos = {
			x: pointer.x - mousePointTo.x * newScale,
			y: pointer.y - mousePointTo.y * newScale
		};
		stageRef.current.position(newPos);

		setFigure({
			...figure,
			stage: {
				x: newPos.x,
				y: newPos.y,
				scale: newScale
			}
		});
	};

	return (
		<div className="w-full h-full overflow-hidden" ref={containerRef}>
			{containerSize && (
				<WhatIfContext.Consumer>
					{value => (
						<Stage
							ref={stageRef}
							x={figure.stage.x}
							y={figure.stage.y}
							scale={{ x: figure.stage.scale, y: figure.stage.scale }}
							className="bg-black"
							width={containerSize.width}
							height={containerSize.height}
							onWheel={e => handleWheel(e)}
							onPointerClick={e => handleStageClick(e)}
							onPointerDblClick={e => handleStageDblClick(e)}
							onPointerMove={e => handleStageMouseMove(e)}
							draggable={editMode === 'MOVE' || editMode === 'NONE'}
							onDragEnd={e => updateStagePos(e)}
						>
							<WhatIfContext.Provider value={value}>
								<Layer>
									<Rect x={0} y={0} width={paperSize.width} height={paperSize.height} fill="white" ref={backgroundRef} />
									{figure.image && figure.image.map(l => <ImageItem key={l.key} item={l} />)}
									{figure.line.map(l => (
										<LineItem key={l.key} item={l} />
									))}
									{figure.circle.map(c => (
										<CircleItem key={c.key} item={c} />
									))}
									{figure.rect.map(r => (
										<RectItem key={r.key} item={r} />
									))}
									{figure.device.map(d => (
										<DeviceItem key={d.key} item={d} />
									))}
									{figure.label.map(d => (
										<LabelItem key={d.key} item={d} />
									))}
									{figure.sumTable && figure.sumTable.map(d => <SumTableItem key={d.key} item={d} />)}
									{figure.document && figure.document.map(d => <DocumentItem key={d.key} item={d} />)}
									{figure.note && figure.note.map(d => <NoteItem key={d.key} item={d} />)}
									{figure.container && figure.container.map(d => <ContainerItem key={d.key} item={d} />)}
								</Layer>
							</WhatIfContext.Provider>
						</Stage>
					)}
				</WhatIfContext.Consumer>
			)}
		</div>
	);
}

function DeviceItem(props) {
	const { item } = props;
	if (item.type === 'PUMP') return <PumpDevice {...props} />;
	if (item.type === 'COMPRESSOR') return <CompressorDevice {...props} />;
	if (item.type === 'AIR_COOLER') return <AirCoolerDevice {...props} />;
	if (item.type === 'FURNACE') return <FurnaceDevice {...props} />;
	if (item.type === 'HEAT_EXCHANGER') return <HeatExchangerDevice {...props} />;
	if (item.type === 'DISTILLATION_COLUMN') return <DistillationColumnDevice {...props} />;
	return <></>;
}
export default WhatIfView;
