const commonHu = {
	YES: 'Igen',
	NO: 'Nem',
	CANCEL: 'Mégse',
	SAVE: 'Mentés',
	LOADING: 'Betöltés...',
	close: 'Bezár',
	load_from_value: 'Kitöltés az értékekből',
	copy: 'Másol',
	notifications: 'Értesítők',
	delete_all: 'Összes törlése',
	emptyNotifications: 'Jelenleg nincs semmilyen értesítő.'
};
export default commonHu;
