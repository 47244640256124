import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormImageInputField from 'modules/ui/editform/fields/EditFormImageInputField';
import EditFormFileInputField from 'modules/ui/editform/fields/EditFormFileInputField';
import EditFormGridComponent from 'modules/ui/editform/subForm/EditFormGridComponent';
import { useParams, withRouter } from 'react-router';
import StorageDriverService from 'app/admin/driver/service/StorageDriverService';
import ExpressionModelEditService from '../service/ExpressionModelEditService';

const config = data => {
	const storageDriverService = new StorageDriverService();
	return {
		key: 'expressionModelEditForm',
		topic: 'model',
		service: ExpressionModelEditService,
		tabList: initData => {
			return [{ key: 'basic' }, { key: 'input_attributes' }, { key: 'output_attributes' }];
		},
		backLink: () => '/model',
		defaultValue: () => ExpressionModelEditService.create(data.driverName),
		fields: initData => {
			const fields = [
				{
					key: 'name',
					component: EditFormTextField,
					required: true,
					readOnly: data.id !== 'new'
				},
				{
					key: 'driver',
					component: EditFormSelectField,
					required: true,
					items: () => storageDriverService.getListSelectionByDriverType('expression')
				},
				{
					key: 'state_file',
					component: EditFormFileInputField,
					required: true
				},
				{
					key: 'image',
					component: EditFormImageInputField,
					required: true
				},
				{
					key: 'input_attributes',
					tab: 'input_attributes',
					component: EditFormGridComponent,
					orderable: true,
					defaultValue: {
						id: null,
						name: null,
						value: null,
						orderIndex: null
					},
					fields: [
						{
							key: 'name',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'value',
							component: EditFormTextField,
							required: true,
							type: 'number'
						},
						{
							key: 'min_value',
							component: EditFormTextField,
							required: true,
							type: 'number'
						},
						{
							key: 'max_value',
							component: EditFormTextField,
							required: true,
							type: 'number'
						},
						{
							key: 'uom',
							component: EditFormTextField,
							required: true
						}
					]
				},
				{
					key: 'output_attributes',
					tab: 'output_attributes',
					component: EditFormGridComponent,
					orderable: true,
					defaultValue: {
						id: null,
						name: null,
						expression: null,
						orderIndex: null
					},
					fields: [
						{
							key: 'name',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'expression',
							component: EditFormTextField
						},
						{
							key: 'uom',
							component: EditFormTextField,
							required: true
						}
					]
				}
			];

			return fields;
		}
	};
};

function ExpressionModelEditForm() {
	const { id, driverName } = useParams();
	const dispatch = useDispatch();

	const myConfig = config({ id, driverName });
	return (
		<EditFormPage
			config={myConfig}
			onSave={result => {
				if (!result.engineSuccessful) dispatch(showMessage({ message: `Engine: ${result.engineMessage}` }));
			}}
		/>
	);
}

export default withRouter(ExpressionModelEditForm);
