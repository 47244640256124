import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './i18n/en';
import hu from './i18n/hu';
import DriverEditForm from './editform/DriverEditForm';
import DriverTable from './table/DriverTable';

i18next.addResourceBundle('en', 'driver', en);
i18next.addResourceBundle('hu', 'driver', hu);

export default [
	{
		path: '/driver/edit/:id',
		component: DriverEditForm,
		auth: authRoles.driver
	},
	{
		path: '/driver/:sessionNameParam?',
		component: DriverTable,
		auth: authRoles.driver
	}
];
