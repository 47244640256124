import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox, FormControlLabel } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import TableFilterRepo from './TableFilterRepo';

function TableBrowserQuerySourceDialog(props) {
	const dispatch = useDispatch();
	const { t } = useTranslation(['tableBrowser', 'common']);
	const [selectedQuerySource, setSelectedQuerySource] = useState([]);
	const [checkAll, setCheckAll] = useState(true);
	const querySourceList = useSelector(rootState => rootState[props.config.key].tableBrowserFilter.querySourceList);

	useEffect(() => {
		if (props.open) {
			if (querySourceList.length > 0) {
				setSelectedQuerySource(querySourceList);
			} else if (props.querySource) {
				setSelectedQuerySource(props.querySource.map(q => q.value));
			}
		}
	}, [props.querySource, querySourceList, props.open]);

	useEffect(() => {
		setCheckAll(props.querySource.length === selectedQuerySource.length);
	}, [selectedQuerySource]);

	const handleFiltering = () => {
		dispatch({
			type: `${props.config.key}/filter/setQuerySourceList`,
			payload: selectedQuerySource
		});
		props.onClose();
	};

	const handleCheckAll = val => {
		setCheckAll(val);
		if (val) {
			setSelectedQuerySource(props.querySource.map(q => q.value));
		} else {
			setSelectedQuerySource([]);
		}
	};

	const handleQuerySourceChange = q => {
		if (selectedQuerySource.indexOf(q) > -1) {
			setSelectedQuerySource(selectedQuerySource.filter(item => item !== q));
		} else {
			setSelectedQuerySource([...selectedQuerySource, ...[q]]);
		}
	};

	return (
		<Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
			<DialogTitle id="form-dialog-title">{t('SEARCH_FIELDS')}</DialogTitle>
			<DialogContent>
				{props.querySource
					? props.querySource.map(q => (
							<div key={q.value}>
								<FormControlLabel control={<Checkbox checked={selectedQuerySource.indexOf(q.value) > -1} onChange={() => handleQuerySourceChange(q.value)} color="primary" />} label={q.label} />
							</div>
					  ))
					: null}
			</DialogContent>
			<DialogActions>
				<div className="flex w-full">
					<FormControlLabel className="flex-1 ml-4" control={<Checkbox checked={checkAll} onChange={() => handleCheckAll(!checkAll)} color="primary" />} label="Mind" />
					<Button onClick={() => props.onClose()}>{t('common:CANCEL')}</Button>
					<Button onClick={() => handleFiltering()} color="primary">
						{t('FILTER')}
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
}

export default TableBrowserQuerySourceDialog;
