import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './i18n/en';
import hu from './i18n/hu';
import TaskManagerTable from './table/TaskManagerTable';

i18next.addResourceBundle('en', 'taskManager', en);
i18next.addResourceBundle('hu', 'taskManager', hu);

export default [
	{
		path: '/task-manager/:sessionNameParam?',
		component: TaskManagerTable,
		auth: authRoles.taskManager
	}
];