import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './i18n/en';
import hu from './i18n/hu';
import FitterLogTable from './table/FitterLogTable';
import FitterLogEditForm from './editform/FitterLogEditForm';

i18next.addResourceBundle('en', 'fitterLog', en);
i18next.addResourceBundle('hu', 'fitterLog', hu);

export default [
	{
		path: '/fitter-log/edit/:id/:tabKey?',
		component: FitterLogEditForm,
		auth: authRoles.fitterLog
	},
	{
		path: '/fitter-log/:sessionNameParam?',
		component: FitterLogTable,
		auth: authRoles.fitterLog
	}
];
