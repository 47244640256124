import nBossConnection from 'modules/base/service/nBossConnection';
import { Tooltip, IconButton, TableCell, Icon } from '@material-ui/core';

function ExportTableDownloadButton(props) {
	return (
		<TableCell className="w-auto text-center" padding="none">
			<Tooltip title="Download">
				<IconButton
					component="a"
					href={props.item && props.item.file !== null ? `${nBossConnection.baseUrl}transformerTableControl/getExport?id=${props.item.id}` : null}
					target="_blank"
					aria-label="fileOpen"
					disabled={props.item && props.item.file === null}
				>
					<Icon fontSize="small">download</Icon>
				</IconButton>
			</Tooltip>
		</TableCell>
	);
}

export default ExportTableDownloadButton;
