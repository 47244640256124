import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useTranslation } from 'react-i18next';
import PlayArrow from '@material-ui/icons/PlayArrow';
import ConfirmDialog from 'modules/ui/component/ConfirmDialog';
import { IconButton, Tooltip, TableCell } from '@material-ui/core';
import TaskManagerService from 'app/admin/taskManager/service/TaskManagerService';

function RunFittingCaseButton(props) {
	const dispatch = useDispatch();
	const { t } = useTranslation('fittingCase');
	const [showConfirm, setShowConfirm] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleRun = () => {
		setLoading(true);
		TaskManagerService.addFittingCase(props.item.name).then(resp => {
			setLoading(false);
			if (resp !== null) {
				dispatch(showMessage({ message: `Engine: ${resp}` }));
			} else {
				dispatch(showMessage({ message: `Running successful.` }));
			}
		});
	};

	return (
		<TableCell className="w-auto text-center" padding="none">
			<Tooltip title={t('run')}>
				<span>
					<IconButton
						onClick={e => {
							setShowConfirm(true);
							e.preventDefault();
						}}
						disabled={loading}
					>
						<PlayArrow fontSize="small" />
					</IconButton>
				</span>
			</Tooltip>
			<ConfirmDialog show={showConfirm} title={t('run')} description={t('runDesc')} onYes={() => handleRun()} onClose={() => setShowConfirm(false)} />
		</TableCell>
	);
}

export default RunFittingCaseButton;
