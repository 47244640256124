import _ from '@lodash';
import { useState, useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useIntersectionObserver } from 'react-intersection-observer-hook';
import { useTranslation } from 'react-i18next';
import { Icon, TextField, Tooltip } from '@material-ui/core';
import ConfirmDialog from 'modules/ui/component/ConfirmDialog';
import IconButton from '@material-ui/core/IconButton';
import { fixDependsOn } from '../EditFormUtils';

function EditFormGridRow(props) {
	const { index, fieldConfig, fieldPrefix, config, field, fieldsLength, addNew, onChangePos } = props;
	const { t } = useTranslation('editform');
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [ref, { entry }] = useIntersectionObserver();
	const isVisible = entry && entry.isIntersecting;
	const [showSkeleton, setShowSkeleton] = useState(true);
	const modifyOnly = fieldConfig.modifyOnly || false;
	const [orderIndex, setOrderIndex] = useState(field.orderIndex + 1);

	useEffect(() => {
		if (isVisible && showSkeleton) setShowSkeleton(false);
	}, [isVisible]);

	const keyList = [];

	fieldConfig.fields.forEach(fieldRow => {
		if (keyList.indexOf(fieldRow.key) === -1) {
			keyList.push(fieldRow.key);
		}
	});

	if (showSkeleton) {
		return (
			<tr className={`${(index + 1) % 2 === 0 ? 'bg-gray-100' : ''}`} ref={ref}>
				<td style={{ height: '72px' }} colSpan={keyList.length + 1}>
					&nbsp;
				</td>
			</tr>
		);
	}

	const strNum = str => {
		const result = Number.parseInt(str, 10);
		if (Number.isNaN(result) || result <= 0) return '';
		return result;
	};

	const handleOrderIndex = () => {
		if (orderIndex === '') setOrderIndex(field.orderIndex + 1);
		else if (orderIndex !== field.orderIndex + 1) {
			onChangePos(orderIndex >= fieldsLength ? fieldsLength - 1 : orderIndex - 1);
		}
	};
	return (
		<tr className={`${(index + 1) % 2 === 0 ? 'bg-gray-100' : ''}`} ref={ref}>
			{fieldConfig.orderable && (
				<td width="1%">
					<div className="flex flex-row my-8">
						{/* <Checkbox checked={selected.indexOf(field._id) > -1} onClick={() => onSelectedChange(field._id)} /> */}
						<TextField
							label="Order"
							variant="outlined"
							value={strNum(orderIndex)}
							onChange={e => setOrderIndex(strNum(e.target.value))}
							onBlur={() => handleOrderIndex()}
							onKeyUp={e => {
								if (e.keyCode && e.keyCode === 13) {
									handleOrderIndex();
								}
							}}
							inputProps={{ style: { width: '60px' } }}
						/>
						<Tooltip title={t('move_up')}>
							<IconButton onClick={() => onChangePos(field.orderIndex - 1)} disabled={index === 0}>
								<Icon className="text-32">keyboard_arrow_up</Icon>
							</IconButton>
						</Tooltip>
						<Tooltip title={t('move_down')}>
							<IconButton onClick={() => onChangePos(field.orderIndex + 1)} disabled={index === fieldsLength - 1}>
								<Icon className="text-32">keyboard_arrow_down</Icon>
							</IconButton>
						</Tooltip>
						<Tooltip title={t('SUB_ADD_NEW')}>
							<IconButton onClick={() => addNew(field._id)}>
								<Icon className="text-32">add</Icon>
							</IconButton>
						</Tooltip>
					</div>
				</td>
			)}
			<EditFormGridRowFields config={config} fieldConfig={fieldConfig} fieldPrefix={fieldPrefix} field={field} />
			{!modifyOnly && (
				<td>
					<Tooltip title={t('SUB_DELETE')}>
						<IconButton onClick={() => setShowDeleteConfirm(true)}>
							<Icon className="text-32">delete</Icon>
						</IconButton>
					</Tooltip>
				</td>
			)}
			<ConfirmDialog show={showDeleteConfirm} title={t('SUB_DELETE')} description={t('SUB_DELETE_MESSAGE')} onYes={() => props.onDelete()} onClose={() => setShowDeleteConfirm(false)} />
		</tr>
	);
}

function EditFormGridRowFields(props) {
	const { config, fieldConfig, fieldPrefix, field } = props;
	const keyList = [];

	fieldConfig.fields.forEach(fieldRow => {
		if (keyList.indexOf(fieldRow.key) === -1) {
			keyList.push(fieldRow.key);
		}
	});

	return keyList.map(key => (
		<td className="flex-1" key={`${fieldPrefix}${key}`}>
			{fieldConfig.fields
				.filter(d => d.key === key)
				.map((fieldRow, index) => (
					<EditFormGridRowField key={`${fieldPrefix}${fieldRow.key}-${index}`} fieldPrefix={fieldPrefix} fieldRow={fieldRow} config={config} field={field} />
				))}
		</td>
	));
}

function EditFormGridRowField(props) {
	const { fieldPrefix, fieldRow, config, field } = props;
	const methods = useFormContext();
	const { control } = methods;

	const watchFields = useWatch({
		name: fixDependsOn(fieldRow.dependsOn, fieldPrefix),
		control
	});

	if (_.isUndefined(fieldRow.visibleIf) || fieldRow.visibleIf(watchFields, methods)) {
		if (_.isUndefined(fieldRow.fields) && fieldRow.isControlledInput !== false) {
			return (
				<Controller
					key={`${fieldPrefix}${fieldRow.key}`}
					name={`${fieldPrefix}${fieldRow.key}`}
					control={control}
					defaultValue={field[fieldRow.key]}
					render={({ field: _field, fieldState }) => <fieldRow.component field={_field} fieldState={fieldState} config={config} fieldConfig={{ ...fieldRow, className: 'my-8' }} fieldPrefix={fieldPrefix} compact />}
				/>
			);
		}
		return <fieldRow.component key={`${fieldPrefix}${fieldRow.key}`} name={`${fieldPrefix}${fieldRow.key}`} config={config} fieldConfig={fieldRow} fieldPrefix={fieldPrefix} field={field} compact />;
	}
	return null;
}

export default EditFormGridRow;
