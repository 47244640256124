import _ from '@lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import PiMeasurementReaderService from 'app/admin/piMeasurementReader/service/PiMeasurementReaderService';
import { useWhatIf } from './WhatIfProvider';

function WhatIfPiMeasurementReaderSelect() {
	const { t } = useTranslation('whatIf');
	const { data, setData } = useWhatIf();
	const schema = yup.object().shape({
		aspect: yup.number().nullable().required(t('validation:FIELD_REQUIRED'))
	});
	const methods = useForm({
		defaultValues: { piMeasurementReader: _.get(data, 'properties.piMeasurementReader', null) },
		mode: 'onChange',
		resolver: yupResolver(schema)
	});
	const handleChange = value => {
		if (!_.isUndefined(value))
			setData({
				...data,
				properties: {
					..._.get(data, 'properties', {}),
					...methods.getValues()
				}
			});
	};

	return (
		<FormProvider {...methods}>
			<div className="flex flex-row">
				<Controller
					control={methods.control}
					name="piMeasurementReader"
					render={({ field, fieldState }) => (
						<EditFormSelectField
							field={field}
							fieldState={fieldState}
							config={{ topic: 'whatIf' }}
							fieldConfig={{
								key: 'piMeasurementReader',
								text: t('piMeasurementReader'),
								type: 'enum',
								onChange: handleChange,
								items: () => PiMeasurementReaderService.getListSelectionEnum()
							}}
						/>
					)}
				/>
			</div>
		</FormProvider>
	);
}

export default WhatIfPiMeasurementReaderSelect;
