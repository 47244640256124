import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import { useParams, withRouter } from 'react-router';
import { summaryDurationUnits, summaryTypes } from 'app/admin/common/component/PiMeasurementReaderQuery';
import EditFormGroupFields from 'modules/ui/editform/subForm/EditFormGroupFields';
import EditFormDateTimeField from 'modules/ui/editform/fields/EditFormDateTimeField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import PiMeasurementReaderService from 'app/admin/piMeasurementReader/service/PiMeasurementReaderService';
import MeasurementService from 'app/admin/measurement/service/MeasurementService';
import UpdateTaskEditService from '../service/UpdateTaskEditService';
import SchedulerCreateMeasurementButton from '../component/SchedulerCreateMeasurementButton';

const config = data => {
	return {
		key: 'updateTaskEditForm',
		text: 'Task',
		topic: 'scheduler',
		service: UpdateTaskEditService,
		fields: initData => {
			const fields = [
				{
					key: 'defInner1Group',
					component: EditFormGroupFields,
					className: 'flex flex-col sm:flex-row',
					fields: [
						{
							key: 'name',
							component: EditFormTextField,
							className: 'mr-8',
							required: true,
							readOnly: true
						},
						{
							key: 'reader_name',
							component: EditFormSelectField,
							className: 'mr-8',
							type: 'enum',
							required: true,
							items: () => PiMeasurementReaderService.getListSelectionEnum()
						},
						{
							key: 'measurement_name',
							component: EditFormSelectField,
							type: 'enum',
							className: 'mr-8',
							required: true,
							filterKey: 'newMeasurementName',
							items: () => MeasurementService.getListSelectionEnum()
						},
						{
							key: 'createMeasurement',
							component: SchedulerCreateMeasurementButton,
							isControlledInput: false
						}
					]
				},
				{
					key: 'defInner2Group',
					component: EditFormGroupFields,
					className: 'flex flex-col sm:flex-row',
					fields: [
						{
							key: 'start_date_time',
							component: EditFormDateTimeField,
							required: true,
							className: 'mr-8'
						},
						{
							key: 'last_date_time',
							component: EditFormDateTimeField
						}
					]
				},
				{
					key: 'defInner3Group',
					component: EditFormGroupFields,
					className: 'flex flex-col sm:flex-row',
					fields: [
						{
							key: 'summary_type',
							component: EditFormSelectField,
							className: 'mr-8',
							type: 'enum',
							items: summaryTypes.map(type => {
								return { label: type, value: type };
							})
						},
						{
							key: 'summary_duration',
							component: EditFormTextField,
							className: 'mr-8',
							type: 'number'
						},
						{
							key: 'summary_duration_unit',
							component: EditFormSelectField,
							type: 'enum',
							items: summaryDurationUnits
						}
					]
				}
			];

			return fields;
		}
	};
};

function UpdateTaskEditForm() {
	const { id, tabKey } = useParams();
	const dispatch = useDispatch();

	const myConfig = config({ id });
	return (
		<EditFormPage
			config={myConfig}
			startTabValue={tabKey}
			onSave={result => {
				if (!result.engineSuccessful) dispatch(showMessage({ message: `Engine: ${result.engineMessage}` }));
			}}
		/>
	);
}

export default withRouter(UpdateTaskEditForm);
