import _ from '@lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import { ThemeProvider } from '@material-ui/core/styles';
import { useState } from 'react';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useFormContext, useForm, Controller, FormProvider } from 'react-hook-form';
import { Button, Icon, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import PiMeasurementReaderService from 'app/admin/piMeasurementReader/service/PiMeasurementReaderService';

function SchedulerCreateMeasurementButton(props) {
	const formPath = props.fieldConfig.formPath || '';
	console.log(formPath);
	const mainTheme = useSelector(selectMainTheme);
	const { t } = useTranslation('scheduler');
	const dispatch = useDispatch();
	const { id } = props;
	const { getValues, setValue } = useFormContext();
	const [loading, setLoading] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [message, setMessage] = useState(null);
	const schema = yup.object().shape({
		measurementName: yup.string().nullable().required(t('validation:FIELD_REQUIRED'))
	});
	const readerName = getValues(`${formPath}reader_name`);
	const methods = useForm({
		defaultValues: { measurementName: null },
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const handleClick = () => {
		const dto = getValues();
		setMessage(null);
		setLoading(false);
		methods.setValue('name', dto.name);
		setShowDialog(true);
	};

	const handleSave = dto => {
		setLoading(true);
		PiMeasurementReaderService.createMeasurement(dto.measurementName, readerName).then(result => {
			setLoading(false);
			if (result === null) {
				dispatch(showMessage({ message: `Successfully created` }));
				setShowDialog(false);
				setValue('newMeasurementName', dto.measurementName);
				setValue(`${formPath}measurement_name`, dto.measurementName);
			} else {
				setMessage(result);
			}
		});
	};

	return (
		<div className="mt-8 mb-16">
			<Button className="whitespace-nowrap mt-8" variant="contained" color="secondary" disabled={!readerName} onClick={() => handleClick()} startIcon={<Icon className="hidden sm:flex">add</Icon>}>
				Create Measurement
			</Button>
			<ThemeProvider theme={mainTheme}>
				<Dialog open={showDialog} fullWidth>
					<DialogTitle disableTypography className="border-b">
						Create Measurement
					</DialogTitle>
					<DialogContent className="p-20">
						<FormProvider {...methods}>
							{loading && <FuseLoading />}
							{!loading && (
								<Controller
									control={methods.control}
									name="measurementName"
									render={({ field, fieldState }) => (
										<EditFormTextField
											field={field}
											fieldState={fieldState}
											config={{ topic: 'scheduler' }}
											fieldConfig={{
												key: 'measurementName',
												text: t('measurementName'),
												required: true
											}}
										/>
									)}
								/>
							)}
							{!loading && message !== null && <p>{message}</p>}
						</FormProvider>
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button
							onClick={() => {
								setShowDialog(false);
							}}
						>
							Close
						</Button>
						<Button onClick={methods.handleSubmit(handleSave)} disabled={loading}>
							Create
						</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</div>
	);
}

export default SchedulerCreateMeasurementButton;
