const editformHu = {
	BACK: 'Vissza',
	SAVE: 'Mentés',
	NEW: 'Új',
	DETAILS: 'részletek',
	NO_DATAS: 'Nincsenek adatok!',
	BACK_TO_PREV_PAGE: 'Vissza az előző oldalra',
	LEAVE_CONFIRM: 'Megerősítés',
	LEAVE_CONFIRM_MESSAGE: 'Az űrlapon mentetlen adatok vannak, továbblépés esetén ezek elvesznek. Biztosan elhagyod az űrlapot?',
	SAVE_SUCCESSFUL: 'Sikeres mentés.',
	REQUEST_EDIT_ACCESS_FAILED: 'Adatok felülírása nem engedélyezett, mert {{activeUserName}} jelenleg szerkeszti.',
	SUB_ADD_NEW: 'Hozzáad',
	SUB_NEW_ITEM: 'Új elem',
	SUB_SELECTED_TO_MOVE: 'Áthelyezendő',
	SUB_SELECTED_TO_MOVE_TARGET: 'Áthelyezés célja',
	SUB_BEFORE: 'Előtte',
	SUB_AFTER: 'Utána',
	SUB_DELETE: 'Törlés',
	SUB_DELETE_MESSAGE: 'Biztosan törli a kiválasztott tételt?',
	SUB_ITEM_SETTINGS: 'beállításai',
	SIZE: 'Méret',
	NOT_SELECTED: 'Nincs kiválasztva.',
	FILE_UPLOADING_ERROR: 'Hiba történt a feltöltés során.',
	FILE_READONLY: 'Fájl feltöltésre nem jogosult',
	SELECT_FILE: 'Fájl kiválasztása...',
	UPLOADING_PROGRESS: 'Feltöltés folyamatban...',
	UPLOADING_DONE: 'Feltöltés kész.',
	DELETE: 'Törlés',
	SELECT_EMPTY: 'Válasszon...',
	move_up: 'Fel',
	move_down: 'Le'
};

export default editformHu;
