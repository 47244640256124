const validationHu = {
	FIELD_REQUIRED: 'Mező kötelező.',
	EMAIL_IS_NOT_VALID: 'E-mail formátuma nem megfelelő.',
	EMAIL_IS_REQUIRED: 'E-mail cím megadása kötelező.',
	PASSWORD_REQUIRED: 'Kérlek add meg a jelszód.',
	PASSWORD_IS_TOO_SHORT: 'A jelszó túl rövid. Minimum 5 karakter hosszúságú kell legyen.',
	PASSWORD_DONT_MATCH: 'A jelszavaknak meg kell egyeznie.',
	AT_LEAST_ONE_REQUIRED: 'Legalább egy szükséges.'
};

export default validationHu;
