import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import ModelTableService from '../service/ModelTableService';
import TableCopyButton from '../component/TableCopyButton';
import ModelService from '../service/ModelService';
import AddModelButton from '../component/AddModelButton';
import Lummus2BySimulation from '../component/Lummus2BySimulation';

const modelEditPath = {
	hysys: 'model-hysys',
	ann: 'model-ann',
	lummus: 'model-lummus',
	lummus2: 'model-lummus2',
	expression: 'model-expression',
	workflow: 'model-workflow',
	opc: 'model-opc'
};

const config = {
	key: 'modelTable',
	topic: 'model',
	service: ModelTableService,
	editFormState: item => `/${modelEditPath[item.driver.driver_type]}/edit/${item ? item.id : 'new'}`,
	rowButtonList: [{ key: 'copy', component: TableCopyButton, service: ModelService }],
	toolbarButtonList: [
		{
			key: 'addNew',
			place: 'before',
			component: AddModelButton
		},
		{
			key: 'lummus2BySimulation',
			place: 'before',
			icon: 'add',
			component: Lummus2BySimulation
		}
	],
	toolbarAction: ['search', 'refresh'],
	rowAction: ['modify', 'delete'],
	columns: [
		{
			key: 'name'
		},
		{
			key: 'type'
		},
		{
			key: 'driver.name'
		}
	]
};

function ModelTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('modelTable', reducer('modelTable'))(ModelTable);
