import _ from '@lodash';
import { useReformer } from './ReformerProvider';
import GridComponent from '../GridComponent';

export default function ReformerSelectivityStoichiometry(props) {
	const methods = useReformer();
	const { type } = props;

	return <GridComponent {...methods} type={type} />;
}
