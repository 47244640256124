import _ from '@lodash';
import NamedDto from 'modules/base/dto/NamedDto';

function fixSketch(sketch) {
	if (sketch != null) {
		if (sketch.experiment != null && sketch.experiment.attributes != null) {
			sketch.experiment.attributes = sketch.experiment.attributes.map(attrib => {
				if (_.isUndefined(attrib.entity)) attrib.entity = 'aspect';
				return attrib;
			});
		}
		if (_.isUndefined(sketch.figure)) {
			sketch.figure = [
				{
					name: 'main',
					parent: null,
					stage: sketch.stage,
					circle: sketch.circle,
					device: sketch.device,
					document: sketch.document,
					image: sketch.image,
					label: sketch.label,
					line: sketch.line,
					note: sketch.note,
					rect: sketch.rect,
					sumTable: sketch.sumTable,
					container: sketch.container
				}
			];
			delete sketch.stage;
			delete sketch.circle;
			delete sketch.device;
			delete sketch.document;
			delete sketch.image;
			delete sketch.label;
			delete sketch.line;
			delete sketch.note;
			delete sketch.rect;
			delete sketch.sumTable;
		}
		if (sketch.figure) {
			sketch.figure = sketch.figure.map(fig => {
				if (fig.label) {
					fig.label = fig.label.map(label => {
						if (label.valueKey && (label.valueKey.startsWith('INPUT.') || label.valueKey.startsWith('OUTPUT.'))) {
							label.valueKey = `aspect.${label.valueKey}`;
						}
						return label;
					});
				}
				if (fig.sumTable) {
					fig.sumTable = fig.sumTable.map(sumTable => {
						if (sumTable.valueKeyList) {
							sumTable.valueKeyList = sumTable.valueKeyList.map(valueKey => {
								if (valueKey.startsWith('INPUT.') || valueKey.startsWith('OUTPUT.')) return `aspect.${valueKey}`;
								return valueKey;
							});
						}
						return sumTable;
					});
				}
				return fig;
			});
		}
		if (sketch.inputAttributes) {
			delete sketch.inputAttributes;
		}
		if (sketch.outputAttributes) {
			delete sketch.outputAttributes;
		}
		if (sketch.uomList) {
			delete sketch.uomList;
		}
	}

	return sketch;
}

export class WhatIfEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {number} */
		this.group = null;
		/** @type {Object} */
		this.sketch = null;
		/** @type {number} */
		this.aspect = null;
		/** @type {number} */
		this.transformer = null;
	}

	/**
	 * @typedef {Object} _WhatIfEditDtoProps
	 * @property {number} group
	 * @property {Object} sketch
	 * @property {number} aspect
	 * @property {number} transformer
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _WhatIfEditDtoProps} WhatIfEditDtoProps
	 */
	/**
	 *
	 * @param {WhatIfEditDtoProps} props
	 * @returns {WhatIfEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.group = props.group;
		this.sketch = fixSketch(props.sketch);
		this.aspect = props.aspect;
		this.transformer = props.transformer;
		return this;
	}
}

export default WhatIfEditDto;
