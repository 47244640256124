import { useTranslation } from 'react-i18next';
import { ButtonGroup, IconButton, Tooltip } from '@material-ui/core';
import { BsArrowsMove, BsArrowUpRight, BsFillTrashFill } from 'react-icons/bs';
import { BiSelection } from 'react-icons/bi';
import { MdCalculate } from 'react-icons/md';
import { GiTransform } from 'react-icons/gi';
import { useWorkflow } from './WorkflowProvider';

function WorkflowActions(props) {
	const { t } = useTranslation('transformer');
	const { editMode, setEditMode } = useWorkflow();

	return (
		<ButtonGroup aria-label="outlined button group">
			<Tooltip title={t('move')}>
				<IconButton onClick={() => setEditMode('MOVE')} color={editMode === 'MOVE' ? 'secondary' : 'default'}>
					<BsArrowsMove />
				</IconButton>
			</Tooltip>
			<Tooltip title={t('select')}>
				<IconButton onClick={() => setEditMode('SELECT')} color={editMode === 'SELECT' ? 'secondary' : 'default'}>
					<BiSelection />
				</IconButton>
			</Tooltip>
			<Tooltip title={t('value')}>
				<IconButton onClick={() => setEditMode('VALUE')} color={editMode === 'VALUE' ? 'secondary' : 'default'}>
					<MdCalculate />
				</IconButton>
			</Tooltip>
			<Tooltip title={t('transform')}>
				<IconButton onClick={() => setEditMode('TRANSFORM')} color={editMode === 'TRANSFORM' ? 'secondary' : 'default'}>
					<GiTransform />
				</IconButton>
			</Tooltip>
			<Tooltip title={t('line')}>
				<IconButton onClick={() => setEditMode('LINE')} color={editMode === 'LINE' ? 'secondary' : 'default'}>
					<BsArrowUpRight />
				</IconButton>
			</Tooltip>
			<Tooltip title={t('delete')}>
				<IconButton onClick={() => setEditMode('DELETE')} color={editMode === 'DELETE' ? 'secondary' : 'default'}>
					<BsFillTrashFill />
				</IconButton>
			</Tooltip>
		</ButtonGroup>
	);
}

export default WorkflowActions;
