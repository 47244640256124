class TableFilterDto {
	constructor() {
		/** @type {string} */
		this.propertyName = null;
		/** @type {string} */
		this.propertyType = null;
		/** @type {string} */
		this.type = null;
		/** @type {any} */
		this.value = null;
		/** @type {boolean} */
		this.permanent = false;
	}

	/**
	 *
	 * @typedef {Object} _TableFilterDtoProps
	 * @property {string} propertyName
	 * @property {string} propertyType
	 * @property {string} type
	 * @property {any} value
	 * @property {boolean} permanent
	 * @typedef {_TableFilterDtoProps} TableFilterDtoProps
	 */
	/**
	 *
	 * @param {TableFilterDtoProps} props
	 * @returns {TableFilterDto}
	 */
	setup(props) {
		this.propertyName = props.propertyName;
		this.propertyType = props.propertyType;
		this.type = props.type;
		this.value = props.value;
		this.permanent = props.permanent || false;
		return this;
	}
}

export default TableFilterDto;
