import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import VariableType from 'app/admin/modelAnn/dto/VariableType';
import EditFormComponentMassSomethingField from 'app/admin/common/component/EditFormComponentMassSomethingField';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormFileInputField from 'modules/ui/editform/fields/EditFormFileInputField';
import EditFormImageInputField from 'modules/ui/editform/fields/EditFormImageInputField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import EditFormGridComponent from 'modules/ui/editform/subForm/EditFormGridComponent';
import { useParams, withRouter } from 'react-router';
import StorageDriverService from 'app/admin/driver/service/StorageDriverService';
import ModelCalculate from 'app/admin/common/component/ModelCalculate';
import HysysModelEditService from '../service/HysysModelEditService';
import LoadHysysInputAttributesButton from '../component/LoadHysysInputAttributesButton';
import LoadHysysAttributeInfoButton from '../component/LoadHysysAttributeInfoButton';
import EditFormComponentListField from '../component/EditFormComponentListField';

const config = data => {
	const storageDriverService = new StorageDriverService();
	return {
		key: 'hysysModelEditForm',
		topic: 'model',
		service: HysysModelEditService,
		noNavigationAfterSave: data.id === 'new',
		noNavigationAfterSaveNewLink: result => `/model-hysys/${result.id}`,
		tabList: initData => {
			return [{ key: 'basic' }, { key: 'input_attributes' }, { key: 'output_attributes' }];
		},
		defaultValue: () => HysysModelEditService.create(data.driverName),
		fields: initData => {
			const fields = [
				{
					key: 'name',
					component: EditFormTextField,
					required: true,
					readOnly: data.id !== 'new'
				},
				{
					key: 'driver',
					component: EditFormSelectField,
					required: true,
					items: () => storageDriverService.getListSelectionByDriverType('hysys')
				},
				{
					key: 'hsc_file',
					component: EditFormFileInputField,
					required: true
				},
				{
					key: 'image',
					component: EditFormImageInputField,
					required: true
				},
				{
					key: 'input_attributes',
					tab: 'input_attributes',
					component: EditFormGridComponent,
					orderable: true,
					defaultValue: {
						id: null,
						name: null,
						type: null,
						spreadsheet: null,
						cell: null,
						value: null,
						min_value: null,
						max_value: null,
						uom: null,
						expression: null,
						stream: null,
						use_uom: false,
						orderIndex: null
					},
					fields: [
						{
							key: 'name',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'type',
							component: EditFormSelectField,
							required: true,
							type: 'enum',
							items: Object.keys(VariableType).map(key => {
								return { label: VariableType[key], value: key };
							})
						},
						{
							key: 'spreadsheet',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'SpreadSheet'
						},
						{
							key: 'cell',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'SpreadSheet'
						},
						{
							key: 'expression',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'Variable'
						},
						{
							key: 'stream',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'ComponentMassFlow' || type === 'ComponentMassFrac'
						},
						{
							key: 'value',
							component: EditFormTextField,
							required: true,
							type: 'number',
							dependsOn: 'type',
							visibleIf: type => type === 'Variable' || type === 'SpreadSheet'
						},
						{
							key: 'value',
							component: EditFormComponentMassSomethingField,
							dependsOn: 'type',
							visibleIf: type => type === 'ComponentMassFrac' || type === 'ComponentMassFlow'
						},
						{
							key: 'min_value',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'Variable' || type === 'SpreadSheet' || type === null,
							type: 'number'
						},
						{
							key: 'min_value',
							component: EditFormComponentMassSomethingField,
							loadValueFrom: 'value',
							loadValueMultiplier: 0.9,
							dependsOn: 'type',
							visibleIf: type => type === 'ComponentMassFrac',
							enumByField: 'components'
						},
						{
							key: 'max_value',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'Variable' || type === 'SpreadSheet' || type === null,
							type: 'number'
						},
						{
							key: 'max_value',
							component: EditFormComponentMassSomethingField,
							loadValueFrom: 'value',
							loadValueMultiplier: 1.1,
							dependsOn: 'type',
							visibleIf: type => type === 'ComponentMassFrac',
							enumByField: 'components'
						},
						{
							key: 'uom',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'SpreadSheet' || type === 'Variable'
						},
						{
							key: 'use_uom',
							component: EditFormCheckboxField,
							dependsOn: 'type',
							visibleIf: type => type === 'SpreadSheet'
						}
					]
				},
				{
					key: 'output_attributes',
					tab: 'output_attributes',
					component: EditFormGridComponent,
					orderable: true,
					defaultValue: {
						id: null,
						name: null,
						type: null,
						spreadsheet: null,
						cell: null,
						uom: null,
						expression: null,
						stream: null,
						use_uom: false,
						orderIndex: null,
						components: []
					},
					fields: [
						{
							key: 'name',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'type',
							component: EditFormSelectField,
							required: true,
							type: 'enum',
							items: Object.keys(VariableType).map(key => {
								return { label: VariableType[key], value: key };
							})
						},
						{
							key: 'spreadsheet',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'SpreadSheet'
						},
						{
							key: 'cell',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'SpreadSheet'
						},

						{
							key: 'expression',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'Variable'
						},
						{
							key: 'stream',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'ComponentMassFlow' || type === 'ComponentMassFrac'
						},
						{
							key: 'components',
							component: EditFormComponentListField,
							required: true,
							multiple: true,
							query: () => new Promise(resolve => resolve([])),
							dependsOn: 'type',
							visibleIf: type => type === 'ComponentMassFlow' || type === 'ComponentMassFrac'
						},
						{
							key: 'uom',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'SpreadSheet' || type === 'Variable'
						},
						{
							key: 'use_uom',
							component: EditFormCheckboxField,
							dependsOn: 'type',
							visibleIf: type => type === 'SpreadSheet'
						}
					]
				}
			];
			return fields;
		}
	};
};

function HysysModelEditForm() {
	const { id, tabKey, driverName } = useParams();
	const dispatch = useDispatch();
	const myConfig = config({ id, driverName });
	return (
		<EditFormPage
			config={myConfig}
			startTabValue={tabKey}
			beforeSaveContent={
				<>
					<LoadHysysInputAttributesButton id={id} />
					<LoadHysysAttributeInfoButton id={id} />
					<ModelCalculate id={id} type="model" />
				</>
			}
			onSave={result => {
				if (!result.engineSuccessful) dispatch(showMessage({ message: `Engine: ${result.engineMessage}` }));
			}}
		/>
	);
}

export default withRouter(HysysModelEditForm);
