import _ from '@lodash';
import EditFormComponentMassSomethingField from 'app/admin/common/component/EditFormComponentMassSomethingField';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import EditFormFreeSoloField from 'modules/ui/editform/fields/EditFormFreeSoloField';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GiConsoleController } from 'react-icons/gi';
import * as yup from 'yup';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';

function TransNameField() {
	const methods = useFormContext();
	return (
		<Controller
			control={methods.control}
			name="name"
			render={({ field, fieldState }) => (
				<EditFormTextField
					field={field}
					fieldState={fieldState}
					config={{ topic: 'transformer' }}
					fieldConfig={{
						key: 'name',
						required: true
					}}
				/>
			)}
		/>
	);
}

function TransOutputKeyOnlyField() {
	const methods = useFormContext();
	return (
		<Controller
			control={methods.control}
			name="output_key"
			render={({ field, fieldState }) => (
				<EditFormTextField
					field={field}
					fieldState={fieldState}
					config={{ topic: 'transformer' }}
					fieldConfig={{
						key: 'output_key',
						required: true
					}}
				/>
			)}
		/>
	);
}

function TransInputKeyField(props) {
	const { index = 0, labelKey = 'input_key' } = props;
	const methods = useFormContext();
	const type = methods.getValues(`input_fields.${index}.type`);
	return (
		<div className="rounded-8 border px-16 pt-10 pb-4 mb-16 flex flex-row">
			<div style={{ width: '120px' }}>
				<Controller
					control={methods.control}
					name={`input_fields.${index}.enabled`}
					render={({ field, fieldState }) => (
						<EditFormCheckboxField
							field={field}
							fieldState={fieldState}
							config={{ topic: 'transformer' }}
							fieldConfig={{
								key: 'enabled',
								required: true
							}}
						/>
					)}
				/>
			</div>
			<Controller
				control={methods.control}
				name={`input_fields.${index}.name`}
				render={({ field, fieldState }) => (
					<EditFormTextField
						field={field}
						fieldState={fieldState}
						config={{ topic: 'transformer' }}
						fieldConfig={{
							key: labelKey,
							className: 'mx-8',
							required: true
						}}
					/>
				)}
			/>
			{type === 'Components' ? (
				<Controller
					control={methods.control}
					name={`input_fields.${index}.value`}
					render={({ field, fieldState }) => (
						<EditFormComponentMassSomethingField
							field={field}
							fieldState={fieldState}
							config={{ topic: 'transformer' }}
							fieldConfig={{
								key: 'value',
								className: 'whitespace-nowrap',
								type: 'number'
							}}
						/>
					)}
				/>
			) : (
				<Controller
					control={methods.control}
					name={`input_fields.${index}.value`}
					render={({ field, fieldState }) => (
						<EditFormTextField
							field={field}
							fieldState={fieldState}
							config={{ topic: 'transformer' }}
							fieldConfig={{
								key: 'value',
								type: 'number'
							}}
						/>
					)}
				/>
			)}
		</div>
	);
}

function TransInputKeysField() {
	const methods = useFormContext();
	return (
		<Controller
			control={methods.control}
			name="input_keys"
			render={({ field, fieldState }) => (
				<EditFormFreeSoloField
					field={field}
					fieldState={fieldState}
					config={{ topic: 'transformer' }}
					fieldConfig={{
						key: 'input_keys',
						multiple: true,
						query: () => new Promise(resolve => resolve([])),
						required: true
					}}
				/>
			)}
		/>
	);
}

function TransInputFieldsPropertiesArray(props) {
	const { type, keysField = ['input_keys'], disableWatch = false } = props;
	const { control } = useFormContext();
	const inputKeys = useWatch({ control, name: keysField, disabled: disableWatch });

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'input_fields',
		keyName: '_id'
	});
	useEffect(() => {
		if (!disableWatch) {
			const _inputKeys = [];
			inputKeys.forEach(inKeys => {
				if (_.isArray(inKeys)) _inputKeys.push(...inKeys);
			});
			if (fields.length < _inputKeys.length) {
				_inputKeys.forEach(inKey => {
					if (fields.findIndex(f => f.name === inKey) === -1) {
						append({ name: inKey, type, enabled: true, value: null });
					}
				});
			} else if (fields.length > _inputKeys.length) {
				fields.forEach((field, i) => {
					if (_inputKeys.findIndex(inKey => field.name === inKey) === -1) {
						remove(i);
					}
				});
			}
		}
	}, [inputKeys]);

	if (fields.length > 0)
		return (
			<div className="rounded-8 border px-16 pt-10 pb-4 mb-16 flex flex-col">
				{fields.map((f, i) => (
					<div className="w-full flex flex-row">
						<div className="flex flex-1 items-center">{f.name}</div>
						<div style={{ width: '120px' }}>
							<Controller
								control={control}
								name={`input_fields.${i}.enabled`}
								render={({ field, fieldState }) => (
									<EditFormCheckboxField
										field={field}
										fieldState={fieldState}
										config={{ topic: 'transformer' }}
										fieldConfig={{
											key: 'enabled',
											required: true
										}}
									/>
								)}
							/>
						</div>
						<div className="flex items-center" style={{ width: '120px' }}>
							{f.type === 'Components' ? (
								<Controller
									control={control}
									name={`input_fields.${i}.value`}
									render={({ field, fieldState }) => (
										<EditFormComponentMassSomethingField
											field={field}
											fieldState={fieldState}
											config={{ topic: 'transformer' }}
											fieldConfig={{
												key: 'value',
												className: 'whitespace-nowrap',
												type: 'number'
											}}
										/>
									)}
								/>
							) : (
								<Controller
									control={control}
									name={`input_fields.${i}.value`}
									render={({ field, fieldState }) => (
										<EditFormTextField
											field={field}
											fieldState={fieldState}
											config={{ topic: 'transformer' }}
											fieldConfig={{
												key: 'value',
												type: 'number'
											}}
										/>
									)}
								/>
							)}
						</div>
					</div>
				))}
			</div>
		);
	return <></>;
}
function TransRadioInputFieldsPropertiesArray(props) {
	const { type, keysField = ['input_keys'], disableWatch = false } = props;
	const { control, setValue, getValues } = useFormContext();
	const inputKeys = useWatch({ control, name: keysField, disabled: disableWatch });

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'input_fields',
		keyName: '_id'
	});

	const uncheckAnotherEnabled = (currentField, methods) => {
		fields.forEach((field, index) => {
			if (`input_fields.${index}.enabled` === currentField.name) {
				methods.setValue(`input_fields.${index}.enabled`, true);
			} else {
				methods.setValue(`input_fields.${index}.enabled`, false);
			}
		});
	};

	useEffect(() => {
		if (!disableWatch) {
			const _inputKeys = [];
			inputKeys.forEach(inKeys => {
				if (_.isArray(inKeys)) _inputKeys.push(...inKeys);
			});
			if (fields.length < _inputKeys.length) {
				_inputKeys.forEach(inKey => {
					if (fields.findIndex(f => f.name === inKey) === -1) {
						if (fields.length === 0) {
							append({ name: inKey, type, enabled: true, value: null });
						} else append({ name: inKey, type, enabled: false, value: null });
					}
				});
			} else if (fields.length > _inputKeys.length) {
				fields.forEach((field, i) => {
					const isDeletedKey = _inputKeys.findIndex(inKey => field.name === inKey) === -1;
					if (isDeletedKey) {
						const isEnabled = getValues(`input_fields.${i}.enabled`);
						if (isEnabled && fields.length > 1) {
							if (i > 0) setValue(`input_fields.${i - 1}.enabled`, true);
							else setValue(`input_fields.${i + 1}.enabled`, true);
						}
						remove(i);
					}
				});
			}
		}
	}, [inputKeys]);

	if (fields.length > 0)
		return (
			<div className="rounded-8 border px-16 pt-10 pb-4 mb-16 flex flex-col">
				{fields.map((f, i) => (
					<div className="w-full flex flex-row" key={f._id}>
						<div className="flex flex-1 items-center">{f.name}</div>
						<div style={{ width: '120px' }}>
							<Controller
								control={control}
								name={`input_fields.${i}.enabled`}
								render={({ field, fieldState }) => (
									<EditFormCheckboxField
										field={field}
										fieldState={fieldState}
										config={{ topic: 'transformer' }}
										fieldConfig={{
											key: 'enabled',
											required: true,
											onChange: (value, fld, methods) => {
												uncheckAnotherEnabled(fld, methods);
											}
										}}
									/>
								)}
							/>
						</div>
					</div>
				))}
			</div>
		);
	return <></>;
}
function TransInputSwitchValueType() {
	const methods = useFormContext();
	return (
		<Controller
			control={methods.control}
			name="value_type"
			render={({ field, fieldState }) => (
				<EditFormSelectField
					field={field}
					fieldState={fieldState}
					config={{ topic: 'transformer' }}
					fieldConfig={{
						key: 'value_type',
						type: 'enum',
						required: true,
						items: [
							{ label: 'Variable', value: 'Variable' },
							{ label: 'Components', value: 'Components' }
						]
					}}
				/>
			)}
		/>
	);
}
function TransOutputKeyField(props) {
	const methods = useFormContext();
	const { index = 0, labelKey = 'output_key' } = props;
	return (
		<Controller
			control={methods.control}
			name={`output_fields.${index}.name`}
			render={({ field, fieldState }) => (
				<EditFormTextField
					field={field}
					fieldState={fieldState}
					config={{ topic: 'transformer' }}
					fieldConfig={{
						key: labelKey,
						required: true
					}}
				/>
			)}
		/>
	);
}

function TransOutputKeysField() {
	const methods = useFormContext();
	return (
		<Controller
			control={methods.control}
			name="output_keys"
			render={({ field, fieldState }) => (
				<EditFormFreeSoloField
					field={field}
					fieldState={fieldState}
					config={{ topic: 'transformer' }}
					fieldConfig={{
						key: 'output_keys',
						multiple: true,
						query: () => new Promise(resolve => resolve([])),
						required: true
					}}
				/>
			)}
		/>
	);
}

function TransFeatureColumnField() {
	const methods = useFormContext();
	return (
		<Controller
			control={methods.control}
			name="feature_column"
			render={({ field, fieldState }) => (
				<EditFormTextField
					field={field}
					fieldState={fieldState}
					config={{ topic: 'transformer' }}
					fieldConfig={{
						key: 'feature_column'
					}}
				/>
			)}
		/>
	);
}

function TransComponentTitleField() {
	const methods = useFormContext();
	return (
		<Controller
			control={methods.control}
			name="component_title"
			render={({ field, fieldState }) => (
				<EditFormTextField
					field={field}
					fieldState={fieldState}
					config={{ topic: 'transformer' }}
					fieldConfig={{
						key: 'component_title'
					}}
				/>
			)}
		/>
	);
}

function TransTextField(props) {
	const methods = useFormContext();
	const { fieldKey, required } = props;
	const labelKey = props.labelKey || fieldKey;
	return (
		<Controller
			control={methods.control}
			name={fieldKey}
			render={({ field, fieldState }) => (
				<EditFormTextField
					field={field}
					fieldState={fieldState}
					config={{ topic: 'transformer' }}
					fieldConfig={{
						key: labelKey,
						required
					}}
				/>
			)}
		/>
	);
}

function TransNumberField(props) {
	const methods = useFormContext();
	const { fieldKey, required } = props;
	return (
		<Controller
			control={methods.control}
			name={fieldKey}
			render={({ field, fieldState }) => (
				<EditFormTextField
					field={field}
					fieldState={fieldState}
					config={{ topic: 'transformer' }}
					fieldConfig={{
						key: fieldKey,
						type: 'number',
						required
					}}
				/>
			)}
		/>
	);
}

function TransValidationName(transformationNameAvailable, itemKey) {
	const { t } = useTranslation('validation');
	return yup
		.string()
		.nullable()
		.required(t('FIELD_REQUIRED'))
		.test({
			name: 'unique',
			exclusive: true,
			params: null,
			message: t('notUnique'),
			test: value => transformationNameAvailable(value, itemKey)
		});
}

function TransValidationIOKey() {
	const { t } = useTranslation('validation');
	return yup
		.array()
		.min(1, t('FIELD_REQUIRED'))
		.required(t('FIELD_REQUIRED'))
		.of(
			yup.object().shape({
				name: yup.string().nullable().required(t('FIELD_REQUIRED'))
			})
		);
}

function TransFixDto(dto) {
	if (dto && dto.json && dto.json.transform) {
		dto.json.transform = dto.json.transform.map(trans => {
			if (trans.input_fields) {
				trans.input_fields = trans.input_fields.map(inp => {
					if (_.isUndefined(inp.enabled)) inp.enabled = true;
					if (_.isUndefined(inp.value)) inp.value = null;
					return inp;
				});
			}
			return trans;
		});
	}
}

export {
	TransNameField,
	TransOutputKeyOnlyField,
	TransInputKeysField,
	TransOutputKeysField,
	TransFeatureColumnField,
	TransComponentTitleField,
	TransTextField,
	TransNumberField,
	TransValidationName,
	TransValidationIOKey,
	TransInputKeyField,
	TransOutputKeyField,
	TransFixDto,
	TransInputFieldsPropertiesArray,
	TransRadioInputFieldsPropertiesArray,
	TransInputSwitchValueType
};
