import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	needDataReload: false,
	tableSessionInitialized: false,
	visibleFiltersDialog: false,
	visibleQuerySourceDialog: false
};

const tableBrowserEventSlice = key =>
	createSlice({
		name: `${key}/event`,
		initialState,
		reducers: {
			setNeedDataReload: (state, action) => {
				state.needDataReload = true;
			},
			dataReloadDone: (state, action) => {
				state.needDataReload = false;
			},
			setTableSessionInitialized: (state, action) => {
				state.tableSessionInitialized = true;
			},
			showFiltersDialog: (state, action) => {
				state.visibleFiltersDialog = true;
			},
			hideFiltersDialog: (state, action) => {
				state.visibleFiltersDialog = false;
			},
			showQuerySourceDialog: (state, action) => {
				state.visibleQuerySourceDialog = true;
			},
			hideQuerySourceDialog: (state, action) => {
				state.visibleQuerySourceDialog = false;
			},
			cleanTableBrowserEventSlice: state => {
				state.tableSessionInitialized = false;
				state.visibleFiltersDialog = false;
				state.needDataReload = false;
			}
		}
	});

export default tableBrowserEventSlice;
