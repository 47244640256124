import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import EditFormValueKeySelect from 'app/admin/whatIf/component/EditFormValueKeySelect';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function EditFormValueKeySelectDialog(props) {
	const { value, ioKeys } = props;
	const { t } = useTranslation(['common']);
	const { getValues } = useFormContext();

	const methods = useForm({
		defaultValues: { valueKeyList: value },
		mode: 'onChange'
	});

	const handleSave = dto => {
		props.onSave(dto.valueKeyList);
	};

	const handleCancel = () => {
		props.onCancel();
	};

	return (
		<Dialog open fullWidth maxWidth="lg">
			<DialogTitle disableTypography className="border-b">
				Value key select
			</DialogTitle>
			<DialogContent className="p-20">
				<FormProvider {...methods}>
					<Controller
						control={methods.control}
						name="valueKeyList"
						render={({ field, fieldState }) => (
							<EditFormValueKeySelect
								field={field}
								fieldState={fieldState}
								config={{ topic: 'whatIf' }}
								fieldConfig={{
									key: 'valueKeyList',
									text: t('valueKeyList'),
									items: ioKeys
								}}
							/>
						)}
					/>
				</FormProvider>
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button
					onClick={() => {
						handleCancel();
					}}
				>
					{t('CANCEL')}
				</Button>
				<Button onClick={methods.handleSubmit(handleSave)} color="primary" autoFocus>
					{t('SAVE')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
