import _ from '@lodash';
import { useState, useEffect } from 'react';
import { useWhatIf } from '../component/WhatIfProvider';
import { formatLabelValue } from '../component/WhatIfUtil';

export default function FloatLabelCompare(props) {
	const { containerRef } = props;
	const [coords, setCoords] = useState({ x: 0, y: 0 });
	const { floatLabelCompare } = useWhatIf();

	useEffect(() => {
		const handleWindowMouseMove = event => {
			setCoords({
				x: event.clientX,
				y: event.clientY
			});
		};
		window.addEventListener('mousemove', handleWindowMouseMove);

		return () => {
			window.removeEventListener('mousemove', handleWindowMouseMove);
		};
	}, []);

	if (!containerRef.current) return <></>;
	const containerCoords = containerRef.current.getBoundingClientRect();

	return (
		<div
			className={`absolute ${floatLabelCompare.diff > 0 ? 'border-red-500' : 'border-green-500'} border-2 bg-white p-8 rounded-xl`}
			style={{ top: `${coords.y - containerCoords.top + 5}px`, left: `${coords.x - containerCoords.left + 5}px` }}
		>
			<table>
				<tbody>
					<tr>
						<th align="left" className="font-bold pr-8">
							Base
						</th>
						<td className="font-mono" align="right">
							{floatLabelCompare.baseLabel}
						</td>
					</tr>
					<tr>
						<th align="left" className="font-bold pr-8">
							Current
						</th>
						<td className="font-mono" align="right">
							{floatLabelCompare.currentLabel}
						</td>
					</tr>
					<tr>
						<th align="left" className="font-bold pr-8">
							Difference
						</th>
						<td className="font-mono">
							<div className="flex flex-row">
								{floatLabelCompare.diffPercent != null && floatLabelCompare.diffPercent !== 0 && (
									<div className={`${floatLabelCompare.diffPercent < 0 ? 'text-green' : 'text-red'} whitespace-nowrap pr-12`}>
										{`${floatLabelCompare.diffPercent > 0 ? '+' : '-'} ${formatLabelValue(Math.abs(floatLabelCompare.diffPercent), {
											thousandsSeparator: true,
											decimalPlaces: 2,
											displayAsPercentage: true,
											showUnit: true
										})}`}
									</div>
								)}
								{floatLabelCompare.diff != null && floatLabelCompare.diff !== 0 && (
									<div className={`${floatLabelCompare.diff < 0 ? 'text-green' : 'text-red'} whitespace-nowrap`}>
										{`(${formatLabelValue(Math.abs(floatLabelCompare.diff), floatLabelCompare.item, floatLabelCompare.item.uom)})`}
									</div>
								)}
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}
