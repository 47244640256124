import { makeStyles } from '@material-ui/core/styles';
import { useStrictMode } from 'react-konva';
import _ from '@lodash';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { Typography, Icon, Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useParams, withRouter, useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useWorkflow } from './WorkflowProvider';
import TransformerEditService from '../service/TransformerEditService';
import WorkflowView from './WorkflowView';
import WorkflowActions from './WorkflowActions';
import WorkflowValueProperties from './items/WorkflowValueProperties';
import WorkflowItemProperties from './items/WorkflowItemProperties';
import ExpressionProperties from './items/expression/ExpressionProperties';
import FilterZeroProperties from './items/filterzero/FilterZeroProperties';
import GroupByPropertyProperties from './items/groupbyproperty/GroupByPropertyProperties';
import MergeComponentsProperties from './items/mergecomponents/MergeComponentsProperties';
import MassFracToMassFlowProperties from './items/massmole/MassFracToMassFlowProperties';
import MassFlowToMoleFlowProperties from './items/massmole/MassFlowToMoleFlowProperties';
import ReformerProperties from './items/reformer/ReformerProperties';
import MoleFlowToMassFlowProperties from './items/massmole/MoleFlowToMassFlowProperties';
import BlenderProperties from './items/blender/BlenderProperties';
import FractionatingProperties from './items/fractionating/FractionatingProperties';
import JoinStreamsProperties from './items/joinstreams/JoinStreamsProperties';
import DodoProperties from './items/dodo/DodoProperties';
import SumUpProperties from './items/sumup/SumUpProperties';
import DutyProperties from './items/duty/DutyProperties';
import TotalProperties from './items/total/TotalProperties';
import ScalarsToTableProperties from './items/scalarsToTable/ScalarsToTableProperties';
import TableToScalarsProperties from './items/tableToScalars/TableToScalarsProperties';
import WorkflowCalculate from './WorkflowCalculate';
import WorkflowOutputValueDialog from './calculate/WorkflowOutputValueDialog';
import WorkflowOutputValueFloat from './calculate/WorkflowOutputValueFloat';
import AspectProperties from './items/aspect/AspectProperties';
import TransformerLoadAspect from './TransformerLoadAspect';
import TransformType from '../dto/TransformType';
import TransformerDebug from './TransformerDebug';
import ValidationFloatMessages from './validation/ValidationFloatMessages';
import MassFlowToMassFracProperties from './items/massmole/MassFlowToMassFracProperties';
import SwitchProperties from './items/switch/SwitchProperties';

export const paperSize = {
	width: 10000,
	height: 10000
};

const defaultData = {
	stage: {
		x: (paperSize.width / 2) * -1,
		y: (paperSize.width / 2) * -1,
		scale: 1
	},
	value: [],
	transform: [],
	line: []
};

const useStyles = makeStyles(theme => ({
	root: {
		'& .container': {
			maxWidth: '100% !important'
		}
	}
}));

function TransformerEditPage(props) {
	useStrictMode(true);
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const { id } = useParams();
	const { data, setData, showPropertiesDialog, setCalculate, selectedCalculate, floatCalculate, floatValidation } = useWorkflow();
	const [dto, setDto] = useState(null);
	const { t } = useTranslation('transformer');
	const containerRef = useRef(null);

	useEffect(() => {
		if (id === 'new') {
			TransformerEditService.create().then(resp => {
				setDto(resp);
				setData(resp.json != null ? resp.json : defaultData);
			});
		} else {
			TransformerEditService.getData(id).then(resp => {
				setDto(resp);
				setData(resp.json !== null ? resp.json : defaultData);
			});
		}
	}, [id]);

	const handleSave = () => {
		const _dto = { ...dto, json: data };
		if (id !== 'new') {
			TransformerEditService.update(dto.id, _dto).then(resp => handleResponse(resp));
		} else {
			TransformerEditService.save(_dto).then(resp => handleResponse(resp));
		}
	};

	const setName = name => {
		setDto({ ...dto, name });
	};

	const handleResponse = resp => {
		if (resp.successful) {
			if (!resp.engineSuccessful) dispatch(showMessage({ message: `Engine: ${resp.engineMessage}` }));
			else dispatch(showMessage({ message: t('saveSuccessful') }));
			history.push('/transformer');
		} else {
			dispatch(showMessage({ message: t('saveFailed') }));
		}
	};

	if (dto !== null)
		return (
			<FusePageCarded
				classes={{
					toolbar: 'p-0',
					header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
					root: classes.root
				}}
				innerScroll
				header={
					<div className="flex flex-1 w-full items-center justify-between">
						<div className="flex flex-col items-start max-w-full min-w-0">
							<motion.div initial={{ x: 20, opacity: 0 }} animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}>
								<Typography className="flex items-center sm:mb-12" component={Link} role="button" to="/transformer" color="inherit">
									<Icon className="text-20">arrow_back</Icon>
									<span className="hidden sm:flex mx-4 font-medium">{t('back')}</span>
								</Typography>
							</motion.div>
							<div className="flex items-center max-w-full">
								<div className="flex flex-col min-w-0 mx-8 sm:mc-16">
									<motion.div initial={{ x: -20 }} animate={{ x: 0, transition: { delay: 0.3 } }}>
										<TextField value={dto.name} onChange={e => (e.target.value.length > 0 ? setName(e.target.value) : setName(null))} className="mt-8 mb-16" required label={t('transformerName')} variant="outlined" />
									</motion.div>
								</div>
							</div>
						</div>
						<div>
							<WorkflowActions />
						</div>
						<motion.div className="flex" initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}>
							<TransformerLoadAspect />
							{dto && <TransformerDebug id={id} dto={dto} onCalculate={calculate => setCalculate(calculate)} />}
							{dto && <WorkflowCalculate id={id} dto={dto} onCalculate={calculate => setCalculate(calculate)} />}
							<Button className="whitespace-nowrap mx-4" variant="contained" color="secondary" onClick={() => handleSave()} startIcon={<Icon className="hidden sm:flex">save</Icon>}>
								{t('save')}
							</Button>
						</motion.div>
					</div>
				}
				content={
					<div className="h-full relative" ref={containerRef}>
						{dto !== null && data !== null && <WorkflowView />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'VALUE' && <WorkflowValueProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === null && <WorkflowItemProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.ASPECT && <AspectProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.FILTER_ZERO && <FilterZeroProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.GROUP_BY_PROPERTY && <GroupByPropertyProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.MERGE_COMPONENTS && <MergeComponentsProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.MASSFRAC_TO_MASSFLOW && <MassFracToMassFlowProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.MASSFLOW_TO_MASSFRAC && <MassFlowToMassFracProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.MASSFLOW_TO_MOLEFLOW && <MassFlowToMoleFlowProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.REFORMER && <ReformerProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.MOLEFLOW_TO_MASSFLOW && <MoleFlowToMassFlowProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.BLENDER && <BlenderProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.FRACTIONATING && <FractionatingProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.JOIN_STREAMS && <JoinStreamsProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.DODO && <DodoProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.SUM_UP && <SumUpProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.DUTY && <DutyProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.TOTAL && <TotalProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.SCALARS_TO_TABLE && <ScalarsToTableProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.TABLE_TO_SCALARS && <TableToScalarsProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.EXPRESSION && <ExpressionProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.SWITCH && <SwitchProperties />}

						{selectedCalculate && <WorkflowOutputValueDialog />}
						{floatCalculate && <WorkflowOutputValueFloat containerRef={containerRef} />}
						{floatValidation && <ValidationFloatMessages containerRef={containerRef} />}
					</div>
				}
			/>
		);
	return <></>;
}

export default withRouter(TransformerEditPage);
