import WorkflowActions from 'app/admin/workflow/component/WorkflowActions';
import { useEditForm } from 'modules/ui/editform/EditFormProvider';

function WorkflowEditFormHeader(props) {
	const { tabValue } = useEditForm();
	if (tabValue === props.workflowTab) {
		return <WorkflowActions />;
	}
	return <></>;
}
export default WorkflowEditFormHeader;
