const OptimizerType = {
	ExhaustiveSearch: 'ExhaustiveSearch',
	GridDescent: 'GridDescent',
	ParticleSwarm: 'ParticleSwarm',
	RandomRestart: 'RandomRestart',
	QuadraticRestart: 'QuadraticRestart',
	DualAnnealing: 'DualAnnealing',
	DifferentialEvolution: 'DifferentialEvolution',
	Shgo: 'Shgo',
	Direct: 'Direct',
	NelderMead: 'NelderMead',
	Powell: 'Powell',
	ConjugateGradient: 'ConjugateGradient',
	BFGS: 'BFGS',
	NewtonCG: 'NewtonCG',
	LBFGSB: 'LBFGSB',
	TruncatedNewton: 'LBFGSB',
	COBYLA: 'COBYLA',
	DogLeg: 'DogLeg',
	TrustNCG: 'TrustNCG',
	NearExactTrustKrylov: 'NearExactTrustKrylov',
	NearExactTrust: 'NearExactTrust',
	BayesianOptimizer: 'BayesianOptimizer'
};

export default OptimizerType;
