import _ from '@lodash';
import { Button, TableCell } from '@material-ui/core';
import ObjectDataDialog from 'app/admin/common/component/ObjectDataDialog';
import { useState } from 'react';

export default function LastResultTableCell(props) {
	const { item } = props;
	const [showDialog, setShowDialog] = useState(false);
	return (
		<TableCell className="w-auto" padding="none">
			{_.isObject(item.last_result) && (
				<Button className="whitespace-nowrap mx-4" variant="contained" color="primary" onClick={() => setShowDialog(true)}>
					Show
				</Button>
			)}
			{showDialog && <ObjectDataDialog title="Last result" data={item.last_result} onClose={() => setShowDialog(false)} />}
		</TableCell>
	);
}
