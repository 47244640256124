import _ from '@lodash';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Paper, TableContainer, Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';

function ObjectDataDialog(props) {
	const { title, data, onClose } = props;
	console.log(data);

	return (
		<Dialog open fullWidth>
			<DialogTitle disableTypography className="border-b">
				{title}
			</DialogTitle>
			<DialogContent className="p-20">
				<TableContainer component={Paper}>
					<Table className="w-full" aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell align="right">Value</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Object.keys(data).map(key =>
								_.isObject(data[key]) ? (
									<>
										<TableRow key={key} colSpan={2} className="font-bold">
											<TableCell component="th" scope="row">
												{key}
											</TableCell>
										</TableRow>
										{Object.keys(data[key]).map(subKey => (
											<TableRow key={key}>
												<TableCell component="th" scope="row" className="pl-32">
													{key}
												</TableCell>
												<TableCell className="font-mono" align="right">
													{data[key][subKey]}
												</TableCell>
											</TableRow>
										))}
									</>
								) : (
									<TableRow key={key}>
										<TableCell component="th" scope="row">
											{key}
										</TableCell>
										<TableCell className="font-mono" align="right">
											{data[key]}
										</TableCell>
									</TableRow>
								)
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button
					onClick={() => {
						onClose();
					}}
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ObjectDataDialog;
