import IdentifiableDto from 'modules/base/dto/IdentifiableDto';

export class OptimizationCaseLimitValueEditDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {string} */
		this.type = null;
		/** @type {number} */
		this.low = null;
		/** @type {number} */
		this.high = null;
		/** @type {number} */
		this.value = null;
	}

	/**
	 * @typedef {Object} _OptimizationCaseLimitValueEditDtoProps
	 * @property {string} type
	 * @property {number} low
	 * @property {number} high
	 * @property {number} value
	 *
	 * @typedef {import("modules/base/dto/IdentifiableDto").IdentifiableDtoProps & _OptimizationCaseLimitValueEditDtoProps} OptimizationCaseLimitValueEditDtoProps
	 */
	/**
	 *
	 * @param {OptimizationCaseLimitValueEditDtoProps} props
	 * @returns {OptimizationCaseLimitValueEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.type = props.type;
		this.low = props.low;
		this.high = props.high;
		this.value = props.value;
		return this;
	}
}

export default OptimizationCaseLimitValueEditDto;
