import _ from '@lodash';
import WorkflowItem from '../WorkflowItem';

export const defaultDefinition = {
	input_fields: [{ name: 'Components', type: 'Components', enabled: true, value: null }],
	output_fields: [
		{ name: 'Components', type: 'Components' },
		{ name: 'MassFlow', type: 'Variable' }
	],
	mass_flow_column: null,
	mass_frac_column: null,
	component_title: null
};

export const converter = function (data) {
	return data;
};

export default function MassFlowToMassFracTransformation(props) {
	return <WorkflowItem {...props} type="MASSFLOW_TO_MASSFRAC" />;
}
