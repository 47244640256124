import _ from '@lodash';
import WorkflowItem, { calcInputPos, calcOutputPos } from '../WorkflowItem';

export const defaultDefinition = {
	name: null,
	input_fields: [],
	output_fields: [{ name: 'Components', type: 'Components' }],
	input_keys: [],
	feature_column: null,
	component_title: null
};

export const converter = function (data) {
	return data;
	/*return {
		...data
		input_fields: data.input_keys.map(key => {
			return { name: key, type: 'Variable' };
		})
	};*/
};

export default function ScalarsToTableTransformation(props) {
	return <WorkflowItem {...props} type="SCALARS_TO_TABLE" />;
}
