import _ from '@lodash';
import { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Paper, TableContainer, Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';
import defaultLabelStyle from 'app/admin/common/component/DefaultLabelStyle';
import LabelNumberValue from 'app/admin/common/component/LabelNumberValue';
import { useWhatIf } from '../component/WhatIfProvider';
import { formatLabelValue } from '../component/WhatIfUtil';

export default function (props) {
	const { compare, outputResultDialog, setOutputResultDialog, getExperimentAttribute } = useWhatIf();
	const [attribute, setAttribute] = useState(null);
	const [result, setResult] = useState([]);

	const parseData = _attribute => {
		const parseResult = [];
		const fields = Object.keys(_attribute.value);
		const compareFields = compare && compare.attributes ? compare.attributes.find(attrib => attrib.io === 'OUTPUT' && attrib.entity === _attribute.entity && attrib.name === _attribute.name) : null;
		for (let i = 0; i < fields.length; i += 1) {
			const value = _attribute.value[fields[i]];
			if (_.isObject(value)) {
				const subFields = Object.keys(value);
				parseResult.push({
					name: fields[i],
					sub: false,
					parent: true,
					count: subFields.length,
					value: null
				});
				for (let j = 0; j < subFields.length; j += 1) {
					parseResult.push({
						name: subFields[j],
						sub: true,
						parent: false,
						value: value[subFields[j]]
					});
				}
			} else {
				const compareValue = compareFields != null && _.isObject(compareFields.value) && _.isNumber(compareFields.value[fields[i]]) ? compareFields.value[fields[i]] : null;
				const diff = _.isNumber(compareValue) && _.isNumber(value) ? value - compareValue : null;
				const diffPercent = diff != null && diff !== 0 && compareValue !== 0 ? diff / compareValue : null;

				parseResult.push({
					name: fields[i],
					sub: false,
					parent: false,
					value,
					compareValue,
					diff,
					diffPercent
				});
			}
		}
		return parseResult;
	};

	useEffect(() => {
		const { valueKey } = outputResultDialog;
		setAttribute(getExperimentAttribute(valueKey));
	}, [outputResultDialog]);

	useEffect(() => {
		if (attribute != null) {
			setResult(parseData(attribute));
		} else {
			setResult([]);
		}
	}, [attribute]);

	return (
		<Dialog open fullWidth>
			<DialogTitle disableTypography className="border-b">
				{outputResultDialog.valueKey}
			</DialogTitle>
			<DialogContent className="p-20">
				<TableContainer component={Paper}>
					<Table className="w-full" aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell align="right">{compare ? 'Base' : 'Value'}</TableCell>
								{compare && <TableCell align="right">Current</TableCell>}
								{compare && <TableCell>Difference</TableCell>}
							</TableRow>
						</TableHead>
						<TableBody>
							{result !== null &&
								result.map(row => (
									<TableRow key={row.name}>
										<TableCell component="th" scope="row" colSpan={row.parent ? 2 : 1} className={row.parent ? 'font-bold' : row.sub ? 'pl-32' : ''}>
											{row.name}
										</TableCell>
										{!row.parent && (
											<>
												<TableCell className="font-mono" align="right">
													{attribute.type !== 'ComponentMassFrac' ? (
														<LabelNumberValue
															value={row.value}
															thousandsSeparator={outputResultDialog.thousandsSeparator || false}
															decimalPlaces={Number.isInteger(outputResultDialog.decimalPlaces) ? outputResultDialog.decimalPlaces : 20}
															displayAsPercentage
														/>
													) : (
														<LabelNumberValue
															value={row.value}
															thousandsSeparator={outputResultDialog.thousandsSeparator || false}
															decimalPlaces={Number.isInteger(outputResultDialog.decimalPlaces) ? outputResultDialog.decimalPlaces : 20}
															displayAsPercentage={outputResultDialog.displayAsPercentage || false}
														/>
													)}
												</TableCell>
												{compare && (
													<TableCell className="font-mono" align="right">
														{attribute.type !== 'ComponentMassFrac' ? (
															<LabelNumberValue
																value={row.compareValue}
																thousandsSeparator={outputResultDialog.thousandsSeparator || false}
																decimalPlaces={Number.isInteger(outputResultDialog.decimalPlaces) ? outputResultDialog.decimalPlaces : 20}
																displayAsPercentage
															/>
														) : (
															<LabelNumberValue
																value={row.compareValue}
																thousandsSeparator={outputResultDialog.thousandsSeparator || false}
																decimalPlaces={Number.isInteger(outputResultDialog.decimalPlaces) ? outputResultDialog.decimalPlaces : 20}
																displayAsPercentage={outputResultDialog.displayAsPercentage || false}
															/>
														)}
													</TableCell>
												)}
												{compare && (
													<TableCell>
														<div className="flex flex-row">
															{row.diffPercent != null && row.diffPercent !== 0 && (
																<div className={`${row.diffPercent < 0 ? 'text-green' : 'text-red'} whitespace-nowrap pr-12`}>
																	{`${row.diffPercent > 0 ? '+' : '-'} ${formatLabelValue(Math.abs(row.diffPercent), {
																		thousandsSeparator: true,
																		decimalPlaces: 2,
																		displayAsPercentage: true,
																		showUnit: true
																	})}`}
																</div>
															)}
															{row.diff != null && row.diff !== 0 && (
																<div className={`${row.diff < 0 ? 'text-green' : 'text-red'} whitespace-nowrap`}>{`(${formatLabelValue(Math.abs(row.diff), defaultLabelStyle(true))})`}</div>
															)}
														</div>
													</TableCell>
												)}
											</>
										)}
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button
					onClick={() => {
						setOutputResultDialog(null);
					}}
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}
