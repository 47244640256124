import _ from 'lodash';
import NamedDto from 'modules/base/dto/NamedDto';
import PiMeasurementReaderFieldEditDto from './PiMeasurementReaderFieldEditDto';

export default class PiMeasurementReaderEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.username = null;
		/** @type {string} */
		this.api_url = null;
		/** @type {string} */
		this.server_id = null;
		/** @type {string} */
		this.time_zone = null;
		/** @type {string} */
		this.password = null;
		/** @type {PiMeasurementReaderFieldEditDto[]} */
		this.fields = null;
	}

	/**
	 * @typedef {Object} _PiMeasurementReaderEditDtoProps
	 * @property {string} username
	 * @property {string} api_url
	 * @property {string} server_id
	 * @property {string} time_zone
	 * @property {string} password
	 * @property {PiMeasurementReaderFieldEditDto[]} fields
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _PiMeasurementReaderEditDtoProps} PiMeasurementReaderEditDtoProps
	 */
	/**
	 *
	 * @param {PiMeasurementReaderEditDtoProps} props
	 * @returns {PiMeasurementReaderEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.username = props.username;
		this.api_url = props.api_url;
		this.server_id = props.server_id;
		this.time_zone = props.time_zone;
		this.password = props.password;
		this.fields = props.fields != null ? props.fields.map(d => new PiMeasurementReaderFieldEditDto().setup(d)) : [];
		return this;
	}
}
