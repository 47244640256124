import _ from '@lodash';
import * as yup from 'yup';
import { Controller, useFormContext } from 'react-hook-form';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import { useTranslation } from 'react-i18next';
import { useWorkflow } from '../../WorkflowProvider';
import { TransComponentTitleField, TransFeatureColumnField, TransInputKeyField, TransNameField, TransOutputKeyField, TransValidationIOKey, TransValidationName } from '../TransformationProperties';
import TransformationPropertiesDialog from '../TransformationPropertiesDialog';

function GroupByPropertyProperties(props) {
	const { data, showPropertiesDialog, transformationNameAvailable } = useWorkflow();
	const { t } = useTranslation(['transformer', 'validation']);
	const item = data.transform.find(d => d.key === showPropertiesDialog.key);

	const schema = yup.object().shape({
		name: TransValidationName(transformationNameAvailable, item.key),
		input_fields: TransValidationIOKey(),
		output_fields: TransValidationIOKey(),
		grouping: yup.string().nullable().required(t('validation:FIELD_REQUIRED'))
	});

	return (
		<TransformationPropertiesDialog schema={schema} titleKey="sumUpProperties">
			<TransNameField />
			<TransInputKeyField />
			<TransOutputKeyField />
			<GroupingField />
			<TransFeatureColumnField />
			<TransComponentTitleField />
		</TransformationPropertiesDialog>
	);
}

function GroupingField() {
	const methods = useFormContext();
	return (
		<Controller
			control={methods.control}
			name="grouping"
			render={({ field, fieldState }) => (
				<EditFormSelectField
					field={field}
					fieldState={fieldState}
					config={{ topic: 'transformer' }}
					fieldConfig={{
						key: 'grouping',
						type: 'enum',
						required: true,
						items: [
							{ label: 'Type 1', value: 'Type 1' },
							{ label: 'NRING', value: 'NRING' },
							{ label: 'CNUM', value: 'CNUM' },
							{ label: 'HYSYSNAME', value: 'HYSYSNAME' },
							{ label: 'CNAME', value: 'CNAME' },
							{ label: 'ShortType 1', value: 'ShortType 1' },
							{ label: 'Type 2', value: 'Type 2' },
							{ label: 'ShortType 2', value: 'ShortType 2' },
							{ label: 'CNAME Gas', value: 'CNAME Gas' }
						]
					}}
				/>
			)}
		/>
	);
}
export default GroupByPropertyProperties;
