import _ from '@lodash';
import { Button } from '@material-ui/core';
import { ViewList } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import CompareViewListDialog from './CompareViewListDialog';
import CompareViewEditDialog from './CompareViewEditDialog';
import { useWhatIf } from '../WhatIfProvider';

export default function CompareViewComponent(props) {
	const { btnType = 'styled' } = props;
	const { data, setData } = useWhatIf();
	const [showListDialog, setShowListDialog] = useState(false);
	const [showEditDialog, setShowEditDialog] = useState(false);
	const [editId, setEditId] = useState(null);

	const handleLoad = d => {
		setData({ ...data, properties: { ..._.get(data, 'properties', {}), compareView: d } });
		setShowListDialog(false);
	};

	return (
		<>
			{btnType === 'styled' && (
				<Button
					className="whitespace-nowrap mx-16"
					variant="contained"
					color="secondary"
					onClick={() => {
						setShowListDialog(true);
					}}
					startIcon={<ViewList className="hidden sm:flex" />}
				>
					Views
				</Button>
			)}
			{btnType === 'simple' && (
				<Button
					onClick={() => {
						setShowListDialog(true);
					}}
				>
					Views
				</Button>
			)}
			{showListDialog && (
				<CompareViewListDialog
					onClose={() => setShowListDialog(false)}
					onLoadView={d => handleLoad(d)}
					onNew={() => {
						setEditId('new');
						setShowListDialog(false);
						setShowEditDialog(true);
					}}
					onModify={id => {
						setEditId(id);
						setShowListDialog(false);
						setShowEditDialog(true);
					}}
				/>
			)}
			{showEditDialog && (
				<CompareViewEditDialog
					editId={editId}
					onCancel={() => {
						setEditId('new');
						setShowEditDialog(false);
						setShowListDialog(true);
					}}
					onSave={() => {
						setEditId(null);
						setShowEditDialog(false);
						setShowListDialog(true);
					}}
				/>
			)}
		</>
	);
}
