import NamedDto from 'modules/base/dto/NamedDto';

export default class DriverLogEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.table_name = null;
		/** @type {string} */
		this.description = null;
	}

	/**
	 * @typedef {Object} _DriverLogEditDtoProps
	 * @property {string} table_name
	 * @property {string} description
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _DriverLogEditDtoProps} DriverLogEditDtoProps
	 */
	/**
	 *
	 * @param {DriverLogEditDtoProps} props
	 * @returns {DriverLogEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.table_name = props.table_name;
		this.description = props.description;
		return this;
	}
}
