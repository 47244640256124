import { authRoles } from 'app/auth';
import UserProfileEditForm from './editform/UserProfileEditForm';

export default [
	{
		path: '/profile/:id?',
		component: UserProfileEditForm,
		auth: authRoles.userProfile
	}
];
