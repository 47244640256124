import { authRoles } from 'app/auth';
import i18next from 'i18next';
import DatasetEditForm from './editform/DatasetEditForm';
import DatasetTable from './table/DatasetTable';
import en from './i18n/en';
import hu from './i18n/hu';

i18next.addResourceBundle('en', 'dataset', en);
i18next.addResourceBundle('hu', 'dataset', hu);

export default [
	{
		path: '/dataset/edit/:id/:tabKey?',
		component: DatasetEditForm,
		auth: authRoles.dataset
	},
	{
		path: '/dataset/:sessionNameParam?',
		component: DatasetTable,
		auth: authRoles.dataset
	}
];
