import UserTableService from 'app/admin/user/service/UserTableService';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import PiMeasurementReaderTableService from '../service/PiMeasurementReaderTableService';

const config = {
	key: 'piMeasurementReaderTable',
	topic: 'piMeasurementReader',
	service: PiMeasurementReaderTableService,
	editFormState: item => `/pi-measurement-reader/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['search', 'refresh', 'new'],
	rowAction: ['modify', 'delete'],
	columns: [
		{
			key: 'name'
		},
		{
			key: 'username'
		},
		{
			key: 'api_url'
		},
		{
			key: 'server_id'
		},
		{
			key: 'time_zone'
		}
	]
};

function PiMeasurementReaderTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('piMeasurementReaderTable', reducer('piMeasurementReaderTable'))(PiMeasurementReaderTable);
