import { useEffect, useState } from 'react';
import { Group, Line, Arrow, Circle } from 'react-konva';
import { useWhatIf } from '../component/WhatIfProvider';

const sensitivity = 6;

function LineItem(props) {
	const { item } = props;
	const { editMode, data, setData, figure, setFigure, underEditing, initLine, endLine } = useWhatIf();
	const [joinPoint, setJoinPoint] = useState(null);
	const [joinPoints, setJoinPoints] = useState([]);
	const isUnderEditing = underEditing !== null && underEditing.key === item.key;

	const handleItemClick = () => {
		if (editMode === 'DELETE') {
			setFigure({
				...figure,
				line: figure.line.filter(d => d.key !== item.key)
			});
		}
	};

	const fillJoinPoints = () => {
		const _joinPoints = [];
		const points = [];
		for (let x = 0; x < item.points.length; x += 2) {
			points.push({
				x: item.points[x],
				y: item.points[x + 1]
			});
		}
		for (let line = 0; line < points.length - 1; line += 1) {
			const start = points[line];
			const end = points[line + 1];
			if (line > 0) _joinPoints.push(start);

			if (start.x === end.x) {
				if (start.y > end.y) {
					for (let pt = end.y; pt <= start.y; pt += 10) {
						const pos = { x: start.x, y: pt };
						_joinPoints.push(pos);
					}
				} else {
					for (let pt = start.y; pt <= end.y; pt += 10) {
						const pos = { x: start.x, y: pt };
						_joinPoints.push(pos);
					}
				}
			} else if (start.x > end.x) {
				for (let pt = end.x; pt <= start.x; pt += 10) {
					const pos = { x: pt, y: start.y };
					_joinPoints.push(pos);
				}
			} else {
				for (let pt = start.x; pt <= end.x; pt += 10) {
					const pos = { x: pt, y: start.y };
					_joinPoints.push(pos);
				}
			}
		}
		setJoinPoints(_joinPoints);
	};

	useEffect(() => {
		if (!isUnderEditing) fillJoinPoints();
		else setJoinPoints([]);
	}, [item]);

	/*
	const handlePointerMove = e => {
		if (editMode === 'LINE') {
			const stage = e.target.getStage();
			const mousePos = stage.getPointerPosition();
			const points = [];
			for (let x = 0; x < item.points.length; x += 2) {
				points.push({
					x: item.points[x],
					y: item.points[x + 1]
				});
			}
			let nearestLinePoint = null;
			for (let line = 0; line < points.length - 1; line += 1) {
				const start = points[line];
				const end = points[line + 1];
				if (start.x === end.x) {
					if (start.y > end.y) {
						for (let pt = end.y; pt <= start.y; pt += 1) {
							const pos = { x: start.x, y: pt };
							nearestLinePoint = distanceBetweenPoints(mousePos, pos, nearestLinePoint);
						}
					} else {
						for (let pt = start.y; pt <= end.y; pt += 1) {
							const pos = { x: start.x, y: pt };
							nearestLinePoint = distanceBetweenPoints(mousePos, pos, nearestLinePoint);
						}
					}
				} else if (start.x > end.x) {
					for (let pt = end.x; pt <= start.x; pt += 1) {
						const pos = { x: pt, y: start.y };
						nearestLinePoint = distanceBetweenPoints(mousePos, pos, nearestLinePoint);
					}
				} else {
					for (let pt = start.x; pt <= end.x; pt += 1) {
						const pos = { x: pt, y: start.y };
						nearestLinePoint = distanceBetweenPoints(mousePos, pos, nearestLinePoint);
					}
				}
			}
			if (nearestLinePoint !== null) setJoinPoint(nearestLinePoint.pos);
		}
	};

	const distanceBetweenPoints = (pt1, pt2, nearestLinePoint) => {
		const a = pt1.x - pt2.x;
		const b = pt1.y - pt2.y;
		const distance = Math.sqrt(a * a + b * b);

		if (nearestLinePoint === null || nearestLinePoint.distance > distance) {
			return {
				distance,
				pos: pt2
			};
		}
		return nearestLinePoint;
	};*/

	const handleJoinPointClick = (pos, i) => {
		if (underEditing !== null) {
			endLine(pos.x, pos.y);
		} else {
			initLine(pos.x, pos.y);
		}
	};

	return (
		<Group onPointerClick={() => handleItemClick()}>
			{!isUnderEditing && <Arrow points={item.points} fill="green" stroke="green" strokeWidth={2} hitStrokeWidth={2 + sensitivity} />}
			{editMode === 'LINE' &&
				joinPoints.map((jp, i) => (
					<Circle
						key={i}
						x={jp.x}
						y={jp.y}
						radius={5}
						hitStrokeWidth={5 + sensitivity}
						fill="darkgreen"
						opacity={joinPoint === i ? 1 : 0}
						onPointerClick={e => handleJoinPointClick(jp, i)}
						onPointerEnter={e => setJoinPoint(i)}
						onPointerLeave={e => setJoinPoint(null)}
					/>
				))}
			{isUnderEditing && <Line points={item.newPoints ? [...item.points, ...item.newPoints] : item.points} stroke="green" strokeWidth={2} listening={false} />}
		</Group>
	);
}

export default LineItem;
