import NamedDto from 'modules/base/dto/NamedDto';
import OptimizerLogFieldEditDto from './OptimizerLogFieldEditDto';

export class OptimizerLogEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.table_name = null;
		/** @type {string} */
		this.description = null;
		/** @type {OptimizerLogFieldEditDto[]} */
		this.fields = null;
	}

	/**
	 * @typedef {Object} _OptimizerLogEditDtoProps
	 * @property {string} table_name
	 * @property {string} description
	 * @property {OptimizerLogFieldEditDto[]} fields
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _OptimizerLogEditDtoProps} OptimizerLogEditDtoProps
	 */
	/**
	 *
	 * @param {OptimizerLogEditDtoProps} props
	 * @returns {OptimizerLogEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.table_name = props.table_name;
		this.description = props.description;
		this.fields = props.fields !== null ? props.fields.map(d => new OptimizerLogFieldEditDto().setup(d)) : [];
		return this;
	}
}

export default OptimizerLogEditDto;
