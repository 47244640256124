import nBossConnection from 'modules/base/service/nBossConnection';

class DriverService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'driverControl';
	}

	/**
	 * @return {Promise<Object[]>}
	 */
	getList() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getList`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<NamedDto[]>}
	 */
	getListSelection() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getListSelection`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} driverType
	 * @return {Promise<NamedDto[]>}
	 */
	getListSelectionByDriverType(driverType) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getListSelectionByDriverType`, {
					driverType
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {number} aspectId
	 * @return {Promise<any>}
	 */
	autoConnectByAspect(aspectId) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/autoConnectByAspect`, {
					aspectId
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new DriverService();
