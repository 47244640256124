import _ from '@lodash';
import WorkflowItem from '../WorkflowItem';

export const defaultDefinition = {
	name: null,
	input_fields: [{ name: 'Components', type: 'Components', enabled: true, value: null }],
	output_fields: [],
	output_keys: [],
	feature_column: null,
	component_title: null
};

export const converter = function (data) {
	return {
		...data,
		output_fields: data.output_keys.map(key => {
			return { name: key, type: 'Variable' };
		})
	};
};

export default function ScalarsToTableTransformation(props) {
	return <WorkflowItem {...props} type="TABLE_TO_SCALARS" />;
}
