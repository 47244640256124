import { Rect } from 'react-konva';
import { useWorkflow } from '../WorkflowProvider';
import { findTransformValueInCalculate } from '../WorkflowUtil';

export default function WorkflowItemOutputValue(props) {
	const { calculate, setSelectedCalculate, setFloatCalculate } = useWorkflow();
	const { transformer, name, width, height, x, y } = props;

	const calc = findTransformValueInCalculate(calculate, transformer, name);

	if (calc == null) return <></>;

	return (
		<>
			<Rect
				onPointerEnter={e => {
					setFloatCalculate({ name, value: calc });
				}}
				onPointerLeave={e => {
					setFloatCalculate(null);
				}}
				onPointerClick={() => {
					setSelectedCalculate({ name, value: calc });
					setFloatCalculate(null);
				}}
				fill="green"
				cornerRadius={10}
				x={x}
				y={y}
				width={width}
				height={height}
				opacity={0.3}
			/>
		</>
	);
}
