import _ from '@lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFormFileInputField from 'modules/ui/editform/fields/EditFormFileInputField';
import { useCallback, useEffect } from 'react';
import nBossConnection from 'modules/base/service/nBossConnection';
import { useWhatIf } from '../component/WhatIfProvider';

function ImageItemProperties(props) {
	const { data, setData, figure, setFigure, showPropertiesDialog, setShowPropertiesDialog } = useWhatIf();
	const { t } = useTranslation(['whatIf', 'validation']);
	const item = figure.image.find(d => d.key === showPropertiesDialog.key);

	const schema = yup.object().shape({
		imageKey: yup.string().nullable().required(t('validation:FIELD_REQUIRED'))
	});

	const methods = useForm({
		defaultValues: item,
		mode: 'onChange',
		resolver: yupResolver(schema)
	});
	const { setValue, watch } = methods;
	const imageKey = watch('imageKey', null);

	const handleSave = value => {
		setFigure({
			...figure,
			image: figure.image.map(c => (c.key === item.key ? value : c))
		});
		setShowPropertiesDialog(null);
	};

	const handleCancel = () => {
		if (item.imageKey === null)
			setFigure({
				...figure,
				image: figure.image.filter(d => d.key !== item.key)
			});
		setShowPropertiesDialog(null);
	};

	const imgLoadCb = useCallback(
		(err, img) => {
			if (err === null) {
				console.log(`w: ${img.naturalWidth}, h: ${img.naturalHeight}`);
				setValue('imageWidth', img.naturalWidth);
				setValue('imageHeight', img.naturalHeight);
				setValue('scaleX', 1);
				setValue('scaleY', 1);
				setValue('rotate', 0);
			} else {
				setValue('imageWidth', null);
				setValue('imageHeight', null);
			}
		},
		[setValue]
	);

	useEffect(() => {
		if (imageKey !== null) {
			const img = new Image();
			img.onload = () => imgLoadCb(null, img);
			img.onerror = imgLoadCb;
			img.src = `${nBossConnection.baseUrl}/fileRepository/getFile?key=${imageKey}`;
		} else {
			setValue('imageWidth', null);
			setValue('imageHeight', null);
		}
	}, [imageKey]);

	return (
		<Dialog open fullWidth>
			<DialogTitle disableTypography className="border-b">
				{t('imageProperties')}
			</DialogTitle>
			<DialogContent className="p-20">
				<FormProvider {...methods}>
					<Controller
						control={methods.control}
						name="imageKey"
						render={({ field, fieldState }) => (
							<EditFormFileInputField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'whatIf' }}
								fieldConfig={{
									key: 'imageKey',
									text: t('imageKey'),
									accept: 'image/png, image/jpeg',
									required: true
								}}
							/>
						)}
					/>
				</FormProvider>
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button
					onClick={() => {
						handleCancel();
					}}
				>
					{t('cancel')}
				</Button>
				<Button onClick={methods.handleSubmit(handleSave)} color="primary" autoFocus>
					{t('save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
export default ImageItemProperties;
