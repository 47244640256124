import nBossConnection from 'modules/base/service/nBossConnection';
import TableFilterListDto from 'modules/base/dto/table/TableFilterListDto';
import WhatIfDocumentTableService from '../service/WhatIfDocumentTableService';
import DocumentListTableModifyButton from './DocumentListTableModifyButton';
import DocumentListTableSwitchRevision from './DocumentListTableSwitchRevision';

export default function DocumentListTableConfig(item, readOnly) {
	return {
		key: 'documentListTable',
		topic: 'whatIf',
		service: WhatIfDocumentTableService,
		withToolbar: true,
		hideTitle: true,
		toolbarAction: ['search', 'refresh'],
		fileOpenState: dto => (dto.file !== null ? `${nBossConnection.baseUrl}fileRepository/getFile?key=${dto.file}` : null),
		rowAction: readOnly ? ['fileOpen'] : ['fileOpen', 'delete'],
		rowButtonList: readOnly
			? []
			: [
					{
						key: 'modify',
						component: DocumentListTableModifyButton
					}
			  ],
		toolbarButtonList: readOnly
			? []
			: [
					{
						key: 'switchAll',
						component: DocumentListTableSwitchRevision
					}
			  ],
		defaultFilter: () => {
			return new TableFilterListDto().setup({
				list: [
					{
						propertyName: 'documentItemKey',
						type: 'EQ',
						value: item.key,
						permanent: true
					},
					{
						propertyName: 'lastRevision',
						type: 'EQ',
						value: true,
						permanent: readOnly
					}
				],
				activeCount: 2
			});
		},
		columns: [
			{
				key: 'name'
			},
			{
				key: 'revision'
			},
			{
				key: 'creator.name'
			},
			{
				key: 'created',
				type: 'dateTime'
			}
		]
	};
}
