import { useState, createContext, useContext } from 'react';

export const EditFormContext = createContext(null);

export const useEditForm = () => {
	return useContext(EditFormContext);
};

export default function EditFormProvider(props) {
	const [saveBtnKeyClick, setSaveBtnKeyClick] = useState(null);
	const [tabValue, setTabValue] = useState(0);

	const value = {
		saveBtnKeyClick,
		setSaveBtnKeyClick,
		tabValue,
		setTabValue
	};

	return <EditFormContext.Provider value={value}>{props.children}</EditFormContext.Provider>;
}
