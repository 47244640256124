import _ from '@lodash';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { useState } from 'react';
import MapTableResult from '../common/component/MapTableResult';
import { TaskManagerResultCalculateByType } from '../taskManager/component/TaskManagerResultCalculate';

function TaskAspectCalculateDoneNotification(props) {
	const { item } = props;
	const dispatch = useDispatch();
	const [showDialog, setShowDialog] = useState(false);
	const [result, setResult] = useState(null);
	const [message, setMessage] = useState(null);
	const mainTheme = useSelector(selectMainTheme);

	const handleClick = () => {
		const data = item.data.calculate;
		if (data != null && _.isArray(data) && data.length > 0) {
			setResult(data[0]);
		} else if (data !== null && _.isObject(data)) {
			setResult(data);
		} else {
			setResult(null);
			if (_.isString(data)) {
				setMessage(data);
			} else {
				setMessage('Empty result');
			}
		}
		setShowDialog(true);
	};

	if (item && item.severity && item.severity === 'success') {
		return (
			<>
				<div className="pt-4 pl-32">
					<Button color="seconary" className="text-white" onClick={handleClick}>
						{item.data.aspectName}
					</Button>
				</div>
				<ThemeProvider theme={mainTheme}>
					<Dialog open={showDialog} fullWidth>
						<DialogTitle disableTypography className="border-b">
							{item.data.aspectName} Calculate
						</DialogTitle>
						<DialogContent className="p-20">
							{result != null && result.aspect != null && <TaskManagerResultCalculateByType result={result.aspect} type="Aspect" />}
							{result && result.transformer != null && <TaskManagerResultCalculateByType result={result.transformer} type="Transformer" />}

							{(result === null || result.length === 0) && <div className="flex justify-center">{message || 'No result.'}</div>}
						</DialogContent>
						<DialogActions className="justify-between border-t p-20">
							<Button
								onClick={() => {
									setShowDialog(false);
								}}
							>
								Close
							</Button>
						</DialogActions>
					</Dialog>
				</ThemeProvider>
			</>
		);
	}
	return <></>;
}

export default TaskAspectCalculateDoneNotification;
