import NamedDto from 'modules/base/dto/NamedDto';

export class OptimizationCaseTableDto extends NamedDto {
	constructor() {
		super();
		/** @type {NamedDto} */
		this.optimizerLog = null;
		/** @type {NamedDto} */
		this.optimizer = null;
		/** @type {NamedDto} */
		this.aspect = null;
		/** @type {NamedDto} */
		this.transformer = null;
	}

	/**
	 * @typedef {Object} _OptimizationCaseTableDtoProps
	 * @property {NamedDto} optimizerLog
	 * @property {NamedDto} optimizer
	 * @property {NamedDto} aspect
	 * @property {NamedDto} transformer
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _OptimizationCaseTableDtoProps} OptimizationCaseTableDtoProps
	 */
	/**
	 *
	 * @param {OptimizationCaseTableDtoProps} props
	 * @returns {OptimizationCaseTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.optimizerLog = props.optimizerLog !== null ? new NamedDto().setup(props.optimizerLog) : null;
		this.optimizer = props.optimizer !== null ? new NamedDto().setup(props.optimizer) : null;
		this.aspect = props.aspect !== null ? new NamedDto().setup(props.aspect) : null;
		this.transformer = props.transformer !== null ? new NamedDto().setup(props.transformer) : null;
		return this;
	}
}

export default OptimizationCaseTableDto;
