import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import WhatIfGroupTableService from '../service/WhatIfGroupTableService';

const config = {
	key: 'whatIfGroupTable',
	topic: 'whatIf',
	service: WhatIfGroupTableService,
	editFormState: item => `/what-if-group/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['search', 'refresh', 'new'],
	rowAction: ['modify', 'delete'],
	columns: [
		{
			key: 'name'
		}
	]
};

function WhatIfGroupTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('whatIfGroupTable', reducer('whatIfGroupTable'))(WhatIfGroupTable);
