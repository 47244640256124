import nBossConnection from 'modules/base/service/nBossConnection';
import { useRef, useState, useEffect } from 'react';
import { Group, Text, Rect, Label, Image } from 'react-konva';
import { useWhatIf } from '../component/WhatIfProvider';
import { getScaledImageCoordinates } from '../component/WhatIfUtil';
import PropertiesSvgBtn from './PropertiesSvgBtn';

const labelBorder = 6;
const itemBorder = 6;
const iconBorder = 12;

function calcImageX(dimension, imageDimension) {
	const result = dimension.width > 0 && imageDimension.width > 0 ? dimension.width / 2 - imageDimension.width / 2 : iconBorder + itemBorder;
	return result;
}

function calcTextX(dimension, textDimension) {
	if (dimension.width > 0 && textDimension.width > 0) {
		const result = dimension.width / 2 - textDimension.width / 2;
		return result;
	}
	return 0;
}

function LabelIconView(props) {
	const { getIconsetKey, iconset } = useWhatIf();
	const { item, labelText, isDragging, enablePropertiesBtn, onPropertiesClick, invalid } = props;
	const [image, setImage] = useState(null);
	const textRef = useRef(null);
	const imgRef = useRef(null);
	const imageDimension = image ? getScaledImageCoordinates(item.iconSize, item.iconSize, image.width, image.height) : { width: 0, height: 0 };
	const textDimension = textRef.current ? { width: textRef.current.width(), height: textRef.current.height() } : { width: 0, height: 0 };

	const dimension =
		image && textRef.current
			? { width: Math.max(textDimension.width, imageDimension.width + iconBorder * 2) + itemBorder * 2, height: imageDimension.height + iconBorder * 2 + textDimension.height + itemBorder * 2 }
			: { width: 0, height: 0 };

	useEffect(() => {
		const imageKey = getIconsetKey(item.icon);
		if (imageKey != null) {
			const imageObj = new window.Image();
			imageObj.onload = function () {
				setImage(imageObj);
			};
			imageObj.src = `${nBossConnection.baseUrl}/fileRepository/getFile?key=${imageKey}`;
		}
	}, [item, iconset]);

	return (
		<Group
			{...props}
			rotation={item.rotate}
			width={dimension.width}
			height={dimension.height}
			offsetX={dimension.width > 0 ? dimension.width / 2 : 0}
			offsetY={dimension.height > 0 ? dimension.height / 2 : 0}
			opacity={dimension.width > 0 ? (invalid ? 0.5 : 1) : 0}
		>
			<Rect fill={item.borderColor} x={itemBorder} y={itemBorder} width={dimension.width - itemBorder * 2} height={dimension.height - itemBorder * 2} />
			<Image ref={imgRef} x={calcImageX(dimension, imageDimension)} y={iconBorder + itemBorder} width={imageDimension.width} height={imageDimension.height} image={image} />
			<Label x={calcTextX(dimension, textDimension)} y={dimension.height - textDimension.height - itemBorder}>
				<Text padding={labelBorder} ref={textRef} text={labelText} fontSize={item.fontSize} fill={item.fillColor} fillAfterStrokeEnabled align="center" />
			</Label>
			<PropertiesSvgBtn enablePropertiesBtn={enablePropertiesBtn} handleClick={() => onPropertiesClick && onPropertiesClick()} />
			{textRef.current && <Rect width={dimension.width} height={dimension.height} dash={[10, 10]} stroke="black" strokeWidth={1} opacity={isDragging ? 1 : 0} listening={false} />}
		</Group>
	);
}

export default LabelIconView;
