import IdentifiableDto from './IdentifiableDto';

export class NamedDto extends IdentifiableDto {
	/**
	 * @module NamedDto
	 */
	constructor() {
		super();
		/** @type {string} */
		this.name = null;
	}

	/**
	 * @typedef {Object} _NamedDtoProps
	 * @property {string} name
	 *
	 * @typedef {import("./IdentifiableDto").IdentifiableDtoProps & _NamedDtoProps} NamedDtoProps
	 */
	/**
	 *
	 * @param {NamedDtoProps} props
	 * @returns {NamedDto}
	 */
	setup(props) {
		super.setup(props);
		this.name = props.name;
		return this;
	}
}

export default NamedDto;
