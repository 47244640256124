import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormFileInputField from 'modules/ui/editform/fields/EditFormFileInputField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormGridComponent from 'modules/ui/editform/subForm/EditFormGridComponent';
import EditFormComponentMassSomethingField from 'app/admin/common/component/EditFormComponentMassSomethingField';
import WorkflowModelCalculate from 'app/admin/common/component/WorkflowModelCalculate';
import { useParams, withRouter } from 'react-router';
import StorageDriverService from 'app/admin/driver/service/StorageDriverService';
import WorkflowProvider from 'app/admin/workflow/component/WorkflowProvider';
import WorkflowModelEditService from '../service/WorkflowModelEditService';
import WorkflowEditFormHeader from '../component/WorkflowEditFormHeader';
import WorkflowEditFormComponent from '../component/WorkflowEditFormComponent';
import WorkflowModelDebug from '../component/WorkflowModelDebug';
import WorkflowDataUpdater from '../component/WorkflowDataUpdater';

const config = data => {
	const storageDriverService = new StorageDriverService();
	return {
		key: 'workflowModelEditForm',
		topic: 'model',
		service: WorkflowModelEditService,
		tabList: initData => {
			return [{ key: 'basic' }, { key: 'input_attributes' }, { key: 'output_attributes' }, { key: 'workflow' }];
		},
		backLink: () => '/model',
		defaultValue: () => WorkflowModelEditService.create(data.driverName),
		fields: initData => {
			const fields = [
				{
					key: 'name',
					component: EditFormTextField,
					required: true,
					readOnly: data.id !== 'new'
				},
				{
					key: 'driver',
					component: EditFormSelectField,
					required: true,
					items: () => storageDriverService.getListSelectionByDriverType('workflow')
				},
				{
					key: 'workflow',
					component: WorkflowEditFormComponent,
					isControlledInput: false,
					tab: 'workflow'
				},
				{
					key: 'workflowDataUpdater',
					component: WorkflowDataUpdater,
					isControlledInput: false,
					tab: 'workflow'
				},
				{
					key: 'image',
					component: EditFormFileInputField,
					showImage: true,
					required: true
				},
				{
					key: 'input_attributes',
					tab: 'input_attributes',
					component: EditFormGridComponent,
					modifyOnly: true,
					defaultValue: {
						id: null,
						name: null,
						type: null,
						value: null
					},
					fields: [
						{
							key: 'name',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'type',
							component: EditFormSelectField,
							required: true,
							type: 'enum',
							items: [
								{ label: 'Variable', value: 'Variable' },
								{ label: 'ComponentMassFrac', value: 'ComponentMassFrac' }
							]
						},
						{
							key: 'value',
							component: EditFormTextField,
							required: true,
							type: 'number',
							dependsOn: 'type',
							visibleIf: type => type === 'Variable'
						},
						{
							key: 'value',
							component: EditFormComponentMassSomethingField,
							dependsOn: 'type',
							visibleIf: type => type === 'ComponentMassFrac'
						}
					]
				},
				{
					key: 'output_attributes',
					tab: 'output_attributes',
					component: EditFormGridComponent,
					modifyOnly: true,
					defaultValue: {
						id: null,
						name: null,
						type: null
					},
					fields: [
						{
							key: 'name',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'type',
							component: EditFormSelectField,
							required: true,
							type: 'enum',
							items: [
								{ label: 'Variable', value: 'Variable' },
								{ label: 'ComponentMassFrac', value: 'ComponentMassFrac' }
							]
						}
					]
				}
			];

			return fields;
		}
	};
};

function WorkflowModelEditForm() {
	const { id, driverName } = useParams();
	const dispatch = useDispatch();

	const myConfig = config({ id, driverName });
	return (
		<WorkflowProvider>
			<EditFormPage
				config={myConfig}
				beforeSaveContent={
					<>
						<WorkflowModelDebug id={id} />
						<WorkflowModelCalculate id={id} />
					</>
				}
				onSave={result => {
					if (!result.engineSuccessful) dispatch(showMessage({ message: `Engine: ${result.engineMessage}` }));
				}}
				headerCenterContent={<WorkflowEditFormHeader workflowTab={3} />}
			/>
		</WorkflowProvider>
	);
}

export default withRouter(WorkflowModelEditForm);
