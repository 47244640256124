const optimizationCaseEn = {
	optimizationCaseTable: 'Optimization Case',
	optimizationCaseEditForm: 'Optimization Case',
	name: 'Name',
	basic: 'Basic',
	domains: 'Domains',
	optimizer_log_name: 'Optimizer Log Name',
	optimizer_log_file: 'Optimizer Log File',
	objective_name: 'Objective Name',
	objective_file: 'Objective File',
	domain_from: 'Domain From',
	domain_to: 'Domain To',
	step_count: 'Step Count',
	run: 'Futtatás',
	runDesc: 'Do you want to run this case?',
	optimizerLog: 'Optimizer Log',
	'optimizerLog.name': 'Optimizer Log',
	'optimizer.name': 'Optimizer',
	'aspect.name': 'Aspect',
	'transformer.name': 'Transformer',
	operator: 'Operator',
	aspectName: 'Aspect name',
	objectiveName: 'Objective name',
	type: 'Típus',
	value: 'Érték',
	min_value: 'Min. érték',
	max_value: 'Max. érték',
	state: 'State',
	values: 'Values',
	reader: 'Reader',
	imputed_value: 'Imputed value',
	optimization_goal: 'Optimization Goal',
	enabled: 'Enabled',
	load_aspect: 'Load Aspect',
	aspect: 'Aspect',
	limits: 'Limits',
	'soft.type': 'Soft Type',
	'soft.low': 'Soft Low',
	'soft.high': 'Soft High',
	'soft.value': 'Soft Value',
	'hard.type': 'Hard Type',
	'hard.low': 'Hard Low',
	'hard.high': 'Hard High',
	'hard.value': 'Hard Value',
	transformerName: 'Transformer name',
	state_limits: 'State limits',
	aspect_limits: 'Aspect limits',
	transformer_limits: 'Transformer limits',
	transformer_attributes: 'Transformer attributes'
};

export default optimizationCaseEn;
