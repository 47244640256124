import NamedDto from 'modules/base/dto/NamedDto';

export class LummusModelAttributeEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.type = null;
		/** @type {string} */
		this.value = null;
		/** @type {string} */
		this.code = null;
		/** @type {string} */
		this.unit_op_code = null;
		/** @type {string} */
		this.uom = null;
		/** @type {string} */
		this.title = null;
		/** @type {number} */
		this.orderIndex = null;
	}

	/**
	 * @typedef {Object} _LummusModelAttributeEditDtoProps
	 * @property {string} type
	 * @property {string} value
	 * @property {string} code
	 * @property {string} unit_op_code
	 * @property {string} uom
	 * @property {title} title
	 * @property {number} orderIndex
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _LummusModelAttributeEditDtoProps} LummusModelAttributeEditDtoProps
	 */
	/**
	 *
	 * @param {LummusModelAttributeEditDtoProps} props
	 * @returns {LummusModelAttributeEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.type = props.type;
		this.value = props.value;
		this.code = props.code;
		this.unit_op_code = props.unit_op_code;
		this.uom = props.uom;
		this.title = props.title;
		this.orderIndex = props.orderIndex;
		return this;
	}
}

export default LummusModelAttributeEditDto;
