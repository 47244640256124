class TableVariableDto {
	constructor() {
		/** @type {string} */
		this.name = null;
		/** @type {string} */
		this.type = null;
		/** @type {string} */
		this.value = null;
	}

	/**
	 *
	 * @typedef {Object} _TableVariableDtoProps
	 * @property {string} name
	 * @property {string} type
	 * @property {string} value
	 * @typedef {_TableVariableDtoProps} TableVariableDtoProps
	 */
	/**
	 *
	 * @param {TableVariableDtoProps} props
	 * @returns {TableVariableDto}
	 */
	setup(props) {
		this.name = props.name;
		this.type = props.type;
		this.value = props.value;
		return this;
	}
}

export default TableVariableDto;
