import _ from '@lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useRef, useState } from 'react';
import { FormHelperText, FormControlLabel, Checkbox, Button, Paper, TextField, InputAdornment } from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search';
import TreeItem from '@material-ui/lab/TreeItem';

const useStyles = makeStyles(theme => ({
	root: {
		margin: 'auto'
	},
	paper: {
		height: 320,
		overflow: 'auto'
	},
	button: {
		margin: theme.spacing(0.5, 0)
	},
	tree: {
		height: 216,
		flexGrow: 1,
		maxWidth: 400
	}
}));

function TreeList(props) {
	const { items, filter, showInput, showOutput, showTransformer, searchInput, value, onSelected, draggable, onDragChangeValue } = props;
	const classes = useStyles();
	const [expanded, setExpanded] = useState([]);
	const [selected, setSelected] = useState([]);
	const [draggedItem, setDraggedItem] = useState(null);
	const [draggedNewPos, setDraggedNewPos] = useState(null);

	const handleToggle = (event, nodeIds) => {
		setExpanded(nodeIds);
	};

	const handleSelect = (event, nodeIds) => {
		setSelected(nodeIds);
		onSelected(nodeIds);
	};

	const getFilteredItems = () => {
		if (draggedItem != null && draggedNewPos != null) {
			const result = [...items];
			if (draggedItem.parentIdx != null) {
				const subItems = [...result[draggedItem.parentIdx].children];
				const movedElement = subItems[draggedItem.idx];
				subItems.splice(draggedItem.idx, 1);
				subItems.splice(draggedNewPos, 0, movedElement);
				result[draggedItem.parentIdx] = { ...result[draggedItem.parentIdx], children: subItems };
			} else {
				const movedElement = result[draggedItem.idx];
				result.splice(draggedItem.idx, 1);
				result.splice(draggedNewPos, 0, movedElement);
			}
			return result;
		}
		if (!filter) return items;
		const result = [];
		if (items) {
			console.log(items);
			const isSearch = !(searchInput === null || searchInput.length === 0);
			items.forEach(item => {
				if (
					((item.entity === 'aspect' && showInput && item.io === 'INPUT') || (item.entity === 'aspect' && showOutput && item.io === 'OUTPUT') || (item.entity === 'transformer' && showTransformer)) &&
					(!isSearch || findStringInItem(item)) &&
					!findItemInValue(item)
				) {
					const hasChild = item.children && item.children.length > 0;
					const filteredItem = {
						...item,
						children: []
					};
					if (hasChild) {
						item.children.forEach(subItem => {
							if (((isSearch && findStringInItem(item)) || findStringInItem(subItem)) && !findItemInValue(subItem)) filteredItem.children.push(subItem);
						});
					}

					if (!(hasChild && filteredItem.children.length === 0)) result.push(filteredItem);
				}
			});
		}
		return result;
	};

	const findStringInItem = item => {
		if (searchInput === null || searchInput.length === 0) return true;
		if (item.name.toLowerCase().includes(searchInput.toLowerCase())) return true;
		if (item.children && item.children.length > 0) {
			if (item.children.findIndex(subItem => subItem.name.toLowerCase().includes(searchInput.toLowerCase())) > -1) {
				return true;
			}
		}
		return false;
	};

	const findItemInValue = item => {
		if (_.isUndefined(value) || value === null || value.length === 0) return false;
		if (value.findIndex(v => v === item.id) > -1) return true;
		return false;
	};

	const handleDragChange = () => {
		const result = getFilteredItems();
		const newValue = [];
		result.forEach(r => {
			newValue.push(r.id);
			const hasChild = r.children && r.children.length > 0;
			if (hasChild) {
				r.children.forEach(rc => newValue.push(rc.id));
			}
		});
		onDragChangeValue(newValue);
	};

	return (
		<Paper className={classes.paper}>
			<div className="p-16">
				<TreeView
					className={classes.tree}
					defaultCollapseIcon={<ExpandMoreIcon />}
					defaultExpandIcon={<ChevronRightIcon />}
					expanded={expanded}
					selected={selected}
					onNodeToggle={handleToggle}
					onNodeSelect={handleSelect}
					multiSelect
				>
					{getFilteredItems().map((item, i) => (
						<TreeItem
							key={item.id}
							nodeId={item.id}
							label={item.entity === 'transformer' ? `Calculated ${item.name}` : `${item.name} ${item.io === 'INPUT' ? 'Input' : 'Output'}`}
							draggable={draggable}
							onDragStart={e => {
								if (draggable) setDraggedItem({ idx: i, parentIdx: null });
							}}
							onDragEnd={e => {
								if (draggable) {
									if (draggedItem.idx !== draggedNewPos) {
										handleDragChange();
									}
									setDraggedItem(null);
									setDraggedNewPos(null);
								}
							}}
							onDragEnter={e => {
								if (draggable && draggedItem != null && draggedItem.parentIdx === null) {
									setDraggedNewPos(i);
								}
							}}
						>
							{item.children.map((child, j) => (
								<TreeItem
									key={child.id}
									nodeId={child.id}
									label={child.name}
									draggable={draggable}
									onDragStart={e => {
										if (draggable) {
											setDraggedItem({ idx: j, parentIdx: i });
											e.stopPropagation();
										}
									}}
									onDragEnd={e => {
										if (draggable) {
											if (draggedItem.idx !== draggedNewPos) {
												handleDragChange();
											}
											setDraggedItem(null);
											setDraggedNewPos(null);
											e.stopPropagation();
										}
									}}
									onDragEnter={e => {
										if (draggable && draggedItem !== null && draggedItem.parentIdx === i) {
											setDraggedNewPos(j);
											e.stopPropagation();
										}
									}}
								/>
							))}
						</TreeItem>
					))}
				</TreeView>
			</div>
		</Paper>
	);
}

function EditFormValueKeySelect(props) {
	const classes = useStyles();
	const readOnly = false;
	const { items } = props.fieldConfig;
	const { value, onChange } = props.field;
	const { error } = props.fieldState;
	const [searchInput, setSearchInput] = useState('');
	const [showInput, setShowInput] = useState(true);
	const [showOutput, setShowOutput] = useState(true);
	const [showTransformer, setShowTransformer] = useState(true);
	const [leftSelected, setLeftSelected] = useState([]);
	const [rightSelected, setRightSelected] = useState([]);

	const handleAllRight = () => {
		const result = [];
		items.forEach(item => {
			if (item.children.length > 0) {
				item.children.forEach(subItem => {
					result.push(subItem.id);
				});
			} else {
				result.push(item.id);
			}
		});
		onChange(result);
	};

	const handleCheckedRight = () => {
		if (leftSelected.length > 0) onChange([...value, ...leftSelected.filter(selectedItem => items.findIndex(item => item.name === selectedItem && item.children.length > 0) === -1)]);
	};

	const handleCheckedLeft = () => {
		if (rightSelected.length > 0) onChange(value.filter(v => rightSelected.indexOf(v) === -1));
	};

	const handleAllLeft = () => {
		onChange([]);
	};

	const selectedItems = () => {
		const result = [];
		if (items && value) {
			value.forEach(id => {
				const item = items.find(d => d.id === id);
				if (item) {
					const selectedItem = {
						...item,
						children: []
					};
					result.push(selectedItem);
				} else {
					const selectedMainItem = items.find(d => id.startsWith(`${d.id}.`));
					if (selectedMainItem) {
						if (result.findIndex(d => d.id === selectedMainItem.id) === -1) {
							result.push({ ...selectedMainItem, children: [] });
						}
						const mainResultItem = result.find(d => d.id === selectedMainItem.id);
						selectedMainItem.children.forEach(subItem => {
							if (subItem.id === id) {
								mainResultItem.children.push(subItem);
							}
						});
					}
				}
			});
		}
		return result;
	};

	return (
		<div className="w-full mt-8 mb-16">
			<div className="flex flex-row justify-between">
				<div className="flex flex-col px-16">
					<div className="flex h-64 items-center">Variables</div>
					<FormControlLabel
						control={
							<Checkbox
								checked={showInput}
								onChange={e => {
									setShowInput(e.target.checked);
								}}
								inputProps={{ 'aria-label': 'primary checkbox' }}
							/>
						}
						label="Input"
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={showOutput}
								onChange={e => {
									setShowOutput(e.target.checked);
								}}
								inputProps={{ 'aria-label': 'primary checkbox' }}
								label="Output"
							/>
						}
						label="Output"
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={showTransformer}
								onChange={e => {
									setShowTransformer(e.target.checked);
								}}
								inputProps={{ 'aria-label': 'primary checkbox' }}
								label="Transformer"
							/>
						}
						label="Transformer"
					/>
				</div>
				<div className="flex-1">
					<div className="h-64">
						<TextField
							className="w-full"
							label="Search"
							variant="outlined"
							value={searchInput}
							onChange={e => setSearchInput(e.target.value)}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<SearchIcon />
									</InputAdornment>
								)
							}}
						/>
					</div>
					<TreeList items={items} filter showInput={showInput} showOutput={showOutput} showTransformer={showTransformer} searchInput={searchInput} value={value} onSelected={setLeftSelected} />
				</div>
				<div className="flex flex-col px-16 justify-center">
					<div className="h-64" />
					<Button variant="outlined" size="small" className={classes.button} onClick={handleAllRight} disabled={readOnly} aria-label="move all right">
						≫
					</Button>
					<Button variant="outlined" size="small" className={classes.button} onClick={handleCheckedRight} disabled={readOnly} aria-label="move selected right">
						&gt;
					</Button>
					<Button variant="outlined" size="small" className={classes.button} onClick={handleCheckedLeft} disabled={readOnly} aria-label="move selected left">
						&lt;
					</Button>
					<Button variant="outlined" size="small" className={classes.button} onClick={handleAllLeft} disabled={readOnly} aria-label="move all left">
						≪
					</Button>
				</div>
				<div className="flex-1">
					<div className="flex h-64 items-center">Selected (drag labels to reorder)</div>
					<TreeList items={selectedItems()} filter={false} onSelected={setRightSelected} draggable onDragChangeValue={newValue => onChange(newValue)} />
				</div>
			</div>
			{error ? <FormHelperText>{error.message}</FormHelperText> : null}
		</div>
	);
}

export function parseAttributesForTreeView(attributes) {
	const result = [];
	if (attributes) {
		attributes.forEach(attribute => {
			attribute.entity = attribute.entity || 'aspect';
			const item = {
				id: `${attribute.entity}.${attribute.io}.${attribute.name}`,
				name: attribute.name,
				io: attribute.io,
				entity: attribute.entity,
				children: []
			};
			if (_.isObject(attribute.value)) {
				Object.keys(attribute.value).forEach(key => {
					item.children.push({ id: `${attribute.entity}.${attribute.io}.${attribute.name}.${key}`, name: key });
				});
			}
			result.push(item);
		});
	}
	return result;
}

export default EditFormValueKeySelect;
