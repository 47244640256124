import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormGridComponent from 'modules/ui/editform/subForm/EditFormGridComponent';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import { useParams, withRouter } from 'react-router';
import PiMeasurementReaderEditService from '../service/PiMeasurementReaderEditService';
import PiMeasurementLoadAspect from '../component/PiMeasurementLoadAspect';

const config = data => {
	return {
		key: 'piMeasurementReaderEditForm',
		text: 'PI Measurement Reader',
		topic: 'piMeasurementReader',
		service: PiMeasurementReaderEditService,
		tabList: initData => {
			return [{ key: 'basic' }, { key: 'fields' }];
		},
		fields: initData => {
			const fields = [
				{
					key: 'name',
					component: EditFormTextField,
					required: true
				},

				{
					key: 'api_url',
					component: EditFormTextField
				},
				{
					key: 'server_id',
					component: EditFormTextField
				},
				{
					key: 'username',
					component: EditFormTextField
				},
				{
					key: 'password',
					component: EditFormTextField,
					required: true,
					autoComplete: 'new-password',
					type: 'password'
				},
				{
					key: 'time_zone',
					component: EditFormTextField
				},
				{
					key: 'fields',
					tab: 'fields',
					component: EditFormGridComponent,
					defaultValue: {
						id: null,
						name: null,
						tag_name: null,
						uom: null,
						category: 'Input'
					},
					fields: [
						{
							key: 'name',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'tag_name',
							component: EditFormTextField
						},
						{
							key: 'uom',
							component: EditFormTextField
						},
						{
							key: 'category',
							component: EditFormSelectField,
							type: 'enum',
							items: [
								{
									label: 'Input',
									value: 'Input'
								},
								{
									label: 'Output',
									value: 'Output'
								}
							]
						}
					]
				}
			];
			return fields;
		}
	};
};

function PiMeasurementReaderEditForm() {
	const { id, tabKey } = useParams();

	const myConfig = config({ id });
	return <EditFormPage config={myConfig} startTabValue={tabKey} beforeSaveContent={<PiMeasurementLoadAspect />} />;
}

export default withRouter(PiMeasurementReaderEditForm);
