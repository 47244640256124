import _ from '@lodash';
import { useSelector, useDispatch } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import { ThemeProvider } from '@material-ui/core/styles';
import { useState } from 'react';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useFormContext } from 'react-hook-form';
import { Button, Icon, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import ModelService from 'app/admin/model/service/ModelService';
import WorkflowConverter from 'app/admin/workflow/component/WorkflowConverter';
import MapTableResult from './MapTableResult';

function WorkflowModelCalculate(props) {
	const mainTheme = useSelector(selectMainTheme);
	const dispatch = useDispatch();
	const { id, type } = props;
	const { getValues } = useFormContext();
	const [loading, setLoading] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [result, setResult] = useState(null);
	const [message, setMessage] = useState(null);

	const handleClick = () => {
		const dto = getValues();
		const exportDto = dto.json ? WorkflowConverter(dto.name, dto.json) : null;
		if (dto.name === null || dto.name.length === 0) {
			dispatch(showMessage({ message: 'Model name is required.' }));
		} else if (exportDto.input_attributes === null || exportDto.input_attributes.length === 0 || exportDto.output_attributes === null || exportDto.output_attributes.length === 0) {
			dispatch(showMessage({ message: 'Input/output attributes is required.' }));
		} else {
			const values = {};
			exportDto.input_attributes.forEach(element => {
				values[element.name] = element.value;
			});
			setLoading(true);
			setShowDialog(true);
			console.log(dto.name);
			console.log(values);
			ModelService.calculate(dto.name, values)
				.then(response => handleResponse(response))
				.catch(err => handleCatch(err));
		}
	};

	const handleResponse = data => {
		console.log(data);
		setLoading(false);
		if (data !== null && _.isArray(data) && data.length > 0) {
			setResult(data[0]);
		} else if (data !== null && _.isObject(data)) {
			setResult(data);
		} else {
			setResult(null);
			if (_.isString(data)) {
				setMessage(data);
			} else {
				setMessage('Empty result');
			}
		}
	};

	const handleCatch = err => {
		console.log(err);
		setLoading(false);
		setResult(null);
		setMessage(`Calculate connection problem: ${err !== null ? err : 'Unkown'}`);
	};

	const parseData = data => {
		const parseResult = [];
		const fields = Object.keys(data);
		for (let i = 0; i < fields.length; i += 1) {
			const value = data[fields[i]];
			if (_.isObject(value)) {
				const subFields = Object.keys(value);
				parseResult.push({
					name: fields[i],
					sub: false,
					parent: true,
					count: subFields.length,
					value: null
				});
				for (let j = 0; j < subFields.length; j += 1) {
					parseResult.push({
						name: subFields[j],
						sub: true,
						parent: false,
						value: value[subFields[j]]
					});
				}
			} else {
				parseResult.push({
					name: fields[i],
					sub: false,
					parrent: false,
					value
				});
			}
		}
		return parseResult;
	};

	return (
		<>
			<Button className="whitespace-nowrap mx-4" variant="contained" color="secondary" disabled={id === 'new'} onClick={() => handleClick()} startIcon={<Icon className="hidden sm:flex">graphic_eq</Icon>}>
				Calculate
			</Button>
			<ThemeProvider theme={mainTheme}>
				<Dialog open={showDialog} fullWidth>
					<DialogTitle disableTypography className="border-b">
						Calculate
					</DialogTitle>
					<DialogContent className="p-20">
						{loading && <FuseLoading />}
						{!loading && result !== null && result.output_values && <MapTableResult data={result.output_values} />}
						{!loading && result === null && <div className="flex justify-center">{message || 'No result.'}</div>}
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button
							onClick={() => {
								setShowDialog(false);
							}}
						>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</>
	);
}
export default WorkflowModelCalculate;
