import _ from '@lodash';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Select, MenuItem, FormControl, InputLabel, ListItemText, Icon, IconButton, Modal, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PhotoshopPicker } from 'react-color';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	root: {
		position: 'absolute',
		zIndex: 1
	},
	cover: {
		position: 'fixed',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	}
}));

function ColorPickerField(props) {
	const { t } = useTranslation('whatIf');
	const { value, onChange } = props.field;
	const emptyAvailable = props.emptyAvailable || false;
	const [displayPicker, setDisplayPicker] = useState(false);
	const [currentValue, setCurrentValue] = useState('#000000');
	const { compact = false } = props;

	return (
		<div className={clsx(compact ? 'w-full flex flex-row' : 'w-full flex flex-row mt-8 mb-16', _.get(props, 'fieldConfig.className', ''))}>
			<div
				aria-hidden="true"
				className="flex flex-1 border-1 rounded-md mr-8 px-12 py-10 cursor-pointer relative"
				style={{ height: '54px' }}
				onClick={e => {
					setCurrentValue(value || '#000000');
					setDisplayPicker(true);
				}}
			>
				{value ? (
					<div className="text-white w-full h-full flex justify-center items-center rounded-md" style={{ backgroundColor: value, textShadow: '0 0 5px #000' }}>
						{value || 'None'}
					</div>
				) : (
					<div className="text-black w-full h-full flex justify-center items-center rounded-md" style={{ backgroundColor: 'transparent' }}>
						None
					</div>
				)}
				<div className="absolute bg-white" style={{ fontSize: '0.9rem', paddingLeft: '8px', paddingRight: '8px', left: '6px', top: '-8px' }}>
					{t(props.label)}
				</div>
			</div>

			<div className="mt-4">
				<IconButton onClick={() => onChange(null)}>
					<Icon>close</Icon>
				</IconButton>
			</div>
			{displayPicker && (
				<Modal open onClose={() => setDisplayPicker(false)} className="flex items-center justify-center">
					<PhotoshopPicker
						onCancel={() => setDisplayPicker(false)}
						onAccept={() => {
							onChange(currentValue);
							setDisplayPicker(false);
						}}
						color={currentValue}
						onChange={c => setCurrentValue(c.hex)}
					/>
				</Modal>
			)}
		</div>
	);
}
export default ColorPickerField;
