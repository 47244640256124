import { useSelector } from 'react-redux';
import { Button, Icon } from '@material-ui/core';

function TableBulkActionButton(props) {
	const sessionName = useSelector(rootState => rootState[props.config.key].tableBrowserSession.sessionName);
	const allSelected = useSelector(rootState => rootState[props.config.key].tableBrowserData.allSelected);
	const selected = useSelector(rootState => rootState[props.config.key].tableBrowserData.selected);

	return (
		<Button
			className="whitespace-nowrap mr-12"
			variant="contained"
			color="secondary"
			onClick={() => props.bulkAction.action(sessionName)}
			disabled={!allSelected && selected.length === 0}
			startIcon={<Icon className="hidden sm:flex">{props.bulkAction.icon}</Icon>}
		>
			{props.bulkAction.title}
		</Button>
	);
}

export default TableBulkActionButton;
