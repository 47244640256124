import _ from 'lodash';
import { Redirect, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import UserProfileEditService from '../service/UserProfileEditService';

const config = {
	key: 'userProfileEditForm',
	text: 'Profile',
	service: UserProfileEditService,
	icon: 'person',
	hideBackLink: true,
	noNavigationAfterSave: true,
	fields: [
		{
			key: 'name',
			text: 'Név',
			component: EditFormTextField,
			required: true
		},
		{
			key: 'email',
			text: 'E-mail',
			component: EditFormTextField,
			required: true
		},
		{
			key: 'password',
			text: 'Jelszó',
			component: EditFormTextField,
			required: true,
			type: 'password',
			autoComplete: 'new-password'
		},
		{
			key: 'passwordAgain',
			text: 'Jelszó mégegyszer',
			component: EditFormTextField,
			required: true,
			type: 'password',
			autoComplete: 'new-password'
		}
	]
};

function UserProfileEditForm() {
	const user = useSelector(({ auth }) => auth.user);
	const { id } = useParams();
	if (_.isUndefined(id)) {
		return <Redirect to={`/profile/${user.data.id}`} />;
	}

	return <EditFormPage config={config} />;
}

export default UserProfileEditForm;
