import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import { useParams, withRouter } from 'react-router';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import EditFormGroupFields from 'modules/ui/editform/subForm/EditFormGroupFields';
import EditFormSeparatorField from 'modules/ui/editform/fields/EditFormSeparatorField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import PiMeasurementReaderService from 'app/admin/piMeasurementReader/service/PiMeasurementReaderService';
import EditFormDateTimeField from 'modules/ui/editform/fields/EditFormDateTimeField';
import { summaryDurationUnits, summaryTypes } from 'app/admin/common/component/PiMeasurementReaderQuery';
import MeasurementService from 'app/admin/measurement/service/MeasurementService';
import SchedulerEditService from '../service/SchedulerEditService';
import SchedulerCreateMeasurementButton from '../component/SchedulerCreateMeasurementButton';

const blockClassName = 'rounded-8 border px-16 pt-10 pb-4 mb-16';
const blockTitleClassName = 'py-8';

const config = data => {
	return {
		key: 'schedulerEditForm',
		text: 'Scheduler',
		topic: 'scheduler',
		service: SchedulerEditService,
		fields: initData => {
			const fields = [
				{
					key: 'scheduleGroup',
					component: EditFormGroupFields,
					className: blockClassName,
					fields: [
						{
							key: 'sepSchedule',
							text: 'Schedule',
							component: EditFormSeparatorField,
							className: blockTitleClassName,
							isControlledInput: false
						},
						{
							key: 'enabled',
							component: EditFormCheckboxField
						},
						{
							key: 'scheduleInnerGroup',
							component: EditFormGroupFields,
							className: 'flex flex-col sm:flex-row',
							fields: [
								{
									key: 'schedule.minute',
									component: EditFormTextField,
									className: 'sm:mr-6',
									required: true,
									mask: {
										mask: /^\*$|^[0-9]{0,2}$/
									}
								},
								{
									key: 'schedule.hour',
									component: EditFormTextField,
									className: 'sm:mr-6',
									required: true,
									mask: {
										mask: /^\*$|^[0-9]{0,2}$/
									}
								},
								{
									key: 'schedule.day',
									component: EditFormTextField,
									className: 'sm:mr-6',
									required: true,
									mask: {
										mask: /^\*$|^[0-9]{0,2}$/
									}
								},
								{
									key: 'schedule.month',
									component: EditFormTextField,
									className: 'sm:mr-6',
									required: true,
									mask: {
										mask: /^\*$|^[0-9]{0,2}$/
									}
								}
							]
						}
					]
				},
				{
					key: 'definitionGroup',
					component: EditFormGroupFields,
					className: blockClassName,
					fields: [
						{
							key: 'sepDefinition',
							text: 'Definition',
							component: EditFormSeparatorField,
							className: blockTitleClassName,
							isControlledInput: false
						},
						{
							key: 'defInner1Group',
							component: EditFormGroupFields,
							className: 'flex flex-col sm:flex-row',
							fields: [
								{
									key: 'definition.name',
									component: EditFormTextField,
									className: 'mr-8',
									required: true
								},
								{
									key: 'definition.reader_name',
									component: EditFormSelectField,
									className: 'mr-8',
									type: 'enum',
									required: true,
									items: () => PiMeasurementReaderService.getListSelectionEnum()
								},
								{
									key: 'definition.measurement_name',
									component: EditFormSelectField,
									type: 'enum',
									className: 'mr-8',
									required: true,
									filterKey: 'newMeasurementName',
									items: () => MeasurementService.getListSelectionEnum()
								},
								{
									key: 'createMeasurement',
									component: SchedulerCreateMeasurementButton,
									formPath: 'definition.',
									isControlledInput: false
								}
							]
						},
						{
							key: 'defInner2Group',
							component: EditFormGroupFields,
							className: 'flex flex-col sm:flex-row',
							fields: [
								{
									key: 'definition.start_date_time',
									component: EditFormDateTimeField,
									required: true,
									className: 'mr-8'
								},
								{
									key: 'definition.last_date_time',
									component: EditFormDateTimeField
								}
							]
						},
						{
							key: 'defInner3Group',
							component: EditFormGroupFields,
							className: 'flex flex-col sm:flex-row',
							fields: [
								{
									key: 'definition.summary_type',
									component: EditFormSelectField,
									className: 'mr-8',
									type: 'enum',
									items: summaryTypes.map(type => {
										return { label: type, value: type };
									})
								},
								{
									key: 'definition.summary_duration',
									component: EditFormTextField,
									className: 'mr-8',
									type: 'number'
								},
								{
									key: 'definition.summary_duration_unit',
									component: EditFormSelectField,
									type: 'enum',
									items: summaryDurationUnits
								}
							]
						}
					]
				}
			];

			return fields;
		}
	};
};

function SchedulerEditForm() {
	const { id, tabKey } = useParams();
	const dispatch = useDispatch();

	const myConfig = config({ id });
	return (
		<EditFormPage
			config={myConfig}
			startTabValue={tabKey}
			onSave={result => {
				if (!result.engineSuccessful) dispatch(showMessage({ message: `Engine: ${result.engineMessage}` }));
			}}
		/>
	);
}

export default withRouter(SchedulerEditForm);
