import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './i18n/en';
import hu from './i18n/hu';
import SchedulerTable from './table/SchedulerTable';
import SchedulerEditForm from './editform/SchedulerEditForm';
import UpdateScheduleEditForm from './editform/UpdateScheduleEditForm';
import UpdateTaskEditForm from './editform/UpdateTaskEditForm';

i18next.addResourceBundle('en', 'scheduler', en);
i18next.addResourceBundle('hu', 'scheduler', hu);

export default [
	{
		path: '/scheduler/update_schedule/:id',
		component: UpdateScheduleEditForm,
		auth: authRoles.scheduler
	},
	{
		path: '/scheduler/update_task/:id',
		component: UpdateTaskEditForm,
		auth: authRoles.scheduler
	},
	{
		path: '/scheduler/edit/:id/:tabKey?',
		component: SchedulerEditForm,
		auth: authRoles.scheduler
	},
	{
		path: '/scheduler/:sessionNameParam?',
		component: SchedulerTable,
		auth: authRoles.scheduler
	}
];
