const taskManagerHu = {
	taskManagerTable: 'Task Manager',
	name: 'Név',
	status: 'Státusz',
	type: 'Típus',
	ts: 'Ideje',
	result: 'Eredmény',
	error: 'Hiba',
	user_name: 'Felhasználónév',
	message: 'Üzenet',
	ts_result: 'Eredmény ideje',
	file_count: 'Fájlok'
};

export default taskManagerHu;
