import { TransFixDto } from 'app/admin/workflow/component/items/TransformationProperties';
import ModelEditDto from 'app/admin/model/dto/ModelEditDto';
import WorkflowModelInputAttributeEditDto from './WorkflowModelInputAttributeEditDto';
import WorkflowModelOutputAttributeEditDto from './WorkflowModelOutputAttributeEditDto';

export class WorkflowModelEditDto extends ModelEditDto {
	constructor() {
		super();
		/** @type {WorkflowModelInputAttributeEditDto[]} */
		this.input_attributes = null;
		/** @type {WorkflowModelOutputAttributeEditDto[]} */
		this.output_attributes = null;
		/** @type {string} */
		this.json = null;
		/** @type {string} */
		this.export = null;
		/** @type {string} */
		this.transformations = null;
	}

	/**
	 * @typedef {Object} _WorkflowModelEditDtoProps
	 * @property {WorkflowModelInputAttributeEditDto[]} input_attributes
	 * @property {WorkflowModelOutputAttributeEditDto[]} output_attributes
	 * @property {string} json
	 * @property {string} export
	 * @property {string} transformations
	 *
	 * @typedef {import("app/admin/model/dto/ModelEditDto").ModelEditDtoProps & _WorkflowModelEditDtoProps} WorkflowModelEditDtoProps
	 */
	/**
	 *
	 * @param {WorkflowModelEditDtoProps} props
	 * @returns {WorkflowModelEditDto}
	 */
	setup(props) {
		super.setup(props);
		TransFixDto(props);
		this.input_attributes = props.input_attributes !== null ? props.input_attributes.map(d => new WorkflowModelInputAttributeEditDto().setup(d)) : [];
		this.output_attributes = props.output_attributes !== null ? props.output_attributes.map(d => new WorkflowModelOutputAttributeEditDto().setup(d)) : [];
		this.json = props.json;
		this.export = props.export;
		this.transformations = props.transformations;
		return this;
	}
}

export default WorkflowModelEditDto;
