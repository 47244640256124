import nBossConnection from 'modules/base/service/nBossConnection';
import OcuEntityValidationResult from 'app/admin/common/dto/OcuEntityValidationResult';
import ScheduleEditDto from '../dto/ScheduleEditDto';
import { convertScheduleDto, parseScheduleDto } from './SchedulerEditService';

class UpdateScheduleEditService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'updateScheduleEditControl';
	}

	/**
	 * @param {number} id
	 * @return {Promise<ScheduleEditDto>}
	 */
	getData(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getData`, {
					id
				})
				.then(response => {
					const res = new ScheduleEditDto().setup(parseScheduleDto(response.data));
					res.id = id;
					resolve(res);
				})
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @param {ScheduleEditDto} dto
	 * @return {Promise<OcuEntityValidationResult>}
	 */
	update(id, dto) {
		const _dto = convertScheduleDto(dto);
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/update`, {
					id,
					dto: _dto
				})
				.then(response => resolve(new OcuEntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}
}

export default new UpdateScheduleEditService();
