import FusePageCarded from '@fuse/core/FusePageCarded/FusePageCarded';
import { Icon, IconButton, TextField, Tooltip, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';

const inputRow = 1000;
const useStyles = makeStyles(theme => ({
	root: {
		'& .container': {
			maxWidth: '100% !important'
		}
	}
}));

// function generateDto() {
// 	const dto = {};
// 	for (let i = 1; i <= inputRow; i += 1) {
// 		const subdto = {};
// 		for (let j = 1; j <= 5; j += 1) {
// 			subdto[j] = `${i}/${j}`;
// 		}
// 		dto[i] = subdto;
// 	}
// 	return dto;
// }

function TestPage(props) {
	const classes = useStyles();
	const [dto, setDto] = useState(null);

	useEffect(() => {
		// setDto(generateDto());
	}, []);

	// const changeInput = (row, column, value) => {
	// 	setDto({
	// 		...dto,
	// 		[row]: {
	// 			...dto[row],
	// 			[column]: value
	// 		}
	// 	});
	// };

	return (
		<FusePageCarded
			classes={{
				toolbar: 'p-0',
				header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
				root: classes.root
			}}
			header={<div className="flex flex-1 w-full items-center justify-between">Test</div>}
			content={
				<div className="p-16 sm:p-24">
					<table className="w-full">
						<tbody>
							hello
						</tbody>
					</table>
				</div>
			}
		/>
	);
}
export default withRouter(TestPage);
