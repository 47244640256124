import NamedDto from 'modules/base/dto/NamedDto';

export class AnnModelOutputAttributeEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.type = null;
		/** @type {string} */
		this.uom = null;
		/** @type {string[]} */
		this.components = null;
		/** @type {number} */
		this.orderIndex = null;
	}

	/**
	 * @typedef {Object} _AnnModelOutputAttributeEditDtoProps
	 * @property {string} type
	 * @property {string} uom
	 * @property {string[]} components
	 * @property {number} orderIndex
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _AnnModelOutputAttributeEditDtoProps} AnnModelOutputAttributeEditDtoProps
	 */
	/**
	 *
	 * @param {AnnModelOutputAttributeEditDtoProps} props
	 * @returns {AnnModelOutputAttributeEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.type = props.type;
		this.uom = props.uom;
		this.components = props.components !== null ? props.components : [];
		this.orderIndex = props.orderIndex;
		return this;
	}
}

export default AnnModelOutputAttributeEditDto;
