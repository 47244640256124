import _ from '@lodash';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import MapTableResult from 'app/admin/common/component/MapTableResult';

import TaskManagerService from 'app/admin/taskManager/service/TaskManagerService';
import { useState } from 'react';

export default function TaskManagerResultShap(props) {
	const { result, id, name } = props.item;
	const [response, setResponse] = useState(null);

	TaskManagerService.getFileContent(id, name).then(resp => setResponse(resp));

	console.log(response);
	return (
		<Dialog open fullWidth>
			<DialogTitle disableTypography className="border-b">
				{props.title || 'Shap Results'}
			</DialogTitle>
			<DialogContent className="p-20">
				{/* {result !== null && result.aspect !== null && <TaskManagerResultCalculateByType result={result.aspect} type="Aspect" />}
				{result !== null && result.transformer !== null && <TaskManagerResultCalculateByType result={result.transformer} type="Transformer" />} */}
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button
					onClick={() => {
						props.onClose();
					}}
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}
// import _ from '@lodash';
// import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
// import ImageList from '@material-ui/core/ImageList';
// import ImageListItem from '@material-ui/core/ImageListItem';
// import ImageListItemBar from '@material-ui/core/ImageListItemBar';
// import IconButton from '@material-ui/core/IconButton';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
// // import itemData from './itemData';

// const itemData = [
// 	{
// 		img: null,
// 		title: 'Image',
// 		author: 'author'
// 	}
// ];
// const useStyles = makeStyles(theme => ({
// 	root: {
// 		display: 'flex',
// 		flexWrap: 'wrap',
// 		justifyContent: 'space-around',
// 		overflow: 'hidden',
// 		backgroundColor: theme.palette.background.paper
// 	},
// 	imageList: {
// 		flexWrap: 'nowrap',
// 		// Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
// 		transform: 'translateZ(0)'
// 	},
// 	title: {
// 		color: theme.palette.primary.light
// 	},
// 	titleBar: {
// 		background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
// 	}
// }));

// export default function TaskManagerResultShap() {
// 	const classes = useStyles();

// 	return (
// 		<div className={classes.root}>
// 			<ImageList className={classes.imageList} cols={2.5}>
// 				{itemData.map(item => (
// 					<ImageListItem key={item.img}>
// 						<img src={item.img} alt={item.title} />
// 						<ImageListItemBar
// 							title={item.title}
// 							classes={{
// 								root: classes.titleBar,
// 								title: classes.title
// 							}}
// 							actionIcon={
// 								<IconButton aria-label={`star ${item.title}`}>
// 									<StarBorderIcon className={classes.title} />
// 								</IconButton>
// 							}
// 						/>
// 					</ImageListItem>
// 				))}
// 			</ImageList>
// 		</div>
// 	);
// }

