import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import { useParams, withRouter } from 'react-router';
import DriverLogEditService from '../service/DriverLogEditService';

const config = data => {
	return {
		key: 'driverLogEditForm',
		topic: 'driverLog',
		service: DriverLogEditService,
		fields: initData => {
			const fields = [
				{
					key: 'name',
					component: EditFormTextField,
					required: true
				},
				{
					key: 'table_name',
					component: EditFormTextField
				},
				{
					key: 'description',
					component: EditFormTextField,
					multiline: true
				}
			];

			return fields;
		}
	};
};

function DriverLogEditForm() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const myConfig = config({ id });
	return (
		<EditFormPage
			config={myConfig}
			onSave={result => {
				if (!result.engineSuccessful) dispatch(showMessage({ message: `Engine: ${result.engineMessage}` }));
			}}
		/>
	);
}

export default withRouter(DriverLogEditForm);
