import _ from '@lodash';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import MapTableResult from 'app/admin/common/component/MapTableResult';

export function TaskManagerResultCalculateByType(props) {
	const { result, type } = props;
	return (
		<>
			{result !== null && result.state !== null && (
				<div>
					<div className="pb-20 font-bold text-xl">{`${type} State`}</div>
					<MapTableResult data={result.state} />
				</div>
			)}
			{result !== null && result.output_values !== null && (
				<div>
					<div className="py-20 pt-40 font-bold text-xl">{`${type} Output values`}</div>
					<MapTableResult data={result.output_values} />
				</div>
			)}
		</>
	);
}

function TaskManagerResultCalculate(props) {
	const { result } = props.item;
	return (
		<Dialog open fullWidth>
			<DialogTitle disableTypography className="border-b">
				{props.title || 'What if result'}
			</DialogTitle>
			<DialogContent className="p-20">
				{result !== null && result.aspect !== null && <TaskManagerResultCalculateByType result={result.aspect} type="Aspect" />}
				{result !== null && result.transformer !== null && <TaskManagerResultCalculateByType result={result.transformer} type="Transformer" />}
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button
					onClick={() => {
						props.onClose();
					}}
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default TaskManagerResultCalculate;
