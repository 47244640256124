import { Tooltip } from '@material-ui/core';
import { useWhatIf } from './WhatIfProvider';

function DeviceButton(props) {
	const { deviceMode, setDeviceMode } = useWhatIf();

	return (
		<Tooltip title={props.title} placement="right">
			<div
				className={`w-80 h-80 p-12 rounded-lg shadow-lg cursor-pointer flex items-center justify-center transition bg-white ${
					deviceMode === props.deviceName ? 'ring-2 ring-green' : 'ring-1 ring-gray-300 opacity-50 filter grayscale hover:opacity-100 hover:grayscale-0'
				}`}
				onClick={() => setDeviceMode(props.deviceName)}
				role="button"
				tabIndex={0}
				aria-hidden="true"
			>
				<img src={props.imageUrl} alt={props.title} className="h-full" />
			</div>
		</Tooltip>
	);
}

export default DeviceButton;
