import ModelEditDto from 'app/admin/model/dto/ModelEditDto';
import OpcModelAttributeEditDto from './OpcModelAttributeEditDto';

export class OpcModelEditDto extends ModelEditDto {
	constructor() {
		super();
		/** @type {string} */
		this.endpoint = null;
		/** @type {OpcModelAttributeEditDto[]} */
		this.input_attributes = null;
		/** @type {OpcModelAttributeEditDto[]} */
		this.output_attributes = null;
	}

	/**
	 * @typedef {Object} _OpcModelEditDtoProps
	 * @property {string} endpoint
	 * @property {OpcModelAttributeEditDto[]} input_attributes
	 * @property {OpcModelAttributeEditDto[]} output_attributes
	 *
	 * @typedef {import("app/admin/model/dto/ModelEditDto").ModelEditDtoProps & _OpcModelEditDtoProps} OpcModelEditDtoProps
	 */
	/**
	 *
	 * @param {OpcModelEditDtoProps} props
	 * @returns {OpcModelEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.endpoint = props.endpoint;
		this.input_attributes = props.input_attributes !== null ? props.input_attributes.map(d => new OpcModelAttributeEditDto().setup(d)) : [];
		this.output_attributes = props.output_attributes !== null ? props.output_attributes.map(d => new OpcModelAttributeEditDto().setup(d)) : [];
		return this;
	}
}

export default OpcModelEditDto;
