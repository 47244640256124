import _ from '@lodash';
import { useState } from 'react';
import { useWhatIf } from '../component/WhatIfProvider';
import { formatLabelValue } from '../component/WhatIfUtil';
import LabelOnlyView from '../view/LabelOnlyView';
import LabelIconView from '../view/LabelIconView';
import IconOnlyView from '../view/IconOnlyView';

const itemBorder = 5;

function LabelItem(props) {
	const { item } = props;
	const LayerType = item.layerType;
	const [pos, setPos] = useState({ x: item.x, y: item.y });
	const { viewMode, editMode, figure, setFigure, setShowPropertiesDialog, setValueModify, setFloatValue, setOutputResultDialog, getExperimentAttribute, getCompareAttribute, getExperimentValidity, setFloatLabelCompare } = useWhatIf();
	const [isDragging, setIsDragging] = useState(false);
	//const handleFloat = viewMode === 'CALCULATE' && item.valueKey !== null && item.value !== null && _.isObject(item.value) && Object.keys(item.value).length > 1;
	const visible = !(viewMode === 'CALCULATE' && !_.isUndefined(item.visible) && item.visible === false);

	const handleItemClick = () => {
		if (editMode === 'DELETE') {
			setFigure({
				...figure,
				label: figure.label.filter(d => d.key !== item.key)
			});
		} else if (editMode === 'ROTATE') {
			const rotateNewValue = item.rotate ? item.rotate + 90 : 90;
			setFigure({
				...figure,
				label: figure.label.map(d => (d.key === item.key ? { ...d, rotate: rotateNewValue === 360 ? 0 : rotateNewValue } : d))
			});
		}
	};

	const updateDragPos = e => {
		const currentPos = { x: e.target.x(), y: e.target.y() };
		setPos(currentPos);
		setFigure({
			...figure,
			label: figure.label.map(d => {
				if (d.key === item.key) {
					return { ...item, ...currentPos };
				}
				return d;
			})
		});
	};

	const labelText = () => {
		if (viewMode === 'CALCULATE') {
			if (item.valueKey != null) {
				const attribute = getExperimentAttribute(item.valueKey);
				if (attribute === null || _.isUndefined(attribute)) return `${item.name}: No data`;
				if (_.isObject(attribute.value)) {
					const keys = Object.keys(attribute.value);
					if (keys.length > 0) {
						//return `${keys[0]}: ${formatter.format(item.value[keys[0]])}${keys.length > 1 ? ', ...' : ''}`;
						return `${item.name} Components`;
					}
					return `${item.name}: No data`;
				}
				return formatLabelValue(attribute.value, item, attribute.uom, item.name);
			}
		}
		return item.name;
	};

	const compareData = () => {
		if (viewMode === 'CALCULATE') {
			if (item.valueKey != null) {
				const comparedAttribute = getCompareAttribute(item.valueKey);
				const attribute = getExperimentAttribute(item.valueKey);
				if (_.isUndefined(attribute) || attribute === null || _.isUndefined(comparedAttribute) || comparedAttribute === null) return null;
				if (_.isNumber(attribute.value) && _.isNumber(comparedAttribute.value)) {
					const diff = attribute.value - comparedAttribute.value;
					const diffPercent = diff != null && diff !== 0 && comparedAttribute.value !== 0 ? diff / comparedAttribute.value : null;
					let diffLabel = `${item.name}: -`;
					const diffEpsilonValue = _.get(item, 'diffEpsilonValue', 0);
					const diffEpsilonPercent = _.get(item, 'diffEpsilonPercent', 0);
					let showDiff = false;

					if ((diffEpsilonValue === 0 && diffEpsilonPercent === 0) || (diffEpsilonValue > 0 && Math.abs(diff) > diffEpsilonValue) || (diffEpsilonPercent > 0 && Math.abs(diffPercent * 100) > diffEpsilonPercent)) {
						diffLabel = `${item.name}: ${diffPercent > 0 ? '+' : '-'} ${formatLabelValue(Math.abs(diffPercent), {
							thousandsSeparator: true,
							decimalPlaces: 2,
							displayAsPercentage: true,
							showUnit: true
						})} (${formatLabelValue(Math.abs(diff), item, item.uom)})`;
						showDiff = true;
					}

					return {
						current: attribute.value,
						currentLabel: `${formatLabelValue(attribute.value, item, attribute.uom /*, item.name*/)}`,
						base: comparedAttribute.value,
						baseLabel: `${formatLabelValue(comparedAttribute.value, item, comparedAttribute.uom /*, item.name*/)}`,
						diff,
						diffPercent,
						diffLabel,
						showDiff,
						item
					};
				}
			}
		}
		return null;
	};

	const isInvalid = () => {

		if (viewMode === 'CALCULATE' && item.valueKey != null) {
			const attribute = getExperimentAttribute(item.valueKey);
			if (attribute && attribute.io === 'OUTPUT' && !getExperimentValidity()) {
				return true;
			}
		}
		return false;
	};

	const handleDblClick = e => {
		setFloatValue(null);
		setFloatLabelCompare(null);
		if (viewMode === 'EDIT' && editMode === 'MOVE') {
			setShowPropertiesDialog({ type: 'LABEL', key: item.key });
			return true;
		}
		if (item.valueKey) {
			const attribute = getExperimentAttribute(item.valueKey);
			if (attribute !== null && !_.isUndefined(attribute)) {
				if (viewMode === 'CALCULATE' && item.valueKey !== null && attribute !== null && attribute.io === 'INPUT') {
					setValueModify(item);
				} else if (viewMode === 'CALCULATE' && item.valueKey !== null && attribute !== null && attribute.io === 'OUTPUT' && _.isObject(attribute.value)) {
					setOutputResultDialog(item);
				}
			}
		}

		return true;
	};

	if (!visible) {
		return null;
	}

	const ViewComponent = item.icon && item.showLabel ? LabelIconView : item.icon ? IconOnlyView : LabelOnlyView;

	return (
		<ViewComponent
			x={pos.x}
			y={pos.y}
			draggable={editMode === 'MOVE'}
			onPointerClick={e => handleItemClick(e)}
			onPointerDblClick={e => handleDblClick(e)}
			onDragStart={e => {
				setIsDragging(true);
			}}
			onDragMove={e => {
				updateDragPos(e);
			}}
			onDragEnd={e => {
				updateDragPos(e);
				setIsDragging(false);
			}}
			item={item}
			labelText={labelText()}
			compareData={compareData()}
			isDragging={isDragging}
			invalid={isInvalid()}
		/>
	);
}

export default LabelItem;
