import { authRoles } from 'app/auth';
import i18next from 'i18next';
import AspectEditForm from './editform/AspectEditForm';
import AspectTable from './table/AspectTable';
import en from './i18n/en';
import hu from './i18n/hu';

i18next.addResourceBundle('en', 'aspect', en);
i18next.addResourceBundle('hu', 'aspect', hu);

export default [
	{
		path: '/aspect/edit/:id/:tabKey?',
		component: AspectEditForm,
		auth: authRoles.aspect
	},
	{
		path: '/aspect/:sessionNameParam?',
		component: AspectTable,
		auth: authRoles.aspect
	}
];
