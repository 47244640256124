import OptimizerEditDto from './OptimizerEditDto';

export class ParticleSwarmOptimizerEditDto extends OptimizerEditDto {
	constructor() {
		super();
		/** @type {number} */
		this.max_iteration_count = null;
		/** @type {number} */
		this.particle_count = null;
		/** @type {number} */
		this.w = null;
		/** @type {number} */
		this.c1 = null;
		/** @type {number} */
		this.c2 = null;
	}

	/**
	 * @typedef {Object} _ParticleSwarmOptimizerEditDtoProps
	 * @property {number} max_iteration_count
	 * @property {number} particle_count
	 * @property {number} w
	 * @property {number} c1
	 * @property {number} c2
	 *
	 * @typedef {import("./OptimizerEditDto").OptimizerEditDtoProps & _ParticleSwarmOptimizerEditDtoProps} ParticleSwarmOptimizerEditDtoProps
	 */
	/**
	 *
	 * @param {ParticleSwarmOptimizerEditDtoProps} props
	 * @returns {ParticleSwarmOptimizerEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.max_iteration_count = props.max_iteration_count;
		this.particle_count = props.particle_count;
		this.w = props.w;
		this.c1 = props.c1;
		this.c2 = props.c2;
		return this;
	}
}

export default ParticleSwarmOptimizerEditDto;
