const whatIfHu = {
	whatIfGroupTable: 'What if group',
	whatIfGroupEditForm: 'What if group',
	whatIfTable: 'What if',
	whatIfExpertTable: 'What if',
	whatIfEditForm: 'What if',
	name: 'Név',
	created: 'Létrehozva',
	updated: 'Módosítva',
	back: 'Vissza',
	save: 'Mentés',
	cancel: 'Mégse',
	properties: 'Tulajdonságok',
	circleProperties: 'Kör tulajdonságok',
	deviceProperties: 'Eszköz tulajdonságok',
	labelProperties: 'Cimke tulajdonságok',
	rectProperties: 'Téglalap tulajdonságok',
	whatIfName: 'What If neve',
	saveSuccessful: 'Sikeres mentés',
	saveFailed: 'Hiba történt a mentés során, minden adat megadása kötelező vagy a név már használatban van',
	move: 'Mozgatás',
	resize: 'Átméretezés',
	rotate: 'Forgatás',
	mirror: 'tükrözés',
	devices: 'Eszközök',
	line: 'Vonal',
	delete: 'Törlés',
	label: 'Cimke',
	pump: 'Szivattyú',
	compressor: 'Kompresszor',
	airCooler: 'Léghűtő',
	furnace: 'Csőkemence',
	heatExchanger: 'Hőcserélő',
	distillationColumn: 'Desztillációs oszlop',
	circle: 'Kör',
	fillColorLabel: 'Kitöltési szín',
	fillLabel: 'Kitöltés',
	borderColorLabel: 'Keretszín',
	backgroundColorLabel: 'Háttérszín',
	fontColorLabel: 'Betűszín',
	color_black: 'Fekete',
	color_white: 'Fehér',
	color_darkRed: 'Piros',
	color_darkGreen: 'Zöld',
	color_darkBlue: 'Kék',
	fontSize: 'Betűméret',
	value: 'Érték',
	justALabel: 'Csak egy címke',
	imageProperties: 'Kép tulajdonságok',
	imageKey: 'Kép',
	image: 'Kép',
	none: 'Nincs',
	'group.name': 'Group',
	group: 'Group',
	decimalPlaces: 'Decimal places',
	thousandsSeparator: 'Thousands separator',
	displayAsPercentage: 'Display as percentage',
	workflow: 'Workflow',
	valueKey: 'Value key',
	type: 'Type',
	variable: 'Variable value',
	dict: 'Dict value',
	showUnit: 'Show unit',
	sumTable: 'Summary table',
	sumTableProperties: 'Summary table properties',
	minMax: 'Min/Max',
	documentProperties: 'Dokumentumok tulajdonságai',
	revision: 'Revízió',
	'creator.name': 'Feltöltő',
	file: 'Fájl',
	close: 'Bezár',
	visible: 'Látható',
	document: 'Document',
	note: 'Note',
	noteProperties: 'Note Properties',
	state: 'State',
	values: 'Values',
	reader: 'Reader',
	'aspect.name': 'Aspect',
	'transformer.name': 'Transformer',
	aspect: 'Aspect',
	icon: 'Ikon',
	iconSize: 'Ikonméret',
	showLabel: 'Címke látszik',
	input: 'Input',
	output: 'Output',
	tableInDialog: 'Táblázat megjelenítése ablakban',
	container: 'Konténer',
	containerProperties: 'Konténer tulajdonságok',
	figureNameNotUnique: 'Konténer neve nem egyedi',
	linkTo: 'Hivatkozás egy másik What if-re',
	linkToDialog1: 'Most átleszel irányítva a következő What if-re:',
	linkToDialog2: 'A mentetlen adatok elvesznek. Folytatja?',
	linkToError: 'Hivatkozott What If hiányzik vagy törölve lett.',
	piMeasurementReader: 'PI Measurement Reader',
	diffEpsilonValue: 'Difference epsilon value',
	diffEpsilonPercent: 'Difference epsilon percentage'
};

export default whatIfHu;
