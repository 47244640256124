import _ from '@lodash';
import { useRef, useState } from 'react';
import { useParams } from 'react-router';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Tabs, Tab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import NoteItemInput from './NoteItemInput';
import { useWhatIf } from '../component/WhatIfProvider';
import NoteItemList from './NoteItemList';
import ColorPickerField from '../component/ColorPickerField';

function NoteItemProperties(props) {
	const { id } = useParams('id');
	const { data, setData, figure, setFigure, showPropertiesDialog, setShowPropertiesDialog } = useWhatIf();
	const { t } = useTranslation(['whatIf', 'validation']);
	const [tabValue, setTabValue] = useState(0);
	const schema = yup.object().shape({
		name: yup.string().nullable().required(t('validation:FIELD_REQUIRED'))
	});
	const item = figure.note.find(d => d.key === showPropertiesDialog.key);
	const updateListRef = useRef(null);

	const methods = useForm({
		defaultValues: item,
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const handleCancel = () => {
		if (item.isNew)
			setFigure({
				...figure,
				note: figure.note.filter(d => d.key !== item.key)
			});
		setShowPropertiesDialog(null);
	};

	const handleSave = () => {
		const value = methods.getValues();
		value.isNew = false;
		setFigure({
			...figure,
			note: figure.note.map(c => (c.key === item.key ? value : c))
		});
		setShowPropertiesDialog(null);
	};

	return (
		<Dialog open maxWidth="lg" fullWidth>
			<DialogTitle disableTypography className="border-b">
				<div className="flex flex-row w-full">
					<div className="flex mr-24 items-center">{t('noteProperties')}</div>
					<Tabs
						value={tabValue}
						onChange={(e, newValue) => {
							setTabValue(newValue);
							if (newValue === 1 && updateListRef.current != null) updateListRef.current();
						}}
						aria-label="document-properties-tabs"
					>
						<Tab label="Basic" />
						<Tab label="Notes" />
					</Tabs>
				</div>
			</DialogTitle>
			<DialogContent className="p-20" style={{ height: 'calc(100vh - 300px)' }}>
				<div className={tabValue !== 0 ? 'hidden' : ''}>
					<FormProvider {...methods}>
						<Controller
							control={methods.control}
							name="name"
							render={({ field, fieldState }) => (
								<EditFormTextField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'name',
										text: t('name')
									}}
								/>
							)}
						/>
						<Controller
							control={methods.control}
							name="fontSize"
							render={({ field, fieldState }) => (
								<EditFormTextField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'fontSize',
										text: t('fontSize'),
										type: 'number'
									}}
								/>
							)}
						/>
						<Controller control={methods.control} name="borderColor" render={({ field, fieldState }) => <ColorPickerField key="borderColor" label="backgroundColorLabel" field={field} fieldState={fieldState} />} />
						<Controller control={methods.control} name="fillColor" render={({ field, fieldState }) => <ColorPickerField key="fillColor" label="fontColorLabel" field={field} fieldState={fieldState} />} />
					</FormProvider>
				</div>
				<div className={tabValue !== 1 ? 'hidden' : 'h-full flex flex-col'}>
					<NoteItemList whatIf={id === 'new' ? null : Number.parseInt(id, 10)} noteItemKey={item.key} updateListRef={updateListRef} />
					<NoteItemInput
						whatIf={id === 'new' ? null : Number.parseInt(id, 10)}
						noteItemKey={item.key}
						onChange={() => {
							if (updateListRef.current != null) updateListRef.current();
						}}
					/>
				</div>
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button onClick={() => handleCancel()}>{t('cancel')}</Button>
				<Button onClick={() => methods.handleSubmit(handleSave)()} color="primary" autoFocus>
					{t('save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default NoteItemProperties;
