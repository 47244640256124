import KeyedAndNamedDto from 'modules/base/dto/KeyedAndNamedDto';
import NamedDto from 'modules/base/dto/NamedDto';

export class WhatIfDocumentTableDto extends KeyedAndNamedDto {
	constructor() {
		super();
		/** @type {NamedDto} */
		this.whatIf = null;
		/** @type {NamedDto} */
		this.creator = null;
		/** @type {Date} */
		this.created = null;
		/** @type {number} */
		this.revision = null;
		/** @type {boolean} */
		this.lastRevision = null;
		/** @type {string} */
		this.file = null;
		/** @type {string} */
		this.documentItemKey = null;
	}

	/**
	 * @typedef {Object} _WhatIfDocumentTableDtoProps
	 * @property {NamedDto} creator
	 * @property {NamedDto} whatIf
	 * @property {number} revision
	 * @property {boolean} lastRevision
	 * @property {Date} created
	 * @property {string} file
	 * @property {string} documentItemKey
	 * @typedef {import("modules/base/dto/KeyedAndNamedDto").KeyedAndNamedDtoProps & _WhatIfDocumentTableDtoProps} WhatIfDocumentTableDtoProps
	 */
	/**
	 *
	 * @param {WhatIfDocumentTableDtoProps} props
	 * @returns {WhatIfDocumentTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.creator = props.creator !== null ? new NamedDto().setup(props.creator) : null;
		this.whatIf = props.whatIf !== null ? new NamedDto().setup(props.whatIf) : null;
		this.revision = props.revision;
		this.lastRevision = props.lastRevision;
		this.created = props.created != null ? new Date(props.created) : null;
		this.file = props.file;
		this.documentItemKey = props.documentItemKey;
		return this;
	}
}

export default WhatIfDocumentTableDto;
