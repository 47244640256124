import { authRoles } from 'app/auth';
import HysysModelEditForm from './editform/HysysModelEditForm';

export default [
	{
		path: '/model-hysys/edit/:id/:driverName?',
		component: HysysModelEditForm,
		auth: authRoles.model
	}
];
