import NamedDto from 'modules/base/dto/NamedDto';

export class OpcModelAttributeEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.type = null;
		/** @type {string} */
		this.uom = null;
		/** @type {string} */
		this.expression = null;
		/** @type {number} */
		this.orderIndex = null;
	}

	/**
	 * @typedef {Object} _OpcModelAttributeEditDtoProps
	 * @property {string} type
	 * @property {string} uom
	 * @property {string} expression
	 * @property {number} orderIndex
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _OpcModelAttributeEditDtoProps} OpcModelAttributeEditDtoProps
	 */
	/**
	 *
	 * @param {OpcModelAttributeEditDtoProps} props
	 * @returns {OpcModelAttributeEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.type = props.type;
		this.uom = props.uom;
		this.expression = props.expression;
		this.orderIndex = props.orderIndex;
		return this;
	}
}

export default OpcModelAttributeEditDto;
