import NamedDto from 'modules/base/dto/NamedDto';
import RegistrationStatusType from './RegistrationStatusType';

export default class UserDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.email = null;
		/** @type {Date} */
		this.registrationTimestamp = null;
		/** @type {Date} */
		this.lastLoginTimestamp = null;
		/** @type {string} */
		this.registrationStatus = null;
		/** @type {Date} */
		this.birthDate = null;
		/** @type {Array<string>} */
		this.roles = null;
		this.role = 'Adminisztrátor';
	}

	/**
	 * @typedef {Object} _UserDtoProps
	 * @property {string} email
	 * @property {Date} registrationTimestamp
	 * @property {Date} lastLoginTimestamp
	 * @property {string} registrationStatus
	 * @property {Date} birthDate
	 * @property {Array<string>} roles
	 *
	 * @typedef {import("../NamedDto").NamedDtoProps & _UserDtoProps} UserDtoProps
	 */
	/**
	 *
	 * @param {UserDtoProps} props
	 * @returns {UserDto}
	 */
	setup(props) {
		super.setup(props);
		this.email = props.email;
		this.registrationTimestamp = props.registrationTimestamp != null ? new Date(props.registrationTimestamp) : null;
		this.lastLoginTimestamp = props.lastLoginTimestamp != null ? new Date(props.lastLoginTimestamp) : null;
		this.registrationStatus = props.registrationStatus;
		this.birthDate = props.birthDate != null ? new Date(props.birthDate) : null;
		// this.roles = props.roles;
		this.roles = ['Menu', 'Content', 'Page', 'ContentType', 'PageTemplate', 'Webpage', 'Form', 'Storage', 'PartnerMail', 'UserAccount', 'UserRole'];
		return this;
	}
}
