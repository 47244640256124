import _ from '@lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { ThemeProvider } from 'styled-components';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { showMessage } from 'app/store/fuse/messageSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import FuseLoading from '@fuse/core/FuseLoading';
import EditFormGridComponent from 'modules/ui/editform/subForm/EditFormGridComponent';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormValueKeySelectButton from 'app/admin/common/component/EditFormValueKeySelectButton';
import { useWhatIf } from '../WhatIfProvider';
import { parseAttributesForTreeView } from '../EditFormValueKeySelect';
import WhatIfCompareViewEditService from '../../service/WhatIfCompareViewEditService';
import ColorPickerField from '../ColorPickerField';

const defaultValues = { id: null, name: null, groupList: [], whatIf: null };

export default function CompareViewEditDialog(props) {
	const { editId } = props;
	const { id } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation(['whatIf', 'validation']);

	const { data } = useWhatIf();
	const ioKeys = parseAttributesForTreeView(data.experiment && data.experiment.attributes ? data.experiment.attributes : null);
	const [loading, setLoading] = useState(false);
	const mainTheme = useSelector(selectMainTheme);

	const schema = yup.object().shape({
		name: yup.string().nullable().required(t('validation:FIELD_REQUIRED')),
		groupList: yup.array().min(1, t('validation:AT_LEAST_ONE_REQUIRED')).required(t('validation:FIELD_REQUIRED'))
	});
	const methods = useForm({
		defaultValues: {
			defaultValues,
			whatIf: Number.parseInt(id, 10)
		},
		resolver: yupResolver(schema),
		mode: 'onChange'
	});

	useEffect(() => {
		if (editId === 'new') {
			methods.reset({
				defaultValues,
				whatIf: Number.parseInt(id, 10)
			});
		} else if (_.isNumber(editId)) {
			setLoading(true);
			WhatIfCompareViewEditService.getData(editId).then(resp => {
				methods.reset(resp);
				setLoading(false);
			});
		}
	}, [editId]);

	const handleSave = dto => {
		if (editId === 'new') {
			setLoading(true);
			WhatIfCompareViewEditService.save(dto).then(resp => {
				handleSaveResp(resp);
			});
		} else if (editId != null) {
			setLoading(true);
			WhatIfCompareViewEditService.update(editId, dto).then(resp => {
				handleSaveResp(resp);
			});
		}
	};

	const handleSaveResp = resp => {
		setLoading(false);
		if (resp.successful) {
			dispatch(showMessage({ message: 'Sikeres mentés' }));
			props.onSave();
		} else {
			dispatch(showMessage({ message: 'Hiba történt a mentés rögzítése során' }));
		}
	};
	return (
		<ThemeProvider theme={mainTheme}>
			<Dialog open maxWidth="lg" fullWidth>
				<DialogTitle disableTypography className="border-b">
					Compare view edit
				</DialogTitle>
				<DialogContent className="p-20">
					<FormProvider {...methods}>
						{loading && <FuseLoading />}
						{!loading && (
							<>
								<Controller
									control={methods.control}
									name="name"
									render={({ field, fieldState }) => (
										<EditFormTextField
											field={field}
											fieldState={fieldState}
											config={{ topic: 'whatIf' }}
											fieldConfig={{
												key: 'name'
											}}
										/>
									)}
								/>
								<EditFormGridComponent
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'groupList',
										component: EditFormGridComponent,
										orderable: true,
										defaultValue: {
											id: null,
											orderIndex: null,
											headerColor: null,
											attributeList: []
										},
										fields: [
											{
												key: 'name',
												text: 'Group name',
												component: EditFormTextField,
												required: true
											},
											{
												key: 'headerColor',
												text: 'Header color',
												component: ColorPickerField
											},
											{
												key: 'attributeList',
												text: 'Attributes',
												component: EditFormValueKeySelectButton,
												required: true,
												ioKeys
											}
										]
									}}
								/>
								{_.get(methods, 'formState.errors.groupList.message') && <p className="text-red">{methods.formState.errors.groupList.message}</p>}
								{_.get(methods, 'formState.errors.groupList.root.message') && <p className="text-red">{methods.formState.errors.groupList.root.message}</p>}
							</>
						)}
					</FormProvider>
				</DialogContent>
				<DialogActions className="justify-between border-t p-20">
					<Button
						onClick={() => {
							props.onCancel();
						}}
						disabled={loading}
					>
						Cancel
					</Button>
					<Button onClick={methods.handleSubmit(handleSave)}>Save</Button>
				</DialogActions>
			</Dialog>
		</ThemeProvider>
	);
}
