import _ from '@lodash';
import { useEffect, useState, useRef } from 'react';
import { Rect, Image, Circle, Group, Transformer, Text } from 'react-konva';
import { useWhatIf } from '../component/WhatIfProvider';
import { rotatePoint, rotatePointArr, calcJoinPointsByLinePoints } from '../component/WhatIfUtil';

const calcBorderLinePoints = (data, item, offset) => {
	const { imgSize, itemBorder } = data;
	const _w = imgSize.w * item.scaleX + itemBorder * 2;
	const _h = imgSize.h * item.scaleY + itemBorder * 2;
	const tl = [item.x - offset.x, item.y - offset.y];
	const tr = [tl[0] + _w, item.y - offset.y];
	const bl = [tl[0], item.y + offset.y];
	const br = [tr[0], item.y + offset.y];
	const o = [item.x, item.y];
	return [...rotatePointArr(tl, o, item.rotate), ...rotatePointArr(tr, o, item.rotate), ...rotatePointArr(br, o, item.rotate), ...rotatePointArr(bl, o, item.rotate), ...rotatePointArr(tl, o, item.rotate)];
};

function DeviceItem(props) {
	const groupRef = useRef();
	const imgRef = useRef();
	const trRef = useRef();

	const { item } = props;
	const { img, imgSize, itemBorder, joinPointRadius } = props.data;
	const offset = {
		x: (imgSize.w * item.scaleX) / 2 + itemBorder,
		y: (imgSize.h * item.scaleY) / 2 + itemBorder
	};

	const labelWidth = item.rotate === 90 || item.rotate === 270 ? imgSize.h * item.scaleY : imgSize.w * item.scaleX;

	const borderLinePoints = calcBorderLinePoints(props.data, item, offset);
	const joinPoints = calcJoinPointsByLinePoints(borderLinePoints);

	const { editMode, data, setData, figure, setFigure, underEditing, setUnderEditing, initLine, endLine, joinPointAlreadyConnected, deviceAlreadyConnected, setShowPropertiesDialog, checkStageCorner } = useWhatIf();
	const [image, setImage] = useState(null);
	const [isDragging, setIsDragging] = useState(false);
	const [activeJoinPoint, setActiveJoinPoint] = useState(null);
	const [pos, setPos] = useState({ x: item.x, y: item.y });

	useEffect(() => {
		const imageObj = new window.Image();
		imageObj.onload = function () {
			setImage(imageObj);
		};
		imageObj.src = img;
	}, []);

	useEffect(() => {
		if (editMode === 'RESIZE' && underEditing !== null && underEditing.key === item.key) {
			trRef.current.nodes([imgRef.current]);
			trRef.current.getLayer().batchDraw();
		}
	}, [underEditing]);

	const handleItemClick = () => {
		if (editMode === 'DELETE') {
			setFigure({
				...figure,
				//line: removeLinesByKeys(figure.line, [item.key]),
				device: figure.device.filter(d => d.key !== item.key)
			});
		} else if (editMode === 'ROTATE') {
			setFigure({
				...figure,
				device: figure.device.map(d => {
					if (d.key === item.key) {
						const rotateNewValue = d.rotate ? d.rotate + 90 : 90;
						return {
							...d,
							rotate: rotateNewValue === 360 ? 0 : rotateNewValue
						};
					}
					return d;
				})
			});
		} else if (editMode === 'RESIZE') {
			setUnderEditing(item);
		} else if (editMode === 'MIRROR') {
			setFigure({
				...figure,
				device: figure.device.map(d => {
					if (d.key === item.key) {
						const mirrorNewValue = _.isNumber(d.mirror) ? d.mirror + 1 : 1;
						return {
							...d,
							mirror: mirrorNewValue > 3 ? 0 : mirrorNewValue
						};
					}
					return d;
				})
			});
		}
	};

	const handleItemEnter = e => {
		if (editMode === 'LINE') {
			//setIsJoinMode(true);
		}
	};

	const handleItemLeave = e => {
		if (editMode === 'LINE') {
			//setIsJoinMode(false);
		}
	};

	const handleJoinPointEnter = id => {
		if (editMode === 'LINE') {
			setActiveJoinPoint(id);
		}
	};

	const handleJoinPointLeave = i => {
		if (editMode === 'LINE') {
			setActiveJoinPoint(null);
		}
	};

	const handleJoinPointClick = (id, e) => {
		if (editMode === 'LINE') {
			const joinPoint = joinPoints[id];
			if (underEditing !== null) {
				endLine(joinPoint.x, joinPoint.y);
			} else {
				initLine(joinPoint.x, joinPoint.y);
			}
		}
	};

	const updateDragPos = e => {
		const currentPos = { x: e.target.x(), y: e.target.y() };
		setPos(currentPos);
		setFigure({
			...figure,
			device: figure.device.map(d => {
				if (d.key === item.key) {
					return { ...d, ...currentPos };
				}
				return d;
			})
		});
	};

	const removeLinesByKeys = (lineList, keys) => {
		const deletedLineKeys = [];
		let newLineList = lineList;
		keys.forEach(key => {
			newLineList = lineList.filter(l => {
				if (l.startPoint !== null && l.startPoint.deviceKey === key) {
					deletedLineKeys.push(l.key);
					return false;
				}
				if (l.endPoint !== null && l.endPoint.deviceKey === key) {
					deletedLineKeys.push(l.key);
					return false;
				}
				return true;
			});
		});
		if (deletedLineKeys.length > 0) return removeLinesByKeys(newLineList, deletedLineKeys);
		return newLineList;
	};

	const handleResize = e => {
		const imgNode = imgRef.current;
		const _w = imgNode.width() * imgNode.scaleX();
		const _h = imgNode.height() * imgNode.scaleY();
		const scaleX = _w / imgSize.w;
		const scaleY = _h / imgSize.h;

		let currentPos;
		if (item.rotate !== null && item.rotate > 0) {
			const rotatedStartPoint = rotatePoint({ x: pos.x - offset.x, y: pos.y - offset.y }, pos, item.rotate);
			if (item.rotate === 90) currentPos = { x: rotatedStartPoint.x - imgNode.y() - (imgSize.h * scaleY) / 2, y: rotatedStartPoint.y + imgNode.x() + (imgSize.w * scaleX) / 2 };
			else if (item.rotate === 180) currentPos = { x: rotatedStartPoint.x - imgNode.x() - (imgSize.w * scaleX) / 2, y: rotatedStartPoint.y - imgNode.y() - (imgSize.h * scaleY) / 2 };
			else currentPos = { x: rotatedStartPoint.x + imgNode.y() + (imgSize.h * scaleY) / 2, y: rotatedStartPoint.y - imgNode.x() - (imgSize.w * scaleX) / 2 };
		} else {
			currentPos = { x: pos.x - offset.x + imgNode.x() + (imgSize.w * scaleX) / 2, y: pos.y - offset.y + imgNode.y() + (imgSize.h * scaleY) / 2 };
		}
		setFigure({
			...figure,
			figure: figure.device.map(d => {
				if (d.key === item.key) {
					return { ...item, ...currentPos, scaleX, scaleY };
				}
				return d;
			})
		});
		setPos(currentPos);
	};

	const scaleValue = () => {
		if (item.mirror === 1) return { x: -1, y: 1 };
		if (item.mirror === 2) return { x: 1, y: -1 };
		if (item.mirror === 3) return { x: -1, y: -1 };
		return { x: 1, y: 1 };
	};

	return (
		<>
			<Group
				ref={groupRef}
				x={pos.x}
				y={pos.y}
				offsetX={offset.x}
				offsetY={offset.y}
				width={imgSize.w * item.scaleX + itemBorder * 2}
				height={imgSize.h * item.scaleY + itemBorder * 2}
				onPointerEnter={e => handleItemEnter(e)}
				onPointerLeave={e => handleItemLeave(e)}
				onPointerClick={e => handleItemClick(e)}
				onPointerDblClick={() => editMode === 'MOVE' && setShowPropertiesDialog({ type: 'DEVICE', key: item.key })}
				draggable={editMode === 'MOVE' /* && !deviceAlreadyConnected(item.key)*/}
				onDragStart={() => {
					setIsDragging(true);
				}}
				onDragMove={e => {
					updateDragPos(e);
					checkStageCorner();
				}}
				onDragEnd={e => {
					setIsDragging(false);
					updateDragPos(e);
				}}
				rotation={item.rotate || 0}
				scale={scaleValue()}
			>
				<Image ref={imgRef} x={itemBorder} y={itemBorder} width={imgSize.w * item.scaleX} height={imgSize.h * item.scaleY} image={image} onTransformEnd={e => handleResize(e)} scaleX={1} scaleY={1} />
				<Rect x={0} y={0} width={imgSize.w * item.scaleX + itemBorder * 2} height={imgSize.h * item.scaleY + itemBorder * 2} dash={[10, 10]} stroke="black" strokeWidth={1} opacity={isDragging ? 1 : 0} />
			</Group>
			{joinPoints.map((j, i) => (
				<Circle
					key={i}
					x={j.x}
					y={j.y}
					radius={joinPointRadius}
					fill="darkgreen"
					opacity={activeJoinPoint === i ? 1 : 0}
					onPointerEnter={e => handleJoinPointEnter(i)}
					onPointerLeave={e => handleJoinPointLeave(i)}
					onPointerClick={e => handleJoinPointClick(i, e)}
				/>
			))}
			{!_.isEmpty(item.name) && (underEditing === null || underEditing.key !== item.key) && (
				<Text
					text={item.name}
					fontSize={14}
					fill="black"
					fontStyle="bold"
					x={pos.x}
					y={pos.y}
					width={labelWidth}
					offsetX={labelWidth / 2}
					offsetY={8}
					align="center"
					stroke="white"
					strokeWidth={1}
					fillAfterStrokeEnabled
					listening={false}
				/>
			)}
			{underEditing !== null && underEditing.key === item.key && (
				<Transformer
					ref={trRef}
					padding={itemBorder}
					borderDash={[10, 10]}
					borderStroke="black"
					borderStrokeWidth={1}
					anchorStroke="black"
					rotateEnabled={false}
					boundBoxFunc={(oldBox, newBox) => {
						if (newBox.width < 50 || newBox.height < 50) {
							return oldBox;
						}
						return newBox;
					}}
				/>
			)}
		</>
	);
}

export default DeviceItem;
