import nBossConnection from 'modules/base/service/nBossConnection';

class FittingCaseService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'fittingCaseControl';
	}
	/**
	 * @param {string} fitterLogName
	 * @param {number} id
	 * @return {Promise<Object>}
	 */

	createFitterLog(fitterLogName, id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/createFitterLog`, {
					fitterLogName,
					id
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}
export default new FittingCaseService();
