import _ from '@lodash';
import WorkflowConverter from 'app/admin/workflow/component/WorkflowConverter';
import { useWorkflow } from 'app/admin/workflow/component/WorkflowProvider';
import { useSelector, useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import { ThemeProvider } from '@material-ui/core/styles';
import { useState } from 'react';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { useFormContext } from 'react-hook-form';
import { Button, Icon, Dialog, DialogTitle, DialogContent, DialogActions, Paper, TableContainer, Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';
import WorkflowModelEditService from '../service/WorkflowModelEditService';

function WorkflowModelDebug(props) {
	const mainTheme = useSelector(selectMainTheme);
	const { setCalculate } = useWorkflow();
	const { getValues } = useFormContext();
	const dispatch = useDispatch();
	const { id } = props;
	const [loading, setLoading] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [result, setResult] = useState(null);
	const [message, setMessage] = useState(null);

	const handleSave = () => {
		setLoading(true);
		setMessage(null);
		const dto = getValues();
		const exportDto = dto.json ? WorkflowConverter(dto.name, dto.json) : null;
		if (dto.name === null || dto.name.length === 0) {
			dispatch(showMessage({ message: 'Model name is required.' }));
		} else if (exportDto.input_attributes === null || exportDto.input_attributes.length === 0 || exportDto.output_attributes === null || exportDto.output_attributes.length === 0) {
			dispatch(showMessage({ message: 'Input/output attributes is required.' }));
		} else {
			const values = {};
			exportDto.input_attributes.forEach(element => {
				values[element.name] = element.value;
			});
			if (id !== 'new') {
				WorkflowModelEditService.update(dto.id, dto).then(resp => handleStoreResponse(resp, dto, values));
			} else {
				WorkflowModelEditService.save(dto).then(resp => handleStoreResponse(resp, dto, values));
			}
		}
	};

	const handleClick = () => {
		setLoading(false);
		setResult(null);
		setMessage(null);
		handleSave();
		setShowDialog(true);
	};

	const handleStoreResponse = (resp, dto, values) => {
		if (resp.successful && resp.engineSuccessful) {
			WorkflowModelEditService.debug(dto.name, values)
				.then(response => handleResponse(response))
				.catch(e => handleCatch(e));
		} else {
			setLoading(false);
			setResult(null);
			setMessage(!resp.engineSuccessful ? resp.engineMessage : 'Save workflow failed.');
		}
	};

	const handleResponse = response => {
		setLoading(false);
		if (response !== null && _.isObject(response)) {
			console.log(response);
			setShowDialog(false);
			if (response.transformer) setCalculate(response);
			else setCalculate({ transformer: response });
		} else {
			setResult(null);
			if (_.isString(response)) {
				setMessage(response);
			}
		}
	};

	const handleCatch = err => {
		console.log(err);
		setLoading(false);
		setResult(null);
		setMessage(`Debug connection problem: ${err !== null ? err : 'Unkown'}`);
	};

	return (
		<>
			<Button className="whitespace-nowrap mx-4" variant="contained" color="secondary" disabled={id === 'new'} onClick={() => handleClick()} startIcon={<Icon className="hidden sm:flex">bug_report</Icon>}>
				Debug
			</Button>
			<ThemeProvider theme={mainTheme}>
				<Dialog open={showDialog} fullWidth>
					<DialogTitle disableTypography className="border-b">
						Debug
					</DialogTitle>
					<DialogContent className="p-20">
						{loading && <FuseLoading />}
						{!loading && result !== null && result.length > 0 && (
							<TableContainer component={Paper}>
								<Table className="w-full" aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Name</TableCell>
											<TableCell align="right">Value</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{result !== null &&
											result.map(row => (
												<TableRow key={row.name}>
													<TableCell component="th" scope="row" colSpan={row.parent ? 2 : 1} className={row.parent ? 'font-bold' : row.sub ? 'pl-32' : ''}>
														{row.name}
													</TableCell>
													{!row.parent && (
														<TableCell className="font-mono" align="right">
															{row.value}
														</TableCell>
													)}
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
						{!loading && (result === null || result.length === 0) && <div className="flex justify-center">{message || 'No result.'}</div>}
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button
							onClick={() => {
								setShowDialog(false);
							}}
						>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</>
	);
}

export default WorkflowModelDebug;
