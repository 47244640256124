import _ from '@lodash';
import { useSelector } from 'react-redux';
import TransformerService from 'app/admin/workflow/service/TransformerService';
import AspectService from 'app/admin/aspect/service/AspectService';
import FuseLoading from '@fuse/core/FuseLoading';
import { Button, Icon, ThemeProvider, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { useWhatIf } from './WhatIfProvider';
import { getInvalidValue } from './WhatIfUtil';

export function RenderErrorMessage(props) {
	const { error } = props;
	if (error.type === 'lt' || error.type === 'gt') return <p className="text-red">{`${error.name}${error.property ? `.${error.property}` : ''}: is ${error.type === 'gt' ? 'greater than' : 'less than'} ${error.limitValue}`}</p>;
	if (error.type === 'missing') return <p className="text-red">{`${error.name}${error.property ? `.${error.property}` : ''}: missing ${error.valueKey}`}</p>;
	return <p className="text-red">{`${error.name}${error.property ? `.${error.property}` : ''}: unknown error`}</p>;
}

export default function WhatIfCalculate(props) {
	const mainTheme = useSelector(selectMainTheme);
	const [showDialog, setShowDialog] = useState(false);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState(null);
	const [calculateResult, setCalculateResult] = useState(null);
	const [valueErrorList, setValueErrorList] = useState([]);
	const { data, setExperiment } = useWhatIf();
	const user = useSelector(({ auth }) => auth.user);
	const isOperator = user.roleList.length === 1 && user.roleList[0].title === 'Operator';
	const connectMode = isOperator ? _.get(data, 'properties.operatorVisibility.connectBtnVisible', 'VISIBLE') : 'VISIBLE';

	const handleClick = () => {
		setMessage(null);
		setValueErrorList([]);
		setLoading(true);
		setShowDialog(true);
	};

	useEffect(() => {
		if (showDialog) {
			if (data.experiment && data.experiment.attributes) {
				const invalidResult = getInvalidValue(data.experiment.attributes);
				if (invalidResult.length > 0) {
					setValueErrorList(invalidResult);
					setLoading(false);
				} else {
					const state = {};
					data.experiment.attributes.forEach(attribute => {
						if (attribute.io === 'INPUT') {
							state[attribute.name] = attribute.value;
						}
					});
					if (data.experiment.transformer || data.experiment.aspect) {
						TransformerService.calculateAndTransform(data.experiment.aspect || null, data.experiment.transformer || null, state, connectMode === 'AUTO_CONNECT')
							.then(response => {
								setLoading(false);
								console.log(response);
								if (_.isObject(response)) {
									setCalculateResult(response);
								} else if (_.isString(response)) {
									setMessage(response);
								} else {
									setMessage('Unknown error');
								}
							})
							.catch(err => handleCatch(err));
					} else {
						setLoading(false);
						setMessage('No aspect or transformer selected');
					}
				}
			} else {
				setLoading(false);
				setMessage('No aspect or transformer selected');
			}
		}
	}, [showDialog]);

	const handleCatch = err => {
		console.log(err);
		setLoading(false);
		setMessage(`Calculate connection problem: ${err !== null ? err : 'Unkown'}`);
	};

	useEffect(() => {
		if (calculateResult != null && showDialog) {
			if (data.experiment) {
				setExperiment({
					...data.experiment,
					validity: true,
					attributes: data.experiment.attributes.map(item => {
						if (item.io === 'OUTPUT' && item.entity === 'aspect' && calculateResult.aspect && calculateResult.aspect.output_values) {
							if (!_.isUndefined(calculateResult.aspect.output_values[item.name])) return { ...item, value: calculateResult.aspect.output_values[item.name] };
							return { ...item, value: null };
						}
						if (item.io === 'OUTPUT' && item.entity === 'transformer' && calculateResult.transformer && calculateResult.transformer.res_output_attributes) {
							if (!_.isUndefined(calculateResult.transformer.res_output_attributes[item.name])) return { ...item, value: calculateResult.transformer.res_output_attributes[item.name] };
							return { ...item, value: null };
						}
						return item;
					})
				});
			}
			setCalculateResult(null);
			setShowDialog(false);
		}
	}, [calculateResult]);

	return (
		<>
			<Button className="whitespace-nowrap mx-4" variant="contained" color="secondary" onClick={() => handleClick()} startIcon={<Icon className="hidden sm:flex">graphic_eq</Icon>}>
				Calculate
			</Button>
			<ThemeProvider theme={mainTheme}>
				<Dialog open={showDialog} fullWidth>
					<DialogTitle disableTypography className="border-b">
						Calculating...
					</DialogTitle>
					<DialogContent className="p-20">
						{loading && <FuseLoading />}
						{!loading && message && <span>{message}</span>}
						{!loading && valueErrorList.length > 0 && valueErrorList.map((err, i) => <RenderErrorMessage error={err} />)}
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button
							onClick={() => {
								setShowDialog(false);
							}}
						>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</>
	);
}
