import KeyedAndNamedDto from 'modules/base/dto/KeyedAndNamedDto';

export class WorkflowModelOutputAttributeEditDto extends KeyedAndNamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.type = null;
	}

	/**
	 * @typedef {Object} _WorkflowModelOutputAttributeEditDtoProps
	 * @property {string} type
	 *
	 * @typedef {import("modules/base/dto/KeyedAndNamedDto").KeyedAndNamedDtoProps & _WorkflowModelOutputAttributeEditDtoProps} WorkflowModelOutputAttributeEditDtoProps
	 */
	/**
	 *
	 * @param {WorkflowModelOutputAttributeEditDtoProps} props
	 * @returns {WorkflowModelOutputAttributeEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.type = props.type;
		return this;
	}
}

export default WorkflowModelOutputAttributeEditDto;
