import _ from '@lodash';
import PictogramService from 'app/admin/pictogram/service/PictogramService';
import { useRef, useState, createContext, useContext, useEffect } from 'react';
import { generateId, findRightAnglePoints, distanceBetweenPoints } from './WhatIfUtil';

const slideSensitivity = 20;
const jumpSensitivity = 25;
const cornerPercent = 25;

export const WhatIfContext = createContext(null);

export const useWhatIf = () => {
	return useContext(WhatIfContext);
};

export default function WhatIfProvider(props) {
	const { mode } = props;
	const [iconset, setIconset] = useState([]);
	const [stage, setStage] = useState(null);
	const [valueModify, setValueModify] = useState(null);
	const [outputResultDialog, setOutputResultDialog] = useState(null);
	const [summaryTableDialog, setSummaryTableDialog] = useState(null);
	const [floatValue, setFloatValue] = useState(null);
	const [viewMode, setViewMode] = useState(mode === 'EXPERT' ? 'EDIT' : 'CALCULATE');
	const [editMode, setEditMode] = useState(mode === 'EXPERT' ? 'MOVE' : 'NONE');
	const [deviceMode, setDeviceMode] = useState('PUMP');
	const [data, setData] = useState(null);
	const [figure, setFigure] = useState(null);
	const [figurePath, setFigurePath] = useState([]);
	const [underEditing, setUnderEditing] = useState(null);
	const [showPropertiesDialog, setShowPropertiesDialog] = useState(null);
	const [linkToWhatIf, setLinkToWhatIf] = useState(null);
	const [compare, setCompare] = useState(null);
	const [floatLabelCompare, setFloatLabelCompare] = useState(null);

	useEffect(() => {
		PictogramService.getListSelection().then(resp => {
			setIconset(resp);
		});
	}, []);

	useEffect(() => {
		setUnderEditing(null);
	}, [editMode]);

	const initLine = (x, y) => {
		const line = {
			key: generateId(),
			layerType: 'LINE',
			type: 'LINE',
			points: [x, y],
			newPoints: [x, y]
		};
		setUnderEditing(line);
		setFigure({
			...figure,
			line: [...figure.line, line]
		});
	};

	const storeLine = (x, y) => {
		const line = {
			...underEditing,
			points: [...underEditing.points, ...underEditing.newPoints],
			newPoints: [x, y]
		};
		setUnderEditing(line);
		setFigure({
			...figure,
			line: figure.line.map(d => {
				if (d.key === line.key) return line;
				return d;
			})
		});
	};

	const drawLine = (x, y) => {
		const line = {
			...underEditing,
			newPoints: findRightAnglePoints(underEditing.points, x, y)
		};
		setUnderEditing(line);
		setFigure({
			...figure,
			line: figure.line.map(d => {
				if (d.key === line.key) return line;
				return d;
			})
		});
	};

	const endLine = (x, y) => {
		if (underEditing.points.length > 0) {
			const lastPoint = underEditing.points.slice(-2);
			if (Math.abs(x - lastPoint[0]) < 6 && Math.abs(y - lastPoint[1]) < 6) {
				setUnderEditing(null);
				return;
			}
		}
		const checkDistance = 20;
		let finalPoints = [...underEditing.points, ...findRightAnglePoints(underEditing.points, x, y)];
		// csatlakozási ponthoz igazítás ha lehetséges(bekavarhat a fedés ellenőrzéséhez)
		if (underEditing.points.length > 2) {
			const lastPoints = finalPoints.slice(-4);
			const lastPointDistance = distanceBetweenPoints({ x: lastPoints[0], y: lastPoints[1] }, { x: lastPoints[2], y: lastPoints[3] });
			if (lastPointDistance < checkDistance) {
				const lastPoint = underEditing.points.slice(-2);
				if (Math.abs(lastPoint[0] - x) < checkDistance) lastPoint[0] = x;
				else if (Math.abs(lastPoint[1] - y) < checkDistance) lastPoint[1] = y;
				finalPoints = [...underEditing.points.slice(0, -2), ...lastPoint, x, y];
			}
		}

		const line = {
			...underEditing,
			points: finalPoints
		};
		delete line.newPoints;

		setUnderEditing(null);
		setFigure({
			...figure,
			line: figure.line.map(d => {
				if (d.key === line.key) return line;
				return d;
			})
		});
	};

	const joinPointAlreadyConnected = (deviceKey, joinPointId) => {
		const result = figure.line.filter(l => {
			if (l.startPoint !== null && l.startPoint.deviceKey === deviceKey && l.startPoint.joinPointId === joinPointId) return true;
			if (l.endPoint !== null && l.endPoint.deviceKey === deviceKey && l.endPoint.joinPointId === joinPointId) return true;
			return false;
		});
		return result.length > 0;
	};

	const deviceAlreadyConnected = deviceKey => {
		const result = figure.line.filter(l => {
			if (l.startPoint !== null && l.startPoint.deviceKey === deviceKey) return true;
			if (l.endPoint !== null && l.endPoint.deviceKey === deviceKey) return true;
			return false;
		});
		return result.length > 0;
	};

	const checkStageCorner = () => {
		const mousePos = stage.getPointerPosition();
		const stageWidth = stage.width();
		const stageHeight = stage.height();
		const horizontalSensitivity = stageWidth * (cornerPercent / 100);
		const verticalSensitivity = stageHeight * (cornerPercent / 100);
		if ((mousePos.y <= slideSensitivity && mousePos.x <= horizontalSensitivity) || (mousePos.x <= slideSensitivity && mousePos.y <= verticalSensitivity)) {
			//TOPLEFT
			setFigure({ ...figure, stage: { ...figure.stage, x: figure.stage.x + jumpSensitivity, y: figure.stage.y + jumpSensitivity } });
		} else if ((mousePos.x >= stageWidth - horizontalSensitivity && mousePos.y <= slideSensitivity) || (mousePos.x >= stageWidth - slideSensitivity && mousePos.y <= verticalSensitivity)) {
			//TOPRIGHT
			setData({ ...data, stage: { ...figure.stage, x: figure.stage.x - jumpSensitivity, y: figure.stage.y + jumpSensitivity } });
		} else if ((mousePos.x <= slideSensitivity && mousePos.y >= stageHeight - verticalSensitivity) || (mousePos.x <= horizontalSensitivity && mousePos.y >= stageHeight - slideSensitivity)) {
			//BOTTOMLEFT
			setData({ ...data, stage: { ...figure.stage, x: figure.stage.x + jumpSensitivity, y: figure.stage.y - jumpSensitivity } });
		} else if ((mousePos.x >= stageWidth - horizontalSensitivity && mousePos.y >= stageHeight - slideSensitivity) || (mousePos.x >= stageWidth - slideSensitivity && mousePos.y >= stageHeight - verticalSensitivity)) {
			//BOTTOMRIGHT
			setData({ ...data, stage: { ...figure.stage, x: figure.stage.x - jumpSensitivity, y: figure.stage.y - jumpSensitivity } });
		} else if (mousePos.x <= slideSensitivity) {
			//LEFT
			setData({ ...data, stage: { ...figure.stage, x: figure.stage.x + jumpSensitivity } });
		} else if (mousePos.x >= stageWidth - slideSensitivity) {
			//RIGHT
			setData({ ...data, stage: { ...figure.stage, x: figure.stage.x - jumpSensitivity } });
		} else if (mousePos.y <= slideSensitivity) {
			//TOP
			setData({ ...data, stage: { ...figure.stage, y: figure.stage.y + jumpSensitivity } });
		} else if (mousePos.y >= stageHeight - slideSensitivity) {
			//BOTTOM
			setData({ ...data, stage: { ...figure.stage, y: figure.stage.y - jumpSensitivity } });
		}
	};

	const changeViewMode = view => {
		if (view === 'CALCULATE') setEditMode('NONE');
		else setEditMode('MOVE');
		setValueModify(null);
		setViewMode(view);
	};

	const setExperiment = experiment => {
		const keyList = [];
		experiment.attributes.forEach(attribute => {
			keyList.push(`${attribute.entity}.${attribute.io}.${attribute.name}`);
			if (_.isObject(attribute.value)) {
				Object.keys(attribute.value).forEach(subKey => {
					keyList.push(`${attribute.entity}.${attribute.io}.${attribute.name}.${subKey}`);
				});
			}
		});
		setData({
			...data,
			experiment
		});
		setFigure({
			...figure,
			label: figure.label.map(label => {
				if (label.valueKey !== null && keyList.indexOf(label.valueKey) === -1) {
					return { ...label, valueKey: null };
				}
				return label;
			}),
			sumTable: _.isArray(figure.sumTable)
				? figure.sumTable.map(sumTable => {
						return { ...sumTable, valueKeyList: sumTable.valueKeyList.filter(v => keyList.indexOf(v) > -1) };
				  })
				: []
		});
	};

	const getIOValueKeySelection = layerType => {
		if (data.experiment && data.experiment.attributes) {
			return data.experiment.attributes
				.filter(d => {
					if (_.isUndefined(d.entity)) d.entity = 'aspect';
					if (layerType.LayerType.split(' ')[1] === 'Input' && d.io === 'INPUT') return true;
					if (layerType.LayerType.split(' ')[1] === 'Output' && d.entity === 'aspect' && d.io === 'OUTPUT') return true;
					if (layerType.LayerType.split(' ')[1] === 'Transformer' && d.entity === 'transformer' && d.io === 'OUTPUT') return true;

					return false;
				})
				.map(d => {
					if (_.isUndefined(d.entity)) d.entity = 'aspect';
					let lbl = null;
					let valu = null;
					if (layerType.LayerType.split(' ')[1] === 'Input' && d.io === 'INPUT') {
						lbl = d.name;
						valu = `${d.entity}.${d.io}.${d.name}`;
						return {
							label: lbl,
							value: valu
						};
					}
					if (layerType.LayerType.split(' ')[1] === 'Output' && d.entity === 'aspect' && d.io === 'OUTPUT') {
						lbl = d.name;
						valu = `${d.entity}.${d.io}.${d.name}`;
						return {
							label: lbl,
							value: valu
						};
					}
					if (layerType.LayerType.split(' ')[1] === 'Transformer' && d.entity === 'transformer' && d.io === 'OUTPUT') {
						lbl = d.name;
						valu = `${d.entity}.${d.io}.${d.name}`;
						return {
							label: `Calculated ${lbl}`,
							value: valu
						};
					}
					return {
						label: lbl,
						value: valu
					};
				});
		}
		return [];
	};

	const getExperimentAttribute = valueKey => {
		if (valueKey.startsWith('INPUT.') || valueKey.startsWith('OUTPUT.')) {
			valueKey = `aspect.${valueKey}`;
		}
		if (valueKey.startsWith('aspect.') || valueKey.startsWith('transformer.')) {
			const valueKeyParts = valueKey.split('.');
			return data.experiment && _.isArray(data.experiment.attributes)
				? data.experiment.attributes.find(d => ((d.entity && d.entity === valueKeyParts[0]) || (_.isUndefined(d.entity) && valueKeyParts[0] === 'aspect')) && d.io === valueKeyParts[1] && d.name === valueKeyParts[2])
				: null;
		}
		return data.experiment && _.isArray(data.experiment.attributes) ? data.experiment.attributes.find(d => d.name === valueKey) : null;
	};

	const getCompareAttribute = valueKey => {
		if (compare != null && _.isArray(compare.attributes)) {
			if (valueKey.startsWith('INPUT.') || valueKey.startsWith('OUTPUT.')) {
				valueKey = `aspect.${valueKey}`;
			}
			if (valueKey.startsWith('aspect.') || valueKey.startsWith('transformer.')) {
				const valueKeyParts = valueKey.split('.');
				return compare.attributes.find(d => ((d.entity && d.entity === valueKeyParts[0]) || (_.isUndefined(d.entity) && valueKeyParts[0] === 'aspect')) && d.io === valueKeyParts[1] && d.name === valueKeyParts[2]);
			}
			return _.isArray(compare.attributes) ? compare.attributes.find(d => d.name === valueKey) : null;
		}
		return null;
	};

	const setExperimentAttributeValue = (name, value) => {
		if (name.startsWith('INPUT.') || name.startsWith('OUTPUT.')) {
			name = `aspect.${name}`;
		}
		if (name.startsWith('aspect.') || name.startsWith('transformer.')) {
			const valueKeyParts = name.split('.');
			setData({
				...data,
				experiment: {
					...data.experiment,
					validity: false,
					attributes: data.experiment.attributes.map(item => {
						if (((item.entity && item.entity === valueKeyParts[0]) || (_.isUndefined(item.entity) && valueKeyParts[0] === 'aspect')) && item.name === valueKeyParts[2] && item.io === valueKeyParts[1]) {
							return { ...item, value };
						}
						return item;
					})
				}
			});
		} else {
			setData({
				...data,
				experiment: {
					...data.experiment,
					validity: false,
					attributes: data.experiment.attributes.map(item => {
						if (item.name === name) {
							return { ...item, value };
						}
						return item;
					})
				}
			});
		}
	};

	const getIconsetKey = iconId => {
		const icon = iconset.find(d => d.id === iconId);
		return icon ? icon.file : null;
	};

	const deleteFigure = figureName => {
		setData({ ...data, figure: _deleteFigure(data.figure, figureName) });
	};

	const _deleteFigure = (figureList, figureName) => {
		let result = figureList.filter(f => f.name !== figureName);
		const subs = result.filter(f => f.parent === figureName);
		if (subs) {
			subs.forEach(sub => {
				result = _deleteFigure(subs.name);
			});
		}
		return result;
	};

	const changeFigure = figureName => {
		const _figure = data.figure.find(f => f.name === figureName);
		if (_figure) {
			setData({
				...data,
				figure: data.figure.map(f => {
					if (f.name === figure.name) return figure;
					return f;
				})
			});
			setFigure(_figure);
		}
	};

	const getExperimentValidity = () => {
		return _.get(data, 'experiment.validity', true);
	};

	useEffect(() => {
		if (figure == null || figure.name === 'main') {
			setFigurePath([]);
		} else {
			const _figurePath = [figure.name];
			let parentName = figure.parent;
			while (parentName != null) {
				const _parentName = parentName;
				const parentFigure = data.figure.find(f => f.name === _parentName);
				parentName = parentFigure.parent;
				_figurePath.push(parentFigure.name);
			}
			setFigurePath(_figurePath.reverse());
		}
	}, [figure]);

	const value = {
		mode,
		stage,
		setStage,
		editMode,
		setEditMode,
		data,
		setData,
		figure,
		setFigure,
		underEditing,
		setUnderEditing,
		initLine,
		storeLine,
		drawLine,
		endLine,
		deviceMode,
		setDeviceMode,
		joinPointAlreadyConnected,
		deviceAlreadyConnected,
		showPropertiesDialog,
		setShowPropertiesDialog,
		checkStageCorner,
		viewMode,
		changeViewMode,
		valueModify,
		setValueModify,
		floatValue,
		setFloatValue,
		outputResultDialog,
		setOutputResultDialog,
		setExperiment,
		getIOValueKeySelection,
		getExperimentAttribute,
		setExperimentAttributeValue,
		iconset,
		getIconsetKey,
		summaryTableDialog,
		setSummaryTableDialog,
		deleteFigure,
		changeFigure,
		figurePath,
		linkToWhatIf,
		setLinkToWhatIf,
		compare,
		setCompare,
		getCompareAttribute,
		getExperimentValidity,
		floatLabelCompare,
		setFloatLabelCompare
	};
	return <WhatIfContext.Provider value={value}>{props.children}</WhatIfContext.Provider>;
}
