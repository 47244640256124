import ModelEditDto from 'app/admin/model/dto/ModelEditDto';
import AnnModelInputAttributeEditDto from './AnnModelInputAttributeEditDto';
import AnnModelOutputAttributeEditDto from './AnnModelOutputAttributeEditDto';

export class AnnModelEditDto extends ModelEditDto {
	constructor() {
		super();
		/** @type {AnnModelInputAttributeEditDto[]} */
		this.input_attributes = null;
		/** @type {AnnModelOutputAttributeEditDto[]} */
		this.output_attributes = null;
		/** @type {string} */
		this.model_zip_file = null;
		/** @type {string} */
		this.xscaler_file = null;
		/** @type {string} */
		this.yscaler_file = null;
		/** @type {string} */
		this.state_file = null;
	}

	/**
	 * @typedef {Object} _AnnModelEditDtoProps
	 * @property {AnnModelInputAttributeEditDto[]} input_attributes
	 * @property {AnnModelOutputAttributeEditDto[]} output_attributes
	 * @property {string} model_zip_file
	 * @property {string} xscaler_file
	 * @property {string} yscaler_file
	 * @property {string} state_file
	 *
	 * @typedef {import("app/admin/model/dto/ModelEditDto").ModelEditDtoProps & _AnnModelEditDtoProps} AnnModelEditDtoProps
	 */
	/**
	 *
	 * @param {AnnModelEditDtoProps} props
	 * @returns {AnnModelEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.input_attributes = props.input_attributes !== null ? props.input_attributes.map(d => new AnnModelInputAttributeEditDto().setup(d)) : [];
		this.output_attributes = props.output_attributes !== null ? props.output_attributes.map(d => new AnnModelOutputAttributeEditDto().setup(d)) : [];
		this.model_zip_file = props.model_zip_file;
		this.xscaler_file = props.xscaler_file;
		this.yscaler_file = props.yscaler_file;
		this.state_file = props.state_file;
		return this;
	}
}

export default AnnModelEditDto;
