const optimizerLogEn = {
	optimizerLogTable: 'Optimizer Log',
	timestamp: 'Timestamp',
	tag: 'Tag',
	objective: 'Objective',
	state: 'State',
	name: 'Name',
	optimizerLogEditForm: 'Optimizer Log',
	basic: 'Basic',
	table_name: 'Table Name',
	description: 'Description',
	fields: 'Fields',
	type: 'Type'
};

export default optimizerLogEn;
