import nBossConnection from 'modules/base/service/nBossConnection';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import DriverLogTableService from '../service/DriverLogTableService';

const config = {
	key: 'driverLogTable',
	topic: 'driverLog',
	service: DriverLogTableService,
	editFormState: item => `/driver-log/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['search', 'refresh', 'new'],
	rowAction: ['exportData', 'modify', 'delete'],
	exportData: {
		tooltip: 'Export to XLSX',
		link: item => `${nBossConnection.basePath}driverLogTableControl/exportToXlsx?name=${item.name}`
	},
	columns: [
		{
			key: 'name'
		},
		{
			key: 'table_name'
		}
	]
};

function DriverLogTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('driverLogTable', reducer('driverLogTable'))(DriverLogTable);
