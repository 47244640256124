import _ from '@lodash';

export function roundCoord(num) {
	return Math.round(num / 10) * 10;
}

export function roundPos(pos) {
	return {
		x: Math.round(pos.x / 10) * 10,
		y: Math.round(pos.y / 10) * 10
	};
}

export function listConverter(data, field) {
	const result = {};
	data[field].forEach(c => {
		const row = data.rows.find(r => c.row === r.key);
		if (row) {
			result[row.name] = { Conversion: c.value };
		}
	});
	return result;
}

export function gridConverter(data, field) {
	const result = {};
	data[field].forEach(g => {
		const row = data.rows.find(r => g.row === r.key);
		const column = data.columns.find(c => g.column === c.key);
		if (row && column) {
			if (_.isUndefined(result[row.name])) {
				result[row.name] = {};
			}
			result[row.name][column.name] = g.value;
		}
	});
	return result;
}

export const findValueInCalculate = (calculate, item) => {
	if (calculate != null) {
		if (calculate.transformer && calculate.transformer.input_attributes) if (item.io === 'INPUT' && !_.isUndefined(calculate.transformer.input_attributes[item.valueKey])) return calculate.transformer.input_attributes[item.valueKey];
		if (calculate.transformer.res_output_attributes) if (item.io === 'OUTPUT' && !_.isUndefined(calculate.transformer.res_output_attributes[item.valueKey])) return calculate.transformer.res_output_attributes[item.valueKey];
		if (!_.isUndefined(calculate[item.valueKey])) return calculate[item.valueKey];
	}
	return null;
};

export const findTransformValueInCalculate = (calculate, transformName, name) => {
	if (calculate != null && calculate.transformer) {
		if (_.isObject(calculate.transformer.transformations) && !_.isUndefined(calculate.transformer.transformations[transformName] && !_.isUndefined(calculate.transformer.transformations[transformName][name])))
			return calculate.transformer.transformations[transformName][name];
	}
	return null;
};

export const validationItemColor = validation => {
	if (validation.entries.findIndex(v => v.severity === 'Error') > -1) return 'indianRed';
	return 'lemonChiffon';
};

export const validationLineColor = validation => {
	if (validation && validation.entries && validation.entries.length > 0) {
		if (validation.entries.findIndex(v => v.severity === 'Error') > -1) return 'crimson';
		return 'khaki';
	}
	return 'darkGreen';
};
