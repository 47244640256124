import nBossConnection from './nBossConnection';
import TableSessionDataDto from '../dto/table/TableSessionDataDto';
import TableVariableDto from '../dto/table/TableVariableDto';
import TableFilterListDto from '../dto/table/TableFilterListDto';
import FilterSelectionDto from '../dto/table/FilterSelectionDto';
import NamedDto from '../dto/NamedDto';
import TableOrderListDto from '../dto/table/TableOrderListDto';
import EntityValidationResult from '../dto/validation/EntityValidationResult';

class GenericTableService {
	constructor() {
		this.connection = nBossConnection.connection;
	}

	/**
	 * @param {string} session
	 * @param {boolean} create
	 * @param {TableFilterListDto} filter
	 * @return {Promise<TableSessionDataDto>}
	 */
	getInstance(session, create, filter) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getInstance`, {
					session,
					create,
					filter
				})
				.then(response => resolve(new TableSessionDataDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {string} session
	 * @param {number} firstResult
	 * @param {number} maxResults
	 * @return {Promise<Object>}
	 */
	getList(session, firstResult, maxResults) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getList`, {
					session,
					firstResult,
					maxResults
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} session
	 * @param {string} propertyName
	 * @param {boolean} ascending
	 * @return {Promise<boolean>}
	 */
	setOrder(session, propertyName, ascending) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/setOrder`, {
					session,
					propertyName,
					ascending
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} session
	 * @param {TableOrderListDto} order
	 * @return {Promise<boolean>}
	 */
	setOrderList(session, order) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/setOrder`, {
					session,
					order
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} session
	 * @param {TableVariableDto} variable
	 * @return {Promise<boolean>}
	 */
	setVariable(session, variable) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/setVariable`, {
					session,
					variable
				})
				.then(response => {
					resolve(response.data);
				})
				.catch(reject);
		});
	}

	/**
	 * @param {string} session
	 * @param {TableFilterListDto} filter
	 * @return {Promise<boolean>}
	 */
	setFilter(session, filter) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/setFilter`, {
					session,
					filter
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} session
	 * @return {Promise<FilterSelectionDto>}
	 */
	getFilterSelection(session) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getFilterSelection`, {
					session
				})
				.then(response => resolve(new FilterSelectionDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {string} session
	 * @param {string} filterName
	 * @param {number} firstResult
	 * @param {number} maxResults
	 * @param {string} query
	 * @return {Promise<Array.<NamedDto>>}
	 */
	getFilterSelectionQuery(session, filterName, firstResult, maxResults, query) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getFilterSelection`, {
					session,
					filterName,
					firstResult,
					maxResults,
					query
				})
				.then(response => resolve(new FilterSelectionDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {string} session
	 * @param {number} id
	 * @return {Promise<EntityValidationResult>}
	 */
	deleteById(session, id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/deleteById`, {
					session,
					id
				})
				.then(response => resolve(response.data !== null ? new EntityValidationResult().setup(response.data) : null))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<EntityValidationResult>}
	 */
	delete(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/deleteById`, {
					id
				})
				.then(response => resolve(response.data !== null ? new EntityValidationResult().setup(response.data) : null))
				.catch(reject);
		});
	}

	/**
	 * @param {string} session
	 * @param {boolean} add
	 * @return {Promise<boolean>}
	 */
	selectAll(session, add) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/selectAll`, {
					session,
					add
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} session
	 * @return {Promise<boolean>}
	 */
	selectClear(session) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/selectClear`, {
					session
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} session
	 * @param {number} id
	 * @param {boolean} add
	 * @return {Promise<boolean>}
	 */
	selectOne(session, id, add) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/selectOne`, {
					session,
					id,
					add
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default GenericTableService;
