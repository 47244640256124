// Internet Explorer 11 requires polyfills and partially supported by this project.
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import '@openfonts/poppins_latin-ext';
import './i18n';
import './react-chartjs-2-defaults';
import './styles/app-base.css';
import './styles/app-components.css';
import './styles/app-utilities.css';
import 'react-advanced-cropper/dist/style.css';
import App from 'app/App';
import reportWebVitals from './reportWebVitals';
/*
window.onerror = function (error, source, lineno, colno) {
	// Print the error message
	let result = '';
	result += 'Message : ' + error + '<br>';
	result += 'Url : ' + source + '<br>';
	result += 'Line number : ' + lineno + '<br>';
	result += 'Column number : ' + colno + '<br>';
	result += 'Error Object : ' + error + '<br>';
	console.log(result);
};
*/
ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals();
