import IdentifiableDto from '../IdentifiableDto';

export class FileResultDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {string} */
		this.key = null;
		/** @type {string} */
		this.fileName = null;
		/** @type {string} */
		this.mimeType = null;
		/** @type {string} */
		this.fileSize = null;
	}

	/**
	 * @typedef {Object} _FileResultDtoProps
	 * @property {string} key
	 * @property {string} fileName
	 * @property {string} mimeType
	 * @property {string} fileSize
	 *
	 * @typedef {import("../IdentifiableDto").IdentifiableDtoProps & _FileResultDtoProps} IdentifiableDtoProps
	 */
	/**
	 *
	 * @param {IdentifiableDtoProps} props
	 * @returns {FileResultDto}
	 */
	setup(props) {
		super.setup(props);
		this.key = props.key;
		this.fileName = props.fileName;
		this.mimeType = props.mimeType;
		this.fileSize = props.fileSize;
		return this;
	}
}

export default FileResultDto;
