import _ from '@lodash';
import WorkflowItem from '../WorkflowItem';

export const defaultDefinition = {
	name: null,
	input_fields: [],
	output_fields: [],
	aspect_name: null
};

export const converter = function (data) {
	return data;
};

export default function AspectTransformation(props) {
	return <WorkflowItem {...props} type="ASPECT" />;
}
