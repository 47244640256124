const measurementEn = {
	measurementTable: 'Measurement',
	name: 'Name',
	measurementEditForm: 'Measurement',
	basic: 'Basic',
	table_name: 'Table Name',
	description: 'Description',
	fields: 'Fields',
	role: 'Role',
	type: 'Type'
};

export default measurementEn;
