import _ from '@lodash';
import { useState } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Tabs, Tab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import ValueStyleConfigFields from 'app/admin/common/component/ValueStyleConfigFields';
import defaultLabelStyle from 'app/admin/common/component/DefaultLabelStyle';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import { useWhatIf } from '../component/WhatIfProvider';
import EditFormValueKeySelect, { parseAttributesForTreeView } from '../component/EditFormValueKeySelect';
import ColorPickerField from '../component/ColorPickerField';

function SumTableItemProperties(props) {
	const { data, figure, setFigure, showPropertiesDialog, setShowPropertiesDialog, iconset } = useWhatIf();
	const { t } = useTranslation('whatIf');
	const item = figure.sumTable.find(d => d.key === showPropertiesDialog.key);
	if (_.isUndefined(item.fillColor)) item.fillColor = 'black';
	if (_.isUndefined(item.borderColor)) item.borderColor = 'white';
	if (_.isUndefined(item.fontSize)) item.fontSize = 12;
	if (_.isUndefined(item.showLabel)) item.showLabel = true;
	if (_.isUndefined(item.icon)) item.icon = null;
	if (_.isUndefined(item.iconSize)) item.iconSize = 64;
	if (_.isUndefined(item.showLabel)) item.showLabel = true;
	if (_.isUndefined(item.tableInDialog)) item.tableInDialog = false;

	if (_.isUndefined(item.variableStyle)) {
		item.variableStyle = defaultLabelStyle(false);
	}
	if (_.isUndefined(item.componentStyle)) {
		item.componentStyle = defaultLabelStyle(true);
	}
	const ioKeys = parseAttributesForTreeView(data.experiment && data.experiment.attributes ? data.experiment.attributes : null);
	const [tabValue, setTabValue] = useState(0);
	const methods = useForm({
		defaultValues: item
	});

	const handleSave = () => {
		const value = methods.getValues();
		setFigure({
			...figure,
			sumTable: figure.sumTable.map(c => (c.key === item.key ? value : c))
		});
		setShowPropertiesDialog(null);
	};

	const renderStyleFields = () => {
		return (
			<>
				<ValueStyleConfigFields prefix="variableStyle" title="Variable" t={t} methods={methods} />
				<ValueStyleConfigFields prefix="componentStyle" title="Component" t={t} methods={methods} />
			</>
		);
	};

	return (
		<Dialog open fullWidth maxWidth="lg">
			<DialogTitle disableTypography className="border-b">
				<div className="flex flex-row w-full">
					<div className="flex mr-24 items-center">{t('sumTableProperties')}</div>
					<Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} aria-label="sum-table-properties-tabs">
						<Tab label="Fields" />
						<Tab label="Properties" />
						<Tab label="Value styles" />
					</Tabs>
				</div>
			</DialogTitle>
			<DialogContent className="p-20">
				<FormProvider {...methods}>
					<div className={tabValue !== 0 ? 'hidden' : ''}>
						<Controller
							control={methods.control}
							name="name"
							render={({ field, fieldState }) => (
								<EditFormTextField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'name',
										text: t('name')
									}}
								/>
							)}
						/>
						<Controller
							control={methods.control}
							name="valueKeyList"
							render={({ field, fieldState }) => (
								<EditFormValueKeySelect
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'valueKeyList',
										text: t('valueKeyList'),
										items: ioKeys
									}}
								/>
							)}
						/>
					</div>
					<div className={tabValue !== 1 ? 'hidden' : ''}>
						<Controller
							control={methods.control}
							name="tableInDialog"
							render={({ field, fieldState }) => (
								<EditFormCheckboxField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'tableInDialog',
										text: t('tableInDialog')
									}}
								/>
							)}
						/>
						<Controller
							control={methods.control}
							name="icon"
							render={({ field, fieldState }) => (
								<EditFormSelectField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'icon',
										text: t('icon'),
										items: iconset
									}}
								/>
							)}
						/>
						<div className="flex flex-row">
							<Controller
								control={methods.control}
								name="fontSize"
								render={({ field, fieldState }) => (
									<EditFormTextField
										field={field}
										fieldState={fieldState}
										config={{ topic: 'whatIf' }}
										fieldConfig={{
											key: 'fontSize',
											text: t('fontSize'),
											type: 'number',
											className: 'mr-8'
										}}
									/>
								)}
							/>
							<Controller
								control={methods.control}
								name="iconSize"
								render={({ field, fieldState }) => (
									<EditFormTextField
										field={field}
										fieldState={fieldState}
										config={{ topic: 'whatIf' }}
										fieldConfig={{
											key: 'iconSize',
											text: t('iconSize'),
											type: 'number',
											className: 'ml-8 mr-8'
										}}
									/>
								)}
							/>
							<Controller
								control={methods.control}
								name="showLabel"
								render={({ field, fieldState }) => (
									<EditFormCheckboxField
										field={field}
										fieldState={fieldState}
										config={{ topic: 'whatIf' }}
										fieldConfig={{
											key: 'showLabel',
											text: t('showLabel')
										}}
									/>
								)}
							/>
						</div>
						<Controller control={methods.control} name="borderColor" render={({ field, fieldState }) => <ColorPickerField key="borderColor" label="backgroundColorLabel" field={field} fieldState={fieldState} />} />
						<Controller control={methods.control} name="fillColor" render={({ field, fieldState }) => <ColorPickerField key="fillColor" label="fontColorLabel" field={field} fieldState={fieldState} />} />
					</div>
					<div className={tabValue !== 2 ? 'hidden' : ''}>{renderStyleFields()}</div>
				</FormProvider>
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button
					onClick={() => {
						setShowPropertiesDialog(null);
					}}
				>
					{t('cancel')}
				</Button>
				<Button onClick={() => handleSave()} color="primary" autoFocus>
					{t('save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
export default SumTableItemProperties;
