import _ from 'lodash';
import NamedDto from 'modules/base/dto/NamedDto';
import RegistrationStatusType from './RegistrationStatusType';

export default class UserTableDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.email = null;
		/** @type {Date} */
		this.lastLoginTimestamp = null;
		/** @type {boolean} */
		this.enabled = null;
		/** @type {NamedDto[]>} */
		this.roleList = null;
		/** @type {Date} */
		this.created = null;
	}

	/**
	 * @typedef {Object} _UserTableDtoProps
	 * @property {string} email
	 * @property {Date} lastLoginTimestamp
	 * @property {boolean} enabled
	 * @property {NamedDto[]} roleList
	 * @property {Date} created
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _UserTableDtoProps} UserTableDtoProps
	 */
	/**
	 *
	 * @param {UserTableDtoProps} props
	 * @returns {UserTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.email = props.email;
		this.lastLoginTimestamp = props.lastLoginTimestamp != null ? new Date(props.lastLoginTimestamp) : null;
		this.enabled = props.enabled;
		this.roleList = props.roleList != null ? props.roleList.map(d => new NamedDto().setup(d)) : [];
		this.created = props.created != null ? new Date(props.created) : null;
		return this;
	}

	getAsString(key) {
		if (key === 'roleList') {
			return _.isArray(this.roleList) ? this.roleList.map(d => d.name).join(', ') : '';
		}
		return super.getAsString(key);
	}
}
