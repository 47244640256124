import UserTableService from 'app/admin/user/service/UserTableService';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import PictogramTableService from '../service/PictogramTableService';

const config = {
	key: 'pictogramTable',
	topic: 'pictogram',
	service: PictogramTableService,
	editFormState: item => `/pictogram/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['search', 'refresh', 'new'],
	rowAction: ['modify', 'delete'],
	columns: [
		{
			key: 'name'
		}
	]
};

function PictogramTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('pictogramTable', reducer('pictogramTable'))(PictogramTable);
