import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import DriverTableService from '../service/DriverTableService';
import DriverOpenTableCell from '../component/DriverOpenTableCell';
import DriverConnectedTableCell from '../component/DriverConnectedTableCell';

const config = {
	key: 'driverTable',
	topic: 'driver',
	service: DriverTableService,
	editFormState: item => `/driver/edit/${item ? item.id : 'new'}`,
	toolbarAction: [/*'search', */ 'refresh', 'new'],

	rowAction: ['modify', 'delete'],
	columns: [
		{
			key: 'name',
			sortable: false
		},
		{
			key: 'open',
			text: 'Open',
			component: DriverOpenTableCell,
			sortable: false,
			disablePadding: false
		},
		{
			key: 'connected',
			text: 'Connected',
			component: DriverConnectedTableCell,
			sortable: false,
			disablePadding: false
		},
		{
			key: 'lastTime',
			text: 'Last time',
			type: 'dateTime',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'autoCloseTimeout',
			text: 'Autoclose timeout',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'activeUntil',
			text: 'Active until',
			type: 'dateTime',
			sortable: true,
			disablePadding: false
		}
	]
};

function DriverTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('driverTable', reducer('driverTable'))(DriverTable);
