import _ from '@lodash';
import { useState } from 'react';
import { useWhatIf } from '../component/WhatIfProvider';
import LabelOnlyView from '../view/LabelOnlyView';
import LabelIconView from '../view/LabelIconView';
import IconOnlyView from '../view/IconOnlyView';

function ContainerItem(props) {
	const { item } = props;
	const [pos, setPos] = useState({ x: item.x, y: item.y });
	const { viewMode, editMode, figure, setFigure, setShowPropertiesDialog, deleteFigure, changeFigure, setLinkToWhatIf } = useWhatIf();
	const [isDragging, setIsDragging] = useState(false);
	const [onOver, setOnOver] = useState(false);
	const visible = !(viewMode === 'CALCULATE' && !_.isUndefined(item.visible) && item.visible === false);

	const handleItemClick = () => {
		if (editMode === 'DELETE') {
			setFigure({
				...figure,
				container: figure.container.filter(d => d.key !== item.key)
			});
			deleteFigure(item.name);
		} else if (editMode === 'ROTATE') {
			const rotateNewValue = item.rotate ? item.rotate + 90 : 90;
			setFigure({
				...figure,
				container: figure.container.map(d => (d.key === item.key ? { ...d, rotate: rotateNewValue === 360 ? 0 : rotateNewValue } : d))
			});
		}
	};

	const updateDragPos = e => {
		const currentPos = { x: e.target.x(), y: e.target.y() };
		setPos(currentPos);
		setFigure({
			...figure,
			container: figure.container.map(d => {
				if (d.key === item.key) {
					return { ...item, ...currentPos };
				}
				return d;
			})
		});
	};

	const labelText = () => {
		return item.name;
	};

	const handleDblClick = e => {
		console.log('ContainerItem');
		if (item.linkTo) {
			setLinkToWhatIf(item.linkTo);
		} else {
			changeFigure(item.name);
		}
		return true;
	};

	if (!visible) {
		return null;
	}

	const ViewComponent = item.icon && item.showLabel ? LabelIconView : item.icon ? IconOnlyView : LabelOnlyView;

	return (
		<ViewComponent
			x={pos.x}
			y={pos.y}
			draggable={editMode === 'MOVE'}
			onPointerClick={e => handleItemClick(e)}
			onPointerDblClick={e => handleDblClick(e)}
			onPointerEnter={e => setOnOver(true)}
			onPointerLeave={e => setOnOver(false)}
			onDragStart={e => {
				setIsDragging(true);
			}}
			onDragMove={e => {
				updateDragPos(e);
			}}
			onDragEnd={e => {
				updateDragPos(e);
				setIsDragging(false);
			}}
			item={item}
			labelText={labelText()}
			isDragging={isDragging}
			enablePropertiesBtn={onOver && viewMode === 'EDIT' && editMode === 'MOVE'}
			onPropertiesClick={() => setShowPropertiesDialog({ type: 'CONTAINER', key: item.key })}
		/>
	);
}

export default ContainerItem;
