const modelEn = {
	modelTable: 'Model',
	modelEditForm: 'Model',
	modelHysysEditForm: 'Model Hysys',
	modelAnnEditForm: 'Model Ann',
	annModelEditForm: 'Model Ann',
	expressionModelEditForm: 'Model Expression',
	workflowModelEditForm: 'Model Workflow',
	addAnn: 'Ann',
	addHysys: 'Hysys',
	addExpression: 'Expression',
	name: 'Name',
	model_file_content: 'Model File',
	model_descriptor_file_content: 'Model Descriptor File',
	model_zip_file_content: 'Model ZIP File',
	image: 'Image',
	x_scaler_file_content: 'X Scaler File',
	y_scaler_file_content: 'Y Scaler File',
	state_file_content: 'State File',
	basic: 'Basic',
	model_zip_file: 'Model ZIP File',
	xscaler_file: 'X Scaler File',
	yscaler_file: 'Y Scaler File',
	state_file: 'State File',
	type: 'Type',
	components: 'Components',
	input_attributes: 'Input Attributes',
	output_attributes: 'Output Attributes',
	hysysModelEditForm: 'Model Hysys',
	model_file: 'Model File',
	input_unit_ops: 'Input Unit Ops',
	output_unit_ops: 'Output Unit Ops',
	components_file: 'Components File',
	type_code: 'Type Code',
	value: 'Value',
	lummusModelEditForm: 'Model Lummus',
	lummus2ModelEditForm: 'Model Lummus2',
	addLummus: 'Lummus',
	addLummus2: 'Lummus2',
	driver_log_file: 'Driver Log',
	simulation_id: 'Simulation Id',
	code: 'Code',
	unit_op_code: 'Unit Op',
	min_value: 'Min. value',
	max_value: 'Max. value',
	uom: 'Unit of',
	lummusBySimulation: 'Lummus by Simulation',
	lummus2BySimulation: 'Lummus2 by Simulation',
	simulationId: 'Simulation Id',
	title: 'Title',
	hsc_file: 'HSC File',
	cell: 'Cell',
	unit: 'Unit',
	stream: 'Stream',
	spreadsheet: 'Spreadsheet',
	expression: 'Expression',
	driver: 'Driver',
	getInputAttributes: 'Get input attributes',
	getHysysInputAttributes: 'Get Hysys Input Attributes',
	addWorkflow: 'Workflow',
	workflow: 'Workflow',
	use_uom: 'Use UOM',
	driverLog: 'Driver Log',
	loadValues: 'Load Values',
	'driver.name': 'Driver',
	subscriptionKey: 'Subscription key',
	subscription_key: 'Subscription key'
};

export default modelEn;
