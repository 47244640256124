const fitterLogHu = {
	optimizerLogTable: 'Optimizer Log',
	timestamp: 'Időbélyeg',
	tag: 'Tag',
	objective: 'Objective',
	state: 'Állapot',
	name: 'Név',
	optimizerLogEditForm: 'Optimizer Log',
	basic: 'Alapadatok',
	table_name: 'Tábla neve',
	description: 'Leírás',
	fields: 'Mezők',
	type: 'Típus'
};

export default fitterLogHu;
