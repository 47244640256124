import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormGridComponent from 'modules/ui/editform/subForm/EditFormGridComponent';
import { useParams, withRouter } from 'react-router';
import FitterLogService from 'app/admin/fitterLog/service/FitterLogService';
import FittingCaseEditService from '../service/FittingCaseEditService';
import CreateFitterLogButton from '../component/CreateFitterLogButton';

const config = data => {
	return {
		key: 'fittingCaseEditForm',
		topic: 'fittingCase',
		service: FittingCaseEditService,
		tabList: initData => {
			return [{ key: 'basic' }, { key: 'domains' } /*, { key: 'limits' }*/];
		},
		fields: initData => {
			const fields = [
				{
					key: 'name',
					component: EditFormTextField,
					required: true,
					readOnly: data.id !== 'new'
				},
				{
					key: 'fitterLog',
					component: EditFormSelectField,
					items: () => FitterLogService.getListSelection()
				},
				{
					key: 'domains',
					tab: 'domains',
					component: EditFormGridComponent,
					defaultValue: {
						id: null,
						name: null,
						domain_from: null,
						domain_to: null,
						step_count: null
					},

					fields: [
						{
							key: 'name',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'domain_from',
							component: EditFormTextField,
							type: 'number',
							required: true
						},
						{
							key: 'domain_to',
							component: EditFormTextField,
							type: 'number',
							required: true
						},
						{
							key: 'step_count',
							component: EditFormTextField,
							type: 'number',
							required: true
						}
					]
				}
			];

			return fields;
		}
	};
};

function FittingCaseEditForm() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const myConfig = config({ id });
	return (
		<EditFormPage
			config={myConfig}
			beforeSaveContent={<CreateFitterLogButton id={id} />}
			onSave={result => {
				if (!result.engineSuccessful) dispatch(showMessage({ message: `Engine: ${result.engineMessage}` }));
			}}
		/>
	);
}

export default withRouter(FittingCaseEditForm);
