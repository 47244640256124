import { authRoles } from 'app/auth';
import WorkflowModelEditForm from './editform/WorkflowModelEditForm';

export default [
	{
		path: '/model-workflow/edit/:id/:driverName?',
		component: WorkflowModelEditForm,
		auth: authRoles.model
	}
];
