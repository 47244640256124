import _ from '@lodash';
import WorkflowItem from '../WorkflowItem';

export const defaultDefinition = {
	name: null,
	input_fields: [{ name: 'Components', type: 'Components', enabled: true, value: null }],
	output_fields: [],
	output_keys: [],
	output_mapping: {},
	feature_column: null,
	component_title: null
};

const convertOutputMapping = data => {
	const result = {};
	data.output_keys.forEach(key => {
		if (_.isArray(data.output_mapping[key])) result[key] = data.output_mapping[key];
		else result[key] = [];
	});
	return result;
};
export const converter = function (data) {
	return {
		...data,
		output_fields: data.output_keys.map(key => {
			return { name: key, type: 'Components' };
		}),
		output_mapping: convertOutputMapping(data)
	};
};

export default function FractionatingTransformation(props) {
	return <WorkflowItem {...props} type="FRACTIONATING" />;
}
