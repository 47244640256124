import _ from '@lodash';
import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Icon } from '@material-ui/core';
import { Cropper } from 'react-advanced-cropper';

function ImageCropDialog(props) {
	const [crop, setCrop] = useState(null);

	const onChange = cropper => {
		setCrop(cropper.getCoordinates());
	};

	return (
		<Dialog open={props.open} fullWidth maxWidth="lg">
			<DialogContent className="p-20 flex justify-center">
				{props.open && props.imageUrl !== null ? <Cropper src={props.imageUrl} aspectRatio={props.aspectRatio} onChange={onChange} className="flex-1" style={{ backgroundColor: 'white' }} defaultCoordinates={props.crop} /> : null}
			</DialogContent>
			<DialogActions className="border-t p-20">
				<div className="flex justify-end">
					<Button onClick={() => props.onSave(crop)} color="secondary" variant="contained" className="sm:w-200 mr-16">
						Mentés
					</Button>
					<Button onClick={() => props.onClose()} color="secondary" variant="contained" className="sm:w-200">
						Bezár
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
}

export default ImageCropDialog;
