import IdentifiableDto from 'modules/base/dto/IdentifiableDto';
import NamedDto from 'modules/base/dto/NamedDto';

export class WhatIfNoteTableDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {string} */
		this.noteItemKey = null;
		/** @type {NamedDto} */
		this.whatIf = null;
		/** @type {NamedDto} */
		this.creator = null;
		/** @type {Date} */
		this.created = null;
		/** @type {string} */
		this.note = null;
	}

	/**
	 * @typedef {Object} _WhatIfNoteTableDtoProps
	 * @property {string} noteItemKey
	 * @property {NamedDto} whatIf
	 * @property {NamedDto} creator
	 * @property {Date} created
	 * @property {string} note
	 * @typedef {import("modules/base/dto/IdentifiableDto").IdentifiableDtoProps & _WhatIfNoteTableDtoProps} WhatIfNoteTableDtoProps
	 */
	/**
	 *
	 * @param {WhatIfNoteTableDtoProps} props
	 * @returns {WhatIfNoteTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.noteItemKey = props.noteItemKey;
		this.whatIf = props.whatIf !== null ? new NamedDto().setup(props.whatIf) : null;
		this.creator = props.creator !== null ? new NamedDto().setup(props.creator) : null;
		this.created = props.created != null ? new Date(props.created) : null;
		this.note = props.note;
		return this;
	}
}

export default WhatIfNoteTableDto;
