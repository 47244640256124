import _ from '@lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { IconButton, Icon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormDateTimeField from 'modules/ui/editform/fields/EditFormDateTimeField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import { sub } from 'date-fns';
import PiMeasurementReaderService from 'app/admin/piMeasurementReader/service/PiMeasurementReaderService';

export const summaryTypes = ['Total', 'Average', 'Minimum', 'Maximum'];
export const summaryDurationUnits = [
	{ label: 'Day', value: 'Day' },
	{ label: 'Hour', value: 'Hour' }
];

function PiMeasurementReaderQuery(props) {
	const piMeasurementReaderName = props.piMeasurementReaderName || null;
	const { t } = useTranslation(['common', 'validation']);
	const [loading, setLoading] = useState(false);
	const schema = yup.object().shape({
		name: yup.string().nullable().required(t('validation:FIELD_REQUIRED')),
		start_date_time: yup.date().nullable().required(t('validation:FIELD_REQUIRED')),
		end_date_time: yup.date().nullable().required(t('validation:FIELD_REQUIRED')),
		summary_duration: yup.number().nullable().required(t('validation:FIELD_REQUIRED')),
		summary_duration_unit: yup.string().nullable().required(t('validation:FIELD_REQUIRED')),
		summary_type: yup.string().nullable().required(t('validation:FIELD_REQUIRED'))
	});
	const methods = useForm({
		defaultValues: {
			name: piMeasurementReaderName,
			start_date_time: sub(new Date(), { weeks: 1 }),
			end_date_time: new Date(),
			summary_duration: 1,
			summary_duration_unit: 'Day',
			summary_type: 'Average'
		},
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const handleSave = dto => {
		/*props.onResult({
			DBFRBF011_REN: {
				'2023-12-15T00:00:00.000000': 70.91559652222527,
				'2023-12-16T00:00:00.000000': 70.91801518334283,
				'2023-12-17T00:00:00.000000': 70.81519428359138,
				'2023-12-18T00:00:00.000000': 70.68973680646332,
				'2023-12-19T00:00:00.000000': 71.18239756160312,
				'2023-12-20T00:00:00.000000': 71.08555796941121,
				'2023-12-21T00:00:00.000000': 70.77822060585022
			},
			DBFRBF012: {
				'2023-12-15T00:00:00.000000': 35.199431003464596,
				'2023-12-16T00:00:00.000000': 35.35418624348111,
				'2023-12-17T00:00:00.000000': 35.38294696807861,
				'2023-12-18T00:00:00.000000': 35.359132954809404,
				'2023-12-19T00:00:00.000000': 35.334019072850545,
				'2023-12-20T00:00:00.000000': 35.39684620433383,
				'2023-12-21T00:00:00.000000': 37.91481707625919
			},
			'DBFRBT113.PVA': {
				'2023-12-15T00:00:00.000000': 127.46706553035312,
				'2023-12-16T00:00:00.000000': 128.54606224430933,
				'2023-12-17T00:00:00.000000': 128.44100963539546,
				'2023-12-18T00:00:00.000000': 131.45161304650483,
				'2023-12-19T00:00:00.000000': 133.32989100703486,
				'2023-12-20T00:00:00.000000': 134.05744602945117,
				'2023-12-21T00:00:00.000000': 135.61749124526978
			}
		});*/
		/*props.onResult({
			'2023-12-15T00:00:00.000000': {
				DBFRBF011_REN: 70.91559652222527,
				DBFRBF012: 70.91801518334283,
				a: 10,
				b: 10
			},
			'2023-12-16T00:00:00.000000': {
				DBFRBF011_REN: 71.91559652222527,
				DBFRBF012: 71.91801518334283,
				a: 20,
				b: 30
			},
			'2023-12-17T00:00:00.000000': {
				DBFRBF011_REN: 72.91559652222527,
				DBFRBF012: 72.91801518334283,
				a: 50,
				b: 50
			}
		});
		return true;*/
		console.log(dto);
		setLoading(true);
		props.onResult(null);
		PiMeasurementReaderService.get(dto.name, dto)
			.then(resolve => {
				props.onResult(resolve);
				console.log(resolve);
				setLoading(false);
			})
			.catch(e => {
				props.onResult(null);
				setLoading(false);
			});
	};

	return (
		<FormProvider {...methods}>
			<div className="flex flex-row">
				<div className="flex flex-1 flex-col mr-12">
					<div className="flex flex-row">
						<Controller
							control={methods.control}
							name="name"
							render={({ field, fieldState }) => (
								<EditFormSelectField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'piMeasurementReader' }}
									fieldConfig={{
										className: 'mr-8',
										key: 'name',
										type: 'enum',
										readOnly: piMeasurementReaderName != null,
										items: () => PiMeasurementReaderService.getListSelectionEnum()
									}}
								/>
							)}
						/>
						<Controller
							control={methods.control}
							name="start_date_time"
							render={({ field, fieldState }) => (
								<EditFormDateTimeField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'piMeasurementReader' }}
									fieldConfig={{
										className: 'ml-8 mr-8',
										key: 'start_date_time'
									}}
								/>
							)}
						/>
						<Controller
							control={methods.control}
							name="end_date_time"
							render={({ field, fieldState }) => (
								<EditFormDateTimeField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'piMeasurementReader' }}
									fieldConfig={{
										className: 'ml-8',
										key: 'end_date_time'
									}}
								/>
							)}
						/>
					</div>
					<div className="flex flex-row">
						<Controller
							control={methods.control}
							name="summary_type"
							render={({ field, fieldState }) => (
								<EditFormSelectField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'piMeasurementReader' }}
									fieldConfig={{
										className: 'mr-8',
										key: 'summary_type',
										type: 'enum',
										items: summaryTypes.map(type => {
											return { label: type, value: type };
										})
									}}
								/>
							)}
						/>
						<Controller
							control={methods.control}
							name="summary_duration"
							render={({ field, fieldState }) => (
								<EditFormTextField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'piMeasurementReader' }}
									fieldConfig={{
										className: 'ml-8 mr-8',
										key: 'summary_duration',
										type: 'number'
									}}
								/>
							)}
						/>
						<Controller
							control={methods.control}
							name="summary_duration_unit"
							render={({ field, fieldState }) => (
								<EditFormSelectField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'piMeasurementReader' }}
									fieldConfig={{
										className: 'ml-8',
										key: 'summary_duration_unit',
										type: 'enum',
										items: summaryDurationUnits
									}}
								/>
							)}
						/>
					</div>
				</div>
				<div className="mt-12">
					<IconButton onClick={() => methods.handleSubmit(handleSave)()} disabled={loading}>
						<Icon>search</Icon>
					</IconButton>
				</div>
			</div>
		</FormProvider>
	);
}

export default PiMeasurementReaderQuery;
