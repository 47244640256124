import { createSlice } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import UserLoginService from 'app/admin/user/service/UserLoginService';
import { getUserData } from './userSlice';

export const submitLogin =
	({ email, password }) =>
	async dispatch => {
		return UserLoginService.login(email, password).then(result => {
			if (result) {
				dispatch(getUserData());
				return dispatch(loginSuccess());
			}
			return dispatch(showMessage({ message: 'Invalid email or password!' }));
		});
	};

const initialState = {
	success: false,
	errors: []
};

const loginSlice = createSlice({
	name: 'auth/login',
	initialState,
	reducers: {
		loginSuccess: (state, action) => {
			state.success = true;
			state.errors = [];
		},
		loginError: (state, action) => {
			state.success = false;
			state.errors = action.payload;
		}
	},
	extraReducers: {}
});

export const { loginSuccess, loginError } = loginSlice.actions;

export default loginSlice.reducer;
