import _ from '@lodash';
import TransformerEditService from 'app/admin/workflow/service/TransformerEditService';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import TransformerService from 'app/admin/workflow/service/TransformerService';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import { useWhatIf } from './WhatIfProvider';

export default function WhatIfLoadTransformer() {
	const { t } = useTranslation('whatIf');
	const { data, setExperiment } = useWhatIf();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState(null);
	const methods = useForm({
		defaultValues: { transformer: data && data.experiment && data.experiment.transformer ? data.experiment.transformer : null },
		mode: 'onChange'
	});

	const handleLoad = dto => {
		setLoading(true);
		setMessage(null);
		if (dto.transformer != null) {
			TransformerEditService.getData(dto.transformer)
				.then(transformer => {
					if (transformer !== null) {
						try {
							changeExperiment(transformer);
							setLoading(false);
						} catch (e) {
							setLoading(false);
							setMessage(`Failed to load transformer: ${e}`);
						}
					} else {
						setMessage(`Failed to load transformer: transformer not found`);
						setLoading(false);
					}
				})
				.catch(e => {
					setMessage(`Failed to load transformer: ${e}`);
					setLoading(false);
				});
		} else {
			clearExperiment();
		}
	};

	const changeExperiment = transformer => {
		setExperiment(
			data.experiment
				? {
						...data.experiment,
						validity: false,
						transformer: transformer.id,
						attributes: [...(data.experiment.attributes ? data.experiment.attributes.filter(attr => attr.entity && attr.entity !== 'transformer') : []), ...parseAttributes(transformer)]
				  }
				: {
						id: null,
						name: null,
						aspect: null,
						transformer: transformer.id,
						whatIf: null,
						validity: true,
						attributes: parseAttributes(transformer)
				  }
		);
	};

	const clearExperiment = () => {
		setExperiment(
			data.experiment
				? {
						...data.experiment,
						transformer: null,
						validity: false,
						attributes: [...(data.experiment.attributes ? data.experiment.attributes.filter(attr => attr.entity && attr.entity !== 'transformer') : [])]
				  }
				: {
						id: null,
						name: null,
						aspect: null,
						transformer: null,
						whatIf: null,
						validity: true,
						attributes: []
				  }
		);
	};

	const parseAttributes = transformer => {
		const result = [];
		/*		transformer.export.input_attributes.forEach(input => {
			result.push({ ...input, io: 'INPUT', entity: 'transformer' });
		});*/
		transformer.export.output_attributes.forEach(output => {
			if (_.isUndefined(output.value)) output.value = null;
			result.push({ ...output, io: 'OUTPUT', entity: 'transformer' });
		});
		return result;
	};

	return (
		<FormProvider {...methods}>
			<div className="flex flex-row">
				<Controller
					control={methods.control}
					name="transformer"
					render={({ field, fieldState }) => (
						<EditFormSelectField
							field={field}
							fieldState={fieldState}
							config={{ topic: 'whatIf' }}
							fieldConfig={{
								key: 'transformer',
								text: t('transformer'),
								emptyLabel: 'No transformer',
								items: () => TransformerService.getListSelection()
							}}
						/>
					)}
				/>
				<div className="flex mx-8 mt-8 mb-16 items-center">
					<Button onClick={methods.handleSubmit(handleLoad)} disabled={loading} variant="contained" color="secondary">
						Load
					</Button>
				</div>
			</div>
			{message != null && <div>{message}</div>}
		</FormProvider>
	);
}
