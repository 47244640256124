import _ from '@lodash';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormGridComponent from 'modules/ui/editform/subForm/EditFormGridComponent';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import { useWhatIf } from '../component/WhatIfProvider';

export default function VariableOrDictModify(props) {
	const { t } = useTranslation(['common']);
	const { valueModify, data, setValueModify, setData, getExperimentAttribute, setExperimentAttributeValue } = useWhatIf();
	const attribute = getExperimentAttribute(valueModify.valueKey);

	const handleValue = () => {
		if (_.isObject(attribute.value)) {
			try {
				const v = attribute.value;
				const items = Object.keys(v).map(key => {
					return { name: key, value: v[key] };
				});
				if (items.length > 0) {
					return { type: 'Dict', variable: null, dict: items };
				}
			} catch (err) {
				console.log(err);
			}
		}
		if (attribute.value !== null) {
			return { type: 'Variable', variable: attribute.value, dict: [] };
		}
		return {
			type: null,
			variable: null,
			dict: [
				{
					name: '',
					value: null
				}
			]
		};
	};

	const methods = useForm({
		defaultValues: handleValue(),
		mode: 'onChange'
	});

	const type = methods.watch('type');

	const handleSave = value => {
		let result = null;
		if (value.type === 'Variable') {
			result = value.variable;
		} else if (value.type === 'Dict') {
			result = {};
			value.dict.forEach(element => {
				result[element.name] = element.value;
			});
		}
		setExperimentAttributeValue(valueModify.valueKey, result);
		setValueModify(null);
	};

	return (
		<Dialog open fullWidth>
			<DialogTitle disableTypography className="border-b">
				{valueModify.valueKey}
			</DialogTitle>
			<DialogContent className="p-20">
				<FormProvider {...methods}>
					<Controller
						control={methods.control}
						name="type"
						render={({ field, fieldState }) => (
							<EditFormSelectField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'whatIf' }}
								fieldConfig={{
									key: 'type',
									type: 'enum',
									items: [
										{ label: 'Variable', value: 'Variable' },
										{ label: 'Dict', value: 'Dict' }
									]
								}}
							/>
						)}
					/>
					{type === 'Variable' && (
						<Controller
							control={methods.control}
							name="variable"
							render={({ field, fieldState }) => (
								<EditFormTextField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'variable',
										type: 'number'
									}}
								/>
							)}
						/>
					)}
					{type === 'Dict' && (
						<EditFormGridComponent
							config={{ topic: 'whatIf' }}
							fieldConfig={{
								key: 'dict',
								component: EditFormGridComponent,
								defaultValue: {
									name: null,
									value: null
								},
								dependsOn: 'type',
								visibleIf: _type => _type === 'Dict',
								fields: [
									{
										key: 'name',
										component: EditFormTextField,
										required: true
									},
									{
										key: 'value',
										component: EditFormTextField,
										type: 'number',
										required: true
									}
								]
							}}
						/>
					)}
				</FormProvider>
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button onClick={() => setValueModify(null)}>{t('CANCEL')}</Button>
				<Button onClick={methods.handleSubmit(handleSave)} color="primary" autoFocus>
					{t('SAVE')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
