import { BsWrench, BsSpeedometer2 } from 'react-icons/bs';
import { IconButton, Tooltip } from '@material-ui/core';
import { useWhatIf } from './WhatIfProvider';

export default function WhatIfViewModeButton() {
	const { viewMode, changeViewMode } = useWhatIf();

	return (
		<Tooltip title={viewMode === 'EDIT' ? 'Toggle to calculate' : 'Toggle to edit'}>
			<IconButton onClick={() => changeViewMode(viewMode === 'EDIT' ? 'CALCULATE' : 'EDIT')}>{viewMode === 'CALCULATE' ? <BsWrench /> : <BsSpeedometer2 />}</IconButton>
		</Tooltip>
	);
}
