import NamedDto from 'modules/base/dto/NamedDto';

export class UserProfileEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.email = null;
		/** @type {string} */
		this.password = null;
		/** @type {Date} */
		this.passwordAgain = null;
	}

	/**
	 * @typedef {Object} _UserProfileEditDtoProps
	 * @property {string} email
	 * @property {string} password
	 * @property {string} passwordAgain
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _UserProfileEditDtoProps} UserProfileEditDtoProps
	 */
	/**
	 *
	 * @param {UserProfileEditDtoProps} props
	 * @returns {UserProfileEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.email = props.email;
		this.password = props.password;
		this.passwordAgain = props.passwordAgain;
		return this;
	}
}

export default UserProfileEditDto;
