import _ from '@lodash';
import { useSelector } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import DriverService from 'app/admin/driver/service/DriverService';
import { Button, Icon, ThemeProvider, Dialog, DialogTitle, DialogContent, DialogActions, TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { useState } from 'react';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { format } from 'date-fns';

const dateTimeFormat = 'yyyy.MM.dd. HH:mm';

export default function WhatIfAutoConnect(props) {
	const mainTheme = useSelector(selectMainTheme);
	const [showDialog, setShowDialog] = useState(false);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState(null);
	const [table, setTable] = useState(null);

	const handleClick = () => {
		setTable(null);
		if (props.aspect) {
			setMessage(null);
			setLoading(true);
			setShowDialog(true);
			DriverService.autoConnectByAspect(_.isObject(props.aspect) ? props.aspect.id : props.aspect).then(response => {
				setLoading(false);
				console.log(response);
				if (_.isObject(response)) {
					const _table = [];
					if (response.last_active_time) {
						_table.push({ label: 'Last active time', value: format(new Date(response.last_active_time * 1000), dateTimeFormat) });
					}
					if (response.auto_close_timeout) {
						_table.push({ label: 'Autoclose timeout', value: `${response.auto_close_timeout} sec` });
					}
					if (response.active_until) {
						_table.push({ label: 'Active until', value: format(new Date(response.active_until * 1000), dateTimeFormat) });
					}
					setTable(_table);
				} else if (_.isString(response)) {
					console.log(response);
					setMessage(response);
				} else {
					console.log(typeof response);
					console.log(response);
					setMessage('Unknown error');
				}
			});
		} else {
			setMessage('Aspect not selected!');
			setShowDialog(true);
		}
	};

	return (
		<>
			<Button className="whitespace-nowrap mx-4" variant="contained" color="secondary" onClick={() => handleClick()} startIcon={<Icon className="hidden sm:flex">call_made</Icon>}>
				Connect Driver
			</Button>
			<ThemeProvider theme={mainTheme}>
				<Dialog open={showDialog} fullWidth>
					<DialogTitle disableTypography className="border-b">
						Connecting a driver
					</DialogTitle>
					<DialogContent className="p-20">
						{loading && <FuseLoading />}
						{!loading && message && <span>{message}</span>}
						{!loading && table !== null && (
							<TableContainer>
								<Table className="w-full" aria-label="simple table">
									<TableBody>
										{table.map((row, i) => (
											<TableRow key={i}>
												<TableCell component="th" scope="row">
													{row.label}
												</TableCell>
												<TableCell className="font-mono" align="right">
													{row.value}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button
							disabled={loading}
							onClick={() => {
								setShowDialog(false);
							}}
						>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</>
	);
}
