import NamedDto from 'modules/base/dto/NamedDto';
import { TransFixDto } from '../component/items/TransformationProperties';

export class TransformerEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.json = null;
		/** @type {string} */
		this.export = null;
	}

	/**
	 * @typedef {Object} _TransformerEditDtoProps
	 * @property {string} json
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _TransformerEditDtoProps} TransformerEditDtoProps
	 */
	/**
	 *
	 * @param {TransformerEditDtoProps} props
	 * @returns {TransformerEditDto}
	 */
	setup(props) {
		super.setup(props);
		TransFixDto(props);
		this.json = props.json;
		this.export = props.export;
		return this;
	}
}

export default TransformerEditDto;
