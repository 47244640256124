import KeyedAndNamedDto from 'modules/base/dto/KeyedAndNamedDto';

export class WhatIfDocumentEditDto extends KeyedAndNamedDto {
	constructor() {
		super();
		/** @type {number} */
		this.whatIf = null;
		/** @type {string} */
		this.file = null;
		/** @type {string} */
		this.documentItemKey = null;
		/** @type {number} */
		this.creator = null;
		/** @type {number} */
		this.revision = null;
		/** @type {boolean} */
		this.lastRevision = null;
	}

	/**
	 * @typedef {Object} _WhatIfDocumentEditDtoProps
	 * @property {number} whatIf
	 * @property {string} file
	 * @property {string} documentItemKey
	 * @property {number} creator
	 * @property {number} revision
	 * @property {boolean} lastRevision
	 * @typedef {import("modules/base/dto/KeyedAndNamedDto").KeyedAndNamedDtoProps & _WhatIfDocumentEditDtoProps} WhatIfDocumentEditDtoProps
	 */
	/**
	 *
	 * @param {WhatIfDocumentEditDtoProps} props
	 * @returns {WhatIfDocumentEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.whatIf = props.whatIf;
		this.file = props.file;
		this.documentItemKey = props.documentItemKey;
		this.creator = props.creator;
		this.revision = props.revision;
		this.lastRevision = props.lastRevision;
		return this;
	}
}

export default WhatIfDocumentEditDto;
