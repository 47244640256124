import { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import ConfirmDialog from 'modules/ui/component/ConfirmDialog';
import clsx from 'clsx';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import TableCell from '@material-ui/core/TableCell';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';
import DriverTableService from '../service/DriverTableService';

function DriverConnectedTableCell(props) {
	const { item } = props;
	const dispatch = useDispatch();
	const [showConfirm, setShowConfirm] = useState(false);

	const handleClick = () => {
		setShowConfirm(false);
		if (!item.connected) {
			DriverTableService.autoConnect(item.name).then(result => {
				dispatch(showMessage({ message: result || 'Driver connected.' }));
				dispatch({ type: 'driverTable/event/setNeedDataReload' });
			});
		}
	};

	return (
		<TableCell className="p-4 md:p-16" scope="row">
			<div className={clsx('sm:ml-6 sm:mr-6 flex justify-start items-center')}>
				{item.connected === true && (
					<Tooltip title="Click to disconnect">
						<div className="flex justify-center items-center text-green-700">
							<DoneOutlinedIcon /> Connected
						</div>
					</Tooltip>
				)}
				{item.connected === false && (
					<Tooltip title="Click to connect">
						<div className="flex justify-center items-center text-red-700">
							<CloseOutlinedIcon /> Not connected
						</div>
					</Tooltip>
				)}
			</div>
			<ConfirmDialog show={showConfirm} title="Connect" description={`Do you want to ${!item.connected ? 'connect' : 'disconnect'} the driver ${item.name} ?`} onYes={() => handleClick()} onClose={() => setShowConfirm(false)} />
		</TableCell>
	);
}

export default DriverConnectedTableCell;
