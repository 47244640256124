import _ from '@lodash';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import EditFormFreeSoloField from 'modules/ui/editform/fields/EditFormFreeSoloField';
import { useWorkflow } from '../../WorkflowProvider';
import TransformationPropertiesDialog from '../TransformationPropertiesDialog';
import { converter } from './TotalTransformation';
import { TransInputFieldsPropertiesArray, TransNameField, TransOutputKeyOnlyField, TransValidationName } from '../TransformationProperties';

function TotalProperties(props) {
	const { data, showPropertiesDialog, transformationNameAvailable } = useWorkflow();
	const { t } = useTranslation(['transformer', 'validation']);
	const item = data.transform.find(d => d.key === showPropertiesDialog.key);

	const schema = yup.object().shape({
		name: TransValidationName(transformationNameAvailable, item.key),
		output_key: yup.string().nullable().required(t('validation:FIELD_REQUIRED'))
	});

	return (
		<TransformationPropertiesDialog schema={schema} converter={converter} titleKey="totalProperties">
			<TransNameField />
			<AddSubtractKeys />
			<TransInputFieldsPropertiesArray type="Variable" keysField={['add_keys', 'subtract_keys']} />
			<TransOutputKeyOnlyField />
		</TransformationPropertiesDialog>
	);
}
function AddSubtractKeys(props) {
	const methods = useFormContext();

	return (
		<>
			<Controller
				control={methods.control}
				name="add_keys"
				render={({ field, fieldState }) => (
					<EditFormFreeSoloField
						field={field}
						fieldState={fieldState}
						config={{ topic: 'transformer' }}
						fieldConfig={{
							key: 'add_keys',
							multiple: true,
							query: () => new Promise(resolve => resolve([]))
						}}
					/>
				)}
			/>
			<Controller
				control={methods.control}
				name="subtract_keys"
				render={({ field, fieldState }) => (
					<EditFormFreeSoloField
						field={field}
						fieldState={fieldState}
						config={{ topic: 'transformer' }}
						fieldConfig={{
							key: 'subtract_keys',
							multiple: true,
							query: () => new Promise(resolve => resolve([]))
						}}
					/>
				)}
			/>
		</>
	);
}

export default TotalProperties;
