import DriverDto from 'app/admin/driver/dto/DriverDto';
import NamedDto from 'modules/base/dto/NamedDto';

export class ModelTableDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.type = null;
		/** @type {DriverDto} */
		this.driver = null;
	}

	/**
	 * @typedef {Object} _ModelTableDtoProps
	 * @property {string} type
	 * @property {DriverDto} driver
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _ModelTableDtoProps} ModelTableDtoProps
	 */
	/**
	 *
	 * @param {ModelTableDtoProps} props
	 * @returns {ModelTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.type = props.type;
		this.driver = props.driver !== null ? new DriverDto().setup(props.driver) : null;
		return this;
	}
}

export default ModelTableDto;
