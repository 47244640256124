import nBossConnection from 'modules/base/service/nBossConnection';
import NamedDto from 'modules/base/dto/NamedDto';
import EnumDto from 'modules/base/dto/enum/EnumDto';

class PiMeasurementReaderService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'piMeasurementReaderControl';
	}

	/**
	 * @return {Promise<NamedDto[]>}
	 */
	getListSelection() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getListSelection`)
				.then(response => resolve(response.data != null ? response.data.map(d => new NamedDto().setup(d)) : []))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<EnumDto[]>}
	 */
	getListSelectionEnum() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getListSelection`)
				.then(response => resolve(response.data != null ? response.data.map(d => new EnumDto().setup({ label: d.name, value: d.name })) : []))
				.catch(reject);
		});
	}

	/**
	 * @param {string} name
	 * @param {any} query
	 * @return {Promise<any>}
	 */
	get(name, query) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/get`, {
					name,
					query
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} measurementName
	 * @param {string} readerName
	 * @return {Promise<any>}
	 */
	createMeasurement(measurementName, readerName) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/createMeasurement`, {
					measurementName,
					readerName
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new PiMeasurementReaderService();
