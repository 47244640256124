import _ from '@lodash';
import TableBrowserComponent from 'modules/ui/component/TableBrowserComponent';

function EditFormTableBrowserField(props) {
	return (
		<div className="w-full mt-8 mb-16">
			<TableBrowserComponent config={props.fieldConfig.config()} />
		</div>
	);
}

export default EditFormTableBrowserField;
