import _ from 'lodash';
import NamedDto from 'modules/base/dto/NamedDto';

export default class PiMeasurementReaderDefinitionDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.type = null;
		/** @type {string} */
		this.reader_name = null;
		/** @type {string} */
		this.measurement_name = null;
		/** @type {Date} */
		this.start_date_time = null;
		/** @type {Date} */
		this.last_date_time = null;
		/** @type {number} */
		this.summary_duration = null;
		/** @type {string} */
		this.summary_duration_unit = null;
		/** @type {string} */
		this.summary_type = null;
	}

	/**
	 * @typedef {Object} _PiMeasurementReaderDefinitionDtoProps
	 * @property {string} type
	 * @property {string} reader_name
	 * @property {string} measurement_name
	 * @property {Date} start_date_time
	 * @property {Date} last_date_time
	 * @property {number} summary_duration
	 * @property {string} summary_duration_unit
	 * @property {string} summary_type
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _PiMeasurementReaderDefinitionDtoProps} PiMeasurementReaderDefinitionDtoProps
	 */
	/**
	 *
	 * @param {PiMeasurementReaderDefinitionDtoProps} props
	 * @returns {PiMeasurementReaderDefinitionDto}
	 */
	setup(props) {
		super.setup(props);
		this.type = 'PiMeasurementReader';
		this.reader_name = props.reader_name;
		this.measurement_name = props.measurement_name;
		this.start_date_time = props.start_date_time != null ? new Date(props.start_date_time) : null;
		this.last_date_time = props.last_date_time != null ? new Date(props.last_date_time) : null;
		this.summary_duration = props.summary_duration;
		this.summary_duration_unit = props.summary_duration_unit;
		this.summary_type = props.summary_type;
		return this;
	}
}
