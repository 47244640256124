import _ from '@lodash';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useWorkflow } from '../../WorkflowProvider';
import { TransComponentTitleField, TransFeatureColumnField, TransInputKeyField, TransNameField, TransOutputKeyField, TransValidationIOKey, TransValidationName } from '../TransformationProperties';
import TransformationPropertiesDialog from '../TransformationPropertiesDialog';

function SumUpProperties(props) {
	const { data, showPropertiesDialog, transformationNameAvailable } = useWorkflow();
	const { t } = useTranslation(['transformer', 'validation']);
	const item = data.transform.find(d => d.key === showPropertiesDialog.key);

	const schema = yup.object().shape({
		name: TransValidationName(transformationNameAvailable, item.key),
		input_fields: TransValidationIOKey(),
		output_fields: TransValidationIOKey()
	});

	return (
		<TransformationPropertiesDialog schema={schema} titleKey="sumUpProperties">
			<TransNameField />
			<TransInputKeyField />
			<TransOutputKeyField />
			<TransFeatureColumnField />
			<TransComponentTitleField />
		</TransformationPropertiesDialog>
	);
}
export default SumUpProperties;
