import _ from '@lodash';
import { useRef, useState, createContext, useContext, useEffect } from 'react';

export const OptimizationPipelineContext = createContext(null);

export const useOptimizationPipeline = () => {
	return useContext(OptimizationPipelineContext);
};

export default function OptimizationPipelineProvider(props) {
	const [sidebar, setSidebar] = useState(null);

	const value = {
		sidebar,
		setSidebar,
	};

	return <OptimizationPipelineContext.Provider value={value}>{props.children}</OptimizationPipelineContext.Provider>;
}
