import { authRoles } from 'app/auth';
import Lummus2ModelEditForm from './editform/Lummus2ModelEditForm';

export default [
	{
		path: '/model-lummus2/edit/:id/:driverName?',
		component: Lummus2ModelEditForm,
		auth: authRoles.model
	}
];
