import _ from '@lodash';
import { useEffect, useState } from 'react';
import { Paper, TableContainer, Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';
import NumberValue from './NumberValue';

function MapTableResult(props) {
	const { data } = props;
	const [result, setResult] = useState(null);

	const parseData = _data => {
		const parseResult = [];
		const fields = Object.keys(_data);
		for (let i = 0; i < fields.length; i += 1) {
			const value = _data[fields[i]];
			if (_.isObject(value)) {
				const subFields = Object.keys(value);
				parseResult.push({
					name: fields[i],
					sub: false,
					parent: true,
					count: subFields.length,
					value: null
				});
				for (let j = 0; j < subFields.length; j += 1) {
					parseResult.push({
						name: subFields[j],
						sub: true,
						parent: false,
						value: value[subFields[j]]
					});
				}
			} else {
				parseResult.push({
					name: fields[i],
					sub: false,
					parrent: false,
					value
				});
			}
		}
		return parseResult;
	};
	useEffect(() => {
		if (_.isObject(data)) {
			setResult(parseData(data));
		} else setResult(null);
	}, [data]);

	return (
		<TableContainer component={Paper}>
			<Table className="w-full" aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell align="right">Value</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{result !== null &&
						result.map(row => (
							<TableRow key={row.name}>
								<TableCell component="th" scope="row" colSpan={row.parent ? 2 : 1} className={row.parent ? 'font-bold' : row.sub ? 'pl-32' : ''}>
									{row.name}
								</TableCell>
								{!row.parent && (
									<TableCell className="font-mono" align="right">
										<NumberValue value={row.value} />
									</TableCell>
								)}
							</TableRow>
						))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default MapTableResult;
