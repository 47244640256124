import { useState } from 'react';
import { motion } from 'framer-motion';
import { Close } from '@material-ui/icons';
import { FaTimes } from 'react-icons/fa';

import { Button, IconButton } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useOptimizationPipeline } from '../../optimizationPipeline/component/OptimizationPipelineProvider';
// import CompareSidebarList from './CompareSidebarList';

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.background.default
	}
}));

function Sidebar(props) {
	const theme = useTheme();
	const classes = useStyles(props);
	const { sidebar, setSidebar } = useOptimizationPipeline();

	return (
		<motion.div
			initial={sidebar != null ? 'visible' : 'hidden'}
			animate={sidebar != null ? 'visible' : 'hidden'}
			variants={{ visible: { translateX: 0 }, hidden: { translateX: '+100%' } }}
			transition={{ ease: 'easeInOut' }}
			className={clsx(classes.root, 'absolute right-0 top-0 h-full shadow-inner border-l z-10')}
			style={{ width: '640px' }}>

			<div className="flex flex-col gap-20 h-full relative">

				<div className="flex-1 overflow-y-scroll p-16">
					{props.children}
				</div>

				<div className="absolute p-6 pr-8 py-12 left-0 top-1/2 text-white rounded-r-xl cursor-pointer group shadow-xl" style={{ backgroundColor: theme.palette.primary.dark }} onClick={() => setSidebar(null)} aria-hidden="true">
					<FaTimes className="opacity-80 group-hover:opacity-100 transition transform group-hover:rotate-180" />
				</div>

			</div>

		</motion.div>
	);
}

export default Sidebar;
