import FileResultDto from 'modules/base/dto/file/FileResultDto';
import nBossConnection from '../nBossConnection';

class FileRepositoryService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'fileRepository';
	}

	/**
	 * @param {string} key
	 * @return {Promise<FileResultDto>}
	 */
	getInfo(key) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getInfo`, {
					key
				})
				.then(response => resolve(new FileResultDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {Array<string>} key
	 * @return {Promise<Array.<FileResultDto>>}
	 */
	getInfos(keys) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getInfos`, {
					keys
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new FileRepositoryService();
