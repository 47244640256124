const authEn = {
	MY_PROFILE: 'My Profile',
	LOGOUT: 'Log Out',
	LOGIN: 'Login',
	REGISTER: 'Register',
	EMAIL: 'E-mail',
	PASSWORD: 'Password',
	PASSWORD_AGAIN: 'Password again',
	LOST_YOUR_PASSWORD: 'Lost your Password?',
	REQUEST_A_NEW_PASSWORD: 'Request a new One!',
	BACK_TO_LOGIN: 'Back to Login',
	SAVE_PASSWORD: 'Save Password',
	NEW_PASSWORD_SET_MESSAGE: 'The new password has set. Please Log In.',
	NEW_PASSWORD_SET_FAILED_MESSAGE: 'An error occurred while setting the new password.',
	PLEASE_SET_PASSWORD_MESSAGE: 'Please enter your new password twice.',
	PLEASE_SET_EMAIL_MESSAGE: 'Kérlek add meg az e-mail címed, amivel a rendszerbe vagy regisztrálva.',
	PLEASE_SET_EMAIL_DESC: 'A megadott e-mail címre egy linket fogunk küldeni, amire kattintva tudsz majd új jelszót generálni.',
	NEW_PASSWORD_REQUEST: 'Please send an E-mail',
	NEW_PASSWORD_REQUEST_MESSAGE: 'A megadott e-mail címre a jelszóbeállításhoz szükséges levelet küldtünk ki. Kérlek ellenőrizd a postafiókod.',
	NEW_PASSWORD_REQUEST_FAILED: 'A megadott e-mail címre nem lehetséges új jelszót igényelni.'
};

export default authEn;
