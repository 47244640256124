import _ from '@lodash';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import EditFormGridRow from './EditFormGridRow';
import { determineEditableAccess } from '../EditFormUtils';
import EditFormGridHeader from './EditFormGridHeader';

const checkListOrderOk = fields => {
	for (let pos = 0; pos < fields.length; pos += 1) {
		if (fields[pos].orderIndex === null || fields[pos].orderIndex !== pos) {
			return false;
		}
	}
	return true;
};

const reorderList = fields => {
	const _fields = fields.sort((firstField, secondField) => {
		if (firstField.orderIndex !== null && secondField.orderIndex !== null) {
			if (firstField.orderIndex > secondField.orderIndex) return 1;
			if (firstField.orderIndex < secondField.orderIndex) return -1;
			return 0;
		}
		return 0;
	});
	for (let pos = 0; pos < _fields.length; pos += 1) {
		_fields[pos].orderIndex = pos;
	}
	return fields;
};

function EditFormGridComponent(props) {
	const { fieldPrefix, fieldConfig, config } = props;
	const { control, setValue, getValues } = useFormContext();
	const { t } = useTranslation('editform');
	const dtoPathWithKey = (!_.isUndefined(fieldPrefix) ? fieldPrefix : '') + fieldConfig.key;
	const { fields, append, remove } = useFieldArray({
		control,
		name: dtoPathWithKey,
		keyName: '_id'
	});

	const [readOnly, setReadOnly] = useState(determineEditableAccess(config, fieldConfig, fieldPrefix));
	const modifyOnly = fieldConfig.modifyOnly || false;

	const keyList = [];
	fieldConfig.fields.forEach(fieldRow => {
		if (keyList.indexOf(fieldRow.key) === -1) {
			keyList.push(fieldRow.key);
		}
	});
	useEffect(() => {
		if (fieldConfig.orderable) {
			if (!checkListOrderOk(fields)) setValue(dtoPathWithKey, reorderList(fields));
		}
	}, [fields]);

	const handleAddNew = (_id, direction) => {
		const _fields = getValues(dtoPathWithKey).map((f, i) => {
			f._id = fields[i]._id;
			return f;
		});

		const pos = _fields.findIndex(field => field._id === _id);
		const newValue = _.isFunction(props.fieldConfig.defaultValue) ? props.fieldConfig.defaultValue() : props.fieldConfig.defaultValue;
		const newPos = direction < 0 ? pos : pos + 1;
		newValue.orderIndex = newPos;
		setValue(
			dtoPathWithKey,
			_fields.map(f => {
				f.orderIndex = f.orderIndex >= newPos ? f.orderIndex + 1 : f.orderIndex;
				return f;
			})
		);
		append(newValue);
	};

	const handleChangePos = (selectedToMoveBase, newPos) => {
		const _fields = getValues(dtoPathWithKey).map((f, i) => {
			f._id = fields[i]._id;
			return f;
		});
		const sourceIndex = _fields.find(f => f._id === selectedToMoveBase).orderIndex;
		const targetIndex = newPos;
		const movedElement = _fields[sourceIndex];
		_fields.splice(sourceIndex, 1);
		_fields.splice(targetIndex, 0, movedElement);
		_fields.forEach((elem, index) => {
			elem.orderIndex = index;
		});
		setValue(dtoPathWithKey, _fields);
	};

	return (
		<div className="flex-1 overflow-y-auto">
			{fieldConfig.showTitle || false ? <div className="mt-8 mb-16 font-medium">{fieldConfig.text}</div> : null}
			<table className="w-full">
				<tbody>
					<EditFormGridHeader {...props} />

					{fields &&
						fields.map((f, i) => (
							<EditFormGridRow
								key={f._id}
								index={i}
								fieldsLength={fields.length}
								config={config}
								fieldConfig={fieldConfig}
								field={f}
								fieldPrefix={`${!_.isUndefined(fieldPrefix) ? fieldPrefix : ''}${fieldConfig.key}.${i}.`}
								onDelete={() => remove(i)}
								readOnly={readOnly}
								onChangePos={newPos => handleChangePos(f._id, newPos)}
								addNew={_id => handleAddNew(_id, 1)}
							/>
						))}
				</tbody>
			</table>

			{!readOnly && !modifyOnly && (!fieldConfig.orderable || fields.length === 0) ? (
				<div className="py-24 flex justify-end">
					<Button
						className="whitespace-nowrap mx-4"
						variant="contained"
						color="secondary"
						onClick={() => {
							append(_.isFunction(fieldConfig.defaultValue) ? fieldConfig.defaultValue() : fieldConfig.defaultValue);
						}}
						startIcon={<Icon className="hidden sm:flex">add</Icon>}
					>
						{fieldConfig.newButtonText || t('SUB_ADD_NEW')}
					</Button>
				</div>
			) : null}
		</div>
	);
}

export default EditFormGridComponent;
