import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import UserEditService from 'app/admin/user/service/UserEditService';
import EditFormRegistrationStatusField from 'modules/ui/editform/fields/EditFormRegistrationStatusField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import { useParams, withRouter } from 'react-router';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import WhatIfGroupService from 'app/admin/whatIf/service/WhatIfGroupService';
//import EditFormFileInputField from 'modules/ui/editform/fields/EditFormFileInputField';

const config = data => {
	return {
		key: 'userEditForm',
		text: 'Hozzáférés',
		topic: 'user',
		service: UserEditService,
		fields: initData => {
			const fields = [
				{
					key: 'name',
					component: EditFormTextField,
					required: true
				},
				{
					key: 'roleList',
					component: EditFormSelectField,
					multiple: true,
					items: () => UserEditService.getRoleListSelection()
				},
				{
					key: 'whatIfGroupList',
					component: EditFormSelectField,
					multiple: true,
					items: () => WhatIfGroupService.getListSelection()
				},
				/*{
			key: 'profilePicture',
			text: 'Profilkép',
			component: EditFormFileInputField,
			required: true
		    },*/
				{
					key: 'registrationStatus',
					text: 'Aktív',
					component: EditFormRegistrationStatusField
				},
				{
					key: 'password',
					text: 'Jelszó',
					component: EditFormTextField,
					required: true,
					type: 'password',
					autoComplete: 'new-password'
				},
				{
					key: 'passwordAgain',
					text: 'Jelszó mégegyszer',
					component: EditFormTextField,
					required: true,
					type: 'password',
					autoComplete: 'new-password'
				},
				{
					key: 'email',
					text: 'E-mail',
					component: EditFormTextField,
					required: true
				},
				{
					key: 'enabled',
					text: 'Engedélyezve',
					component: EditFormCheckboxField
				}
			];

			return fields;
		}
	};
};

function UserEditForm() {
	const { id, tabKey } = useParams();

	const myConfig = config({ id });
	return <EditFormPage config={myConfig} startTabValue={tabKey} />;
}

export default withRouter(UserEditForm);
