export default function (props) {
	const { value } = props;
	const formatter = new Intl.NumberFormat('en-US', {
		minimumFractionDigits: 0,
		maximumFractionDigits: 4
	});
	const shortValue = formatter.format(value);

	return <span title={value}>{shortValue}</span>;
}
