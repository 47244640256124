import nBossConnection from 'modules/base/service/nBossConnection';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import OptimizerLogTableService from '../service/OptimizerLogTableService';

const config = {
	key: 'optimizerLogTable',
	topic: 'optimizerLog',
	service: OptimizerLogTableService,
	editFormState: item => `/optimizer-log/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['search', 'refresh', 'new'],
	rowAction: ['exportData', 'modify', 'delete'],
	exportData: {
		tooltip: 'Export to XLSX',
		link: item => `${nBossConnection.basePath}optimizerLogTableControl/exportToXlsx?name=${item.name}`
	},
	columns: [
		{
			key: 'name'
		}
	]
};

function OptimizerLogTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('optimizerLogTable', reducer('optimizerLogTable'))(OptimizerLogTable);
