import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import { useParams, withRouter } from 'react-router';
import OptimizerType from '../dto/OptimizerType';
import OptimizerEditService from '../service/OptimizerEditService';
import ParticleSwarmOptimizerEditService from '../service/ParticleSwarmOptimizerEditService';
import ShgoOptimizerEditService from '../service/ShgoOptimizerEditService';
import RestartOptimizerEditService from '../service/RestartOptimizerEditService';
import DualAnnealingOptimizerEditService from '../service/DualAnnealingOptimizerEditService';

const optimizerService = type => {
	if (type === 'RandomRestart' || type === 'QuadraticRestart') return RestartOptimizerEditService;
	if (type === 'ParticleSwarm') return ParticleSwarmOptimizerEditService;
	if (type === 'Shgo') return ShgoOptimizerEditService;
	if (type === 'DualAnnealing') return DualAnnealingOptimizerEditService;
	return OptimizerEditService;
};

export const shgoMethod = [
	{ label: 'BFGS', value: 'BFGS' },
	{ label: 'L-BFGS-B', value: 'L-BFGS-B' },
	{ label: 'SLSQP', value: 'SLSQP' },
];
export const shgoSampling = [
	{ label: 'Halton', value: 'halton' },
	{ label: 'Sobol', value: 'sobol' },
	{ label: 'Simplicial', value: 'simplicial' },
];

const config = data => {
	const form = {
		key: 'optimizerEditForm',
		topic: 'optimizer',
		service: optimizerService(data.type),
		defaultValue: () => optimizerService(data.type).createByType(data.type),
		fields: initData => {
			const fields = [
				{
					key: 'name',
					component: EditFormTextField,
					required: true,
					readOnly: data.id !== 'new'
				},
				{
					key: 'type',
					component: EditFormSelectField,
					type: 'enum',
					readOnly: true,
					items: Object.keys(OptimizerType).map(key => {
						return { label: OptimizerType[key], value: key };
					})
				},
				...(data.type === 'ParticleSwarm'
					? [
							{
								key: 'max_iteration_count',
								component: EditFormTextField,
								type: 'number'
							},
							{
								key: 'particle_count',
								component: EditFormTextField,
								type: 'number'
							},
							{
								key: 'w',
								component: EditFormTextField,
								type: 'number'
							},
							{
								key: 'c1',
								component: EditFormTextField,
								type: 'number'
							},
							{
								key: 'c2',
								component: EditFormTextField,
								type: 'number'
							}
					  ]
					: []),
				...(data.type === 'Shgo'
					? [
							{
								key: 'iters',
								component: EditFormTextField,
								type: 'number'
							},
							{
								key: 'n',
								component: EditFormTextField,
								type: 'number'
							},
							{
								key: 'method',
								component: EditFormSelectField,
								type: 'enum',
								items: shgoMethod
							},
							{
								key: 'sampling_method',
								component: EditFormSelectField,
								type: 'enum',
								items: shgoSampling
							},
							{
								key: 'workers',
								component: EditFormTextField,
								type: 'number'
							}
					  ]
					: []),
				...(data.type === 'DualAnnealing'
					? [
							{
								key: 'max_iter',
								component: EditFormTextField,
								type: 'number'
							},
							{
								key: 'initial_temp',
								component: EditFormTextField,
								type: 'number',
								min: 0.01
							},
							{
								key: 'restart_temp_ratio',
								component: EditFormTextField,
								type: 'number'
							},
							{
								key: 'visit',
								component: EditFormTextField,
								type: 'number'
							},
							{
								key: 'accept',
								component: EditFormTextField,
								type: 'number'
							}
					  ]
					: []),

				...(data.type === 'RandomRestart' || data.type === 'QuadraticRestart'
					? [
							{
								key: 'max_iteration_count',
								component: EditFormTextField,
								type: 'number'
							},
							{
								key: 'local_search_name',
								component: EditFormTextField
							}
					  ]
					: [])
			];
			return fields;
		}
	};

	return form;
};

function OptimizerEditForm() {
	const { id, type, tabKey } = useParams();

	const myConfig = config({ id, type });
	console.log(myConfig);

	return <EditFormPage config={myConfig} />;
}

export default withRouter(OptimizerEditForm);
