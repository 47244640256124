import useResizeObserver from '@react-hook/resize-observer';
import { useState, useLayoutEffect } from 'react';

export default function (target) {
	const [size, setSize] = useState();

	useLayoutEffect(() => {
		if (target.current) setSize(target.current.getBoundingClientRect());
	}, [target]);

	// Where the magic happens
	useResizeObserver(target, entry => setSize(entry.contentRect));
	return size;
}
