import { authRoles } from 'app/auth';
import UserTable from 'app/admin/user/table/UserTable';
import DashboardRoleTable from 'app/admin/user/table/DashboardRoleTable';
import DashboardRoleEditForm from 'app/admin/user/editform/DashboardRoleEditForm';
import UserEditForm from 'app/admin/user/editform/UserEditForm';
import i18next from 'i18next';
import en from './i18n/en';
import hu from './i18n/hu';

i18next.addResourceBundle('en', 'user', en);
i18next.addResourceBundle('hu', 'user', hu);

export default [
	{
		path: '/user/edit/:id/:tabKey?',
		component: UserEditForm,
		auth: authRoles.userAccount
	},
	{
		path: '/user/:sessionNameParam?',
		component: UserTable,
		auth: authRoles.userAccount
	},
	{
		path: '/dashboard-role/edit/:id',
		component: DashboardRoleEditForm,
		auth: authRoles.userRole
	},
	{
		path: '/dashboard-role/:sessionNameParam?',
		component: DashboardRoleTable,
		auth: authRoles.userRole
	}
];
