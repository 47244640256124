import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import { useParams, withRouter } from 'react-router';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import EditFormGroupFields from 'modules/ui/editform/subForm/EditFormGroupFields';
import EditFormSeparatorField from 'modules/ui/editform/fields/EditFormSeparatorField';
import UpdateScheduleEditService from '../service/UpdateScheduleEditService';

const blockClassName = 'rounded-8 border px-16 pt-10 pb-4 mb-16';
const blockTitleClassName = 'py-8';

const config = data => {
	return {
		key: 'updateScheduleEditForm',
		text: 'Schedule',
		topic: 'scheduler',
		service: UpdateScheduleEditService,
		fields: initData => {
			const fields = [
				{
					key: 'scheduleGroup',
					component: EditFormGroupFields,
					className: blockClassName,
					fields: [
						{
							key: 'sepSchedule',
							text: 'Schedule',
							component: EditFormSeparatorField,
							className: blockTitleClassName,
							isControlledInput: false
						},
						{
							key: 'enabled',
							component: EditFormCheckboxField
						},
						{
							key: 'scheduleInnerGroup',
							component: EditFormGroupFields,
							className: 'flex flex-col sm:flex-row',
							fields: [
								{
									key: 'minute',
									component: EditFormTextField,
									className: 'sm:mr-6',
									required: true,
									mask: {
										mask: /^\*$|^[0-9]{0,2}$/
									}
								},
								{
									key: 'hour',
									component: EditFormTextField,
									className: 'sm:mr-6',
									required: true,
									mask: {
										mask: /^\*$|^[0-9]{0,2}$/
									}
								},
								{
									key: 'day',
									component: EditFormTextField,
									className: 'sm:mr-6',
									required: true,
									mask: {
										mask: /^\*$|^[0-9]{0,2}$/
									}
								},
								{
									key: 'month',
									component: EditFormTextField,
									className: 'sm:mr-6',
									required: true,
									mask: {
										mask: /^\*$|^[0-9]{0,2}$/
									}
								}
							]
						}
					]
				}
			];

			return fields;
		}
	};
};

function UpdateScheduleEditForm() {
	const { id, tabKey } = useParams();
	const dispatch = useDispatch();

	const myConfig = config({ id });
	return (
		<EditFormPage
			config={myConfig}
			startTabValue={tabKey}
			onSave={result => {
				if (!result.engineSuccessful) dispatch(showMessage({ message: `Engine: ${result.engineMessage}` }));
			}}
		/>
	);
}

export default withRouter(UpdateScheduleEditForm);
