const transformerHu = {
	transformerTable: 'Transformer',
	transformerEditForm: 'Transformer',
	name: 'Név',
	created: 'Létrehozva',
	updated: 'Módosítva',
	back: 'Vissza',
	save: 'Mentés',
	cancel: 'Mégse',
	properties: 'Tulajdonságok',
	workflowName: 'Workflow neve',
	transformerName: 'Transformer neve',
	move: 'Mozgatás',
	key: 'Kulcs',
	value: 'Érték',
	transform: 'Transform',
	line: 'Vezeték',
	delete: 'Törlés',
	select: 'Kijelölés',
	itemProperties: 'Tulajdonság',
	input_key: 'Input Key',
	output_key: 'Output Key',
	feature_column: 'Feature Column',
	threshold: 'Threshold',
	grouping: 'Grouping',
	group_title: 'Group Title',
	basicInfo: 'Alapadatok',
	conversion: 'Conversion',
	selectivity: 'Selectivity',
	stoichiometry: 'Stoichiometry',
	key_title: 'Key Title',
	deleteRow: 'Sor törlése',
	addNewRow: 'Sor hozzáadása',
	deleteColumn: 'Oszlop törlése',
	addNewColumn: 'Oszlop hozzáadása',
	deleteRowConfirm: 'Biztosan törlöd a sort?',
	deleteColumnConfirm: 'Biztosan törlöd az oszlopot?',
	notUnique: 'Ez a kulcs már használatban van.',
	aspectProperties: 'Aspect tulajdonságok',
	blenderProperties: 'Blender tulajdonságok',
	ReformerProperties: 'Reformer tulajdonságok',
	dodoProperties: 'Dodo tulajdonságok',
	dutyProperties: 'Duty tulajdonságok',
	expressionProperties: 'Expression tulajdonságok',
	filterZeroProperties: 'Filter Zero tulajdonságok',
	fractionatingProperties: 'Fractionating tulajdonságok',
	groupByProperty: 'Group by property',
	joinStreamsProperties: 'Join Streams tulajdonságok',
	massFlowToMoleFlowProperties: 'Mass Flow to Mole Flow tulajdonságok',
	massFracToMassFlowProperties: 'Mass Frac to Mass Flow tulajdonságok',
	massFlowToMassFracProperties: 'Mass Flow to Mass Frac tulajdonságok',
	mergeComponentsProperties: 'Merge Components tulajdonságok',
	moleFlowToMassFlowProperties: 'Mole Flow to Mass Flow tulajdonságok',
	reformerProperties: 'Reformer tulajdonságok',
	sumUpProperties: 'Sum Up tulajdonságok',
	totalProperties: 'Total tulajdonságok',
	scalarsToTableProperties: 'Scalars 2 Table tulajdonságok',
	tableToScalarsProperties: 'Table 2 Scalars tulajdonságok',
	prices: 'Árak',
	efficiency: 'Hatásfok',
	output_keys: 'Output keys',
	input_keys: 'Input keys',
	mole_flow_title: 'Mole Flow Title',
	mass_frac_title: 'Mass Frac Title',
	mass_flow_title: 'Mass Flow Title',
	mass_flow_key: 'Mass Flow Key',
	output_component: 'Output Component',
	input_components: 'Input Components',
	component_title: 'Component Title',
	add_keys: 'Add Keys',
	subtract_keys: 'Subtract Keys',
	lineAlreadyConnected: 'Már van kötve rá vezeték',
	ASPECT: 'Aspect',
	BLENDER: 'Blender',
	DODO: 'Dodo',
	DUTY: 'Duty',
	FILTER_ZERO: 'FilterZero',
	FRACTIONATING: 'Fractionating',
	GROUP_BY_PROPERTY: 'Group By',
	JOIN_STREAMS: 'Join',
	MASSFLOW_TO_MOLEFLOW: 'Mass2Mole',
	MASSFRAC_TO_MASSFLOW: 'Frac2Mass',
	MASSFLOW_TO_MASSFRAC: 'Mass2Frac',
	MERGE_COMPONENTS: 'Merge',
	MOLEFLOW_TO_MASSFLOW: 'Mole2Mass',
	REFORMER: 'Reformer',
	SUM_UP: 'Sum Up',
	TOTAL: 'Total',
	SCALARS_TO_TABLE: 'Scals2Tbl',
	TABLE_TO_SCALARS: 'Tbl2Scals',
	EXPRESSION: 'Expression',
	SWITCH: 'Switch',
	valueKey: 'Value Key',
	valueProperties: 'Érték tulajdonságok',
	item_title: 'Item Title',
	saveSuccessful: 'Sikeres mentés',
	saveFailed: 'Hiba történt a mentés során, minden adat megadása kötelező vagy a név már használatban van',
	io: 'I/O',
	valueType: 'Type',
	type: 'Type',
	warningConfirmText: 'Ignoring the warnings, are you sure you want to save?',
	uom: 'UOM',
	expressions: 'Expressions',
	enabled: 'Engedélyezve',
	input_fields: 'Bemeneti mezők',
	aspect_name: 'Aspect neve',
	switchProperties: 'Kapcsoló tulajdonságok'
};

export default transformerHu;
