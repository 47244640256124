import _ from '@lodash';
import { useCallback, useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { useWhatIf } from '../component/WhatIfProvider';

export default function VariableModify(props) {
	const { valueModify, data, figure, setValueModify, setData, getExperimentAttribute, setExperimentAttributeValue } = useWhatIf();
	const attribute = getExperimentAttribute(valueModify.valueKey);
	const [value, setValue] = useState(attribute.value);
	const [error, setError] = useState(false);
	const scale = figure.stage.scale || 1;
	const coords = {
		x: valueModify.x * scale + figure.stage.x,
		y: valueModify.y * scale + figure.stage.y
	};

	const keyDownHandle = useCallback(
		e => {
			if (e.key === 'Escape') {
				e.preventDefault();
				setValueModify(null);
			} else if (e.key === 'Enter') {
				const floatValue = Number.parseFloat(value);
				if (Number.isNaN(floatValue)) {
					setError(true);
					e.preventDefault();
				} else {
					setExperimentAttributeValue(valueModify.valueKey, floatValue);
					e.preventDefault();
					setValueModify(null);
				}
			} else {
				setError(false);
			}
		},
		[value, data, setData, valueModify, setValueModify, setError]
	);

	useEffect(() => {
		document.addEventListener('keydown', keyDownHandle, false);
		return () => {
			document.removeEventListener('keydown', keyDownHandle, false);
		};
	}, [keyDownHandle]);

	return (
		<div className="absolute bg-white " style={{ width: '120px', top: `${coords.y}px`, left: `${coords.x}px` }}>
			<TextField variant="outlined" size="small" type="number" value={value} onChange={e => setValue(e.target.value)} autoFocus error={error} />
			<MinMaxValue minValue={attribute.min_value} maxValue={attribute.max_value} />
		</div>
	);
}

export function MinMaxValue(props) {
	const { minValue, maxValue } = props;

	if (minValue == null && maxValue == null) return <></>;

	return (
		<div className="flex flex-row text-red-500 text-xs py-8">
			{minValue != null && <div className="pr-12">Min: {minValue}</div>}
			{maxValue != null && <div>Max: {maxValue}</div>}
		</div>
	);
}
