import { Typography } from '@material-ui/core';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import { Controller } from 'react-hook-form';

export default function ValueStyleConfigFields(props) {
	const { methods, prefix, title, t } = props;
	const blockClassName = 'rounded-8 border px-16 pt-10 pb-4 mb-16';
	const blockTitleClassName = 'py-8 pb-16';

	return (
		<div className={blockClassName}>
			<div className={blockTitleClassName}>
				<div className="flex items-center">
					<Typography className="h2 mx-10 font-medium" color="textSecondary">
						{title}
					</Typography>
				</div>
			</div>
			<div className="flex flex-row">
				<Controller
					control={methods.control}
					name={`${prefix}.displayAsPercentage`}
					render={({ field, fieldState }) => (
						<EditFormCheckboxField
							field={field}
							fieldState={fieldState}
							config={{ topic: 'whatIf' }}
							fieldConfig={{
								key: `${prefix}.displayAsPercentage`,
								text: t('displayAsPercentage')
							}}
						/>
					)}
				/>
				<Controller
					control={methods.control}
					name={`${prefix}.showUnit`}
					render={({ field, fieldState }) => (
						<EditFormCheckboxField
							field={field}
							fieldState={fieldState}
							config={{ topic: 'whatIf' }}
							fieldConfig={{
								key: `${prefix}.showUnit`,
								text: t('showUnit')
							}}
						/>
					)}
				/>
			</div>
			<div className="flex flex-row">
				<Controller
					control={methods.control}
					name={`${prefix}.thousandsSeparator`}
					render={({ field, fieldState }) => (
						<EditFormCheckboxField
							field={field}
							fieldState={fieldState}
							config={{ topic: 'whatIf' }}
							fieldConfig={{
								key: `${prefix}.thousandsSeparator`,
								text: t('thousandsSeparator')
							}}
						/>
					)}
				/>
				<Controller
					control={methods.control}
					name={`${prefix}.decimalPlaces`}
					render={({ field, fieldState }) => (
						<EditFormTextField
							field={field}
							fieldState={fieldState}
							config={{ topic: 'whatIf' }}
							fieldConfig={{
								key: `${prefix.decimalPlaces}`,
								text: t('decimalPlaces'),
								type: 'number'
							}}
						/>
					)}
				/>
			</div>
		</div>
	);
}
