import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import OptimizationCaseTableService from '../service/OptimizationCaseTableService';
import RunOptimizationCaseButton from '../component/RunOptimizationCaseButton';
import CreateOptimCaseFromAspect from '../component/CreateOptimCaseFromAspect';

const config = {
	key: 'optimizationCaseTable',
	topic: 'optimizationCase',
	service: OptimizationCaseTableService,
	editFormState: item => `/optimization-case/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['search', 'refresh', 'new'],
	rowAction: ['modify', 'delete'],
	rowButtonList: [{ key: 'runOptimCase', component: RunOptimizationCaseButton }],
	toolbarButtonList: [
		{
			key: 'createFromAspect',
			place: 'before',
			component: CreateOptimCaseFromAspect
		}
	],
	columns: [
		{
			key: 'name'
		},

		{
			key: 'optimizer.name'
		},
		{
			key: 'optimizerLog.name'
		},
		{
			key: 'aspect.name'
		},
		{
			key: 'transformer.name'
		}
	]
};

function OptimizationCaseTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('optimizationCaseTable', reducer('optimizationCaseTable'))(OptimizationCaseTable);
