const datasetEn = {
	datasetTable: 'Dataset',
	name: 'Name',
	datasetEditForm: 'Dataset',
	basic: 'Basic',
	table_name: 'Table Name',
	description: 'Description',
	fields: 'Fields',
	role: 'Role',
	type: 'Type',
	key_fields: 'Key fields',
	value_fields: 'Value fields'
};

export default datasetEn;
