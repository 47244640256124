import _ from '@lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Tooltip, TableCell, Switch } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { showMessage } from 'app/store/fuse/messageSlice';
import ConfirmDialog from 'modules/ui/component/ConfirmDialog';
import SchedulerTableService from '../service/SchedulerTableService';

function UpdateEnableTableCell(props) {
	const { t } = useTranslation('scheduler');
	const { item } = props;
	const [disabled, setDisabled] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);
	const dispatch = useDispatch();

	const handleUpdateEnabled = () => {
		setDisabled(true);
		setShowConfirm(false);
		SchedulerTableService.updateEnabled(item.name, !item.enabled).then(resp => {
			setDisabled(false);
			if (resp == null) {
				dispatch({ type: `${props.config.tableKey}/event/setNeedDataReload` });
			} else {
				dispatch(showMessage({ message: resp }));
			}
		});
	};
	return (
		<TableCell className="w-auto text-center" padding="none">
			<Tooltip title={item.enabled ? 'Enabled' : 'Disabled'}>
				<Switch checked={item.enabled} disabled={disabled} onChange={() => setShowConfirm(true)} inputProps={{ 'aria-label': 'secondary checkbox' }} />
			</Tooltip>
			<ConfirmDialog
				show={showConfirm}
				title={t('update_enable_confirm_title')}
				description={t(item.enabled ? 'disable_confirm_text' : 'enable_confirm_text')}
				onYes={() => handleUpdateEnabled()}
				onClose={() => setShowConfirm(false)}
			/>
		</TableCell>
	);
}

export default UpdateEnableTableCell;
