import { authRoles } from 'app/auth';
import i18next from 'i18next';
import OptimizerTable from './table/OptimizerTable';
import en from './i18n/en';
import hu from './i18n/hu';
import OptimizerEditForm from './editform/OptimizerEditForm';

i18next.addResourceBundle('en', 'optimizer', en);
i18next.addResourceBundle('hu', 'optimizer', hu);

export default [
	{
		path: '/optimizer/edit/:type/:id/:tabKey?',
		component: OptimizerEditForm,
		auth: authRoles.optimizer
	},
	{
		path: '/optimizer/:sessionNameParam?',
		component: OptimizerTable,
		auth: authRoles.optimizer
	}
];
