import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams, withRouter, Link, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useTranslation } from 'react-i18next';
import NewPasswordDto from './NewPasswordDto';
import UserAccountService from './UserAccountService';

const useStyles = makeStyles(theme => ({
	root: {
		background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(theme.palette.primary.dark, 0.5)} 100%)`,
		color: theme.palette.primary.contrastText
	},
	leftSection: {}
}));

const defaultValues = {
	password: '',
	passwordAgain: ''
};

function NewPassword() {
	const { userId, key } = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation(['auth', 'validation']);
	const [loading, setLoading] = useState(false);
	/**
	 * Form Validation Schema
	 */
	const schema = yup.object().shape({
		password: yup.string().required(t('validation:PASSWORD_REQUIRED')).min(5, t('validation:PASSWORD_IS_TOO_SHORT')),
		passwordAgain: yup.string().oneOf([yup.ref('password'), null], t('validation:PASSWORD_DONT_MATCH'))
	});

	const { control, setValue, formState, handleSubmit, reset, trigger } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});
	const [showPassword, setShowPassword] = useState(false);
	const { isValid, dirtyFields, errors } = formState;

	useEffect(() => {
		setValue('email', '', { shouldDirty: true, shouldValidate: true });
	}, [reset, setValue, trigger]);

	function onSubmit(model) {
		setLoading(true);
		UserAccountService.setNewPassword(key, Number.parseInt(userId, 10), new NewPasswordDto().setup(model)).then(resp => {
			setLoading(false);
			if (resp.successful) {
				dispatch(showMessage({ message: t('NEW_PASSWORD_SET_MESSAGE') }));
				history.push('/login');
			} else {
				dispatch(showMessage({ message: t('NEW_PASSWORD_SET_FAILED_MESSAGE') }));
			}
		});
	}

	return (
		<div className={clsx(classes.root, 'flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24')}>
			<motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }} className="flex min-w-320 max-w-400 md:max-w-3xl rounded-20 shadow-2xl overflow-hidden">
				<Card className={clsx(classes.leftSection, 'flex flex-col w-full max-w-sm items-center justify-center shadow-0')} square>
					<CardContent className="flex flex-col items-center justify-center w-full py-24 max-w-320">
						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
							<div className="flex items-center py-24">
								<img className="w-full h-auto" src="assets/images/logos/mol_logo.svg" alt="MOL" />
							</div>
						</motion.div>

						<div className="w-full">
							<div className="pb-16">{t('PLEASE_SET_PASSWORD_MESSAGE')}</div>
							<form className="flex flex-col justify-center w-full" onSubmit={handleSubmit(onSubmit)}>
								<Controller
									name="password"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											className="mb-16"
											label={t('PASSWORD')}
											type="password"
											error={!!errors.password}
											helperText={errors?.password?.message}
											variant="outlined"
											autoComplete="new-password"
											InputProps={{
												className: 'pr-2',
												type: showPassword ? 'text' : 'password',
												endAdornment: (
													<InputAdornment position="end">
														<IconButton onClick={() => setShowPassword(!showPassword)}>
															<Icon className="text-20" color="action">
																{showPassword ? 'visibility' : 'visibility_off'}
															</Icon>
														</IconButton>
													</InputAdornment>
												)
											}}
											required
										/>
									)}
								/>

								<Controller
									name="passwordAgain"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											className="mb-16"
											label={t('PASSWORD_AGAIN')}
											type="password"
											error={!!errors.passwordAgain}
											helperText={errors?.passwordAgain?.message}
											variant="outlined"
											autoComplete="new-password"
											InputProps={{
												className: 'pr-2',
												type: showPassword ? 'text' : 'password',
												endAdornment: (
													<InputAdornment position="end">
														<IconButton onClick={() => setShowPassword(!showPassword)}>
															<Icon className="text-20" color="action">
																{showPassword ? 'visibility' : 'visibility_off'}
															</Icon>
														</IconButton>
													</InputAdornment>
												)
											}}
											required
										/>
									)}
								/>

								<Button type="submit" variant="contained" color="primary" className="w-full mx-auto mt-16" aria-label="LOG IN" disabled={_.isEmpty(dirtyFields) || !isValid || loading} value="legacy">
									{t('SAVE_PASSWORD')}
								</Button>
							</form>
						</div>
					</CardContent>

					<div className="flex flex-col items-center justify-center pb-32">
						<div>
							<Link className="font-normal" to="/login">
								{t('BACK_TO_LOGIN')}
							</Link>
						</div>
					</div>
				</Card>
			</motion.div>
		</div>
	);
}

export default withRouter(NewPassword);
