import _ from '@lodash';
import { useEffect, useState, useRef } from 'react';
import { Transformer, Rect, Text, Circle } from 'react-konva';
import { calcJoinPointsByLinePoints } from '../component/WhatIfUtil';
import { useWhatIf } from '../component/WhatIfProvider';

const sensitivity = 6;
const itemBorder = 5;
const joinPointRadius = 6;

const calcBorderLinePoints = item => {
	const _w = item.width + itemBorder * 2;
	const _h = item.height + itemBorder * 2;
	const offset = { x: item.width / 2 + itemBorder, y: item.height / 2 + itemBorder };
	const tl = [item.x - offset.x, item.y - offset.y];
	const tr = [tl[0] + _w, item.y - offset.y];
	const bl = [tl[0], item.y + offset.y];
	const br = [tr[0], item.y + offset.y];
	return [...tl, ...tr, ...br, ...bl, ...tl];
};

function RectItem(props) {
	const { item } = props;
	const [pos, setPos] = useState({ x: item.x, y: item.y });
	const [activeJoinPoint, setActiveJoinPoint] = useState(null);
	const [isDragging, setIsDragging] = useState(false);

	const offset = {
		x: item.width / 2,
		y: item.height / 2
	};
	const labelWidth = item.rotate === 90 || item.rotate === 270 ? item.height : item.width;
	const borderLinePoints = calcBorderLinePoints(item);
	const joinPoints = calcJoinPointsByLinePoints(borderLinePoints);

	const { editMode, data, setData, figure, setFigure, underEditing, setUnderEditing, setShowPropertiesDialog, checkStageCorner, initLine, endLine } = useWhatIf();
	const rectRef = useRef();
	const trRef = useRef();

	useEffect(() => {
		if (editMode === 'RESIZE' && underEditing !== null && underEditing.key === item.key) {
			trRef.current.nodes([rectRef.current]);
			trRef.current.getLayer().batchDraw();
		}
	}, [underEditing]);

	const handleItemClick = () => {
		if (editMode === 'DELETE') {
			setFigure({
				...figure,
				rect: figure.rect.filter(d => d.key !== item.key)
			});
		} else if (editMode === 'RESIZE') {
			setUnderEditing(item);
		} else if (editMode === 'ROTATE') {
			setFigure({
				...figure,
				rect: figure.rect.map(d => (d.key === item.key ? { ...d, width: d.height, height: d.width } : d))
			});
		}
	};

	const handleResize = e => {
		const rectNode = rectRef.current;
		const _w = rectNode.width() * rectNode.scaleX();
		const _h = rectNode.height() * rectNode.scaleY();
		const currentPos = { x: rectNode.x(), y: rectNode.y() };
		setPos(currentPos);
		setFigure({
			...figure,
			rect: figure.rect.map(d => {
				if (d.key === item.key) {
					return { ...item, ...currentPos, width: _w, height: _h };
				}
				return d;
			})
		});
	};

	const updateDragPos = e => {
		const currentPos = { x: e.target.x(), y: e.target.y() };
		setPos(currentPos);
		setFigure({
			...figure,
			rect: figure.rect.map(d => {
				if (d.key === item.key) {
					return { ...item, ...currentPos };
				}
				return d;
			})
		});
	};

	const handleJoinPointEnter = id => {
		if (editMode === 'LINE') {
			setActiveJoinPoint(id);
		}
	};

	const handleJoinPointLeave = i => {
		if (editMode === 'LINE') {
			setActiveJoinPoint(null);
		}
	};

	const handleJoinPointClick = (id, e) => {
		if (editMode === 'LINE') {
			const joinPoint = joinPoints[id];
			if (underEditing !== null) {
				endLine(joinPoint.x, joinPoint.y);
			} else {
				initLine(joinPoint.x, joinPoint.y);
			}
		}
	};

	return (
		<>
			<Rect
				onPointerClick={() => handleItemClick()}
				onPointerDblClick={() => editMode === 'MOVE' && setShowPropertiesDialog({ type: 'RECT', key: item.key })}
				ref={rectRef}
				x={pos.x}
				y={pos.y}
				width={item.width}
				height={item.height}
				offsetX={offset.x}
				offsetY={offset.y}
				fill={item.fill ? item.fillColor : null}
				stroke={item.borderColor}
				onTransformEnd={e => handleResize(e)}
				scaleX={1}
				scaleY={1}
				draggable={editMode === 'MOVE'}
				onDragStart={() => {
					setIsDragging(true);
				}}
				onDragMove={e => {
					updateDragPos(e);
					checkStageCorner();
				}}
				onDragEnd={e => {
					setIsDragging(false);
					updateDragPos(e);
				}}
			/>
			<Rect
				x={pos.x - offset.x - itemBorder}
				y={pos.y - offset.y - itemBorder}
				width={item.width + itemBorder * 2}
				height={item.height + itemBorder * 2}
				dash={[10, 10]}
				stroke="black"
				strokeWidth={1}
				opacity={isDragging ? 1 : 0}
				listening={false}
			/>
			{joinPoints.map((j, i) => (
				<Circle
					key={i}
					x={j.x}
					y={j.y}
					radius={joinPointRadius}
					fill="darkgreen"
					opacity={activeJoinPoint === i ? 1 : 0}
					onPointerEnter={e => handleJoinPointEnter(i)}
					onPointerLeave={e => handleJoinPointLeave(i)}
					onPointerClick={e => handleJoinPointClick(i, e)}
				/>
			))}
			{!_.isEmpty(item.name) && (underEditing === null || underEditing.key !== item.key) && (
				<Text
					text={item.name}
					fontSize={14}
					fill="black"
					fontStyle="bold"
					x={pos.x}
					y={pos.y}
					width={labelWidth}
					offsetX={labelWidth / 2}
					offsetY={8}
					align="center"
					stroke="white"
					strokeWidth={3}
					fillAfterStrokeEnabled
					listening={false}
				/>
			)}
			{underEditing !== null && underEditing.key === item.key && (
				<Transformer
					ref={trRef}
					padding={itemBorder}
					borderDash={[10, 10]}
					borderStroke="black"
					borderStrokeWidth={1}
					anchorStroke="black"
					rotateEnabled={false}
					boundBoxFunc={(oldBox, newBox) => {
						if (newBox.width < 12 || newBox.height < 12) {
							return oldBox;
						}
						return newBox;
					}}
				/>
			)}
		</>
	);
}

export default RectItem;
