import { useParams, withRouter } from 'react-router';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormFileInputField from 'modules/ui/editform/fields/EditFormFileInputField';
import PictogramEditService from '../service/PictogramEditService';

const config = data => {
	return {
		key: 'pictogramEditForm',
		text: 'Pictogram',
		topic: 'pictogram',
		service: PictogramEditService,
		fields: initData => {
			const fields = [
				{
					key: 'name',
					component: EditFormTextField,
					required: true
				},
				{
					key: 'file',
					text: 'Pictogram',
					component: EditFormFileInputField,
					required: true
				}
			];

			return fields;
		}
	};
};

function PictogramEditForm() {
	const { id, tabKey } = useParams();

	const myConfig = config({ id });
	return <EditFormPage config={myConfig} startTabValue={tabKey} />;
}

export default withRouter(PictogramEditForm);
