import _ from '@lodash';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useWorkflow } from '../../WorkflowProvider';
import { TransInputKeyField, TransNameField, TransOutputKeyField, TransTextField, TransValidationIOKey, TransValidationName } from '../TransformationProperties';
import TransformationPropertiesDialog from '../TransformationPropertiesDialog';

function MassFlowToMassFracProperties() {
	const { data, showPropertiesDialog, transformationNameAvailable } = useWorkflow();
	const { t } = useTranslation('validation');
	const item = data.transform.find(d => d.key === showPropertiesDialog.key);

	const schema = yup.object().shape({
		name: TransValidationName(transformationNameAvailable, item.key),
		input_fields: TransValidationIOKey(),
		output_fields: yup
			.array()
			.min(2, t('FIELD_REQUIRED'))
			.required(t('FIELD_REQUIRED'))
			.of(
				yup.object().shape({
					name: yup.string().nullable().required(t('FIELD_REQUIRED'))
				})
			)
	});

	return (
		<TransformationPropertiesDialog schema={schema} titleKey="massFlowToMassFracProperties">
			<TransNameField />
			<TransInputKeyField />
			<TransOutputKeyField />
			<TransTextField fieldKey="output_fields.1.name" labelKey="mass_flow_key" required />
			<TransTextField fieldKey="mass_flow_column" />
			<TransTextField fieldKey="mass_frac_column" />
			<TransTextField fieldKey="component_title" />
		</TransformationPropertiesDialog>
	);
}
export default MassFlowToMassFracProperties;
