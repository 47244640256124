import { useState } from 'react';
import { Button, Icon, Menu, MenuItem, ThemeProvider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import DriverService from 'app/admin/driver/service/DriverService';

const links = {
	hysys: '/model-hysys/edit/new/',
	ann: '/model-ann/edit/new/',
	lummus: '/model-lummus/edit/new/',
	lummus2: '/model-lummus2/edit/new/',
	expression: '/model-expression/edit/new/',
	workflow: '/model-workflow/edit/new/',
	opc: '/model-opc/edit/new/'
};

function AddModelButton() {
	const mainTheme = useSelector(selectMainTheme);
	const [anchorEl, setAnchorEl] = useState(null);
	const [drivers, setDrivers] = useState(null);

	if (drivers == null) {
		DriverService.getList().then(resp => {
			setDrivers(resp);
		});
		return <></>;
	}

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className="whitespace-nowrap mx-8" variant="contained" color="secondary" startIcon={<Icon className="hidden sm:flex">add</Icon>}>
				Add new model
			</Button>
			<ThemeProvider theme={mainTheme}>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
				>
					{drivers.map((driver, i) => (
						<MenuItem key={i} component={Link} to={links[driver.driver_type] + driver.name} role="button">
							{driver.name}
						</MenuItem>
					))}
				</Menu>
			</ThemeProvider>
		</>
	);
}

export default AddModelButton;
