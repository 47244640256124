import _ from '@lodash';
import AspectService from './AspectService';

class StorageAspectService {
	constructor() {
		this.outputKeysResult = [];
		this.inputAttributeResult = [];
		this.outputAttributeResult = [];
	}

	getOutputKeysSelection(id) {
		if (_.isUndefined(this.outputKeysResult[id]))
			return new Promise((resolve, reject) => {
				AspectService.getOutputKeysSelection(id)
					.then(resp => {
						this.outputKeysResult[id] = resp;
						resolve(resp);
					})
					.catch(e => reject(e));
			});
		return new Promise(resolve => {
			resolve(this.outputKeysResult[id]);
		});
	}

	getInputAttributesListSelection(id) {
		if (_.isUndefined(this.inputAttributeResult[id]))
			return new Promise((resolve, reject) => {
				AspectService.getInputAttributesListSelection(id)
					.then(resp => {
						this.inputAttributeResult[id] = resp;
						resolve(resp);
					})
					.catch(e => reject(e));
			});
		return new Promise(resolve => {
			resolve(this.inputAttributeResult[id]);
		});
	}
}
export default StorageAspectService;
