import _ from '@lodash';
import { useSelector } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import { ThemeProvider } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { useFormContext, useWatch } from 'react-hook-form';
import { Paper, Button, Icon, Dialog, DialogTitle, DialogContent, DialogActions, TableContainer, Table, TableHead, TableRow, TableCell, Checkbox, TableBody } from '@material-ui/core';
import ModelService from 'app/admin/model/service/ModelService';

function AspectModelAttributeLoad(props) {
	const aspectFormMethods = useFormContext();
	const mainTheme = useSelector(selectMainTheme);
	const [loading, setLoading] = useState(false);
	const [inputSelected, setInputSelected] = useState([]);
	const [outputSelected, setOutputSelected] = useState([]);
	const [inputs, setInputs] = useState([]);
	const [outputs, setOutputs] = useState([]);
	const [showDialog, setShowDialog] = useState(false);
	const aspectModel = useWatch({
		name: 'model',
		control: aspectFormMethods.control
	});

	useEffect(() => {
		if (showDialog) {
			setLoading(true);
			ModelService.getData(aspectModel).then(model => {
				const inputAttributes = model.export ? model.export.input_attributes : model.input_attributes;
				const outputAttributes = model.export ? model.export.output_attributes : model.output_attributes;
				const currentInputs = aspectFormMethods.getValues('input_attributes').map(inp => (inp.model_field_name ? inp.model_field_name : inp.name));
				const currentOutputs = aspectFormMethods.getValues('output_attributes').map(outp => (outp.model_field_name ? outp.model_field_name : outp.name));
				const _inputs = [];
				const _outputs = [];
				const _inputSelected = [];
				const _outputSelected = [];
				inputAttributes.forEach(attribute => {
					if (currentInputs.indexOf(attribute.name) === -1) {
						_inputs.push(attribute);
						_inputSelected.push(attribute.name);
					}
				});
				outputAttributes.forEach(attribute => {
					if (currentOutputs.indexOf(attribute.name) === -1) {
						_outputs.push(attribute);
						_outputSelected.push(attribute.name);
					}
				});
				setInputSelected(_inputSelected);
				setInputs(_inputs);
				setOutputSelected(_outputSelected);
				setOutputs(_outputs);
				setLoading(false);
			});
		}
	}, [showDialog]);

	const handleAllInputClick = () => {
		if (inputSelected.length > 0) setInputSelected([]);
		else setInputSelected(inputs.map(inp => inp.name));
	};
	const handleInputClick = inp => {
		if (inputSelected.indexOf(inp.name) > -1) setInputSelected(inputSelected.filter(d => d !== inp.name));
		else setInputSelected([...inputSelected, inp.name]);
	};

	const handleAllOutputClick = () => {
		if (outputSelected.length > 0) setOutputSelected([]);
		else setOutputSelected(outputs.map(outp => outp.name));
	};
	const handleOutputClick = outp => {
		if (outputSelected.indexOf(outp.name) > -1) setOutputSelected(outputSelected.filter(d => d !== outp.name));
		else setOutputSelected([...outputSelected, outp.name]);
	};

	const handleLoad = () => {
		setShowDialog(false);
		if (inputSelected.length > 0) {
			const newInputs = [];
			inputs.forEach(inp => {
				if (inputSelected.indexOf(inp.name) > -1) {
					newInputs.push({
						id: null,
						name: inp.name,
						type: inp.type,
						value: inp.value,
						attribute_class: null
					});
				}
			});
			aspectFormMethods.setValue('input_attributes', [...aspectFormMethods.getValues('input_attributes'), ...newInputs]);
		}
		if (outputSelected.length > 0) {
			const newOutputs = [];
			outputs.forEach(outp => {
				if (outputSelected.indexOf(outp.name) > -1) {
					newOutputs.push({
						id: null,
						name: outp.name,
						attribute_class: null
					});
				}
			});
			aspectFormMethods.setValue('output_attributes', [...aspectFormMethods.getValues('output_attributes'), ...newOutputs]);
		}
	};

	return (
		<>
			<Button className="whitespace-nowrap mx-4" variant="contained" color="secondary" disabled={aspectModel == null} onClick={() => setShowDialog(true)} startIcon={<Icon className="hidden sm:flex">add</Icon>}>
				Model attr.
			</Button>
			{showDialog && (
				<ThemeProvider theme={mainTheme}>
					<Dialog open fullWidth>
						<DialogTitle disableTypography className="border-b">
							Load Attribute from Model
						</DialogTitle>
						<DialogContent className="p-20">
							{loading && <FuseLoading />}
							{!loading && inputs.length > 0 && (
								<TableContainer component={Paper} className="mb-12">
									<Table className="w-full" aria-label="simple table">
										<TableHead>
											<TableRow onClick={() => handleAllInputClick()}>
												<TableCell align="left" padding="checkbox">
													<Checkbox checked={inputSelected.length === inputs.length} indeterminate={inputSelected.length > 0 && inputSelected.length < inputs.length} />
												</TableCell>
												<TableCell>Inputs</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{inputs.map((input, idx) => (
												<TableRow key={idx} onClick={() => handleInputClick(input)}>
													<TableCell align="right" padding="checkbox">
														<Checkbox checked={inputSelected.indexOf(input.name) > -1} />
													</TableCell>
													<TableCell component="th" scope="row">
														{input.name}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							)}
							{!loading && outputs.length > 0 && (
								<TableContainer component={Paper}>
									<Table className="w-full" aria-label="simple table">
										<TableHead>
											<TableRow onClick={() => handleAllOutputClick()}>
												<TableCell align="left" padding="checkbox">
													<Checkbox checked={outputSelected.length === outputs.length} indeterminate={outputSelected.length > 0 && outputSelected.length < outputs.length} />
												</TableCell>
												<TableCell>Outputs</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{outputs.map((output, idx) => (
												<TableRow key={idx} onClick={() => handleOutputClick(output)}>
													<TableCell align="right" padding="checkbox">
														<Checkbox checked={outputSelected.indexOf(output.name) > -1} />
													</TableCell>
													<TableCell component="th" scope="row">
														{output.name}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							)}
							{!loading && inputs.length === 0 && outputs.length === 0 && <p>No inputs or outputs available.</p>}
						</DialogContent>
						<DialogActions className="justify-between border-t p-20">
							<Button
								onClick={() => {
									setShowDialog(false);
								}}
							>
								Close
							</Button>
							<Button onClick={() => handleLoad()} disabled={loading || (inputSelected.length === 0 && outputSelected.length === 0)}>
								Load
							</Button>
						</DialogActions>
					</Dialog>
				</ThemeProvider>
			)}
		</>
	);
}

export default AspectModelAttributeLoad;
