const editformEn = {
	BACK: 'Back',
	SAVE: 'Save',
	NEW: 'New',
	DETAILS: 'details',
	NO_DATAS: 'No data available!',
	BACK_TO_PREV_PAGE: 'Back to previous page',
	LEAVE_CONFIRM: 'Confirm',
	LEAVE_CONFIRM_MESSAGE: 'You have unsaved changes on this page. Do you want to leave this page and discard your changes?',
	SAVE_SUCCESSFUL: 'Save Successful',
	REQUEST_EDIT_ACCESS_FAILED: 'Editing data is not allowed because {{activeUserName}} is currently editing.',
	SUB_ADD_NEW: 'Add New',
	SUB_NEW_ITEM: 'New Item',
	SUB_SELECTED_TO_MOVE: 'To be moved',
	SUB_SELECTED_TO_MOVE_TARGET: 'Target of move',
	SUB_BEFORE: 'Before',
	SUB_AFTER: 'After',
	SUB_DELETE: 'Delete',
	SUB_DELETE_MESSAGE: 'Are you sure you want to delete this item?',
	SUB_ITEM_SETTINGS: 'settings',
	SIZE: 'Size',
	NOT_SELECTED: 'Not selected.',
	FILE_UPLOADING_ERROR: 'An error occurred during the upload.',
	FILE_READONLY: 'You are not authorized to upload a file.',
	SELECT_FILE: 'Select a File...',
	UPLOADING_PROGRESS: 'Uploading progress...',
	UPLOADING_DONE: 'Uploading Done.',
	DELETE: 'Delete',
	SELECT_EMPTY: 'Select...',
	move_up: 'Move up',
	move_down: 'Move down'
};

export default editformEn;
