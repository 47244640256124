import ModelEditDto from 'app/admin/model/dto/ModelEditDto';
import HysysModelInputAttributeEditDto from './HysysModelInputAttributeEditDto';
import HysysModelOutputAttributeEditDto from './HysysModelOutputAttributeEditDto';

export class HysysModelEditDto extends ModelEditDto {
	constructor() {
		super();
		/** @type {string} */
		this.hsc_file = null;
		/** @type {HysysModelInputAttributeEditDto[]} */
		this.input_attributes = null;
		/** @type {HysysModelOutputAttributeEditDto[]} */
		this.output_attributes = null;
	}

	/**
	 * @typedef {Object} _HysysModelEditDtoProps
	 * @property {string} hsc_file
	 * @property {HysysModelInputAttributeEditDto[]} input_attributes
	 * @property {HysysModelOutputAttributeEditDto[]} output_attributes
	 *
	 * @typedef {import("app/admin/model/dto/ModelEditDto").ModelEditDtoProps & _HysysModelEditDtoProps} HysysModelEditDtoProps
	 */
	/**
	 *
	 * @param {HysysModelEditDtoProps} props
	 * @returns {HysysModelEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.hsc_file = props.hsc_file;
		this.input_attributes = props.input_attributes !== null ? props.input_attributes.map(d => new HysysModelInputAttributeEditDto().setup(d)) : [];
		this.output_attributes = props.output_attributes !== null ? props.output_attributes.map(d => new HysysModelOutputAttributeEditDto().setup(d)) : [];
		return this;
	}
}

export default HysysModelEditDto;
