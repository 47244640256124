import _ from '@lodash';
import { useSelector, useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import { ThemeProvider } from '@material-ui/core/styles';
import { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Icon, Dialog, DialogTitle, DialogContent, DialogActions, Paper, TableContainer, Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';
import WorkflowCalculateForm from './calculate/WorkflowCalculateForm';
import TransformerService from '../service/TransformerService';
import { useWorkflow } from './WorkflowProvider';
import TransformerEditService from '../service/TransformerEditService';
import WorkflowConverter from './WorkflowConverter';

function TransformerDebug(props) {
	const mainTheme = useSelector(selectMainTheme);
	const { data } = useWorkflow();
	const { t } = useTranslation('validation');
	const dispatch = useDispatch();
	const { id, dto } = props;
	const [showForm, setShowForm] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [result, setResult] = useState(null);
	const [message, setMessage] = useState(null);

	const schema = yup.object().shape({
		aspect: yup.string().nullable().required(t('FIELD_REQUIRED'))
	});

	const methods = useForm({
		defaultValues: {
			aspect: null
		},
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const handleSave = value => {
		setShowForm(false);
		setLoading(true);
		const _dto = { ...dto, json: data, export: WorkflowConverter(dto, data) };
		if (id !== 'new') {
			TransformerEditService.update(dto.id, _dto).then(resp => handleStoreResponse(resp, value));
		} else {
			TransformerEditService.save(_dto).then(resp => handleStoreResponse(resp, value));
		}
	};

	const handleStoreResponse = (resp, value) => {
		if (resp.successful && resp.engineSuccessful) {
			TransformerService.debug({
				name: dto.name,
				...value
			}).then(response => handleResponse(response));
		} else {
			setLoading(false);
			setResult(null);
			setMessage(!resp.engineSuccessful ? resp.engineMessage : 'Save transformer failed.');
		}
	};

	const handleClick = () => {
		setLoading(false);
		setResult(null);
		setMessage(null);
		setShowForm(true);
		setShowDialog(true);
	};

	const handleResponse = response => {
		setLoading(false);
		if (response !== null && _.isObject(response)) {
			console.log(response);
			setShowDialog(false);
			props.onCalculate(response);
		} else {
			setResult(null);
			if (_.isString(response)) {
				setMessage(response);
			}
		}
	};

	return (
		<FormProvider {...methods}>
			<Button className="whitespace-nowrap mx-4" variant="contained" color="secondary" disabled={id === 'new'} onClick={() => handleClick()} startIcon={<Icon className="hidden sm:flex">bug_report</Icon>}>
				Debug
			</Button>
			<ThemeProvider theme={mainTheme}>
				<Dialog open={showDialog} fullWidth>
					<DialogTitle disableTypography className="border-b">
						Debug
					</DialogTitle>
					<DialogContent className="p-20">
						{showForm && <WorkflowCalculateForm />}
						{loading && <FuseLoading />}
						{!showForm && !loading && result !== null && result.length > 0 && (
							<TableContainer component={Paper}>
								<Table className="w-full" aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Name</TableCell>
											<TableCell align="right">Value</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{result !== null &&
											result.map(row => (
												<TableRow key={row.name}>
													<TableCell component="th" scope="row" colSpan={row.parent ? 2 : 1} className={row.parent ? 'font-bold' : row.sub ? 'pl-32' : ''}>
														{row.name}
													</TableCell>
													{!row.parent && (
														<TableCell className="font-mono" align="right">
															{row.value}
														</TableCell>
													)}
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
						{!showForm && !loading && (result === null || result.length === 0) && <div className="flex justify-center">{message || 'No result.'}</div>}
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button
							onClick={() => {
								setShowDialog(false);
							}}
						>
							Close
						</Button>
						{showForm && <Button onClick={methods.handleSubmit(handleSave)}>Debug</Button>}
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</FormProvider>
	);
}

export default TransformerDebug;
