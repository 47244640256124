import IdentifiableDto from 'modules/base/dto/IdentifiableDto';

export class WhatIfNoteEditDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {string} */
		this.noteItemKey = null;
		/** @type {number} */
		this.whatIf = null;
		/** @type {number} */
		this.creator = null;
		/** @type {Date} */
		this.created = null;
		/** @type {string} */
		this.note = null;
	}

	/**
	 * @typedef {Object} _WhatIfNoteEditDtoProps
	 * @property {string} noteItemKey
	 * @property {number} whatIf
	 * @property {number} creator
	 * @property {Date} created
	 * @property {string} note
	 * @typedef {import("modules/base/dto/IdentifiableDto").IdentifiableDtoProps & _WhatIfNoteEditDtoProps} WhatIfNoteEditDtoProps
	 */
	/**
	 *
	 * @param {WhatIfNoteEditDtoProps} props
	 * @returns {WhatIfNoteEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.noteItemKey = props.noteItemKey;
		this.whatIf = props.whatIf;
		this.creator = props.creator;
		this.created = props.created != null ? new Date(props.created) : null;
		this.note = props.note;
		return this;
	}
}

export default WhatIfNoteEditDto;
