import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './i18n/en';
import hu from './i18n/hu';
import WhatIfEditPage from './page/WhatIfEditPage';
import WhatIfProvider from './component/WhatIfProvider';
import WhatIfExpertTable from './table/WhatIfExpertTable';
import WhatIfTable from './table/WhatIfTable';
import WhatIfGroupEditForm from './editform/WhatIfGroupEditForm';
import WhatIfGroupTable from './table/WhatIfGroupTable';

i18next.addResourceBundle('en', 'whatIf', en);
i18next.addResourceBundle('hu', 'whatIf', hu);

export default [
	{
		path: '/what-if-group/edit/:id',
		component: WhatIfGroupEditForm,
		auth: authRoles.whatifGroup
	},
	{
		path: '/what-if-group/:sessionNameParam?',
		component: WhatIfGroupTable,
		auth: authRoles.whatifGroup
	},
	{
		path: '/what-if/edit/:id/:taskId?',
		component: () => (
			<WhatIfProvider mode="OPERATOR">
				<WhatIfEditPage />
			</WhatIfProvider>
		),
		auth: authRoles.whatif
	},
	{
		path: '/what-if/:sessionNameParam?',
		component: WhatIfTable,
		auth: authRoles.whatif
	},
	{
		path: '/what-if-expert/edit/:id',
		component: () => (
			<WhatIfProvider mode="EXPERT">
				<WhatIfEditPage />
			</WhatIfProvider>
		),
		auth: authRoles.whatifExpert
	},
	{
		path: '/what-if-expert/:sessionNameParam?',
		component: WhatIfExpertTable,
		auth: authRoles.whatifExpert
	}
];
