import _ from '@lodash';

export function formatLabelValue(value, format, uom, label) {
    const formatter = new Intl.NumberFormat('en-US', {
        useGrouping: format.thousandsSeparator || false,
        minimumFractionDigits: 0,
        maximumFractionDigits: Number.isInteger(format.decimalPlaces) ? format.decimalPlaces : 20
    });
    if (!value || _.isNaN(value)) {
        return `${label ? `${label}: ` : ''} -`;
    }
    const convertedValue = format.displayAsPercentage ? value * 100 : value;
    const formattedValue = formatter.format(convertedValue).replaceAll(',', ' ');
    return `${label ? `${label}: ` : ''}${format.displayAsPercentage ? `${formattedValue}${format.showUnit ? ' %' : ''}` : formattedValue}${format.showUnit ? `${uom ? ` ${uom}` : ''}` : ''}`;
}

export function logg(log1) {
    console.log(log1);
}