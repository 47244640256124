import _ from 'lodash';
import IdentifiableDto from 'modules/base/dto/IdentifiableDto';

export default class ScheduleEditDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {string} */
		this.minute = null;
		/** @type {string} */
		this.hour = null;
		/** @type {string} */
		this.day = null;
		/** @type {string} */
		this.month = null;
	}

	/**
	 * @typedef {Object} _ScheduleEditDtoProps
	 * @property {string} minute
	 * @property {string} hour
	 * @property {string} day
	 * @property {string} month
	 *
	 * @typedef {import("modules/base/dto/IdentifiableDto").IdentifiableDtoProps & _ScheduleEditDtoProps} ScheduleEditDtoProps
	 */
	/**
	 *
	 * @param {ScheduleEditDtoProps} props
	 * @returns {ScheduleEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.minute = props.minute != null ? props.minute : '*';
		this.hour = props.hour != null ? props.hour : '*';
		this.day = props.day != null ? props.day : '*';
		this.month = props.month != null ? props.month : '*';
		return this;
	}
}
