import _ from 'lodash';
import NamedDto from 'modules/base/dto/NamedDto';

export default class PiMeasurementReaderTableDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.username = null;
		/** @type {string} */
		this.api_url = null;
		/** @type {string} */
		this.server_id = null;
		/** @type {string>} */
		this.time_zone = null;
	}

	/**
	 * @typedef {Object} _PiMeasurementReaderTableDtoProps
	 * @property {string} username
	 * @property {string} api_url
	 * @property {string} server_id
	 * @property {string} time_zone
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _PiMeasurementReaderTableDtoProps} PiMeasurementReaderTableDtoProps
	 */
	/**
	 *
	 * @param {PiMeasurementReaderTableDtoProps} props
	 * @returns {PiMeasurementReaderTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.username = props.username;
		this.api_url = props.api_url;
		this.server_id = props.server_id;
		this.time_zone = props.time_zone;
		return this;
	}
}
