import nBossConnection from 'modules/base/service/nBossConnection';

class DriverLogService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'driverLogControl';
	}

	/**
	 * @return {Promise<NamedDto[]>}
	 */
	getListSelection() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getListSelection`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new DriverLogService();
