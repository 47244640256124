import _ from '@lodash';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, InputAdornment, CircularProgress } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import clsx from 'clsx';
import { determineEditableAccess } from '../EditFormUtils';

function EditFormFreeSoloField(props) {
	const { control, getValues } = useFormContext();
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const { error } = props.fieldState;
	const { value, onChange } = props.field;
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [autocompleteValue, setAutocompleteValue] = useState(props.fieldConfig.multiple ? [] : null);
	const [readOnly, setReadOnly] = useState(determineEditableAccess(props.config, props.fieldConfig, props.fieldPrefix));
	const queryTimeout = useRef(null);
	const { compact = false } = props;

	const parseFilterKey = () => {
		if (props.fieldConfig.filterKey) {
			if (_.isArray(props.fieldConfig.filterKey)) {
				return props.fieldConfig.filterKey.map(f => (!f.startsWith('^') ? (props.fieldPrefix || '') + f : f.substring(1)));
			}
			return !props.fieldConfig.filterKey.startsWith('^') ? (props.fieldPrefix || '') + props.fieldConfig.filterKey : props.fieldConfig.filterKey.substring(1);
		}
		return null;
	};

	const filterValue = useWatch({
		control,
		name: parseFilterKey()
		// defaultValue: null TODO: gondot okoz ha benne van
	});

	const getQuery = () => {
		setLoading(true);
		props.fieldConfig.query(inputValue || '', props.fieldConfig.limit || 100, props.fieldConfig.itemsParameterIsDto ? getValues() : filterValue).then(resp => {
			setLoading(false);
			if (resp && resp.length > 0) {
				setOptions(resp);
			} else {
				setOptions([]);
				setOpen(false);
			}
		});
	};

	const updateByCurrentValue = () => {
		setAutocompleteValue(value);
	};

	useEffect(() => {
		if (open) {
			if (queryTimeout && queryTimeout.current) clearTimeout(queryTimeout.current);
			queryTimeout.current = setTimeout(() => {
				if (open) {
					getQuery();
				}
			}, 500);
		}
	}, [inputValue]);

	const handleAutoCompleteOnChange = (e, v, r) => {
		if (r === 'create-option') {
			onChange(v);
			setAutocompleteValue(v);
			setOptions([v]);
		} else if (r === 'select-option') {
			onChange(v);
			setAutocompleteValue(v);
			setOptions([v]);
		} else if (r === 'clear') {
			onChange(props.fieldConfig.multiple ? [] : null);
			setAutocompleteValue(props.fieldConfig.multiple ? [] : null);
		} else if (r === 'remove-option') {
			onChange(v);
			setAutocompleteValue(v);
			setOptions([v]);
		}
	};

	useEffect(() => {
		if (!open && value && autocompleteValue === null) {
			updateByCurrentValue();
		}
	}, [value]);

	useEffect(() => {
		if (value) updateByCurrentValue();
	}, [props.fieldConfig, filterValue]);

	return (
		<Autocomplete
			open={open}
			multiple={!!props.fieldConfig.multiple}
			onOpen={() => {
				setOpen(true);
				if (!inputValue || inputValue.length === 0) getQuery();
			}}
			onClose={() => {
				setOpen(false);
			}}
			inputValue={inputValue}
			onInputChange={(e, v) => setInputValue(v)}
			getOptionSelected={(option, val) => option === val}
			getOptionLabel={option => option}
			defaultValue={null}
			value={autocompleteValue}
			onChange={(e, v, r) => handleAutoCompleteOnChange(e, v, r)}
			options={options}
			loading={loading}
			filterOptions={opt => opt}
			noOptionsText="Nincs találat"
			freeSolo
			disabled={readOnly}
			fullWidth
			disableClearable={props.fieldConfig.disableClearable || false}
			renderInput={params => (
				<TextField
					{...params}
					variant="outlined"
					className={clsx(compact ? '' : 'mt-8 mb-16', props.fieldConfig.className)}
					error={error}
					required={props.fieldConfig.required}
					helperText={error ? error.message : props.fieldConfig.helperText}
					label={!_.isUndefined(props.fieldConfig.text) ? props.fieldConfig.text : t(`${props.config.topic}:${props.fieldConfig.key}`)}
					id={props.field.name}
					fullWidth
					InputProps={{
						...params.InputProps,
						readOnly,
						endAdornment: readOnly ? (
							<InputAdornment position="end">
								<Lock fontSize="small" color="disabled" />
							</InputAdornment>
						) : (
							<>
								{loading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						)
					}}
				/>
			)}
		/>
	);
}

export default EditFormFreeSoloField;
