import _ from '@lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import { ThemeProvider } from '@material-ui/core/styles';
import { useState } from 'react';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useFormContext, useForm, Controller, FormProvider } from 'react-hook-form';
import { Button, Icon, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import FittingCaseService from '../service/FittingCaseService';

export default function (props) {
	const mainTheme = useSelector(selectMainTheme);
	const { t } = useTranslation('fittincCase');
	const dispatch = useDispatch();
	const { id } = props;
	const { getValues } = useFormContext();
	const [loading, setLoading] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [message, setMessage] = useState(null);
	const schema = yup.object().shape({
		name: yup.string().nullable().required(t('validation:FIELD_REQUIRED'))
	});
	const methods = useForm({
		defaultValues: { name: null },
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const handleClick = () => {
		const dto = getValues();
		setMessage(null);
		setLoading(false);
		console.log(dto.name);
		methods.setValue('name', dto.name);
		setShowDialog(true);
	};

	const handleLoad = dto => {
		setLoading(true);
		FittingCaseService.createFitterLog(dto.name, id).then(result => {
			setLoading(false);
			console.log(result);
			if (result === null) {
				dispatch(showMessage({ message: `Successfully created` }));
				setShowDialog(false);
			} else {
				setMessage(result);
			}
		});
	};

	return (
		<>
			<Button className="whitespace-nowrap mx-4" variant="contained" color="secondary" disabled={id === 'new'} onClick={() => handleClick()} startIcon={<Icon className="hidden sm:flex">add</Icon>}>
				Create Fitter log
			</Button>
			<ThemeProvider theme={mainTheme}>
				<Dialog open={showDialog} fullWidth>
					<DialogTitle disableTypography className="border-b">
						Create Fitter log
					</DialogTitle>
					<DialogContent className="p-20">
						<FormProvider {...methods}>
							{loading && <FuseLoading />}
							{!loading && (
								<Controller
									control={methods.control}
									name="name"
									render={({ field, fieldState }) => (
										<EditFormTextField
											field={field}
											fieldState={fieldState}
											config={{ topic: 'fittinCase' }}
											fieldConfig={{
												key: 'name',
												text: t('name'),
												required: true
											}}
										/>
									)}
								/>
							)}
							{!loading && message !== null && <p>{message}</p>}
						</FormProvider>
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button
							onClick={() => {
								setShowDialog(false);
							}}
						>
							Close
						</Button>
						<Button onClick={methods.handleSubmit(handleLoad)} disabled={loading}>
							Create
						</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</>
	);
}
