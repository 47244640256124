import NamedDto from 'modules/base/dto/NamedDto';

export class DomainEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {number} */
		this.step_count = null;
		/** @type {string} */
		this.type = null;
		/** @type {string} */
		this.value = null;
		/** @type {string} */
		this.min_value = null;
		/** @type {string} */
		this.max_value = null;
		/** @type {boolean} */
		this.enabled = null;
	}

	/**
	 * @typedef {Object} _DomainEditDtoProps
	 * @property {number} step_count
	 * @property {string} type
	 * @property {string} value
	 * @property {string} min_value
	 * @property {string} max_value
	 * @property {boolean} enabled
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _DomainEditDtoProps} DomainEditDtoProps
	 */
	/**
	 *
	 * @param {DomainEditDtoProps} props
	 * @returns {DomainEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.step_count = props.step_count;
		this.type = props.type;
		this.value = props.value;
		this.min_value = props.min_value;
		this.max_value = props.max_value;
		this.enabled = props.enabled;

		return this;
	}
}

export default DomainEditDto;
