import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './i18n/en';
import hu from './i18n/hu';
import MeasurementUnitEditForm from './editform/MeasurementUnitEditForm';
import MeasurementUnitTable from './table/MeasurementUnitTable';

i18next.addResourceBundle('en', 'measurementUnit', en);
i18next.addResourceBundle('hu', 'measurementUnit', hu);

export default [
	{
		path: '/measurement-unit/edit/:id',
		component: MeasurementUnitEditForm,
		auth: authRoles.measurementUnit
	},
	{
		path: '/measurement-unit/:sessionNameParam?',
		component: MeasurementUnitTable,
		auth: authRoles.measurementUnit
	}
];
