import { useState } from 'react';
import { Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

export default function DocumentListTableSwitchRevision(props) {
	const filterList = useSelector(rootState => rootState[props.config.key].tableBrowserFilter.filterList);
	const dispatch = useDispatch();
	const [showAll, setShowAll] = useState(false);

	const handleClick = () => {
		console.log(filterList);
		if (showAll) {
			dispatch({ type: `${props.config.key}/filter/setFilterList`, payload: [{ propertyName: 'lastRevision', type: 'EQ', value: true, permanent: false }] });
		} else {
			dispatch({ type: `${props.config.key}/filter/setFilterList`, payload: [] });
		}
		setShowAll(!showAll);
	};

	return (
		<Button className="whitespace-nowrap mx-4" variant="contained" color="secondary" onClick={() => handleClick()}>
			{showAll ? 'Hide old' : 'Show all'}
		</Button>
	);
}
