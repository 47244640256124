import NamedDto from 'modules/base/dto/NamedDto';

export class ModelEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.type = null;
		/** @type {number} */
		this.driver = null;
		/** @type {string} */
		this.image = null;
	}

	/**
	 * @typedef {Object} _ModelEditDtoProps
	 * @property {string} type
	 * @property {number} driver
	 * @property {string} image
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _ModelEditDtoProps} ModelEditDtoProps
	 */
	/**
	 *
	 * @param {ModelEditDtoProps} props
	 * @returns {ModelEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.type = props.type;
		this.driver = props.driver;
		this.image = props.image;
		return this;
	}
}

export default ModelEditDto;
