const driverHu = {
	driverTable: 'Driver',
	driverEditForm: 'Driver',
	name: 'Név',
	code: 'Kód',
	created: 'Létrehozva',
	updated: 'Módosítva',
	modelType: 'Model type'
};

export default driverHu;
