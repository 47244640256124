import { authRoles } from 'app/auth';
import ExpressionModelEditForm from './editform/ExpressionModelEditForm';

export default [
	{
		path: '/model-expression/edit/:id/:driverName?',
		component: ExpressionModelEditForm,
		auth: authRoles.model
	}
];
