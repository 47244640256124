import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import FitterLogTableService from '../service/FitterLogTableService';

const config = {
	key: 'fitterLogTable',
	topic: 'fitterLog',
	service: FitterLogTableService,
	editFormState: item => `/fitter-log/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['search', 'refresh', 'new'],
	rowAction: ['modify', 'delete'],
	columns: [
		{
			key: 'name'
		}
	]
};

function FitterLogTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('fitterLogTable', reducer('fitterLogTable'))(FitterLogTable);
