import NamedDto from 'modules/base/dto/NamedDto';

export class OptimizerEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.type = null;
	}

	/**
	 * @typedef {Object} _OptimizerEditDtoProps
	 * @property {string} type
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _OptimizerEditDtoProps} OptimizerEditDtoProps
	 */
	/**
	 *
	 * @param {OptimizerEditDtoProps} props
	 * @returns {OptimizerEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.type = props.type;
		return this;
	}
}

export default OptimizerEditDto;
