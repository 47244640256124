import { authRoles } from 'app/auth';
import i18next from 'i18next';
import ModelTable from './table/ModelTable';
import en from './i18n/en';
import hu from './i18n/hu';

i18next.addResourceBundle('en', 'model', en);
i18next.addResourceBundle('hu', 'model', hu);

export default [
	{
		path: '/model/:sessionNameParam?',
		component: ModelTable,
		auth: authRoles.model
	}
];
