import _ from '@lodash';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import DriverLogService from 'app/admin/driverLog/service/DriverLogService';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import ModelCalculate from 'app/admin/common/component/ModelCalculate';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormFileInputField from 'modules/ui/editform/fields/EditFormFileInputField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormComponentMassSomethingField from 'app/admin/common/component/EditFormComponentMassSomethingField';
import EditFormGridComponent from 'modules/ui/editform/subForm/EditFormGridComponent';
import { useParams, withRouter } from 'react-router';
import StorageDriverService from 'app/admin/driver/service/StorageDriverService';
import LummusModelEditService from '../service/LummusModelEditService';

const handleUnitOpsCode = (dto, field) => {
	const items = [];
	if (_.isArray(dto[field])) {
		dto[field].forEach(item => {
			if (_.isString(item.name) && item.name.length > 0 && _.isString(item.code) && item.code.length > 0)
				items.push({
					label: `${item.code} - ${item.name}`,
					value: item.code
				});
		});
	}
	return new Promise(resolve => resolve(items));
};

const config = data => {
	const storageDriverService = new StorageDriverService();
	return {
		key: 'lummusModelEditForm',
		topic: 'model',
		service: LummusModelEditService,
		tabList: initData => {
			return [{ key: 'basic' }, { key: 'input_unit_ops' }, { key: 'output_unit_ops' }, { key: 'input_attributes' }, { key: 'output_attributes' }, { key: 'components' }];
		},
		backLink: () => '/model',
		defaultValue: () => LummusModelEditService.create(data.driverName),
		fields: initData => {
			const fields = [
				{
					key: 'name',
					component: EditFormTextField,
					required: true,
					readOnly: data.id !== 'new'
				},
				{
					key: 'driver',
					component: EditFormSelectField,
					required: true,
					items: () => storageDriverService.getListSelectionByDriverType('lummus')
				},
				{
					key: 'state_file',
					component: EditFormFileInputField
				},
				{
					key: 'driverLog',
					component: EditFormSelectField,
					items: () => DriverLogService.getListSelection()
				},
				{
					key: 'simulation_id',
					component: EditFormTextField
				},
				{
					key: 'image',
					component: EditFormFileInputField,
					required: true
				},
				{
					key: 'input_unit_ops',
					tab: 'input_unit_ops',
					component: EditFormGridComponent,
					defaultValue: {
						id: null,
						code: null,
						name: null,
						type_code: null
					},
					fields: [
						{
							key: 'code',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'name',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'type_code',
							component: EditFormTextField,
							required: true
						}
					]
				},
				{
					key: 'output_unit_ops',
					tab: 'output_unit_ops',
					component: EditFormGridComponent,
					defaultValue: {
						id: null,
						code: null,
						name: null,
						type_code: null
					},
					fields: [
						{
							key: 'code',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'name',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'type_code',
							component: EditFormTextField,
							required: true
						}
					]
				},
				{
					key: 'input_attributes',
					tab: 'input_attributes',
					component: EditFormGridComponent,
					orderable: true,
					defaultValue: {
						id: null,
						code: null,
						title: null,
						name: null,
						unit_op_code: null,
						type: null,
						value: null,
						min_value: null,
						max_value: null,
						orderIndex: null
					},
					fields: [
						{
							key: 'title',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'code',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'name',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'unit_op_code',
							component: EditFormSelectField,
							type: 'enum',
							required: true,
							filterKey: 'input_unit_ops',
							itemsParameterIsDto: true,
							items: dto => handleUnitOpsCode(dto || initData, 'input_unit_ops')
						},
						{
							key: 'uom',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'type',
							component: EditFormSelectField,
							required: true,
							type: 'enum',
							items: [
								{ label: 'Variable', value: 'Variable' },
								{ label: 'ComponentMassFrac', value: 'ComponentMassFrac' }
							]
						},
						{
							key: 'value',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'Variable' || type === 'SpreadSheet' || type === null,
							type: 'number'
						},
						{
							key: 'value',
							component: EditFormComponentMassSomethingField,
							dependsOn: 'type',
							visibleIf: type => type === 'ComponentMassFrac',
							enumByField: 'components'
						},
						{
							key: 'min_value',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'Variable' || type === 'SpreadSheet' || type === null,
							type: 'number'
						},
						{
							key: 'min_value',
							component: EditFormComponentMassSomethingField,
							loadValueFrom: 'value',
							loadValueMultiplier: 0.9,
							dependsOn: 'type',
							visibleIf: type => type === 'ComponentMassFrac',
							enumByField: 'components'
						},
						{
							key: 'max_value',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'Variable' || type === 'SpreadSheet' || type === null,
							type: 'number'
						},
						{
							key: 'max_value',
							component: EditFormComponentMassSomethingField,
							loadValueFrom: 'value',
							loadValueMultiplier: 1.1,
							dependsOn: 'type',
							visibleIf: type => type === 'ComponentMassFrac',
							enumByField: 'components'
						}
					]
				},
				{
					key: 'output_attributes',
					tab: 'output_attributes',
					component: EditFormGridComponent,
					orderable: true,
					defaultValue: {
						id: null,
						code: null,
						name: null,
						unit_op_code: null,
						type: null,
						value: null,
						title: null,
						orderIndex: null
					},
					fields: [
						{
							key: 'title',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'code',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'name',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'unit_op_code',
							component: EditFormSelectField,
							type: 'enum',
							required: true,
							filterKey: 'output_unit_ops',
							itemsParameterIsDto: true,
							items: dto => handleUnitOpsCode(dto, 'output_unit_ops')
						},
						{
							key: 'uom',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'type',
							component: EditFormSelectField,
							required: true,
							type: 'enum',
							items: [
								{ label: 'Variable', value: 'Variable' },
								{ label: 'ComponentMassFrac', value: 'ComponentMassFrac' }
							]
						},
						{
							key: 'value',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'Variable' || type === 'SpreadSheet' || type === null,
							type: 'number'
						},
						{
							key: 'value',
							component: EditFormComponentMassSomethingField,
							dependsOn: 'type',
							visibleIf: type => type === 'ComponentMassFrac',
							enumByField: 'components'
						}
					]
				},
				{
					key: 'components',
					tab: 'components',
					component: EditFormGridComponent,
					defaultValue: {
						id: null,
						name: null,
						value: null
					},
					fields: [
						{
							key: 'name',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'value',
							component: EditFormTextField,
							type: 'number',
							required: true
						}
					]
				}
			];

			return fields;
		}
	};
};

function LummusModelEditForm(props) {
	const { id, driverName } = useParams();
	const dispatch = useDispatch();

	const myConfig = config({ id, driverName });
	return (
		<EditFormPage
			config={myConfig}
			beforeSaveContent={<ModelCalculate id={id} type="model" />}
			onSave={result => {
				if (!result.engineSuccessful) dispatch(showMessage({ message: `Engine: ${result.engineMessage}` }));
			}}
		/>
	);
}

export default withRouter(LummusModelEditForm);
