import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import SchedulerTableService from '../service/SchedulerTableService';
import UpdateEnableTableCell from '../component/UpdateEnableTableCell';
import LastResultTableCell from '../component/LastResultTableCell';

const config = {
	key: 'schedulerTable',
	topic: 'scheduler',
	service: SchedulerTableService,
	editFormState: item => `/scheduler/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['refresh', 'new'],
	newButtonText: 'Add Pi Measurement Reader',
	rowAction: ['delete'],
	handleDelete: (sessionName, item) => SchedulerTableService.deleteByName(sessionName, item.name),
	rowButtonList: [
		{
			key: 'updateSchedule',
			text: 'Update schedule',
			link: item => `/scheduler/update_schedule/${item.id}`,
			icon: 'schedule'
		},
		{
			key: 'updateTask',
			text: 'Update task',
			link: item => `/scheduler/update_task/${item.id}`,
			icon: 'settings'
		}
	],
	columns: [
		{
			key: 'enabled',
			tableKey: 'schedulerTable',
			component: UpdateEnableTableCell,
			sortable: false
		},
		{
			key: 'name',
			sortable: false
		},
		{
			key: 'type',
			sortable: false
		},
		{
			key: 'ts',
			type: 'dateTime',
			sortable: false
		},
		{
			key: 'ok_cnt',
			sortable: false
		},
		{
			key: 'fail_cnt',
			sortable: false
		},
		{
			key: 'last_status',
			sortable: false
		},

		{
			key: 'last_timestamp',
			type: 'dateTime',
			sortable: false
		},
		{
			key: 'last_message',
			sortable: false
		},
		{
			key: 'last_result',
			component: LastResultTableCell,
			sortable: false
		}
	]
};

function SchedulerTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('schedulerTable', reducer('schedulerTable'))(SchedulerTable);
