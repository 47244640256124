import _ from '@lodash';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { useState } from 'react';

function TaskOptimizeDoneNotification(props) {
	const { item } = props;
	const [showDialog, setShowDialog] = useState(false);
	const mainTheme = useSelector(selectMainTheme);

	if (item && item.severity && item.severity === 'success') {
		return (
			<>
				<div className="pt-4 pl-32">
					<Button color="seconary" className="text-white" onClick={() => setShowDialog(true)}>
						Show result
					</Button>
				</div>
				<ThemeProvider theme={mainTheme}>
					<Dialog open={showDialog} fullWidth>
						<DialogTitle disableTypography className="border-b">
							Optimize Result
						</DialogTitle>
						<DialogContent className="p-20">
							{item.data.calculate.best_state && (
								<div>
									<div className="pb-12 text-lg font-semibold">Best state:</div>
									<TableContainer component={Paper}>
										<Table className="w-full" aria-label="simple table">
											<TableHead>
												<TableRow>
													<TableCell>Name</TableCell>
													<TableCell align="right">Value</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{Object.keys(item.data.calculate.best_state).map(key => (
													<TableRow key={key}>
														<TableCell component="th" scope="row">
															{key}
														</TableCell>
														<TableCell className="font-mono" align="right">
															{item.data.calculate.best_state[key]}
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</div>
							)}
							{item.data.calculate.best_value && (
								<div className="pt-12 flex flex-row">
									<div className="pr-12 text-lg font-semibold">Best value:</div>
									<div className="text-lg font-mono">{item.data.calculate.best_value}</div>
								</div>
							)}
						</DialogContent>
						<DialogActions className="justify-between border-t p-20">
							<Button
								onClick={() => {
									setShowDialog(false);
								}}
							>
								Close
							</Button>
						</DialogActions>
					</Dialog>
				</ThemeProvider>
			</>
		);
	}
	return <></>;
}

export default TaskOptimizeDoneNotification;
