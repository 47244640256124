const fittingCaseEn = {
	fittingCaseTable: 'Fitting Case',
	fittingCaseEditForm: 'Fitting Case',
	name: 'Name',
	basic: 'Basic',
	domains: 'Domains',
	optimizer_log_name: 'Optimizer Log Name',
	optimizer_log_file: 'Optimizer Log File',
	objective_name: 'Objective Name',
	objective_file: 'Objective File',
	domain_from: 'Domain From',
	domain_to: 'Domain To',
	step_count: 'Step Count',
	run: 'Futtatás',
	runDesc: 'Do you want to run this case?',
	fitterLog: 'Fitter log'
};

export default fittingCaseEn;
