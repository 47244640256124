import _ from '@lodash';
import { useTranslation } from 'react-i18next';
import { Typography, Icon } from '@material-ui/core';

function EditFormSeparatorField(props) {
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	return (
		<div className={props.fieldConfig.className ? props.fieldConfig.className : 'pb-16'}>
			<div className="flex items-center">
				{props.fieldConfig.icon ? <Icon color="action">{props.fieldConfig.icon}</Icon> : null}
				<Typography className="h2 mx-10 font-medium" color="textSecondary">
					{!_.isUndefined(props.fieldConfig.text) ? props.fieldConfig.text : t(`${props.config.topic}:${props.fieldConfig.key}`)}
				</Typography>
			</div>
		</div>
	);
}

export default EditFormSeparatorField;
