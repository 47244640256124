import _ from '@lodash';
import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import LabelNumberValue from 'app/admin/common/component/LabelNumberValue';
import defaultLabelStyle from 'app/admin/common/component/DefaultLabelStyle';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { useWhatIf } from '../component/WhatIfProvider';
import { convertSumTableData } from './SumTableItem';
import { formatLabelValue } from '../component/WhatIfUtil';

function formData(tableData) {
	const result = {};
	tableData.forEach(d => {
		if (d.io === 'INPUT' && d.cellType === 'DATA') result[d.valueKey.replaceAll('.', '###')] = d.value;
		if (d.io === 'INPUT' && d.children) {
			d.children.forEach(dd => {
				result[dd.valueKey.replaceAll('.', '###')] = dd.value;
			});
		}
	});
	return result;
}

function SumTableDialog() {
	const { data, setData, summaryTableDialog, setSummaryTableDialog, getExperimentAttribute, compare } = useWhatIf();
	const { t } = useTranslation(['common']);
	const [tableData, setTableData] = useState([]);
	const [closedComponents, setClosedComponents] = useState([]);
	const hasInput = tableData.findIndex(d => d.io === 'INPUT') > -1;
	const hasOutput = tableData.findIndex(d => d.io === 'OUTPUT') > -1;

	const methods = useForm({
		mode: 'onChange'
	});

	useEffect(() => {
		setTableData(convertSumTableData(summaryTableDialog, data, compare));
	}, [summaryTableDialog, data]);

	useEffect(() => {
		if (tableData.length > 0) methods.reset(formData(tableData));
	}, [tableData]);

	const handleSave = dto => {
		console.log({
			...data,
			experiment: {
				...data.experiment,
				attributes: data.experiment.attributes.map(attrib => {
					const valueKey = `${attrib.entity}.${attrib.io}.${attrib.name}`;
					if (_.isObject(attrib.value)) {
						const keys = Object.keys(attrib.value);
						for (let i = 0; i < keys.length; i += 1) {
							const valueSubKey = `${valueKey}.${keys[i]}`;
							if (!_.isUndefined(dto[valueSubKey.replaceAll('.', '###')])) {
								attrib.value = { ...attrib.value, [keys[i]]: dto[valueSubKey.replaceAll('.', '###')] };
							}
						}
					} else if (!_.isUndefined(dto[valueKey.replaceAll('.', '###')])) {
						return { ...attrib, value: dto[valueKey.replaceAll('.', '###')] };
					}
					return attrib;
				})
			}
		});
		setData({
			...data,
			experiment: {
				...data.experiment,
				attributes: data.experiment.attributes.map(attrib => {
					const valueKey = `${attrib.entity}.${attrib.io}.${attrib.name}`;
					if (_.isObject(attrib.value)) {
						const keys = Object.keys(attrib.value);
						for (let i = 0; i < keys.length; i += 1) {
							const valueSubKey = `${valueKey}.${keys[i]}`;
							if (!_.isUndefined(dto[valueSubKey.replaceAll('.', '###')])) {
								attrib.value = { ...attrib.value, [keys[i]]: dto[valueSubKey.replaceAll('.', '###')] };
							}
						}
					} else if (!_.isUndefined(dto[valueKey.replaceAll('.', '###')])) {
						return { ...attrib, value: dto[valueKey.replaceAll('.', '###')] };
					}
					return attrib;
				})
			}
		});
		setSummaryTableDialog(null);
	};

	const toggleComponents = row => {
		const key = `${row.entity}.${row.io}.${row.name}`;
		if (closedComponents.indexOf(key) > -1) setClosedComponents(closedComponents.filter(d => d !== key));
		else setClosedComponents([...closedComponents, key]);
	};

	const isClosed = row => {
		const key = `${row.entity}.${row.io}.${row.name}`;
		return closedComponents.indexOf(key) > -1;
	};

	return (
		<Dialog open fullWidth maxWidth="lg">
			<DialogTitle disableTypography className="border-b">
				{summaryTableDialog.name}
			</DialogTitle>
			<DialogContent className="p-20">
				<FormProvider {...methods}>
					<TableContainer component={Paper}>
						<Table className="w-full" aria-label="simple table" size={hasInput ? 'small' : undefined}>
							<TableHead>
								<TableRow>
									<TableCell>Name</TableCell>
									<TableCell align="right" style={{ minWidth: '120px' }}>
										Value
									</TableCell>
									{hasInput && <TableCell>Min/Max</TableCell>}
									<TableCell>UOM</TableCell>
									{hasOutput && compare && <TableCell>Difference</TableCell>}
								</TableRow>
							</TableHead>
							<TableBody>
								{tableData !== null &&
									tableData.map((row, idx) => (
										<Fragment key={idx}>
											<TableRow key={row.name}>
												<TableCell
													component="th"
													scope="row"
													colSpan={row.cellType === 'HEADER' ? (hasInput && hasOutput && compare ? 5 : hasInput ? 4 : hasOutput && compare ? 4 : 3) : 1}
													className={row.cellType === 'HEADER' ? 'font-bold cursor-pointer' : ''}
													onClick={() => {
														if (row.cellType === 'HEADER') toggleComponents(row);
													}}
												>
													<div className="flex flex-row w-full">
														{row.cellType === 'HEADER' && <div className="pr-20">{isClosed(row) ? <ExpandMore fontSize="small" /> : <ExpandLess fontSize="small" />}</div>}
														<div className="flex flex-row justify-between w-full">
															<div>{row.name}</div>
															<div>
																{Object.values(row.value).reduce((acc, curr) => {
																	return acc + (summaryTableDialog.componentStyle.displayAsPercentage ? curr * 100 : curr);
																}, 0)}
																{summaryTableDialog.componentStyle.showUnit ? '%' : null}
															</div>
														</div>
													</div>
												</TableCell>
												{console.log(row)}

												{console.log(Object.values(row.value).reduce((acc, curr) => acc + curr, 0))}
												{row.cellType === 'DATA' && (
													<TableCell className="font-mono" align="right">
														{row.io === 'INPUT' ? (
															<Controller
																control={methods.control}
																name={row.valueKey.replaceAll('.', '###')}
																render={({ field, fieldState }) => (
																	<EditFormTextField
																		compact
																		field={field}
																		fieldState={fieldState}
																		config={{ topic: 'whatIf' }}
																		style={{ minWidth: '100px' }}
																		fieldConfig={{
																			key: row.valueKey.replaceAll('.', '###'),
																			text: null,
																			type: 'number'
																		}}
																	/>
																)}
															/>
														) : (
															<LabelNumberValue
																value={row.value}
																thousandsSeparator={summaryTableDialog.variableStyle.thousandsSeparator || false}
																decimalPlaces={Number.isInteger(summaryTableDialog.variableStyle.decimalPlaces) ? summaryTableDialog.variableStyle.decimalPlaces : 20}
																displayAsPercentage={summaryTableDialog.variableStyle.displayAsPercentage || false}
																showUnit={summaryTableDialog.variableStyle.showUnit || false}
															/>
														)}
													</TableCell>
												)}
												{row.cellType === 'DATA' && hasInput && (
													<TableCell>
														<MinMaxValue attribute={getExperimentAttribute(row.valueKey)} item={row} style={summaryTableDialog.variableStyle} />
													</TableCell>
												)}
												{row.cellType === 'DATA' && <TableCell>{summaryTableDialog.variableStyle.showUnit ? row.uom : null}</TableCell>}
												{compare && hasOutput && row.cellType === 'DATA' && (
													<TableCell>
														<div className="flex flex-row">
															{row.diffPercent != null && row.diffPercent !== 0 && (
																<div className={`${row.diffPercent < 0 ? 'text-green' : 'text-red'} whitespace-nowrap pr-12`}>
																	{`${row.diffPercent > 0 ? '+' : '-'} ${formatLabelValue(Math.abs(row.diffPercent), {
																		thousandsSeparator: true,
																		decimalPlaces: 2,
																		displayAsPercentage: true,
																		showUnit: true
																	})}`}
																</div>
															)}
															{row.diff != null && row.diff !== 0 && (
																<div className={`${row.diff < 0 ? 'text-green' : 'text-red'} whitespace-nowrap`}>{`(${formatLabelValue(Math.abs(row.diff), defaultLabelStyle(true))})`}</div>
															)}
														</div>
													</TableCell>
												)}
											</TableRow>
											{row.children &&
												!isClosed(row) &&
												row.children.map(sub => (
													<TableRow key={sub.name}>
														<TableCell component="th" scope="row" className="pl-32">
															{sub.name}
														</TableCell>
														<TableCell className="font-mono" align="right">
															{sub.io === 'INPUT' ? (
																<Controller
																	control={methods.control}
																	name={sub.valueKey.replaceAll('.', '###')}
																	render={({ field, fieldState }) => (
																		<EditFormTextField
																			compact
																			field={field}
																			fieldState={fieldState}
																			config={{ topic: 'whatIf' }}
																			fieldConfig={{
																				key: sub.valueKey.replaceAll('.', '###'),
																				text: null,
																				type: 'number'
																			}}
																		/>
																	)}
																/>
															) : (
																<LabelNumberValue
																	value={sub.value}
																	thousandsSeparator={summaryTableDialog.componentStyle.thousandsSeparator || false}
																	decimalPlaces={Number.isInteger(summaryTableDialog.componentStyle.decimalPlaces) ? summaryTableDialog.componentStyle.decimalPlaces : 20}
																	displayAsPercentage={summaryTableDialog.componentStyle.displayAsPercentage || false}
																	showUnit={summaryTableDialog.variableStyle.showUnit || false}
																/>
															)}
														</TableCell>
														{hasInput && (
															<TableCell>
																<MinMaxValue attribute={getExperimentAttribute(sub.valueKey)} item={sub} style={summaryTableDialog.componentStyle} />
															</TableCell>
														)}
														<TableCell>{summaryTableDialog.variableStyle.showUnit ? row.uom : null}</TableCell>
														{compare && hasOutput && (
															<TableCell>
																<div className="flex flex-row">
																	{sub.diffPercent != null && sub.diffPercent !== 0 && (
																		<div className={`${sub.diffPercent < 0 ? 'text-green' : 'text-red'} whitespace-nowrap pr-12`}>
																			{`${sub.diffPercent > 0 ? '+' : '-'} ${formatLabelValue(Math.abs(sub.diffPercent), {
																				thousandsSeparator: true,
																				decimalPlaces: 2,
																				displayAsPercentage: true,
																				showUnit: true
																			})}`}
																		</div>
																	)}
																	{sub.diff != null && sub.diff !== 0 && (
																		<div className={`${sub.diff < 0 ? 'text-green' : 'text-red'} whitespace-nowrap`}>{`(${formatLabelValue(Math.abs(sub.diff), defaultLabelStyle(true))})`}</div>
																	)}
																</div>
															</TableCell>
														)}
													</TableRow>
												))}
										</Fragment>
									))}
							</TableBody>
						</Table>
					</TableContainer>
				</FormProvider>
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				{hasInput ? (
					<>
						<Button onClick={() => setSummaryTableDialog(null)}>{t('CANCEL')}</Button>
						<Button onClick={methods.handleSubmit(handleSave)} color="primary" autoFocus>
							{t('SAVE')}
						</Button>
					</>
				) : (
					<Button
						onClick={() => {
							setSummaryTableDialog(null);
						}}
					>
						Close
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}

function MinMaxValue(props) {
	const { attribute, style, item } = props;
	if (item.io !== 'INPUT') return <></>;
	const valueKeyArray = item.valueKey.split('.');
	const lastValueKey = valueKeyArray[valueKeyArray.length - 1];

	const minValue = _.isObject(attribute.min_value) ? attribute.min_value[lastValueKey] : attribute.min_value;
	const maxValue = _.isObject(attribute.max_value) ? attribute.max_value[lastValueKey] : attribute.max_value;

	if (minValue == null && maxValue == null) return <></>;

	return (
		<div className="flex flex-col text-red-500 text-xs py-8">
			{minValue != null && <div className="whitespace-nowrap">Min: {formatLabelValue(minValue, style, attribute.uom)}</div>}
			{maxValue != null && <div className="whitespace-nowrap">Max: {formatLabelValue(maxValue, style, attribute.uom)}</div>}
		</div>
	);
}

export default SumTableDialog;
