import { useSelector, useDispatch } from 'react-redux';
import { generateId } from 'app/admin/whatIf/component/WhatIfUtil';
import { showMessage } from 'app/store/fuse/messageSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { Button, Checkbox, Paper, ThemeProvider, Dialog, DialogContent, DialogActions, DialogTitle, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { BsUpload } from 'react-icons/bs';
import { useForm, FormProvider, Controller, useWatch } from 'react-hook-form';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import AspectService from 'app/admin/aspect/service/AspectService';
import AspectEditService from 'app/admin/aspect/service/AspectEditService';
import ModelService from 'app/admin/model/service/ModelService';
import { useWorkflow } from './WorkflowProvider';
import TransformerValueType from '../dto/TransformerValueType';

export default function TransformerLoadAspect() {
	const dispatch = useDispatch();
	const { data, setData } = useWorkflow();
	const { t } = useTranslation('whatIf');
	const mainTheme = useSelector(selectMainTheme);
	const [showDialog, setShowDialog] = useState(false);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState(null);
	const [outputs, setOutputs] = useState([]);
	const [aspect, setAspect] = useState(null);
	const [model, setModel] = useState(null);
	const [selected, setSelected] = useState([]);
	const schema = yup.object().shape({
		aspect: yup.number().nullable().required(t('validation:FIELD_REQUIRED'))
	});
	const methods = useForm({
		defaultValues: { aspect: null },
		mode: 'onChange',
		resolver: yupResolver(schema)
	});
	const aspectValue = useWatch({ name: 'aspect', control: methods.control });

	const handleLoad = dto => {
		if (selected.length > 0) {
			setMessage(null);
			insertInputsByOutputs();
			setShowDialog(false);
		} else {
			dispatch(showMessage({ message: `Selected value required.` }));
		}
	};

	const insertInputsByOutputs = () => {
		const result = [];
		const modelOutputAttributes = model.export ? model.export.output_attributes : model.output_attributes;
		if (aspect.output_attributes !== null && aspect.output_attributes.length > 0 && modelOutputAttributes && modelOutputAttributes.length > 0) {
			aspect.output_attributes.forEach(output => {
				const valueIndex = data.value.findIndex(d => d.valueKey === output.name);
				if (valueIndex === -1 && selected.indexOf(outputs.indexOf(output.name)) > -1) {
					const modelData = modelOutputAttributes.find(d => d.name === (output.model_field_name != null ? output.model_field_name : output.name));
					result.push({
						key: generateId(),
						valueKey: output.name,
						class: 'VALUE',
						x: null,
						y: null,
						io: 'INPUT',
						valueType: TransformerValueType.indexOf(modelData.type) > -1 ? modelData.type : 'Variable',
						value: null
					});
				}
			});
		}
		const x = data.stage.x < 0 ? Math.ceil(Math.abs(data.stage.x / data.stage.scale) / 10) * 10 + 50 + 75 : 125;
		let y = data.stage.y < 0 ? Math.ceil(Math.abs(data.stage.y / data.stage.scale) / 10) * 10 + 50 + 50 : 100;
		if (y + 130 * result.length > 10000) {
			y = 10000 - 20 - 130 * result.length;
		}
		setData({
			...data,
			value: [
				...data.value,
				...result.map((input, index) => {
					return {
						...input,
						x,
						y: y + index * 130
					};
				})
			]
		});
	};

	useEffect(() => {
		if (aspectValue) {
			setMessage(null);
			setLoading(true);
			AspectEditService.getData(aspectValue)
				.then(aspectData => {
					if (aspectData !== null && aspectData.model !== null) {
						ModelService.getData(aspectData.model)
							.then(modelData => {
								try {
									const newOutputs = [];
									const modelOutputAttributes = modelData.export ? modelData.export.output_attributes : modelData.output_attributes;
									if (aspectData.output_attributes !== null && aspectData.output_attributes.length > 0 && modelOutputAttributes && modelOutputAttributes.length > 0) {
										aspectData.output_attributes.forEach(output => {
											const outputData = modelOutputAttributes.find(d => d.name === (output.model_field_name != null ? output.model_field_name : output.name));
											if (outputData) newOutputs.push(output.name);
										});
									}
									setAspect(aspectData);
									setModel(modelData);
									setOutputs(newOutputs);
									setSelected(newOutputs.map((d, idx) => idx));
									setLoading(false);
								} catch (e) {
									handleError();
									setMessage(`Failed to load aspect: ${e}`);
								}
							})
							.catch(e => {
								setMessage(`Failed to load aspect: ${e}`);
								handleError();
							});
					} else {
						setMessage(`Failed to load aspect: aspect not found`);
						handleError();
					}
				})
				.catch(e => {
					setMessage(`Failed to load aspect: ${e}`);
					handleError();
				});
		} else {
			handleError();
		}
	}, [aspectValue]);

	const handleCheckboxClick = idx => {
		if (selected.indexOf(idx) > -1) setSelected(selected.filter(d => d !== idx));
		else setSelected([...selected, idx]);
	};

	const handleAllCheckboxClick = () => {
		if (selected.length === outputs.length) {
			setSelected([]);
		} else {
			setSelected(outputs.map((d, idx) => idx));
		}
	};

	const handleError = () => {
		setAspect(null);
		setModel(null);
		setOutputs([]);
		setSelected([]);

		setLoading(false);
	};
	return (
		<>
			<Button
				className="whitespace-nowrap mx-4"
				variant="contained"
				color="secondary"
				onClick={() => {
					setLoading(false);
					setShowDialog(true);
				}}
				startIcon={<BsUpload className="hidden sm:flex" />}
			>
				Load Aspect
			</Button>
			<ThemeProvider theme={mainTheme}>
				<Dialog open={showDialog} fullWidth>
					<DialogTitle disableTypography className="border-b">
						Load key from Aspect
					</DialogTitle>
					<DialogContent className="p-20">
						<FormProvider {...methods}>
							{loading && <FuseLoading />}
							{!loading && (
								<>
									<Controller
										control={methods.control}
										name="aspect"
										render={({ field, fieldState }) => (
											<EditFormSelectField
												field={field}
												fieldState={fieldState}
												config={{ topic: 'whatIf' }}
												fieldConfig={{
													key: 'aspect',
													text: t('aspect'),
													items: () => AspectService.getListSelection()
												}}
											/>
										)}
									/>
									{outputs.length > 0 && (
										<TableContainer component={Paper}>
											<Table className="w-full" aria-label="simple table">
												<TableHead>
													<TableRow onClick={() => handleAllCheckboxClick()}>
														<TableCell align="left" padding="checkbox">
															<Checkbox checked={selected.length === outputs.length} indeterminate={selected.length > 0 && selected.length < outputs.length} />
														</TableCell>
														<TableCell>Name</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{outputs.map((output, idx) => (
														<TableRow key={idx} onClick={() => handleCheckboxClick(idx)}>
															<TableCell align="right" padding="checkbox">
																<Checkbox checked={selected.indexOf(idx) > -1} />
															</TableCell>
															<TableCell component="th" scope="row">
																{output}
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									)}
									{message != null && <div>{message}</div>}
								</>
							)}
						</FormProvider>
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button
							onClick={() => {
								setShowDialog(false);
							}}
						>
							Close
						</Button>
						<Button onClick={methods.handleSubmit(handleLoad)}>Load</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</>
	);
}
