import _ from 'lodash';
import TableCell from '@material-ui/core/TableCell';
import { useState, useEffect } from 'react';

function FileSizeTableCell(props) {
	const [label, setLabel] = useState(null);

	useEffect(() => {
		if (_.get(props.item, props.config.key)) {
			setLabel(humanFileSize(_.get(props.item, props.config.key)));
		} else {
			setLabel('-');
		}
	}, [props.item, props.config]);

	/**
	 * Format bytes as human-readable text.
	 *
	 * @param bytes Number of bytes.
	 * @param si True to use metric (SI) units, aka powers of 1000. False to use
	 *           binary (IEC), aka powers of 1024.
	 * @param dp Number of decimal places to display.
	 *
	 * @return Formatted string.
	 */
	const humanFileSize = (bytes, si = false, dp = 1) => {
		const thresh = si ? 1000 : 1024;

		if (Math.abs(bytes) < thresh) {
			return `${bytes} B`;
		}

		const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
		let u = -1;
		const r = 10 ** dp;

		do {
			bytes /= thresh;
			u += 1;
		} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

		return `${bytes.toFixed(dp)} ${units[u]}`;
	};

	return (
		<TableCell className="p-4 md:p-16" scope="row">
			{label}
		</TableCell>
	);
}

export default FileSizeTableCell;
