const taskManagerEn = {
	taskManagerTable: 'Task Manager',
	name: 'Name',
	status: 'Status',
	type: 'Type',
	ts: 'Timestamp',
	result: 'Result',
	error: 'Error',
	user_name: 'Username',
	message: 'Message',
	ts_result: 'Result timestamp',
	file_count: 'Files'
};

export default taskManagerEn;
