import _ from '@lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import { defaultDefinition as aspectDefinition } from './aspect/AspectTransformation';
import { defaultDefinition as expressionDefinition } from './expression/ExpressionTransformation';
import { defaultDefinition as filterZeroDefinition } from './filterzero/FilterZeroTransformation';
import { defaultDefinition as groupByPropertyDefinition } from './groupbyproperty/GroupByPropertyTransformation';
import { defaultDefinition as mergeComponentsDefinition } from './mergecomponents/MergeComponentsTransformation';
import { defaultDefinition as massFracToMassFlowDefinition } from './massmole/MassFracToMassFlowTransformation';
import { defaultDefinition as massFlowToMassFracDefinition } from './massmole/MassFlowToMassFracTransformation';
import { defaultDefinition as massFlowToMoleFlowDefinition } from './massmole/MassFlowToMoleFlowTransformation';
import { defaultDefinition as reformerDefinition } from './reformer/ReformerTransformation';
import { defaultDefinition as moleFlowToMassFlowDefinition } from './massmole/MoleFlowToMassFlowTransformation';
import { defaultDefinition as blenderDefinition } from './blender/BlenderTransformation';
import { defaultDefinition as fractionatingDefinition } from './fractionating/FractionatingTransformation';
import { defaultDefinition as joinStreamsDefinition } from './joinstreams/JoinStreamsTransformation';
import { defaultDefinition as dodoDefinition } from './dodo/DodoTransformation';
import { defaultDefinition as sumUpDefinition } from './sumup/SumUpTransformation';
import { defaultDefinition as dutyDefinition } from './duty/DutyTransformation';
import { defaultDefinition as totalDefinition } from './total/TotalTransformation';
import { defaultDefinition as scalarsToTableDefinition } from './scalarsToTable/ScalarsToTableTransformation';
import { defaultDefinition as tableToScalarsDefinition } from './tableToScalars/TableToScalarsTransformation';
import { defaultDefinition as switchDefinition } from './switch/SwitchTransformation';
import { useWorkflow } from '../WorkflowProvider';
import TransformType from '../../dto/TransformType';

function WorkflowItemProperties(props) {
	const { data, setData, showPropertiesDialog, setShowPropertiesDialog } = useWorkflow();
	const { t } = useTranslation(['transformer', 'validation']);
	const item = data.transform.find(d => d.key === showPropertiesDialog.key);

	const schema = yup.object().shape({
		type: yup.string().nullable().required(t('validation:FIELD_REQUIRED'))
	});

	const methods = useForm({
		defaultValues: item,
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const getDefinition = classType => {
		switch (classType) {
			case TransformType.ASPECT:
				return aspectDefinition;
			case TransformType.FILTER_ZERO:
				return filterZeroDefinition;
			case TransformType.GROUP_BY_PROPERTY:
				return groupByPropertyDefinition;
			case TransformType.MERGE_COMPONENTS:
				return mergeComponentsDefinition;
			case TransformType.MASSFRAC_TO_MASSFLOW:
				return massFracToMassFlowDefinition;
			case TransformType.MASSFLOW_TO_MASSFRAC:
				return massFlowToMassFracDefinition;
			case TransformType.MASSFLOW_TO_MOLEFLOW:
				return massFlowToMoleFlowDefinition;
			case TransformType.REFORMER:
				return reformerDefinition;
			case TransformType.MOLEFLOW_TO_MASSFLOW:
				return moleFlowToMassFlowDefinition;
			case TransformType.BLENDER:
				return blenderDefinition;
			case TransformType.FRACTIONATING:
				return fractionatingDefinition;
			case TransformType.JOIN_STREAMS:
				return joinStreamsDefinition;
			case TransformType.DODO:
				return dodoDefinition;
			case TransformType.SUM_UP:
				return sumUpDefinition;
			case TransformType.DUTY:
				return dutyDefinition;
			case TransformType.TOTAL:
				return totalDefinition;
			case TransformType.SCALARS_TO_TABLE:
				return scalarsToTableDefinition;
			case TransformType.TABLE_TO_SCALARS:
				return tableToScalarsDefinition;
			case TransformType.EXPRESSION:
				return expressionDefinition;
			case TransformType.SWITCH:
				return switchDefinition;

			default:
				return {};
		}
	};
	const handleSave = value => {
		setData({
			...data,
			transform: data.transform.map(c => (c.key === item.key ? { ...value, ...getDefinition(value.type) } : c))
		});
		setShowPropertiesDialog({ type: value.type, class: value.class, key: item.key });
	};

	const handleCancel = () => {
		setData({
			...data,
			transform: data.transform.filter(d => d.key !== item.key)
		});
		setShowPropertiesDialog(null);
	};

	return (
		<Dialog open fullWidth>
			<DialogTitle disableTypography className="border-b">
				{t('itemProperties')}
			</DialogTitle>
			<DialogContent className="p-20">
				<FormProvider {...methods}>
					<Controller
						control={methods.control}
						name="type"
						render={({ field, fieldState }) => (
							<EditFormSelectField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'transformer' }}
								fieldConfig={{
									key: 'type',
									text: t('type'),
									type: 'enum',
									required: true,
									items: Object.keys(TransformType).map(key => {
										return { label: t(key), value: TransformType[key] };
									})
								}}
							/>
						)}
					/>
				</FormProvider>
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button
					onClick={() => {
						handleCancel();
					}}
				>
					{t('cancel')}
				</Button>
				<Button onClick={methods.handleSubmit(handleSave)} color="primary" autoFocus>
					{t('save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
export default WorkflowItemProperties;
