import _ from '@lodash';
import { useEffect, useState } from 'react';
import { Checkbox, IconButton, Icon, Button } from '@material-ui/core';
import { FaArrowTurnUp, FaPen } from 'react-icons/fa6';
import { LuSettings2, LuText } from 'react-icons/lu';
import { GrAscend, GrDescend } from "react-icons/gr";

import { formatLabelValue } from 'app/admin/common/util/CommonUtil';

export default function DynamicAttributeTable(props) {
    const [sortKey, setSortKey] = useState(null);
    const [direction, setDirection] = useState('DESCENDING');
    const [list, setList] = useState([]);

    useEffect(() => {
        if (props.list) setList(orderList(props.list));
    }, [sortKey, direction, props.list]);

    const changeSort = (key) => {
        if (key === sortKey) toggleDirection();
        else setSortKey(key);
    };

    const toggleDirection = () => {
        setDirection(direction === 'ASCENDING' ? 'DESCENDING' : 'ASCENDING');
    };

    const orderList = (_list) => {
        if (sortKey) {
            console.log('Sort by: ' + sortKey);

            if (_.isNumber(_list[0][sortKey])) {
                console.log('Number sort.');
                _list.sort((a, b) => (direction === 'ASCENDING' ? (a[sortKey] - b[sortKey]) : (b[sortKey] - a[sortKey])));
            } else {
                console.log('Label sort.');
                _list.sort((a, b) => direction === 'ASCENDING' ? b[sortKey].localeCompare(a[sortKey]) : a[sortKey].localeCompare(b[sortKey]))
            }
        }
        return _list;
    };

    return (
        <div className='w-full flex flex-col'>

            <div className='w-full flex items-center justify-between bg-black text-white px-6 py-0 rounded-t-2xl'>

                <div className={'font-medium flex gap-8 ml-12 ' + (props.editable ? '' : 'py-20')}>
                    {props.editable ? <LuSettings2 className='mt-2' /> : <LuText className='mt-2' />}
                    {props.title}
                </div>

                <div className='flex items-center gap-2 p-16'>
                    {props.editable && <Button variant='contained' color='secondary' className='w-full' onClick={() => props.onClose()} size='small'>Save</Button>}
                </div>

            </div>

            <div className='w-full flex flex-col bg-white border border-grey-300 rounded-b-2xl overflow-hidden shadow'>

                <div className='w-full grid items-center font-medium py-2' style={{ gridTemplateColumns: 'repeat(' + (props.columns.length * 2 + (props.editable ? 1 : 0)) + ', minmax(0, 1fr))' }}>

                    {props.columns && props.columns.map((item) => <button key={item.key} disabled={!item.config.sortable} className={'col-span-2 p-14 text-left flex items-center gap-6 ' + (item.config.header.className || '')} onClick={() => changeSort(item.key)}>

                        {item.config.sortable && <div className={'' + (sortKey === item.key ? 'opacity-100' : 'opacity-10')}>
                            {direction === 'DESCENDING' && <GrDescend />}
                            {direction === 'ASCENDING' && <GrAscend />}
                        </div>}

                        {item.title}

                    </button>)}

                </div>

                {list && list.map((item, index) => <EditDataLine key={item.id} index={index} data={item} columns={props.columns} editable={props.editable} />)}

            </div>

        </div>
    );
}

function EditDataLine(props) {
    const [selectedKey, setSelectedKey] = useState(null);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(props.data.type === 'Variable' ? props.data.value : null);

    useEffect(() => {
        const _value = selectedKey ? (props.data[selectedKey] || 0) : props.data.value;
        setValue(_value);
    }, [selectedKey]);

    useEffect(() => {
        for (let i = 0; i < props.columns.length; i += 1)
            if (props.columns[i].config.selectDefault)
                setSelectedKey(props.columns[i].key);
    }, [props.data]);

    return (
        <div className='flex flex-col'>

            <div className={'w-full grid items-center border-t ' + (props.index % 2 === 0 ? 'bg-gray-200' : '')} style={{ gridTemplateColumns: 'repeat(' + (props.columns.length * 2 + (props.editable ? 1 : 0)) + ', minmax(0, 1fr))' }}>

                {props.columns && props.columns.map((item) => <div className='col-span-2 p-14'>

                    {!item.config.field.custom && <button disabled={!item.config.selectable} className={'w-full flex gap-8 items-center ' + (item.config.field.className || '')} onClick={() => setSelectedKey(item.key)}>

                        {!item.config.field.format && (props.data[item.key] || '-')}
                        {item.config.field.format && (formatLabelValue(props.data[item.key], item.config.field.format) || '-')}

                        {item.config.selectable && <FaPen className={'' + (selectedKey === item.key ? 'opacity-100' : 'opacity-20')} />}

                    </button>}

                    {(item.config.field.custom && item.config.field.custom.type === 'INPUT') && <EditInput value={value} onChange={(v) => setValue(v)} />}

                </div>)}

                {/* <div className={'col-span-2 p-16 ' + (open ? 'font-bold' : '')}>{props.data.name}</div>

                <div className='col-span-2 p-16 text-right text-xs'>{props.data.type === 'Variable' ? props.data.value : 'Details'}</div>

                <div className='col-span-2 px-16 py-8'>

                    {props.data.type === 'Variable' && <EditInput value={value} onChange={(v) => setValue(v)} />}

                    {props.data.type === 'ComponentMassFrac' && <Button variant='contained' color='primary' className='w-full' onClick={() => setOpen(!open)} size='small'>
                        {open ? 'Close List' : 'Open List'}
                    </Button>}

                </div> */}

                {props.editable && <div className='col-span-1 flex items-center justify-center px-8'>

                    {/* <IconButton className='w-20 h-20' aria-label='Reset' size='small' onClick={() => { setValue(props.data.value); setReset(reset + 1) }}>
                        <Icon fontSize='small'>replay</Icon>
                    </IconButton> */}

                    <Checkbox />

                </div>}

            </div>

            {(props.data.type === 'ComponentMassFrac' && props.data.value && open) && <div>

                {Object.entries(props.data.value).map((item, index) => <EditSubDataLine key={item} data={item} index={index} />)}

            </div>}

        </div>
    );
}

function EditSubDataLine(props) {
    const [value, setValue] = useState(props.data[1] || '');

    useEffect(() => {
        if (props.reset)
            setValue(props.data[1]);
    }, [props.reset]);

    return (
        <div className='w-full grid grid-cols-7 items-center border-t'>

            <div className='col-span-2 p-16 flex gap-8 items-center'><FaArrowTurnUp className='opacity-20 transform rotate-90 text-xs' /> {props.data[0]}</div>
            <div className='col-span-2 p-16 text-right text-xs'>{props.data[1]}</div>
            <div className='col-span-2 px-16 py-8'>

                <EditInput value={value} onChange={(v) => setValue(v)} />

            </div>

            <div className='col-span-1 flex items-center justify-between px-8'>

                <IconButton className='w-24 h-24' aria-label='Reset' size='small' onClick={() => { setValue(props.data[1]) }}>
                    <Icon fontSize='small'>replay</Icon>
                </IconButton>

            </div>

        </div>
    );
}

function EditInput(props) {
    return (
        <input
            className={'w-full outline-none text-xs border rounded px-8 py-8 focus:border-grey-700 bg-white focus:bg-white text-right shadow-inner noappearance ' + (props.className || '')}
            value={props.value}
            onChange={ev => props.onChange(ev.target.value)}
            type='number'
            step='any' />
    );
}