import useFullScreen from '@toluade/use-fullscreen';
import NotificationPanelToggleButton from 'modules/ui/notificationPanel/NotificationPanelToggleButton';
import { AppBar, Hidden, Toolbar, Typography } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import NavbarToggleButton from 'app/fuse-layouts/shared-components/NavbarToggleButton';
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu';
import clsx from 'clsx';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import FullScreenToggle from '../../shared-components/FullScreenToggle';
import LanguageSwitcher from '../../shared-components/LanguageSwitcher';

const useStyles = makeStyles(theme => ({
	root: {}
}));

function ToolbarLayout1(props) {
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const navbar = useSelector(({ fuse }) => fuse.navbar);
	const toolbarTheme = useSelector(selectToolbarTheme);

	const classes = useStyles(props);
	const { isFullScreen } = useFullScreen('root');
	if (!isFullScreen)
		return (
			<ThemeProvider theme={toolbarTheme}>
				<AppBar id="fuse-toolbar" className={clsx(classes.root, 'flex relative z-20 shadow-md', props.className)} color="default" style={{ backgroundColor: toolbarTheme.palette.background.paper }} position="static">
					<Toolbar className="p-0 min-h-48 md:min-h-64">
						<div className="flex flex-1 px-16 items-center">
							{config.navbar.display && config.navbar.position === 'left' && (
								<>
									<Hidden mdDown>
										{(config.navbar.style === 'style-3' || config.navbar.style === 'style-3-dense') && <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />}

										{config.navbar.style === 'style-1' && !navbar.open && <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />}
									</Hidden>

									<Hidden lgUp>
										<NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
									</Hidden>
								</>
							)}
							<Typography className="text-16 sm:text-20 truncate font-semibold">{process.env.REACT_APP_TITLE}</Typography>
						</div>

						<div className="flex items-center px-8 h-full overflow-x-auto">
							<LanguageSwitcher />

							<FullScreenToggle />

							{process.env.REACT_APP_NOTIFICATION === 'true' ? <NotificationPanelToggleButton /> : null}

							<UserMenu />
						</div>

						{config.navbar.display && config.navbar.position === 'right' && (
							<>
								<Hidden mdDown>{!navbar.open && <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />}</Hidden>

								<Hidden lgUp>
									<NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
								</Hidden>
							</>
						)}
					</Toolbar>
				</AppBar>
			</ThemeProvider>
		);
	return <></>;
}

export default memo(ToolbarLayout1);
