import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import WhatIfTableService from '../service/WhatIfTableService';

const config = {
	key: 'whatIfTable',
	topic: 'whatIf',
	service: WhatIfTableService,
	editFormState: item => `/what-if/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['filter', 'search', 'refresh'],
	rowAction: ['modify'],
	filterFields: [
		{
			propertyName: 'group',
			propertyType: 'Long',
			type: 'IN'
		}
	],
	columns: [
		{
			key: 'name'
		},
		{
			key: 'group.name',
			sortable: false
		},
		{
			key: 'aspect.name',
			sortable: false
		},
		{
			key: 'transformer.name',
			sortable: false
		},
		{
			key: 'created',
			type: 'dateTime'
		},

		{
			key: 'updated',
			type: 'dateTime'
		}
	]
};

function WhatIfTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('whatIfTable', reducer('whatIfTable'))(WhatIfTable);
