import clsx from 'clsx';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import TableCell from '@material-ui/core/TableCell';

function DriverOpenTableCell(props) {
	const { item } = props;
	return (
		<TableCell className="p-4 md:p-16" scope="row">
			<div className={clsx('sm:ml-6 sm:mr-6 flex justify-start items-center')}>
				{item.open === true && (
					<div className="flex justify-center items-center text-green-700">
						<DoneOutlinedIcon /> Open
					</div>
				)}
				{item.open === false && (
					<div className="flex justify-center items-center text-red-700">
						<CloseOutlinedIcon /> Close
					</div>
				)}
			</div>
		</TableCell>
	);
}

export default DriverOpenTableCell;
