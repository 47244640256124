import NamedDto from 'modules/base/dto/NamedDto';

export class DatasetFieldEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.type = null;
	}

	/**
	 * @typedef {Object} _DatasetFieldEditDtoProps
	 * @property {string} type
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _DatasetFieldEditDtoProps} DatasetFieldEditDtoProps
	 */
	/**
	 *
	 * @param {DatasetFieldEditDtoProps} props
	 * @returns {DatasetFieldEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.type = props.type;
		return this;
	}
}

export default DatasetFieldEditDto;
