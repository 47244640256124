const DriverType = {
	hysys: 'hysys',
	ann: 'ann',
	lummus: 'lummus',
	lummus2: 'lummus2',
	expression: 'expression',
	workflow: 'workflow',
	opc: 'opc'
};

export default DriverType;
