import _ from '@lodash';
import { MdOutlineInput } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ThemeProvider } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Tabs, Tab } from '@material-ui/core';
import EditFormGridComponent from 'modules/ui/editform/subForm/EditFormGridComponent';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormComponentMassSomethingField from '../../common/component/EditFormComponentMassSomethingField';
import PiMeasurementReaderComponent from '../../common/component/PiMeasurementReaderComponent';
import { useWhatIf } from './WhatIfProvider';

function WhatIfStateButton(props) {
	const mainTheme = useSelector(selectMainTheme);
	const { data, setData } = useWhatIf();
	const [tabValue, setTabValue] = useState(0);
	const [showDialog, setShowDialog] = useState(false);
	const { t } = useTranslation(['whatIf', 'common']);
	const piMeasurementReaderName = _.get(data, 'properties.piMeasurementReader', null);

	const methods = useForm({
		defaultValues: { input_attributes: [], output_attributes: [] },
		mode: 'onChange'
	});

	const handleClick = () => {
		setShowDialog(true);
	};

	useEffect(() => {
		if (showDialog) {
			const inputAttributeList = [];
			const outputAttributeList = [];
			data.experiment.attributes.forEach(attribute => {
				if (attribute.io === 'INPUT') {
					inputAttributeList.push({
						id: attribute.id,
						name: attribute.name,
						model_field_name: attribute.model_field_name,
						type: attribute.type,
						value: attribute.value
					});
				} else if (attribute.io === 'OUTPUT') {
					outputAttributeList.push({
						id: attribute.id,
						name: attribute.name,
						model_field_name: attribute.model_field_name,
						type: attribute.type,
						value: attribute.value
					});
				}
			});
			methods.reset({ input_attributes: inputAttributeList, output_attributes: outputAttributeList });
		}
	}, [showDialog]);

	const handleLoad = values => {
		const dto = methods.getValues();
		methods.reset({
			input_attributes: dto.input_attributes.map(d => {
				const key = d.model_field_name != null ? d.model_field_name : d.name;
				if (!_.isUndefined(values[key])) {
					return { ...d, value: values[key] };
				}
				return d;
			}),
			output_attributes: dto.output_attributes.map(d => {
				const key = d.model_field_name != null ? d.model_field_name : d.name;
				if (!_.isUndefined(values[key])) {
					return { ...d, value: values[key] };
				}
				return d;
			})
		});
		setTabValue(0);
	};

	const updateAttributes = () => {
		const dto = methods.getValues();
		setData({
			...data,
			experiment: {
				...data.experiment,
				attributes: data.experiment.attributes.map(attribute => {
					if (attribute.io === 'INPUT') {
						const index = dto.input_attributes.findIndex(d => d.id === attribute.id && d.name === attribute.name);
						if (index > -1) {
							return {
								...attribute,
								value: dto.input_attributes[index].value
							};
						}
					} else if (attribute.io === 'OUTPUT') {
						const index = dto.output_attributes.findIndex(d => d.id === attribute.id && d.name === attribute.name);
						if (index > -1) {
							return {
								...attribute,
								value: dto.output_attributes[index].value
							};
						}
					}
					return attribute;
				})
			}
		});
	};

	return (
		<>
			<Button
				className="whitespace-nowrap mx-4"
				variant="contained"
				color="secondary"
				disabled={!(data && data.experiment && data.experiment.attributes)}
				onClick={() => handleClick()}
				startIcon={<MdOutlineInput className="hidden sm:flex" />}
			>
				{t('state')}
			</Button>
			<ThemeProvider theme={mainTheme}>
				<Dialog open={showDialog} fullWidth maxWidth="lg">
					<DialogTitle disableTypography className="border-b">
						<div className="flex flex-row w-full">
							<div className="flex mr-24 items-center">{t('state')}</div>

							<Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} aria-label="sum-table-properties-tabs">
								<Tab label={t('input')} />
								<Tab label={t('output')} />
								{piMeasurementReaderName != null && <Tab label={t('reader')} />}
							</Tabs>
						</div>
					</DialogTitle>
					<DialogContent className="p-20">
						<div className={tabValue !== 0 ? 'hidden' : ''}>
							<FormProvider {...methods}>
								<EditFormGridComponent
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'input_attributes',
										component: EditFormGridComponent,
										modifyOnly: true,
										defaultValue: {
											id: null,
											name: null,
											type: null,
											value: null
										},
										fields: [
											{
												key: 'name',
												component: EditFormTextField,
												required: true
											},
											{
												key: 'value',
												component: EditFormTextField,
												required: true,
												dependsOn: 'type',
												visibleIf: type => type === 'Variable' || type === 'SpreadSheet' || type === null,
												type: 'number'
											},
											{
												key: 'value',
												component: EditFormComponentMassSomethingField,
												dependsOn: 'type',
												visibleIf: type => type === 'ComponentMassFrac'
											}
										]
									}}
								/>
							</FormProvider>
						</div>
						<div className={tabValue !== 1 ? 'hidden' : ''}>
							<FormProvider {...methods}>
								<EditFormGridComponent
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'output_attributes',
										component: EditFormGridComponent,
										modifyOnly: true,
										defaultValue: {
											id: null,
											name: null,
											type: null,
											value: null
										},
										fields: [
											{
												key: 'name',
												component: EditFormTextField,
												required: true
											},
											{
												key: 'value',
												component: EditFormTextField,
												required: true,
												dependsOn: 'type',
												visibleIf: type => type === 'Variable' || type === 'SpreadSheet' || type === null,
												type: 'number'
											},
											{
												key: 'value',
												component: EditFormComponentMassSomethingField,
												dependsOn: 'type',
												visibleIf: type => type === 'ComponentMassFrac'
											}
										]
									}}
								/>
							</FormProvider>
						</div>
						{piMeasurementReaderName != null && (
							<div className={tabValue !== 2 ? 'hidden' : ''}>
								<PiMeasurementReaderComponent onLoad={values => handleLoad(values)} piMeasurementReaderName={piMeasurementReaderName} />
							</div>
						)}
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button
							onClick={() => {
								updateAttributes();
								setShowDialog(false);
							}}
						>
							{t('common:close')}
						</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</>
	);
}

export default WhatIfStateButton;
