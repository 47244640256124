import _ from '@lodash';
import { Button } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';

function TaskWhatIfCalculateDoneNotification(props) {
	const { item } = props;
	if (item && item.severity && item.severity === 'success') {
		return (
			<>
				<div className="pt-4 pl-32">
					<Button
						color="seconary"
						className="text-white"
						component={Link}
						/*to={{
							pathname: `/what-if/edit/${item.data.id}`,
							state: item.data.calculate
						}}*/
						to={{ pathname: `/what-if/edit/${item.data.id}/${item.id}` }}
					>
						{item.data.whatIfName}
					</Button>
				</div>
			</>
		);
	}
	return <></>;
}

export default withRouter(TaskWhatIfCalculateDoneNotification);
