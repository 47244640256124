import _ from '@lodash';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import TableFilterRepo from './TableFilterRepo';

function TableBrowserFiltersDialog(props) {
	const { t } = useTranslation(['tableBrowser', 'common']);
	const dispatch = useDispatch();
	const filterList = useSelector(rootState => rootState[props.config.key].tableBrowserFilter.filterList);
	const [changedFilterList, setChangedFilterList] = useState([]);

	useEffect(() => {
		setChangedFilterList(filterList);
	}, [filterList]);

	const handleFilterChange = filterValue => {
		if (changedFilterList.findIndex(el => el.propertyName === filterValue.propertyName) > -1) {
			setChangedFilterList(changedFilterList.map(el => (el.propertyName === filterValue.propertyName ? filterValue : el)));
		} else {
			setChangedFilterList([filterValue, ...changedFilterList]);
		}
	};

	const handleFilterRemove = filterKey => {
		if (changedFilterList.findIndex(el => el.propertyName === filterKey) > -1) {
			setChangedFilterList(changedFilterList.filter(el => el.propertyName !== filterKey));
		}
	};

	const handleFiltering = () => {
		dispatch({ type: `${props.config.key}/filter/setFilterList`, payload: changedFilterList });
		props.onClose();
	};

	const getSpecificFilterField = config => {
		if (_.isFunction(config.visibleIf) && !config.visibleIf()) return null;
		let tableFilterName = config.propertyType + config.type;
		if (typeof config.customField !== 'undefined') tableFilterName = config.customField;
		if (typeof TableFilterRepo[tableFilterName] !== 'undefined') {
			const SpecificFilterField = TableFilterRepo[tableFilterName];
			return <SpecificFilterField key={config.propertyName} config={props.config} filterConfig={config} onFilterChanged={v => handleFilterChange(v)} onFilterRemoved={filterKey => handleFilterRemove(filterKey)} />;
		}
		return null;
	};

	return (
		<Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
			<DialogTitle id="form-dialog-title">{t('FILTER_CONDITIONS')}</DialogTitle>
			<DialogContent>
				{props.filterFields.map(f => {
					return getSpecificFilterField(f);
				})}
			</DialogContent>
			<DialogActions>
				<Button onClick={() => props.onClose()}>{t('common:CANCEL')}</Button>
				<Button onClick={() => handleFiltering()} color="primary">
					{t('FILTER')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default TableBrowserFiltersDialog;
