import _ from '@lodash';
import { useState } from 'react';
import ConfirmDialog from 'modules/ui/component/ConfirmDialog';
import { IconButton, Icon, Tooltip, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';

export default function GridComponent(props) {
	const { t } = useTranslation('transformer');
	const { grid, addRow, removeRow, removeColumn, type } = props;
	const [deleteRowConfirm, setDeleteRowConfirm] = useState(null);
	const [deleteColumnConfirm, setDeleteColumnConfirm] = useState(null);
	const { showTotal } = props;
	return (
		<>
			<table className="w-full">
				<GridHeader {...props} type={type} onDelete={i => setDeleteColumnConfirm(i)} />
				{grid.rows ? grid.rows.map((r, i) => <GridRow {...props} key={r.key} row={r} type={type} index={i} onDelete={() => setDeleteRowConfirm(i)} />) : null}
				{showTotal && <TotalRowsComponent grid={grid} />}
			</table>
			{!showTotal ? (
				<div className="flex justify-end">
					<Tooltip title={t('addNewRow')}>
						<IconButton onClick={() => addRow()} disabled={grid.columns.length === 0}>
							<Icon className="text-32">add</Icon>
						</IconButton>
					</Tooltip>
				</div>
			) : null}
			<ConfirmDialog
				show={deleteRowConfirm !== null}
				title={t('delete')}
				description={t('deleteRowConfirm')}
				onYes={() => {
					removeRow(deleteRowConfirm);
					setDeleteRowConfirm(null);
				}}
				onClose={() => setDeleteRowConfirm(null)}
			/>
			<ConfirmDialog
				show={deleteColumnConfirm !== null}
				title={t('delete')}
				description={t('deleteColumnConfirm')}
				onYes={() => {
					removeColumn(deleteColumnConfirm);
					setDeleteColumnConfirm(null);
				}}
				onClose={() => setDeleteColumnConfirm(null)}
			/>
		</>
	);
}

export function GridHeader(props) {
	const { t } = useTranslation('transformer');
	const { grid, addColumn, changeColumnName } = props;

	return (
		<tr className="border-b-2 sticky top-0 z-10" style={{ backgroundColor: '#e2e2e2' }}>
			<th className="pt-8 text-left">Name</th>
			{grid.columns
				? grid.columns.map((c, i) => (
						<th className="pt-8" key={c.key}>
							<div className="flex flex-row">
								<TextField value={grid.columns[i].name || ''} onChange={e => (e.target.value.length > 0 ? changeColumnName(c.key, e.target.value) : changeColumnName(c.key, null))} variant="outlined" fullWidth />
								<Tooltip title={t('deleteColumn')}>
									<IconButton onClick={() => props.onDelete(i)}>
										<Icon className="text-32">delete</Icon>
									</IconButton>
								</Tooltip>
							</div>
						</th>
				  ))
				: null}
			<th className="pt-8" style={{ width: '56px' }}>
				<Tooltip title={t('addNewColumn')}>
					<IconButton onClick={() => addColumn()}>
						<Icon className="text-32">add</Icon>
					</IconButton>
				</Tooltip>
			</th>
		</tr>
	);
}

export function GridRow(props) {
	const { row, type, index, showTotal } = props;
	const { t } = useTranslation('transformer');
	const { grid, changeValue, changeRowName } = props;

	return (
		<tr className={`${(index + 1) % 2 === 0 ? 'bg-gray-100' : ''}`}>
			<td>
				{!showTotal ? (
					<TextField value={row.name || ''} onChange={e => (e.target.value.length > 0 ? changeRowName(row.key, e.target.value) : changeRowName(row.key, null))} variant="outlined" fullWidth />
				) : (
					<TextField value={row.name} variant="outlined" fullWidth disabled />
				)}
			</td>
			{grid.columns
				? grid.columns.map((c, i) => {
						const item = grid[type].find(d => d.row === row.key && d.column === c.key);
						return (
							<td key={item.key} className="flex-1">
								<TextField
									type="number"
									value={!_.isUndefined(item.value) && item.value !== null ? item.value : ''}
									onChange={e => (e.target.value.length > 0 ? changeValue(type, item.key, Number.parseFloat(e.target.value)) : changeValue(type, item.key, null))}
									variant="outlined"
									fullWidth
								/>
							</td>
						);
				  })
				: null}
			<td>
				{!showTotal ? (
					<Tooltip title={t('deleteRow')}>
						<IconButton onClick={() => props.onDelete()}>
							<Icon className="text-32">delete</Icon>
						</IconButton>
					</Tooltip>
				) : (
					<Tooltip title={t('deleteRow')}>
						<IconButton disabled>
							<Icon className="text-32">delete</Icon>
						</IconButton>
					</Tooltip>
				)}
			</td>
		</tr>
	);
}

export function TotalRowsComponent(props) {
	const { grid } = props;
	if (_.isArray(grid.columns)) {
		const { columns } = grid;
		const getTotalForColumn = column => {
			const data = grid.conversion.filter(d => d.column === column.key);
			return data.reduce((total, row) => total + (Number(row.value) || 0), 0);
		};
		return (
			<tfoot>
				<tr>
					<th>
						<TextField value="Total" variant="outlined" fullWidth disabled />
					</th>
					{columns.map(column => (
						<td className="pt-8 text-right" key={column.key} style={{ color: getTotalForColumn(column) > 100 ? 'red' : 'inherit' }}>
							{getTotalForColumn(column)}
						</td>
					))}
				</tr>
			</tfoot>
		);
	}
	return <></>;
}
