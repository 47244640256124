import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './i18n/en';
import hu from './i18n/hu';
import FittingCaseTable from './table/FittingCaseTable';
import FittingCaseEditForm from './editform/FittingCaseEditForm';

i18next.addResourceBundle('en', 'fittingCase', en);
i18next.addResourceBundle('hu', 'fittingCase', hu);

export default [
	{
		path: '/fitting-case/edit/:id/:tabKey?',
		component: FittingCaseEditForm,
		auth: authRoles.fittingCase
	},
	{
		path: '/fitting-case/:sessionNameParam?',
		component: FittingCaseTable,
		auth: authRoles.fittingCase
	}
];
