const datasetHu = {
	datasetTable: 'Dataset',
	name: 'Név',
	datasetEditForm: 'Dataset',
	basic: 'Alapadatok',
	table_name: 'Tábla neve',
	description: 'Leírás',
	fields: 'Mezők',
	role: 'Szabály',
	type: 'Típus',
	key_fields: 'Key fields',
	value_fields: 'Value fields'
};

export default datasetHu;
