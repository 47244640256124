import KeyedAndNamedDto from 'modules/base/dto/KeyedAndNamedDto';

export class RoleServicePermissionDto extends KeyedAndNamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.title = null;
		/** @type {string} */
		this.service = null;
		/** @type {string} */
		this.grantedLevel = null;
	}

	/**
	 * @typedef {Object} _RoleServicePermissionDtoProps
	 * @property {string} title
	 * @property {string} service
	 * @property {string} grantedLevel
	 * @typedef {import("../KeyedAndNamedDto").KeyedAndNamedDtoProps & _RoleServicePermissionDtoProps} RoleServicePermissionDtoProps
	 */
	/**
	 *
	 * @param {RoleServicePermissionDtoProps} props
	 * @returns {RoleServicePermissionDto}
	 */
	setup(props) {
		super.setup(props);
		this.title = props.title;
		this.service = props.service;
		this.grantedLevel = props.grantedLevel;
		return this;
	}
}

export default RoleServicePermissionDto;
