import nBossConnection from 'modules/base/service/nBossConnection';
import NamedDto from 'modules/base/dto/NamedDto';
import EnumDto from 'modules/base/dto/enum/EnumDto';

class MeasurementService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'measurementControl';
	}

	/**
	 * @return {Promise<NamedDto[]>}
	 */
	getListSelection() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getListSelection`)
				.then(response => resolve(response.data != null ? response.data.map(d => new NamedDto().setup(d)) : []))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<EnumDto[]>}
	 */
	getListSelectionEnum() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getListSelection`)
				.then(response => resolve(response.data != null ? response.data.map(d => new EnumDto().setup({ label: d.name, value: d.name })) : []))
				.catch(reject);
		});
	}
}

export default new MeasurementService();
