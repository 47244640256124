import _ from '@lodash';
import WorkflowItem from '../WorkflowItem';

export const defaultDefinition = {
	input_fields: [
		{ name: 'Components', type: 'Components', enabled: true, value: null },
		{ name: 'MassFlow', type: 'Variable', enabled: true, value: null }
	],
	output_fields: [{ name: 'Components', type: 'Components' }],
	mass_frac_column: null,
	mass_flow_column: null,
	component_title: null
};

export const converter = function (data) {
	return data;
};

export default function MassFracToMassFlowTransformation(props) {
	return <WorkflowItem {...props} type="MASSFRAC_TO_MASSFLOW" />;
}
