import { authRoles } from 'app/auth';
import i18next from 'i18next';
import PictogramTable from './table/PictogramTable';
import PictogramEditForm from './editform/PictogramEditForm';
import en from './i18n/en';
import hu from './i18n/hu';

i18next.addResourceBundle('en', 'pictogram', en);
i18next.addResourceBundle('hu', 'pictogram', hu);

export default [
	{
		path: '/pictogram/edit/:id/:tabKey?',
		component: PictogramEditForm,
		auth: authRoles.pictogram
	},
	{
		path: '/pictogram/:sessionNameParam?',
		component: PictogramTable,
		auth: authRoles.pictogram
	}
];
