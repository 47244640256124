import _ from '@lodash';
import NumberValue from 'app/admin/common/component/NumberValue';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useWorkflow } from '../WorkflowProvider';

export default function WorkflowOutputValueDialog(props) {
	const { selectedCalculate, setSelectedCalculate } = useWorkflow();
	const { t } = useTranslation(['transformer', 'validation']);

	const renderObject = () => {
		const rows = Object.keys(selectedCalculate.value);
		if (_.isObject(selectedCalculate.value[rows[0]])) {
			const columns = Object.keys(selectedCalculate.value[rows[0]]);
			return (
				<TableContainer component={Paper}>
					<Table className="w-full" aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								{columns.map(column => (
									<TableCell key={column} align="right">
										{column}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map(row => (
								<TableRow key={row}>
									<TableCell component="th" scope="row" className="font-bold">
										{row}
									</TableCell>
									{columns.map(column => (
										<TableCell className="font-mono" align="right">
											<NumberValue value={selectedCalculate.value[row][column]} />
										</TableCell>
									))}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			);
		}

		return (
			<TableContainer component={Paper}>
				<Table className="w-full" aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell align="right">Value</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map(row => (
							<TableRow key={row}>
								<TableCell component="th" scope="row" className="font-bold">
									{row}
								</TableCell>
								<TableCell className="font-mono" align="right">
									<NumberValue value={selectedCalculate.value[row]} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	};

	return (
		<Dialog open>
			<DialogTitle disableTypography className="border-b">
				{selectedCalculate.name}
			</DialogTitle>
			<DialogContent className="p-20">
				{_.isObject(selectedCalculate.value) ? (
					renderObject()
				) : (
					<div className="font-mono">
						<NumberValue value={selectedCalculate.value} />
					</div>
				)}
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button onClick={() => setSelectedCalculate(null)}>{t('close')}</Button>
			</DialogActions>
		</Dialog>
	);
}
