import EntityValidationResult from 'modules/base/dto/validation/EntityValidationResult';
import GenericTableDto from 'modules/base/dto/table/GenericTableDto';
import GenericTableService from 'modules/base/service/GenericTableService';
import TaskManagerTableDto from '../dto/TaskManagerTableDto';

class TaskManagerTableService extends GenericTableService {
	constructor() {
		super();
		this.CONTROL = 'taskManagerTableControl';
	}

	/**
	 * @param {string} session
	 * @param {number} firstResult
	 * @param {number} maxResults
	 * @return {Promise<GenericTableDto<TaskManagerTableDto>>}
	 */
	getList(session, firstResult, maxResults) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getList`, {
					session,
					firstResult,
					maxResults
				})
				.then(response => resolve(new GenericTableDto().setup(response.data, TaskManagerTableDto)))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<any>}
	 */
	deleteErroneous() {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/deleteErroneous`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} session
	 * @param {string} name
	 * @return {Promise<EntityValidationResult>}
	 */
	deleteByName(session, name) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/deleteByName`, {
					session,
					name
				})
				.then(response => resolve(response.data !== null ? new EntityValidationResult().setup(response.data) : null))
				.catch(reject);
		});
	}
}

export default new TaskManagerTableService();
