import NotificationService from 'modules/ui/notificationPanel/service/NotificationService';
import { useLocation } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { useStrictMode } from 'react-konva';
import _ from '@lodash';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { Typography, Icon, Button, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useParams, withRouter, useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowDropDown, Dashboard } from '@material-ui/icons';
import { showMessage } from 'app/store/fuse/messageSlice';
import AspectService from 'app/admin/aspect/service/AspectService';
import WhatIfView from '../component/WhatIfView';
import { useWhatIf } from '../component/WhatIfProvider';
import WhatIfActions from '../component/WhatIfActions';
import WhatIfViewModeButton from '../component/WhatIfViewModeButton';
import WhatIfEditService from '../service/WhatIfEditService';
import WhatIfDeviceButtons from '../component/WhatIfDeviceButtons';
import CircleItemProperties from '../circle/CircleItemProperties';
import RectItemProperties from '../rect/RectItemProperties';
import DeviceItemProperties from '../device/DeviceItemProperties';
import LabelItemProperties from '../label/LabelItemProperties';
import WhatIfCalculate from '../component/WhatIfCalculate';
import WhatIfAutoConnect from '../component/WhatIfAutoConnect';
import ValueModify from '../label/ValueModify';
import FloatValue from '../label/FloatValue';
import ImageItemProperties from '../image/ImageItemProperties';
import OutputResultDialog from '../label/OutputResultDialog';
import WhatIfGroupService from '../service/WhatIfGroupService';
import WhatIfExperiment from '../component/WhatIfExperiment';
import SumTableItemProperties from '../sumtable/SumTableItemProperties';
import DocumentItemProperties from '../document/DocumentItemProperties';
import DocumentItemOperator from '../document/DocumentItemOperator';
import NoteItemProperties from '../note/NoteItemProperties';
import NoteItemOperator from '../note/NoteItemOperator';
import WhatIfStateButton from '../component/WhatIfStateButton';
import SumTableDialog from '../sumtable/SumTableDialog';
import ContainerItemProperties from '../container/ContainerItemProperties';
import FigurePathComponent from '../component/FigurePathComponent';
import LinkToWhatIfDialog from '../component/LinkToWhatIfDialog';
import WhatIfProperties from '../component/WhatIfProperties';
import CompareSidebar from '../component/compare/CompareSidebar';
import WhatIfCalculateBackground from '../component/WhatIfCalculateBackground';
import FloatLabelCompare from '../label/FloatLabelCompare';

export const paperSize = {
	width: 10000,
	height: 10000
};

export const defaultFigureData = {
	name: 'main',
	parent: null,
	stage: {
		x: (paperSize.width / 2) * -1,
		y: (paperSize.width / 2) * -1,
		scale: 1
	},
	circle: [],
	device: [],
	document: [],
	image: [],
	label: [],
	line: [],
	note: [],
	rect: [],
	sumTable: [],
	container: []
};

export const defaultData = {
	figure: [defaultFigureData],
	experiment: null,
	properties: null
};

const injecTaskStateByType = (sketch, state, entityType) => {
	if (_.isObject(state.state)) {
		Object.keys(state.state).forEach(key => {
			const attrib = sketch.experiment.attributes.find(d => d.io === 'INPUT' && d.name === key && d.entity === entityType);
			if (attrib) attrib.value = state.state[key];
		});
	}
	if (_.isObject(state.output_values)) {
		Object.keys(state.output_values).forEach(key => {
			const attrib = sketch.experiment.attributes.find(d => d.io === 'OUTPUT' && d.name === key && d.entity === entityType);
			if (attrib) attrib.value = state.output_values[key];
		});
	}
};

const injecTaskState = (sketch, state) => {
	if (state && state.aspect) {
		injecTaskStateByType(sketch, state.aspect, 'aspect');
	}
	if (state && state.transformer) {
		injecTaskStateByType(sketch, state.transformer, 'transformer');
	}
	// console.log(sketch);
};

const useStyles = makeStyles(theme => ({
	root: {
		'& .container': {
			maxWidth: '100% !important'
		}
	}
}));

function WhatIfEditPage(props) {
	const user = useSelector(({ auth }) => auth.user);
	const isOperator = user.roleList.length === 1 && user.roleList[0].title === 'Operator';
	useStrictMode(true);
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const { state } = useLocation();
	const { id, taskId } = useParams();

	const { mode, data, setData, figure, setFigure, viewMode, showPropertiesDialog, valueModify, floatValue, outputResultDialog, summaryTableDialog, linkToWhatIf, floatLabelCompare } = useWhatIf();
	const containerRef = useRef(null);
	const [groupList, setGroupList] = useState(null);

	const [dto, setDto] = useState(null);
	const [image, setImage] = useState(null);
	const { t } = useTranslation('whatIf');

	useEffect(() => {
		WhatIfGroupService.getListSelection().then(response => {
			setGroupList(_.isArray(response) ? response : []);
		});
		//throw new Error('Whatiferror');
	}, []);

	useEffect(() => {
		if (id === 'new') {
			WhatIfEditService.create().then(resp => {
				setDto(resp);
				const sketch = resp.sketch !== null ? resp.sketch : defaultData;
				setData(sketch);
				setFigure(sketch.figure.find(f => f.name === 'main'));
			});
		} else {
			WhatIfEditService.getData(id).then(resp => {
				setDto(resp);
				const sketch = resp.sketch !== null ? resp.sketch : defaultData;
				if (taskId && sketch && sketch.experiment && sketch.experiment.attributes) {
					NotificationService.getData(taskId).then(task => {
						if (task && task.data && task.data.calculate && (task.data.calculate.aspect || task.data.calculate.transformer)) {
							injecTaskState(sketch, task.data.calculate);
							setData(sketch);
							setFigure(sketch.figure.find(f => f.name === 'main'));
						}
					});
				} else {
					if (state && (state.aspect || state.attributes) && sketch && sketch.experiment && sketch.experiment.attributes) {
						injecTaskState(sketch, state);
					}
					setData(sketch);
					setFigure(sketch.figure.find(f => f.name === 'main'));
				}
			});
		}
	}, [id, taskId]);
	const handleSave = () => {
		const _dto = {
			...dto,
			sketch: figure
				? {
						...data,
						figure: data.figure.map(f => {
							if (f.name === figure.name) {
								return figure;
							}
							return f;
						})
				  }
				: data,
			aspect: data.experiment && data.experiment.aspect ? data.experiment.aspect : null
		};
		if (id !== 'new') {
			WhatIfEditService.update(dto.id, _dto).then(resp => handleResponse(resp));
		} else {
			WhatIfEditService.save(_dto).then(resp => handleResponse(resp));
		}
	};

	const setName = name => {
		setDto({ ...dto, name });
	};

	const setGroup = group => {
		setDto({ ...dto, group });
	};

	const handleResponse = resp => {
		if (resp.successful) {
			dispatch(showMessage({ message: t('saveSuccessful') }));
			history.push(mode === 'EXPERT' ? '/what-if-expert' : '/what-if');
		} else {
			dispatch(showMessage({ message: t('saveFailed') }));
		}
	};

	if (dto !== null && data !== null && figure !== null && groupList !== null)
		return (
			<FusePageCarded
				classes={{
					toolbar: 'p-0',
					header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
					root: classes.root
				}}
				innerScroll
				header={
					<div className="flex flex-1 w-full items-center justify-between">
						<div className="flex flex-col items-start max-w-full min-w-0">
							<motion.div initial={{ x: 20, opacity: 0 }} animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}>
								<Typography className="flex items-center sm:mb-12" component={Link} role="button" to={mode === 'EXPERT' ? '/what-if-expert' : '/what-if'} color="inherit">
									<Icon className="text-20">arrow_back</Icon>
									<span className="hidden sm:flex mx-4 font-medium">{t('back')}</span>
								</Typography>
							</motion.div>
							<div className="flex items-center max-w-full">
								<div className="flex flex-col min-w-0 mx-8 sm:mc-16 gap-4">
									<motion.div initial={{ x: -20 }} animate={{ x: 0, transition: { delay: 0.3 } }}>
										<TextField value={dto.name} onChange={e => (e.target.value.length > 0 ? setName(e.target.value) : setName(null))} className="mt-8 mb-16" required label={t('whatIfName')} variant="outlined" />
										<FormControl className="mt-8 mb-16 ml-8 w-128" variant="outlined">
											<InputLabel id="group-label">Group</InputLabel>
											<Select
												labelId="group-label"
												id="group"
												label="Group"
												value={dto.group}
												defaultValue={null}
												onChange={e => setGroup(e.target.value)}
												renderValue={selected => {
													return selected ? groupList.find(item => item.id === selected).name : '';
												}}
												IconComponent={ArrowDropDown}
											>
												<MenuItem value={null} />
												{groupList.map((item, i) => (
													<MenuItem key={item.id} value={item.id}>
														{item.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</motion.div>
								</div>
							</div>
						</div>
						<div>{viewMode === 'EDIT' && <WhatIfActions />}</div>
						<div className="flex flex-col h-full">
							<div className="flex-1" />
							<motion.div className="flex flex-1" initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}>
								{id !== 'new' && user.role.includes('WHATIFEXPERT') && <WhatIfViewModeButton />}
								{viewMode === 'EDIT' && <WhatIfProperties />}
								{viewMode === 'EDIT' && (
									<Button className="whitespace-nowrap mx-4" variant="contained" color="secondary" onClick={() => handleSave()} startIcon={<Icon className="hidden sm:flex">save</Icon>}>
										{t('save')}
									</Button>
								)}
								{viewMode === 'CALCULATE' && <WhatIfStateButton />}
								{viewMode === 'CALCULATE' && <WhatIfExperiment />}
								{viewMode === 'CALCULATE' && !(isOperator && _.get(data, 'properties.operatorVisibility.connectBtnVisible', 'VISIBLE') !== 'VISIBLE') && (
									<WhatIfAutoConnect aspect={data.experiment && data.experiment.aspect ? data.experiment.aspect : data.aspect} flg={_.get(data, 'properties.operatorVisibility.connectBtnVisible', 'VISIBLE')} />
								)}
								{viewMode === 'CALCULATE' && !isOperator && _.get(data, 'properties.operatorVisibility.calculateBtnVisible', true) === true ? <WhatIfCalculate id={id} /> : null}
								{viewMode === 'CALCULATE' && !isOperator && _.get(data, 'properties.operatorVisibility.calculateBackgroundBtnVisible', true) === true ? <WhatIfCalculateBackground id={id} /> : null}
							</motion.div>
							<div className="flex flex-1 flex-row justify-end items-center">
								<FigurePathComponent />
							</div>
						</div>
					</div>
				}
				content={
					<div className="h-full relative overflow-hidden" ref={containerRef}>
						{dto !== null && data !== null && figure != null && <WhatIfView />}
						<WhatIfDeviceButtons />
						<CompareSidebar />
						{valueModify !== null && <ValueModify containerRef={containerRef} />}
						{outputResultDialog !== null && <OutputResultDialog />}
						{summaryTableDialog !== null && <SumTableDialog />}
						{floatValue !== null && <FloatValue containerRef={containerRef} />}
						{floatLabelCompare !== null && <FloatLabelCompare containerRef={containerRef} />}
						{showPropertiesDialog !== null && showPropertiesDialog.type === 'CIRCLE' && <CircleItemProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.type === 'RECT' && <RectItemProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.type === 'DEVICE' && <DeviceItemProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.type === 'LABEL' && <LabelItemProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.type === 'IMAGE' && <ImageItemProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.type === 'SUMTABLE' && <SumTableItemProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.type === 'DOCUMENT' && <DocumentItemProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.type === 'DOCUMENT_OPERATOR' && <DocumentItemOperator />}
						{showPropertiesDialog !== null && showPropertiesDialog.type === 'NOTE' && <NoteItemProperties />}
						{showPropertiesDialog !== null && showPropertiesDialog.type === 'NOTE_OPERATOR' && <NoteItemOperator />}
						{showPropertiesDialog !== null && showPropertiesDialog.type === 'CONTAINER' && <ContainerItemProperties />}
						{linkToWhatIf !== null && <LinkToWhatIfDialog />}
					</div>
				}
			/>
		);
	return <></>;
}

//<Typography className="text-16 sm:text-20 truncate font-semibold">{id !== 'new' ? 'Módosítás' : 'Új szimuláció'}</Typography>

export default withRouter(WhatIfEditPage);
