import NamedDto from 'modules/base/dto/NamedDto';

export class Lummus2ModelOutputUnitOpEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.code = null;
		/** @type {string} */
		this.type_code = null;
	}

	/**
	 * @typedef {Object} _Lummus2ModelOutputUnitOpEditDtoProps
	 * @property {string} code
	 * @property {string} type_code
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _Lummus2ModelOutputUnitOpEditDtoProps} Lummus2ModelOutputUnitOpEditDtoProps
	 */
	/**
	 *
	 * @param {Lummus2ModelOutputUnitOpEditDtoProps} props
	 * @returns {Lummus2ModelOutputUnitOpEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.code = props.code;
		this.type_code = props.type_code;
		return this;
	}
}

export default Lummus2ModelOutputUnitOpEditDto;
