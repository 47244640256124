import _ from '@lodash';
import { useCallback, useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { useWhatIf } from '../component/WhatIfProvider';
import { MinMaxValue } from './VariableModify';

function ComponentVariableModify(props) {
	const { valueModify, data, figure, setValueModify, setData, getExperimentAttribute, setExperimentAttributeValue } = useWhatIf();
	const attribute = getExperimentAttribute(valueModify.valueKey);
	const valueKeyArray = valueModify.valueKey.split('.');
	const lastValueKey = valueKeyArray[valueKeyArray.length - 1];
	const [value, setValue] = useState(attribute.value[lastValueKey]);
	const [error, setError] = useState(false);
	const scale = figure.stage.scale || 1;
	const coords = {
		x: valueModify.x * scale + figure.stage.x,
		y: valueModify.y * scale + figure.stage.y
	};
	const minValue = attribute.min_value[lastValueKey];
	const maxValue = attribute.max_value[lastValueKey];

	const keyDownHandle = useCallback(
		e => {
			if (e.key === 'Escape') {
				e.preventDefault();
				setValueModify(null);
			} else if (e.key === 'Enter') {
				const floatValue = Number.parseFloat(value);
				if (Number.isNaN(floatValue)) {
					setError(true);
					e.preventDefault();
				} else {
					console.log({ ...attribute.value, [lastValueKey]: floatValue });
					setExperimentAttributeValue(valueModify.valueKey, { ...attribute.value, [lastValueKey]: floatValue });
					e.preventDefault();
					setValueModify(null);
				}
			} else {
				setError(false);
			}
		},
		[value, data, setData, valueModify, setValueModify, setError, lastValueKey]
	);

	useEffect(() => {
		document.addEventListener('keydown', keyDownHandle, false);
		return () => {
			document.removeEventListener('keydown', keyDownHandle, false);
		};
	}, [keyDownHandle]);

	return (
		<div className="absolute bg-white " style={{ width: '120px', top: `${coords.y}px`, left: `${coords.x}px` }}>
			<TextField variant="outlined" size="small" type="number" value={value} onChange={e => setValue(e.target.value)} autoFocus error={error} />
			<MinMaxValue minValue={minValue} maxValue={maxValue} />
		</div>
	);
}

export default ComponentVariableModify;
