import TableFilterDto from './TableFilterDto';
import TableOrderDto from './TableOrderDto';
import TableVariableDto from './TableVariableDto';

class TableSessionDataDto {
	constructor() {
		/** @type {string} */
		this.session = null;
		/** @type {Array<TableOrderDto>} */
		this.orderList = null;
		/** @type {Array<TableFilterDto>} */
		this.filterList = null;
		/** @type {Object.<string, TableVariableDto>} */
		this.variableMap = null;
		/** @type {Date} */
		this.created = null;
		/** @type {Date} */
		this.updated = null;
	}

	/**
	 *
	 * @typedef {Object} _TableSessionDataDtoProps
	 * @property {string} session
	 * @property {Array<TableOrderDto>} orderList
	 * @property {Array<TableFilterDto>} filterList
	 * @property {Object.<string, TableVariableDto>} variableMap
	 * @property {Date} created
	 * @property {Date} updated
	 * @typedef {_TableSessionDataDtoProps} TableSessionDataDtoProps
	 */
	/**
	 *
	 * @param {TableSessionDataDtoProps} props
	 * @returns {TableSessionDataDto}
	 */
	setup(props) {
		this.session = props.session;
		this.orderList = props.orderList != null ? props.orderList.map(d => new TableOrderDto().setup(d)) : null;
		this.filterList = props.filterList != null ? props.filterList.map(d => new TableFilterDto().setup(d)) : null;
		this.variableMap = null;
		if (props.variableMap != null) {
			this.variableMap = {};
			Object.keys(props.variableMap).forEach(key => {
				this.variableMap[key] = new TableVariableDto().setup(props.variableMap[key]);
			});
		}
		this.created = props.created != null ? new Date(props.created) : null;
		this.updated = props.updated != null ? new Date(props.updated) : null;
		return this;
	}
}

export default TableSessionDataDto;
