class TableMetadataDto {
	constructor() {
		/** @type {number} */
		this.first = null;
		/** @type {number} */
		this.currentCount = null;
		/** @type {number} */
		this.totalCount = null;
		/** @type {number} */
		this.selectedCount = null;
	}

	/**
	 *
	 * @typedef {Object} _TableMetadataDtoProps
	 * @property {number} first
	 * @property {number} currentCount
	 * @property {number} totalCount
	 * @property {number} selectedCount
	 * @typedef {_TableMetadataDtoProps} TableMetadataDtoProps
	 */
	/**
	 *
	 * @param {TableMetadataDtoProps} props
	 * @returns {TableMetadataDto}
	 */
	setup(props) {
		this.first = props.first;
		this.currentCount = props.currentCount;
		this.totalCount = props.totalCount;
		this.selectedCount = props.selectedCount;
		return this;
	}
}

export default TableMetadataDto;
