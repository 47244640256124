import _ from '@lodash';
import AspectEditService from 'app/admin/aspect/service/AspectEditService';
import ModelService from 'app/admin/model/service/ModelService';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from 'react';
import { Group, Rect, Text } from 'react-konva';
import { generateId } from 'app/admin/whatIf/component/WhatIfUtil';
import { useWorkflow } from '../WorkflowProvider';
import { roundCoord, validationItemColor } from '../WorkflowUtil';
import InputPin from './InputPin';
import OutputPin from './OutputPin';

export const width = 150;
export const offsetX = 75;
export const minHeight = 100;

export const calcInputPosByName = (item, inputName) => {
	const index = item.input_fields.findIndex(input => input.name === inputName) + 1;
	if (index > 0) return calcInputPos(index);
	return null;
};

export const calcOutputPosByName = (item, outputName, height) => {
	const index = item.output_fields.findIndex(output => output.name === outputName) + 1;
	if (index > 0) return calcOutputPos(height, index, item.output_fields.length);
	return null;
};

export const calcInputPos = index => {
	return {
		x: -10,
		y: 10 + (index - 1) * 15
	};
};

export const calcOutputPos = (height, index, max) => {
	return {
		x: width + 10,
		y: height - 10 - (max - index) * 15
	};
};

export const getCalculateValidationValue = (calculate, name) => {
	if (calculate != null && calculate.transformer && _.isArray(calculate.transformer.transformation_validations)) {
		let result = null;
		calculate.transformer.transformation_validations.forEach(transValidation => {
			if (transValidation[name] && transValidation[name].entries.length > 0) {
				result = transValidation[name];
			}
		});
		return result;
	}
	return null;
};

export default function WorkflowItem(props) {
	const { item, type } = props;
	const [isDragging, setIsDragging] = useState(false);
	const [pos, setPos] = useState({ x: item.x, y: item.y });
	const { calculate, data, setData, editMode, checkStageCorner, setShowPropertiesDialog, underEditing, endLine, valueKeyAvailable, initLine, selected, setUnderEditing, setFloatValidation, showMessage } = useWorkflow();
	const { t } = useTranslation('transformer');
	const dragStartData = useRef(null);
	const itemPrevState = useRef(item);

	const validation = getCalculateValidationValue(calculate, item.name);

	useEffect(() => {
		if (pos.x !== item.x || pos.y !== item.y) setPos({ x: item.x, y: item.y });
		//if (itemPrevState.current.inputs.toString() !== item.inputs.toString() || itemPrevState.current.outputs.toString() !== item.outputs.toString()) updatePins();
		//itemPrevState.current = item;
	}, [item]);

	const handleItemClick = () => {
		if (editMode === 'DELETE') {
			setData({
				...data,
				line: data.line.filter(d => d.start.key !== item.key && d.end.key !== item.key),
				transform: data.transform.filter(d => d.key !== item.key)
			});
		} else if (editMode === 'LINE' && underEditing !== null) {
			setData({ ...data, line: data.line.filter(d => d.key !== underEditing.key) });
			setUnderEditing(null);
		}
	};

	const handleInputClick = (name, pointPos) => {
		if (editMode === 'LINE' && underEditing !== null) {
			endLine(item.key, name, pointPos);
		} else if (editMode === 'MOVE') {
			setData({
				...data,
				transform: data.transform.map(d => {
					if (d.key === item.key) {
						return {
							...d,
							input_fields: d.input_fields.map(inp => {
								if (inp.name === name) {
									return { ...inp, enabled: !inp.enabled };
								}
								return inp;
							})
						};
					}
					return d;
				})
			});
		} else if (editMode === 'VALUE') {
			if (data.line.findIndex(l => l.end.key === item.key && l.end.valueKey === name) > -1) {
				showMessage(`Line connected.`);
			} else if (!valueKeyAvailable(name, null)) {
				showMessage(`Value already added.`);
			} else if (item.type === 'Aspect' && item.aspect_name) {
				AspectEditService.getDataByName(item.aspect_name).then(aspect => {
					if (aspect !== null && aspect.model !== null) {
						ModelService.getData(aspect.model)
							.then(model => {
								try {
									const modelInputAttributes = model.export ? model.export.input_attributes : model.input_attributes;
									if (aspect.input_attributes !== null && aspect.input_attributes.length > 0 && modelInputAttributes && modelInputAttributes.length > 0) {
										const input = aspect.input_attributes.find(inp => inp.name === name);
										if (input) {
											const modelData = modelInputAttributes.find(d => d.name === (input.model_field_name != null ? input.model_field_name : input.name));
											if (modelData) {
												addInputValueItem(name, pointPos, input, modelData);
												return;
											}
										}
									}
									addInputValueItem(name, pointPos);
								} catch (e) {
									showMessage(`Failed to load model: ${e}`);
								}
							})
							.catch(e => {
								showMessage(`Failed to load aspect: ${e}`);
							});
					}
				});
			} else {
				addInputValueItem(name, pointPos);
			}
		}
	};

	const handleOutputClick = (name, pointPos) => {
		if (editMode === 'LINE' && underEditing === null) {
			initLine(item.key, name, pointPos);
		} else if (editMode === 'LINE' && underEditing !== null) {
			setData({ ...data, line: data.line.filter(d => d.key === underEditing.key) });
			setUnderEditing(null);
		} else if (editMode === 'VALUE') {
			if (data.line.findIndex(l => l.start.key === item.key && l.start.valueKey === name) > -1) {
				showMessage(`Line connected.`);
			} else if (!valueKeyAvailable(name, null)) {
				showMessage(`Value already added.`);
			} else if (item.type === 'Aspect' && item.aspect_name) {
				AspectEditService.getDataByName(item.aspect_name).then(aspect => {
					if (aspect !== null && aspect.model !== null) {
						ModelService.getData(aspect.model)
							.then(model => {
								try {
									const modelOutputAttributes = model.export ? model.export.output_attributes : model.output_attributes;
									if (aspect.output_attributes !== null && aspect.output_attributes.length > 0 && modelOutputAttributes && modelOutputAttributes.length > 0) {
										const output = aspect.output_attributes.find(inp => inp.name === name);
										if (output) {
											const modelData = modelOutputAttributes.find(d => d.name === (output.model_field_name != null ? output.model_field_name : output.name));
											if (modelData) {
												addOutputValueItem(name, pointPos, output, modelData);
												return;
											}
										}
									}
									addOutputValueItem(name, pointPos);
								} catch (e) {
									showMessage(`Failed to load model: ${e}`);
								}
							})
							.catch(e => {
								showMessage(`Failed to load aspect: ${e}`);
							});
					}
				});
			} else {
				addOutputValueItem(name, pointPos);
			}
		}
	};

	const calcHeight = () => {
		let plusItems = 0;
		if (item.input_fields.length > 2) plusItems = plusItems + item.input_fields.length - 2;
		if (item.output_fields.length > 2) plusItems = plusItems + item.output_fields.length - 2;
		if (plusItems > 0) {
			return minHeight + plusItems * 18;
		}
		return minHeight;
	};

	const height = calcHeight();
	const offsetY = height / 2;

	const calcRelativePos = pointPos => {
		return {
			x: pos.x - offsetX + pointPos.x,
			y: pos.y - offsetY + pointPos.y
		};
	};

	const updateDragPos = (e, isEnd) => {
		const currentPos = { x: roundCoord(e.target.x()), y: roundCoord(e.target.y()) };
		let deltaX = 0;
		let deltaY = 0;
		const dragStartValue = dragStartData.current.transform.find(d => d.key === item.key);
		deltaX = currentPos.x - dragStartValue.x;
		deltaY = currentPos.y - dragStartValue.y;
		if (selected.length > 0 && selected.indexOf(item.key) > -1) {
			setData({
				...data,
				line: data.line.map(d => {
					if (selected.indexOf(d.start.key) > -1 || d.start.key === item.key) {
						const startValue = dragStartData.current.line.find(f => f.key === d.key);
						d.start.pos = { x: startValue.start.pos.x + deltaX, y: startValue.start.pos.y + deltaY };
					}
					if (selected.indexOf(d.end.key) > -1 || d.end.key === item.key) {
						const startValue = dragStartData.current.line.find(f => f.key === d.key);
						d.end.pos = { x: startValue.end.pos.x + deltaX, y: startValue.end.pos.y + deltaY };
					}
					return d;
				}),
				value: data.value.map(d => {
					if (selected.indexOf(d.key) > -1) {
						const startValue = dragStartData.current.value.find(f => f.key === d.key);
						return { ...d, x: startValue.x + deltaX, y: startValue.y + deltaY };
					}
					return d;
				}),
				transform: data.transform.map(d => {
					if (d.key === item.key) {
						return { ...d, ...currentPos };
					}
					if (selected.indexOf(d.key) > -1) {
						const startValue = dragStartData.current.transform.find(f => f.key === d.key);
						return { ...d, x: startValue.x + deltaX, y: startValue.y + deltaY };
					}
					return d;
				})
			});
		} else {
			setData({
				...data,
				line: data.line.map(d => {
					if (d.start.key === item.key) {
						if (isEnd) {
							const pinPos = calcOutputPosByName(item, d.start.valueKey, height);
							d.start.pos = { x: currentPos.x - offsetX + pinPos.x, y: currentPos.y - offsetY + pinPos.y };
						} else {
							const startValue = dragStartData.current.line.find(f => f.key === d.key);
							d.start.pos = { x: startValue.start.pos.x + deltaX, y: startValue.start.pos.y + deltaY };
						}
					}
					if (d.end.key === item.key) {
						if (isEnd) {
							const pinPos = calcInputPosByName(item, d.end.valueKey);
							d.end.pos = { x: currentPos.x - offsetX + pinPos.x, y: currentPos.y - offsetY + pinPos.y };
						} else {
							const startValue = dragStartData.current.line.find(f => f.key === d.key);
							d.end.pos = { x: startValue.end.pos.x + deltaX, y: startValue.end.pos.y + deltaY };
						}
					}
					return d;
				}),
				transform: data.transform.map(d => {
					if (d.key === item.key) {
						return { ...d, ...currentPos };
					}
					return d;
				})
			});
		}
	};

	const addInputValueItem = (name, pointPos, aspectData, modelData) => {
		const valueId = generateId();
		const lineId = generateId();
		setData({
			...data,
			value: [
				...data.value,
				{
					key: valueId,
					valueKey: name,
					class: 'VALUE',
					io: 'INPUT',
					valueType: aspectData ? aspectData.type : null,
					value: aspectData ? aspectData.value : null,
					uom: modelData ? modelData.uom : null,
					x: pointPos.x - 150,
					y: pointPos.y
				}
			],
			line: [
				...data.line,
				{
					key: lineId,
					start: {
						key: valueId,
						valueKey: name,
						pos: { x: pointPos.x - 65, y: pointPos.y }
					},
					end: {
						key: item.key,
						valueKey: name,
						pos: pointPos
					}
				}
			]
		});
	};

	const addOutputValueItem = (name, pointPos, aspectData, modelData) => {
		const valueId = generateId();
		const lineId = generateId();
		console.log(aspectData);
		console.log(modelData);

		setData({
			...data,
			value: [
				...data.value,
				{
					key: valueId,
					valueKey: name,
					class: 'VALUE',
					io: 'OUTPUT',
					valueType: aspectData ? aspectData.type : null,
					value: aspectData ? aspectData.value : null,
					uom: modelData ? modelData.uom : null,
					x: pointPos.x + 150,
					y: pointPos.y
				}
			],
			line: [
				...data.line,
				{
					key: lineId,
					start: {
						key: item.key,
						valueKey: name,
						pos: pointPos
					},
					end: {
						key: valueId,
						valueKey: name,
						pos: { x: pointPos.x + 65, y: pointPos.y }
					}
				}
			]
		});
	};

	const labelOffsetY = item.input_fields.length > 2 ? 10 + (item.input_fields.length - 1) * 15 + 34 : offsetY;

	return (
		<Group
			id={item.key}
			name="item"
			x={pos.x}
			y={pos.y}
			offsetX={offsetX}
			offsetY={offsetY}
			width={width}
			height={height}
			draggable={editMode === 'MOVE'}
			onDragStart={() => {
				setIsDragging(true);
				dragStartData.current = {
					line: data.line.map(d => ({ key: d.key, start: { ...d.start }, end: { ...d.end } })),
					value: data.value.map(d => ({ key: d.key, x: d.x, y: d.y })),
					transform: data.transform.map(d => ({ key: d.key, x: d.x, y: d.y }))
				};
			}}
			onDragMove={e => {
				updateDragPos(e, false);
				checkStageCorner();
			}}
			onDragEnd={e => {
				setIsDragging(false);
				updateDragPos(e, true);
				dragStartData.current = null;
			}}
			onPointerDblClick={() => {
				if (editMode !== 'DELETE') setShowPropertiesDialog({ class: 'TRANSFORM', type: item.type, key: item.key });
				setFloatValidation(null);
			}}
		>
			<Rect onPointerClick={() => handleItemClick()} stroke="darkGreen" fill={selected.indexOf(item.key) > -1 ? 'lightGreen' : 'white'} cornerRadius={10} width={width} height={height} opacity={0.8} />
			{validation && (
				<Rect
					fill={validationItemColor(validation)}
					cornerRadius={10}
					width={width - 6}
					height={height - 6}
					x={3}
					y={3}
					opacity={0.8}
					onPointerEnter={() => {
						if (validation) setFloatValidation({ name: item.key, value: validation });
					}}
					onPointerLeave={() => {
						if (validation) setFloatValidation(null);
					}}
				/>
			)}
			{item.input_fields &&
				item.input_fields.map((inp, i) => <InputPin key={i} index={i + 1} transformer={item.name} name={inp.name} enabled={inp.enabled} onInputClick={(name, pointPos) => handleInputClick(name, calcRelativePos(pointPos))} />)}
			{item.output_fields &&
				item.output_fields.map((out, i) => (
					<OutputPin key={i} index={i + 1} transformer={item.name} name={out.name} max={item.output_fields.length} itemHeight={height} onOutputClick={(name, pointPos) => handleOutputClick(name, calcRelativePos(pointPos))} />
				))}
			<Text text={t(type)} fontSize={16} fill="black" fontStyle="bold" width={width} padding={5} y={labelOffsetY - 18} align="center" listening={false} />
			<Text text={item.name} fontSize={12} fill="black" fontStyle="bold" width={width} padding={5} y={labelOffsetY} align="center" listening={false} />
		</Group>
	);
}
