import useFullScreen from '@toluade/use-fullscreen';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';

const HeaderFullScreenToggle = props => {
	const { isFullScreen, toggleFullScreen } = useFullScreen('root');
	return (
		<Tooltip title="Fullscreen toggle" placement="bottom">
			<IconButton onClick={toggleFullScreen} className={clsx('w-40 h-40', props.className)}>
				<Icon>{isFullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
			</IconButton>
		</Tooltip>
	);
};

export default HeaderFullScreenToggle;
