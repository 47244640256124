import _ from '@lodash';
import WorkflowItem from '../WorkflowItem';
import { gridConverter } from '../../WorkflowUtil';

export const defaultDefinition = {
	name: null,
	input_fields: [{ name: 'Components', type: 'Components', enabled: true, value: null }],
	output_fields: [{ name: 'Components', type: 'Components' }],
	feature_column: null,
	component_title: null,
	multiplier: null,
	rows: [],
	columns: [],
	conversion: []
};

export const converter = function (data) {
	return {
		...data,
		conversion: gridConverter(data, 'conversion')
	};
};

export default function DodoTransformation(props) {
	return <WorkflowItem {...props} type="DODO" />;
}
