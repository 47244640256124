const userEn = {
	userTable: 'Hozzáférés',
	name: 'Név',
	roleList: 'Jogkör',
	email: 'E-mail',
	registrationTimestamp: 'Regisztráció ideje',
	lastLoginTimestamp: 'Utolsó belépés ideje',
	registrationStatus: 'Státusz',
	dashboardRoleTable: 'Jogkör',
	title: 'Név',
	adminDescription: 'Leírás',
	isEnabled: 'Engedélyezve',
	created: 'Létrehozva',
	updated: 'Módosítva',
	dashboardRoleEditForm: 'Jogkör',
	basic: 'Alapadatok',
	servicePermissionList: 'Szolgáltatások',
	contentPermissionList: 'Tartalom hozzáférés',
	key: 'Rendszer által használt név (opcionális)',
	orderIndex: 'Pozíció',
	isSuperAdmin: 'Szuperadmin',
	service: 'Szolgáltatás',
	grantedLevel: 'Jogosultság',
	contentType: 'Tartalomtípus',
	whatIfGroupList: 'What If Group Access'
};

export default userEn;
