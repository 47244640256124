import _ from '@lodash';

export class IdentifiableDto {
	constructor() {
		/** @type {number} */
		this.id = null;
	}

	/**
	 *
	 * @typedef {Object} _IdentifiableDtoProps
	 * @property {number} id
	 * @typedef {_IdentifiableDtoProps} IdentifiableDtoProps
	 */
	/**
	 *
	 * @param {IdentifiableDtoProps} props
	 * @returns {IdentifiableDto}
	 */
	setup(props) {
		if (props === null) return null;
		this.id = props.id;
		return this;
	}

	/**
	 *
	 * @param {string} key
	 * @returns {string}
	 */
	getAsString(key) {
		if (key.indexOf('.') > 0) {
			const keyParts = key.split('.');
			if (!(_.isUndefined(this[keyParts[0]]) || this[keyParts[0]] === null)) {
				return this[keyParts[0]].getAsString(keyParts.splice(1).join('.'));
			}
		}
		return _.isUndefined(this[key]) || this[key] === null ? '' : this[key].toString();
	}
}

export default IdentifiableDto;
