const authHu = {
	MY_PROFILE: 'Profilom',
	LOGOUT: 'Kijelentkezés',
	LOGIN: 'Bejelentkezés',
	REGISTER: 'Regisztráció',
	EMAIL: 'E-mail',
	PASSWORD: 'Jelszó',
	PASSWORD_AGAIN: 'Jelszó mégegyszer',
	LOST_YOUR_PASSWORD: 'Elfelejtetted a jelszavad?',
	REQUEST_A_NEW_PASSWORD: 'Kérj újat!',
	BACK_TO_LOGIN: 'Vissza a bejelentkezésre',
	SAVE_PASSWORD: 'Jelszó mentése',
	NEW_PASSWORD_SET_MESSAGE: 'Az új jelszavad beállításra került. Kérlek jelentkezz be.',
	NEW_PASSWORD_SET_FAILED_MESSAGE: 'Hiba történt az új jelszó beállítása közben.',
	PLEASE_SET_PASSWORD_MESSAGE: 'Kérlek add meg a biztonságos új jelszavad kétszer.',
	PLEASE_SET_EMAIL_MESSAGE: 'Kérlek add meg az e-mail címed, amivel a rendszerbe vagy regisztrálva.',
	PLEASE_SET_EMAIL_DESC: 'A megadott e-mail címre egy linket fogunk küldeni, amire kattintva tudsz majd új jelszót generálni.',
	NEW_PASSWORD_REQUEST: 'Kérem az e-mail-t',
	NEW_PASSWORD_REQUEST_MESSAGE: 'A megadott e-mail címre a jelszóbeállításhoz szükséges levelet küldtünk ki. Kérlek ellenőrizd a postafiókod.',
	NEW_PASSWORD_REQUEST_FAILED: 'A megadott e-mail címre nem lehetséges új jelszót igényelni.'
};

export default authHu;
