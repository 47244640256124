import { Circle, Group, Line, Text } from 'react-konva';
import { calcInputPos, width } from './WorkflowItem';

function InputPin(props) {
	const { index, name, onInputClick, enabled } = props;
	const pos = calcInputPos(index);

	return (
		<Group>
			<Text text={name} fontSize={9} fill="black" listening={false} width={width} x={5} y={pos.y - 4} hitStrokeWidth={5} />
			<Line stroke="darkGreen" strokeWidth={5} points={[pos.x, pos.y, 0, pos.y]} hitStrokeWidth={5} onPointerClick={() => onInputClick(name, pos)} />
			<Circle x={pos.x} y={pos.y} fill="darkGreen" radius={5} hitStrokeWidth={5} onPointerClick={() => onInputClick(name, pos)} />
			{!enabled && <Circle x={pos.x} y={pos.y} fill="white" radius={3} listening={false} />}
		</Group>
	);
}

export default InputPin;
