import nBossConnection from 'modules/base/service/nBossConnection';

class OptimizationCaseService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'optimizationCaseControl';
	}

	/**
	 * @return {Promise<any>}
	 */
	getOptimizerTypeSelection() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getOptimizerTypeSelection`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} optimizerLogName
	 * @param {number} id
	 * @return {Promise<Object>}
	 */
	createOptimizerLog(optimizerLogName, id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/createOptimizerLog`, {
					optimizerLogName,
					id
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} name
	 * @param {string} aspectName
	 * @param {string} transformerName
	 * @return {Promise<any>}
	 */
	createFromAspect(name, aspectName, transformerName) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/createFromAspect`, {
					name,
					aspectName,
					transformerName
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}
export default new OptimizationCaseService();
