import _ from '@lodash';
import { MdOutlineInput } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ThemeProvider } from '@material-ui/core/styles';
import { useState } from 'react';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { useFormContext } from 'react-hook-form';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Tabs, Tab } from '@material-ui/core';
import EditFormGridComponent from 'modules/ui/editform/subForm/EditFormGridComponent';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormComponentMassSomethingField from '../../common/component/EditFormComponentMassSomethingField';
import PiMeasurementReaderComponent from '../../common/component/PiMeasurementReaderComponent';

function OptimizationCaseStateButton(props) {
	const mainTheme = useSelector(selectMainTheme);
	const { id } = props;
	const [tabValue, setTabValue] = useState(0);
	const { getValues, setValue } = useFormContext();
	const [showDialog, setShowDialog] = useState(false);
	const { t } = useTranslation(['optimizationCase', 'common']);

	const handleClick = () => {
		setShowDialog(true);
	};

	const handleLoad = values => {
		const dto = getValues('domains');
		console.log(dto);
		setValue(
			'domains',
			dto.map(d => {
				const key = d.model_field_name != null ? d.model_field_name : d.name;
				if (!_.isUndefined(values[key])) {
					return { ...d, value: values[key] };
				}
				return d;
			})
		);
		setTabValue(0);
	};

	return (
		<>
			<Button className="whitespace-nowrap mx-4" variant="contained" color="secondary" disabled={id === 'new'} onClick={() => handleClick()} startIcon={<MdOutlineInput className="hidden sm:flex" />}>
				{t('state')}
			</Button>
			<ThemeProvider theme={mainTheme}>
				<Dialog open={showDialog} fullWidth maxWidth="lg">
					<DialogTitle disableTypography className="border-b">
						<div className="flex flex-row w-full">
							<div className="flex mr-24 items-center">{t('state')}</div>
							<Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} aria-label="sum-table-properties-tabs">
								<Tab label={t('values')} />
								<Tab label={t('reader')} />
							</Tabs>
						</div>
					</DialogTitle>
					<DialogContent className="p-20">
						<div className={tabValue !== 0 ? 'hidden' : ''}>
							<EditFormGridComponent
								config={{ topic: 'optimizationCase' }}
								fieldConfig={{
									key: 'domains',
									component: EditFormGridComponent,
									modifyOnly: true,
									defaultValue: {
										id: null,
										name: null,
										type: null,
										value: null,
										min_value: null,
										max_value: null,
										step_count: null
									},
									fields: [
										{
											key: 'name',
											component: EditFormTextField,
											required: true
										},
										{
											key: 'value',
											component: EditFormTextField,
											required: true,
											dependsOn: 'type',
											visibleIf: type => type === 'Variable' || type === 'SpreadSheet' || type === null,
											type: 'number'
										},
										{
											key: 'value',
											component: EditFormComponentMassSomethingField,
											dependsOn: 'type',
											visibleIf: type => type === 'ComponentMassFrac'
										}
									]
								}}
							/>
						</div>
						<div className={tabValue !== 1 ? 'hidden' : ''}>
							<PiMeasurementReaderComponent onLoad={values => handleLoad(values)} />
						</div>
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button
							onClick={() => {
								setShowDialog(false);
							}}
						>
							{t('common:close')}
						</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</>
	);
}

export default OptimizationCaseStateButton;
