import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { motion } from 'framer-motion';
import { Breadcrumbs, Button, Typography } from '@material-ui/core';
import { useWhatIf } from './WhatIfProvider';

function FigurePathComponent(props) {
	const { figurePath, changeFigure } = useWhatIf();

	if (figurePath.length === 0) return <></>;

	return (
		<motion.div className="px-8" initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}>
			<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
				{figurePath.map((figure, index) =>
					index === figurePath.length - 1 ? (
						<Typography color="textPrimary" key={figure}>
							{figure}
						</Typography>
					) : (
						<Button size="small" key={figure} color="inherit" onClick={() => changeFigure(figure)}>
							{figure}
						</Button>
					)
				)}
			</Breadcrumbs>
		</motion.div>
	);
}

export default FigurePathComponent;
