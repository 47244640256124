import { makeStyles, useTheme, ThemeProvider } from '@material-ui/core/styles';
import { selectContrastMainTheme } from 'app/store/fuse/settingsSlice';
import { useSelector } from 'react-redux';
import TableBrowserHeader from '../tablebrowser/components/TableBrowserHeader';

const headerHeight = 64;

const useStyles = makeStyles(theme => ({
	header: {
		background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
		backgroundSize: 'cover',
		padding: '0 3.2rem',
		borderRadius: '20px 20px 0 0',
		height: headerHeight,
		minHeight: headerHeight,
		maxHeight: headerHeight,
		display: 'flex',
		color: theme.palette.primary.contrastText,
		width: '100%'
	}
}));

function TableBrowserComponentHeader(props) {
	const classes = useStyles(props);
	const theme = useTheme();
	const contrastTheme = useSelector(selectContrastMainTheme(theme.palette.primary.main));

	return (
		<div className={classes.header}>
			<ThemeProvider theme={contrastTheme}>
				<TableBrowserHeader config={props.config} />
			</ThemeProvider>
		</div>
	);
}

export default TableBrowserComponentHeader;
