import { authRoles } from 'app/auth';
import TestPage from './TestPage';
import Test1Page from './Test1Page';
import Test2Page from './Test2Page';

export default [
	{
		path: '/test',
		component: TestPage,
		auth: authRoles.driver
	},
	{
		path: '/test1',
		component: Test1Page,
		auth: authRoles.driver
	},
	{
		path: '/test2',
		component: Test2Page,
		auth: authRoles.driver
	}
];
