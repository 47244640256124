import _ from '@lodash';
import WorkflowItem from '../WorkflowItem';

export const defaultDefinition = {
	name: null,
	input_fields: [{ name: 'Components', type: 'Components', enabled: true, value: null }],
	output_fields: [{ name: 'Components', type: 'Components' }],
	feature_column: null,
	component_title: null,
	threshold: 0.000001
};

export const converter = function (data) {
	return data;
};

export default function FilterZeroTransformation(props) {
	return <WorkflowItem {...props} type="FILTER_ZERO" />;
}
