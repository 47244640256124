import _ from '@lodash';
import clsx from 'clsx';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { determineEditableAccess } from 'modules/ui/editform/EditFormUtils';
import { useTranslation } from 'react-i18next';
import EditFormFreeSoloField from 'modules/ui/editform/fields/EditFormFreeSoloField';

function EditFormComponentListField(props) {
	const methods = useFormContext();
	const { value, onChange } = props.field;
	const [openDialog, setOpenDialog] = useState(false);
	const [readOnly, setReadOnly] = useState(determineEditableAccess(props.config, props.fieldConfig, props.fieldPrefix));
	const { compact = false } = props;

	useEffect(() => {
		if (_.isFunction(props.fieldConfig.onChange)) props.fieldConfig.onChange(value, props.field, methods);
	}, [value]);

	return (
		<div className={clsx(compact ? 'flex items-center px-8' : 'flex items-center px-8 mt-8 mb-16', props.fieldConfig.className)}>
			<Button variant="contained" color="primary" className="w-full" onClick={() => setOpenDialog(true)}>
				Components
			</Button>
			{openDialog && (
				<ComponentListDialog
					fieldPrefix={props.fieldPrefix}
					value={value}
					onCancel={() => setOpenDialog(false)}
					onSave={result => {
						onChange(result);
						setOpenDialog(false);
					}}
				/>
			)}
		</div>
	);
}

export function ComponentListDialog(props) {
	const { t } = useTranslation(['common']);
	const methods = useForm({
		defaultValues: { components: props.value },
		mode: 'onChange'
	});

	const handleSave = value => {
		props.onSave(value.components);
	};

	const handleCancel = () => {
		props.onCancel();
	};

	return (
		<Dialog open fullWidth>
			<DialogTitle disableTypography className="border-b">
				Components
			</DialogTitle>
			<DialogContent className="p-20">
				<FormProvider {...methods}>
					<Controller
						control={methods.control}
						name="components"
						render={({ field, fieldState }) => (
							<EditFormFreeSoloField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'model' }}
								fieldConfig={{
									key: 'components',
									text: 'Components',
									required: true,
									multiple: true,
									query: () => new Promise(resolve => resolve([]))
								}}
							/>
						)}
					/>
				</FormProvider>
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button
					onClick={() => {
						handleCancel();
					}}
				>
					{t('CANCEL')}
				</Button>
				<Button onClick={methods.handleSubmit(handleSave)} color="primary" autoFocus>
					{t('SAVE')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default EditFormComponentListField;
