import _ from '@lodash';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Paper, TableContainer, Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';

function OptimizationPipelineEda(props) {
	const { item } = props;

	return (
		<Dialog open fullWidth>
			<DialogTitle disableTypography className="border-b">
				{props.title || 'Unit'}
			</DialogTitle>
			<DialogContent className="p-20">
				{/* {item.result.best_state && ( */}
					<div>
						{/* <div className="pb-12 text-lg font-semibold">Best state:</div> */}
						<TableContainer component={Paper}>
							<Table className="w-full" aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell align="right">Value</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{/* {Object.keys(item.result.best_state).map(key => (
										<TableRow key={key}>
											<TableCell component="th" scope="row">
												{key}
											</TableCell>
											<TableCell className="font-mono" align="right">
												{item.result.best_state[key]}
											</TableCell>
										</TableRow>
									))} */}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				{/* )} */}
				{/* {item.result.best_value && (
					<div className="pt-12 flex flex-row">
						<div className="pr-12 text-lg font-semibold">Best value:</div>
						<div className="text-lg font-mono">{item.result.best_value}</div>
					</div>
				)} */}
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button onClick={() => { props.onClose(); }}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default OptimizationPipelineEda;
