import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './i18n/en';
import hu from './i18n/hu';
import OptimizationCaseEditForm from './editform/OptimizationCaseEditForm';
import OptimizationCaseTable from './table/OptimizationCaseTable';

i18next.addResourceBundle('en', 'optimizationCase', en);
i18next.addResourceBundle('hu', 'optimizationCase', hu);

export default [
	{
		path: '/optimization-case/edit/:id/:tabKey?',
		component: OptimizationCaseEditForm,
		auth: authRoles.optimizationCase
	},
	{
		path: '/optimization-case/:sessionNameParam?',
		component: OptimizationCaseTable,
		auth: authRoles.optimizationCase
	}
];
