const DeviceData = {
	AIR_COOLER: {
		img: '/assets/whatIf/device/air_cooler.svg',
		imgSize: {
			w: 104,
			h: 76
		},
		itemBorder: 5,
		joinPointRadius: 6,
		joinPoints: [
			{
				id: 1,
				x: 0,
				y: 24
			},
			{
				id: 2,
				x: 104,
				y: 24
			}
		]
	},
	COMPRESSOR: {
		img: '/assets/whatIf/device/compressor.svg',
		imgSize: {
			w: 122,
			h: 76
		},
		itemBorder: 5,
		joinPointRadius: 6,
		joinPoints: [
			{
				id: 1,
				x: 24,
				y: 14
			},
			{
				id: 2,
				x: 120,
				y: 14
			},
			{
				id: 3,
				x: 0,
				y: 50
			},
			{
				id: 4,
				x: 106,
				y: 50
			}
		]
	},
	DISTILLATION_COLUMN: {
		img: '/assets/whatIf/device/distillation_column.svg',
		imgSize: {
			w: 42,
			h: 120
		},
		itemBorder: 5,
		joinPointRadius: 6,
		joinPoints: []
	},
	FURNACE: {
		img: '/assets/whatIf/device/furnace.svg',
		imgSize: {
			w: 69,
			h: 91
		},
		itemBorder: 5,
		joinPointRadius: 6,
		joinPoints: [
			{
				id: 1,
				x: 18,
				y: 6
			},
			{
				id: 2,
				x: 18,
				y: 26
			},
			{
				id: 3,
				x: 2,
				y: 50
			},
			{
				id: 4,
				x: 66,
				y: 90
			}
		]
	},
	HEAT_EXCHANGER: {
		img: '/assets/whatIf/device/heat_exchanger.svg',
		imgSize: {
			w: 64,
			h: 64
		},
		itemBorder: 5,
		joinPointRadius: 6,
		joinPoints: [
			{
				id: 1,
				x: 2,
				y: 32
			},
			{
				id: 2,
				x: 62,
				y: 32
			}
		]
	},
	PUMP: {
		img: '/assets/whatIf/device/pump.svg',
		imgSize: {
			w: 66,
			h: 68
		},
		itemBorder: 5,
		joinPointRadius: 6,
		joinPoints: [
			{
				id: 1,
				x: 4,
				y: 30
			},
			{
				id: 2,
				x: 64,
				y: 13
			}
		]
	}
};
export default DeviceData;
