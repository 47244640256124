import TableFilterDateIntervalField from './TableFilterDateIntervalField';
import TableFilterStringInField from './TableFilterStringInField';
import TableFilterStringLikeField from './TableFilterStringLikeField';
import TableFilterLongInField from './TableFilterLongInField';
import TableFilterLongEqField from './TableFilterLongEqField';
import TableFilterBooleanEqField from './TableFilterBooleanEqField';
import TableFilterBooleanIsNullField from './TableFilterBooleanIsNullField';
import TableFilterLongAutoCompleteField from './TableFilterLongAutoCompleteField';
import TableFilterStringEqField from './TableFilterStringEqField';

const TableFilterRepo = {
	StringLIKE: TableFilterStringLikeField,
	StringIN: TableFilterStringInField,
	StringEQ: TableFilterStringEqField,
	LongIN: TableFilterLongInField,
	LongEQ: TableFilterLongEqField,
	DateINTERVAL: TableFilterDateIntervalField,
	BooleanEQ: TableFilterBooleanEqField,
	BooleanIS_NULL: TableFilterBooleanIsNullField,
	LongAutoComplete: TableFilterLongAutoCompleteField
};

export default TableFilterRepo;
