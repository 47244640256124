import ModelEditDto from 'app/admin/model/dto/ModelEditDto';
import LummusModelInputUnitOpEditDto from './LummusModelInputUnitOpEditDto';
import LummusModelOutputUnitOpEditDto from './LummusModelOutputUnitOpEditDto';
import LummusModelInputAttributeEditDto from './LummusModelInputAttributeEditDto';
import LummusModelAttributeEditDto from './LummusModelAttributeEditDto';
import LummusModelComponentEditDto from './LummusModelComponentEditDto';

export class LummusModelEditDto extends ModelEditDto {
	constructor() {
		super();
		/** @type {string} */
		this.state_file = null;
		/** @type {number} */
		this.driverLog = null;
		/** @type {string} */
		this.simulation_id = null;
		/** @type {LummusModelComponentEditDto[]} */
		this.components = null;
		/** @type {LummusModelInputUnitOpEditDto[]} */
		this.input_unit_ops = null;
		/** @type {LummusModelOutputUnitOpEditDto[]} */
		this.output_unit_ops = null;
		/** @type {LummusModelInputAttributeEditDto[]} */
		this.input_attributes = null;
		/** @type {LummusModelAttributeEditDto[]} */
		this.output_attributes = null;
	}

	/**
	 * @typedef {Object} _LummusModelEditDtoProps
	 * @property {string} state_file
	 * @property {number} driverLog
	 * @property {string} simulation_id
	 * @property {LummusModelComponentEditDto[]} components
	 * @property {LummusModelInputUnitOpEditDto[]} input_unit_ops
	 * @property {LummusModelOutputUnitOpEditDto[]} output_unit_ops
	 * @property {LummusModelInputAttributeEditDto[]} input_attributes
	 * @property {LummusModelAttributeEditDto[]} output_attributes
	 *
	 * @typedef {import("app/admin/model/dto/ModelEditDto").ModelEditDtoProps & _LummusModelEditDtoProps} LummusModelEditDtoProps
	 */
	/**
	 *
	 * @param {LummusModelEditDtoProps} props
	 * @returns {LummusModelEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.state_file = props.state_file;
		this.driverLog = props.driverLog;
		this.simulation_id = props.simulation_id;
		this.components = props.components !== null ? props.components.map(d => new LummusModelComponentEditDto().setup(d)) : [];
		this.input_unit_ops = props.input_unit_ops !== null ? props.input_unit_ops.map(d => new LummusModelInputUnitOpEditDto().setup(d)) : [];
		this.output_unit_ops = props.output_unit_ops !== null ? props.output_unit_ops.map(d => new LummusModelOutputUnitOpEditDto().setup(d)) : [];
		this.input_attributes = props.input_attributes !== null ? props.input_attributes.map(d => new LummusModelInputAttributeEditDto().setup(d)) : [];
		this.output_attributes = props.output_attributes !== null ? props.output_attributes.map(d => new LummusModelAttributeEditDto().setup(d)) : [];
		return this;
	}
}

export default LummusModelEditDto;
