const userHu = {
	userTable: 'Access',
	name: 'Name',
	roleList: 'Role',
	email: 'E-mail',
	registrationTimestamp: 'Registration Time',
	lastLoginTimestamp: 'Last Login Time',
	registrationStatus: 'Status',
	dashboardRoleTable: 'Role',
	title: 'Name',
	adminDescription: 'Description',
	isEnabled: 'Enabled',
	created: 'Created',
	updated: 'Updated',
	dashboardRoleEditForm: 'Role',
	basic: 'Basic',
	servicePermissionList: 'Service Permissions',
	contentPermissionList: 'Content Permissions',
	key: 'Name used by System (optional)',
	orderIndex: 'Position',
	isSuperAdmin: 'Superadmin',
	service: 'Service',
	grantedLevel: 'Grant Level',
	contentType: 'Content Type',
	whatIfGroupList: 'What If Group Access'
};

export default userHu;
