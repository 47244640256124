const schedulerEn = {
	name: 'Name',
	type: 'Type',
	schedulerTable: 'Scheduler',
	'definition.type': 'Type',
	'definition.start_date_time': 'Start date time',
	'definition.last_date_time': 'Last date time',
	ok_cnt: 'Ok count',
	fail_cnt: 'Fail count',
	last_result_timestamp: 'Last result timestamp',
	last_error_timestamp: 'Last error timestamp',
	enabled: 'Enabled',
	'schedule.minute': 'Minute',
	'schedule.hour': 'Hour',
	'schedule.day': 'Day',
	'schedule.month': 'Month',
	'definition.name': 'Name',
	'definition.reader_name': 'Reader name',
	'definition.measurement_name': 'Measurement name',
	'definition.summary_type': 'Summary type',
	'definition.summary_duration': 'Summary duration',
	'definition.summary_duration_unit': 'Summary duration unit',
	measurementName: 'Measurement name',
	disabled: 'Disabled',
	update_enable_confirm_title: 'Update scheduler',
	enable_confirm_text: 'Do you want to enable this scheduler?',
	disable_confirm_text: 'Do you want to disable this scheduler?',
	reader_name: 'Reader name',
	measurement_name: 'Measurement name',
	summary_type: 'Summary type',
	summary_duration: 'Summary duration',
	summary_duration_unit: 'Summary duration unit',
	start_date_time: 'Start date time',
	last_date_time: 'Last date time',
	last_status: 'Last status',
	last_timestamp: 'Last timestamp',
	last_message: 'Last message',
	last_result: 'Last result',
	ts: 'Timestamp'
};

export default schedulerEn;
