import _ from '@lodash';
import { useTranslation } from 'react-i18next';

export default function EditFormGridHeader(props) {
	const { config, fieldConfig } = props;
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const fields = fieldConfig.fields.filter((item, index) => fieldConfig.fields.findIndex(d => d.key === item.key) === index);
	const modifyOnly = fieldConfig.modifyOnly || false;

	return (
		<tr className="border-b-2 sticky top-0 z-10 h-56" style={{ backgroundColor: '#e2e2e2' }}>
			{fieldConfig.orderable && <th className="px-8 text-left">#</th>}
			{fields &&
				fields.map((f, i) => (
					<th className="px-8 text-left" key={`field-${i}`}>
						{!_.isUndefined(f.text) ? f.text : t(`${config.topic}:${f.key}`)}
						{f.required ? '*' : ''}
					</th>
				))}
			{!modifyOnly && (
				<th className="px-8" style={{ width: '56px' }}>
					{t('DELETE')}
				</th>
			)}
		</tr>
	);
}
