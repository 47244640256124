import { authRoles } from 'app/auth';
import LummusModelEditForm from './editform/LummusModelEditForm';

export default [
	{
		path: '/model-lummus/edit/:id/:driverName?',
		component: LummusModelEditForm,
		auth: authRoles.model
	}
];
