import _ from 'lodash';
import NamedDto from 'modules/base/dto/NamedDto';
import PiMeasurementReaderDefinitionDto from './PiMeasurementReaderDefinitionDto';
import SchedulerResultDto from './SchedulerResultDto';

export default class SchedulerTableDto extends NamedDto {
	constructor() {
		super();
		/** @type {Date} */
		this.ts = null;
		/** @type {boolean} */
		this.enabled = null;
		/** @type {string} */
		this.schedule = null;
		/** @type {string} */
		this.type = null;
		/** @type {number} */
		this.ok_cnt = null;
		/** @type {number} */
		this.fail_cnt = null;
		/** @type {PiMeasurementReaderDefinitionDto} */
		this.definition = null;
		/** @type {string} */
		this.last_status = null;
		/** @type {Date} */
		this.last_timestamp = null;
		/** @type {string} */
		this.last_message = null;
		/** @type {object} */
		this.last_result = null;
	}

	/**
	 * @typedef {Object} _SchedulerTableDtoProps
	 * @property {Date} ts
	 * @property {boolean} enabled
	 * @property {string} schedule
	 * @property {string} type
	 * @property {number} ok_cnt
	 * @property {number} fail_cnt
	 * @property {PiMeasurementReaderDefinitionDto} definition
	 * @property {string} last_status
	 * @property {Date} last_timestamp
	 * @property {string} last_message
	 * @property {object} last_result
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _SchedulerTableDtoProps} SchedulerTableDtoProps
	 */
	/**
	 *
	 * @param {SchedulerTableDtoProps} props
	 * @returns {SchedulerTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.ts = props.ts !== null ? new Date(props.ts) : null;
		this.enabled = props.enabled != null ? props.enabled : false;
		this.schedule = props.schedule;
		this.type = props.type;
		this.ok_cnt = props.ok_cnt;
		this.fail_cnt = props.fail_cnt;
		this.definition = props.definition != null ? new PiMeasurementReaderDefinitionDto().setup(props.definition) : null;
		this.last_status = props.last_status;
		this.last_timestamp = props.last_timestamp !== null ? new Date(props.last_timestamp) : null;
		this.last_message = props.last_message;
		//this.last_result = props.last_result != null ? new SchedulerResultDto().setup(props.last_result) : null;
		this.last_result = props.last_result;
		return this;
	}
}
