import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import MeasurementTableService from '../service/MeasurementTableService';

const config = {
	key: 'measurementTable',
	topic: 'measurement',
	service: MeasurementTableService,
	editFormState: item => `/measurement/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['search', 'refresh', 'new'],
	rowAction: ['modify', 'delete'],
	columns: [
		{
			key: 'name'
		}
	]
};

function MeasurementTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('measurementTable', reducer('measurementTable'))(MeasurementTable);
