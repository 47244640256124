import { useState } from 'react';
import _ from '@lodash';
import * as yup from 'yup';
import AspectService from 'app/admin/aspect/service/AspectService';
import { Controller, useFormContext } from 'react-hook-form';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import { Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useWorkflow } from '../../WorkflowProvider';
import { TransInputFieldsPropertiesArray, TransNameField, TransValidationName } from '../TransformationProperties';
import TransformationPropertiesDialog from '../TransformationPropertiesDialog';

function AspectProperties(props) {
	const { data, showPropertiesDialog, transformationNameAvailable } = useWorkflow();
	const { t } = useTranslation(['transformer', 'validation']);
	const [tabValue, setTabValue] = useState(0);

	const item = data.transform.find(d => d.key === showPropertiesDialog.key);

	const schema = yup.object().shape({
		name: TransValidationName(transformationNameAvailable, item.key),
		aspect_name: yup.string().nullable().required(t('validation:FIELD_REQUIRED'))
	});

	const handleTabChange = (event, value) => {
		setTabValue(value);
	};

	return (
		<TransformationPropertiesDialog
			schema={schema}
			titleKey="aspectProperties"
			titleContent={
				<Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto" classes={{ root: 'h-64 bg-white' }}>
					<Tab className="h-64" label={t('basicInfo')} />
					<Tab className="h-64" label={t('input_fields')} />
				</Tabs>
			}
		>
			<div className={tabValue !== 0 ? 'hidden' : 'mt-16'}>
				<TransNameField />
				<AspectSelectField />
			</div>
			<div className={tabValue !== 1 ? 'hidden' : 'mt-16'}>
				<TransInputFieldsPropertiesArray disableWatch />
			</div>
		</TransformationPropertiesDialog>
	);
}

function AspectSelectField() {
	const methods = useFormContext();
	return (
		<Controller
			control={methods.control}
			name="aspect_name"
			render={({ field, fieldState }) => (
				<EditFormSelectField
					field={field}
					fieldState={fieldState}
					config={{ topic: 'transformer' }}
					fieldConfig={{
						key: 'aspect_name',
						type: 'enum',
						required: true,
						items: () => AspectService.getListSelectionEnum()
					}}
				/>
			)}
		/>
	);
}

export default AspectProperties;
