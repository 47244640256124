import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { Tooltip, IconButton } from '@material-ui/core';
import { GiBroom } from 'react-icons/gi';
import ConfirmDialog from 'modules/ui/component/ConfirmDialog';
import TaskManagerTableService from '../service/TaskManagerTableService';

function DeleteErrorneousButton(props) {
	const dispatch = useDispatch();
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleDelete = () => {
		setLoading(true);
		TaskManagerTableService.deleteErroneous()
			.then(resp => {
				setLoading(false);
				if (resp != null) {
					dispatch(showMessage({ message: `Engine: ${resp}` }));
				} else {
					dispatch(showMessage({ message: `Successfully cleaned.` }));
					dispatch({ type: `${props.config.key}/event/setNeedDataReload` });
				}
			})
			.catch(resp => {
				setLoading(false);
				dispatch(showMessage({ message: `Engine: ${resp}` }));
				dispatch({ type: `${props.config.key}/event/setNeedDataReload` });
			});
	};

	return (
		<>
			<Tooltip title="Delete erroneous">
				<IconButton onClick={() => setShowDeleteConfirm(true)} disabled={loading}>
					<GiBroom fontSize="small" />
				</IconButton>
			</Tooltip>
			<ConfirmDialog show={showDeleteConfirm} title="Delete" description="Do you want to clean this list?" onYes={() => handleDelete()} onClose={() => setShowDeleteConfirm(false)} />
		</>
	);
}
export default DeleteErrorneousButton;
