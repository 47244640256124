import { authRoles } from 'app/auth';
import OptimizationPipeline from './OptimizationPipeline';
import OptimizationPipelineProvider from './component/OptimizationPipelineProvider';

export default [
	{
		path: '/optimization-pipeline',
		component: () => (<OptimizationPipelineProvider><OptimizationPipeline /></OptimizationPipelineProvider>),
		auth: authRoles.taskManager
	}
];
