import IOType from 'app/admin/common/dto/IOType';

import _ from '@lodash';
import { useState } from 'react';
import {
	TableContainer,
	Table,
	TableHead,
	TableCell,
	TableRow,
	TableBody,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Radio,
	RadioGroup,
	FormControlLabel,
	InputAdornment,
	Icon,
	IconButton,
	TextField,
	Checkbox,
	Tooltip
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { formatLabelValue } from '../WhatIfUtil';
import CompareViewComponent from './CompareViewComponent';
import { useWhatIf } from '../WhatIfProvider';
import CompareData from './CompareData';

export default function ExperimentCompare(props) {
	const { data } = useWhatIf();
	const { experimentList, style } = props;
	const [baseCase, setBaseCase] = useState(0);
	const [compareType, setCompareType] = useState('diff');
	const [queryText, setQueryText] = useState('');
	const compareView = _.get(data, 'properties.compareView', false);
	const [filteredDiffGroupList, setFilteredDiffGroupList] = useState([]);

	const compareData = CompareData(experimentList, baseCase, compareView, queryText, filteredDiffGroupList);

	return (
		<>
			<TableContainer component={Paper}>
				<Table className="w-full">
					<TableHead>
						<TableRow className="bg-gray-300">
							<TableCell align="left">Model Compare</TableCell>
							<TableCell align="left">
								<TextField
									variant="outlined"
									label="Search attribute..."
									className="flex-grow mr-16"
									value={queryText}
									onChange={e => setQueryText(e.target.value)}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Icon className="text-20" color="action">
													search
												</Icon>
											</InputAdornment>
										),
										endAdornment:
											queryText.length > 0 ? (
												<InputAdornment position="end">
													<IconButton onClick={() => setQueryText('')}>
														<Icon className="text-20" color="action">
															clear
														</Icon>
													</IconButton>
												</InputAdornment>
											) : null
									}}
								/>
							</TableCell>
							<TableCell colSpan={experimentList.length} align="right">
								<div className="flex flex-row justify-end items-center">
									<CompareViewComponent />
									<FormControl>
										<RadioGroup row aria-label="compareType" name="compareType" value={compareType} onChange={e => setCompareType(e.target.value)}>
											<FormControlLabel value="value" control={<Radio color="primary" />} label="Value" />
											<FormControlLabel value="diff" control={<Radio color="primary" />} label="Diff" />
										</RadioGroup>
									</FormControl>
									<FormControl variant="outlined" style={{ minWidth: '120px' }}>
										<InputLabel id="base-case-label">Base case</InputLabel>
										<Select labelId="base-case-label" value={baseCase} onChange={e => setBaseCase(e.target.value)} label="Base case">
											{experimentList.map((exp, row) => (
												<MenuItem key={exp.id} value={row}>
													{exp.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
							</TableCell>
						</TableRow>
						<TableRow className="bg-gray-300">
							<TableCell>Parameters</TableCell>
							<TableCell>uom</TableCell>
							{compareData.headerList.map(headerName => (
								<TableCell align="right">{headerName}</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{compareView !== false &&
							compareData.groupList.map(group => (
								<>
									<TableRow key={group.key}>
										<TableCell colSpan={experimentList.length + 2} className="font-bold bg-gray-100">
											<Tooltip title="Show only there is a difference" aria-label="toggle">
												<Checkbox
													checked={filteredDiffGroupList.indexOf(group.key) > -1}
													onChange={() =>
														filteredDiffGroupList.indexOf(group.key) > -1
															? setFilteredDiffGroupList(filteredDiffGroupList.filter(k => k !== group.key))
															: setFilteredDiffGroupList([...filteredDiffGroupList, group.key])
													}
												/>
											</Tooltip>
											{group.name}
										</TableCell>
									</TableRow>
									{group.data.map((row, rowNum) => (
										<>
											{row.hasSub && !row.isSub && (
												<TableRow key={rowNum} className={`${(row + 1) % 2 === 0 ? 'bg-gray-100' : ''}`}>
													<TableCell colSpan={experimentList.length + 2} className="font-bold">
														{row.name}
													</TableCell>
												</TableRow>
											)}
											{((!row.hasSub && !row.isSub) || row.isSub) && (
												<TableRow key={row} className={`${(row + 1) % 2 === 0 ? 'bg-gray-100' : ''}`}>
													<TableCell className={row.isSub ? 'pl-36' : ''}>
														{row.name}
														{!row.isSub ? ` ${IOType[row.io]}` : ''}
													</TableCell>
													<TableCell>{row.uom}</TableCell>
													{row.values.map((value, vIdx) => (
														<TableCell align="right">
															{row.isSub
																? formatLabelValue(compareType === 'diff' && vIdx !== baseCase ? row.diffs[vIdx] : value, style.componentStyle)
																: formatLabelValue(compareType === 'diff' && vIdx !== baseCase ? row.diffs[vIdx] : value, style.variableStyle)}
														</TableCell>
													))}
												</TableRow>
											)}
										</>
									))}
								</>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}
