const commonEn = {
	YES: 'Yes',
	NO: 'No',
	CANCEL: 'Cancel',
	SAVE: 'Save',
	LOADING: 'Loading...',
	close: 'Close',
	load_from_value: 'Load from value',
	copy: 'Copy',
	notifications: 'Notifications',
	delete_all: 'Delete all',
	emptyNotifications: 'There are no notifications for now.'
};

export default commonEn;
