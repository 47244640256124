import { BsSendFill } from 'react-icons/bs';
import { useState } from 'react';
import { TextField, IconButton } from '@material-ui/core';
import WhatIfNoteEditService from '../service/WhatIfNoteEditService';
import WhatIfNoteEditDto from '../dto/WhatIfNoteEditDto';

function NoteItemInput(props) {
	const { noteItemKey, whatIf } = props;
	const [messageText, setMessageText] = useState('');
	const [loading, setLoading] = useState(false);

	function onInputChange(ev) {
		setMessageText(ev.target.value);
	}

	const sendTextMessage = () => {
		setLoading(true);
		WhatIfNoteEditService.save(
			new WhatIfNoteEditDto().setup({
				noteItemKey,
				whatIf,
				creator: null,
				created: new Date(),
				note: messageText
			})
		)
			.then(resp => {
				setLoading(false);
				if (resp.successful) {
					setMessageText('');
					props.onChange(resp);
				}
			})
			.catch(resp => {
				setLoading(false);
			});
	};

	return (
		<div className="w-full bg-white flex flex-row gap-6">
			<TextField className="flex-1" multiline variant="outlined" placeholder="please write your comment here" label="Your comment" onChange={onInputChange} value={messageText} />
			<div className="flex items-end justify-center pb-4">
				<IconButton variant="contained" color="primary" onClick={() => sendTextMessage(messageText)} disabled={loading || messageText.length === 0}>
					<BsSendFill size="20px" />
				</IconButton>
			</div>
		</div>
	);
}
export default NoteItemInput;
