const measurementHu = {
	measurementTable: 'Measurement',
	name: 'Név',
	measurementEditForm: 'Measurement',
	basic: 'Alapadatok',
	table_name: 'Tábla neve',
	description: 'Leírás',
	fields: 'Mezők',
	role: 'Szabály',
	type: 'Típus'
};

export default measurementHu;
