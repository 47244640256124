import nBossConnection from 'modules/base/service/nBossConnection';
import OcuEntityValidationResult from 'app/admin/common/dto/OcuEntityValidationResult';
import SchedulerEditDto from '../dto/SchedulerEditDto';

export const convertScheduleDto = dto => {
	const numberRegex = new RegExp('^[0-9]{1,2}$');
	dto.minute = numberRegex.test(dto.minute) ? Number.parseInt(dto.minute, 10) : null;
	dto.hour = numberRegex.test(dto.hour) ? Number.parseInt(dto.hour, 10) : null;
	dto.day = numberRegex.test(dto.day) ? Number.parseInt(dto.day, 10) : null;
	dto.month = numberRegex.test(dto.month) ? Number.parseInt(dto.month, 10) : null;
	return dto;
};

export const parseScheduleDto = dto => {
	dto.minute = dto.minute == null ? '*' : String(dto.minute);
	dto.hour = dto.hour == null ? '*' : String(dto.hour);
	dto.day = dto.day == null ? '*' : String(dto.day);
	dto.month = dto.month == null ? '*' : String(dto.month);
	return dto;
};
class SchedulerEditService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'schedulerEditControl';
	}

	/**
	 * @return {Promise<SchedulerEditDto>}
	 */
	create() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/create`)
				.then(response => resolve(new SchedulerEditDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<SchedulerEditDto>}
	 */
	getData(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getData`, {
					id
				})
				.then(response => resolve(new SchedulerEditDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {SchedulerEditDto} dto
	 * @return {Promise<OcuEntityValidationResult>}
	 */
	save(dto) {
		const _dto = { ...dto, schedule: convertScheduleDto(dto.schedule) };
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/save`, {
					dto: _dto
				})
				.then(response => resolve(new OcuEntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @param {SchedulerEditDto} dto
	 * @return {Promise<OcuEntityValidationResult>}
	 */
	update(id, dto) {
		const _dto = { ...dto, schedule: convertScheduleDto(dto.schedule) };
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/update`, {
					id,
					dto: _dto
				})
				.then(response => resolve(new OcuEntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}
}

export default new SchedulerEditService();
