import ModelEditDto from 'app/admin/model/dto/ModelEditDto';
import Lummus2ModelInputUnitOpEditDto from './Lummus2ModelInputUnitOpEditDto';
import Lummus2ModelOutputUnitOpEditDto from './Lummus2ModelOutputUnitOpEditDto';
import Lummus2ModelInputAttributeEditDto from './Lummus2ModelInputAttributeEditDto';
import Lummus2ModelAttributeEditDto from './Lummus2ModelAttributeEditDto';
import Lummus2ModelComponentEditDto from './Lummus2ModelComponentEditDto';

export class Lummus2ModelEditDto extends ModelEditDto {
	constructor() {
		super();
		/** @type {string} */
		this.state_file = null;
		/** @type {number} */
		this.driverLog = null;
		/** @type {string} */
		this.simulation_id = null;
		/** @type {Lummus2ModelComponentEditDto[]} */
		this.components = null;
		/** @type {Lummus2ModelInputUnitOpEditDto[]} */
		this.input_unit_ops = null;
		/** @type {Lummus2ModelOutputUnitOpEditDto[]} */
		this.output_unit_ops = null;
		/** @type {Lummus2ModelInputAttributeEditDto[]} */
		this.input_attributes = null;
		/** @type {Lummus2ModelAttributeEditDto[]} */
		this.output_attributes = null;
		/** @type {string} */
		this.subscription_key = null;
	}

	/**
	 * @typedef {Object} _Lummus2ModelEditDtoProps
	 * @property {string} state_file
	 * @property {number} driverLog
	 * @property {string} simulation_id
	 * @property {Lummus2ModelComponentEditDto[]} components
	 * @property {Lummus2ModelInputUnitOpEditDto[]} input_unit_ops
	 * @property {Lummus2ModelOutputUnitOpEditDto[]} output_unit_ops
	 * @property {Lummus2ModelInputAttributeEditDto[]} input_attributes
	 * @property {Lummus2ModelAttributeEditDto[]} output_attributes
	 * @property {string} subscription_key
	 *
	 * @typedef {import("app/admin/model/dto/ModelEditDto").ModelEditDtoProps & _Lummus2ModelEditDtoProps} Lummus2ModelEditDtoProps
	 */
	/**
	 *
	 * @param {Lummus2ModelEditDtoProps} props
	 * @returns {Lummus2ModelEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.state_file = props.state_file;
		this.driverLog = props.driverLog;
		this.simulation_id = props.simulation_id;
		this.components = props.components !== null ? props.components.map(d => new Lummus2ModelComponentEditDto().setup(d)) : [];
		this.input_unit_ops = props.input_unit_ops !== null ? props.input_unit_ops.map(d => new Lummus2ModelInputUnitOpEditDto().setup(d)) : [];
		this.output_unit_ops = props.output_unit_ops !== null ? props.output_unit_ops.map(d => new Lummus2ModelOutputUnitOpEditDto().setup(d)) : [];
		this.input_attributes = props.input_attributes !== null ? props.input_attributes.map(d => new Lummus2ModelInputAttributeEditDto().setup(d)) : [];
		this.output_attributes = props.output_attributes !== null ? props.output_attributes.map(d => new Lummus2ModelAttributeEditDto().setup(d)) : [];
		this.subscription_key = props.subscription_key;
		return this;
	}
}

export default Lummus2ModelEditDto;
