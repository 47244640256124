import _ from '@lodash';
import { useWorkflow } from 'app/admin/workflow/component/WorkflowProvider';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

function inputChanged(input, value) {
	if (input.name !== value.valueKey) return true;
	if (input.type !== value.valueType) return true;
	if (input.value !== value.value) return true;
	return false;
}

function outputChanged(output, value) {
	if (output.name !== value.valueKey) return true;
	if (output.type !== value.valueType) return true;
	return false;
}

export default function WorkflowDataUpdater() {
	const { data } = useWorkflow();
	const { getValues, setValue } = useFormContext();
	const [dataValue, setDataValue] = useState(null);
	useEffect(() => {
		if (data && data.value) {
			const _dataValue = JSON.stringify(data.value);
			if (dataValue == null || data.value !== dataValue) {
				/*console.log(dataValue);
				console.log(_dataValue);
				console.log('values changed');*/
				const inputAttributes = getValues('input_attributes');
				let inChanged = false;
				if (_.isArray(inputAttributes)) {
					const delInIdx = [];
					inputAttributes.forEach((inp, idx) => {
						if (data.value.findIndex(v => v.key === inp.key) === -1) delInIdx.push(idx);
					});
					if (delInIdx.length > 0) {
						delInIdx.sort().forEach((idx, ct) => inputAttributes.splice(idx - ct, 1));
						inChanged = true;
					}
					data.value.forEach(val => {
						if (val.io === 'INPUT') {
							const input = inputAttributes.find(inp => inp.key === val.key);
							if (input) {
								if (inputChanged(input, val)) {
									input.name = val.valueKey;
									input.type = val.valueType;
									input.value = val.value;
									inChanged = true;
								}
							} else {
								inputAttributes.push({
									id: null,
									name: val.valueKey,
									key: val.key,
									type: val.valueType,
									value: val.value
								});
								inChanged = true;
							}
						}
					});
					if (inChanged) {
						setValue('input_attributes', inputAttributes);
					}
				}
				const outputAttributes = getValues('output_attributes');
				let outChanged = false;
				if (_.isArray(outputAttributes)) {
					const delInIdx = [];
					outputAttributes.forEach((inp, idx) => {
						if (data.value.findIndex(v => v.key === inp.key) === -1) delInIdx.push(idx);
					});
					if (delInIdx.length > 0) {
						console.log(outputAttributes);
						delInIdx.sort().forEach((idx, ct) => outputAttributes.splice(idx - ct, 1));
						outChanged = true;
					}
					data.value.forEach(val => {
						if (val.io === 'OUTPUT') {
							const output = outputAttributes.find(inp => inp.key === val.key);
							if (output) {
								if (outputChanged(output, val)) {
									output.name = val.valueKey;
									output.type = val.valueType;
									outChanged = true;
								}
							} else {
								outputAttributes.push({
									id: null,
									name: val.valueKey,
									key: val.key,
									type: val.valueType
								});
								outChanged = true;
							}
						}
					});
					if (outChanged) {
						setValue('output_attributes', outputAttributes);
					}
				}
				setDataValue(_dataValue);
			}
		}
	}, [data]);

	if (data && data.valuen && dataValue == null) {
		setDataValue(JSON.stringify(data.value));
	}

	return <></>;
}
