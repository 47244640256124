import OcuEntityValidationResult from 'app/admin/common/dto/OcuEntityValidationResult';
import nBossConnection from 'modules/base/service/nBossConnection';
import OptimizationCaseEditDto from '../dto/OptimizationCaseEditDto';

class OptimizationCaseEditService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'optimizationCaseEditControl';
	}

	/**
	 * @return {Promise<OptimizationCaseEditDto>}
	 */
	create() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/create`)
				.then(response => resolve(new OptimizationCaseEditDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<OptimizationCaseEditDto>}
	 */
	getData(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getData`, {
					id
				})
				.then(response => resolve(new OptimizationCaseEditDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {OptimizationCaseEditDto} dto
	 * @return {Promise<OcuEntityValidationResult>}
	 */
	save(dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/save`, {
					dto
				})
				.then(response => resolve(new OcuEntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @param {OptimizationCaseEditDto} dto
	 * @return {Promise<OcuEntityValidationResult>}
	 */
	update(id, dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/update`, {
					id,
					dto
				})
				.then(response => resolve(new OcuEntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<Object>}
	 */
	getFormData() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getFormData`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new OptimizationCaseEditService();
