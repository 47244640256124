import _ from '@lodash';
import WorkflowItem from '../WorkflowItem';

const convertExpressions = expressionList => {
	const result = {};
	if (expressionList) {
		expressionList.forEach(d => {
			result[d.key] = d.value;
		});
	}
	return result;
};

export const defaultDefinition = {
	name: null,
	input_fields: [],
	output_fields: [],
	expressionList: [],
	feature_column: null,
	component_title: null
};

export const converter = function (data, isExport) {
	return {
		...data,
		input_fields: isExport ? data.input_fields : [],
		output_fields: isExport ? data.output_fields : [],
		/*input_fields: data.expressionList.map(exp => {
			return { name: exp.value, type: 'Variable' };
		}),
		output_fields: data.expressionList.map(exp => {
			return { name: exp.key, type: 'Variable' };
		}),*/
		expressions: convertExpressions(data.expressionList)
	};
};

export default function ExpressionsTransformation(props) {
	return <WorkflowItem {...props} type="EXPRESSION" />;
}
