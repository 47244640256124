import TableFilterListDto from 'modules/base/dto/table/TableFilterListDto';
import { useParams, withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import _ from '@lodash';
import { useEffect, useState, useRef } from 'react';
import { Checkbox, Button, ThemeProvider, Dialog, DialogContent, DialogActions, DialogTitle, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Tooltip, IconButton, Tabs, Tab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import LoadIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import ValueStyleConfigFields from 'app/admin/common/component/ValueStyleConfigFields';
import defaultLabelStyle from 'app/admin/common/component/DefaultLabelStyle';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import ConfirmDialog from 'modules/ui/component/ConfirmDialog';
import { BsClockHistory } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { useWhatIf } from './WhatIfProvider';
import { generateId } from './WhatIfUtil';
import WhatIfExperimentTableService from '../service/WhatIfExperimentTableService';
import WhatIfExperimentEditDto from '../dto/WhatIfExperimentEditDto';
import WhatIfExperimentEditService from '../service/WhatIfExperimentEditService';
import ExperimentCompare from './compare/ExperimentCompare';
import CompareViewComponent from './compare/CompareViewComponent';

const defaultStyle = {
	variableStyle: defaultLabelStyle(false),
	componentStyle: defaultLabelStyle(true)
};

export default withRouter(function WhatIfExperiment() {
	const [tabValue, setTabValue] = useState(0);
	const { id } = useParams();
	const { t } = useTranslation(['whatIf', 'tableBrowser']);
	const { data, setExperiment, setCompare } = useWhatIf();
	const [sessionId, setSessionId] = useState(null);
	const [loading, setLoading] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [viewMode, setViewMode] = useState('LIST');
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [experimentList, setStateList] = useState([]);
	const [indeterminate, setIndeterminate] = useState(false);
	const [selected, setSelected] = useState([]);
	const styleRef = useRef({ ...defaultStyle });
	const mainTheme = useSelector(selectMainTheme);

	useEffect(() => {
		if (showDialog) {
			setLoading(true);
			if (sessionId === null) {
				const newId = generateId();
				const connectFilter = [];
				if (data.experiment != null && data.experiment.aspect != null) {
					connectFilter.push({
						propertyName: 'aspect',
						propertyType: 'Long',
						type: 'EQ',
						value: data.experiment.aspect
					});
				} else if (data.experiment != null && data.experiment.transformer != null) {
					connectFilter.push({
						propertyName: 'transformer',
						propertyType: 'Long',
						type: 'EQ',
						value: data.experiment.transformer
					});
				}
				WhatIfExperimentTableService.getInstance(
					newId,
					true,
					new TableFilterListDto().setup({
						list: [
							{
								propertyName: 'whatIf',
								propertyType: 'Long',
								type: 'EQ',
								value: Number.parseInt(id, 10)
							},
							...connectFilter
						],
						activeCount: 2
					})
				).then(resp => {
					setSessionId(newId);
				});
			} else {
				getList();
			}
		}
	}, [showDialog]);

	useEffect(() => {
		if (sessionId) getList();
	}, [sessionId]);

	const getList = () => {
		WhatIfExperimentTableService.getList(sessionId, 0, 0).then(result => {
			setStateList(result.data || []);
			setLoading(false);
		});
	};

	const schema = yup.object().shape({
		name: yup.string().nullable().required(t('validation:FIELD_REQUIRED'))
	});

	const methods = useForm({
		defaultValues: { name: null },
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const handleSave = dto => {
		const result = new WhatIfExperimentEditDto().setup({
			...data.experiment,
			name: dto.name,
			whatIf: id
		});
		WhatIfExperimentEditService.save(result).then(() => {
			getList();
			setViewMode('LIST');
		});
	};

	const handleDelete = rowId => {
		setShowDeleteConfirm(false);
		setLoading(true);
		WhatIfExperimentTableService.deleteById(sessionId, rowId).then(() => getList());
	};

	const loadExperiment = item => {
		setExperiment({
			id: item.id,
			name: item.name,
			aspect: item.aspect ? item.aspect.id : null,
			transformer: item.transformer ? item.transformer.id : null,
			whatIf: item.whatIf,
			validity: _.isBoolean(item.validity) ? item.validity : true,
			attributes: item.attributes
		});
		setShowDialog(false);
	};

	const handleSelectedChange = experimentId => {
		if (selected.indexOf(experimentId) > -1) setSelected(selected.filter(d => d !== experimentId));
		else setSelected([...selected, experimentId]);
	};

	const handleSelectAllClick = () => {
		if (selected.length > 0 && selected.length === experimentList.length) {
			setSelected([]);
		} else {
			setSelected(experimentList.map(d => d.id));
		}
	};

	const setCompareOnScreen = () => {
		if (selected.length === 1) {
			setCompare(experimentList.find(e => e.id === selected[0]));
			setShowDialog(false);
		}
	};

	return (
		<>
			<Button
				className="whitespace-nowrap mx-4"
				variant="contained"
				color="secondary"
				onClick={() => {
					setShowDialog(true);
				}}
				startIcon={<BsClockHistory className="hidden sm:flex" />}
			>
				Experiments
			</Button>
			<ThemeProvider theme={mainTheme}>
				<Dialog open={showDialog} maxWidth="lg" fullWidth>
					<DialogTitle disableTypography className="border-b">
						What If experiments
						{viewMode === 'COMPARE' && (
							<Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} aria-label="compare-properties-tabs">
								<Tab label="Result" />
								<Tab label="Label styles" />
							</Tabs>
						)}
					</DialogTitle>
					<DialogContent className="p-20">
						<FormProvider {...methods}>
							{loading && <FuseLoading />}
							{!loading && viewMode === 'COMPARE' && tabValue === 0 && <ExperimentCompare experimentList={selected.map(selectedId => experimentList.find(e => e.id === selectedId))} style={styleRef.current} />}
							{!loading && viewMode === 'COMPARE' && tabValue === 1 && (
								<CompareStyle
									value={{ ...defaultStyle }}
									t={t}
									onChange={value => {
										styleRef.current = value;
									}}
								/>
							)}
							{!loading && viewMode === 'LIST' && (
								<TableContainer component={Paper}>
									<Table className="w-full" aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell padding="checkbox">
													<Checkbox
														indeterminate={indeterminate}
														checked={selected.length > 0 && selected.length === experimentList.length}
														onChange={() => handleSelectAllClick()}
														disabled={experimentList.length === 0}
													/>
												</TableCell>
												<TableCell>Name</TableCell>
												<TableCell>Creator</TableCell>
												<TableCell align="right">Actions</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{experimentList.length > 0 ? (
												experimentList.map(row => (
													<TableRow key={row.name}>
														<TableCell padding="checkbox">
															<Checkbox checked={selected.indexOf(row.id) > -1} onChange={() => handleSelectedChange(row.id)} />
														</TableCell>
														<TableCell component="th" scope="row">
															{row.name}
														</TableCell>
														<TableCell>{row.creator ? row.creator.name : ''}</TableCell>
														<TableCell className="font-mono" align="right">
															<Tooltip title="Load experiment">
																<span>
																	<IconButton aria-label="load" onClick={e => loadExperiment(row)}>
																		<LoadIcon fontSize="small" />
																	</IconButton>
																</span>
															</Tooltip>
															<Tooltip title={t('tableBrowser:DELETE')}>
																<span>
																	<IconButton
																		aria-label="delete"
																		onClick={e => {
																			setShowDeleteConfirm(true);
																			e.preventDefault();
																		}}
																	>
																		<DeleteIcon fontSize="small" />
																	</IconButton>
																</span>
															</Tooltip>
															<ConfirmDialog
																show={showDeleteConfirm}
																title={t('tableBrowser:DELETE')}
																description={t('tableBrowser:DELETE_DESC')}
																onYes={() => handleDelete(row.id)}
																onClose={() => setShowDeleteConfirm(false)}
															/>
														</TableCell>
													</TableRow>
												))
											) : (
												<TableRow>
													<TableCell component="th" scope="row" colSpan={4} align="center">
														No experiments available
													</TableCell>
												</TableRow>
											)}
										</TableBody>
									</Table>
								</TableContainer>
							)}
							{!loading && viewMode === 'SAVE' && (
								<Controller
									control={methods.control}
									name="name"
									render={({ field, fieldState }) => (
										<EditFormTextField
											field={field}
											fieldState={fieldState}
											config={{ topic: 'whatIf' }}
											fieldConfig={{
												key: 'name',
												text: t('name'),
												required: true
											}}
										/>
									)}
								/>
							)}
						</FormProvider>
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						{(viewMode === 'LIST' || viewMode === 'COMPARE') && (
							<Button
								onClick={() => {
									if (viewMode === 'COMPARE') setViewMode('LIST');
									else setShowDialog(false);
								}}
								disabled={loading}
							>
								Close
							</Button>
						)}
						{viewMode === 'SAVE' && (
							<Button
								onClick={() => {
									setViewMode('LIST');
								}}
								disabled={loading}
							>
								Cancel
							</Button>
						)}
						{viewMode === 'SAVE' && (
							<Button onClick={methods.handleSubmit(handleSave)} disabled={loading}>
								Save
							</Button>
						)}
						{viewMode === 'LIST' && <CompareViewComponent btnType="simple" />}
						{viewMode === 'LIST' && (
							<Button onClick={() => setViewMode('COMPARE')} disabled={selected.length < 2}>
								Compare
							</Button>
						)}
						{viewMode === 'LIST' && (
							<Button onClick={() => setCompareOnScreen()} disabled={selected.length !== 1}>
								Compare on screen
							</Button>
						)}
						{viewMode === 'LIST' && (
							<Button onClick={() => setViewMode('SAVE')} disabled={loading}>
								Save new experiment
							</Button>
						)}
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</>
	);
});

function CompareStyle(props) {
	const { t, value, onChange } = props;
	const methods = useForm({
		defaultValues: value,
		mode: 'onChange'
	});
	const watchAllFields = methods.watch();

	useEffect(() => {
		onChange(methods.getValues());
	}, [watchAllFields]);

	return (
		<FormProvider {...methods}>
			<ValueStyleConfigFields prefix="variableStyle" title="Variable" t={t} methods={methods} />
			<ValueStyleConfigFields prefix="componentStyle" title="Component" t={t} methods={methods} />
		</FormProvider>
	);
}
