import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './i18n/en';
import hu from './i18n/hu';
import DriverLogTable from './table/DriverLogTable';
import DriverLogEditForm from './editform/DriverLogEditForm';

i18next.addResourceBundle('en', 'driverLog', en);
i18next.addResourceBundle('hu', 'driverLog', hu);

export default [
	{
		path: '/driver-log/edit/:id/:tabKey?',
		component: DriverLogEditForm,
		auth: authRoles.driverLog
	},
	{
		path: '/driver-log/:sessionNameParam?',
		component: DriverLogTable,
		auth: authRoles.driverLog
	}
];
