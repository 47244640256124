import NamedDto from 'modules/base/dto/NamedDto';
import { fixExperimentAttributes } from './WhatIfExperimentTableDto';

export class WhatIfExperimentEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {number} */
		this.aspect = null;
		/** @type {number} */
		this.transformer = null;
		/** @type {number} */
		this.whatIf = null;
		/** @type {number} */
		this.creator = null;
		/** @type {Object[]} */
		this.attributes = null;
	}

	/**
	 * @typedef {Object} _WhatIfExperimentEditDtoDtoProps
	 * @property {number} aspect
	 * @property {number} transformer
	 * @property {number} whatIf
	 * @property {number} creator
	 * @property {Object[]} attributes
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _WhatIfExperimentEditDtoDtoProps} WhatIfExperimentEditDtoDtoProps
	 */
	/**
	 *
	 * @param {WhatIfExperimentEditDtoDtoProps} props
	 * @returns {WhatIfExperimentEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.aspect = props.aspect;
		this.transformer = props.transformer;
		this.whatIf = props.whatIf;
		this.creator = props.creator;
		this.attributes = fixExperimentAttributes(props.attributes);
		return this;
	}
}

export default WhatIfExperimentEditDto;
