import NamedDto from 'modules/base/dto/NamedDto';
import WhatIfCompareViewGroupEditDto from './WhatIfCompareViewGroupEditDto';

export class WhatIfCompareViewTableDto extends NamedDto {
	constructor() {
		super();
		/** @type {NamedDto} */
		this.creator = null;
		/** @type {Date} */
		this.created = null;
		/** @type {WhatIfCompareViewGroupEditDto[]} */
		this.groupList = null;
	}

	/**
	 * @typedef {Object} _WhatIfCompareViewTableDtoProps
	 * @property {NamedDto} creator
	 * @property {Date} created
	 * @property {WhatIfCompareViewGroupEditDto[]} groupList
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _WhatIfCompareViewTableDtoProps} WhatIfCompareViewTableDtoProps
	 */
	/**
	 *
	 * @param {WhatIfCompareViewTableDtoProps} props
	 * @returns {WhatIfCompareViewTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.creator = props.creator != null ? new NamedDto().setup(props.creator) : null;
		this.created = props.created != null ? new Date(props.created) : null;
		this.groupList = props.groupList != null ? props.groupList.map(d => new WhatIfCompareViewGroupEditDto().setup(d)) : [];
		return this;
	}
}

export default WhatIfCompareViewTableDto;
