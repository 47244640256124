import nBossConnection from 'modules/base/service/nBossConnection';
import { useRef, useState, useEffect } from 'react';
import { Group, Text, Rect, Label, Image } from 'react-konva';
import { useWhatIf } from '../component/WhatIfProvider';
import { getScaledImageCoordinates } from '../component/WhatIfUtil';
import PropertiesSvgBtn from './PropertiesSvgBtn';

const itemBorder = 6;
const iconBorder = 12;

function IconOnlyView(props) {
	const { getIconsetKey, iconset } = useWhatIf();
	const { item, isDragging, enablePropertiesBtn, onPropertiesClick } = props;
	const [image, setImage] = useState(null);
	const imgRef = useRef(null);
	const imageDimension = image ? getScaledImageCoordinates(item.iconSize, item.iconSize, image.width, image.height) : { width: 0, height: 0 };

	const dimension = image ? { width: imageDimension.width + iconBorder * 2 + itemBorder * 2, height: imageDimension.height + iconBorder * 2 + itemBorder * 2 } : { width: 0, height: 0 };

	useEffect(() => {
		const imageKey = getIconsetKey(item.icon);
		if (imageKey != null) {
			const imageObj = new window.Image();
			imageObj.onload = function () {
				setImage(imageObj);
			};
			imageObj.src = `${nBossConnection.baseUrl}/fileRepository/getFile?key=${getIconsetKey(item.icon)}`;
		}
	}, [item, iconset]);

	return (
		<Group
			{...props}
			rotation={item.rotate}
			width={dimension.width}
			height={dimension.height}
			offsetX={dimension.width > 0 ? dimension.width / 2 : 0}
			offsetY={dimension.height > 0 ? dimension.height / 2 : 0}
			opacity={dimension.width > 0 ? 1 : 0}
		>
			<Rect fill={item.borderColor} x={itemBorder} y={itemBorder} width={dimension.width - itemBorder * 2} height={dimension.height - itemBorder * 2} />
			<Image ref={imgRef} x={iconBorder + itemBorder} y={iconBorder + itemBorder} width={imageDimension.width} height={imageDimension.height} image={image} />
			<PropertiesSvgBtn enablePropertiesBtn={enablePropertiesBtn} handleClick={() => onPropertiesClick && onPropertiesClick()} />
			{image && <Rect width={dimension.width} height={dimension.height} dash={[10, 10]} stroke="black" strokeWidth={1} opacity={isDragging ? 1 : 0} listening={false} />}
		</Group>
	);
}

export default IconOnlyView;
