const optimizerHu = {
	optimizerTable: 'Optimizer',
	optimizerEditForm: 'Optimizer',
	name: 'Name',
	type: 'Type',
	imputed_value: 'Imputed value',
	max_iteration_count: 'Max iteration count',
	particle_count: 'Particle count',
	local_search_name: 'Local search name'
};

export default optimizerHu;
