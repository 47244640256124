import format from 'date-fns/format';
import parse from 'date-fns/parse';

export const convertDateToBackingsString = d => {
	return format(d, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
};

export const convertBackingsStringToDate = s => {
	return parse(s, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", new Date());
};
