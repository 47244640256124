import _ from '@lodash';
import { useSelector, useDispatch } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import { ThemeProvider } from '@material-ui/core/styles';
import { useState } from 'react';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { Button, Icon, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import ConfirmDialog from 'modules/ui/component/ConfirmDialog';
import TaskManagerService from 'app/admin/taskManager/service/TaskManagerService';
import { useWhatIf } from './WhatIfProvider';
import { getInvalidValue } from './WhatIfUtil';
import { RenderErrorMessage } from './WhatIfCalculate';

function WhatIfCalculateBackground(props) {
	const mainTheme = useSelector(selectMainTheme);

	const dispatch = useDispatch();
	const { id } = props;
	const { data } = useWhatIf();
	const [loading, setLoading] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [valueErrorList, setValueErrorList] = useState([]);
	const [message, setMessage] = useState(null);

	const handleClick = () => {
		setShowConfirm(false);
		setLoading(true);
		setShowDialog(true);
		if (data.experiment && data.experiment.attributes) {
			const invalidResult = getInvalidValue(data.experiment.attributes);
			if (invalidResult.length > 0) {
				setValueErrorList(invalidResult);
				setLoading(false);
			} else {
				const state = {};
				data.experiment.attributes.forEach(attribute => {
					if (attribute.io === 'INPUT') {
						state[attribute.name] = attribute.value;
					}
				});
				if (data.experiment.transfomer || data.experiment.aspect) {
					TaskManagerService.addWhatIf(id, data.experiment.aspect, data.experiment.transfomer, state)
						.then(response => {
							handleResponse(response);
						})
						.catch(err => handleCatch(err));
				} else {
					setMessage('No aspect or transformer selected');
					setLoading(false);
				}
			}
		} else {
			setMessage('No aspect or transformer selected');
			setLoading(false);
		}
	};

	const handleResponse = response => {
		console.log(response);
		if (response != null) {
			setMessage(response);
		} else {
			setShowDialog(false);
			dispatch(showMessage({ message: 'Calculate request submitted.' }));
			TaskManagerService.getQueueCountByAspect(data.experiment.aspect).then(count => {
				if (_.isNumber(count)) dispatch(showMessage({ message: `Tasks in the queue: ${count}` }));
			});
		}
		setLoading(false);
	};

	const handleCatch = err => {
		console.log(err);
		setLoading(false);
		setMessage(`Calculate connection problem: ${err !== null ? err : 'Unknown'}`);
	};

	return (
		<>
			<Button className="whitespace-nowrap mx-4" variant="contained" color="secondary" disabled={id === 'new'} onClick={() => setShowConfirm(true)} startIcon={<Icon className="hidden sm:flex">graphic_eq</Icon>}>
				Calculate Background
			</Button>
			<ConfirmDialog show={showConfirm} title="Calculate Background" description="Do you want to start this What If calculation task in the background?" onYes={() => handleClick()} onClose={() => setShowConfirm(false)} />
			<ThemeProvider theme={mainTheme}>
				<Dialog open={showDialog} fullWidth>
					<DialogTitle disableTypography className="border-b">
						Calculate Background
					</DialogTitle>
					<DialogContent className="p-20">
						{loading && <FuseLoading />}
						{!loading && message && <div className="flex justify-center">{message || 'No response.'}</div>}
						{!loading && valueErrorList.length > 0 && valueErrorList.map((err, i) => <RenderErrorMessage error={err} />)}
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button
							onClick={() => {
								setShowDialog(false);
							}}
						>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</>
	);
}

export default WhatIfCalculateBackground;
