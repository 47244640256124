import _ from '@lodash';
import { useState } from 'react';
import { useWhatIf } from '../component/WhatIfProvider';
import LabelIconView from '../view/LabelIconView';
import IconOnlyView from '../view/IconOnlyView';
import LabelOnlyView from '../view/LabelOnlyView';

const itemBorder = 5;

function DocumentItem(props) {
	const { item } = props;
	const [pos, setPos] = useState({ x: item.x, y: item.y });
	const { viewMode, editMode, data, setData, figure, setFigure, setShowPropertiesDialog } = useWhatIf();
	const [isDragging, setIsDragging] = useState(false);

	const handleItemClick = () => {
		if (editMode === 'DELETE') {
			setFigure({
				...figure,
				document: figure.document.filter(d => d.key !== item.key)
			});
		} else if (editMode === 'ROTATE') {
			const rotateNewValue = item.rotate ? item.rotate + 90 : 90;
			setFigure({
				...figure,
				document: figure.document.map(d => (d.key === item.key ? { ...d, rotate: rotateNewValue === 360 ? 0 : rotateNewValue } : d))
			});
		}
	};

	const updateDragPos = e => {
		const currentPos = { x: e.target.x(), y: e.target.y() };
		setPos(currentPos);
		setFigure({
			...figure,
			document: figure.document.map(d => {
				if (d.key === item.key) {
					return { ...item, ...currentPos };
				}
				return d;
			})
		});
	};

	const labelText = () => {
		return item.name;
	};

	const handleDblClick = e => {
		if (viewMode === 'EDIT' && editMode === 'MOVE') {
			setShowPropertiesDialog({ type: 'DOCUMENT', key: item.key });
		} else if (viewMode === 'CALCULATE') {
			setShowPropertiesDialog({ type: 'DOCUMENT_OPERATOR', key: item.key });
		}
		return true;
	};

	const ViewComponent = item.icon && item.showLabel ? LabelIconView : item.icon ? IconOnlyView : LabelOnlyView;

	return (
		<ViewComponent
			x={pos.x}
			y={pos.y}
			draggable={editMode === 'MOVE'}
			onPointerClick={e => handleItemClick(e)}
			onPointerDblClick={e => handleDblClick(e)}
			onDragStart={e => {
				setIsDragging(true);
			}}
			onDragMove={e => {
				updateDragPos(e);
			}}
			onDragEnd={e => {
				updateDragPos(e);
				setIsDragging(false);
			}}
			item={item}
			labelText={labelText()}
			isDragging={isDragging}
		/>
	);
}

export default DocumentItem;
