import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { hideMessage, showMessage } from 'app/store/fuse/messageSlice';
import UserLoginService from 'app/admin/user/service/UserLoginService';
import { getUserData, logoutUser } from './store/userSlice';

class Auth extends Component {
	state = {
		waitAuthCheck: true
	};

	componentDidMount() {
		return Promise.all([this.authCheck()]).then(() => {
			this.setState({ waitAuthCheck: false });
		});
	}

	authCheck = () => {
		return new Promise(resolve => {
			UserLoginService.isLoggedIn().then(result => {
				if (result) {
					this.props.getUserData().then(() => {
						resolve();
					});
				} else {
					resolve();
				}
			});
		});
	};

	render() {
		return this.state.waitAuthCheck ? <FuseSplashScreen /> : <>{this.props.children}</>;
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			logout: logoutUser,
			getUserData,
			showMessage,
			hideMessage
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(Auth);
