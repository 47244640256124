import _ from '@lodash';
import WorkflowItem from '../WorkflowItem';

export const defaultDefinition = {
	name: null,
	input_fields: [{ name: 'Components', type: 'Components', enabled: true, value: null }],
	output_fields: [{ name: 'Components', type: 'Components' }],
	grouping: null,
	feature_column: null,
	component_title: null
};

export const converter = function (data) {
	return data;
};

export default function GroupByPropertyTransformation(props) {
	return <WorkflowItem {...props} type="GROUP_BY_PROPERTY" />;
}
