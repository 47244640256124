import { Group, Rect, Text } from 'react-konva';
import { height, width } from './WorkflowValue';
import { useWorkflow } from '../WorkflowProvider';

function WorkflowInputValue(props) {
	const { calc, name } = props;
	const { setFloatCalculate, setSelectedCalculate } = useWorkflow();

	return (
		<Group width={width - 30} x={30} y={height - 15}>
			<Text text="Value" fontSize={9} fill="black" width={width - 40} listening={false} align="right" />

			<Rect
				onPointerEnter={e => {
					setFloatCalculate({ name, value: calc });
				}}
				onPointerLeave={e => {
					setFloatCalculate(null);
				}}
				onPointerClick={() => {
					setSelectedCalculate({ name, value: calc });
					setFloatCalculate(null);
				}}
				fill="green"
				cornerRadius={10}
				width={width - 30}
				height={10}
				opacity={0.3}
			/>
		</Group>
	);
}

export default WorkflowInputValue;
