import _ from '@lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from '@material-ui/core';
import TableHeaderAllSelectionCheckbox from './header/TableHeaderAllSelectionCheckbox';

const useStyles = makeStyles(theme => ({
	actionsButtonWrapper: {
		background: theme.palette.background.paper
	}
}));

function TableBrowserHead(props) {
	const classes = useStyles(props);
	const { t } = useTranslation(props.config.topic ? ['tableBrowser', props.config.topic] : 'tableBrowser');
	const createSortHandler = property => event => {
		props.onRequestSort(event, property);
	};

	const sortDirection = row => {
		return props.order.propertyName === row.key ? (props.order.ascending ? 'asc' : 'desc') : false;
	};

	const tooltipLabel = row => {
		return props.order.propertyName === row.key ? (props.order.ascending ? t('DESC') : t('ASC')) : t('SORT');
	};

	const tableCellText = row => {
		return !_.isUndefined(row.text) ? row.text : t(`${props.config.topic}:${row.key}`);
	};

	return (
		<TableHead>
			<TableRow className="h-48 sm:h-64">
				{props.config.bulkSelection ? <TableHeaderAllSelectionCheckbox config={props.config} /> : null}
				<TableBrowserHeadButtonCells rowButtonList={props.config.rowButtonList} rowAction={props.config.rowAction} />
				{props.config.columns.map(row => {
					return !_.isFunction(row.visibleIf) || row.visibleIf() ? (
						<TableCell className={row.disablePadding ? 'p-4' : 'p-4 md:p-16'} key={row.key} align={row.align} sortDirection={sortDirection(row)}>
							{row.sortable !== false ? (
								<Tooltip title={tooltipLabel(row)} placement={row.align === 'right' ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
									<TableSortLabel active={props.order.propertyName === row.key} direction={props.order.ascending ? 'asc' : 'desc'} onClick={createSortHandler(row.key)} className="font-semibold">
										{tableCellText(row)}
									</TableSortLabel>
								</Tooltip>
							) : (
								tableCellText(row)
							)}
						</TableCell>
					) : null;
				}, this)}
				<TableBrowserHeadButtonCells rowButtonList={props.config.rowEndButtonList} rowAction={props.config.rowEndAction} />
			</TableRow>
		</TableHead>
	);
}

function TableBrowserHeadButtonCells(props) {
	return (
		<>
			{props.rowButtonList
				? props.rowButtonList.map(r => {
						return <TableCell key={r.key} padding="none" className="w-0 text-center z-99" />;
				  })
				: null}
			{props.rowAction
				? props.rowAction.map(r => {
						return <TableCell key={r} padding="none" className="w-0 text-center z-99" />;
				  })
				: null}
		</>
	);
}
export default TableBrowserHead;
