import { authRoles } from 'app/auth';
import AnnModelEditForm from './editform/AnnModelEditForm';

export default [
	{
		path: '/model-ann/edit/:id/:driverName?',
		component: AnnModelEditForm,
		auth: authRoles.model
	}
];
