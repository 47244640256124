import _ from 'lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, ThemeProvider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider, Controller, useFormContext } from 'react-hook-form';
import AddIcon from '@material-ui/icons/Add';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import AspectService from 'app/admin/aspect/service/AspectService';
import AspectEditService from 'app/admin/aspect/service/AspectEditService';
import ModelService from 'app/admin/model/service/ModelService';
import { useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';

export default function OptimCaseLoadAspectAttributes() {
	const optimCaseFormMethods = useFormContext();
	const mainTheme = useSelector(selectMainTheme);
	const { t } = useTranslation('optimizationCase');
	const [showDialog, setShowDialog] = useState(false);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState(null);
	const schema = yup.object().shape({
		aspect: yup.number().nullable().required(t('validation:FIELD_REQUIRED'))
	});
	const methods = useForm({
		defaultValues: { aspect: null },
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const handleLoad = dto => {
		setLoading(true);
		setMessage(null);
		AspectEditService.getData(dto.aspect)
			.then(aspect => {
				if (aspect !== null && aspect.model !== null) {
					ModelService.getData(aspect.model)
						.then(model => {
							try {
								parseAttributes(aspect, model);
								setLoading(false);
								setShowDialog(false);
							} catch (e) {
								setLoading(false);
								setMessage(`Failed to load aspect: ${e}`);
							}
						})
						.catch(e => {
							setMessage(`Failed to load aspect: ${e}`);
							setLoading(false);
						});
				} else {
					setMessage(`Failed to load aspect: aspect not found`);
					setLoading(false);
				}
			})
			.catch(e => {
				setMessage(`Failed to load aspect: ${e}`);
				setLoading(false);
			});
	};

	const parseAttributes = (aspect, model) => {
		const modelInputAttributes = model.export ? model.export.input_attributes : model.input_attributes;
		const domains = optimCaseFormMethods.getValues('domains');
		console.log(domains);
		if (aspect.input_attributes !== null && aspect.input_attributes.length > 0 && modelInputAttributes && modelInputAttributes.length > 0) {
			aspect.input_attributes.forEach(input => {
				const modelData = modelInputAttributes.find(d => d.name === (input.model_field_name != null ? input.model_field_name : input.name));
				if (modelData) {
					const idx = domains.findIndex(d => d.name === input.name);
					if (idx > -1) {
						const domain = domains[idx];
						domain.value = input.value;
						domain.min_value = modelData.min_value;
						domain.max_value = modelData.max_value;
					} else {
						domains.push({
							id: null,
							enabled: true,
							name: input.name,
							type: input.type,
							value: input.value,
							min_value: modelData.min_value,
							max_value: modelData.max_value,
							step_count: null
						});
					}
				}
			});
		}
		optimCaseFormMethods.setValue('domains', domains);
	};

	return (
		<FormProvider {...methods}>
			<Button
				className="whitespace-nowrap mx-4"
				variant="contained"
				color="secondary"
				onClick={() => {
					setShowDialog(true);
				}}
				startIcon={<AddIcon className="hidden sm:flex" />}
			>
				{t('load_aspect')}
			</Button>
			<ThemeProvider theme={mainTheme}>
				<Dialog open={showDialog} fullWidth>
					<DialogTitle disableTypography className="border-b">
						{t('load_aspect')}
					</DialogTitle>
					<DialogContent className="p-20">
						{' '}
						<Controller
							control={methods.control}
							name="aspect"
							render={({ field, fieldState }) => (
								<EditFormSelectField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'optimizationCase' }}
									fieldConfig={{
										key: 'aspect',
										text: t('aspect'),
										items: () => AspectService.getListSelection()
									}}
								/>
							)}
						/>
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button
							onClick={() => {
								setShowDialog(false);
							}}
						>
							Close
						</Button>
						<Button onClick={methods.handleSubmit(handleLoad)}>Load</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</FormProvider>
	);
}
