import { useState, useEffect } from 'react';
import { Icon, Tooltip, TableCell } from '@material-ui/core';

export const valueToIcon = value => {
	if (value === 'Directory') return 'folder_open';
	if (value === 'File') return 'insert_drive_file';
	if (value === 'Image') return 'image';
	if (value === 'Document') return 'description';
	return null;
};

function IconFileTypeTableCell(props) {
	const [iconName, setIconName] = useState(null);

	useEffect(() => {
		if (props.item[props.config.key] != null) {
			setIconName(valueToIcon(props.item[props.config.key]));
		} else {
			setIconName(null);
		}
	}, [props.item, props.config]);

	return (
		<TableCell className="p-4 md:p-16 w-0 text-center" scope="row">
			{iconName ? (
				<Tooltip title={props.item.getAsString(props.config.key)}>
					<Icon fontSize="small">{iconName}</Icon>
				</Tooltip>
			) : null}
		</TableCell>
	);
}

export default IconFileTypeTableCell;
