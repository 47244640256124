import TableMetadataDto from './TableMetadataDto';

/** @template TABLE_DTO */
class GenericTableDto {
	constructor() {
		/** @type {TableMetadataDto} */
		this.metadata = null;
		/** @type {Array<TABLE_DTO>} */
		this.data = null;
		/** @type {number[]} */
		this.selected = null;
	}

	/**
	 * @typedef {Object} _GenericTableDtoProps
	 * @property {TableMetadataDto} metadata
	 * @property {Array<TABLE_DTO>} data
	 * @property {number[]} selected
	 */
	/**
	 *
	 * @param {_GenericTableDtoProps} props
	 * @param {new() => TABLE_DTO} TableDtoObjectConstuctor
	 * @returns {GenericTableDto<TABLE_DTO>}
	 */
	setup(props, TableDtoObjectConstuctor) {
		this.metadata = props.metadata != null ? new TableMetadataDto().setup(props.metadata) : null;
		this.data = props.data != null ? props.data.map(d => new TableDtoObjectConstuctor().setup(d)) : null;
		this.selected = props.selected;
		return this;
	}
}

export default GenericTableDto;
