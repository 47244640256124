import OptimizerEditDto from './OptimizerEditDto';

export class ShgoOptimizerEditDto extends OptimizerEditDto {
	constructor() {
		super();
		/** @type {number} */
		this.iters = null;
		/** @type {number} */
		this.n = null;
		/** @type {number} */
		this.method = null;
		/** @type {number} */
		this.sampling_method = null;
		/** @type {number} */
		this.workers = null;
	}

	/**
	 * @typedef {Object} _ShgoOptimizerEditDtoProps
	 * @property {number} iters
	 * @property {number} n
	 * @property {string} method
	 * @property {string} sampling_method
	 * @property {number} workers
	 *
	 * @typedef {import("./OptimizerEditDto").OptimizerEditDtoProps & _ShgoOptimizerEditDtoProps} ShgoOptimizerEditDtoProps
	 */
	/**
	 *
	 * @param {ShgoOptimizerEditDtoProps} props
	 * @returns {ShgoOptimizerEditDto}
	 */
	setup(props) {
		super.setup(props);

		this.iters = props.iters;
		this.n = props.n;
		this.method = props.method;
		this.sampling_method = props.sampling_method;
		this.workers = props.workers;
		return this;
	}
}

export default ShgoOptimizerEditDto;
