import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: [],
	metaData: null,
	selected: [],
	allSelected: false
};

const tableBrowserDataSlice = key =>
	createSlice({
		name: `${key}/data`,
		initialState,
		reducers: {
			setData: (state, action) => {
				state.data = action.payload;
			},
			setMetaData: (state, action) => {
				state.metaData = action.payload;
				state.allSelected = state.metaData.totalCount > 0 && state.metaData.totalCount === state.metaData.selectedCount;
			},
			setSelected: (state, action) => {
				state.selected = action.payload;
			},
			setSelectedId: (state, action) => {
				if (action.payload.add) {
					if (state.selected.indexOf(action.payload.id) === -1) {
						state.selected = [...state.selected, action.payload.id];
						state.metaData = { ...state.metaData, ...{ selectedCount: state.metaData.selectedCount + 1 } };
						state.allSelected = state.metaData.totalCount > 0 && state.metaData.totalCount === state.metaData.selectedCount;
					}
				} else if (state.selected.indexOf(action.payload.id) > -1) {
					state.selected = state.selected.filter(item => item !== action.payload.id);
					state.metaData = { ...state.metaData, ...{ selectedCount: state.metaData.selectedCount - 1 } };
					state.allSelected = state.metaData.totalCount > 0 && state.metaData.totalCount === state.metaData.selectedCount;
				}
			},
			setAllSelected: (state, action) => {
				state.selected = [];
				if (action.payload) {
					state.metaData = { ...state.metaData, ...{ selectedCount: state.metaData.totalCount } };
					state.allSelected = true;
				} else {
					state.metaData = { ...state.metaData, ...{ selectedCount: 0 } };
					state.allSelected = false;
				}
			}
		}
	});

export default tableBrowserDataSlice;
