import FusePageCarded from '@fuse/core/FusePageCarded/FusePageCarded';
import { Icon, IconButton, TextField, Tooltip, makeStyles } from '@material-ui/core';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router';

const inputRow = 1000;

const useStyles = makeStyles(theme => ({
	root: {
		'& .container': {
			maxWidth: '100% !important'
		}
	}
}));

function generateDto() {
	const dto = {};
	for (let i = 1; i <= inputRow; i += 1) {
		const subdto = {};
		for (let j = 1; j <= 5; j += 1) {
			subdto[j] = `${i}/${j}`;
		}
		dto[i] = subdto;
	}
	return dto;
}

function range() {
	const result = [];
	for (let i = 1; i <= inputRow; i += 1) {
		result.push(i);
	}
	return result;
}
function Test2Page(props) {
	const classes = useStyles();
	const methods = useForm({
		defaultValues: generateDto(),
		mode: 'onChange'
	});

	return (
		<FusePageCarded
			classes={{
				toolbar: 'p-0',
				header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
				root: classes.root
			}}
			header={<div className="flex flex-1 w-full items-center justify-between">Test2</div>}
			content={
				<div className="p-16 sm:p-24">
					<FormProvider {...methods}>
						<table className="w-full">
							<tbody>
								{range().map(index => (
									<tr className={`${index % 2 === 0 ? 'bg-gray-100' : ''}`} key={index}>
										<td>
											<Controller
												control={methods.control}
												name={`${index}.1`}
												render={({ field, fieldState }) => (
													<EditFormTextField
														field={field}
														fieldState={fieldState}
														config={{ topic: 'test' }}
														fieldConfig={{
															key: `${index}.1`,
															text: `${index}.1`
														}}
													/>
												)}
											/>
										</td>
										<td>
											<Controller
												control={methods.control}
												name={`${index}.2`}
												render={({ field, fieldState }) => (
													<EditFormTextField
														field={field}
														fieldState={fieldState}
														config={{ topic: 'test' }}
														fieldConfig={{
															key: `${index}.2`,
															text: `${index}.2`
														}}
													/>
												)}
											/>
										</td>
										<td>
											<Controller
												control={methods.control}
												name={`${index}.3`}
												render={({ field, fieldState }) => (
													<EditFormTextField
														field={field}
														fieldState={fieldState}
														config={{ topic: 'test' }}
														fieldConfig={{
															key: `${index}.3`,
															text: `${index}.3`
														}}
													/>
												)}
											/>
										</td>
										<td>
											<Controller
												control={methods.control}
												name={`${index}.4`}
												render={({ field, fieldState }) => (
													<EditFormTextField
														field={field}
														fieldState={fieldState}
														config={{ topic: 'test' }}
														fieldConfig={{
															key: `${index}.4`,
															text: `${index}.4`
														}}
													/>
												)}
											/>
										</td>
										<td>
											<Controller
												control={methods.control}
												name={`${index}.5`}
												render={({ field, fieldState }) => (
													<EditFormTextField
														field={field}
														fieldState={fieldState}
														config={{ topic: 'test' }}
														fieldConfig={{
															key: `${index}.5`,
															text: `${index}.5`
														}}
													/>
												)}
											/>
										</td>
										<td>
											<Tooltip title="delete">
												<IconButton onClick={() => console.log('delete')}>
													<Icon className="text-32">delete</Icon>
												</IconButton>
											</Tooltip>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</FormProvider>
				</div>
			}
		/>
	);
}
export default withRouter(Test2Page);
