import { useState } from 'react';
import PiMeasurementReaderQuery from './PiMeasurementReaderQuery';
import PiMeasurementReaderResult from './PiMeasurementReaderResult';

function PiMeasurementReaderComponent(props) {
	const piMeasurementReaderName = props.piMeasurementReaderName || null;
	const [result, setResult] = useState(null);
	return (
		<div>
			<PiMeasurementReaderQuery onResult={_result => setResult(_result)} piMeasurementReaderName={piMeasurementReaderName} />
			{result != null && <PiMeasurementReaderResult result={result} onLoad={values => props.onLoad(values)} />}
		</div>
	);
}

export default PiMeasurementReaderComponent;
