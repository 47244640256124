import NamedDto from 'modules/base/dto/NamedDto';

export default class DriverTableDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.driver_type = null;
		/** @type {Date} */
		this.created = null;
		/** @type {Date} */
		this.updated = null;
		/** @type {boolean} */
		this.open = null;
		/** @type {boolean} */
		this.connected = null;
		/** @type {Date} */
		this.lastTime = null;
		/** @type {number} */
		this.autoCloseTimeout = null;
		/** @type {Date} */
		this.activeUntil = null;
	}

	/**
	 * @typedef {Object} _DriverTableDtoProps
	 * @property {string} driver_type
	 * @property {Date} created
	 * @property {Date} updated
	 * @property {boolean} open
	 * @property {boolean} connected
	 * @property {Date} lastTime
	 * @property {number} autoCloseTimeout
	 * @property {Date} activeUntil
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _DriverTableDtoProps} DriverTableDtoProps
	 */
	/**
	 *
	 * @param {DriverTableDtoProps} props
	 * @returns {DriverTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.driver_type = props.driver_type;
		this.created = props.created != null ? new Date(props.created) : null;
		this.updated = props.updated != null ? new Date(props.updated) : null;
		this.open = props.open;
		this.connected = props.connected;
		this.lastTime = props.lastTime !== null ? new Date(props.lastTime) : null;
		this.autoCloseTimeout = props.autoCloseTimeout;
		this.activeUntil = props.activeUntil !== null ? new Date(props.activeUntil) : null;
		return this;
	}
}
