import AccessConfigRoleDto from './AccessConfigRoleDto';
import AccessConfigUserDto from './AccessConfigUserDto';

export default class AccessConfigDto {
	constructor() {
		/** @type {AccessConfigUserDto} */
		this.user = null;
		/** @type {AccessConfigRoleDto[]} */
		this.roleList = null;
		/** @type {string[]} */
		this.serviceList = null;
		/** @type {Object} */
		this.settingMap = null;
		/** @type {boolean} */
		this.isSuperAdmin = null;
		/** @type {string} */
		this.accessType = null;
		/** @type {Object} */
		this.serviceLevelMap = null;
	}

	/**
	 * @typedef {Object} _AccessConfigDtoProps
	 * @property {AccessConfigUserDto} user
	 * @property {AccessConfigRoleDto[]} roleList
	 * @property {string[]} serviceList
	 * @property {Object} settingMap
	 * @property {boolean} isSuperAdmin
	 * @property {string} accessType
	 * @property {Object} serviceLevelMap
	 *
	 * @typedef {_AccessConfigDtoProps} AccessConfigDtoProps
	 */
	/**
	 *
	 * @param {AccessConfigDtoProps} props
	 * @returns {AccessConfigDto}
	 */
	setup(props) {
		if (props === null) return null;
		this.user = props.user !== null ? new AccessConfigUserDto().setup(props.user) : null;
		this.roleList = props.roleList !== null ? props.roleList.map(d => new AccessConfigRoleDto().setup(d)) : [];
		this.serviceList = props.serviceList ? props.serviceList : [];
		this.settingMap = props.settingMap;
		this.isSuperAdmin = props.isSuperAdmin;
		this.accessType = props.accessType;
		this.serviceLevelMap = props.serviceLevelMap;
		return this;
	}
}
