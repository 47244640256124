import nBossConnection from 'modules/base/service/nBossConnection';
import EntityValidationResult from 'modules/base/dto/validation/EntityValidationResult';
import UserProfileEditDto from '../dto/UserProfileEditDto';

class UserProfileEditService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'userProfileEditControl';
	}

	/**
	 * @param {number} id
	 * @return {Promise<UserProfileEditDto>}
	 */
	getData(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getData`, {
					id
				})
				.then(response => resolve(new UserProfileEditDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @param {UserProfileEditDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	update(id, dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/update`, {
					id,
					dto
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}
}

export default new UserProfileEditService();
