import NamedDto from 'modules/base/dto/NamedDto';

export default class DriverDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.driver_type = null;
	}

	/**
	 * @typedef {Object} _DriverDtoProps
	 * @property {string} driver_type
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _DriverDtoProps} DriverDtoProps
	 */
	/**
	 *
	 * @param {DriverDtoProps} props
	 * @returns {DriverDto}
	 */
	setup(props) {
		super.setup(props);
		this.driver_type = props.driver_type;
		return this;
	}
}
