import { useState, createContext, useContext } from 'react';
import { generateId } from 'app/admin/whatIf/component/WhatIfUtil';

export const BlenderContext = createContext(null);

export const useBlender = () => {
	return useContext(BlenderContext);
};

export default function BlenderProvider(props) {
	const [grid, setGrid] = useState(null);

	const addRow = () => {
		const item = { key: generateId(), name: null };
		setGrid({
			...grid,
			rows: [...grid.rows, item],
			conversion: [
				...grid.conversion,
				...grid.columns.map(c => {
					return {
						key: generateId(),
						row: item.key,
						column: c.key,
						value: null
					};
				})
			]
		});
	};

	const changeRowName = (key, value) => {
		setGrid({
			...grid,
			rows: grid.rows.map(d => {
				if (d.key === key) {
					return {
						...d,
						name: value
					};
				}
				return d;
			})
		});
	};

	const removeRow = index => {
		const row = grid.rows[index];
		setGrid({
			...grid,
			rows: grid.rows.filter((d, i) => i !== index),
			conversion: grid.conversion.filter(c => c.row !== row.key)
		});
	};

	const addColumn = () => {
		const item = { key: generateId(), name: null };
		setGrid({
			...grid,
			columns: [...grid.columns, item],
			conversion: [
				...grid.conversion,
				...grid.rows.map(r => {
					return {
						key: generateId(),
						row: r.key,
						column: item.key,
						value: null
					};
				})
			]
		});
	};

	const removeColumn = index => {
		const column = grid.columns[index];
		setGrid({
			...grid,
			columns: grid.columns.filter((d, i) => i !== index),
			conversion: grid.conversion.filter(c => c.column !== column.key)
		});
	};

	const changeColumnName = (key, value) => {
		setGrid({
			...grid,
			columns: grid.columns.map(d => {
				if (d.key === key) {
					return {
						...d,
						name: value
					};
				}
				return d;
			})
		});
	};

	const changeValue = (type, key, value) => {
		setGrid({
			...grid,
			[type]: grid[type].map(d => {
				if (d.key === key) {
					return {
						...d,
						value
					};
				}
				return d;
			})
		});
	};

	const value = {
		grid,
		setGrid,
		addRow,
		removeRow,
		changeRowName,
		addColumn,
		removeColumn,
		changeColumnName,
		changeValue
	};

	return <BlenderContext.Provider value={value}>{props.children}</BlenderContext.Provider>;
}
