import NamedDto from 'modules/base/dto/NamedDto';
import DatasetFieldEditDto from './DatasetFieldEditDto';

export class DatasetEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.table_name = null;
		/** @type {string} */
		this.description = null;
		/** @type {DatasetFieldEditDto[]} */
		this.key_fields = null;
		/** @type {DatasetFieldEditDto[]} */
		this.value_fields = null;
	}

	/**
	 * @typedef {Object} _DatasetEditDtoProps
	 * @property {string} table_name
	 * @property {string} description
	 * @property {DatasetFieldEditDto[]} key_fields
	 * @property {DatasetFieldEditDto[]} value_fields
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _DatasetEditDtoProps} DatasetEditDtoProps
	 */
	/**
	 *
	 * @param {DatasetEditDtoProps} props
	 * @returns {DatasetEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.table_name = props.table_name;
		this.description = props.description;
		this.key_fields = props.key_fields !== null ? props.key_fields.map(d => new DatasetFieldEditDto().setup(d)) : [];
		this.value_fields = props.value_fields !== null ? props.value_fields.map(d => new DatasetFieldEditDto().setup(d)) : [];
		return this;
	}
}

export default DatasetEditDto;
