import _ from '@lodash';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { determineEditableAccess } from 'modules/ui/editform/EditFormUtils';
import ComponentMassSomethingDialog from './ComponentMassSomethingDialog';

function EditFormComponentMassSomethingField(props) {
	const methods = useFormContext();
	const { value, onChange } = props.field;
	const [nameItems, setNameItems] = useState(null);
	const [openDialog, setOpenDialog] = useState(false);
	const [readOnly, setReadOnly] = useState(determineEditableAccess(props.config, props.fieldConfig, props.fieldPrefix));
	const { compact = false } = props;

	useEffect(() => {
		if (_.isFunction(props.fieldConfig.onChange)) props.fieldConfig.onChange(value, props.field, methods);
	}, [value]);

	const handleOpen = () => {
		if (props.fieldConfig.enumByField) {
			const dto = methods.getValues(props.fieldConfig.enumByField);
			const _nameItems = [];
			if (_.isArray(dto)) {
				dto.forEach(d => {
					_nameItems.push({
						label: d.name,
						value: d.name
					});
				});
				setNameItems(_nameItems);
			}
		}
		setOpenDialog(true);
	};

	return (
		<div className={clsx(compact ? 'flex items-center px-8' : 'flex items-center px-8 mt-8 mb-16', props.fieldConfig.className)}>
			<Button variant="contained" color="primary" className="w-full" onClick={() => handleOpen()}>
				Open List
			</Button>
			{openDialog && (
				<ComponentMassSomethingDialog
					fieldPrefix={props.fieldPrefix}
					loadValueFrom={props.fieldConfig.loadValueFrom}
					loadValueMultiplier={props.fieldConfig.loadValueMultiplier}
					value={value}
					nameItems={nameItems}
					onCancel={() => setOpenDialog(false)}
					onSave={result => {
						onChange(result);
						setOpenDialog(false);
					}}
				/>
			)}
		</div>
	);
}

export default EditFormComponentMassSomethingField;
