import _ from '@lodash';
import defaultLabelStyle from 'app/admin/common/component/DefaultLabelStyle';
import { useEffect, useState } from 'react';
import { grey } from '@material-ui/core/colors';
import { TableContainer, Table, TableHead, TableCell, TableRow, TableBody, Checkbox, Tooltip, withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { FaFilter } from 'react-icons/fa6';

import { formatLabelValue } from 'app/admin/common/util/CommonUtil';

const CustomCheckbox = withStyles({
    root: { color: grey[100], '&$checked': { color: grey[200] } },
    checked: {}
})(props => <Checkbox color='default' {...props} />);

export default function CompareTable(props) {
    const [rowList, setRowList] = useState([]);
    const [differenceFilter, setDifferenceFilter] = useState(false);

    const getDifferenceBetweenValues = (l, r) => {
        return r - l;
    };

    const getDifferencePercent = (l, difference) => {
        if (difference !== 0) return (difference / l);
        return null;
    };

    const createRowList = (l, r) => {
        const attributes = [...new Set([...Object.keys(l), ...Object.keys(r)])];
        const _rowList = [];

        for (let i = 0; i < attributes.length; i += 1)
            _rowList.push({
                name: attributes[i],
                leftValue: l[attributes[i]],
                rightValue: r[attributes[i]],
                difference: getDifferenceBetweenValues(l[attributes[i]], r[attributes[i]]),
                differencePercent: getDifferencePercent(l[attributes[i]], getDifferenceBetweenValues(l[attributes[i]], r[attributes[i]])),
            });

        setRowList(_rowList);
    };

    useEffect(() => {
        if (props.dataLeft && props.dataRight)
            createRowList(props.dataLeft, props.dataRight);
    }, [props.dataLeft, props.dataRight]);

    return (
        <TableContainer component={Paper}>

            <div className='w-full flex items-center justify-between bg-black text-white p-6'>

                <div className='font-medium flex gap-8 ml-12'><FaFilter className='mt-2' /> Filter</div>

                <div className='flex items-center gap-2'>
                    <div className='text-xs'>Show only values with difference</div>
                    <Tooltip title='Show only if there is a difference' aria-label='toggle'>
                        <CustomCheckbox checked={differenceFilter} onChange={() => setDifferenceFilter(!differenceFilter)} />
                    </Tooltip>
                </div>

            </div>

            <Table className='w-full shadow-2xl'>

                <TableHead>
                    <TableRow>
                        <TableCell>Attribute</TableCell>
                        <TableCell className='text-right'>{props.titleLeft}</TableCell>
                        <TableCell className='text-right'>{props.titleRight}</TableCell>
                        <TableCell className='text-right'>Difference</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {rowList && rowList.map((item, index) => (!differenceFilter || (differenceFilter && item.difference)) ? <Row data={item} index={index} /> : null)}

                </TableBody>
            </Table>
        </TableContainer>
    );
}

function Row(props) {
    const style = {
        variableStyle: defaultLabelStyle(false),
        componentStyle: defaultLabelStyle(true)
    };

    if (props.data.hasSub && !props.data.hasSub.isSub)
        return (
            <TableRow key={props.index} className={`${(props.index + 1) % 2 === 1 ? 'bg-gray-100' : ''}`}>

                <TableCell colSpan={4} className='font-bold'>
                    {props.data.name}
                </TableCell>

            </TableRow>
        );

    return (
        <TableRow key={props.index} className={`${(props.index + 1) % 2 === 1 ? 'bg-gray-100' : ''}`}>

            <TableCell className={props.data.isSub ? 'pl-36' : ''}>{props.data.name}</TableCell>

            <TableCell className='whitespace-nowrap' align='right'>
                {props.data.isSub ? formatLabelValue(props.data.leftValue, style.componentStyle) : formatLabelValue(props.data.leftValue, style.variableStyle)}
            </TableCell>

            <TableCell className='whitespace-nowrap' align='right'>
                {props.data.isSub ? formatLabelValue(props.data.rightValue, style.componentStyle) : formatLabelValue(props.data.rightValue, style.variableStyle)}
            </TableCell>

            <DifferenceCell row={props.data} style={style} />

        </TableRow>
    );
}

function DifferenceCell(props) {
    const { row, style } = props;

    return (
        <TableCell>
            <div className='flex flex-row justify-end gap-6 font-medium'>

                {row.differencePercent != null && row.differencePercent !== 0 && (
                    <div className={`${row.differencePercent < 0 ? 'text-green-800' : 'text-red-800'} whitespace-nowrap`}>
                        {`${row.differencePercent > 0 ? '+' : '-'} ${formatLabelValue(Math.abs(row.differencePercent), { thousandsSeparator: true, decimalPlaces: 2, displayAsPercentage: true, showUnit: true })}`}
                    </div>
                )}

                {row.difference != null && row.difference !== 0 && (
                    <div className={`${row.difference < 0 ? 'text-green-800' : 'text-red-800'} whitespace-nowrap`}>({row.isSub ? formatLabelValue(Math.abs(row.difference), style.componentStyle) : formatLabelValue(Math.abs(row.difference), style.variableStyle)})</div>
                )}

                {row.difference === null || row.difference === 0 && <div>-</div>}

            </div>
        </TableCell>
    );
}