import i18next from 'i18next';
import enCommon from './common/en';
import huCommon from './common/hu';
import enValidation from './validation/en';
import huValidation from './validation/hu';
import enAuth from './auth/en';
import huAuth from './auth/hu';
import enTableBrowser from './tableBrowser/en';
import huTableBrowser from './tableBrowser/hu';
import enEditform from './editform/en';
import huEditform from './editform/hu';
import enFilePicker from './filePicker/en';
import huFilePicker from './filePicker/hu';

export default function () {
	i18next.addResourceBundle('en', 'common', enCommon);
	i18next.addResourceBundle('hu', 'common', huCommon);
	i18next.addResourceBundle('en', 'validation', enValidation);
	i18next.addResourceBundle('hu', 'validation', huValidation);
	i18next.addResourceBundle('en', 'auth', enAuth);
	i18next.addResourceBundle('hu', 'auth', huAuth);
	i18next.addResourceBundle('en', 'tableBrowser', enTableBrowser);
	i18next.addResourceBundle('hu', 'tableBrowser', huTableBrowser);
	i18next.addResourceBundle('en', 'editform', enEditform);
	i18next.addResourceBundle('hu', 'editform', huEditform);
	i18next.addResourceBundle('en', 'filePicker', enFilePicker);
	i18next.addResourceBundle('hu', 'filePicker', huFilePicker);
}
