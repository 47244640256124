import _ from '@lodash';
import DriverService from './DriverService';

class StorageDriverService {
	constructor() {
		this.listSelectionByDriverTypeResult = [];
	}

	getListSelectionByDriverType(driverType) {
		if (_.isUndefined(this.listSelectionByDriverTypeResult[driverType]))
			return new Promise((resolve, reject) => {
				DriverService.getListSelectionByDriverType(driverType)
					.then(resp => {
						this.listSelectionByDriverTypeResult[driverType] = resp;
						resolve(resp);
					})
					.catch(e => reject(e));
			});
		return new Promise(resolve => {
			resolve(this.listSelectionByDriverTypeResult[driverType]);
		});
	}
}
export default StorageDriverService;
