import { authRoles } from 'app/auth';
import PasswordRequest from './PasswordRequest';
import NewPassword from './NewPassword';

const PasswordConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/password-request',
			component: PasswordRequest
		},
		{
			path: '/new-password/:userId/:key',
			component: NewPassword
		}
	]
};

export default PasswordConfig;
