import OcuEntityValidationResult from 'app/admin/common/dto/OcuEntityValidationResult';
import nBossConnection from 'modules/base/service/nBossConnection';
import HysysModelEditDto from '../dto/HysysModelEditDto';

class HysysModelEditService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'hysysModelEditControl';
	}

	/**
	 * @param {string} driverName
	 * @return {Promise<HysysModelEditDto>}
	 */
	create(driverName) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/create`, { driverName })
				.then(response => resolve(new HysysModelEditDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<HysysModelEditDto>}
	 */
	getData(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getData`, {
					id
				})
				.then(response => resolve(new HysysModelEditDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {HysysModelEditDto} dto
	 * @return {Promise<OcuEntityValidationResult>}
	 */
	save(dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/save`, {
					dto
				})
				.then(response => resolve(new OcuEntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @param {HysysModelEditDto} dto
	 * @return {Promise<OcuEntityValidationResult>}
	 */
	update(id, dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/update`, {
					id,
					dto
				})
				.then(response => resolve(new OcuEntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {string} name
	 * @return {Promise<Object>}
	 */
	getHysysInputAttributeValues(name) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getHysysInputAttributeValues`, {
					name
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} name
	 * @return {Promise<Object>}
	 */
	getHysysAttributeInfo(name) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getHysysAttributeInfo`, {
					name
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new HysysModelEditService();
