import nBossConnection from 'modules/base/service/nBossConnection';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import DatasetTableService from '../service/DatasetTableService';

const config = {
	key: 'datasetTable',
	topic: 'dataset',
	service: DatasetTableService,
	editFormState: item => `/dataset/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['search', 'refresh', 'new'],
	rowAction: ['exportData', 'modify', 'delete'],
	exportData: {
		tooltip: 'Export to XLSX',
		link: item => `${nBossConnection.basePath}datasetTableControl/exportToXlsx?name=${item.name}`
	},
	columns: [
		{
			key: 'name'
		}
	]
};

function DatasetTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('datasetTable', reducer('datasetTable'))(DatasetTable);
