import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import TransformerTableService from '../service/TransformerTableService';
import ExportTableDownloadButton from '../component/ExportTableDownloadButton';

const config = {
	key: 'transformerTable',
	topic: 'transformer',
	service: TransformerTableService,
	editFormState: item => `/transformer/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['search', 'refresh', 'new'],
	rowAction: ['modify', 'delete'],
	rowButtonList: [{ key: 'downloadButton', component: ExportTableDownloadButton }],
	columns: [
		{
			key: 'name'
		},
		{
			key: 'created',
			type: 'dateTime'
		},

		{
			key: 'updated',
			type: 'dateTime'
		}
	]
};

function TransformerTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('transformerTable', reducer('transformerTable'))(TransformerTable);
