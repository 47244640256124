import NamedDto from 'modules/base/dto/NamedDto';

export class UserEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.email = null;
		/** @type {string} */
		this.password = null;
		/** @type {Date} */
		this.passwordAgain = null;
		/** @type {string} */
		this.registrationStatus = null;
		/** @type {number[]} */
		this.roleList = null;
		/** @type {string} */
		this.profilePicture = null;
		/** @type {boolean} */
		this.passwordActive = null;
		/** @type {number[]} */
		this.whatIfGroupList = null;
	}

	/**
	 * @typedef {Object} _UserEditDtoProps
	 * @property {string} email
	 * @property {string} password
	 * @property {string} passwordAgain
	 * @property {string} registrationStatus
	 * @property {number[]} roleList
	 * @property {string} profilePicture
	 * @property {boolean} passwordActive
	 * @property {number[]} whatIfGroupList
	 *
	 * @typedef {import("../NamedDto").NamedDtoProps & _UserEditDtoProps} UserEditDtoProps
	 */
	/**
	 *
	 * @param {UserEditDtoProps} props
	 * @returns {UserEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.email = props.email;
		this.password = props.password;
		this.passwordAgain = props.passwordAgain;
		this.registrationStatus = props.registrationStatus;
		this.roleList = props.roleList;
		this.profilePicture = props.profilePicture;
		this.passwordActive = props.passwordActive;
		this.whatIfGroupList = props.whatIfGroupList !== null ? props.whatIfGroupList : [];
		return this;
	}
}

export default UserEditDto;
