import _ from '@lodash';
import { useSelector, useDispatch } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import { ThemeProvider } from '@material-ui/core/styles';
import { useState } from 'react';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useFormContext } from 'react-hook-form';
import { Button, Icon, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import ModelService from 'app/admin/model/service/ModelService';
import AspectService from 'app/admin/aspect/service/AspectService';
import MapTableResult from './MapTableResult';

export default function (props) {
	const mainTheme = useSelector(selectMainTheme);

	const dispatch = useDispatch();
	const { id, type } = props;
	const { getValues } = useFormContext();
	const [loading, setLoading] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [result, setResult] = useState(null);
	const [message, setMessage] = useState(null);

	const handleClick = () => {
		const dto = getValues();
		if (dto.name === null || dto.name.length === 0) {
			dispatch(showMessage({ message: 'Model name is required.' }));
		} else if (dto.input_attributes === null || dto.input_attributes.length === 0 || dto.output_attributes === null || dto.output_attributes.length === 0) {
			dispatch(showMessage({ message: 'Input/output attributes is required.' }));
		} else {
			const values = {};
			dto.input_attributes.forEach(element => {
				values[element.name] = element.value;
			});
			setLoading(true);
			setShowDialog(true);
			if (type === 'aspect')
				AspectService.calculate(dto.name, values).then(response => handleResponse(response)).catch(err => handleCatch(err));
			else
				ModelService.calculate(dto.name, values).then(response => handleResponse(response)).catch(err => handleCatch(err));
		}
	};

	const handleResponse = data => {
		console.log(data);
		setLoading(false);
		if (data !== null && _.isArray(data) && data.length > 0) {
			setResult(data[0]);
		} else if (data !== null && _.isObject(data) && data.output_values) {
			setResult(data.output_values);
		} else if (data !== null && _.isObject(data)) {
			setResult(data);
		} else {
			setResult(null);
			if (_.isString(data)) {
				setMessage(data);
			} else {
				setMessage('Empty result');
			}
		}
	};

	const handleCatch = err => {
		console.log(err);
		setLoading(false);
		setResult(null);
		setMessage(`Calculate connection problem: ${err !== null ? err : 'Unkown'}`);
	};

	return (
		<>
			<Button className="whitespace-nowrap mx-4" variant="contained" color="secondary" disabled={id === 'new'} onClick={() => handleClick()} startIcon={<Icon className="hidden sm:flex">graphic_eq</Icon>}>
				Calculate
			</Button>
			<ThemeProvider theme={mainTheme}>
				<Dialog open={showDialog} fullWidth>
					<DialogTitle disableTypography className="border-b">
						Calculate
					</DialogTitle>
					<DialogContent className="p-20">
						{loading && <FuseLoading />}
						{!loading && result !== null && <MapTableResult data={result} />}
						{!loading && (result === null || result.length === 0) && <div className="flex justify-center">{message || 'No result.'}</div>}
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button onClick={() => { setShowDialog(false); }}>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</>
	);
}
