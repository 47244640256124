import _ from '@lodash';
import { format } from 'date-fns';
import { useState, useEffect } from 'react';
import { Table, TableHead, TableRow, TableBody, TableCell, Tooltip, IconButton } from '@material-ui/core';
import LoadIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import LabelNumberValue from './LabelNumberValue';

function PiMeasurementReaderResult(props) {
	const { result } = props;
	const [data, setData] = useState([]);
	const [headers, setHeaders] = useState([]);

	useEffect(() => {
		const _data = [];
		const _headers = [];
		const _attributeList = [];
		if (_.isObject(result)) {
			Object.keys(result).forEach(dateTime => {
				_headers.push(dateTime);
				Object.keys(result[dateTime]).forEach(attribute => {
					if (!_attributeList.includes(attribute)) {
						_attributeList.push(attribute);
					}
				});
			});
			let i = 1;
			_attributeList.forEach(attribute => {
				const rowData = {
					id: i,
					attribute,
					valueList: []
				};
				_headers.forEach(dateTime => {
					if (result[dateTime][attribute] != null) {
						rowData.valueList.push(result[dateTime][attribute]);
					} else {
						rowData.valueList.push(null);
					}
				});
				_data.push(rowData);
				i += 1;
			});
		}
		setHeaders(_headers);
		setData(_data);
	}, [result]);

	const handleLoad = dateTime => {
		const loadData = result[dateTime] || {};
		props.onLoad(loadData);
	};

	return (
		<div className="overflow-x-scroll w-full">
			<Table className="w-full" aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>Attribute</TableCell>
						{headers.map((dateTime, index) => (
							<TableCell align="right" key={index} className="whitespace-nowrap">
								{format(new Date(dateTime), 'yyyy.MM.dd. HH:mm')}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{data.length > 0 && (
						<>
							{data.map(row => (
								<TableRow key={row.id}>
									<TableCell component="th" scope="row" className="whitespace-nowrap font-medium">
										{row.attribute}
									</TableCell>
									{row.valueList.map(value => (
										<TableCell className="font-mono" align="right">
											{value != null ? _.isObject(value) ? 'Components' : <LabelNumberValue value={value} thousandsSeparator decimalPlaces={4} displayAsPercentage={false} /> : ''}
										</TableCell>
									))}
								</TableRow>
							))}
							<TableRow>
								<TableCell className="font-mono" align="right" />
								{headers.map((dateTime, index) => (
									<TableCell className="font-mono" align="right">
										<Tooltip title="Load state">
											<span>
												<IconButton aria-label="load" onClick={e => handleLoad(dateTime)}>
													<LoadIcon fontSize="small" />
												</IconButton>
											</span>
										</Tooltip>
									</TableCell>
								))}
							</TableRow>
						</>
					)}
					{data.length === 0 && (
						<TableRow>
							<TableCell component="th" scope="row" colSpan={2}>
								No result
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</div>
	);
}

export default PiMeasurementReaderResult;
