import _ from '@lodash';
import EnumDto from 'modules/base/dto/enum/EnumDto';
import nBossConnection from 'modules/base/service/nBossConnection';

class TransformerService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'transformerControl';
	}

	/**
	 * @return {Promise<NamedDto[]>}
	 */
	getListSelection() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getListSelection`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<EnumDto[]>}
	 */
	getListSelectionEnum() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getListSelection`)
				.then(response => resolve(_.isArray(response.data) ? response.data.map(d => new EnumDto().setup({ label: d.name, value: d.name })) : []))
				.catch(reject);
		});
	}

	/**
	 * @param {number} workflowId
	 * @param {number} aspectId
	 * @param {Object} values
	 * @return {Promise<any>}
	 */
	calculateForWhatIf(workflowId, aspectId, values) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/calculateForWhatIf`, {
					workflowId,
					aspectId,
					values
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {Object} dto
	 * @return {Promise<Object>}
	 */
	calculate(dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/calculate`, dto)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {Object} dto
	 * @return {Promise<Object>}
	 */
	debug(dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/debug`, dto)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<any>}
	 */
	getInputAttributesListSelection(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getInputAttributesListSelection`, {
					id
				})
				.then(response => {
					resolve(response.data);
				})
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<any>}
	 */
	getOutputKeysSelection(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getOutputKeysSelection`, {
					id
				})
				.then(response => {
					resolve(response.data);
				})
				.catch(reject);
		});
	}

	/**
	 * @param {Object} definition
	 * @return {Promise<any>}
	 */
	processDefinition(definition) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/processDefinition`, {
					definition
				})
				.then(response => {
					resolve(response.data);
				})
				.catch(reject);
		});
	}

	/**
	 * @param {number} aspectId
	 * @param {number} transformerId
	 * @param {Object} values
	 * @param {boolean} autoConnect
	 * @return {Promise<any>}
	 */
	calculateAndTransform(aspectId, transformerId, values, autoConnect) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/calculateAndTransform`, {
					aspectId,
					transformerId,
					values,
					autoConnect
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new TransformerService();
