import EntityValidationResult from 'modules/base/dto/validation/EntityValidationResult';

class OcuEntityValidationResult extends EntityValidationResult {
	/**
	 * @module OcuEntityValidationResult
	 */
	constructor() {
		super();
		/** @type {boolean} */
		this.engineSuccessful = null;
		/** @type {string} */
		this.engineMessage = null;
	}

	/**
	 * @typedef {Object} _OcuEntityValidationResultProps
	 * @property {boolean} engineSuccessful
	 * @property {string} engineMessage
	 * @typedef {import("modules/base/dto/validation/EntityValidationResult").EntityValidationResultProps & _OcuEntityValidationResultProps} OcuEntityValidationResultProps
	 */
	/**
	 *
	 * @param {OcuEntityValidationResultProps} props
	 * @returns {OcuEntityValidationResult}
	 */
	setup(props) {
		super.setup(props);
		this.engineSuccessful = props.engineSuccessful;
		try {
			this.engineMessage = JSON.parse(props.engineMessage);
		} catch (e) {
			this.engineMessage = props.engineMessage;
		}
		return this;
	}
}

export default OcuEntityValidationResult;
