import _ from '@lodash';
import { format } from 'date-fns';
import { Button, TableCell } from '@material-ui/core';
import { useState } from 'react';
import { useHistory, withRouter } from 'react-router';
import TaskManagerResultOptimize from './TaskManagerResultOptimize';
import TaskManagerResultFit from './TaskManagerResultFit';
import TaskManagerResultCalculate from './TaskManagerResultCalculate';
import TaskManagerResultShap from './TaskManagerResultShap';
import TaskManagerResultGallery from './TaskManagerResultGallery';

const availableResults = ['Optimize', 'Fit', 'WhatIf', 'Calculate', 'Fake', 'Shap'];

function TaskManagerResultButton(props) {
	const { item } = props;
	const [showResult, setShowResult] = useState(null);

	const history = useHistory();

	if (item.status !== 'Done' || item.result === null || item.type === null || availableResults.indexOf(item.type) === -1)
		return <TableCell className="w-auto text-center" padding="none" />;

	if (item.type === 'Fake' && _.isObject(item.result) && item.result.datetime) {
		return (
			<TableCell className="p-4 md:p-16" scope="row">
				{format(new Date(item.result.datetime), 'yyyy.MM.dd. HH:mm')}
			</TableCell>
		);
	}

	const handleClick = () => {
		if (item.type === 'WhatIf') {
			history.push({
				pathname: `/what-if/edit/${item.definition.meta.id}`,
				state: item.result
			});
		} else {
			setShowResult(true);
		}
	};

	return (
		<TableCell className="w-auto" padding="none">

			<Button className="whitespace-nowrap" variant="contained" color="primary" size="small" onClick={() => handleClick()}>Show Result</Button>

			{showResult && item.type === 'Optimize' && <TaskManagerResultOptimize item={item} onClose={() => setShowResult(false)} />}
			{showResult && item.type === 'Fit' && <TaskManagerResultFit item={item} onClose={() => setShowResult(false)} />}
			{showResult && item.type === 'Calculate' && <TaskManagerResultCalculate item={item} onClose={() => setShowResult(false)} />}
			{showResult && item.type === 'Shap' && <TaskManagerResultShap item={item} onClose={() => setShowResult(false)} />}

		</TableCell>
	);
}

export default withRouter(TaskManagerResultButton);
