import { Tooltip, IconButton, TableCell, Button, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@material-ui/icons/Settings';
import { useDocumentItemForm } from './DocumentItemFormProvider';

function DocumentListTableModifyButton(props) {
	const { documentId, setDocumentId, setTabValue } = useDocumentItemForm();
	const { t } = useTranslation('tableBrowser');

	return (
		<TableCell className="text-center" padding="none">
			<Tooltip title={t('MODIFY')}>
				<span>
					<IconButton
						component={Button}
						onClick={() => {
							if (documentId == null || documentId !== props.item.id) {
								setDocumentId(props.item.id);
								setTabValue(2);
							}
						}}
						aria-label="modify"
						disabled={props.item && props.item.protectionLevel && props.item.protectionLevel === 'CannotBeModified'}
					>
						<SettingsIcon fontSize="small" />
					</IconButton>
				</span>
			</Tooltip>
		</TableCell>
	);
}

export default DocumentListTableModifyButton;
