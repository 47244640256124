import _ from '@lodash';
import WorkflowItem from '../WorkflowItem';

export const defaultDefinition = {
	name: null,
	input_fields: [{ name: 'Components', type: 'Components', enabled: true, value: null }],
	output_fields: [{ name: 'Components', type: 'Components' }],
	component_title: null,
	current_input_key: null
};

export const converter = function (data) {
	const selectedInput = data.input_fields.find(d=> d.enabled);
	const updatedInputFields = data.input_fields.map(field => ({ ...field, type: data.value_type }));
	const updatedOutputFields = data.output_fields.map(field => ({ ...field, type: data.value_type }));

	return { ...data, input_fields: updatedInputFields, output_fields: updatedOutputFields, current_input_key: selectedInput ? selectedInput.name : null };
};

export default function SwitchTransformation(props) {
	return <WorkflowItem {...props} type="SWITCH" />;
}
