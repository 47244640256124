import EditFormFreeSoloField from 'modules/ui/editform/fields/EditFormFreeSoloField';
import { Controller, useFormContext } from 'react-hook-form';

function OutputMappingComponent(props) {
	const methods = useFormContext();
	const outputMapping = methods.watch('output_keys');
	return (
		<>
			{outputMapping.map((output, i) => (
				<Controller
					key={i}
					control={methods.control}
					name={`output_mapping.${output}`}
					render={({ field, fieldState }) => (
						<EditFormFreeSoloField
							field={field}
							fieldState={fieldState}
							config={{ topic: 'transformer' }}
							fieldConfig={{
								key: `output_mapping.${output}`,
								text: output,
								multiple: true,
								query: () => new Promise(resolve => resolve([]))
							}}
						/>
					)}
				/>
			))}
		</>
	);
}

export default OutputMappingComponent;
