import _ from '@lodash';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import LimitValueType from 'app/admin/optimizationCase/dto/LimitValueType';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import EditFormGridComponent from 'modules/ui/editform/subForm/EditFormGridComponent';
import EditFormComponentMassSomethingField from 'app/admin/common/component/EditFormComponentMassSomethingField';
import StorageTransformerService from 'app/admin/workflow/service/StorageTransformerService';
import StorageAspectService from 'app/admin/aspect/service/StorageAspectService';
import { useParams, withRouter } from 'react-router';
import OptimizationCaseEditService from '../service/OptimizationCaseEditService';
import CreateOptimizerLogButton from '../component/CreateOptimizerLogButton';
import OptimizationCaseStateButton from '../component/OptimizationCaseStateButton';
import OptimizationGoalType from '../dto/OptimizationGoalType';
import OptimCaseLoadAspectAttributes from '../component/OptimCaseLoadAspectAttributes';

const config = data => {
const storageAspectService = new StorageAspectService();
const storageTransformerService = new StorageTransformerService();

	return {
		key: 'optimizationCaseEditForm',
		topic: 'optimizationCase',
		service: OptimizationCaseEditService,
		tabList: initData => {
			return [{ key: 'basic' }, { key: 'domains' }, { key: 'state_limits' }, { key: 'aspect_limits' }, { key: 'transformer_limits' }, { key: 'transformer_attributes' }];
		},
		fields: initData => {
			const fields = [
				{
					key: 'name',
					component: EditFormTextField,
					required: true,
					readOnly: data.id !== 'new'
				},
				{
					key: 'optimizer',
					component: EditFormSelectField,
					items: data.formData.optimizer
				},
				{
					key: 'optimizerLog',
					component: EditFormSelectField,
					items: data.formData.optimizerLog
				},
				{
					key: 'aspect',
					component: EditFormSelectField,
					items: data.formData.aspect
				},
				{
					key: 'transformer',
					component: EditFormSelectField,
					items: data.formData.transformer,
					onChange: (value, field, methods) => {console.log(value)}
				},                    
				{
					key: 'imputed_value',
					component: EditFormTextField,
					type: 'number'
				},
				{
					key: 'optimization_goal',
					component: EditFormSelectField,
					type: 'enum',
					items: Object.keys(OptimizationGoalType).map(key => {
						return { label: OptimizationGoalType[key], value: key };
					})
				},
				{
					key: 'output_key',
					component: EditFormSelectField,
					type: 'enum',
					filterKey: ['transformer', 'aspect'],
					items: formValues => {
						const transformer = formValues[0];
						const aspect = formValues[1];

						if (transformer != null) {
							return new Promise(resolve => {
								storageTransformerService.getOutputKeysSelection(transformer).then(outputs => {
									resolve([...(_.isArray(outputs) ? outputs : [])]);
								});
							});
						}
						if (transformer == null && aspect != null) {
							console.log(aspect)
							return new Promise(resolve => {
								storageAspectService.getInputAttributesListSelection(aspect).then(inputs => {
									resolve([...(_.isArray(inputs) ? inputs : [])]);
								});
							});
						}
						return new Promise(resolve => resolve([]));
					}
				},
				{
					key: 'domains',
					tab: 'domains',
					component: EditFormGridComponent,
					defaultValue: {
						id: null,
						enabled: true,
						name: null,
						type: null,
						value: null,
						min_value: null,
						max_value: null,
						step_count: null
					},

					fields: [
						{
							key: 'enabled',
							component: EditFormCheckboxField
						},
						{
							key: 'name',
							component: EditFormTextField,
							required: true
						},
						{
							key: 'type',
							component: EditFormSelectField,
							required: true,
							type: 'enum',
							items: [
								{ label: 'Variable', value: 'Variable' },
								{ label: 'ComponentMassFrac', value: 'ComponentMassFrac' }
							]
						},
						{
							key: 'value',
							component: EditFormTextField,
							required: true,
							type: 'number',
							dependsOn: 'type',
							visibleIf: type => type === 'Variable'
						},
						{
							key: 'value',
							component: EditFormComponentMassSomethingField,
							dependsOn: 'type',
							visibleIf: type => type === 'ComponentMassFrac'
						},
						{
							key: 'min_value',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'Variable' || type === 'SpreadSheet' || type === null,
							type: 'number'
						},
						{
							key: 'min_value',
							component: EditFormComponentMassSomethingField,
							loadValueFrom: 'value',
							loadValueMultiplier: 0.9,
							dependsOn: 'type',
							visibleIf: type => type === 'ComponentMassFrac',
							enumByField: 'components'
						},
						{
							key: 'max_value',
							component: EditFormTextField,
							required: true,
							dependsOn: 'type',
							visibleIf: type => type === 'Variable' || type === 'SpreadSheet' || type === null,
							type: 'number'
						},
						{
							key: 'max_value',
							component: EditFormComponentMassSomethingField,
							loadValueFrom: 'value',
							loadValueMultiplier: 1.1,
							dependsOn: 'type',
							visibleIf: type => type === 'ComponentMassFrac',
							enumByField: 'components'
						},
						{
							key: 'step_count',
							component: EditFormTextField,
							type: 'number',
							required: true
						}
					]
				},
				{
					key: 'state_limits',
					tab: 'state_limits',
					component: EditFormGridComponent,
					defaultValue: {
						id: null,
						name: null,
						enabled: true,
						hard: {
							type: Object.keys(LimitValueType)[0],
							low: null,
							high: null,
							value: null
						},
						soft: {
							type: Object.keys(LimitValueType)[0],
							low: null,
							high: null,
							value: null
						}
					},
					fields: [
						{
							key: 'enabled',
							component: EditFormCheckboxField
						},
						{
							key: 'name',
							component: EditFormSelectField,
							type: 'enum',
							filterKey: '^aspect',
							required: true,
							items: aspectId => {
								if (aspectId != null) {
									return new Promise(resolve => {
										storageAspectService.getInputAttributesListSelection(aspectId).then(inputs => {
											resolve([...(_.isArray(inputs) ? inputs : [])]);
										});
									});
								}
								return new Promise(resolve => resolve([]));
							}
						},
						{
							key: 'soft.type',
							component: EditFormSelectField,
							type: 'enum',
							hideNull: true,
							items: Object.keys(LimitValueType).map(key => {
								return { label: LimitValueType[key], value: key };
							})
						},
						{
							key: 'soft.low',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'soft.high',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'soft.value',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'hard.type',
							component: EditFormSelectField,
							type: 'enum',
							hideNull: true,
							items: Object.keys(LimitValueType).map(key => {
								return { label: LimitValueType[key], value: key };
							})
						},
						{
							key: 'hard.low',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'hard.high',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'hard.value',
							component: EditFormTextField,
							type: 'number'
						}
					]
				},
				{
					key: 'aspect_limits',
					tab: 'aspect_limits',
					component: EditFormGridComponent,
					defaultValue: {
						id: null,
						name: null,
						enabled: true,
						hard: {
							type: Object.keys(LimitValueType)[0],
							low: null,
							high: null,
							value: null
						},
						soft: {
							type: Object.keys(LimitValueType)[0],
							low: null,
							high: null,
							value: null
						}
					},
					fields: [
						{
							key: 'enabled',
							component: EditFormCheckboxField
						},
						{
							key: 'name',
							component: EditFormSelectField,
							type: 'enum',
							filterKey: '^aspect',
							required: true,
							items: aspectId => {
								if (aspectId != null) {
									return new Promise(resolve => {
										storageAspectService.getOutputKeysSelection(aspectId).then(outputs => {
											resolve([...(_.isArray(outputs) ? outputs : [])]);
										});
									});
								}
								return new Promise(resolve => resolve([]));
							}
						},
						{
							key: 'soft.type',
							component: EditFormSelectField,
							type: 'enum',
							hideNull: true,
							items: Object.keys(LimitValueType).map(key => {
								return { label: LimitValueType[key], value: key };
							})
						},
						{
							key: 'soft.low',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'soft.high',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'soft.value',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'hard.type',
							component: EditFormSelectField,
							type: 'enum',
							hideNull: true,
							items: Object.keys(LimitValueType).map(key => {
								return { label: LimitValueType[key], value: key };
							})
						},
						{
							key: 'hard.low',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'hard.high',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'hard.value',
							component: EditFormTextField,
							type: 'number'
						}
					]
				},
				{
					key: 'transformer_limits',
					tab: 'transformer_limits',
					component: EditFormGridComponent,
					defaultValue: {
						id: null,
						name: null,
						enabled: true,
						hard: {
							type: Object.keys(LimitValueType)[0],
							low: null,
							high: null,
							value: null
						},
						soft: {
							type: Object.keys(LimitValueType)[0],
							low: null,
							high: null,
							value: null
						}
					},
					fields: [
						{
							key: 'enabled',
							component: EditFormCheckboxField
						},
						{
							key: 'name',
							component: EditFormSelectField,
							type: 'enum',
							filterKey: '^transformer',
							required: true,
							items: transformerId => {
								if (transformerId != null) {
									return new Promise(resolve => {
										storageTransformerService.getOutputKeysSelection(transformerId).then(outputs => {
											resolve([...(_.isArray(outputs) ? outputs : [])]);
										});
									});
								}
								return new Promise(resolve => resolve([]));
							}
						},
						{
							key: 'soft.type',
							component: EditFormSelectField,
							type: 'enum',
							hideNull: true,
							items: Object.keys(LimitValueType).map(key => {
								return { label: LimitValueType[key], value: key };
							})
						},
						{
							key: 'soft.low',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'soft.high',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'soft.value',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'hard.type',
							component: EditFormSelectField,
							type: 'enum',
							hideNull: true,
							items: Object.keys(LimitValueType).map(key => {
								return { label: LimitValueType[key], value: key };
							})
						},
						{
							key: 'hard.low',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'hard.high',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'hard.value',
							component: EditFormTextField,
							type: 'number'
						}
					]
				},
				{
					key: 'transformer_attributes',
					tab: 'transformer_attributes',
					component: EditFormGridComponent,
					defaultValue: {
						id: null,
						name: null,
						type: null,
						value: null
					},

					fields: [
						{
							key: 'name',
							component: EditFormSelectField,
							required: true,
							type: 'enum',
							filterKey: '^transformer',
							items: transformerId => {
								if (transformerId != null) {
									return new Promise(resolve => {
										storageTransformerService.getInputAttributesListSelection(transformerId).then(outputs => {
											resolve([...(_.isArray(outputs) ? outputs : [])]);
										});
									});
								}
								return new Promise(resolve => resolve([]));
							}
						},
						{
							key: 'type',
							component: EditFormSelectField,
							required: true,
							type: 'enum',
							items: [
								{ label: 'Variable', value: 'Variable' },
								{ label: 'ComponentMassFlow', value: 'ComponentMassFlow' },
								{ label: 'ComponentMassFrac', value: 'ComponentMassFrac' }
							]
						},
						{
							key: 'value',
							component: EditFormTextField,
							required: true,
							type: 'number',
							dependsOn: 'type',
							visibleIf: type => type === 'Variable'
						},
						{
							key: 'value',
							component: EditFormComponentMassSomethingField,
							dependsOn: 'type',
							visibleIf: type => type === 'ComponentMassFrac' || type === 'ComponentMassFlow'
						}
					]
				}
			];

			return fields;
		}
	};
};

function OptimizationCaseEditForm() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [formData, setFormData] = useState(null);
	if (formData == null) {
		OptimizationCaseEditService.getFormData().then(resp => {
			setFormData(resp);
		});
		return <></>;
	}
	const myConfig = config({ id, formData });
	return (
		<EditFormPage
			config={myConfig}
			beforeSaveContent={
				<>
					<OptimCaseLoadAspectAttributes />
					<CreateOptimizerLogButton id={id} />
					<OptimizationCaseStateButton id={id} />
				</>
			}
			onSave={result => {
				if (!result.engineSuccessful) dispatch(showMessage({ message: `Engine: ${result.engineMessage}` }));
			}}
		/>
	);
}

export default withRouter(OptimizationCaseEditForm);
