import _ from '@lodash';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { TransComponentTitleField, TransFeatureColumnField, TransInputKeyField, TransNameField, TransOutputKeysField, TransValidationIOKey, TransValidationName } from '../TransformationProperties';
import { useWorkflow } from '../../WorkflowProvider';
import TransformationPropertiesDialog from '../TransformationPropertiesDialog';
import { converter } from './TableToScalarsTransformation';

function TableToScalarsProperties(props) {
	const { data, showPropertiesDialog, transformationNameAvailable } = useWorkflow();
	const { t } = useTranslation(['transformer', 'validation']);
	const item = data.transform.find(d => d.key === showPropertiesDialog.key);

	const schema = yup.object().shape({
		name: TransValidationName(transformationNameAvailable, item.key),
		input_fields: TransValidationIOKey(),
		output_keys: yup.array().min(1, t('validation:AT_LEAST_ONE_REQUIRED')).required(t('validation:FIELD_REQUIRED'))
	});

	return (
		<TransformationPropertiesDialog schema={schema} converter={converter} titleKey="tableToScalarsProperties">
			<TransNameField />
			<TransInputKeyField />
			<TransOutputKeysField />
			<TransFeatureColumnField />
			<TransComponentTitleField />
		</TransformationPropertiesDialog>
	);
}

export default TableToScalarsProperties;
