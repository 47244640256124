const piMeasurementReaderHu = {
	piMeasurementReaderEditForm: 'PI Measurement Reader',
	piMeasurementReaderTable: 'PI Measurement Reader',
	name: 'Name',
	username: 'Username',
	api_url: 'API URL',
	server_id: 'Server Id',
	time_zone: 'Timezone',
	password: 'Password',
	tag_name: 'Tag name',
	basic: 'Basic',
	fields: 'Fields',
	start_date_time: 'Kezdődátum',
	end_date_time: 'Végdátum',
	summary_type: 'Summary type',
	summary_duration: 'Summary duration',
	summary_duration_unit: 'Summary duration unit',
	uom: 'UOM',
	category: 'Category'
};

export default piMeasurementReaderHu;
