import FusePageCarded from '@fuse/core/FusePageCarded';
import TableBrowserHeader from './components/TableBrowserHeader';
import TableBrowserBase from './components/TableBrowserBase';

function TableBrowser(props) {
	return (
		<FusePageCarded
			classes={{
				content: 'flex',
				contentCard: 'overflow-hidden',
				header: 'min-h-72 h-72 sm:h-136 sm:min-h-136'
			}}
			header={<TableBrowserHeader config={props.config} />}
			content={<TableBrowserBase config={props.config} />}
			innerScroll
		/>
	);
}

export default TableBrowser;
