import { useState } from 'react';
import { motion } from 'framer-motion';
import { Close } from '@material-ui/icons';
import { FaTimes } from 'react-icons/fa';

import { Button, IconButton } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useWhatIf } from '../WhatIfProvider';
import CompareSidebarList from './CompareSidebarList';

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.background.default
	}
}));

function CompareSidebar(props) {
	const theme = useTheme();
	const classes = useStyles(props);
	const { compare, setCompare } = useWhatIf();
	const [baseCaseIndex, setBaseCaseIndex] = useState(1);
	const [filteredDiffGroupList, setFilteredDiffGroupList] = useState([]);

	return (
		<motion.div
			initial={compare != null ? 'visible' : 'hidden'}
			animate={compare != null ? 'visible' : 'hidden'}
			variants={{ visible: { translateX: 0 }, hidden: { translateX: '+100%' } }}
			transition={{ ease: 'easeInOut' }}
			className={clsx(classes.root, 'absolute right-0 top-0 h-full shadow-inner border-l')}
			style={{ minWidth: '600px' }}>

			<div className="flex flex-col gap-20 h-full relative">
				<div className="flex-1 overflow-y-scroll p-16">
					{compare != null && compare.name && <div className="py-8 px-12 mb-12 font-medium bg-white bg-opacity-70 backdrop-filter backdrop-blur-sm rounded-xl text-xs shadow-xl text-center">{compare.name}</div>}
					{compare != null && (
						<CompareSidebarList
							baseCaseIndex={baseCaseIndex}
							filteredDiffGroupList={filteredDiffGroupList}
							onToggleFilteredDiffGroupList={gKey => {
								filteredDiffGroupList.indexOf(gKey) > -1 ? setFilteredDiffGroupList(filteredDiffGroupList.filter(k => k !== gKey)) : setFilteredDiffGroupList([...filteredDiffGroupList, gKey]);
							}}
						/>
					)}
				</div>
				<div className="absolute p-6 pr-8 py-12 left-0 top-1/2 text-white rounded-r-xl cursor-pointer group shadow-xl" style={{ backgroundColor: theme.palette.primary.dark }} onClick={() => setCompare(null)} aria-hidden="true">
					<FaTimes className="opacity-80 group-hover:opacity-100 transition transform group-hover:rotate-180" />
				</div>
			</div>

		</motion.div>
	);
}

export default CompareSidebar;
