import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import OptimizerTableService from '../service/OptimizerTableService';
import AddOptimizerButton from '../component/AddOptimizerButton';

const config = data => {
	return {
		key: 'optimizerTable',
		topic: 'optimizer',
		service: OptimizerTableService,
		editFormState: item => `/optimizer/edit/${item.type}/${item ? item.id : 'new'}`,
		toolbarButtonList: [
			{
				key: 'addNew',
				place: 'before',
				component: AddOptimizerButton
			}
		],
		toolbarAction: ['search', 'refresh'],
		rowAction: ['modify', 'delete'],
		columns: [
			{
				key: 'name'
			},
			{
				key: 'type'
			}
		]
	};
};

function OptimizerTable() {
	const myConfig = config();
	return <TableBrowser config={myConfig} />;
}

export default withReducer('optimizerTable', reducer('optimizerTable'))(OptimizerTable);
