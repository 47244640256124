import _ from '@lodash';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import { useWhatIf } from '../component/WhatIfProvider';
import ColorPickerField from '../component/ColorPickerField';

function LabelItemProperties(props) {
	const { figure, setFigure, showPropertiesDialog, setShowPropertiesDialog, getIOValueKeySelection, iconset } = useWhatIf();
	const { t } = useTranslation('whatIf');
	const item = figure.label.find(d => d.key === showPropertiesDialog.key);
	if (_.isUndefined(item.thousandsSeparator)) item.thousandsSeparator = false;
	if (_.isUndefined(item.displayAsPercentage)) item.displayAsPercentage = false;
	if (_.isUndefined(item.showUnit)) item.showUnit = true;
	if (_.isUndefined(item.visible)) item.visible = true;
	if (_.isUndefined(item.icon)) item.icon = null;
	if (_.isUndefined(item.iconSize)) item.iconSize = 64;
	if (_.isUndefined(item.showLabel)) item.showLabel = true;
	if (_.isUndefined(item.diffEpsilonValue)) item.diffEpsilonValue = null;
	if (_.isUndefined(item.diffEpsilonPercent)) item.diffEpsilonPercent = null;
	if (_.isUndefined(item.layerType)) item.layerType = null;

	const methods = useForm({
		defaultValues: item
	});

	const handleSave = () => {
		const value = methods.getValues();
		setFigure({
			...figure,
			label: figure.label.map(c => (c.key === item.key ? value : c))
		});
		setShowPropertiesDialog(null);
	};

	return (
		<Dialog open fullWidth>
			<DialogTitle disableTypography className="border-b">
				{t('labelProperties')}
			</DialogTitle>
			<DialogContent className="p-20">
				<FormProvider {...methods}>
					<div className="flex flex-row">
						<Controller
							control={methods.control}
							name="name"
							render={({ field, fieldState }) => (
								<EditFormTextField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										className: 'w-4/5 mr-12',
										key: 'name',
										text: t('name')
									}}
								/>
							)}
						/>
						<div className="w-1/5">
							<Controller
								control={methods.control}
								name="visible"
								render={({ field, fieldState }) => (
									<EditFormCheckboxField
										field={field}
										fieldState={fieldState}
										config={{ topic: 'whatIf' }}
										fieldConfig={{
											key: 'visible',
											text: t('visible')
										}}
									/>
								)}
							/>
						</div>
					</div>
					<Controller
						control={methods.control}
						name="valueKey"
						render={({ field, fieldState }) => (
							<EditFormSelectField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'whatIf' }}
								fieldConfig={{
									key: 'valueKey',
									text: t('valueKey'),
									type: 'enum',
									emptyLabel: t('justALabel'),
									items: getIOValueKeySelection({ LayerType: item.layerType })
								}}
							/>
						)}
					/>
					<Controller
						control={methods.control}
						name="icon"
						render={({ field, fieldState }) => (
							<EditFormSelectField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'whatIf' }}
								fieldConfig={{
									key: 'icon',
									text: t('icon'),
									items: iconset
								}}
							/>
						)}
					/>
					<div className="flex flex-row">
						<Controller
							control={methods.control}
							name="fontSize"
							render={({ field, fieldState }) => (
								<EditFormTextField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'fontSize',
										text: t('fontSize'),
										type: 'number',
										className: 'mr-8'
									}}
								/>
							)}
						/>
						<Controller
							control={methods.control}
							name="iconSize"
							render={({ field, fieldState }) => (
								<EditFormTextField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'iconSize',
										text: t('iconSize'),
										type: 'number',
										className: 'ml-8 mr-8'
									}}
								/>
							)}
						/>
						<Controller
							control={methods.control}
							name="showLabel"
							render={({ field, fieldState }) => (
								<EditFormCheckboxField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'showLabel',
										text: t('showLabel')
									}}
								/>
							)}
						/>
					</div>
					<div className="flex flex-row">
						<Controller
							control={methods.control}
							name="diffEpsilonValue"
							render={({ field, fieldState }) => (
								<EditFormTextField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'diffEpsilonValue',
										text: t('diffEpsilonValue'),
										type: 'number',
										className: 'mr-8'
									}}
								/>
							)}
						/>
						<Controller
							control={methods.control}
							name="diffEpsilonPercent"
							render={({ field, fieldState }) => (
								<EditFormTextField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'diffEpsilonPercent',
										text: t('diffEpsilonPercent'),
										type: 'number'
									}}
								/>
							)}
						/>
					</div>
					<div className="flex flex-row">
						<Controller
							control={methods.control}
							name="displayAsPercentage"
							render={({ field, fieldState }) => (
								<EditFormCheckboxField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'displayAsPercentage',
										text: t('displayAsPercentage')
									}}
								/>
							)}
						/>
						<Controller
							control={methods.control}
							name="showUnit"
							render={({ field, fieldState }) => (
								<EditFormCheckboxField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'showUnit',
										text: t('showUnit')
									}}
								/>
							)}
						/>
					</div>
					<div className="flex flex-row">
						<Controller
							control={methods.control}
							name="thousandsSeparator"
							render={({ field, fieldState }) => (
								<EditFormCheckboxField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'thousandsSeparator',
										text: t('thousandsSeparator')
									}}
								/>
							)}
						/>
						<Controller
							control={methods.control}
							name="decimalPlaces"
							render={({ field, fieldState }) => (
								<EditFormTextField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'decimalPlaces',
										text: t('decimalPlaces'),
										type: 'number'
									}}
								/>
							)}
						/>
					</div>
					<Controller control={methods.control} name="borderColor" render={({ field, fieldState }) => <ColorPickerField key="borderColor" label="backgroundColorLabel" field={field} fieldState={fieldState} />} />
					<Controller control={methods.control} name="fillColor" render={({ field, fieldState }) => <ColorPickerField key="fillColor" label="fontColorLabel" field={field} fieldState={fieldState} />} />
				</FormProvider>
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button
					onClick={() => {
						setShowPropertiesDialog(null);
					}}
				>
					{t('cancel')}
				</Button>
				<Button onClick={() => handleSave()} color="primary" autoFocus>
					{t('save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
export default LabelItemProperties;
