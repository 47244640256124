import _ from '@lodash';

const componentContains = (queryText, components) => {
	if (queryText != null && queryText.length > 0 && _.isObject(components)) {
		const idx = Object.keys(components).findIndex(key => key.toLowerCase().includes(queryText.toLowerCase()));
		if (idx > -1) return true;
	}
	return false;
};

const containsDiff = data => {
	if (data.diffs && data.diffs.findIndex(d => d != null && d !== 0) > -1) {
		return true;
	}
	return false;
};

const getValueColumns = (indexTables, experimentList, baseCaseIndex, attribKey) => {
	const result = {
		values: new Array(experimentList.length).fill(null),
		diffs: new Array(experimentList.length).fill(null),
		diffPercents: new Array(experimentList.length).fill(null)
	};
	const attribKeyParts = attribKey.split('.');
	const isComponent = attribKeyParts.length > 3;
	let baseCaseValue = null;
	if (isComponent) {
		const mainKey = attribKeyParts.slice(0, 3).join('.');
		if (mainKey in indexTables[baseCaseIndex]) {
			const componentName = attribKeyParts[3];
			const attrib = experimentList[baseCaseIndex].attributes[indexTables[baseCaseIndex][mainKey]];
			if (_.isObject(attrib.value) && _.isNumber(attrib.value[componentName])) {
				result.values[0] = attrib.value[componentName];
				baseCaseValue = attrib.value[componentName];
			}
			let idx = 1;
			for (let i = 0; i < experimentList.length; i += 1) {
				if (i !== baseCaseIndex) {
					if (mainKey in indexTables[i]) {
						const anotherAttrib = experimentList[i].attributes[indexTables[i][mainKey]];
						if (_.isObject(anotherAttrib.value) && _.isNumber(anotherAttrib.value[componentName])) {
							result.values[idx] = anotherAttrib.value[componentName];
							if (baseCaseValue != null) {
								const diff = anotherAttrib.value[componentName] - baseCaseValue;
								const diffPercent = diff != null && diff !== 0 && baseCaseValue !== 0 ? diff / baseCaseValue : null;
								result.diffs[idx] = diff;
								result.diffPercents[idx] = diffPercent;
							}
						}
					}
					idx += 1;
				}
			}
		}
	} else if (attribKey in indexTables[baseCaseIndex]) {
		const attrib = experimentList[baseCaseIndex].attributes[indexTables[baseCaseIndex][attribKey]];
		if (_.isNumber(attrib.value)) {
			baseCaseValue = attrib.value;
			result.values[0] = attrib.value;
		}
		let idx = 1;
		for (let i = 0; i < experimentList.length; i += 1) {
			if (i !== baseCaseIndex) {
				if (attribKey in indexTables[i]) {
					const anotherAttrib = experimentList[i].attributes[indexTables[i][attribKey]];
					if (_.isNumber(anotherAttrib.value)) {
						result.values[idx] = anotherAttrib.value;
						if (baseCaseValue != null) {
							const diff = anotherAttrib.value - baseCaseValue;
							const diffPercent = diff != null && diff !== 0 && baseCaseValue !== 0 ? diff / baseCaseValue : null;
							result.diffs[idx] = diff;
							result.diffPercents[idx] = diffPercent;
						}
					}
				}
				idx += 1;
			}
		}
	}
	return result;
};

export default function CompareData(experimentList, baseCaseIndex, compareView, queryText, filteredDiffGroupList) {
	const result = {
		headerList: [experimentList[baseCaseIndex].name],
		groupList: []
	};
	experimentList.forEach((exp, index) => {
		if (index !== baseCaseIndex) {
			result.headerList.push(exp.name);
		}
	});
	if (compareView !== false) {
		const indexTables = [];
		experimentList.forEach((exp, idx) => {
			indexTables[idx] = {};
			exp.attributes.forEach((attrib, aidx) => {
				const valueKey = `${attrib.entity || 'aspect'}.${attrib.io}.${attrib.name}`;
				indexTables[idx][valueKey] = aidx;
			});
		});
		if (compareView == null) {
			result.groupList.push({
				key: 'inputs',
				name: 'Input attributes',
				data: []
			});
			result.groupList.push({
				key: 'outputs',
				name: 'Output attributes',
				data: []
			});
			experimentList[baseCaseIndex].attributes.forEach(attrib => {
				if (queryText.length === 0 || attrib.name.toLowerCase().includes(queryText.toLowerCase()) || componentContains(queryText, attrib.value)) {
					const valueKey = `${attrib.entity || 'aspect'}.${attrib.io}.${attrib.name}`;
					if (_.isObject(attrib.value)) {
						const gridRow = {
							id: valueKey,
							name: attrib.name,
							uom: attrib.uom,
							hasSub: true,
							isSub: false,
							type: attrib.type,
							io: attrib.io,
							values: []
						};
						result.groupList[attrib.io === 'INPUT' ? 0 : 1].data.push(gridRow);
						Object.keys(attrib.value).forEach(subKey => {
							if (queryText.length === 0 || subKey.toLowerCase().includes(queryText.toLowerCase())) {
								const subGridRow = {
									id: `${valueKey}.${subKey}`,
									name: subKey,
									uom: attrib.uom,
									hasSub: false,
									isSub: true,
									type: attrib.type,
									io: attrib.io,
									...getValueColumns(indexTables, experimentList, baseCaseIndex, `${valueKey}.${subKey}`)
								};
								if (filteredDiffGroupList.indexOf(attrib.io === 'INPUT' ? 'inputs' : 'outputs') === -1 || containsDiff(gridRow)) result.groupList[attrib.io === 'INPUT' ? 0 : 1].data.push(subGridRow);
							}
						});
					} else {
						const gridRow = {
							id: valueKey,
							name: attrib.name,
							uom: attrib.uom,
							hasSub: false,
							isSub: false,
							type: attrib.type,
							io: attrib.io,
							...getValueColumns(indexTables, experimentList, baseCaseIndex, valueKey)
						};
						if (filteredDiffGroupList.indexOf(attrib.io === 'INPUT' ? 'inputs' : 'outputs') === -1 || containsDiff(gridRow)) result.groupList[attrib.io === 'INPUT' ? 0 : 1].data.push(gridRow);
					}
				}
			});
		} else {
			compareView.groupList
				.sort((firstField, secondField) => {
					if (firstField.orderIndex !== null && secondField.orderIndex !== null) {
						if (firstField.orderIndex > secondField.orderIndex) return 1;
						if (firstField.orderIndex < secondField.orderIndex) return -1;
						return 0;
					}
					return 0;
				})
				.forEach(g => {
					result.groupList.push({
						key: g.id,
						name: g.name,
						headerColor: g.headerColor,
						data: []
					});
					const grpIdx = result.groupList.length - 1;
					const addedMainKeys = [];
					g.attributeList.forEach(attribKey => {
						const attribKeyParts = attribKey.split('.');
						const isComponent = attribKeyParts.length > 3;

						if (isComponent) {
							const mainKey = attribKeyParts.slice(0, 3).join('.');
							const componentName = attribKeyParts[3];
							if (mainKey in indexTables[baseCaseIndex]) {
								const attrib = experimentList[baseCaseIndex].attributes[indexTables[baseCaseIndex][mainKey]];
								if (_.isObject(attrib.value) && _.isNumber(attrib.value[componentName]) && (queryText.length === 0 || attribKey.toLowerCase().includes(queryText.toLowerCase()))) {
									if (addedMainKeys.indexOf(mainKey) === -1) {
										const gridRow = {
											id: mainKey,
											name: attrib.name,
											uom: attrib.uom,
											hasSub: true,
											isSub: false,
											type: attrib.type,
											io: attrib.io,
											values: []
										};
										result.groupList[grpIdx].data.push(gridRow);
										addedMainKeys.push(mainKey);
									}
									const subGridRow = {
										id: attribKey,
										name: componentName,
										uom: attrib.uom,
										hasSub: false,
										isSub: true,
										type: attrib.type,
										io: attrib.io,
										...getValueColumns(indexTables, experimentList, baseCaseIndex, attribKey)
									};
									if (filteredDiffGroupList.indexOf(g.id) === -1 || containsDiff(subGridRow)) result.groupList[grpIdx].data.push(subGridRow);
								}
							}
						} else if (attribKey in indexTables[baseCaseIndex] && (queryText.length === 0 || attribKey.toLowerCase().includes(queryText.toLowerCase()))) {
							const attrib = experimentList[baseCaseIndex].attributes[indexTables[baseCaseIndex][attribKey]];
							const gridRow = {
								id: attribKey,
								name: attrib.name,
								uom: attrib.uom,
								hasSub: false,
								isSub: false,
								type: attrib.type,
								io: attrib.io,
								...getValueColumns(indexTables, experimentList, baseCaseIndex, attribKey)
							};
							if (filteredDiffGroupList.indexOf(g.id) === -1 || containsDiff(gridRow)) result.groupList[grpIdx].data.push(gridRow);
						}
					});
				});
		}
	}
	return result;
}
