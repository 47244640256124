import _ from '@lodash';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useWorkflow } from '../../WorkflowProvider';
import GridProvider, { useGrid } from '../GridProvider';
import DodoConversion from './DodoConversion';
import { TransComponentTitleField, TransFeatureColumnField, TransInputKeyField, TransNameField, TransNumberField, TransOutputKeyField, TransValidationIOKey, TransValidationName } from '../TransformationProperties';
import TransformationPropertiesDialog from '../TransformationPropertiesDialog';
import { converter } from './DodoTransformation';

function DodoProperties() {
	const { data, showPropertiesDialog, transformationNameAvailable } = useWorkflow();
	const { t } = useTranslation(['transformer', 'validation']);
	const item = data.transform.find(d => d.key === showPropertiesDialog.key);
	const [tabValue, setTabValue] = useState(0);
	const { grid, setGrid } = useGrid();

	useEffect(() => {
		if (item)
			setGrid({
				rows: item.rows,
				columns: item.columns,
				conversion: item.conversion
			});
	}, [item]);

	const schema = yup.object().shape({
		name: TransValidationName(transformationNameAvailable, item.key),
		input_fields: TransValidationIOKey(),
		output_fields: TransValidationIOKey()
	});

	const handleTabChange = (event, value) => {
		setTabValue(value);
	};

	return (
		<TransformationPropertiesDialog
			maxWidth="lg"
			schema={schema}
			titleKey="dodoProperties"
			converter={converter}
			titleContent={
				<Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto" classes={{ root: 'h-64 bg-white' }}>
					<Tab className="h-64" label={t('basicInfo')} />
					<Tab className="h-64" label={t('conversion')} />
				</Tabs>
			}
			mergeValue={value => {
				return { ...value, ...grid };
			}}
			mergeProcess={(value, def) => {
				const result = { ...value, ...def };
				result.conversion = value.conversion;
				return result;
			}}
		>
			<div className={tabValue !== 0 ? 'hidden' : ''}>
				<TransNameField />
				<TransInputKeyField />
				<TransOutputKeyField />
				<TransNumberField fieldKey="multiplier" />
				<TransFeatureColumnField />
				<TransComponentTitleField />
			</div>
			<div className={tabValue !== 1 ? 'hidden' : ''}>{tabValue === 1 && <DodoConversion />}</div>
		</TransformationPropertiesDialog>
	);
}

export default function () {
	return (
		<GridProvider field="conversion">
			<DodoProperties />
		</GridProvider>
	);
}
