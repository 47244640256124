import nBossConnection from 'modules/base/service/nBossConnection';
import EntityValidationResult from 'modules/base/dto/validation/EntityValidationResult';
import NamedDto from 'modules/base/dto/NamedDto';

class WhatIfService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'whatIfControl';
	}

	/**
	 * @param {number} id
	 * @param {string} name
	 * @return {Promise<EntityValidationResult>}
	 */
	copy(id, name) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/copy`, {
					id,
					name
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<NamedDto[]>}
	 */
	getListSelection() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getListSelection`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<NamedDto>}
	 */
	getName(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getName`, { id })
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new WhatIfService();
