const whatIfEn = {
	whatIfGroupTable: 'What if group',
	whatIfGroupEditForm: 'What if group',
	whatIfTable: 'What if',
	whatIfExpertTable: 'What if',
	whatIfEditForm: 'What if',
	name: 'Name',
	created: 'Created',
	updated: 'Updated',
	back: 'Back',
	save: 'Save',
	cancel: 'Cancel',
	properties: 'Properties',
	circleProperties: 'Circle Properties',
	deviceProperties: 'Device Properties',
	labelProperties: 'Label Properties',
	rectProperties: 'Rectangle Properties',
	whatIfName: 'Name of What If',
	saveSuccessful: 'Save Successful',
	saveFailed: 'Save failed, all fields is required or name is already in use',
	move: 'Move',
	resize: 'Resize',
	rotate: 'Rotate',
	mirror: 'Mirror',
	devices: 'Devices',
	line: 'Line',
	delete: 'Delete',
	label: 'Label',
	pump: 'Pump',
	compressor: 'Compressor',
	airCooler: 'Air Cooler',
	furnace: 'Furnace',
	heatExchanger: 'Heat Exchanger',
	distillationColumn: 'Distillation Column',
	circle: 'Circle',
	fillColorLabel: 'Fill Color',
	fillLabel: 'Fill',
	borderColorLabel: 'Border Color',
	backgroundColorLabel: 'Background Color',
	fontColorLabel: 'Font Color',
	color_black: 'Black',
	color_white: 'White',
	color_darkRed: 'Red',
	color_darkGreen: 'Green',
	color_darkBlue: 'Blue',
	fontSize: 'Font Size',
	value: 'Value',
	justALabel: 'Just a label',
	imageProperties: 'Image properties',
	imageKey: 'Image',
	image: 'Image',
	none: 'None',
	'group.name': 'Group',
	group: 'Group',
	decimalPlaces: 'Decimal places',
	thousandsSeparator: 'Thousands separator',
	displayAsPercentage: 'Display as percentage',
	workflow: 'Workflow',
	valueKey: 'Value key',
	type: 'Type',
	variable: 'Variable value',
	dict: 'Dict value',
	showUnit: 'Show unit',
	sumTable: 'Summary table',
	sumTableProperties: 'Summary table properties',
	minMax: 'Min/Max',
	documentProperties: 'Documents properties',
	revision: 'Revision',
	'creator.name': 'Uploader',
	file: 'File',
	close: 'Close',
	visible: 'Visible',
	document: 'Document',
	note: 'Note',
	noteProperties: 'Note Properties',
	state: 'State',
	values: 'Values',
	reader: 'Reader',
	'aspect.name': 'Aspect',
	'transformer.name': 'Transformer',
	aspect: 'Aspect',
	icon: 'Icon',
	iconSize: 'Icon size',
	showLabel: 'Show label',
	input: 'Input',
	output: 'Output',
	tableInDialog: 'Table in a dialog',
	container: 'Container',
	containerProperties: 'Container properties',
	figureNameNotUnique: 'Container name not unique',
	linkTo: 'Link to another What if',
	linkToDialog1: 'Most átleszel irányítva a következő What if-re:',
	linkToDialog2: 'A mentetlen adatok elvesznek. Folytatja?',
	linkToError: 'Hivatkozott What If hiányzik vagy törölve lett.',
	piMeasurementReader: 'PI Measurement Reader',
	diffEpsilonValue: 'Difference epsilon value',
	diffEpsilonPercent: 'Difference epsilon percentage'
};

export default whatIfEn;
