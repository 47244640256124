import _ from '@lodash';
import NamedDto from 'modules/base/dto/NamedDto';

export function fixExperimentAttributes(attributes) {
	if (attributes) {
		return attributes.map(attrib => {
			if (_.isUndefined(attrib.entity)) {
				attrib.entity = 'aspect';
			}
			return attrib;
		});
	}
	return attributes;
}

export class WhatIfExperimentTableDto extends NamedDto {
	constructor() {
		super();
		/** @type {NamedDto} */
		this.aspect = null;
		/** @type {NamedDto} */
		this.transformer = null;
		/** @type {NamedDto} */
		this.whatIf = null;
		/** @type {NamedDto} */
		this.creator = null;
		/** @type {Date} */
		this.created = null;
		/** @type {Object[]} */
		this.attributes = null;
	}

	/**
	 * @typedef {Object} _WhatIfExperimentTableDtoProps
	 * @property {NamedDto} creator
	 * @property {NamedDto} aspect
	 * @property {NamedDto} transformer
	 * @property {NamedDto} whatIf
	 * @property {Date} created
	 * @property {Object[]} attributes
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _WhatIfExperimentTableDtoProps} WhatIfExperimentTableDtoProps
	 */
	/**
	 *
	 * @param {WhatIfExperimentTableDtoProps} props
	 * @returns {WhatIfExperimentTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.creator = props.creator !== null ? new NamedDto().setup(props.creator) : null;
		this.aspect = props.aspect !== null ? new NamedDto().setup(props.aspect) : null;
		this.transformer = props.transformer !== null ? new NamedDto().setup(props.transformer) : null;
		this.whatIf = props.whatIf !== null ? new NamedDto().setup(props.whatIf) : null;
		this.created = props.created != null ? new Date(props.created) : null;
		this.attributes = fixExperimentAttributes(props.attributes);
		return this;
	}
}

export default WhatIfExperimentTableDto;
