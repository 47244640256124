const driverEn = {
	driverTable: 'Driver',
	driverEditForm: 'Driver',
	name: 'Name',
	code: 'Code',
	created: 'Created',
	updated: 'Updated',
	modelType: 'Model type'
};

export default driverEn;
