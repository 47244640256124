import { useState, useEffect } from "react";
import _ from "@lodash";
import mime from "mime-types";
import { format } from "date-fns";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon } from "@material-ui/core";
import { FaFileImage, FaFileLines } from "react-icons/fa6";

import TaskManagerService from "app/admin/taskManager/service/TaskManagerService";

export default function TaskManagerResultGallery(props) {
	const { id, groupName } = props.item;

	const [response, setResponse] = useState(null);

	useEffect(() => {
		TaskManagerService.listFiles(id, groupName).then(resp => setResponse(resp));
	}, [id]);

	return (
		<Dialog open fullWidth>

			<DialogTitle disableTypography className="border-b">
				{props.title || "Available Files"}
			</DialogTitle>

			<DialogContent className="p-20">
				<div className="grid grid-cols-3 gap-8 w-full">
					{response && response.map(_item => (<ListItem key={_item.name} data={_item} taskManagerId={id} />))}
				</div>
			</DialogContent>

			<DialogActions className="justify-between border-t p-20">
				<Button onClick={() => { props.onClose(); }}>
					Close
				</Button>
			</DialogActions>

		</Dialog>
	);
}

function ListItem(props) {
	const handleClick = () => {
		const url = window.location.protocol + "//" + window.location.host + "/mol-ocu-portal/methods/taskManagerControl/getFileContent?taskManagerId=" + props.taskManagerId + "&name=" + props.data.name;
		window.open(url, "_blank");
	};

	return (
		<button type="button" className="text-left w-full flex items-center gap-8 p-8 rounded-xl border shadow-sm transition-colors hover:bg-grey-100" onClick={() => handleClick()}>

			{props.data.type.indexOf("image") > -1 ? <FaFileImage className="mx-2 text-2xl opacity-30" /> : <FaFileLines className="mx-2 text-2xl opacity-30" />}

			<div className="flex-1 flex flex-col overflow-hidden">

				<div className={"flex-1 break-all " + (props.data.name.length > 16 ? "text-10" : "text-13")}>{props.data.name}<span className="opacity-50 inline-block">.{mime.extension(props.data.type)}</span></div>
				<div className="w-full text-10 opacity-75">{format(new Date(props.data.ts), "yyyy.MM.dd. HH:mm")}</div>

			</div>

		</button>
	);
}