import _ from '@lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { openDialog, closeDialog } from 'app/store/fuse/dialogSlice';
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

function ConfirmDialog(props) {
	const dispatch = useDispatch();
	const { t } = useTranslation('common');
	const state = useSelector(({ fuse }) => fuse.dialog.state);

	const handleOnClose = reason => {
		if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
			if (_.isFunction(props.onNo)) props.onNo();
		}
		dispatch(closeDialog());
		props.onClose();
	};

	useEffect(() => {
		if (props.show) {
			dispatch(
				openDialog({
					onClose: (ev, reason) => handleOnClose(reason),
					children: (
						<>
							<DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
							<DialogContent>
								<DialogContentText id="alert-dialog-description">{props.description}</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={() => {
										if (_.isFunction(props.onNo)) props.onNo();
										dispatch(closeDialog());
										props.onClose();
									}}
									color="primary"
								>
									{t('NO')}
								</Button>
								<Button
									onClick={() => {
										if (_.isFunction(props.onYes)) props.onYes();
										dispatch(closeDialog());
										props.onClose();
									}}
									color="primary"
									autoFocus
								>
									{t('YES')}
								</Button>
							</DialogActions>
						</>
					)
				})
			);
		}
	}, [props.show]);
	return <></>;
}

export default ConfirmDialog;
