import _ from '@lodash';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { generateId } from 'app/admin/whatIf/component/WhatIfUtil';
import { useBlender } from './BlenderProvider';
import GridComponent from '../GridComponent';

export default function BlenderConversion(props) {
	const methods = useBlender();
	const { getValues } = useFormContext();
	const inputValues = getValues('input_fields')[0].value;
	const { grid, setGrid } = methods;
	if (!_.isUndefined(inputValues) && inputValues != null) {
		const inputKeys = Object.keys(inputValues);
		const newGrid = { ...grid, rows: grid.rows.filter(d => inputKeys.indexOf(d.name) > -1), conversion: grid.conversion.filter(conv => inputKeys.indexOf(grid.rows.find(d => d.key === conv.row).name) > -1) };

		inputKeys.forEach(inp => {
			if (newGrid.rows.findIndex(d => d.name === inp) === -1) {
				const newRowKey = generateId();
				newGrid.rows.push({
					key: newRowKey,
					name: inp
				});
				newGrid.columns.forEach(col => {
					newGrid.conversion.push({
						key: generateId(),
						row: newRowKey,
						column: col.key,
						value: null
					});
				});
			}
		});

		if (!_.isEqual(newGrid.rows, grid.rows)) {
			setGrid(newGrid);
			return <></>;
		}
	}
	return <GridComponent {...methods} type="conversion" showTotal />;
}
