import NamedDto from 'modules/base/dto/NamedDto';

export default class PictogramDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.file = null;
	}

	/**
	 * @typedef {Object} _PictogramDtoProps
	 * @property {string} file
	 *
	 * @typedef {import("../NamedDto").NamedDtoProps & _PictogramDtoProps} PictogramDtoProps
	 */
	/**
	 *
	 * @param {PictogramDtoProps} props
	 * @returns {PictogramDto}
	 */
	setup(props) {
		super.setup(props);
		this.file = props.file;
		return this;
	}
}
