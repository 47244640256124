import _ from '@lodash';
import { isFunction } from 'lodash';

const determineFieldPath = (fieldConfig, fieldPrefix) => {
	return !_.isUndefined(fieldPrefix) && fieldPrefix !== null ? fieldPrefix + fieldConfig.key : fieldConfig.key;
};

export const determineEditableAccess = (config, fieldConfig, fieldPrefix) => {
	const fieldPath = determineFieldPath(fieldConfig, fieldPrefix);
	if (!_.isUndefined(config.editableAccess)) {
		let editableAccess = null;
		if (isFunction(config.editableAccess)) editableAccess = config.editableAccess();
		else editableAccess = config.editableAccess;
		if (editableAccess === 'NoAccess') return true;
		if (editableAccess === 'Full') return false;
		if (editableAccess === 'Restricted') {
			if (_.isUndefined(config.editableFields) || config.editableFields === null) return true;
			if (fieldPath.includes('.')) {
				const splittedFieldPath = fieldPath.split('.').filter(item => Number.isNaN(Number(item)));
				return config.editableFields.indexOf(splittedFieldPath.join('.')) === -1;
			}
			return config.editableFields.indexOf(fieldPath) === -1;
		}
	}

	return fieldConfig.readOnly || false;
};

export const fixDependsOn = (dependsOn, fieldPrefix) => {
	const result = [];
	if (_.isUndefined(dependsOn)) return result;
	if (_.isString(dependsOn)) return fixDependOn(dependsOn, fieldPrefix);
	if (_.isArray(dependsOn)) {
		dependsOn.forEach(d => {
			result.push(fixDependOn(d, fieldPrefix));
		});
	}
	return result;
};

export const fixDependOn = (dependOn, fieldPrefix) => {
	if (dependOn.startsWith('^')) return dependOn.substring(1);
	if (fieldPrefix) {
		return `${fieldPrefix}${dependOn}`;
	}
	return dependOn;
};

export default {};
