import { useEffect, useState, useRef } from 'react';
import { Group, Tag, Text, Rect, Label } from 'react-konva';
import PropertiesSvgBtn from './PropertiesSvgBtn';
import { useWhatIf } from '../component/WhatIfProvider';

const itemBorder = 5;

function calcWidth(textRef, compareTextRef) {
	if (textRef && compareTextRef) {
		return Math.max(textRef.width(), compareTextRef.width()) + itemBorder * 2;
	}
	if (textRef) {
		return textRef.width() + itemBorder * 2;
	}
	return 0;
}

function calcHeight(textRef, compareTextRef) {
	if (textRef && compareTextRef) {
		return Math.max(textRef.height(), compareTextRef.height()) + itemBorder * 2;
	}
	if (textRef) {
		return textRef.height() + itemBorder * 2;
	}
	return 0;
}

function LabelOnlyView(props) {
	const { setFloatLabelCompare } = useWhatIf();
	const { item, labelText, compareData, isDragging, enablePropertiesBtn, onPropertiesClick, invalid } = props;
	const textRef = useRef(null);
	const compareTextRef = useRef(null);
	const width = calcWidth(textRef.current, compareData != null ? compareTextRef.current : null);
	const height = calcHeight(textRef.current, compareData != null ? compareTextRef.current : null);

	const handlePointerEnter = () => {
		if (compareData && compareData.base != null) setFloatLabelCompare(compareData);
	};

	const handlePointerLeave = () => {
		if (compareData && compareData.base != null) setFloatLabelCompare(null);
	};

	return (
		<Group {...props} rotation={item.rotate} width={width} height={height} opacity={invalid ? 0.5 : 1}>
			<Label x={itemBorder} y={itemBorder} visible={compareData == null}>
				<Tag fill={item.borderColor} />
				<Text padding={itemBorder} ref={textRef} text={labelText} fontSize={item.fontSize} fill={item.fillColor} fillAfterStrokeEnabled />
			</Label>
			<Label x={itemBorder} y={itemBorder} visible={compareData != null}>
				<Tag fill={item.borderColor} />
				<Text
					padding={itemBorder}
					ref={compareTextRef}
					text={compareData ? compareData.diffLabel : null}
					fontSize={item.fontSize}
					fill={compareData && compareData.showDiff ? (compareData.diff > 0 ? 'red' : 'green') : item.fillColor}
					fillAfterStrokeEnabled
				/>
			</Label>
			<Rect width={width} height={height} onPointerEnter={() => handlePointerEnter()} onPointerLeave={() => handlePointerLeave()} />
			<PropertiesSvgBtn enablePropertiesBtn={enablePropertiesBtn} handleClick={() => onPropertiesClick && onPropertiesClick()} />
			{textRef.current != null && <Rect width={width} height={height} dash={[10, 10]} stroke="black" strokeWidth={1} visible={isDragging} listening={false} />}
		</Group>
	);
}
/*
    {compareData && compareData.diff != null && compareData.diff > 0 && <CompareArrowIcon color="green" iconName="arrow_up" size={height - itemBorder * 2} x={width} y={itemBorder} visible={compareView} />}
    {compareData && compareData.diff != null && compareData.diff < 0 && <CompareArrowIcon color="red" iconName="arrow_down" size={height - itemBorder * 2} x={width} y={itemBorder} visible={compareView} />}
*/
export default LabelOnlyView;
