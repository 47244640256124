import _ from 'lodash';
import NamedDto from 'modules/base/dto/NamedDto';

export default class PiMeasurementReaderFieldEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.tag_name = null;
		/** @type {string} */
		this.uom = null;
		/** @type {string} */
		this.category = null;
	}

	/**
	 * @typedef {Object} _PiMeasurementReaderFieldEditDtoProps
	 * @property {string} tag_name
	 * @property {string} uom
	 * @property {string} category
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _PiMeasurementReaderFieldEditDtoProps} PiMeasurementReaderFieldEditDtoProps
	 */
	/**
	 *
	 * @param {PiMeasurementReaderFieldEditDtoProps} props
	 * @returns {PiMeasurementReaderFieldEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.tag_name = props.tag_name;
		this.uom = props.uom;
		this.category = props.category;
		return this;
	}
}
