import { useState, useRef } from 'react';
import { useInterval } from 'react-timers-hooks';
import { logoutUser } from 'app/auth/store/userSlice';
import Badge from '@material-ui/core/Badge';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch } from 'react-redux';
import withReducer from 'app/store/withReducer';
import reducer from './store';
import { toggleNotificationPanel } from './store/stateSlice';
import NotificationService from './service/NotificationService';

function NotificationPanelToggleButton(props) {
	const [status, setStatus] = useState(null);
	const dispatch = useDispatch();
	const callRunning = useRef(false);

	useInterval(() => {
		if (callRunning.current === false) {
			callRunning.current = true;
			NotificationService.getStatus()
				.then(s => {
					callRunning.current = false;
					setStatus(s);
				})
				.catch(error => {
					callRunning.current = false;
					if (error && error.response && error.response.status === 403) dispatch(logoutUser());
				});
		}
	}, 5000);

	return (
		<IconButton className="w-40 h-40" onClick={ev => dispatch(toggleNotificationPanel())}>
			<Badge color="secondary" max={99} badgeContent={status ? status.count : 0}>
				{props.children}
			</Badge>
		</IconButton>
	);
}

NotificationPanelToggleButton.defaultProps = {
	children: <Icon>notifications</Icon>
};

export default withReducer('notificationPanel', reducer)(NotificationPanelToggleButton);
