import { Tooltip, IconButton, TableCell } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

function TableExportDataButton(props) {
	return (
		<TableCell className="w-auto text-center" padding="none">
			<Tooltip title={props.config.exportData.tooltip}>
				<IconButton component="a" href={props.config.exportData.link ? props.config.exportData.link(props.item) : false} target="_blank">
					<ExitToAppIcon fontSize="small" />
				</IconButton>
			</Tooltip>
		</TableCell>
	);
}

export default TableExportDataButton;
