import _ from '@lodash';
import * as yup from 'yup';
import { Controller, useFormContext } from 'react-hook-form';
import EditFormFreeSoloField from 'modules/ui/editform/fields/EditFormFreeSoloField';
import { useTranslation } from 'react-i18next';
import { useWorkflow } from '../../WorkflowProvider';
import { TransComponentTitleField, TransFeatureColumnField, TransInputKeyField, TransNameField, TransOutputKeyField, TransTextField, TransValidationIOKey, TransValidationName } from '../TransformationProperties';
import TransformationPropertiesDialog from '../TransformationPropertiesDialog';

function MergeComponentsProperties(props) {
	const { data, showPropertiesDialog, transformationNameAvailable } = useWorkflow();
	const { t } = useTranslation(['transformer', 'validation']);
	const item = data.transform.find(d => d.key === showPropertiesDialog.key);

	const schema = yup.object().shape({
		name: TransValidationName(transformationNameAvailable, item.key),
		input_fields: TransValidationIOKey(),
		output_fields: TransValidationIOKey(),
		input_components: yup.array().min(1, t('validation:AT_LEAST_ONE_REQUIRED')).required('validation:FIELD_REQUIRED'),
		output_component: yup.string().nullable().required(t('validation:FIELD_REQUIRED'))
	});

	return (
		<TransformationPropertiesDialog schema={schema} titleKey="mergeComponentsProperties">
			<TransNameField />
			<TransInputKeyField />
			<MergeInputComponents />
			<TransOutputKeyField />
			<TransTextField fieldKey="output_component" required />
			<TransFeatureColumnField />
			<TransComponentTitleField />
		</TransformationPropertiesDialog>
	);
}

function MergeInputComponents() {
	const methods = useFormContext();
	return (
		<Controller
			control={methods.control}
			name="input_components"
			render={({ field, fieldState }) => (
				<EditFormFreeSoloField
					field={field}
					fieldState={fieldState}
					config={{ topic: 'transformer' }}
					fieldConfig={{
						key: 'input_components',
						multiple: true,
						query: () => new Promise(resolve => resolve([])),
						required: true
					}}
				/>
			)}
		/>
	);
}
export default MergeComponentsProperties;
