import IdentifiableDto from 'modules/base/dto/IdentifiableDto';

export class NotificationStatusDto extends IdentifiableDto {
	/**
	 * @module NotificationStatusDto
	 */
	constructor() {
		super();
		/** @type {number} */
		this.count = null;
	}

	/**
	 * @typedef {Object} _NotificationStatusDtoProps
	 * @property {number} count
	 * @typedef {import("./IdentifiableDto").IdentifiableDtoProps & _NotificationStatusDtoProps} NotificationStatusDtoProps
	 */
	/**
	 *
	 * @param {NotificationStatusDtoProps} props
	 * @returns {NotificationStatusDto}
	 */
	setup(props) {
		super.setup(props);
		this.count = props.count;
		return this;
	}
}

export default NotificationStatusDto;
