import _ from '@lodash';
import { useEffect, useState, useRef } from 'react';
import { Transformer, Ellipse, Text, Circle, Rect } from 'react-konva';
import { useWhatIf } from '../component/WhatIfProvider';
import { calcJoinPointsByLinePoints } from '../component/WhatIfUtil';

const sensitivity = 6;
const itemBorder = 5;
const joinPointRadius = 6;

const calcBorderLinePoints = (item, offset) => {
	const _w = item.radius * 2 * item.scaleX + itemBorder * 2;
	//const _h = item.radius * 2 * item.scaleY + itemBorder * 2;
	const tl = [item.x - offset.x, item.y - offset.y];
	const tr = [tl[0] + _w, item.y - offset.y];
	const bl = [tl[0], item.y + offset.y];
	const br = [tr[0], item.y + offset.y];
	return [...tl, ...tr, ...br, ...bl, ...tl];
};

function CircleItem(props) {
	const { item } = props;
	const offset = { x: (item.radius * 2 * item.scaleX) / 2 + itemBorder, y: (item.radius * 2 * item.scaleY) / 2 + itemBorder };
	const width = item.radius * 2 * item.scaleX;
	const height = item.radius * 2 * item.scaleY;
	const [pos, setPos] = useState({ x: item.x, y: item.y });
	const [isDragging, setIsDragging] = useState(false);
	const [activeJoinPoint, setActiveJoinPoint] = useState(null);
	const { editMode, data, setData, figure, setFigure, underEditing, setUnderEditing, setShowPropertiesDialog, checkStageCorner, initLine, endLine } = useWhatIf();
	const circleRef = useRef();
	const trRef = useRef();
	const labelWidth = item.rotate === 90 || item.rotate === 270 ? item.radius * item.scaleY : item.radius * item.scaleX;
	const borderLinePoints = calcBorderLinePoints(item, offset);
	const joinPoints = calcJoinPointsByLinePoints(borderLinePoints);

	useEffect(() => {
		if (editMode === 'RESIZE' && underEditing !== null && underEditing.key === item.key) {
			trRef.current.nodes([circleRef.current]);
			trRef.current.getLayer().batchDraw();
		}
	}, [underEditing]);

	const handleItemClick = () => {
		if (editMode === 'DELETE') {
			setFigure({
				...figure,
				circle: figure.circle.filter(d => d.key !== item.key)
			});
		} else if (editMode === 'RESIZE') {
			setUnderEditing(item);
		} else if (editMode === 'ROTATE') {
			setFigure({
				...figure,
				circle: figure.circle.map(d => (d.key === item.key ? { ...d, scaleX: d.scaleY, scaleY: d.scaleX } : d))
			});
		}
	};

	const handleResize = e => {
		const circleNode = circleRef.current;
		const _w = circleNode.width() * circleNode.scaleX();
		const _h = circleNode.height() * circleNode.scaleY();
		const scaleX = _w / (item.radius * 2);
		const scaleY = _h / (item.radius * 2);
		const currentPos = { x: circleNode.x(), y: circleNode.y() };
		setPos(currentPos);
		setFigure({
			...figure,
			circle: figure.circle.map(d => {
				if (d.key === item.key) {
					return { ...item, ...currentPos, scaleX, scaleY };
				}
				return d;
			})
		});
	};

	const updateDragPos = e => {
		const currentPos = { x: e.target.x(), y: e.target.y() };
		setPos(currentPos);
		setFigure({
			...figure,
			circle: figure.circle.map(d => {
				if (d.key === item.key) {
					return { ...item, ...currentPos };
				}
				return d;
			})
		});
	};

	const handleJoinPointEnter = id => {
		if (editMode === 'LINE') {
			setActiveJoinPoint(id);
		}
	};

	const handleJoinPointLeave = i => {
		if (editMode === 'LINE') {
			setActiveJoinPoint(null);
		}
	};

	const handleJoinPointClick = (id, e) => {
		if (editMode === 'LINE') {
			const joinPoint = joinPoints[id];
			if (underEditing !== null) {
				endLine(joinPoint.x, joinPoint.y);
			} else {
				initLine(joinPoint.x, joinPoint.y);
			}
		}
	};

	return (
		<>
			<Ellipse
				onPointerClick={() => handleItemClick()}
				onPointerDblClick={() => editMode === 'MOVE' && setShowPropertiesDialog({ type: 'CIRCLE', key: item.key })}
				ref={circleRef}
				x={pos.x}
				y={pos.y}
				radius={{ x: item.radius * item.scaleX, y: item.radius * item.scaleY }}
				width={width}
				height={height}
				fill={item.fill ? item.fillColor : null}
				stroke={item.borderColor}
				onTransformEnd={e => handleResize(e)}
				scaleX={1}
				scaleY={1}
				draggable={editMode === 'MOVE'}
				onDragStart={() => {
					setIsDragging(true);
				}}
				onDragMove={e => {
					updateDragPos(e);
					checkStageCorner();
				}}
				onDragEnd={e => {
					setIsDragging(false);
					updateDragPos(e);
				}}
			/>
			<Rect x={pos.x - offset.x} y={pos.y - offset.y} width={width + itemBorder * 2} height={height + itemBorder * 2} dash={[10, 10]} stroke="black" strokeWidth={1} opacity={isDragging ? 1 : 0} listening={false} />
			{joinPoints.map((j, i) => (
				<Circle
					key={i}
					x={j.x}
					y={j.y}
					radius={joinPointRadius}
					fill="darkgreen"
					opacity={activeJoinPoint === i ? 1 : 0}
					onPointerEnter={e => handleJoinPointEnter(i)}
					onPointerLeave={e => handleJoinPointLeave(i)}
					onPointerClick={e => handleJoinPointClick(i, e)}
				/>
			))}
			{!_.isEmpty(item.name) && (underEditing === null || underEditing.key !== item.key) && (
				<Text
					text={item.name}
					fontSize={14}
					fill="black"
					fontStyle="bold"
					x={pos.x}
					y={pos.y}
					width={labelWidth}
					offsetX={labelWidth / 2}
					offsetY={8}
					align="center"
					stroke="white"
					strokeWidth={3}
					fillAfterStrokeEnabled
					listening={false}
				/>
			)}
			{underEditing !== null && underEditing.key === item.key && (
				<Transformer
					ref={trRef}
					padding={itemBorder}
					borderDash={[10, 10]}
					borderStroke="black"
					borderStrokeWidth={1}
					anchorStroke="black"
					rotateEnabled={false}
					boundBoxFunc={(oldBox, newBox) => {
						if (newBox.width < 12 || newBox.height < 12) {
							return oldBox;
						}
						return newBox;
					}}
				/>
			)}
		</>
	);
}

export default CircleItem;
