const FieldType = {
	Double: 'Double',
	Dict: 'Dict',
	Text: 'Text',
	Integer: 'Integer',
	Timestamp: 'String',
	Boolean: 'Boolean'
};

export default FieldType;
