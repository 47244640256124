import _ from '@lodash';
import NumberValue from 'app/admin/common/component/NumberValue';
import { useState, useEffect } from 'react';
import { useWhatIf } from '../component/WhatIfProvider';

const maxItem = 4;

export default function FloatValue(props) {
	const { containerRef } = props;
	const [coords, setCoords] = useState({ x: 0, y: 0 });
	const { floatValue } = useWhatIf();

	useEffect(() => {
		const handleWindowMouseMove = event => {
			setCoords({
				x: event.clientX,
				y: event.clientY
			});
		};
		window.addEventListener('mousemove', handleWindowMouseMove);

		return () => {
			window.removeEventListener('mousemove', handleWindowMouseMove);
		};
	}, []);

	if (!containerRef.current) return <></>;
	const containerCoords = containerRef.current.getBoundingClientRect();

	const renderObject = () => {
		const rows = Object.keys(floatValue.value);
		return (
			<table>
				<thead>
					<tr>
						<th align="left" className="pr-8">
							Name
						</th>
						<th align="right">Value</th>
					</tr>
				</thead>
				<tbody>
					{rows.slice(0, maxItem).map(row => (
						<tr key={row}>
							<th align="left" className="font-bold pr-8">
								{row}
							</th>
							<td className="font-mono" align="right">
								<NumberValue value={floatValue.value[row]} />
							</td>
						</tr>
					))}
					{rows.length > maxItem ? <p>and more...</p> : null}
				</tbody>
			</table>
		);
	};

	return (
		<div className="absolute border-green-500 border-2 bg-white p-8 rounded-xl" style={{ top: `${coords.y - containerCoords.top + 5}px`, left: `${coords.x - containerCoords.left + 5}px` }}>
			{_.isObject(floatValue.value) ? (
				renderObject()
			) : (
				<div className="font-mono">
					<NumberValue value={floatValue.value} />
				</div>
			)}
		</div>
	);
}
