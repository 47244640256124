import _ from '@lodash';

// dec2hex :: Integer -> String
// i.e. 0-255 -> '00'-'ff'
export function dec2hex(dec) {
	return dec.toString(16).padStart(2, '0');
}

// generateId :: Integer -> String
export function generateId(len) {
	const arr = new Uint8Array((len || 40) / 2);
	window.crypto.getRandomValues(arr);
	return Array.from(arr, dec2hex).join('');
}

export function rotatePoint(pt, o, a) {
	const angle = a * (Math.PI / 180);
	const rotatedX = Math.cos(angle) * (pt.x - o.x) - Math.sin(angle) * (pt.y - o.y) + o.x;
	const rotatedY = Math.sin(angle) * (pt.x - o.x) + Math.cos(angle) * (pt.y - o.y) + o.y;
	return { x: rotatedX, y: rotatedY };
}

export function rotatePointArr(pt, o, a) {
	const angle = a * (Math.PI / 180);
	const rotatedX = Math.cos(angle) * (pt[0] - o[0]) - Math.sin(angle) * (pt[1] - o[1]) + o[0];
	const rotatedY = Math.sin(angle) * (pt[0] - o[0]) + Math.cos(angle) * (pt[1] - o[1]) + o[1];
	return [rotatedX, rotatedY];
}

export function distanceBetweenPoints(pt1, pt2) {
	const a = pt1.x - pt2.x;
	const b = pt1.y - pt2.y;
	const distance = Math.sqrt(a * a + b * b);
	return distance;
}

export function findRightAnglePoints(points, bx, by) {
	const lastPoint = points.slice(-2);
	const ax = lastPoint[0];
	const ay = lastPoint[1];
	const a = bx - ax;
	const b = by - ay;
	let result = [];
	if (points.length > 2) {
		const lastPrevPoint = points.slice(-4, -2);
		if (lastPrevPoint[0] === lastPoint[0]) result = [ax + a, ay, bx, by];
		else if (lastPrevPoint[1] === lastPoint[1]) result = [ax, ay + b, bx, by];
	} else if (b > a) result = [ax, ay + b, bx, by];
	else result = [ax + a, ay, bx, by];
	return result;
}

export function calcJoinPointsByLinePoints(linePoints) {
	const _joinPoints = [];
	const points = [];
	for (let x = 0; x < linePoints.length; x += 2) {
		points.push({
			x: linePoints[x],
			y: linePoints[x + 1]
		});
	}
	for (let line = 0; line < points.length - 1; line += 1) {
		const start = points[line];
		const end = points[line + 1];
		if (line > 0) _joinPoints.push(start);

		if (start.x === end.x) {
			if (start.y > end.y) {
				for (let pt = end.y; pt <= start.y; pt += 10) {
					const pos = { x: start.x, y: pt };
					_joinPoints.push(pos);
				}
			} else {
				for (let pt = start.y; pt <= end.y; pt += 10) {
					const pos = { x: start.x, y: pt };
					_joinPoints.push(pos);
				}
			}
		} else if (start.x > end.x) {
			for (let pt = end.x; pt <= start.x; pt += 10) {
				const pos = { x: pt, y: start.y };
				_joinPoints.push(pos);
			}
		} else {
			for (let pt = start.x; pt <= end.x; pt += 10) {
				const pos = { x: pt, y: start.y };
				_joinPoints.push(pos);
			}
		}
	}
	return _joinPoints;
}

export function formatLabelValue(value, format, uom, label) {
	const formatter = new Intl.NumberFormat('en-US', {
		useGrouping: format.thousandsSeparator || false,
		minimumFractionDigits: 0,
		maximumFractionDigits: Number.isInteger(format.decimalPlaces) ? format.decimalPlaces : 20
	});
	if (_.isNaN(value)) {
		return `${label ? `${label}: ` : ''} NaN`;
	}
	const convertedValue = format.displayAsPercentage ? value * 100 : value;
	const formattedValue = formatter.format(convertedValue).replaceAll(',', ' ');
	return `${label ? `${label}: ` : ''}${format.displayAsPercentage ? `${formattedValue}${format.showUnit ? ' %' : ''}` : formattedValue}${format.showUnit ? `${uom ? ` ${uom}` : ''}` : ''}`;
}

export function getScaledImageCoordinates(containerWidth, containerHeight, width, height) {
	const widthRatio = containerWidth / width;
	const heightRatio = containerHeight / height;
	const bestRatio = Math.min(widthRatio, heightRatio);
	const newWidth = width * bestRatio;
	const newHeight = height * bestRatio;
	return { width: newWidth, height: newHeight };
}

export function getInvalidValue(attributes) {
	const result = [];
	attributes.forEach(attrib => {
		if (attrib.io === 'INPUT') {
			if (_.isObject(attrib.value)) {
				const propertyList = Object.keys(attrib.value);
				for (let i = 0; i < propertyList.length; i += 1) {
					const property = propertyList[i];
					if (_.isNumber(attrib.value[property])) {
						if (_.isObject(attrib.min_value)) {
							if (_.isNumber(attrib.min_value[property]) && attrib.value[property] < attrib.min_value[property]) result.push({ name: attrib.name, property, type: 'lt', limitValue: attrib.min_value[property] });
							else if (!_.isNumber(attrib.min_value[property])) result.push({ name: attrib.name, property, type: 'missing', valueKey: 'min_value' });
						}
						if (_.isObject(attrib.max_value)) {
							if (_.isNumber(attrib.max_value[property]) && attrib.value[property] > attrib.max_value[property]) result.push({ name: attrib.name, property, type: 'gt', limitValue: attrib.max_value[property] });
							else if (!_.isNumber(attrib.max_value[property])) result.push({ name: attrib.name, property, type: 'missing', valueKey: 'max_value' });
						}
					} else {
						result.push({ name: attrib.name, property, type: 'missing', valueKey: 'value' });
					}
				}
			} else {
				if (_.isNumber(attrib.min_value) && attrib.value < attrib.min_value) {
					result.push({ name: attrib.name, type: 'lt', limitValue: attrib.min_value });
				}
				if (_.isNumber(attrib.max_value) && attrib.value > attrib.max_value) {
					result.push({ name: attrib.name, type: 'gt', limitValue: attrib.max_value });
				}
			}
		}
	});
	return result;
}
