import KeyedAndNamedDto from 'modules/base/dto/KeyedAndNamedDto';

export class WorkflowModelInputAttributeEditDto extends KeyedAndNamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.type = null;
		/** @type {string} */
		this.value = null;
	}

	/**
	 * @typedef {Object} _WorkflowModelInputAttributeEditDtoProps
	 * @property {string} key
	 * @property {string} type
	 * @property {string} value
	 *
	 * @typedef {import("modules/base/dto/KeyedAndNamedDto").KeyedAndNamedDtoProps & _WorkflowModelInputAttributeEditDtoProps} WorkflowModelInputAttributeEditDtoProps
	 */
	/**
	 *
	 * @param {WorkflowModelInputAttributeEditDtoProps} props
	 * @returns {WorkflowModelInputAttributeEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.key = props.key;
		this.type = props.type;
		this.value = props.value;
		return this;
	}
}

export default WorkflowModelInputAttributeEditDto;
