const modelHu = {
	modelTable: 'Model',
	modelEditForm: 'Model',
	modelHysysEditForm: 'Model Hysys',
	modelAnnEditForm: 'Model Ann',
	annModelEditForm: 'Model Ann',
	expressionModelEditForm: 'Model Expression',
	workflowModelEditForm: 'Model Workflow',
	addAnn: 'Ann',
	addHysys: 'Hysys',
	addExpression: 'Expression',
	name: 'Név',
	model_file_content: 'Model fájl',
	model_descriptor_file_content: 'Model Descriptor fálj',
	model_zip_file_content: 'Model ZIP fájl',
	image: 'Kép',
	x_scaler_file_content: 'X Scaler fájl',
	y_scaler_file_content: 'Y Scaler fájl',
	state_file_content: 'State fájl',
	basic: 'Alapadatok',
	model_zip_file: 'Model ZIP fájl',
	xscaler_file: 'X Scaler fájl',
	yscaler_file: 'Y Scaler fájl',
	state_file: 'State fájl',
	type: 'Típus',
	components: 'Komponensek',
	input_attributes: 'Bemenő attribútumok',
	output_attributes: 'Kimenő attribútumok',
	hysysModelEditForm: 'Model Hysys',
	model_file: 'Model fájl',
	input_unit_ops: 'Input Unit Ops',
	output_unit_ops: 'Output Unit Ops',
	components_file: 'Komponens fájl',
	type_code: 'Típus kód',
	value: 'Érték',
	lummusModelEditForm: 'Model Lummus',
	lummus2ModelEditForm: 'Model Lummus2',
	addLummus: 'Lummus',
	addLummus2: 'Lummus2',
	driver_log_file: 'Driver Log',
	simulation_id: 'Simulation',
	code: 'Code',
	unit_op_code: 'Unit Op',
	min_value: 'Min. érték',
	max_value: 'Max. érték',
	uom: 'Mértékegység',
	lummusBySimulation: 'Lummus by Simulation',
	lummus2BySimulation: 'Lummus2 by Simulation',
	simulationId: 'Simulation Id',
	title: 'Title',
	hsc_file: 'HSC File',
	cell: 'Cell',
	unit: 'Unit',
	stream: 'Stream',
	spreadsheet: 'Spreadsheet',
	expression: 'Expression',
	driver: 'Driver',
	getInputAttributes: 'Get input attributes',
	getHysysInputAttributes: 'Get Hysys Input Attributes',
	addWorkflow: 'Workflow',
	workflow: 'Workflow',
	use_uom: 'Use UOM',
	driverLog: 'Driver Log',
	loadValues: 'Load Values',
	'driver.name': 'Driver',
	subscriptionKey: 'Subscription key',
	subscription_key: 'Subscription key'
};

export default modelHu;
