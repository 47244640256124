import KeyedDto from 'modules/base/dto/KeyedDto';
import NamedDto from 'modules/base/dto/NamedDto';

export default class AccessConfigUserDto extends NamedDto {
	constructor() {
		super();
		/** @type {KeyedDto} */
		this.profilePicture = null;
		/** @type {string} */
		this.currentLocale = null;
	}

	/**
	 * @typedef {Object} _AccessConfigUserDtoProps
	 * @property {KeyedDto} profilePicture
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _AccessConfigUserDtoProps} AccessConfigUserDtoProps
	 */
	/**
	 *
	 * @param {AccessConfigUserDtoProps} props
	 * @returns {AccessConfigUserDto}
	 */
	setup(props) {
		super.setup(props);
		this.profilePicture = props.profilePicture;
		this.currentLocale = props.currentLocale;
		return this;
	}
}
