import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from '@lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useTranslation } from 'react-i18next';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormFileInputField from 'modules/ui/editform/fields/EditFormFileInputField';
import WhatIfDocumentEditDto from '../dto/WhatIfDocumentEditDto';
import WhatIfDocumentEditService from '../service/WhatIfDocumentEditService';
import { useDocumentItemForm } from './DocumentItemFormProvider';

function DocumentItemForm(props) {
	const { whatIfId, item, onChange, saveRef } = props;
	const dispatch = useDispatch();
	const { documentId, setDocumentId } = useDocumentItemForm();
	const { t } = useTranslation(['whatIf', 'validation']);
	const schema = yup.object().shape({
		name: yup.string().nullable().required(t('validation:FIELD_REQUIRED')),
		file: yup.string().nullable().required(t('validation:FIELD_REQUIRED'))
	});

	const defaultFileFormDto = () => {
		return new WhatIfDocumentEditDto().setup({
			id: null,
			key: null,
			name: null,
			file: null,
			whatIf: whatIfId,
			documentItemKey: item.key,
			creator: null,
			revision: 1,
			lastRevision: true
		});
	};

	const methods = useForm({
		defaultValues: defaultFileFormDto(),
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const save = () => {
		methods.handleSubmit(handleSave)();
	};

	const handleSave = () => {
		const value = methods.getValues();
		if (value.id !== null) {
			WhatIfDocumentEditService.update(value.id, value).then(resp => {
				if (resp.successful) {
					dispatch(showMessage({ message: `Successfully saved` }));
					onChange(resp);
				} else {
					dispatch(showMessage({ message: `Failed to save` }));
				}
			});
		} else {
			WhatIfDocumentEditService.save(value).then(resp => {
				if (resp.successful) {
					dispatch(showMessage({ message: `Successfully saved` }));
					onChange(resp);
				} else {
					dispatch(showMessage({ message: `Failed to save` }));
				}
			});
		}
	};

	useEffect(() => {
		if (documentId != null) {
			WhatIfDocumentEditService.getData(documentId).then(resp => {
				methods.reset(resp);
			});
		} else {
			methods.reset(defaultFileFormDto());
		}
	}, [documentId]);

	saveRef.current = save;

	return (
		<FormProvider {...methods}>
			<Controller
				control={methods.control}
				name="name"
				render={({ field, fieldState }) => (
					<EditFormTextField
						field={field}
						fieldState={fieldState}
						config={{ topic: 'whatIf' }}
						fieldConfig={{
							key: 'name',
							text: t('name')
						}}
					/>
				)}
			/>
			<Controller
				control={methods.control}
				name="file"
				render={({ field, fieldState }) => (
					<EditFormFileInputField
						field={field}
						fieldState={fieldState}
						config={{ topic: 'whatIf' }}
						fieldConfig={{
							key: 'file',
							text: t('file')
						}}
					/>
				)}
			/>
		</FormProvider>
	);
}

export default DocumentItemForm;
