import NamedDto from 'modules/base/dto/NamedDto';
import DomainEditDto from 'app/admin/optimizationCase/dto/DomainEditDto';

export class FittingCaseEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {number} */
		this.fitterLog = null;
		/** @type {DomainEditDto[]} */
		this.domains = null;
	}

	/**
	 * @typedef {Object} _FittingCaseEditDtoProps
	 * @property {number} fitterLog
	 * @property {DomainEditDto[]} domains
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _FittingCaseEditDtoProps} FittingCaseEditDtoProps
	 */
	/**
	 *
	 * @param {FittingCaseEditDtoProps} props
	 * @returns {FittingCaseEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.fitterLog = props.fitterLog;
		this.domains = props.domains !== null ? props.domains.map(d => new DomainEditDto().setup(d)) : [];
		return this;
	}
}

export default FittingCaseEditDto;
