import NamedDto from 'modules/base/dto/NamedDto';
import FitterLogFieldEditDto from './FitterLogFieldEditDto';

export class FitterLogEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.table_name = null;
		/** @type {string} */
		this.description = null;
		/** @type {FitterLogFieldEditDto[]} */
		this.fields = null;
	}

	/**
	 * @typedef {Object} _FitterLogEditDtoProps
	 * @property {string} table_name
	 * @property {string} description
	 * @property {FitterLogFieldEditDto[]} fields
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _FitterLogEditDtoProps} FitterLogEditDtoProps
	 */
	/**
	 *
	 * @param {FitterLogEditDtoProps} props
	 * @returns {FitterLogEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.table_name = props.table_name;
		this.description = props.description;
		this.fields = props.fields !== null ? props.fields.map(d => new FitterLogFieldEditDto().setup(d)) : [];
		return this;
	}
}

export default FitterLogEditDto;
