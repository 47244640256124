import _ from '@lodash';
import React, { useEffect, useState } from 'react';
import { InputLabel } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { convertDateToBackingsString, convertBackingsStringToDate } from 'modules/base/utils/DateUtils';
import set from 'date-fns/set';
import enLocale from 'date-fns/locale/en-US';
import huLocale from 'date-fns/locale/hu';

const localeMap = {
	en: enLocale,
	hu: huLocale
};

const dateLocaleFormatMap = {
	en: 'd MMMM yyyy',
	hu: 'yyyy MMMM d.'
};

const dateTimeLocaleFormatMap = {
	en: 'd MMMM yyyy HH:mm',
	hu: 'yyyy MMMM d. HH:mm'
};

const localeTextMap = {
	en: {
		cancel: 'Cancel',
		ok: 'Ok',
		clear: 'Clear'
	},
	hu: {
		cancel: 'Mégse',
		ok: 'Ok',
		clear: 'Törlés'
	}
};

function TableFilterDateIntervalField(props) {
	const [locale, setLocale] = useState('hu');
	const { t } = useTranslation(props.config.topic ? ['tableBrowser', props.config.topic] : 'tableBrowser');
	const filterList = useSelector(rootState => rootState[props.config.key].tableBrowserFilter.filterList);
	const [fromDate, setFromDate] = useState(null);
	const [toDate, setToDate] = useState(null);

	useEffect(() => {
		const index = filterList.findIndex(el => el.propertyName === props.filterConfig.propertyName);
		if (index > -1) {
			setFromDate(filterList[index].value.from != null ? convertBackingsStringToDate(filterList[index].value.from) : null);
			setToDate(filterList[index].value.to != null ? convertBackingsStringToDate(filterList[index].value.to) : null);
		} else {
			setFromDate(null);
			setToDate(null);
		}
	}, [filterList]);

	const parseDateWithTime = (date, isEnd) => {
		return convertDateToBackingsString(set(date, { seconds: isEnd ? 59 : 0, milliseconds: isEnd ? 999 : 0 }));
	};

	const parseDateWithoutTime = (date, isEnd) => {
		return convertDateToBackingsString(set(date, { hours: isEnd ? 23 : 0, minutes: isEnd ? 59 : 0, seconds: isEnd ? 59 : 0, milliseconds: isEnd ? 999 : 0 }));
	};

	const parseDate = (date, isEnd) => {
		if (date !== null) {
			if (props.filterConfig.withTime) return parseDateWithTime(date, isEnd);
			return parseDateWithoutTime(date, isEnd);
		}
		return null;
	};

	useEffect(() => {
		if (fromDate || toDate) {
			const _fromDate = parseDate(fromDate, false);
			const _toDate = parseDate(toDate, true);
			if (_fromDate !== null || _toDate !== null) {
				const filterValue = {
					propertyName: props.filterConfig.propertyName,
					type: props.filterConfig.type,
					value: {
						from: _fromDate,
						to: _toDate
					}
				};
				props.onFilterChanged(filterValue);
			} else {
				props.onFilterRemoved(props.filterConfig.propertyName);
			}
		} else {
			props.onFilterRemoved(props.filterConfig.propertyName);
		}
	}, [fromDate, toDate]);

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
			<div>
				<InputLabel className="mt-16 w-full">{!_.isUndefined(props.filterConfig.text) ? props.filterConfig.text : t(`${props.config.topic}:${props.filterConfig.propertyName}`)}</InputLabel>
				{props.filterConfig.withTime ? (
					<div className="flex flex-col sm:flex-row">
						<DateTimePicker
							label={t('DATE_FROM')}
							margin="dense"
							className="w-full"
							value={fromDate}
							onChange={e => setFromDate(e)}
							format={dateTimeLocaleFormatMap[locale]}
							cancelLabel={localeTextMap[locale].cancel}
							okLabel={localeTextMap[locale].ok}
							clearLabel={localeTextMap[locale].clear}
							clearable
						/>
						<DateTimePicker
							label={t('DATE_TO')}
							margin="dense"
							className="w-full"
							value={toDate}
							onChange={e => setToDate(e)}
							format={dateTimeLocaleFormatMap[locale]}
							cancelLabel={localeTextMap[locale].cancel}
							okLabel={localeTextMap[locale].ok}
							clearLabel={localeTextMap[locale].clear}
							clearable
						/>
					</div>
				) : (
					<div className="flex flex-col sm:flex-row">
						<DatePicker
							label={t('DATE_FROM')}
							margin="dense"
							className="w-full"
							value={fromDate}
							onChange={e => setFromDate(e)}
							format={dateLocaleFormatMap[locale]}
							cancelLabel={localeTextMap[locale].cancel}
							okLabel={localeTextMap[locale].ok}
							clearLabel={localeTextMap[locale].clear}
							clearable
						/>
						<DatePicker
							label={t('DATE_TO')}
							margin="dense"
							className="w-full"
							value={toDate}
							onChange={e => setToDate(e)}
							format={dateLocaleFormatMap[locale]}
							cancelLabel={localeTextMap[locale].cancel}
							okLabel={localeTextMap[locale].ok}
							clearLabel={localeTextMap[locale].clear}
							clearable
						/>
					</div>
				)}
			</div>
		</MuiPickersUtilsProvider>
	);
}

export default TableFilterDateIntervalField;
