import _ from '@lodash';
import { useRef, useState } from 'react';
import { useParams } from 'react-router';
import * as yup from 'yup';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NoteItemInput from './NoteItemInput';
import { useWhatIf } from '../component/WhatIfProvider';
import NoteItemList from './NoteItemList';

function NoteItemOperator(props) {
	const { id } = useParams('id');
	const { data, setData, figure, setFigure, showPropertiesDialog, setShowPropertiesDialog } = useWhatIf();
	const { t } = useTranslation(['whatIf', 'validation']);
	const [tabValue, setTabValue] = useState(0);
	const schema = yup.object().shape({
		name: yup.string().nullable().required(t('validation:FIELD_REQUIRED'))
	});
	const item = figure.note.find(d => d.key === showPropertiesDialog.key);
	const updateListRef = useRef(null);

	return (
		<Dialog open maxWidth="lg" fullWidth>
			<DialogTitle disableTypography className="border-b">
				<div className="flex flex-row w-full">
					<div className="flex mr-24 items-center">{item.name}</div>
				</div>
			</DialogTitle>
			<DialogContent className="p-20" style={{ height: 'calc(100vh - 300px)' }}>
				<div className="h-full flex flex-col">
					<NoteItemList whatIf={id === 'new' ? null : Number.parseInt(id, 10)} noteItemKey={item.key} updateListRef={updateListRef} />
					<NoteItemInput
						whatIf={id === 'new' ? null : Number.parseInt(id, 10)}
						noteItemKey={item.key}
						onChange={() => {
							if (updateListRef.current != null) updateListRef.current();
						}}
					/>
				</div>
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button onClick={() => setShowPropertiesDialog(null)}>{t('close')}</Button>
			</DialogActions>
		</Dialog>
	);
}

export default NoteItemOperator;
