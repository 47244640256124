import _ from '@lodash';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';

function EditFormGroupFields(props) {
	const methods = useFormContext();
	const { control, watch } = methods;
	const watchAllFields = watch();
	const fieldPrefixStr = props.fieldPrefix || '';

	return (
		<div className={props.fieldConfig.className}>
			{props.fieldConfig.fields
				? props.fieldConfig.fields.map(fieldRow =>
						_.isUndefined(fieldRow.visibleIf) || fieldRow.visibleIf(watchAllFields, methods) ? (
							_.isUndefined(fieldRow.fields) || fieldRow.isControlledInput === false ? (
								<Controller
									key={`${fieldPrefixStr}${fieldRow.key}`}
									name={`${fieldPrefixStr}${fieldRow.key}`}
									control={control}
									defaultValue={props.field ? props.field[fieldRow.key] : undefined}
									render={({ field, fieldState }) => <fieldRow.component field={field} fieldState={fieldState} config={props.config} fieldConfig={fieldRow} className={fieldRow.className} fieldPrefix={props.fieldPrefix} />}
								/>
							) : (
								<fieldRow.component key={`${props.fieldPrefix}${fieldRow.key}`} name={`${props.fieldPrefix}${fieldRow.key}`} config={props.config} fieldConfig={fieldRow} fieldPrefix={props.fieldPrefix} field={props.field} />
							)
						) : null
				  )
				: null}
		</div>
	);
}

export default EditFormGroupFields;
