import _ from '@lodash';
import { useFormContext } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { determineEditableAccess } from 'modules/ui/editform/EditFormUtils';
import EditFormValueKeySelectDialog from './EditFormValueKeySelectDialog';

function EditFormValueKeySelectButton(props) {
	const methods = useFormContext();
	const { value, onChange } = props.field;
	const { ioKeys } = props.fieldConfig;
	const [openDialog, setOpenDialog] = useState(false);
	const [readOnly, setReadOnly] = useState(determineEditableAccess(props.config, props.fieldConfig, props.fieldPrefix));

	useEffect(() => {
		if (_.isFunction(props.fieldConfig.onChange)) props.fieldConfig.onChange(value, props.field, methods);
	}, [value]);

	return (
		<div className="px-8">
			<Button variant="contained" color="primary" className="w-full" onClick={() => setOpenDialog(true)} disabled={readOnly}>
				Open Attributes
			</Button>
			{openDialog && (
				<EditFormValueKeySelectDialog
					ioKeys={ioKeys}
					value={value}
					onCancel={() => setOpenDialog(false)}
					onSave={result => {
						onChange(result);
						setOpenDialog(false);
					}}
				/>
			)}
		</div>
	);
}

export default EditFormValueKeySelectButton;
