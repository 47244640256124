const validationEn = {
	FIELD_REQUIRED: 'This field is required.',
	EMAIL_IS_NOT_VALID: 'E-mail address is not valid.',
	EMAIL_IS_REQUIRED: 'E-mail address is required.',
	PASSWORD_REQUIRED: 'Password is required.',
	PASSWORD_IS_TOO_SHORT: 'Password is too short. Must be a minimum of 5 characters.',
	PASSWORD_DONT_MATCH: "Password don't match.",
	AT_LEAST_ONE_REQUIRED: 'At least one required.'
};

export default validationEn;
