import _ from '@lodash';
import { useState, useRef } from 'react';
import { Rect, Label, Tag, Text } from 'react-konva';
import { useWhatIf } from '../component/WhatIfProvider';

const itemBorder = 5;

function NoteItem(props) {
	const { item } = props;
	const [pos, setPos] = useState({ x: item.x, y: item.y });
	const { viewMode, editMode, data, setData, figure, setFigure, setShowPropertiesDialog } = useWhatIf();
	const [isDragging, setIsDragging] = useState(false);
	const textRef = useRef(null);

	const handleItemClick = () => {
		if (editMode === 'DELETE') {
			setFigure({
				...figure,
				note: figure.note.filter(d => d.key !== item.key)
			});
		} else if (editMode === 'ROTATE') {
			const rotateNewValue = item.rotate ? item.rotate + 90 : 90;
			setFigure({
				...figure,
				note: figure.note.map(d => (d.key === item.key ? { ...d, rotate: rotateNewValue === 360 ? 0 : rotateNewValue } : d))
			});
		}
	};

	const updateDragPos = e => {
		const currentPos = { x: e.target.x(), y: e.target.y() };
		setPos(currentPos);
		setFigure({
			...figure,
			note: figure.note.map(d => {
				if (d.key === item.key) {
					return { ...item, ...currentPos };
				}
				return d;
			})
		});
	};

	const labelText = () => {
		return item.name;
	};

	const handleDblClick = e => {
		if (viewMode === 'EDIT' && editMode === 'MOVE') {
			setShowPropertiesDialog({ type: 'NOTE', key: item.key });
		} else if (viewMode === 'CALCULATE') {
			setShowPropertiesDialog({ type: 'NOTE_OPERATOR', key: item.key });
		}
		return true;
	};

	const calcRectDimension = () => {
		if (item.rotate === 270) {
			return {
				x: pos.x - itemBorder,
				y: pos.y - textRef.current.width() - itemBorder,
				h: textRef.current.width() + itemBorder * 2,
				w: textRef.current.height() + itemBorder * 2
			};
		}
		if (item.rotate === 180) {
			return {
				x: pos.x - textRef.current.width() - itemBorder,
				y: pos.y - textRef.current.height() - itemBorder,
				w: textRef.current.width() + itemBorder * 2,
				h: textRef.current.height() + itemBorder * 2
			};
		}
		if (item.rotate === 90) {
			return {
				x: pos.x - textRef.current.height() - itemBorder,
				y: pos.y - itemBorder,
				h: textRef.current.width() + itemBorder * 2,
				w: textRef.current.height() + itemBorder * 2
			};
		}
		return {
			x: pos.x - itemBorder,
			y: pos.y - itemBorder,
			w: textRef.current.width() + itemBorder * 2,
			h: textRef.current.height() + itemBorder * 2
		};
	};

	const LabelRect = () => {
		const rectDimension = calcRectDimension();
		return <Rect x={rectDimension.x} y={rectDimension.y} width={rectDimension.w} height={rectDimension.h} dash={[10, 10]} stroke="black" strokeWidth={1} opacity={isDragging ? 1 : 0} listening={false} />;
	};

	return (
		<>
			<Label
				x={pos.x}
				y={pos.y}
				draggable={editMode === 'MOVE'}
				onPointerClick={e => handleItemClick(e)}
				onPointerDblClick={e => handleDblClick(e)}
				onDragStart={e => {
					setIsDragging(true);
				}}
				onDragMove={e => {
					updateDragPos(e);
				}}
				onDragEnd={e => {
					updateDragPos(e);
					setIsDragging(false);
				}}
				rotation={item.rotate || 0}
			>
				<Tag fill={item.borderColor} />
				<Text padding={5} ref={textRef} text={labelText()} fontSize={item.fontSize} fill={item.fillColor} fillAfterStrokeEnabled />
			</Label>
			{textRef.current && <LabelRect />}
		</>
	);
}

export default NoteItem;
