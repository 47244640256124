import NamedDto from 'modules/base/dto/NamedDto';

export class TransformerTableDto extends NamedDto {
	constructor() {
		super();
		/** @type {NamedDto} */
		this.creator = null;
		/** @type {Date} */
		this.created = null;
		/** @type {Date} */
		this.updated = null;
	}

	/**
	 * @typedef {Object} _TransformerTableDtoProps
	 * @property {NamedDto} creator
	 * @property {Date} created
	 * @property {Date} updated
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _TransformerTableDtoProps} TransformerTableDtoProps
	 */
	/**
	 *
	 * @param {TransformerTableDtoProps} props
	 * @returns {TransformerTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.creator = props.creator !== null ? new NamedDto().setup(props.creator) : null;
		this.created = props.created != null ? new Date(props.created) : null;
		this.updated = props.updated != null ? new Date(props.updated) : null;
		return this;
	}
}

export default TransformerTableDto;
