import _ from '@lodash';
import { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useWhatIf } from './WhatIfProvider';

const blockClassName = 'rounded-8 border px-16 pt-10 pb-4 mb-16';
const blockTitleClassName = 'py-8 pb-16';

function WhatIfOperatorVisibilityProperties(props) {
	const { data, setData } = useWhatIf();
	const methods = useForm({
		defaultValues: {
			connectBtnVisible: _.get(data, 'properties.operatorVisibility.connectBtnVisible', 'VISIBLE'),
			calculateBtnVisible: _.get(data, 'properties.operatorVisibility.calculateBtnVisible', true),
			calculateBackgroundBtnVisible: _.get(data, 'properties.operatorVisibility.calculateBackgroundBtnVisible', true)
		},
		mode: 'onChange'
	});

	const values = useWatch({
		control: methods.control
	});
	useEffect(() => {
		setData({ ...data, properties: { ...data.properties, operatorVisibility: values } });
	}, [values]);

	return (
		<FormProvider {...methods}>
			<div className={blockClassName}>
				<div className={blockTitleClassName}>
					<div className="flex items-center">
						<Typography className="h2 mx-10 font-medium" color="textSecondary">
							Operator's action visibility
						</Typography>
					</div>
				</div>
				<div className="flex flex-row">
					<Controller
						control={methods.control}
						name="connectBtnVisible"
						render={({ field, fieldState }) => (
							<EditFormSelectField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'whatIf' }}
								fieldConfig={{
									key: 'connectBtnVisible',
									text: 'Connect button',
									type: 'enum',
									className: 'mr-16',
									items: [
										{ label: 'Visible', value: 'VISIBLE' },
										{ label: 'Not visible', value: 'NOT_VISIBLE' },
										{ label: 'Auto-Connect', value: 'AUTO_CONNECT' }
									]
								}}
							/>
						)}
					/>
					<Controller
						control={methods.control}
						name="calculateBtnVisible"
						render={({ field, fieldState }) => (
							<EditFormCheckboxField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'whatIf' }}
								fieldConfig={{
									key: 'calculateBtnVisible',
									text: 'Calculate button'
								}}
							/>
						)}
					/>
					<Controller
						control={methods.control}
						name="calculateBackgroundBtnVisible"
						render={({ field, fieldState }) => (
							<EditFormCheckboxField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'whatIf' }}
								fieldConfig={{
									key: 'calculateBackgroundBtnVisible',
									text: 'Calculate background button'
								}}
							/>
						)}
					/>
				</div>
			</div>
		</FormProvider>
	);
}

export default WhatIfOperatorVisibilityProperties;
