import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './i18n/en';
import hu from './i18n/hu';
import TransformerEditPage from './component/TransformerEditPage';
import WorkflowProvider from './component/WorkflowProvider';
import WorkflowTable from './table/TransformerTable';

i18next.addResourceBundle('en', 'transformer', en);
i18next.addResourceBundle('hu', 'transformer', hu);

export default [
	{
		path: '/transformer/edit/:id',
		component: () => (
			<WorkflowProvider isTransformer>
				<TransformerEditPage />
			</WorkflowProvider>
		),
		auth: authRoles.transformer
	},
	{
		path: '/transformer/:sessionNameParam?',
		component: WorkflowTable,
		auth: authRoles.transformer
	}
];
