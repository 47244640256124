import { useSelector } from 'react-redux';
import { useState } from 'react';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { Button, ThemeProvider, Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { BsGearFill } from 'react-icons/bs';
import WhatIfLoadAspect from './WhatIfLoadAspect';
import WhatIfPiMeasurementReaderSelect from './WhatIfPiMeasurementReaderSelect';
import WhatIfLoadTransformer from './WhatIfLoadTransformer';
import WhatIfOperatorVisibilityProperties from './WhatIfOperatorVisibilityProperties';

export default function WhatIfProperties() {
	const { t } = useTranslation('whatIf');
	const mainTheme = useSelector(selectMainTheme);
	const [showDialog, setShowDialog] = useState(false);

	return (
		<>
			<Button
				className="whitespace-nowrap mx-4"
				variant="contained"
				color="secondary"
				onClick={() => {
					setShowDialog(true);
				}}
				startIcon={<BsGearFill className="hidden sm:flex" />}
			>
				{t('properties')}
			</Button>
			<ThemeProvider theme={mainTheme}>
				<Dialog open={showDialog} maxWidth="md" fullWidth>
					<DialogTitle disableTypography className="border-b">
						{t('properties')}
					</DialogTitle>
					<DialogContent className="p-20">
						<WhatIfLoadAspect />
						<WhatIfLoadTransformer />
						<WhatIfPiMeasurementReaderSelect />
						<WhatIfOperatorVisibilityProperties />
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button
							onClick={() => {
								setShowDialog(false);
							}}
						>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</>
	);
}
