import OptimizerEditDto from './OptimizerEditDto';

export class RestartOptimizerEditDto extends OptimizerEditDto {
	constructor() {
		super();
		/** @type {number} */
		this.max_iteration_count = null;
		/** @type {string} */
		this.local_search_name = null;
	}

	/**
	 * @typedef {Object} _RestartOptimizerEditDtoProps
	 * @property {number} max_iteration_count
	 * @property {string} local_search_name
	 *
	 * @typedef {import("./OptimizerEditDto").OptimizerEditDtoProps & _RestartOptimizerEditDtoProps} RestartOptimizerEditDtoProps
	 */
	/**
	 *
	 * @param {RestartOptimizerEditDtoProps} props
	 * @returns {RestartOptimizerEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.max_iteration_count = props.max_iteration_count;
		this.local_search_name = props.local_search_name;
		return this;
	}
}

export default RestartOptimizerEditDto;
