import _ from '@lodash';
import { useWhatIf } from '../component/WhatIfProvider';
import VariableModify from './VariableModify';
import ComponentMassFracModify from './ComponentMassFracModify';
import VariableOrDictModify from './VariableOrDictModify';
import ComponentVariableModify from './ComponentVariableModify';

export default function ValueModify(props) {
	const { valueModify, setValueModify, getExperimentAttribute } = useWhatIf();
	const attribute = getExperimentAttribute(valueModify.valueKey);
	const isObjectSubValue = valueModify.valueKey.split('.').length > 3;
	if (attribute) {
		if (attribute.type === 'Variable' && attribute.io === 'INPUT') return <VariableModify {...props} />;
		if (attribute.type === 'SpreadSheet' && attribute.io === 'INPUT') return <VariableModify {...props} />;
		if (attribute.type === 'ComponentMassFrac' && attribute.io === 'INPUT' && isObjectSubValue) return <ComponentVariableModify {...props} />;
		if (attribute.type === 'ComponentMassFrac') return <ComponentMassFracModify {...props} />;
		if (attribute.type === 'VariableOrDict' && attribute.io === 'INPUT') return <VariableOrDictModify {...props} />;
	}
	setValueModify(null);
	return <></>;
}
