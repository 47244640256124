const TransformType = {
	ASPECT: 'Aspect',
	BLENDER: 'Blender',
	DODO: 'Dodo',
	DUTY: 'Duty',
	EXPRESSION: 'Expression',
	FILTER_ZERO: 'FilterZero',
	FRACTIONATING: 'Fractionating', // multi_out
	GROUP_BY_PROPERTY: 'GroupByProperty',
	JOIN_STREAMS: 'JoinStreams', // multi_in
	MASSFLOW_TO_MOLEFLOW: 'MassFlowToMoleFlow',
	MASSFRAC_TO_MASSFLOW: 'MassFracToMassFlow', // multi_in
	MASSFLOW_TO_MASSFRAC: 'MassFlowToMassFrac',
	MERGE_COMPONENTS: 'MergeComponents',
	MOLEFLOW_TO_MASSFLOW: 'MoleFlowToMassFlow',
	REFORMER: 'Reformer',
	SUM_UP: 'SumUp',
	TOTAL: 'Total', // multi_in
	SCALARS_TO_TABLE: 'ScalarsToTable', // multi_in
	TABLE_TO_SCALARS: 'TableToScalars',
	SWITCH: 'Switch'
};

export default TransformType;
