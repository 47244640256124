import _ from '@lodash';
import defaultLabelStyle from 'app/admin/common/component/DefaultLabelStyle';
import { useEffect, useState } from 'react';
import { grey } from '@material-ui/core/colors';
import { TableContainer, Table, TableHead, TableCell, TableRow, TableBody, Checkbox, Tooltip, withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { formatLabelValue } from '../WhatIfUtil';
import { useWhatIf } from '../WhatIfProvider';
import CompareData from './CompareData';
import CompareViewComponent from './CompareViewComponent';

const CustomCheckbox = withStyles({
	root: {
		color: grey[100],
		'&$checked': {
			color: grey[200]
		}
	},
	checked: {}
})(props => <Checkbox color="default" {...props} />);

export default function CompareSidebarList(props) {
	const { baseCaseIndex, filteredDiffGroupList, onToggleFilteredDiffGroupList } = props;
	const { data, compare } = useWhatIf();
	const compareView = _.get(data, 'properties.compareView', false);
	const [compareData, setCompareData] = useState(null);

	const style = {
		variableStyle: defaultLabelStyle(false),
		componentStyle: defaultLabelStyle(true)
	};

	useEffect(() => {
		console.log('Data:');
		console.log(data);
		console.log('Compare:');
		console.log(compare);
		console.log('BaseCaseIndex:');
		console.log(baseCaseIndex);
		console.log('FilteredDiffGroupList:');
		console.log(filteredDiffGroupList);

		if (compare != null && data.experiment && data.experiment.attributes && compareView !== false) {
			setCompareData(CompareData([data.experiment, compare], baseCaseIndex, compareView, '', filteredDiffGroupList));
		} else {
			setCompareData(null);
		}
	}, [data, compare, baseCaseIndex, filteredDiffGroupList]);

	if (compareView === false) {
		return (
			<div className="flex flex-row justify-center">
				<CompareViewComponent />
			</div>
		);
	}
	if (compareData != null) {
		return (
			<>
				{compareData.groupList.map((group, idx) => (
					<TableContainer className="mb-12" component={Paper}>
						<CompareTable group={group} style={style} idx={idx + 1} filteredDiffGroupList={filteredDiffGroupList} onToggleFilteredDiffGroupList={onToggleFilteredDiffGroupList} />
					</TableContainer>
				))}
			</>
		);
	}
	return <></>;
}

function CompareTable(props) {
	const { group, style, idx, filteredDiffGroupList } = props;

	console.log('Group:');
	console.log(group);
	console.log('FilteredDiffGroupList:');
	console.log(filteredDiffGroupList);

	return (
		<Table className="w-full">
			<TableHead>
				<TableRow style={{ backgroundColor: group.headerColor || 'none' }}>
					<TableCell padding="checkbox">
						<Tooltip title="Show only there is a difference" aria-label="toggle">
							<CustomCheckbox checked={filteredDiffGroupList.indexOf(group.key) > -1} onChange={() => props.onToggleFilteredDiffGroupList(group.key)} />
						</Tooltip>
						{group.name}
					</TableCell>
					<TableCell>Base</TableCell>
					<TableCell>Current</TableCell>
					<TableCell>Difference</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{group.data.map((row, rowNum) =>
					row.hasSub && !row.isSub ? (
						<TableRow key={rowNum} className={`${(rowNum + 1) % 2 === 0 ? 'bg-gray-100' : ''}`}>
							<TableCell colSpan={4} className="font-bold">
								{row.name}
							</TableCell>
						</TableRow>
					) : (
						<TableRow key={rowNum} className={`${(rowNum + 1) % 2 === 0 ? 'bg-gray-100' : ''}`}>
							<TableCell className={row.isSub ? 'pl-36' : ''}>{row.name}</TableCell>
							<TableCell className="whitespace-nowrap" align="right">
								{row.isSub ? formatLabelValue(row.values[0], style.componentStyle) : formatLabelValue(row.values[0], style.variableStyle)}
							</TableCell>
							<TableCell className="whitespace-nowrap" align="right">
								{row.isSub ? formatLabelValue(row.values[1], style.componentStyle) : formatLabelValue(row.values[1], style.variableStyle)}
							</TableCell>
							<DifferenceCell row={row} style={style} />
						</TableRow>
					)
				)}
				<SummaryTableRow data={group.data} style={style} />
			</TableBody>
		</Table>
	);
}

function DifferenceCell(props) {
	const { row, style } = props;
	const diff = row.diffs[1];
	const diffPercent = row.diffPercents[1];

	return (
		<TableCell>
			<div className="flex flex-row">
				{diffPercent != null && diffPercent !== 0 && (
					<div className={`${diffPercent < 0 ? 'text-green' : 'text-red'} whitespace-nowrap pr-12`}>
						{`${diffPercent > 0 ? '+' : '-'} ${formatLabelValue(Math.abs(diffPercent), {
							thousandsSeparator: true,
							decimalPlaces: 2,
							displayAsPercentage: true,
							showUnit: true
						})}`}
					</div>
				)}
				{diff != null && diff !== 0 && (
					<div className={`${diff < 0 ? 'text-green' : 'text-red'} whitespace-nowrap`}>({row.isSub ? formatLabelValue(Math.abs(diff), style.componentStyle) : formatLabelValue(Math.abs(diff), style.variableStyle)})</div>
				)}
			</div>
		</TableCell>
	);
}

function SummaryTableRow(props) {
	const { data, style } = props;
	let sum = 0;
	let avg = 0;
	let valueCt = 0;

	data.forEach(d => {
		if ((!d.hasSub || d.isSub) && d.diffPercents[1] != null) {
			sum += d.diffPercents[1];
			avg += d.diffs[1];
			valueCt += 1;
		}
	});
	if (valueCt === 0) return null;
	if (avg != null && valueCt > 0) avg /= valueCt;

	return (
		<TableRow>
			<TableCell colSpan={3} align="right" className="font-bold">
				&sum;
			</TableCell>
			<TableCell>
				<div className="flex flex-row">
					<div className={`${sum < 0 ? 'text-green' : 'text-red'} whitespace-nowrap pr-12`}>
						{`${sum > 0 ? '+' : '-'} ${formatLabelValue(Math.abs(sum), {
							thousandsSeparator: true,
							decimalPlaces: 2,
							displayAsPercentage: true,
							showUnit: true
						})}`}
					</div>
					<div className={`${avg < 0 ? 'text-green' : 'text-red'} whitespace-nowrap`}>{`(${formatLabelValue(avg, style.variableStyle)})`}</div>
				</div>
			</TableCell>
		</TableRow>
	);
}
