import _ from '@lodash';
import nBossConnection from 'modules/base/service/nBossConnection';
import AspectEditService from 'app/admin/aspect/service/AspectEditService';
import { useEffect, useState, useRef } from 'react';
import { Rect, Image, Group, Transformer } from 'react-konva';
import { useWhatIf } from '../component/WhatIfProvider';
import { rotatePoint, rotatePointArr } from '../component/WhatIfUtil';

const itemBorder = 5;

const calcBorderLinePoints = (item, offset) => {
	const _w = item.imageWidth * item.scaleX + itemBorder * 2;
	const _h = item.imageHeight * item.scaleY + itemBorder * 2;
	const tl = [item.x - offset.x, item.y - offset.y];
	const tr = [tl[0] + _w, item.y - offset.y];
	const bl = [tl[0], item.y + offset.y];
	const br = [tr[0], item.y + offset.y];
	const o = [item.x, item.y];
	return [...rotatePointArr(tl, o, item.rotate), ...rotatePointArr(tr, o, item.rotate), ...rotatePointArr(br, o, item.rotate), ...rotatePointArr(bl, o, item.rotate), ...rotatePointArr(tl, o, item.rotate)];
};

function ImageItem(props) {
	const groupRef = useRef();
	const imgRef = useRef();
	const trRef = useRef();
	const { item } = props;

	const offset = {
		x: (item.imageWidth * item.scaleX) / 2 + itemBorder,
		y: (item.imageHeight * item.scaleY) / 2 + itemBorder
	};

	const labelWidth = item.rotate === 90 || item.rotate === 270 ? item.imageHeight * item.scaleY : item.imageWidth * item.scaleX;

	const borderLinePoints = calcBorderLinePoints(item, offset);

	const { editMode, data, setData, figure, setFigure, underEditing, setUnderEditing, setShowPropertiesDialog, checkStageCorner } = useWhatIf();
	const [image, setImage] = useState(null);
	const [img, setImg] = useState(null);
	const [isDragging, setIsDragging] = useState(false);
	const [pos, setPos] = useState({ x: item.x, y: item.y });
	useEffect(() => {
		const imageObj = new window.Image();
		imageObj.onload = function() {
			setImage(imageObj);
		};
		imageObj.src = `${nBossConnection.baseUrl}/fileRepository/getFile?key=${item.imageKey}`;
	}, [item]);

	useEffect(() => {
		if (editMode === 'RESIZE' && underEditing !== null && underEditing.key === item.key) {
			trRef.current.nodes([imgRef.current]);
			trRef.current.getLayer().batchDraw();
		}
	}, [underEditing]);

	const handleItemClick = e => {
		if (editMode === 'DELETE') {
			setFigure({
				...figure,
				image: figure.image.filter(d => d.key !== item.key)
			});
		} else if (editMode === 'ROTATE') {
			setFigure({
				...figure,
				image: figure.image.map(d => {
					if (d.key === item.key) {
						const rotateNewValue = d.rotate ? d.rotate + 90 : 90;
						return {
							...d,
							rotate: rotateNewValue === 360 ? 0 : rotateNewValue
						};
					}
					return d;
				})
			});
		} else if (editMode === 'RESIZE') {
			setUnderEditing(item);
		} else if (editMode === 'MIRROR') {
			setFigure({
				...figure,
				image: figure.image.map(d => {
					if (d.key === item.key) {
						const mirrorNewValue = _.isNumber(d.mirror) ? d.mirror + 1 : 1;
						return {
							...d,
							mirror: mirrorNewValue > 3 ? 0 : mirrorNewValue
						};
					}
					return d;
				})
			});
		} else {
			console.log(e);
		}
	};

	const updateDragPos = e => {
		const currentPos = { x: e.target.x(), y: e.target.y() };
		setPos(currentPos);
		setFigure({
			...figure,
			image: figure.image.map(d => {
				if (d.key === item.key) {
					return { ...d, ...currentPos };
				}
				return d;
			})
		});
	};

	const handleResize = e => {
		const imgNode = imgRef.current;
		const _w = imgNode.width() * imgNode.scaleX();
		const _h = imgNode.height() * imgNode.scaleY();
		const scaleX = _w / item.imageWidth;
		const scaleY = _h / item.imageHeight;

		let currentPos;
		if (item.rotate !== null && item.rotate > 0) {
			const rotatedStartPoint = rotatePoint({ x: pos.x - offset.x, y: pos.y - offset.y }, pos, item.rotate);
			if (item.rotate === 90) currentPos = { x: rotatedStartPoint.x - imgNode.y() - (item.imageHeight * scaleY) / 2, y: rotatedStartPoint.y + imgNode.x() + (item.imageWidth * scaleX) / 2 };
			else if (item.rotate === 180) currentPos = { x: rotatedStartPoint.x - imgNode.x() - (item.imageWidth * scaleX) / 2, y: rotatedStartPoint.y - imgNode.y() - (item.imageHeight * scaleY) / 2 };
			else currentPos = { x: rotatedStartPoint.x + imgNode.y() + (item.imageHeight * scaleY) / 2, y: rotatedStartPoint.y - imgNode.x() - (item.imageWidth * scaleX) / 2 };
		} else {
			currentPos = { x: pos.x - offset.x + imgNode.x() + (item.imageWidth * scaleX) / 2, y: pos.y - offset.y + imgNode.y() + (item.imageHeight * scaleY) / 2 };
		}
		setFigure({
			...figure,
			image: figure.image.map(d => {
				if (d.key === item.key) {
					return { ...item, ...currentPos, scaleX, scaleY };
				}
				return d;
			})
		});
		setPos(currentPos);
	};

	const scaleValue = () => {
		if (item.mirror === 1) return { x: -1, y: 1 };
		if (item.mirror === 2) return { x: 1, y: -1 };
		if (item.mirror === 3) return { x: -1, y: -1 };
		return { x: 1, y: 1 };
	};

	return (
		<>
			<Group
				ref={groupRef}
				x={pos.x}
				y={pos.y}
				offsetX={offset.x}
				offsetY={offset.y}
				width={item.imageWidth * item.scaleX + itemBorder * 2}
				height={item.imageHeight * item.scaleY + itemBorder * 2}
				onPointerClick={e => handleItemClick(e)}
				onPointerDblClick={() => editMode === 'MOVE' && setShowPropertiesDialog({ type: 'IMAGE', key: item.key })}
				draggable={editMode === 'MOVE'}
				onDragStart={() => {
					setIsDragging(true);
				}}
				onDragMove={e => {
					updateDragPos(e);
					checkStageCorner();
				}}
				onDragEnd={e => {
					setIsDragging(false);
					updateDragPos(e);
				}}
				rotation={item.rotate || 0}
				scale={scaleValue()}
			>
				<Image ref={imgRef} x={itemBorder} y={itemBorder} width={item.imageWidth * item.scaleX} height={item.imageHeight * item.scaleY} image={image} onTransformEnd={e => handleResize(e)} scaleX={1} scaleY={1} />
				<Rect x={0} y={0} width={item.imageWidth * item.scaleX + itemBorder * 2} height={item.imageHeight * item.scaleY + itemBorder * 2} dash={[10, 10]} stroke="black" strokeWidth={1} opacity={isDragging ? 1 : 0} />
			</Group>
			{underEditing !== null && underEditing.key === item.key && (
				<Transformer
					ref={trRef}
					padding={itemBorder}
					borderDash={[10, 10]}
					borderStroke="black"
					borderStrokeWidth={1}
					anchorStroke="black"
					rotateEnabled={false}
					boundBoxFunc={(oldBox, newBox) => {
						if (newBox.width < 50 || newBox.height < 50) {
							return oldBox;
						}
						return newBox;
					}}
				/>
			)}
		</>
	);
}

export default ImageItem;
