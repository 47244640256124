import _ from '@lodash';
import * as yup from 'yup';
import { useWorkflow } from '../../WorkflowProvider';
import { TransComponentTitleField, TransFeatureColumnField, TransInputKeyField, TransNameField, TransOutputKeyField, TransValidationIOKey, TransValidationName } from '../TransformationProperties';
import TransformationPropertiesDialog from '../TransformationPropertiesDialog';

function MoleFlowToMassFlowProperties(props) {
	const { data, showPropertiesDialog, transformationNameAvailable } = useWorkflow();
	const item = data.transform.find(d => d.key === showPropertiesDialog.key);

	const schema = yup.object().shape({
		name: TransValidationName(transformationNameAvailable, item.key),
		input_fields: TransValidationIOKey(),
		output_fields: TransValidationIOKey()
	});

	return (
		<TransformationPropertiesDialog schema={schema} titleKey="moleFlowToMassFlowProperties">
			<TransNameField />
			<TransInputKeyField />
			<TransOutputKeyField />
			<TransFeatureColumnField />
			<TransComponentTitleField />
		</TransformationPropertiesDialog>
	);
}

export default MoleFlowToMassFlowProperties;
