import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import { useParams, withRouter } from 'react-router';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import ModelType from 'app/admin/model/dto/ModelType';
import DriverEditService from '../service/DriverEditService';
import DriverType from '../dto/DriverType';

const config = data => {
	return {
		key: 'driverEditForm',
		topic: 'driver',
		service: DriverEditService,
		fields: initData => {
			const fields = [
				{
					key: 'name',
					component: EditFormTextField,
					required: true
				},
				{
					key: 'driver_type',
					component: EditFormSelectField,
					required: true,
					type: 'enum',
					items: Object.keys(DriverType).map(key => {
						return {
							label: key,
							value: key
						};
					})
				}
			];

			return fields;
		}
	};
};

function DriverEditForm() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const myConfig = config({ id });
	return (
		<EditFormPage
			config={myConfig}
			onSave={result => {
				if (!result.engineSuccessful) dispatch(showMessage({ message: `Engine: ${result.engineMessage}` }));
			}}
		/>
	);
}

export default withRouter(DriverEditForm);
