import { Tooltip, IconButton, TableCell } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ListIcon from '@material-ui/icons/List';
import SearchIcon from '@material-ui/icons/Search';
import { Link, withRouter, generatePath } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function TableFileOpenButton(props) {
	const { t } = useTranslation('tableBrowser');
	const dispatch = useDispatch();
	const getSubDirectoryLink = () => {
		return generatePath(props.match.path, { ...props.match.params, directory: props.item.id });
	};

	return (
		<TableCell className="w-auto text-center" padding="none">
			<Tooltip title={t('OPEN')}>
				{props.item !== null && props.item.fileCategory === 'Directory' ? (
					<IconButton component={Link} onClick={() => dispatch({ type: `${props.config.key}/event/cleanTableBrowserEventSlice` })} to={() => getSubDirectoryLink()} aria-label="dirOpen">
						<ListIcon fontSize="small" />
					</IconButton>
				) : (
					<IconButton component="a" href={props.config.fileOpenState(props.item)} target="_blank" aria-label="fileOpen" disabled={props.config.fileOpenState(props.item) === null}>
						<SearchIcon fontSize="small" />
					</IconButton>
				)}
			</Tooltip>
		</TableCell>
	);
}

export default withRouter(TableFileOpenButton);
