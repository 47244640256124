import _ from '@lodash';
import { format } from 'date-fns';
import { Button, TableCell } from '@material-ui/core';
import { useState } from 'react';
import { useHistory, withRouter } from 'react-router';
import TaskManagerResultOptimize from './TaskManagerResultOptimize';
import TaskManagerResultFit from './TaskManagerResultFit';
import TaskManagerResultCalculate from './TaskManagerResultCalculate';
import TaskManagerResultShap from './TaskManagerResultShap';
import TaskManagerResultGallery from './TaskManagerResultGallery';

const availableResults = ['Optimize', 'Fit', 'WhatIf', 'Calculate', 'Fake', 'Shap'];

function TaskManagerFileListButton(props) {
	const { item } = props;
	const [showGallery, setShowGallery] = useState(null);

	const history = useHistory();

	if (!item.file_count)
		return <TableCell className="w-auto text-center" padding="none" />;

	const handleClick = () => {
		setShowGallery(true);
	};

	return (
		<TableCell className="w-auto" padding="none">

			<Button className="whitespace-nowrap" variant="contained" color="secondary" size="small" onClick={() => handleClick()}>Show Files</Button>

			{console.log(item)}

			{showGallery && <TaskManagerResultGallery item={item} onClose={() => setShowGallery(false)} />}

		</TableCell>
	);
}

export default withRouter(TaskManagerFileListButton);