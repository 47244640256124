import _ from '@lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useTranslation } from 'react-i18next';
import FileCopy from '@material-ui/icons/FileCopy';
import { IconButton, Tooltip, TableCell, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import FuseLoading from '@fuse/core/FuseLoading';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';

function TableCopyButton(props) {
	const dispatch = useDispatch();
	const { t } = useTranslation('common');
	const [showDialog, setShowDialog] = useState(false);
	const [loading, setLoading] = useState(false);
	const methods = useForm({
		defaultValues: {
			id: props.item.id,
			name: props.item.name
		}
	});

	const handleCopy = query => {
		setLoading(true);
		props.button.service.copy(query.id, query.name).then(response => {
			setLoading(false);
			if (response.successful && (_.isUndefined(response.engineSuccessful) || response.engineSuccessful)) {
				dispatch(showMessage({ message: `Cloning successful.` }));
				dispatch({ type: `${props.config.key}/event/setNeedDataReload` });
				setShowDialog(false);
			} else {
				const values = methods.getValues();
				Object.keys(response.properties).forEach(key => {
					if (response.properties[key].length > 0) {
						if (!_.isUndefined(values[key])) {
							methods.setError(key, {
								type: 'manual',
								message: response.properties[key][0].message
							});
						} else {
							dispatch(showMessage({ message: response.properties[key][0].message }));
						}
					}
				});
			}
		});
	};

	return (
		<TableCell className="w-auto text-center" padding="none">
			<Tooltip title={t('copy')}>
				<span>
					<IconButton
						onClick={e => {
							setShowDialog(true);
							e.preventDefault();
						}}
					>
						<FileCopy fontSize="small" />
					</IconButton>
				</span>
			</Tooltip>
			{showDialog && (
				<Dialog open fullWidth>
					<DialogTitle disableTypography className="border-b">
						<div className="flex flex-row w-full">
							<div className="flex mr-24 items-center">{t('copy')}</div>
						</div>
					</DialogTitle>
					<DialogContent className="p-20">
						<FormProvider {...methods}>
							{loading && <FuseLoading />}
							{!loading && (
								<>
									<Controller
										control={methods.control}
										name="name"
										render={({ field, fieldState }) => (
											<EditFormTextField
												field={field}
												fieldState={fieldState}
												config={{ topic: 'common' }}
												fieldConfig={{
													key: 'name',
													required: true
												}}
											/>
										)}
									/>
								</>
							)}
						</FormProvider>
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button
							onClick={() => {
								setShowDialog(false);
							}}
						>
							{loading ? t('close') : t('CANCEL')}
						</Button>
						<Button onClick={methods.handleSubmit(handleCopy)} color="primary" autoFocus disabled={loading}>
							{t('SAVE')}
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</TableCell>
	);
}

export default TableCopyButton;
