import ModelEditDto from 'app/admin/model/dto/ModelEditDto';
import ExpressionModelInputAttributeEditDto from './ExpressionModelInputAttributeEditDto';
import ExpressionModelOutputAttributeEditDto from './ExpressionModelOutputAttributeEditDto';

export class ExpressionModelEditDto extends ModelEditDto {
	constructor() {
		super();
		/** @type {ExpressionModelInputAttributeEditDto[]} */
		this.input_attributes = null;
		/** @type {ExpressionModelOutputAttributeEditDto[]} */
		this.output_attributes = null;
		/** @type {string} */
		this.state_file = null;
	}

	/**
	 * @typedef {Object} _ExpressionModelEditDtoProps
	 * @property {ExpressionModelInputAttributeEditDto[]} input_attributes
	 * @property {ExpressionModelOutputAttributeEditDto[]} output_attributes
	 * @property {string} state_file
	 *
	 * @typedef {import("app/admin/model/dto/ModelEditDto").ModelEditDtoProps & _ExpressionModelEditDtoProps} ExpressionModelEditDtoProps
	 */
	/**
	 *
	 * @param {ExpressionModelEditDtoProps} props
	 * @returns {ExpressionModelEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.input_attributes = props.input_attributes !== null ? props.input_attributes.map(d => new ExpressionModelInputAttributeEditDto().setup(d)) : [];
		this.output_attributes = props.output_attributes !== null ? props.output_attributes.map(d => new ExpressionModelOutputAttributeEditDto().setup(d)) : [];
		this.state_file = props.state_file;
		return this;
	}
}

export default ExpressionModelEditDto;
