import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import { useParams, withRouter } from 'react-router';
import WhatIfGroupEditService from '../service/WhatIfGroupEditService';

const config = data => {
	return {
		key: 'whatIfGroupEditForm',
		topic: 'whatIf',
		service: WhatIfGroupEditService,
		fields: initData => {
			const fields = [
				{
					key: 'name',
					component: EditFormTextField,
					required: true
				}
			];

			return fields;
		}
	};
};

function WhatIfGroupEditForm() {
	const { id, tabKey } = useParams();

	const myConfig = config({ id });
	return <EditFormPage config={myConfig} startTabValue={tabKey} />;
}

export default withRouter(WhatIfGroupEditForm);
