import { makeStyles } from '@material-ui/core/styles';
import { useStrictMode } from 'react-konva';
import _ from '@lodash';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useWorkflow } from 'app/admin/workflow/component/WorkflowProvider';
import WorkflowView from 'app/admin/workflow/component/WorkflowView';
import WorkflowValueProperties from 'app/admin/workflow/component/items/WorkflowValueProperties';
import WorkflowItemProperties from 'app/admin/workflow/component/items/WorkflowItemProperties';
import ExpressionProperties from 'app/admin/workflow/component/items/expression/ExpressionProperties';
import FilterZeroProperties from 'app/admin/workflow/component/items/filterzero/FilterZeroProperties';
import GroupByPropertyProperties from 'app/admin/workflow/component/items/groupbyproperty/GroupByPropertyProperties';
import MergeComponentsProperties from 'app/admin/workflow/component/items/mergecomponents/MergeComponentsProperties';
import MassFracToMassFlowProperties from 'app/admin/workflow/component/items/massmole/MassFracToMassFlowProperties';
import MassFlowToMoleFlowProperties from 'app/admin/workflow/component/items/massmole/MassFlowToMoleFlowProperties';
import ReformerProperties from 'app/admin/workflow/component/items/reformer/ReformerProperties';
import MoleFlowToMassFlowProperties from 'app/admin/workflow/component/items/massmole/MoleFlowToMassFlowProperties';
import BlenderProperties from 'app/admin/workflow/component/items/blender/BlenderProperties';
import FractionatingProperties from 'app/admin/workflow/component/items/fractionating/FractionatingProperties';
import JoinStreamsProperties from 'app/admin/workflow/component/items/joinstreams/JoinStreamsProperties';
import DodoProperties from 'app/admin/workflow/component/items/dodo/DodoProperties';
import SumUpProperties from 'app/admin/workflow/component/items/sumup/SumUpProperties';
import DutyProperties from 'app/admin/workflow/component/items/duty/DutyProperties';
import TotalProperties from 'app/admin/workflow/component/items/total/TotalProperties';
import ScalarsToTableProperties from 'app/admin/workflow/component/items/scalarsToTable/ScalarsToTableProperties';
import TableToScalarsProperties from 'app/admin/workflow/component/items/tableToScalars/TableToScalarsProperties';
import WorkflowOutputValueDialog from 'app/admin/workflow/component/calculate/WorkflowOutputValueDialog';
import WorkflowOutputValueFloat from 'app/admin/workflow/component/calculate/WorkflowOutputValueFloat';
import AspectProperties from 'app/admin/workflow/component/items/aspect/AspectProperties';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import TransformType from 'app/admin/workflow/dto/TransformType';
import ValidationFloatMessages from 'app/admin/workflow/component/validation/ValidationFloatMessages';
import MassFlowToMassFracProperties from 'app/admin/workflow/component/items/massmole/MassFlowToMassFracProperties';
import SwitchProperties from 'app/admin/workflow/component/items/switch/SwitchProperties';

export const paperSize = {
	width: 10000,
	height: 10000
};

const defaultData = {
	stage: {
		x: (paperSize.width / 2) * -1,
		y: (paperSize.width / 2) * -1,
		scale: 1
	},
	value: [],
	transform: [],
	line: []
};

const useStyles = makeStyles(theme => ({
	root: {
		'& .container': {
			maxWidth: '100% !important'
		}
	}
}));

function WorkflowEditFormComponent(props) {
	useStrictMode(true);
	const dispatch = useDispatch();
	const history = useHistory();

	const { data, setData, showPropertiesDialog, setCalculate, selectedCalculate, floatCalculate, floatValidation } = useWorkflow();
	const { control } = useFormContext();
	const { field } = useController({
		name: 'json',
		control
	});
	const { t } = useTranslation('transformer');
	const containerRef = useRef(null);

	useEffect(() => {
		if (data == null) setData(field.value != null ? field.value : defaultData);
	}, [field.value]);

	useEffect(() => {
		if (data != null) {
			field.onChange(data);
		}
	}, [data]);

	return (
		<div className="w-full h-full absolute left-0 top-0" ref={containerRef}>
			{data !== null && <WorkflowView />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'VALUE' && <WorkflowValueProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === null && <WorkflowItemProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.ASPECT && <AspectProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.EXPRESSION && <ExpressionProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.FILTER_ZERO && <FilterZeroProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.GROUP_BY_PROPERTY && <GroupByPropertyProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.MERGE_COMPONENTS && <MergeComponentsProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.MASSFRAC_TO_MASSFLOW && <MassFracToMassFlowProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.MASSFLOW_TO_MASSFRAC && <MassFlowToMassFracProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.MASSFLOW_TO_MOLEFLOW && <MassFlowToMoleFlowProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.REFORMER && <ReformerProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.MOLEFLOW_TO_MASSFLOW && <MoleFlowToMassFlowProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.BLENDER && <BlenderProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.FRACTIONATING && <FractionatingProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.JOIN_STREAMS && <JoinStreamsProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.DODO && <DodoProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.SUM_UP && <SumUpProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.DUTY && <DutyProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.TOTAL && <TotalProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.SCALARS_TO_TABLE && <ScalarsToTableProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.TABLE_TO_SCALARS && <TableToScalarsProperties />}
			{showPropertiesDialog !== null && showPropertiesDialog.class === 'TRANSFORM' && showPropertiesDialog.type === TransformType.SWITCH && <SwitchProperties />}
			{selectedCalculate && <WorkflowOutputValueDialog />}
			{floatCalculate && <WorkflowOutputValueFloat containerRef={containerRef} />}
			{floatValidation && <ValidationFloatMessages containerRef={containerRef} />}
		</div>
	);
}

export default WorkflowEditFormComponent;
