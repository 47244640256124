import _ from 'lodash';
import IdentifiableDto from 'modules/base/dto/IdentifiableDto';
import ScheduleEditDto from './ScheduleEditDto';
import PiMeasurementReaderDefinitionDto from './PiMeasurementReaderDefinitionDto';

export default class SchedulerEditDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {ScheduleEditDto} */
		this.schedule = null;
		/** @type {boolean} */
		this.enabled = null;
		/** @type {PiMeasurementReaderDefinitionDto} */
		this.definition = null;
		/** @type {string} */
		this.newMeasurementName = null;
	}

	/**
	 * @typedef {Object} _SchedulerEditDtoProps
	 * @property {ScheduleEditDto} schedule
	 * @property {boolean} enabled
	 * @property {PiMeasurementReaderDefinitionDto} definition
	 * @property {string} newMeasurementName
	 *
	 * @typedef {import("modules/base/dto/IdentifiableDto").IdentifiableDtoProps & _SchedulerEditDtoProps} SchedulerEditDtoProps
	 */
	/**
	 *
	 * @param {SchedulerEditDtoProps} props
	 * @returns {SchedulerEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.schedule = props.schedule != null ? new ScheduleEditDto().setup(props.schedule) : null;
		this.enabled = props.enabled != null ? props.enabled : true;
		this.definition = props.definition != null ? new PiMeasurementReaderDefinitionDto().setup(props.definition) : null;
		return this;
	}
}
