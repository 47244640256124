import _ from '@lodash';
import WorkflowItem from '../WorkflowItem';
import { listConverter, gridConverter } from '../../WorkflowUtil';

export const defaultDefinition = {
	name: null,
	input_fields: [{ name: 'Components', type: 'Components', enabled: true, value: null }],
	output_fields: [{ name: 'Components', type: 'Components' }],
	columns: [],
	rows: [],
	conversion: [],
	selectivity: [],
	stoichiometry: [],
	feature_column: null,
	component_title: null
};

export const converter = function (data) {
	return {
		...data,
		conversion: listConverter(data, 'conversion'),
		selectivity: gridConverter(data, 'selectivity'),
		stoichiometry: gridConverter(data, 'stoichiometry')
	};
};

export default function ReformerTransformation(props) {
	return <WorkflowItem {...props} type="REFORMER" />;
}
