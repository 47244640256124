import { useRef, useState, createContext, useContext, useEffect } from 'react';
import { generateId } from 'app/admin/whatIf/component/WhatIfUtil';

export const ReformerContext = createContext(null);

export const useReformer = () => {
	return useContext(ReformerContext);
};

export default function ReformerProvider(props) {
	const [grid, setGrid] = useState(null);

	const addRow = () => {
		const item = { key: generateId(), name: null };
		setGrid({
			...grid,
			rows: [...grid.rows, item],
			conversion: [
				...grid.conversion,
				{
					key: generateId(),
					row: item.key,
					value: null
				}
			],
			selectivity: [
				...grid.selectivity,
				...grid.columns.map(c => {
					return {
						key: generateId(),
						row: item.key,
						column: c.key,
						value: null
					};
				})
			],
			stoichiometry: [
				...grid.stoichiometry,
				...grid.columns.map(c => {
					return {
						key: generateId(),
						row: item.key,
						column: c.key,
						value: null
					};
				})
			]
		});
	};

	const changeRowName = (key, value) => {
		setGrid({
			...grid,
			rows: grid.rows.map(d => {
				if (d.key === key) {
					return {
						...d,
						name: value
					};
				}
				return d;
			})
		});
	};

	const removeRow = index => {
		const row = grid.rows[index];
		setGrid({
			...grid,
			rows: grid.rows.filter((d, i) => i !== index),
			conversion: grid.conversion.filter(c => c.row !== row.key),
			selectivity: grid.selectivity.filter(c => c.row !== row.key),
			stoichiometry: grid.stoichiometry.filter(c => c.row !== row.key)
		});
	};

	const changeConversionValue = (rowKey, value) => {
		setGrid({
			...grid,
			conversion: grid.conversion.map(d => {
				if (d.row === rowKey) {
					return {
						...d,
						value
					};
				}
				return d;
			})
		});
	};

	const addColumn = () => {
		const item = { key: generateId(), name: null };
		setGrid({
			...grid,
			columns: [...grid.columns, item],
			selectivity: [
				...grid.selectivity,
				...grid.rows.map(r => {
					return {
						key: generateId(),
						row: r.key,
						column: item.key,
						value: null
					};
				})
			],
			stoichiometry: [
				...grid.stoichiometry,
				...grid.rows.map(r => {
					return {
						key: generateId(),
						row: r.key,
						column: item.key,
						value: null
					};
				})
			]
		});
	};

	const removeColumn = index => {
		const column = grid.columns[index];
		setGrid({
			...grid,
			columns: grid.columns.filter((d, i) => i !== index),
			selectivity: grid.selectivity.filter(c => c.column !== column.key),
			stoichiometry: grid.stoichiometry.filter(c => c.column !== column.key)
		});
	};

	const changeColumnName = (key, value) => {
		setGrid({
			...grid,
			columns: grid.columns.map(d => {
				if (d.key === key) {
					return {
						...d,
						name: value
					};
				}
				return d;
			})
		});
	};

	const changeValue = (type, key, value) => {
		setGrid({
			...grid,
			[type]: grid[type].map(d => {
				if (d.key === key) {
					return {
						...d,
						value
					};
				}
				return d;
			})
		});
	};

	const value = {
		grid,
		setGrid,
		addRow,
		removeRow,
		changeRowName,
		changeConversionValue,
		addColumn,
		removeColumn,
		changeColumnName,
		changeValue
	};

	return <ReformerContext.Provider value={value}>{props.children}</ReformerContext.Provider>;
}
