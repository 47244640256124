import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import FittingCaseTableService from '../service/FittingCaseTableService';
import RunFittingCaseButton from '../component/RunFittingCaseButton';

const config = {
	key: 'fittingCaseTable',
	topic: 'fittingCase',
	service: FittingCaseTableService,
	editFormState: item => `/fitting-case/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['search', 'refresh', 'new'],
	rowAction: ['modify', 'delete'],
	rowButtonList: [{ key: 'runOptimCase', component: RunFittingCaseButton }],
	columns: [
		{
			key: 'name'
		}
	]
};

function FittingCaseTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('fittingCaseTable', reducer('fittingCaseTable'))(FittingCaseTable);
