import NamedDto from 'modules/base/dto/NamedDto';
import AspectInputAttributeEditDto from './AspectInputAttributeEditDto';
import AspectOutputAttributeEditDto from './AspectOutputAttributeEditDto';

export class AspectEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {number} */
		this.dataset = null;
		/** @type {number} */
		this.model = null;
		/** @type {string} */
		this.description = null;
		/** @type {AspectInputAttributeEditDto[]} */
		this.input_attributes = null;
		/** @type {AspectOutputAttributeEditDto[]} */
		this.output_attributes = null;
		this.datasetList = null;
	}

	/**
	 * @typedef {Object} _AspectEditDtoProps
	 * @property {number} dataset
	 * @property {number} model
	 * @property {string} description
	 * @property {AspectInputAttributeEditDto[]} input_attributes
	 * @property {AspectOutputAttributeEditDto[]} output_attributes
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _AspectEditDtoProps} AspectEditDtoProps
	 */
	/**
	 *
	 * @param {AspectEditDtoProps} props
	 * @returns {AspectEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.dataset = props.dataset;
		this.model = props.model;
		this.description = props.description;
		this.input_attributes = props.input_attributes !== null ? props.input_attributes.map(d => new AspectInputAttributeEditDto().setup(d)) : [];
		this.output_attributes = props.output_attributes !== null ? props.output_attributes.map(d => new AspectOutputAttributeEditDto().setup(d)) : [];
		this.datasetListChanged = false;
		return this;
	}
}

export default AspectEditDto;
