import GenericTableDto from 'modules/base/dto/table/GenericTableDto';
import GenericTableService from 'modules/base/service/GenericTableService';
import DriverTableDto from '../dto/DriverTableDto';

class DriverTableService extends GenericTableService {
	constructor() {
		super();
		this.CONTROL = 'driverTableControl';
	}

	/**
	 * @param {string} session
	 * @param {number} firstResult
	 * @param {number} maxResults
	 * @return {Promise<GenericTableDto<DriverTableDto>>}
	 */
	getList(session, firstResult, maxResults) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getList`, {
					session,
					firstResult,
					maxResults
				})
				.then(response => resolve(new GenericTableDto().setup(response.data, DriverTableDto)))
				.catch(reject);
		});
	}

	/**
	 * @param {string} name
	 * @return {Promise<string>}
	 */
	autoConnect(name) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/autoConnect`, {
					name
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} name
	 * @return {Promise<string>}
	 */
	connect(name) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/connect`, {
					name
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} name
	 * @return {Promise<string>}
	 */
	disconnect(name) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/disconnect`, {
					name
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new DriverTableService();
