import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import AspectService from 'app/admin/aspect/service/AspectService';

export default function (props) {
	const { t } = useTranslation('transformer');
	const methods = useFormContext();
	return (
		<>
			<Controller
				control={methods.control}
				name="aspect"
				render={({ field, fieldState }) => (
					<EditFormSelectField
						field={field}
						fieldState={fieldState}
						config={{ topic: 'transformer' }}
						fieldConfig={{
							key: 'aspect',
							text: t('aspect'),
							items: () => AspectService.getListSelection()
						}}
					/>
				)}
			/>
		</>
	);
}
