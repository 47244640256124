import _ from '@lodash';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import { ThemeProvider } from '@material-ui/core/styles';
import { useState } from 'react';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useFormContext } from 'react-hook-form';
import { Button, Icon, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import HysysModelEditService from '../service/HysysModelEditService';

const inputAttributeDefaultValue = {
	id: null,
	name: null,
	type: null,
	spreadsheet: null,
	cell: null,
	value: null,
	min_value: null,
	max_value: null,
	uom: null,
	expression: null,
	stream: null
};
function LoadHysysInputAttributesButton(props) {
	const { id } = props;
	const { t } = useTranslation(['model', 'common']);
	const mainTheme = useSelector(selectMainTheme);
	const dispatch = useDispatch();
	const { getValues, setValue } = useFormContext();
	const [loading, setLoading] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [message, setMessage] = useState([]);

	const handleTest = () => {
		setLoading(true);
		setShowDialog(true);
		handleResponse({
			OHFEEDMASRATE: 76.2,
			OLFEEDMASRATE: null,
			O107REFLUXRATIO: null,
			O102REFLUXRATIO: null,
			'107TOPPRES': null,
			E107OVERALLEFF: null,
			E102OVERALLEFF: null,
			O102BTMTEMP: null,
			'107CONDSUBCOL': null,
			FOULING121TUBE: null,
			FOULING117TUBE: null,
			PRICEC4: null,
			PRICEC5: null,
			PRICEGASOLINE: null,
			PRICEVN: null,
			PRICEBENZENE: null,
			PRICETOLUENE: null,
			PRICEXYLENEMIX: null,
			PRICEOXYLENE: null,
			PRICEHYDROGEN: null,
			CONDEFF: null,
			REBEFF: null,
			ELECTRICITYCOST: null,
			FUELGASCOST: null,
			USDHUF: null,
			LNFEEDQUALITY: {
				Methane: 0.0,
				Ethylene: 0.0,
				Ethane: 0.0,
				Propene: 0.0,
				Propane: 0.0,
				'i-Butane': 0.00011052388320639836,
				'i-Butene': 0.0,
				'1-Butene': 0.0,
				'n-Butane': 0.013976247412736377,
				'tr2-Butene': 0.0,
				'cis2-Butene': 0.0,
				'22-Mpropane': 0.00034161927536523136,
				'i-Pentane': 0.13073970620742326,
				'n-Pentane': 0.18541888551735228,
				'2M-2-butene': 4.019050298414486e-5,
				Cyclopentane: 0.019502441573056296,
				'22-Mbutane': 0.0037377167775254723,
				'23-Mbutane': 0.011645198239655973,
				'2-Mpentane': 0.08527419970660936,
				'3-Mpentane': 0.05317203544802365,
				'cis3-Hexene': 0.0,
				'n-Hexane': 0.13610513835580654,
				Mcyclopentan: 0.0504390812451018,
				'22-Mpentane': 0.0013162389727307442,
				Benzene: 0.020507204147659917,
				'24-Mpentane': 0.0038381930349858343,
				Cyclohexane: 0.02765106605309166,
				'223-Mbutane': 0.0,
				'33-Mpentane': 0.0007636195566987523,
				'11Mcycpentan': 0.003034382975302937,
				'23-Mpentane': 0.010228483009464868,
				'2-Mhexane': 0.02511906436509054,
				'1-ci3-MCC5': 0.001446858107429215,
				'1-tr3-MCC5': 0.007344814420352474,
				'3-Mhexane': 0.03176054498322048,
				'1-tr2-MCC5': 0.010349054518417302,
				'3-Epentane': 0.01521210537949883,
				'n-Heptane': 0.05954223017101062,
				'1-ci2-MCC5': 0.0016578582480959757,
				Mcyclohexane: 0.02673673211020237,
				Ecyclopentan: 0.0015774772421276858,
				'113-MCC5': 0.001456905733175251,
				'25-Mhexane': 0.006410385225971105,
				'1tr2ci4-MCC5': 0.002853525711874285,
				'24-Mhexane': 0.0025320016880011264,
				Toluene: 0.011323674215782816,
				'234-Mpentane': 0.00025119064365090535,
				'23-Mhexane': 0.001014810200349658,
				'2M-3Epentane': 0.0011353817093020925,
				'1ci2tr4-MCC5': 0.000974619697365513,
				'2-Mheptane': 0.007123766653939676,
				'4-Mheptane': 0.0024114301790486917,
				'34-Mhexane': 0.003878383537969979,
				'3-Mheptane': 0.00074352430520668,
				'1-tr4-MCC6': 0.0027430018286678875,
				'1-ci3-MCC6': 0.0,
				'1M-ci3-ECC5': 0.0,
				'1M-tr3-ECC5': 0.000653095673492354,
				'1-Octene': 0.0,
				'1-M-1-ECC5': 0.0010148102003496578,
				'225-Mhexane': 0.00043204790707955726,
				'1-ci4-MCC6': 0.0,
				'n-Octane': 0.008339529369210057,
				'224-Mhexane': 0.0014770009846673238,
				'TetramethyCP*': 0.0,
				'1-ci2-MCC6': 0.00015071438619054323,
				'235-Mhexane': 0.00011052388320639836,
				Ecyclohexane: 0.0009645720716194768,
				'24-Mheptane': 0.00018085726342865186,
				'44-Mheptane': 0.0,
				'26-Mheptane': 0.0,
				'25-Mheptane': 0.0,
				'35-Mheptane': 0.00013061913469847078,
				'E-Benzene': 0.0008540481884130782,
				'p-Xylene': 0.001456905733175251,
				'1c3c5MCC6': 0.0,
				'm-Xylene': 0.0,
				'334-Mhexane': 6.02857544762173e-5,
				'23-Mheptane': 0.0002411430179048692,
				'1c3t5MCC6': 0.0,
				'4-Eheptane': 0.0,
				'1tr2tr4-MCC6': 0.0,
				'4-Moctane': 0.0002210477664127967,
				'3-Eheptane': 0.00027128589514297777,
				'2-Moctane': 0.0,
				'E-Norbornene': 0.0,
				'3-Moctane': 0.0,
				'o-Xylene': 0.0,
				'tert-BCC5': 0.0,
				'2M3E-15-C6==': 0.0,
				'1-Nonene': 0.0,
				'22M-4Ehexane': 0.0,
				'2245-Mhexane': 0.0,
				'i-Bcycpentan': 0.0,
				'2235-Mhexane': 0.0,
				'226-Mheptane': 0.0,
				'n-Nonane': 0.0,
				Cumene: 0.0,
				'1Mci2n-PCC5': 0.0,
				'i-Pcychexane': 0.0,
				'24-Moctane': 0.0,
				'n-Bcycpentan': 0.0,
				'n-Pcychexane': 0.0,
				'n-PBenzene': 0.0,
				'35-Moctane': 0.0,
				'27-Moctane': 0.0,
				'26-Moctane': 0.0,
				Camphene: 0.0,
				'36-Moctane': 0.0,
				'33-Moctane': 0.0,
				'1M3-EBenzene': 0.0,
				'1M4-EBenzene': 0.0,
				'45-Moctane': 0.0,
				'3M-4Eheptane': 0.0,
				'4-Eoctane': 0.0,
				'135-MBenzene': 0.0,
				'5-Mnonane': 0.0,
				'4-Mnonane': 0.0,
				'3-Eoctane': 0.0,
				'3-Mnonane': 0.0,
				'124-MBenzene': 0.0,
				'3344-Mhexane': 0.0,
				'1M-4i-PCC6': 0.0,
				'i-Bcychexane': 0.0,
				'i-BBenzene': 0.0,
				'2245-Mheptane*': 0.0,
				'n-Decane': 0.0,
				'm-Cymene': 0.0,
				'123-MBenzene': 0.0,
				'25-Mnonane*': 0.0,
				'p-Cymene': 0.0,
				'2-Mdecane*': 0.0,
				Indane: 0.0,
				'n-Bcychexane': 0.0,
				'13-E-BZ': 0.0,
				'1M3nPropylBZ': 0.0,
				Indene: 0.0,
				'1M4nPropylBZ': 0.0,
				'5EmXylene': 0.0,
				'14-EBenzene': 0.0,
				'1M2nPropylBZ': 0.0,
				'2EpXylene': 0.0,
				Adamantane: 0.0,
				'4EmXylene': 0.0,
				'4EoXylene': 0.0,
				'2EmXylene': 0.0,
				'3EoXylene': 0.0,
				'n-C11': 0.0,
				'1245-M-BZ': 0.0,
				'2-Mundecane*': 0.0,
				Cyclodecane: 0.0,
				'1234-M-BZ': 0.0,
				'n-Pentyl-BZ': 0.0,
				'n-C12': 0.0,
				H2O: 0.0
			},
			HNFEEDQUALITY: {
				Methane: 0.0,
				Ethylene: 0.0,
				Ethane: 0.0,
				Propene: 0.0,
				Propane: 0.0,
				'i-Butane': 0.0,
				'i-Butene': 0.0,
				'1-Butene': 0.0,
				'n-Butane': 0.0,
				'tr2-Butene': 0.0,
				'cis2-Butene': 0.0,
				'22-Mpropane': 0.0,
				'i-Pentane': 0.0032381879734151553,
				'n-Pentane': 0.010597706094813236,
				'2M-2-butene': 0.0,
				Cyclopentane: 0.003713726067413186,
				'22-Mbutane': 0.0005321497718549382,
				'23-Mbutane': 0.002683393530417454,
				'2-Mpentane': 0.01879507704849356,
				'3-Mpentane': 0.013983084430656356,
				'cis3-Hexene': 0.0,
				'n-Hexane': 0.037669410445986796,
				Mcyclopentan: 0.02152375992119654,
				'22-Mpentane': 0.00010190102014243496,
				Benzene: 0.0075067084838260426,
				'24-Mpentane': 0.0017549620135641577,
				Cyclohexane: 0.020017889290202782,
				'223-Mbutane': 0.0,
				'33-Mpentane': 0.0005095051007121748,
				'11Mcycpentan': 0.0022984341209904774,
				'23-Mpentane': 0.007325551114683935,
				'2-Mhexane': 0.0164853205919317,
				'1-ci3-MCC5': 0.0014039696108513263,
				'1-tr3-MCC5': 0.005966870846118137,
				'3-Mhexane': 0.023074919894475826,
				'1-tr2-MCC5': 0.009035223785962568,
				'3-Epentane': 0.013835894068228391,
				'n-Heptane': 0.05947622875646788,
				'1-ci2-MCC5': 0.0039514951144121995,
				Mcyclohexane: 0.0502598476013632,
				Ecyclopentan: 0.003928850443269437,
				'113-MCC5': 0.003249510308986537,
				'25-Mhexane': 0.01212622139694976,
				'1tr2ci4-MCC5': 0.007993568913395454,
				'24-Mhexane': 0.007031170389828014,
				Toluene: 0.027751044485456455,
				'234-Mpentane': 0.000871819838996388,
				'23-Mhexane': 0.003917528107698055,
				'2M-3Epentane': 0.004302487517125032,
				'1ci2tr4-MCC5': 0.017911934873925794,
				'2-Mheptane': 0.03326502190871933,
				'4-Mheptane': 0.010926053826383304,
				'34-Mhexane': 0.020017889290202782,
				'3-Mheptane': 0.0034533123492714074,
				'1-tr4-MCC6': 0.0,
				'1-ci3-MCC6': 0.0,
				'1M-ci3-ECC5': 0.004177941825839833,
				'1M-tr3-ECC5': 0.004710091597694773,
				'1-Octene': 0.0,
				'1-M-1-ECC5': 0.009227703490676056,
				'225-Mhexane': 0.002909840241845087,
				'1-ci4-MCC6': 0.0,
				'n-Octane': 0.07246294765684265,
				'224-Mhexane': 0.008899355759105986,
				'TetramethyCP*': 0.0,
				'1-ci2-MCC6': 0.002253144778704951,
				'235-Mhexane': 0.001437936617565471,
				Ecyclohexane: 0.021048221827198513,
				'24-Mheptane': 0.00334008899355759,
				'44-Mheptane': 0.0,
				'26-Mheptane': 0.018059125236353755,
				'25-Mheptane': 0.0,
				'35-Mheptane': 0.003838271758698384,
				'E-Benzene': 0.017243917075214273,
				'p-Xylene': 0.030740141076301217,
				'1c3c5MCC6': 0.0,
				'm-Xylene': 0.011322335571381664,
				'334-Mhexane': 0.004664802255409245,
				'23-Mheptane': 0.0015964493155648145,
				'1c3t5MCC6': 0.0,
				'4-Eheptane': 0.020233013666059032,
				'1tr2tr4-MCC6': 0.0,
				'4-Moctane': 0.014130274793084317,
				'3-Eheptane': 0.004370421530553322,
				'2-Moctane': 0.014685069236082017,
				'E-Norbornene': 0.0,
				'3-Moctane': 0.00045289342285526654,
				'o-Xylene': 0.015296475356936626,
				'tert-BCC5': 0.0,
				'2M3E-15-C6==': 0.0,
				'1-Nonene': 0.0,
				'22M-4Ehexane': 0.0,
				'2245-Mhexane': 0.0,
				'i-Bcycpentan': 0.0,
				'2235-Mhexane': 0.0,
				'226-Mheptane': 0.0,
				'n-Nonane': 0.07178360752255974,
				Cumene: 0.0028079392217026525,
				'1Mci2n-PCC5': 0.0,
				'i-Pcychexane': 0.0,
				'24-Moctane': 0.004291165181553651,
				'n-Bcycpentan': 0.0005774391141404648,
				'n-Pcychexane': 0.009442827866532303,
				'n-PBenzene': 0.014130274793084317,
				'35-Moctane': 0.0,
				'27-Moctane': 0.005740424134690504,
				'26-Moctane': 0.015602178417363931,
				Camphene: 0.0,
				'36-Moctane': 0.0008152081611394798,
				'33-Moctane': 0.0018002513558496847,
				'1M3-EBenzene': 0.008220015624823088,
				'1M4-EBenzene': 0.004811992617837207,
				'45-Moctane': 0.0,
				'3M-4Eheptane': 0.0,
				'4-Eoctane': 0.00782373387982473,
				'135-MBenzene': 0.007563320161682952,
				'5-Mnonane': 0.0073934851281122265,
				'4-Mnonane': 0.00619331755754577,
				'3-Eoctane': 0.0014945482954223798,
				'3-Mnonane': 0.005989515517260901,
				'124-MBenzene': 0.009929688296101719,
				'3344-Mhexane': 0.0,
				'1M-4i-PCC6': 0.003838271758698384,
				'i-Bcychexane': 0.0,
				'i-BBenzene': 0.0,
				'2245-Mheptane*': 0.0,
				'n-Decane': 0.023742937693187346,
				'm-Cymene': 0.0,
				'123-MBenzene': 0.0,
				'25-Mnonane*': 0.0020833097451342262,
				'p-Cymene': 0.0,
				'2-Mdecane*': 0.0,
				Indane: 0.0007812411544253349,
				'n-Bcychexane': 0.0,
				'13-E-BZ': 0.0002604137181417782,
				'1M3nPropylBZ': 0.0009963655302815861,
				Indene: 0.0002717360537131599,
				'1M4nPropylBZ': 0.0006453731275687547,
				'5EmXylene': 0.00021512437585625158,
				'14-EBenzene': 0.00027173605371315997,
				'1M2nPropylBZ': 0.0003623147382842132,
				'2EpXylene': 0.00013586802685657996,
				Adamantane: 0.0,
				'4EmXylene': 0.00015851269799934328,
				'4EoXylene': 0.00012454569128519827,
				'2EmXylene': 0.0,
				'3EoXylene': 0.0,
				'n-C11': 0.00011322335571381662,
				'1245-M-BZ': 0.0,
				'2-Mundecane*': 0.0,
				Cyclodecane: 0.0,
				'1234-M-BZ': 0.0,
				'n-Pentyl-BZ': 0.0,
				'n-C12': 0.0,
				H2O: 0.0
			}
		});
	};

	const handleClick = () => {
		const modelName = getValues('name');
		if (modelName == null || modelName.length === 0) {
			dispatch(showMessage({ message: 'Model name is required.' }));
		} else {
			setLoading(true);
			setShowDialog(true);
			HysysModelEditService.getHysysInputAttributeValues(modelName)
				.then(response => handleResponse(response))
				.catch(err => handleCatch(err));
		}
	};

	const handleResponse = data => {
		setLoading(false);
		if (data != null && _.isObject(data)) {
			const newInputs = getValues('input_attributes') || [];
			const valueWithNull = [];
			const notPaired = [];
			Object.keys(data).forEach(key => {
				if (data[key] != null) {
					const index = newInputs.findIndex(inp => inp.name === key);
					if (index > -1) {
						newInputs[index] = {
							...newInputs[index],
							value: data[key]
						};
					} else {
						notPaired.push(key);
					}
				} else {
					valueWithNull.push(key);
				}
			});
			console.log(newInputs);
			setValue('input_attributes', newInputs);
			if (valueWithNull.length > 0 || notPaired.length > 0) {
				const mess = [];
				if (valueWithNull.length > 0) {
					mess[0] = `Attribute with null value: ${valueWithNull.join(', ')}`;
				}
				if (notPaired.length > 0) {
					mess[1] = `Attribute not exists : ${notPaired.join(', ')}`;
				}
				setMessage(mess);
			} else {
				setShowDialog(false);
			}
		} else if (_.isString(data)) {
			setMessage([data || 'Empty result']);
		}
	};

	const handleCatch = err => {
		console.log(err);
		setLoading(false);
		setMessage([`Connection problem: ${err !== null ? err : 'Unknown'}`]);
	};

	return (
		<>
			<Button className="whitespace-nowrap mx-4" variant="contained" color="secondary" disabled={id === 'new'} onClick={() => handleClick()} startIcon={<Icon className="hidden sm:flex">graphic_eq</Icon>}>
				{t('getInputAttributes')}
			</Button>
			<ThemeProvider theme={mainTheme}>
				<Dialog open={showDialog} fullWidth>
					<DialogTitle disableTypography className="border-b">
						{t('getHysysInputAttributes')}
					</DialogTitle>
					<DialogContent className="p-20">
						{loading && <FuseLoading />}
						{!loading && message.length > 0 && message.map(mess => <div className="flex py-8">{mess}</div>)}
						{!loading && message.length === 0 && <div className="flex justify-center">No result</div>}
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button
							onClick={() => {
								setShowDialog(false);
							}}
						>
							{t('common:close')}
						</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</>
	);
}
export default LoadHysysInputAttributesButton;
