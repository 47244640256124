import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { useParams } from 'react-router';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import ConfirmDialog from 'modules/ui/component/ConfirmDialog';
import { ThemeProvider } from 'styled-components';
import TableFilterListDto from 'modules/base/dto/table/TableFilterListDto';
import LoadIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import { generateId } from '../WhatIfUtil';
import WhatIfCompareViewTableService from '../../service/WhatIfCompareViewTableService';

export default function CompareViewListDialog(props) {
	const { t } = useTranslation(['whatIf', 'tableBrowser']);
	const [sessionId, setSessionId] = useState(null);
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const mainTheme = useSelector(selectMainTheme);
	const [viewList, setViewList] = useState([]);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

	useEffect(() => {
		setLoading(true);
		if (sessionId === null) {
			const newId = generateId();
			const connectFilter = [];
			WhatIfCompareViewTableService.getInstance(
				newId,
				true,
				new TableFilterListDto().setup({
					list: [
						{
							propertyName: 'whatIf',
							propertyType: 'Long',
							type: 'EQ',
							value: Number.parseInt(id, 10)
						},
						...connectFilter
					],
					activeCount: 2
				})
			).then(resp => {
				setSessionId(newId);
			});
		} else {
			getList();
		}
	}, []);

	useEffect(() => {
		if (sessionId) getList();
	}, [sessionId]);

	const getList = () => {
		WhatIfCompareViewTableService.getList(sessionId, 0, 0).then(result => {
			setViewList(result.data || []);
			setLoading(false);
		});
	};

	const handleDelete = rowId => {
		setShowDeleteConfirm(false);
		setLoading(true);
		WhatIfCompareViewTableService.deleteById(sessionId, rowId).then(() => getList());
	};

	return (
		<ThemeProvider theme={mainTheme}>
			<Dialog open maxWidth="lg" fullWidth>
				<DialogTitle disableTypography className="border-b">
					Compare views list
				</DialogTitle>
				<DialogContent className="p-20">
					{loading && <FuseLoading />}
					{!loading && (
						<TableContainer component={Paper}>
							<Table className="w-full" aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell>Creator</TableCell>
										<TableCell align="right">Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{viewList.length > 0 ? (
										viewList.map(row => (
											<TableRow key={row.name}>
												<TableCell component="th" scope="row">
													{row.name}
												</TableCell>
												<TableCell>{row.creator ? row.creator.name : ''}</TableCell>
												<TableCell className="font-mono" align="right">
													<Tooltip title="Use this View">
														<span>
															<IconButton aria-label="load" onClick={e => props.onLoadView(row)}>
																<LoadIcon fontSize="small" />
															</IconButton>
														</span>
													</Tooltip>
													<Tooltip title={t('tableBrowser:MODIFY')}>
														<span>
															<IconButton aria-label="modify" onClick={() => props.onModify(row.id)}>
																<SettingsIcon fontSize="small" />
															</IconButton>
														</span>
													</Tooltip>
													<Tooltip title={t('tableBrowser:DELETE')}>
														<span>
															<IconButton
																aria-label="delete"
																onClick={e => {
																	setShowDeleteConfirm(true);
																	e.preventDefault();
																}}
															>
																<DeleteIcon fontSize="small" />
															</IconButton>
														</span>
													</Tooltip>
													<ConfirmDialog
														show={showDeleteConfirm}
														title={t('tableBrowser:DELETE')}
														description={t('tableBrowser:DELETE_DESC')}
														onYes={() => handleDelete(row.id)}
														onClose={() => setShowDeleteConfirm(false)}
													/>
												</TableCell>
											</TableRow>
										))
									) : (
										<TableRow>
											<TableCell component="th" scope="row" colSpan={3} align="center">
												No Compare views available
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</DialogContent>
				<DialogActions className="justify-between border-t p-20">
					<Button
						onClick={() => {
							props.onClose();
						}}
					>
						Close
					</Button>
					<Button
						onClick={() => {
							props.onLoadView(null);
						}}
					>
						Show all attributes
					</Button>
					<Button
						onClick={() => {
							props.onNew();
						}}
					>
						Add new
					</Button>
				</DialogActions>
			</Dialog>
		</ThemeProvider>
	);
}
