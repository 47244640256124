import _ from '@lodash';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import VariableType from 'app/admin/modelAnn/dto/VariableType';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import AspectStateButton from 'app/admin/aspect/component/AspectStateButton';
import ModelCalculate from 'app/admin/common/component/ModelCalculate';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormComponentMassSomethingField from 'app/admin/common/component/EditFormComponentMassSomethingField';
import EditFormGridComponent from 'modules/ui/editform/subForm/EditFormGridComponent';
import { useParams, withRouter } from 'react-router';
import ModelService from 'app/admin/model/service/ModelService';
import DatasetService from 'app/admin/dataset/service/DatasetService';
import StorageModelService from 'app/admin/model/service/StorageModelService';
import AspectEditService from '../service/AspectEditService';
import AspectModelAttributeLoad from '../component/AspectModelAttributeLoad';
import CreateDatasetByAspectButton from '../component/CreateDatasetByAspectButton';
import AspectInputAttributeClass from '../dto/AspectInputAttributeClass';
import AspectOutputAttributeClass from '../dto/AspectOutputAttributeClass';
import CalculateBackground from '../component/CalculateBackground';

const handleModelValue = (value, field, methods) => {
	if (value) {
		const { getValues, setValue } = methods;
		const values = getValues();
		if (values.input_attributes.length === 0 && values.output_attributes.length === 0) {
			ModelService.getData(value).then(model => {
				if (model.export) {
					setValue(
						'input_attributes',
						model.export.input_attributes.map(d => {
							return {
								id: null,
								name: d.name,
								type: d.type,
								value: d.value,
								attribute_class: null
							};
						})
					);
					setValue(
						'output_attributes',
						model.export.output_attributes.map(d => {
							return {
								id: null,
								name: d.name,
								attribute_class: null
							};
						})
					);
				} else {
					setValue(
						'input_attributes',
						model.input_attributes.map(d => {
							return {
								id: null,
								name: d.name,
								type: d.type,
								value: d.value,
								attribute_class: null
							};
						})
					);
					setValue(
						'output_attributes',
						model.output_attributes.map(d => {
							return {
								id: null,
								name: d.name,
								attribute_class: null
							};
						})
					);
				}
			});
		}
	}
};

export const queryAttributeName = (input, limit, optionsPromise) => {
	const inputLower = input.toLowerCase();
	return new Promise(resolve => {
		optionsPromise.then(options => {
			if (input != null && input.length > 2) {
				const result = options.filter(opt => opt.value.toLowerCase().includes(inputLower)).map(d => d.value);
				resolve(result.length > limit ? result.slice(0, limit) : result);
			}
			resolve([]);
		});
	});
};

export const aspectInputAttributeFields = storageModelService => {
	return [
		{
			key: 'name',
			component: EditFormTextField,
			required: true,
			filterKey: '^model',
			query: (q, limit, model) => queryAttributeName(q, limit, storageModelService.getOutputAttributesListSelection(model))
		},
		{
			key: 'model_field_name',
			component: EditFormSelectField,
			type: 'enum',
			filterKey: '^model',
			items: filterValue => {
				return filterValue ? storageModelService.getInputAttributesListSelection(filterValue) : new Promise(resolve => resolve([]));
			}
		},
		{
			key: 'attribute_class',
			component: EditFormSelectField,
			required: true,
			type: 'enum',
			items: Object.keys(AspectInputAttributeClass).map(key => {
				return { label: AspectInputAttributeClass[key], value: key };
			})
		},
		{
			key: 'type',
			component: EditFormSelectField,
			required: true,
			type: 'enum',
			items: Object.keys(VariableType).map(key => {
				return { label: VariableType[key], value: key };
			})
		},
		{
			key: 'value',
			component: EditFormTextField,
			required: true,
			dependsOn: 'type',
			visibleIf: type => type === 'Variable' || type === 'SpreadSheet' || type === null,
			type: 'number'
		},
		{
			key: 'value',
			component: EditFormComponentMassSomethingField,
			dependsOn: 'type',
			visibleIf: type => type === 'ComponentMassFrac'
		}
	];
};

const config = data => {
	const storageModelService = new StorageModelService();
	return {
		key: 'aspectEditForm',
		topic: 'aspect',
		service: AspectEditService,
		tabList: initData => {
			return [{ key: 'basic' }, { key: 'input_attributes' }, { key: 'output_attributes' }];
		},
		backLink: () => '/aspect',
		fields: initData => {
			const fields = [
				{
					key: 'name',
					component: EditFormTextField,
					required: true
				},
				{
					key: 'description',
					component: EditFormTextField,
					multiline: true
				},
				{
					key: 'model',
					component: EditFormSelectField,
					required: true,
					items: () => ModelService.getListSelection(),
					onChange: (value, field, methods) => handleModelValue(value, field, methods)
				},
				{
					key: 'dataset',
					component: EditFormSelectField,
					dependsOn: 'model',
					visibleIf: model => model,
					filterKey: 'datasetListChanged',
					items: () => DatasetService.getListSelection()
				},
				{
					key: 'input_attributes',
					tab: 'input_attributes',
					component: EditFormGridComponent,
					defaultValue: {
						id: null,
						name: null,
						type: null,
						value: null,
						attribute_class: null,
						model_field_name: null
					},
					fields: aspectInputAttributeFields(storageModelService)
				},
				{
					key: 'output_attributes',
					tab: 'output_attributes',
					component: EditFormGridComponent,
					defaultValue: {
						id: null,
						name: null,
						attribute_class: null,
						model_field_name: null
					},
					fields: [
						{
							key: 'name',
							component: EditFormTextField,
							className: 'min-w-min',
							required: true,
							filterKey: '^model',
							query: (q, limit, model) => queryAttributeName(q, limit, storageModelService.getOutputAttributesListSelection(model))
						},
						{
							key: 'model_field_name',
							component: EditFormSelectField,
							type: 'enum',
							filterKey: '^model',
							items: filterValue => {
								return filterValue ? storageModelService.getOutputAttributesListSelection(filterValue) : new Promise(resolve => resolve([]));
							}
						},
						{
							key: 'attribute_class',
							component: EditFormSelectField,
							required: true,
							type: 'enum',
							items: Object.keys(AspectOutputAttributeClass).map(key => {
								return { label: AspectOutputAttributeClass[key], value: key };
							})
						}
					]
				}
			];

			return fields;
		}
	};
};

function AspectEditForm() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const myConfig = config({ id });
	return (
		<EditFormPage
			config={myConfig}
			beforeSaveContent={
				<>
					<CreateDatasetByAspectButton id={id} />
					<AspectStateButton id={id} />
					<AspectModelAttributeLoad id={id} />
					<ModelCalculate id={id} type="aspect" />
					<CalculateBackground id={id} type="aspect" />
				</>
			}
			onSave={result => {
				if (!result.engineSuccessful) dispatch(showMessage({ message: `Engine: ${result.engineMessage}` }));
			}}
		/>
	);
}

export default withRouter(AspectEditForm);
