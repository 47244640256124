import _ from '@lodash';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import { useWhatIf } from '../component/WhatIfProvider';
import ColorPickerField from '../component/ColorPickerField';

function RectItemProperties(props) {
	const { data, setData, figure, setFigure, showPropertiesDialog, setShowPropertiesDialog } = useWhatIf();
	const { t } = useTranslation('whatIf');
	const item = figure.rect.find(d => d.key === showPropertiesDialog.key);
	const methods = useForm({
		defaultValues: item
	});

	const handleSave = () => {
		const value = methods.getValues();
		setFigure({
			...figure,
			rect: figure.rect.map(c => (c.key === item.key ? value : c))
		});
		setShowPropertiesDialog(null);
	};

	return (
		<Dialog open fullWidth>
			<DialogTitle disableTypography className="border-b">
				{t('rectProperties')}
			</DialogTitle>
			<DialogContent className="p-20">
				<FormProvider {...methods}>
					<Controller
						control={methods.control}
						name="name"
						render={({ field, fieldState }) => (
							<EditFormTextField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'whatIf' }}
								fieldConfig={{
									key: 'name',
									text: t('name')
								}}
							/>
						)}
					/>
					<Controller control={methods.control} name="borderColor" render={({ field, fieldState }) => <ColorPickerField key="borderColor" label="borderColorLabel" field={field} fieldState={fieldState} />} />
					<Controller
						control={methods.control}
						name="fill"
						render={({ field, fieldState }) => (
							<EditFormCheckboxField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'whatIf' }}
								fieldConfig={{
									key: 'fill',
									text: t('fillLabel')
								}}
							/>
						)}
					/>
					<Controller control={methods.control} name="fillColor" render={({ field, fieldState }) => <ColorPickerField key="fillColor" label="fillColorLabel" field={field} fieldState={fieldState} />} />
				</FormProvider>
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button
					onClick={() => {
						setShowPropertiesDialog(null);
					}}
				>
					{t('cancel')}
				</Button>
				<Button onClick={() => handleSave()} color="primary" autoFocus>
					{t('save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
export default RectItemProperties;
