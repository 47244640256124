import withReducer from 'app/store/withReducer';
import { useDispatch } from 'react-redux';
import reducer from 'modules/ui/tablebrowser/store';
import _ from '@lodash';
import { useParams } from 'react-router';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Tabs, Tab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormTableBrowserField from 'modules/ui/editform/fields/EditFormTableBrowserField';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import { getSessionString } from 'modules/base/utils/GenericUtils';
import { useRef } from 'react';
import { useWhatIf } from '../component/WhatIfProvider';
import DocumentItemForm from './DocumentItemForm';
import DocumentItemFormProvider, { useDocumentItemForm } from './DocumentItemFormProvider';
import DocumentListTableConfig from './DocumentListTableConfig';
import ColorPickerField from '../component/ColorPickerField';

function DocumentItemProperties(props) {
	return (
		<DocumentItemFormProvider>
			<DocumentItemPropertiesComponent />
		</DocumentItemFormProvider>
	);
}

function DocumentItemPropertiesComponent(props) {
	const { id } = useParams('id');
	const dispatch = useDispatch();
	const documentSaveFormRef = useRef(null);
	const { data, setData, figure, setFigure, showPropertiesDialog, setShowPropertiesDialog, iconset } = useWhatIf();
	const { t } = useTranslation(['whatIf', 'validation']);
	const { setDocumentId, tabValue, setTabValue } = useDocumentItemForm();
	const schema = yup.object().shape({
		name: yup.string().nullable().required(t('validation:FIELD_REQUIRED'))
	});
	const item = figure.document.find(d => d.key === showPropertiesDialog.key);
	if (_.isUndefined(item.icon)) item.icon = null;
	if (_.isUndefined(item.iconSize)) item.iconSize = 64;
	if (_.isUndefined(item.showLabel)) item.showLabel = true;

	const methods = useForm({
		defaultValues: item,
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const handleCancel = () => {
		if (item.isNew)
			setFigure({
				...figure,
				document: figure.document.filter(d => d.key !== item.key)
			});
		setShowPropertiesDialog(null);
	};

	const handleSave = () => {
		const value = methods.getValues();
		value.isNew = false;
		setFigure({
			...figure,
			document: figure.document.map(c => (c.key === item.key ? value : c))
		});
		setShowPropertiesDialog(null);
	};

	const handleFileSave = () => {
		dispatch({ type: `documentListTable/event/setNeedDataReload` });
		setTabValue(1);
		setDocumentId(null);
	};

	return (
		<Dialog open maxWidth="lg" fullWidth>
			<DialogTitle disableTypography className="border-b">
				<div className="flex flex-row w-full">
					<div className="flex mr-24 items-center">{t('documentProperties')}</div>
					<Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} aria-label="document-properties-tabs">
						<Tab label="Basic" />
						<Tab label="Documents" />
						<Tab label="File" />
					</Tabs>
				</div>
			</DialogTitle>
			<DialogContent className="p-20">
				<FormProvider {...methods}>
					<div className={tabValue !== 0 ? 'hidden' : ''}>
						<Controller
							control={methods.control}
							name="name"
							render={({ field, fieldState }) => (
								<EditFormTextField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'name',
										text: t('name')
									}}
								/>
							)}
						/>
						<Controller
							control={methods.control}
							name="icon"
							render={({ field, fieldState }) => (
								<EditFormSelectField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'icon',
										text: t('icon'),
										items: iconset
									}}
								/>
							)}
						/>
						<div className="flex flex-row">
							<Controller
								control={methods.control}
								name="fontSize"
								render={({ field, fieldState }) => (
									<EditFormTextField
										field={field}
										fieldState={fieldState}
										config={{ topic: 'whatIf' }}
										fieldConfig={{
											key: 'fontSize',
											text: t('fontSize'),
											type: 'number',
											className: 'mr-8'
										}}
									/>
								)}
							/>
							<Controller
								control={methods.control}
								name="iconSize"
								render={({ field, fieldState }) => (
									<EditFormTextField
										field={field}
										fieldState={fieldState}
										config={{ topic: 'whatIf' }}
										fieldConfig={{
											key: 'iconSize',
											text: t('iconSize'),
											type: 'number',
											className: 'ml-8 mr-8'
										}}
									/>
								)}
							/>
							<Controller
								control={methods.control}
								name="showLabel"
								render={({ field, fieldState }) => (
									<EditFormCheckboxField
										field={field}
										fieldState={fieldState}
										config={{ topic: 'whatIf' }}
										fieldConfig={{
											key: 'showLabel',
											text: t('showLabel')
										}}
									/>
								)}
							/>
						</div>
						<Controller control={methods.control} name="borderColor" render={({ field, fieldState }) => <ColorPickerField key="borderColor" label="backgroundColorLabel" field={field} fieldState={fieldState} />} />
						<Controller control={methods.control} name="fillColor" render={({ field, fieldState }) => <ColorPickerField key="fillColor" label="fontColorLabel" field={field} fieldState={fieldState} />} />
					</div>
					<div className={tabValue !== 1 ? 'hidden' : ''}>
						<EditFormTableBrowserField fieldConfig={{ key: getSessionString(), config: v => DocumentListTableConfig(item, false) }} />
					</div>
					<div className={tabValue !== 2 ? 'hidden' : ''}>
						<DocumentItemForm
							saveRef={documentSaveFormRef}
							whatIfId={id !== 'new' ? Number.parseInt(id, 10) : null}
							item={item}
							onChange={resp => {
								handleFileSave();
							}}
						/>
					</div>
				</FormProvider>
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button
					onClick={() => {
						if (tabValue === 2) {
							setDocumentId(null);
							setTabValue(1);
						} else handleCancel();
					}}
				>
					{t('cancel')}
				</Button>
				<Button
					onClick={() => {
						if (tabValue === 2) documentSaveFormRef.current();
						else methods.handleSubmit(handleSave)();
					}}
					color="primary"
					autoFocus
				>
					{t('save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default withReducer('documentListTable', reducer('documentListTable'))(DocumentItemProperties);
