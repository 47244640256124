import nBossConnection from 'modules/base/service/nBossConnection';
import OcuEntityValidationResult from 'app/admin/common/dto/OcuEntityValidationResult';
import PiMeasurementReaderDefinitionDto from '../dto/PiMeasurementReaderDefinitionDto';

class UpdateTaskEditService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'updateTaskEditControl';
	}

	/**
	 * @param {number} id
	 * @return {Promise<PiMeasurementReaderDefinitionDto>}
	 */
	getData(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getData`, {
					id
				})
				.then(response => {
					const res = new PiMeasurementReaderDefinitionDto().setup(response.data);
					res.id = id;
					resolve(res);
				})
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @param {PiMeasurementReaderDefinitionDto} dto
	 * @return {Promise<OcuEntityValidationResult>}
	 */
	update(id, dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/update`, {
					id,
					dto
				})
				.then(response => resolve(new OcuEntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}
}

export default new UpdateTaskEditService();
