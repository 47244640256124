import _ from '@lodash';
import { useState, createContext, useContext } from 'react';

export const DocumentItemFormContext = createContext(null);

export const useDocumentItemForm = () => {
	return useContext(DocumentItemFormContext);
};

export default function DocumentItemFormProvider(props) {
	const [documentId, setDocumentId] = useState(null);
	const [tabValue, setTabValue] = useState(0);

	const value = {
		documentId,
		setDocumentId,
		tabValue,
		setTabValue
	};

	return <DocumentItemFormContext.Provider value={value}>{props.children}</DocumentItemFormContext.Provider>;
}
