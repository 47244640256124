import _ from '@lodash';
import WorkflowItem from '../WorkflowItem';

export const defaultDefinition = {
	name: null,
	input_fields: [],
	output_fields: [],
	add_keys: [],
	subtract_keys: [],
	output_key: 'Total'
};

export const converter = function (data) {
	return {
		...data,
		/*input_fields: [
			...data.add_keys.map(key => {
				return { name: key, type: 'Variable' };
			}),
			...data.subtract_keys.map(key => {
				return { name: key, type: 'Variable' };
			})
		],*/
		output_fields: [{ name: data.output_key, type: 'Variable' }]
	};
};

export default function TotalTransformation(props) {
	return <WorkflowItem {...props} type="TOTAL" />;
}
