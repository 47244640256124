import NamedDto from 'modules/base/dto/NamedDto';
import WhatIfCompareViewGroupEditDto from './WhatIfCompareViewGroupEditDto';

export class WhatIfCompareViewEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {number} */
		this.whatIf = null;
		/** @type {number} */
		this.creator = null;
		/** @type {WhatIfCompareViewGroupEditDto[]} */
		this.groupList = null;
	}

	/**
	 * @typedef {Object} _WhatIfCompareViewEditDtoProps
	 * @property {number} whatIf
	 * @property {number} creator
	 * @property {WhatIfCompareViewGroupEditDto[]} groupList
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _WhatIfCompareViewEditDtoProps} WhatIfCompareViewEditDtoProps
	 */
	/**
	 *
	 * @param {WhatIfCompareViewEditDtoProps} props
	 * @returns {WhatIfCompareViewEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.whatIf = props.whatIf;
		this.creator = props.creator;
		this.groupList = props.groupList != null ? props.groupList.map(d => new WhatIfCompareViewGroupEditDto().setup(d)) : [];
		return this;
	}
}

export default WhatIfCompareViewEditDto;
