import _ from '@lodash';
import defaultLabelStyle from 'app/admin/common/component/DefaultLabelStyle';
import { useRef, useState, Fragment, useEffect } from 'react';
import { Group, Text, Rect } from 'react-konva';
import { useWhatIf } from '../component/WhatIfProvider';
import { formatLabelValue } from '../component/WhatIfUtil';
import LabelIconView from '../view/LabelIconView';
import IconOnlyView from '../view/IconOnlyView';
import LabelOnlyView from '../view/LabelOnlyView';

const rowHeight = 24;
const borderColor = 'darkGreen';
const textColor = 'darkGreen';
const defaultKeyCellMinWidth = 180;
const defaultValueCellMinWidth = 120;
const defaultUomCellMinWidth = 100;

export const convertSumTableData = (item, data, compare) => {
	const _tableData = [];
	if (item && data.experiment && data.experiment.attributes) {
		item.valueKeyList.forEach(id => {
			const idParts = id.split('.');
			const attribute = data.experiment.attributes.find(attrib => attrib.entity === idParts[0] && attrib.io === idParts[1] && attrib.name === idParts[2]);
			if (attribute) {
				const compareFields = compare && compare.attributes && attribute.io === 'OUTPUT' ? compare.attributes.find(attrib => attrib.io === 'OUTPUT' && attrib.entity === attribute.entity && attrib.name === attribute.name) : null;
				if (_.isObject(attribute.value)) {
					if (_tableData.findIndex(td => td.entity === idParts[0] && td.io === idParts[1] && td.name === idParts[2]) === -1) {
						_tableData.push({
							...attribute,
							children: [],
							cellType: 'HEADER'
						});
					}
					if (idParts.length > 2) {
						const subKey = idParts[3];
						if (!_.isUndefined(attribute.value[subKey])) {
							const tableItem = _tableData.find(td => td.entity === idParts[0] && td.io === idParts[1] && td.name === idParts[2]);
							const value = attribute.value[subKey];
							const compareValue = compareFields != null && _.isObject(compareFields.value) && _.isNumber(compareFields.value[subKey]) ? compareFields.value[subKey] : null;
							const diff = _.isNumber(compareValue) && _.isNumber(value) ? value - compareValue : null;
							const diffPercent = diff != null && diff !== 0 && compareValue !== 0 ? diff / compareValue : null;
							tableItem.children.push({
								id: null,
								name: subKey,
								type: attribute.type,
								entity: attribute.entity,
								io: attribute.io,
								uom: attribute.uom,
								value: attribute.value[subKey],
								valueKey: id,
								compareValue,
								diff,
								diffPercent
							});
						}
					}
				} else {
					const { value } = attribute;
					const compareValue = compareFields != null && _.isNumber(compareFields.value) ? compareFields.value : null;
					const diff = _.isNumber(compareValue) && _.isNumber(value) ? value - compareValue : null;
					const diffPercent = diff != null && diff !== 0 && compareValue !== 0 ? diff / compareValue : null;
					_tableData.push({
						...attribute,
						cellType: 'DATA',
						valueKey: id,
						compareValue,
						diff,
						diffPercent
					});
				}
			}
		});
	}

	return _tableData;
};

function SumTableItem({ item }) {
	const [pos, setPos] = useState({ x: item.x, y: item.y });
	const { viewMode, editMode, data, setData, figure, setFigure, setShowPropertiesDialog, setSummaryTableDialog } = useWhatIf();
	const [isDragging, setIsDragging] = useState(false);
	const [keyCellMinWidth, setKeyCellMinWidth] = useState(defaultKeyCellMinWidth);
	const [valueCellMinWidth, setValueCellMinWidth] = useState(defaultValueCellMinWidth);
	const [uomCellMinWidth, setUomCellMinWidth] = useState(defaultUomCellMinWidth);
	const [tableData, setTableData] = useState([]);
	let row = 0;

	useEffect(() => {
		setTableData(convertSumTableData(item, data, null));
	}, [item, data]);

	const handleItemClick = () => {
		if (editMode === 'DELETE') {
			setFigure({
				...figure,
				sumTable: figure.sumTable.filter(d => d.key !== item.key)
			});
		}
	};

	const updateDragPos = e => {
		const currentPos = { x: e.target.x(), y: e.target.y() };
		setPos(currentPos);
		setFigure({
			...figure,
			sumTable: figure.sumTable.map(d => {
				if (d.key === item.key) {
					return { ...item, ...currentPos };
				}
				return d;
			})
		});
	};

	const handleDblClick = e => {
		if (viewMode === 'EDIT' && editMode === 'MOVE') {
			setShowPropertiesDialog({ type: 'SUMTABLE', key: item.key });
		} else if (viewMode === 'CALCULATE' && item.tableInDialog) {
			setSummaryTableDialog(item);
		}
	};

	const renderHeader = (title, center) => {
		const result = <TableHeader width={keyCellMinWidth + valueCellMinWidth + uomCellMinWidth} name={title} row={row} center={center} />;
		row += 1;
		return result;
	};
	const renderData = (key, itemData, isSub) => {
		const result = (
			<Fragment key={key}>
				<TableKeyCell cellMinWidth={keyCellMinWidth} label={isSub ? itemData.name : `${itemData.name} ${itemData.io === 'INPUT' ? 'Input' : 'Output'}`} row={row} onWidth={w => setKeyCellMinWidth(w)} isSub={isSub} />
				<TableValueCell cellMinWidth={valueCellMinWidth} itemData={itemData} row={row} onWidth={w => setValueCellMinWidth(w)} xOffset={keyCellMinWidth} itemStyle={isSub ? item.componentStyle : item.variableStyle} tablePos={pos} />
				<TableUnitCell cellMinWidth={uomCellMinWidth} row={row} unit={itemData.uom} onWidth={w => setUomCellMinWidth(w)} xOffset={keyCellMinWidth + valueCellMinWidth} itemStyle={isSub ? item.componentStyle : item.variableStyle} />
			</Fragment>
		);
		row += 1;
		return result;
	};

	const renderSubData = itemData => {
		return (
			<>
				{renderHeader(`${itemData.name} ${itemData.io === 'INPUT' ? 'Input' : 'Output'}`)}
				{itemData.children.map(subItemData => renderData(`${itemData.name}.${subItemData.name}`, subItemData, true))}
			</>
		);
	};

	if (item.tableInDialog) {
		const ViewComponent = item.icon && item.showLabel ? LabelIconView : item.icon ? IconOnlyView : LabelOnlyView;
		return (
			<ViewComponent
				x={pos.x}
				y={pos.y}
				draggable={editMode === 'MOVE'}
				onPointerClick={e => handleItemClick(e)}
				onPointerDblClick={e => handleDblClick(e)}
				onDragStart={e => {
					setIsDragging(true);
				}}
				onDragMove={e => {
					updateDragPos(e);
				}}
				onDragEnd={e => {
					updateDragPos(e);
					setIsDragging(false);
				}}
				item={item}
				labelText={item.name}
				isDragging={isDragging}
			/>
		);
	}

	const tableRowCount = () => {
		let ct = tableData.length;
		tableData.forEach(d => {
			if (d.children) ct += d.children.length;
		});
		return ct;
	};

	return (
		<Table
			item={item}
			x={pos.x}
			y={pos.y}
			rowHeight={rowHeight}
			width={keyCellMinWidth + valueCellMinWidth + uomCellMinWidth}
			height={rowHeight + rowHeight * tableRowCount()}
			draggable={editMode === 'MOVE'}
			onPointerClick={e => handleItemClick(e)}
			onPointerDblClick={e => handleDblClick(e)}
			onDragStart={e => {
				setIsDragging(true);
			}}
			onDragMove={e => {
				updateDragPos(e);
			}}
			onDragEnd={e => {
				updateDragPos(e);
				setIsDragging(false);
			}}
		>
			{renderHeader(item.name, true)}
			{tableData.map(itemData => (
				<Fragment key={itemData.id || itemData.name}>{itemData.cellType === 'DATA' ? renderData(itemData.name, itemData, false) : renderSubData(itemData)}</Fragment>
			))}
		</Table>
	);
}

function Table(props) {
	const { x, y, width, height } = props;
	return (
		<Group {...props}>
			<Rect x={0} y={0} height={height} width={width} fill="white" stroke={borderColor} />
			<Text x={x} y={y} height={height} width={width} text="Hello" verticalAlign="middle" align="center" />
			{props.children}
		</Group>
	);
}

const TableHeader = ({ width, row, name, center }) => {
	return (
		<>
			<Rect x={0} y={rowHeight * row} height={rowHeight} width={width} stroke={borderColor} />
			<Text y={rowHeight * row} padding={5} text={name || 'No name'} fontSize={12} fill={textColor} fillAfterStrokeEnabled align={center ? 'center' : 'left'} verticalAlign="middle" width={width} height={rowHeight} />
		</>
	);
};

const TableKeyCell = ({ label, row, cellMinWidth, onWidth, isSub }) => {
	const textRef = useRef(null);
	const width = textRef.current ? textRef.current.measureSize(label).width + 5 * 2 : null;
	if (width !== null && width > cellMinWidth && onWidth) {
		onWidth(width);
	}
	return (
		<>
			<Rect x={0} y={rowHeight * row} height={rowHeight} width={cellMinWidth} stroke={borderColor} />
			<Text ref={textRef} x={isSub ? 12 : 0} y={rowHeight * row} padding={5} text={label} fontSize={12} fill={textColor} fillAfterStrokeEnabled verticalAlign="middle" width={cellMinWidth} height={rowHeight} />
		</>
	);
};

const TableValueCell = ({ itemData, row, cellMinWidth, onWidth, xOffset, itemStyle, tablePos }) => {
	const { viewMode, setValueModify, getExperimentValidity } = useWhatIf();
	const style = itemStyle || defaultLabelStyle(false);
	const label = formatLabelValue(itemData.value, style);
	const textRef = useRef(null);
	const width = textRef.current ? textRef.current.measureSize(label).width + 5 * 2 : null;
	if (width !== null && width > cellMinWidth && onWidth) {
		onWidth(width);
	}
	const handleValueClick = () => {
		if (viewMode === 'CALCULATE' && itemData.io === 'INPUT' && itemData.valueKey) {
			setValueModify({ ...itemData, x: tablePos.x + xOffset, y: tablePos.y + rowHeight * row });
		}
	};
	const isInvalid = () => {
		if (viewMode === 'CALCULATE' && itemData.io === 'OUTPUT' && !getExperimentValidity()) {
			return true;
		}
		return false;
	};

	return (
		<>
			<Rect x={xOffset} y={rowHeight * row} height={rowHeight} width={cellMinWidth} stroke={borderColor} />
			<Text
				ref={textRef}
				x={xOffset}
				y={rowHeight * row}
				padding={5}
				text={label}
				fontSize={12}
				fill={textColor}
				fillAfterStrokeEnabled
				align="right"
				verticalAlign="middle"
				width={cellMinWidth}
				height={rowHeight}
				onPointerDblClick={e => handleValueClick()}
				opacity={isInvalid() ? 0.001 : 1}
			/>
		</>
	);
};

const TableUnitCell = ({ row, unit, cellMinWidth, onWidth, xOffset, itemStyle }) => {
	const textRef = useRef(null);
	const style = itemStyle || defaultLabelStyle(false);
	const width = textRef.current ? textRef.current.measureSize(unit).width + 5 * 2 : null;

	if (width !== null && width > cellMinWidth && onWidth) {
		onWidth(width);
	}
	return (
		<>
			<Rect x={xOffset} y={rowHeight * row} height={rowHeight} width={cellMinWidth} stroke={borderColor} />
			{style.showUnit && <Text ref={textRef} x={xOffset} y={rowHeight * row} padding={5} text={unit} fontSize={12} fill={textColor} fillAfterStrokeEnabled align="left" verticalAlign="middle" width={cellMinWidth} height={rowHeight} />}
		</>
	);
};

export default SumTableItem;
