import NamedDto from 'modules/base/dto/NamedDto';

export default class MeasurementUnitEditDto extends NamedDto {
	/**
	 * @typedef {Object} _MeasurementUnitEditDtoProps
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _MeasurementUnitEditDtoProps} MeasurementUnitEditDtoProps
	 */
	/**
	 *
	 * @param {MeasurementUnitEditDtoProps} props
	 * @returns {MeasurementUnitEditDto}
	 */
	setup(props) {
		super.setup(props);
		return this;
	}
}
