import NamedDto from 'modules/base/dto/NamedDto';
import MeasurementFieldEditDto from './MeasurementFieldEditDto';

export class MeasurementEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.table_name = null;
		/** @type {string} */
		this.description = null;
		/** @type {MeasurementFieldEditDto[]} */
		this.fields = null;
	}

	/**
	 * @typedef {Object} _MeasurementEditDtoProps
	 * @property {string} table_name
	 * @property {string} description
	 * @property {MeasurementFieldEditDto[]} fields
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _MeasurementEditDtoProps} MeasurementEditDtoProps
	 */
	/**
	 *
	 * @param {MeasurementEditDtoProps} props
	 * @returns {MeasurementEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.table_name = props.table_name;
		this.description = props.description;
		this.fields = props.fields !== null ? props.fields.map(d => new MeasurementFieldEditDto().setup(d)) : [];
		return this;
	}
}

export default MeasurementEditDto;
