import _ from '@lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, Controller, useWatch } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ModelWorkflowValueType from 'app/admin/modelWorkflow/dto/ModelWorkflowValueType';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import IOType from 'app/admin/common/dto/IOType';
import EditFormComponentMassSomethingField from 'app/admin/common/component/EditFormComponentMassSomethingField';
import { useWorkflow } from '../WorkflowProvider';
import TransformerValueType from '../../dto/TransformerValueType';

const modelWorkflowValueTypeList = ModelWorkflowValueType.map(t => {
	return { label: t, value: t };
});

export const transformerValueTypeList = TransformerValueType.map(t => {
	return { label: t, value: t };
});

function WorkflowValueProperties(props) {
	const { data, setData, showPropertiesDialog, setShowPropertiesDialog, valueKeyAvailable, updateIOProperties, isTransformer } = useWorkflow();
	const { t } = useTranslation('transformer');
	const item = data.value.find(d => d.key === showPropertiesDialog.key);
	if (_.isUndefined(item.io)) item.io = null;
	if (_.isUndefined(item.valueType)) item.valueType = null;
	if (_.isUndefined(item.value)) item.value = null;
	if (_.isUndefined(item.uom)) item.uom = null;

	const schema = yup.object().shape({
		io: yup.string().nullable().required(t('validation:FIELD_REQUIRED')),
		valueKey: yup
			.string()
			.nullable()
			.required(t('validation:FIELD_REQUIRED'))
			.test({
				name: 'unique',
				exclusive: true,
				params: null,
				message: t('notUnique'),
				test: value => valueKeyAvailable(value, item.key)
			})
	});

	const methods = useForm({
		defaultValues: item,
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const valueType = useWatch({
		name: 'valueType',
		control: methods.control
	});

	const io = useWatch({
		name: 'io',
		control: methods.control
	});

	const handleSave = value => {
		updateIOProperties(item, value);
		setShowPropertiesDialog(null);
	};

	const handleCancel = () => {
		if (item.new) {
			setData({
				...data,
				value: data.value.filter(d => d.key !== item.key)
			});
		}
		setShowPropertiesDialog(null);
	};

	return (
		<Dialog open fullWidth>
			<DialogTitle disableTypography className="border-b">
				{t('valueProperties')}
			</DialogTitle>
			<DialogContent className="p-20">
				<FormProvider {...methods}>
					<Controller
						control={methods.control}
						name="io"
						render={({ field, fieldState }) => (
							<EditFormSelectField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'transformer' }}
								required
								fieldConfig={{
									key: 'io',
									text: t('io'),
									type: 'enum',
									items: Object.keys(IOType).map(d => {
										return { label: IOType[d], value: d };
									})
								}}
							/>
						)}
					/>
					<Controller
						control={methods.control}
						name="valueKey"
						render={({ field, fieldState }) => (
							<EditFormTextField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'transformer' }}
								required
								fieldConfig={{
									key: 'valueKey',
									text: t('valueKey')
								}}
							/>
						)}
					/>
					<Controller
						control={methods.control}
						name="uom"
						render={({ field, fieldState }) => (
							<EditFormTextField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'transformer' }}
								required
								fieldConfig={{
									key: 'uom',
									text: t('uom')
								}}
							/>
						)}
					/>
					<Controller
						control={methods.control}
						name="valueType"
						render={({ field, fieldState }) => (
							<EditFormSelectField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'transformer' }}
								required
								fieldConfig={{
									key: 'valueType',
									text: t('valueType'),
									type: 'enum',
									items: isTransformer ? transformerValueTypeList : modelWorkflowValueTypeList
								}}
							/>
						)}
					/>
					{!isTransformer && io !== 'OUTPUT' && valueType === 'Variable' && (
						<Controller
							control={methods.control}
							name="value"
							render={({ field, fieldState }) => (
								<EditFormTextField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'transformer' }}
									required
									fieldConfig={{
										key: 'value',
										text: t('value'),
										type: 'number'
									}}
								/>
							)}
						/>
					)}
					{!isTransformer && io !== 'OUTPUT' && valueType === 'ComponentMassFrac' && (
						<Controller
							control={methods.control}
							name="value"
							render={({ field, fieldState }) => (
								<EditFormComponentMassSomethingField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'transformer' }}
									required
									fieldConfig={{
										key: 'value',
										text: t('value')
									}}
								/>
							)}
						/>
					)}
				</FormProvider>
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button
					onClick={() => {
						handleCancel();
					}}
				>
					{t('cancel')}
				</Button>
				<Button onClick={methods.handleSubmit(handleSave)} color="primary" autoFocus>
					{t('save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
export default WorkflowValueProperties;
