import _ from 'lodash';
import NamedDto from 'modules/base/dto/NamedDto';

export default class TaskManagerTableDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.status = null;
		/** @type {string} */
		this.type = null;
		/** @type {Date} */
		this.ts = null;
		/** @type {object>} */
		this.definition = null;
		/** @type {object} */
		this.result = null;
		/** @type {string} */
		this.user_name = null;
		/** @type {Date} */
		this.ts_result = null;
		/** @type {string} */
		this.message = null;
		/** @type {string} */
		this.protectionLevel = null;
		/** @type {string} */
		this.file_count = null;
	}

	/**
	 * @typedef {Object} _TaskManagerTableDtoProps
	 * @property {string} status
	 * @property {string} type
	 * @property {Date} ts
	 * @property {object} definition
	 * @property {object} result
	 * @property {string} user_name
	 * @property {Date} ts
	 * @property {string} message
	 * @property {string} file_count
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _TaskManagerTableDtoProps} TaskManagerTableDtoProps
	 */
	/**
	 *
	 * @param {TaskManagerTableDtoProps} props
	 * @returns {TaskManagerTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.status = props.status;
		this.type = props.type;
		//this.ts = props.ts !== null ? new Date(props.ts * 1000) : null;
		this.ts = props.ts !== null ? new Date(props.ts) : null;
		this.definition = props.definition;
		this.result = props.result;
		this.message = props.message;
		this.user_name = props.user_name;
		this.ts_result = props.ts_result !== null ? new Date(props.ts_result) : null;
		this.protectionLevel = this.status !== 'New' ? 'CannotBeDeleted' : null;
		this.file_count = props.file_count;
		return this;
	}

	getAsString(key) {
		return super.getAsString(key);
	}
}
