import nBossConnection from 'modules/base/service/nBossConnection';
import EntityValidationResult from 'modules/base/dto/validation/EntityValidationResult';
import DualAnnealingOptimizerEditDto from '../dto/DualAnnealingOptimizerEditDto';

class DualAnnealingOptimizerEditService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'dualAnnealingOptimizerEditControl';
	}

	/**
	 * @return {Promise<DualAnnealingOptimizerEditDto>}
	 */
	create() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/create`)
				.then(response => resolve(new DualAnnealingOptimizerEditDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {string} type
	 * @return {Promise<DualAnnealingOptimizerEditDto>}
	 */
	createByType(type) {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/create`)
				.then(response =>
					resolve(
						new DualAnnealingOptimizerEditDto().setup({
							...response.data,
							type
						})
					)
				)
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<DualAnnealingOptimizerEditDto>}
	 */
	getData(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getData`, {
					id
				})
				.then(response => resolve(new DualAnnealingOptimizerEditDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {DualAnnealingOptimizerEditDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	save(dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/save`, {
					dto
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @param {DualAnnealingOptimizerEditDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	update(id, dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/update`, {
					id,
					dto
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}
}

export default new DualAnnealingOptimizerEditService();
