import _ from '@lodash';
import { converter as aspectConverter } from './items/aspect/AspectTransformation';
import { converter as expressionConverter } from './items/expression/ExpressionTransformation';
import { converter as filterZeroConverter } from './items/filterzero/FilterZeroTransformation';
import { converter as groupByPropertyConverter } from './items/groupbyproperty/GroupByPropertyTransformation';
import { converter as mergeComponentsConverter } from './items/mergecomponents/MergeComponentsTransformation';
import { converter as massFracToMassFlowConverter } from './items/massmole/MassFracToMassFlowTransformation';
import { converter as massFlowToMassFracConverter } from './items/massmole/MassFlowToMassFracTransformation';
import { converter as massFlowToMoleFlowConverter } from './items/massmole/MassFlowToMoleFlowTransformation';
import { converter as reformerConverter } from './items/reformer/ReformerTransformation';
import { converter as blenderConverter } from './items/blender/BlenderTransformation';
import { converter as dodoConverter } from './items/dodo/DodoTransformation';
import { converter as dutyConverter } from './items/duty/DutyTransformation';
import { converter as fractionatingConverter } from './items/fractionating/FractionatingTransformation';
import { converter as joinStreamsConverter } from './items/joinstreams/JoinStreamsTransformation';
import { converter as moleFlowToMassFlowConverter } from './items/massmole/MoleFlowToMassFlowTransformation';
import { converter as sumUpConverter } from './items/sumup/SumUpTransformation';
import { converter as totalConverter } from './items/total/TotalTransformation';
import { converter as scalarsToTableConverter } from './items/scalarsToTable/ScalarsToTableTransformation';
import { converter as tableToScalarsConverter } from './items/tableToScalars/TableToScalarsTransformation';
import { converter as switchConverter } from './items/switch/SwitchTransformation';
import TransformType from '../dto/TransformType';

export default function WorkflowConverter(name, data) {
	const exportData = {
		name,
		driver_name: 'workflow',
		driver_type: 'workflow',
		input_attributes: [],
		output_attributes: [],
		transformations: [],
		pipes: []
	};
	if (data) {
		data.value.forEach(value => {
			if (value.io === 'OUTPUT') {
				exportData.output_attributes.push({
					name: value.valueKey,
					type: value.valueType,
					...(value.uom ? { uom: value.uom } : {})
				});
			} else {
				exportData.input_attributes.push({
					name: value.valueKey,
					type: value.valueType,
					value: value.value,
					...(value.uom ? { uom: value.uom } : {})
				});
			}
		});
		data.transform.forEach(transform => {
			const _transform = { ...transform };
			switch (_transform.type) {
				case TransformType.ASPECT:
					exportData.transformations.push(aspectConverter(_transform));
					break;
				case TransformType.FILTER_ZERO:
					exportData.transformations.push(filterZeroConverter(_transform));
					break;
				case TransformType.GROUP_BY_PROPERTY:
					exportData.transformations.push(groupByPropertyConverter(_transform));
					break;
				case TransformType.MERGE_COMPONENTS:
					exportData.transformations.push(mergeComponentsConverter(_transform));
					break;
				case TransformType.MASSFRAC_TO_MASSFLOW:
					exportData.transformations.push(massFracToMassFlowConverter(_transform));
					break;
				case TransformType.MASSFLOW_TO_MASSFRAC:
					exportData.transformations.push(massFlowToMassFracConverter(_transform));
					break;
				case TransformType.MASSFLOW_TO_MOLEFLOW:
					exportData.transformations.push(massFlowToMoleFlowConverter(_transform));
					break;
				case TransformType.REFORMER: {
					exportData.transformations.push(reformerConverter(_transform));
					break;
				}
				case TransformType.BLENDER: {
					exportData.transformations.push(blenderConverter(_transform));
					break;
				}
				case TransformType.DODO: {
					exportData.transformations.push(dodoConverter(_transform));
					break;
				}
				case TransformType.DUTY: {
					exportData.transformations.push(dutyConverter(_transform));
					break;
				}
				case TransformType.FRACTIONATING: {
					exportData.transformations.push(fractionatingConverter(_transform));
					break;
				}
				case TransformType.JOIN_STREAMS: {
					exportData.transformations.push(joinStreamsConverter(_transform));
					break;
				}
				case TransformType.MOLEFLOW_TO_MASSFLOW: {
					exportData.transformations.push(moleFlowToMassFlowConverter(_transform));
					break;
				}
				case TransformType.SUM_UP: {
					exportData.transformations.push(sumUpConverter(_transform));
					break;
				}
				case TransformType.TOTAL: {
					exportData.transformations.push(totalConverter(_transform));
					break;
				}
				case TransformType.SCALARS_TO_TABLE: {
					exportData.transformations.push(scalarsToTableConverter(_transform));
					break;
				}
				case TransformType.TABLE_TO_SCALARS: {
					exportData.transformations.push(tableToScalarsConverter(_transform));
					break;
				}
				case TransformType.EXPRESSION: {
					exportData.transformations.push(expressionConverter(_transform, true));
					break;
				}
				case TransformType.SWITCH: {
					exportData.transformations.push(switchConverter(_transform, true));
					break;
				}

				default:
					break;
			}
		});
		data.line.forEach(line => {
			const startValue = data.value.find(v => v.key === line.start.key);
			const endValue = data.value.find(v => v.key === line.end.key);
			const startTransform = data.transform.find(v => v.key === line.start.key);
			const endTransform = data.transform.find(v => v.key === line.end.key);
			if (startValue && endTransform) {
				exportData.pipes.push({
					name: line.key,
					type: 'Input',
					input_attribute_name: startValue.valueKey,
					output_transformation_name: endTransform.name,
					output_field_name: line.end.valueKey
				});
			} else if (endValue && startTransform) {
				exportData.pipes.push({
					name: line.key,
					type: 'Output',
					input_transformation_name: startTransform.name,
					input_field_name: line.start.valueKey,
					output_attribute_name: endValue.valueKey
				});
			} else if (startTransform && endTransform) {
				exportData.pipes.push({
					name: line.key,
					type: 'Internal',
					input_transformation_name: startTransform.name,
					input_field_name: line.start.valueKey,
					output_transformation_name: endTransform.name,
					output_field_name: line.end.valueKey
				});
			}
		});
	}
	return exportData;
}
