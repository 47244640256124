import NamedDto from 'modules/base/dto/NamedDto';
import DomainEditDto from './DomainEditDto';
import OptimizationCaseLimitEditDto from './OptimizationCaseLimitEditDto';
import OptimizationCaseTransformerAttributeEditDto from './OptimizationCaseTransformerAttributeEditDto';

export class OptimizationCaseEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {number} */
		this.optimizer = null;
		/** @type {number} */
		this.optimizerLog = null;
		/** @type {number} */
		this.aspect = null;
		/** @type {number} */
		this.transformer = null;
		/** @type {DomainEditDto[]} */
		this.domains = null;
		/** @type {number} */
		this.imputed_value = null;
		/** @type {string} */
		this.optimization_goal = null;
		/** @type {string} */
		this.output_key = null;
		/** @type {OptimizationCaseLimitEditDto[]} */
		this.state_limits = null;
		/** @type {OptimizationCaseLimitEditDto[]} */
		this.aspect_limits = null;
		/** @type {OptimizationCaseLimitEditDto[]} */
		this.transformer_limits = null;
		/** @type {OptimizationCaseTransformerAttributeEditDto[]} */
		this.transformer_attributes = null;
	}

	/**
	 * @typedef {Object} _OptimizationCaseEditDtoProps
	 * @property {number} optimizer
	 * @property {number} optimizerLog
	 * @property {number} aspect
	 * @property {number} transformer
	 * @property {DomainEditDto[]} domains
	 * @property {number} imputed_value
	 * @property {string} optimization_goal
	 * @property {string} output_key
	 * @property {OptimizationCaseLimitEditDto[]} state_limits
	 * @property {OptimizationCaseLimitEditDto[]} aspect_limits
	 * @property {OptimizationCaseLimitEditDto[]} transformer_limits
	 * @property {OptimizationCaseTransformerAttributeEditDto[]} transformer_attributes
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _OptimizationCaseEditDtoProps} OptimizationCaseEditDtoProps
	 */
	/**
	 *
	 * @param {OptimizationCaseEditDtoProps} props
	 * @returns {OptimizationCaseEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.optimizer = props.optimizer;
		this.optimizerLog = props.optimizerLog;
		this.aspect = props.aspect;
		this.transformer = props.transformer;
		this.domains = props.domains !== null ? props.domains.map(d => new DomainEditDto().setup(d)) : [];
		this.imputed_value = props.imputed_value;
		this.optimization_goal = props.optimization_goal;
		this.output_key = props.output_key;
		this.state_limits = props.state_limits != null ? props.state_limits.map(d => new OptimizationCaseLimitEditDto().setup(d)) : [];
		this.aspect_limits = props.aspect_limits != null ? props.aspect_limits.map(d => new OptimizationCaseLimitEditDto().setup(d)) : [];
		this.transformer_limits = props.transformer_limits != null ? props.transformer_limits.map(d => new OptimizationCaseLimitEditDto().setup(d)) : [];
		this.transformer_attributes = props.transformer_attributes != null ? props.transformer_attributes.map(d => new OptimizationCaseTransformerAttributeEditDto().setup(d)) : [];
		return this;
	}
}

export default OptimizationCaseEditDto;
