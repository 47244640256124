import { combineReducers } from '@reduxjs/toolkit';
import tableBrowserSessionSlice from './tableBrowserSessionSlice';
import tableBrowserEventSlice from './tableBrowserEventSlice';
import tableBrowserFilterSlice from './tableBrowserFilterSlice';
import tableBrowserDataSlice from './tableBrowserDataSlice';

const reducer = key => {
	const tableBrowserSession = tableBrowserSessionSlice(key).reducer;
	const tableBrowserEvent = tableBrowserEventSlice(key).reducer;
	const tableBrowserFilter = tableBrowserFilterSlice(key).reducer;
	const tableBrowserData = tableBrowserDataSlice(key).reducer;
	return combineReducers({
		tableBrowserSession,
		tableBrowserEvent,
		tableBrowserFilter,
		tableBrowserData
	});
};

export default reducer;
