import { useSelector, useDispatch } from 'react-redux';
import { generateId } from 'app/admin/whatIf/component/WhatIfUtil';
import FuseLoading from '@fuse/core/FuseLoading';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { Button, Icon, ThemeProvider, Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { BsUpload } from 'react-icons/bs';
import { useForm, FormProvider, Controller, useFormContext, useFieldArray } from 'react-hook-form';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import AspectService from 'app/admin/aspect/service/AspectService';
import AspectEditService from 'app/admin/aspect/service/AspectEditService';
import ModelService from 'app/admin/model/service/ModelService';

export default function PiMeasurementLoadAspect() {
	const { getValues, reset } = useFormContext();
	const { t } = useTranslation('whatIf');
	const mainTheme = useSelector(selectMainTheme);
	const [showDialog, setShowDialog] = useState(false);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState(null);
	const schema = yup.object().shape({
		aspect: yup.number().nullable().required(t('validation:FIELD_REQUIRED'))
	});
	const methods = useForm({
		defaultValues: { aspect: null },
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const handleLoad = dto => {
		setLoading(true);
		setMessage(null);
		AspectEditService.getData(dto.aspect)
			.then(aspect => {
				if (aspect !== null && aspect.model !== null) {
					ModelService.getData(aspect.model)
						.then(model => {
							try {
								handleData(aspect, model);
								setLoading(false);
								setShowDialog(false);
							} catch (e) {
								setLoading(false);
								setMessage(`Failed to load aspect: ${e}`);
							}
						})
						.catch(e => {
							setMessage(`Failed to load aspect: ${e}`);
							setLoading(false);
						});
				} else {
					setMessage(`Failed to load aspect: aspect not found`);
					setLoading(false);
				}
			})
			.catch(e => {
				setMessage(`Failed to load aspect: ${e}`);
				setLoading(false);
			});
	};

	const handleData = (aspect, model) => {
		const result = [];
		const modelInputAttributes = model.export ? model.export.input_attributes : model.input_attributes;
		const modelOutputAttributes = model.export ? model.export.output_attributes : model.output_attributes;
		if (aspect.input_attributes !== null && aspect.input_attributes.length > 0 && modelInputAttributes && modelInputAttributes.length > 0) {
			aspect.input_attributes.forEach(input => {
				const modelData = modelInputAttributes.find(d => d.name === (input.model_field_name != null ? input.model_field_name : input.name));
				if (modelData) {
					result.push({ id: null, name: input.name, tag_name: null, uom: modelData.uom || null, category: 'Input' });
				}
			});
		}
		if (aspect.output_attributes !== null && aspect.output_attributes.length > 0 && modelOutputAttributes && modelOutputAttributes.length > 0) {
			aspect.output_attributes.forEach(output => {
				const modelData = modelOutputAttributes.find(d => d.name === (output.model_field_name != null ? output.model_field_name : output.name));
				if (modelData) {
					result.push({ id: null, name: output.name, tag_name: null, uom: modelData.uom || null, category: 'Output' });
				}
			});
		}
		reset({ ...getValues(), fields: result });
	};

	return (
		<>
			<Button
				className="whitespace-nowrap mx-4"
				variant="contained"
				color="secondary"
				onClick={() => {
					setLoading(false);
					setShowDialog(true);
				}}
				startIcon={<BsUpload className="hidden sm:flex" />}
			>
				Load Aspect
			</Button>
			<ThemeProvider theme={mainTheme}>
				<Dialog open={showDialog} fullWidth>
					<DialogTitle disableTypography className="border-b">
						Load key from Aspect
					</DialogTitle>
					<DialogContent className="p-20">
						<FormProvider {...methods}>
							{loading && <FuseLoading />}
							{!loading && (
								<>
									<Controller
										control={methods.control}
										name="aspect"
										render={({ field, fieldState }) => (
											<EditFormSelectField
												field={field}
												fieldState={fieldState}
												config={{ topic: 'whatIf' }}
												fieldConfig={{
													key: 'aspect',
													text: t('aspect'),
													items: () => AspectService.getListSelection()
												}}
											/>
										)}
									/>
									{message != null && <div>{message}</div>}
								</>
							)}
						</FormProvider>
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button
							onClick={() => {
								setShowDialog(false);
							}}
						>
							Close
						</Button>
						<Button onClick={methods.handleSubmit(handleLoad)}>Load</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		</>
	);
}
