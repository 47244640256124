import _ from '@lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import { useWhatIf } from '../component/WhatIfProvider';
import ColorPickerField from '../component/ColorPickerField';
import { defaultFigureData } from '../page/WhatIfEditPage';
import WhatIfService from '../service/WhatIfService';

function ContainerItemProperties(props) {
	const { data, setData, figure, setFigure, showPropertiesDialog, setShowPropertiesDialog, iconset } = useWhatIf();
	const { t } = useTranslation('whatIf');
	const item = figure.container.find(d => d.key === showPropertiesDialog.key);
	const schema = yup.object().shape({
		name: yup
			.string()
			.nullable()
			.required(t('validation:FIELD_REQUIRED'))
			.test({
				name: 'unique',
				exclusive: true,
				params: null,
				message: t('figureNameNotUnique'),
				test: value => nameAvailable(value)
			})
	});

	const methods = useForm({
		defaultValues: item,
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const handleCancel = () => {
		if (item.isNew)
			setFigure({
				...figure,
				container: figure.container.filter(d => d.key !== item.key)
			});
		setShowPropertiesDialog(null);
	};

	const handleSave = value => {
		if (value.isNew) {
			setData({
				...data,
				figure: [
					...data.figure,
					{
						...defaultFigureData,
						name: value.name,
						parent: figure.name
					}
				]
			});
			delete value.isNew;
		} else if (item.name !== value.name) {
			setData({
				...data,
				figure: figure.map(f => {
					if (f.name === item.name) return { ...f, name: value.name };
					if (f.parent != null && f.parent === item.name) return { ...f, parent: value.name };
					return f;
				})
			});
		}
		setFigure({
			...figure,
			container: figure.container.map(c => (c.key === item.key ? value : c))
		});
		setShowPropertiesDialog(null);
	};

	const nameAvailable = newName => {
		return data.figure.findIndex(f => f.name !== item.name && f.name === newName) === -1;
	};

	return (
		<Dialog open fullWidth>
			<DialogTitle disableTypography className="border-b">
				{t('containerProperties')}
			</DialogTitle>
			<DialogContent className="p-20">
				<FormProvider {...methods}>
					<div className="flex flex-row">
						<Controller
							control={methods.control}
							name="name"
							render={({ field, fieldState }) => (
								<EditFormTextField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										className: 'w-4/5 mr-12',
										key: 'name',
										text: t('name'),
										required: true
									}}
								/>
							)}
						/>
						<div className="w-1/5">
							<Controller
								control={methods.control}
								name="visible"
								render={({ field, fieldState }) => (
									<EditFormCheckboxField
										field={field}
										fieldState={fieldState}
										config={{ topic: 'whatIf' }}
										fieldConfig={{
											key: 'visible',
											text: t('visible')
										}}
									/>
								)}
							/>
						</div>
					</div>
					<Controller
						control={methods.control}
						name="linkTo"
						render={({ field, fieldState }) => (
							<EditFormSelectField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'whatIf' }}
								fieldConfig={{
									key: 'linkTo',
									text: t('linkTo'),
									items: () => WhatIfService.getListSelection()
								}}
							/>
						)}
					/>
					<Controller
						control={methods.control}
						name="icon"
						render={({ field, fieldState }) => (
							<EditFormSelectField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'whatIf' }}
								fieldConfig={{
									key: 'icon',
									text: t('icon'),
									items: iconset
								}}
							/>
						)}
					/>
					<div className="flex flex-row">
						<Controller
							control={methods.control}
							name="fontSize"
							render={({ field, fieldState }) => (
								<EditFormTextField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'fontSize',
										text: t('fontSize'),
										type: 'number',
										className: 'mr-8'
									}}
								/>
							)}
						/>
						<Controller
							control={methods.control}
							name="iconSize"
							render={({ field, fieldState }) => (
								<EditFormTextField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'iconSize',
										text: t('iconSize'),
										type: 'number',
										className: 'ml-8 mr-8'
									}}
								/>
							)}
						/>
						<Controller
							control={methods.control}
							name="showLabel"
							render={({ field, fieldState }) => (
								<EditFormCheckboxField
									field={field}
									fieldState={fieldState}
									config={{ topic: 'whatIf' }}
									fieldConfig={{
										key: 'showLabel',
										text: t('showLabel')
									}}
								/>
							)}
						/>
					</div>
					<Controller control={methods.control} name="borderColor" render={({ field, fieldState }) => <ColorPickerField key="borderColor" label="backgroundColorLabel" field={field} fieldState={fieldState} />} />
					<Controller control={methods.control} name="fillColor" render={({ field, fieldState }) => <ColorPickerField key="fillColor" label="fontColorLabel" field={field} fieldState={fieldState} />} />
				</FormProvider>
			</DialogContent>
			<DialogActions className="justify-between border-t p-20">
				<Button
					onClick={() => {
						handleCancel();
					}}
				>
					{t('cancel')}
				</Button>
				<Button onClick={methods.handleSubmit(handleSave)} color="primary" autoFocus>
					{t('save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
export default ContainerItemProperties;
